import {Orderbook} from '../utils/orderbook';
import {ORDER_BOOK, ORDER_BOOK_DIFF, SUBSCRIBE_TO_PRECISION} from './WsReducer';

let book = new Orderbook();

export const SAVED_PRECISION = 'SAVED_PRECISION';

const initialState = {
  ctcInstrIdentifier: null,
  precision: localStorage.getItem(SAVED_PRECISION),
  buy: [],
  sell: [],
  spread: 0,
  spreadPercent: 0,
  buyVolume: '0',
  sellVolume: '0',
  highestBuy: null,
  lowestSell: null,
  hasMorePrecision: false,
  hasLessPrecision: false,
};

export const SET_ORDERBOOK_PRECISION = 'SET_ORDERBOOK_PRECISION';
export const UPDATE_ORDER_BOOK = 'UPDATE_ORDER_BOOK';

function OrderbookReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ORDER_BOOK:
      const {marketId, precision, buyOrderbookList, sellOrderbookList} = action.payload;
      book.init(marketId, precision, buyOrderbookList, sellOrderbookList);

      return book.toState();

    case SET_ORDERBOOK_PRECISION: {
      const newPrecisionState = book.getNewPrecisionState(action.delta);

      if (!newPrecisionState) {
        return state;
      }

      action.asyncDispatch({
        type: SUBSCRIBE_TO_PRECISION,
        precision: newPrecisionState.precision,
        ctcInstrIdentifier: action.ctcInstrIdentifier,
      });

      return {...state, ...newPrecisionState};
    }

    default:
      return state;
  }
}

export default OrderbookReducer;
