import React, {Component} from 'react';
import I18n from '../../cmp/helpers/I18n';
import {connect} from 'react-redux';
import en1 from '../../../../assets/images/faq/en/enable_2fa_1.png';
import en2 from '../../../../assets/images/faq/en/enable_2fa_2.png';
import en3 from '../../../../assets/images/faq/en/enable_2fa_3.png';
import en4 from '../../../../assets/images/faq/en/enable_2fa_4.png';
import en5 from '../../../../assets/images/faq/en/enable_2fa_5.png';
import en6 from '../../../../assets/images/faq/en/enable_2fa_6.png';
import en7 from '../../../../assets/images/faq/en/enable_2fa_7.png';
import en8 from '../../../../assets/images/faq/en/enable_2fa_8.png';
import en9 from '../../../../assets/images/faq/en/enable_2fa_9.png';
import ru1 from '../../../../assets/images/faq/ru/enable_2fa_1.png';
import ru2 from '../../../../assets/images/faq/ru/enable_2fa_2.png';
import ru3 from '../../../../assets/images/faq/ru/enable_2fa_3.png';
import ru4 from '../../../../assets/images/faq/ru/enable_2fa_4.png';
import ru5 from '../../../../assets/images/faq/ru/enable_2fa_5.png';
import ru6 from '../../../../assets/images/faq/ru/enable_2fa_6.png';
import ru7 from '../../../../assets/images/faq/ru/enable_2fa_7.png';
import ru8 from '../../../../assets/images/faq/ru/enable_2fa_8.png';
import ru9 from '../../../../assets/images/faq/ru/enable_2fa_9.png';

class Enable2FaAnswer extends Component {
  renderRuVersion() {
    return (
      <React.Fragment>
        <div className='faq-complex-answer'>
          <div>
            <strong>Шаг 1.</strong>
            <p>Зайдите в свой аккаунт UnitEx - раздел «Настройки»</p>
            <img src={ru1} alt='' />
          </div>

          <div>
            <strong>Шаг 2.</strong>
            <p>
              Откроется страница для скачивания приложения, подходящего под Ваше устройство (App Store, Google Play,
              Microsoft). Мы рекомендуем использовать приложение Google Authenticator для мобильного устройства.
            </p>
            <p>Выберите нужное приложение и перейдите к скачиванию.</p>
            <p>
              Убедитесь, что установка приложения завершена, и перейдите к следующему шагу – вводу пароля от Вашего
              аккаунта (личного кабинета) UnitEx.
            </p>
            <img src={ru2} alt='' />
          </div>

          <div>
            <strong>Шаг 3.</strong>
            <p>Сохраните Ваш уникальный резервный ключ в надежном месте и перейдите к следующему шагу.</p>
            <img src={ru3} alt='' />
          </div>

          <div>
            <strong>Шаг 4.</strong>
            <p>
              Откройте скачанное приложение (Google Authenticator) на Вашем мобильном устройстве, выберите «Сканировать
              QR-код» и отсканируйте предоставленный QR код.
            </p>
            <p>Ручной ключ будет введён автоматически, это поле трогать не нужно.</p>
            <p>
              В графу «Код 2FA из приложения», введите шестизначный код, отображающийся в приложении Google
              Authenticator под адресом электронной почты, и в течение 30 секунд нажмите кнопку «Включить 2FA». Данный
              код в приложении будет меняться каждые 30 секунд на новый, если Вы не успели его ввести или получили
              сообщение об ошибке, просто дождитесь появления нового кода и попробуйте снова.
            </p>
            <img src={ru4} alt='' />
            <img src={ru5} alt='' />
            <img src={ru6} alt='' />
          </div>

          <div>
            <strong>Шаг 5.</strong>
            <p>
              Для завершения процесса пожалуйста нажмите «продолжить» и дождитесь письма на Вашу электронную почту.
              Иногда этот процесс может занять до одной минуты.
            </p>
            <img src={ru7} alt='' />
          </div>

          <div>
            <strong>Шаг 6.</strong>
            <p>
              Для подтверждения включения Двухфакторной аутентификации, в присланном письме на электронную почту,
              нажмите на синюю кнопку «Активировать 2ФА».
            </p>
            <img src={ru8} alt='' />
          </div>

          <div>
            <strong>Шаг 7.</strong>
            <p>Включение 2ФА завершено.</p>
            <img src={ru9} alt='' />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEnVersion() {
    return (
      <React.Fragment>
        <div className='faq-complex-answer'>
          <div>
            <strong>Step 1.</strong>
            <p>Log in and go to settings in your account.</p>
            <img src={en1} alt='' />
          </div>

          <div>
            <strong>Step 2.</strong>
            <p>
              A window will open to download the application which is suitable for your device (App Store, Google Play,
              Microsoft). We advise to use Google Authenticator app for mobile devices.
            </p>
            <p>Pick the applicable application and start Downloading.</p>
            <p>
              Make sure that installation of the application is completed and go to the next step – enter password of
              your UntiEx Account.
            </p>
            <img src={en2} alt='' />
          </div>

          <div>
            <strong>Step 3.</strong>
            <p>Save your unique backup key in a safe place and move to the next step.</p>
            <img src={en3} alt='' />
          </div>

          <div>
            <strong>Step 4.</strong>
            <p>
              Open the downloaded application (Google Authenticator) on your mobile device. Choose "Scan QR code" and
              scan the provided QR code.
            </p>
            <p>Manual key will be inputted automatically and you don’t need to touch that field.</p>
            <p>
              In the field "2FA code from app" input the 6 digit code, which you can find in Google Authenticator under
              your email address. When the app runs, it generates a new 6-digit authentication code every 30 seconds.
              This code is the one-time password that the user enters for two-factor authentication. You should enter
              this code and press "Enable 2FA" within 30 seconds. If you have not received the code or you have got an
              error message, wait for the new code and try again.
            </p>
            <img src={en4} alt='' />
            <img src={en5} alt='' />
            <img src={en6} alt='' />
          </div>

          <div>
            <strong>Step 5.</strong>
            <p>
              To finalize the process press "Continue" and wait for an confirmation email. Sometimes this process might
              take up to 1 minute.
            </p>
            <img src={en7} alt='' />
          </div>

          <div>
            <strong>Step 6.</strong>
            <p>
              To confirm activation of 2 Factor Authentication (2FA), in the received email, press on the blue button
              "Activate 2FA"
            </p>
            <img src={en8} alt='' />
          </div>

          <div>
            <strong>Step 7.</strong>
            <p>2FA enabled successfully.</p>
            <img src={en9} alt='' />
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {lng} = this.props.LngReducer;
    if (lng === 'en') return this.renderEnVersion();
    else if (lng === 'ru') return this.renderRuVersion();
    return <I18n tKey='faq.enable_2fa_answer' />;
  }
}

const mapStateToProps = ({LngReducer}) => {
  return {LngReducer};
};

export default connect(mapStateToProps)(Enable2FaAnswer);
