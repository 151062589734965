// @flow
import * as React from 'react';
import {Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {CLOSE_POPUP, OPEN_POPUP} from '../../../reducers/PopupReducer';
import {ENABLE_2FA_STEP5_POPUP} from './Enable2FAStep5Popup';
import * as Actions from '../../../api/Actions';
import copy from 'copy-to-clipboard';
import {ReactComponent as CopyIc} from '../../../../../assets/images/icon/copy.svg';
import {SvgWrapper} from '../../helpers/SvgWrapper';
import I18n from '../../helpers/I18n';

export const ENABLE_2FA_STEP4_POPUP = 'ENABLE_2FA_STEP4_POPUP';

class Enable4FAStep2Popup extends React.PureComponent<void, ComponentState> {
  state = {
    code: '',
    manualKey: this.props.AddTwoFactorAuthReducer.data.mysteryInf,
  };

  copyToClipboard = data => {
    copy(data);
  };

  componentWillReceiveProps(n) {
    if (n.BindTwoFactorAuthReducer && n.BindTwoFactorAuthReducer.completed) {
      this.props.nextStep();
    }
  }

  sendEmail = () => {
    this.props.save({code: this.state.code, tfVec: 'google'});
  };

  onPressEnter = event => {
    if (event.key === 'Enter') {
      this.sendEmail();
    }
  };

  render() {
    const {AddTwoFactorAuthReducer, BindTwoFactorAuthReducer, SessionReducer} = this.props;
    const twofaData = AddTwoFactorAuthReducer.data;
    if (!twofaData) {
      return;
    }
    return (
      <Fragment>
        <div className='modal-2FA-form text-center'>
          <h2>
            <I18n tKey='popup.long' />
          </h2>
          <div className='modal-2FA-form-text'>
            <I18n tKey='popup.open_google' />{' '}
            {SessionReducer.masterPersonality && SessionReducer.masterPersonality.electronicMailVal}{' '}
            <I18n tKey='popup.open_google.account' />
          </div>
          <div className='block-qr'>
            <img src={twofaData.qrLocation} alt='' />
          </div>
          <form className='ui-form'>
            <div className='ui-form-input focus'>
              <input
                type='text'
                value={this.state.manualKey}
                onChange={e => this.setState({manualKey: e.target.value})}
                className='ui-form-control'
              />
              <div className='ui-form-control-focus' />
              <label className='ui-form-control-placeholder'>
                <I18n tKey='popup.manual_key' />
              </label>
              <label className='ui-form-control-btn'>
                <a
                  className='link'
                  onClick={() => {
                    this.copyToClipboard(this.state.manualKey);
                  }}
                >
                  <SvgWrapper themeWatch className='icon icon-key-print ic-size-11' SvgCmp={CopyIc} />
                </a>
              </label>
            </div>
            <div className='ui-form-input focus'>
              <input
                type='text'
                value={this.state.code}
                onChange={e => this.setState({code: e.target.value})}
                className='ui-form-control'
                onKeyPress={this.onPressEnter}
              />
              <div className='ui-form-control-focus' />
              <label className='ui-form-control-placeholder'>
                <I18n tKey='popup.two_fa' />
              </label>
              <label className='ui-form-control-btn'>
                <a className='link'>
                  <i className='icon icon-app' />
                </a>
              </label>
            </div>
          </form>
          {!BindTwoFactorAuthReducer.fetching && (
            <a className='btn btn-primary btn-lg' onClick={this.sendEmail}>
              <I18n tKey='popup.enable_2fa' />
            </a>
          )}
          {BindTwoFactorAuthReducer.fetching && <I18n tKey='common.loading' />}
          {BindTwoFactorAuthReducer.error && (
            <div className='error-2fa'>
              {BindTwoFactorAuthReducer.error.parsedError || BindTwoFactorAuthReducer.error.error}
            </div>
          )}
          <div className='modal-text-bottom'>
            <a href='/page/faq/all' target='_blank'>
              <I18n tKey='faq.about_2fa' />
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({BindTwoFactorAuthReducer, AddTwoFactorAuthReducer, SessionReducer}) => ({
  BindTwoFactorAuthReducer,
  AddTwoFactorAuthReducer,
  SessionReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        save: Actions.BindTwoFactorAuth.action,
      },
      dispatch
    ),
    nextStep: () => dispatch({type: OPEN_POPUP, name: ENABLE_2FA_STEP5_POPUP}),
    close: () => dispatch({type: CLOSE_POPUP}),
  };
};
const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Enable4FAStep2Popup));

export default connectedWithRouter;
