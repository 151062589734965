// @flow

import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import TopMenu, {returnInfoTabs} from './static/TopMenu';
import LeftMenu from './static/LeftMenu';
import {translate} from '../utils/i18n';
import Faq from './Faq';
import HelpCenter from './HelpCenter/HelpCenter';
import Footer from './Footer';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import HelpCenterNew from './HelpCenter/HelpCenterNew';
import FeesPage from './FeesPage';

class StaticPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.infoTabs = returnInfoTabs(props.LngReducer.dictionary, false);
    this.state = {
      pageIsBeingRead: false,
      html: null,
      faq: false,
      subSection: 'all',
      helpCenter: false,
      helpSection: 'accounts',
    };
  }

  componentDidMount() {
    const {SessionReducer} = this.props;
    const user = SessionReducer.masterPersonality || {};
    this.infoTabs = returnInfoTabs(this.props.LngReducer.dictionary, user.curUName ? true : false);
    this.readTabContent(this.props);
  }

  readTabContent = props => {
    const folder = props.match.params.name;
    const file = props.match.params.subName;
    const lng = props.LngReducer.lng;
    const notFound = translate('common.page_not_found', this.props.LngReducer.dictionary);

    let faqRoutes = ['about', 'all', 'verification', 'problems', 'finance', 'general'];

    if (folder === 'faq') {
      if (faqRoutes.includes(file)) {
        this.setState({
          faq: true,
          subSection: file,
        });
      } else {
        this.setState({pageIsBeingRead: true});
        import(`../../../assets/static_pages/${folder}/${file}.EN.html`)
          .then(html => {
            this.setState({html: html.default || `${notFound}`, pageIsBeingRead: false, faq: false});
          })
          .catch(er => {
            this.setState({html: `<div>${notFound}</div>`, pageIsBeingRead: false, faq: false});
          });
      }
    } else if (folder === 'help-center') {
      this.setState({
        helpCenter: true,
        helpSection: file,
      });
    } else {
      this.setState({pageIsBeingRead: true});
      import(`../../../assets/static_pages/${folder}/${file}.EN.html`)
        .then(html => {
          this.setState({html: html.default || `${notFound}`, pageIsBeingRead: false, faq: false});
        })
        .catch(er => {
          this.setState({html: `<div>${notFound}</div>`, pageIsBeingRead: false, faq: false});
        });
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.match.url !== nextProps.match.url) {
      this.readTabContent(nextProps);
    }
  }

  render() {
    const tabName = this.props.match.params.name;
    const {faq, subSection, helpCenter, helpSection} = this.state;
    const leftTabs = (this.infoTabs.filter(tab => tab.url.includes(tabName))[0] || {subRoutes: []}).subRoutes;
    // const isFeesPage = this.props.match.params.subName === 'fees-information';

    return (
      <div className='page-legacy'>
        <div className='container'>
          <TopMenu />
          <div className='tab-content'>
            <div className='tab-pane fade active' id='terms' role='tabpanel' aria-labelledby='tab-profile'>
              <div className='page-legacy_content'>
                <LeftMenu tabs={leftTabs} />

                {helpCenter && (
                  <GoogleReCaptchaProvider reCaptchaKey='6LfYSwYjAAAAANsjdPcghMkspqJB4HhDx6okeS3g'>
                    {/*<HelpCenter section={helpSection} />*/}
                    <HelpCenterNew section={helpSection} />
                  </GoogleReCaptchaProvider>
                )}

                {faq ? (
                  <Faq section={subSection} tabs={leftTabs} />
                ) : (
                  <div dangerouslySetInnerHTML={{__html: this.state.html}} />
                )}
                {/* with fees page code */}
                {/* {faq ? (
                  <Faq section={subSection} tabs={leftTabs} />
                ) : isFeesPage ? (
                  <FeesPage />
                ) : (
                  <div dangerouslySetInnerHTML={{__html: this.state.html}} />
                )} */}
              </div>
            </div>
            {/*<div className="tab-pane fade" id="privacy" role="tabpanel" aria-labelledby="tab-profile"/>*/}
            {/*<div className="tab-pane fade" id="cookies" role="tabpanel" aria-labelledby="tab-profile"/>*/}
            {/*<div className="tab-pane fade" id="white" role="tabpanel" aria-labelledby="tab-profile"/>*/}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({LngReducer, SessionReducer}) => ({LngReducer, SessionReducer});
export default withRouter(connect(mapStateToProps, {})(StaticPage));
