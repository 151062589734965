import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link, withRouter} from 'react-router-dom';
import moment from 'moment';
import React from 'react';

import {CurrencyView} from '../helpers/CurrencyView';
import {SvgWrapper} from '../helpers/SvgWrapper';
import * as Actions from '../../api/Actions';
import I18n from '../helpers/I18n';
import {ReactComponent as IncomeIc} from '../../../../assets/images/icon/income-ic.svg';
import {ReactComponent as ErrIc} from '../../../../assets/images/icon/err-ic.svg';
import {ReactComponent as OkIc} from '../../../../assets/images/icon/ok-ic.svg';
import {ReactComponent as WarningIc} from '../../../../assets/images/icon/warning-ic.svg';
import {ReactComponent as DocumentIc} from '../../../../assets/images/documents-history/document.svg';

import {URLMAP} from '../../utils/const';

export const KYC_STATUS_CHANGED = 'KYS_STATUS_CHANGED';

export const DEPOSIT_CONFIRMED = 'DEPOSIT_CONFIRMED';
export const DEPOSIT_RECEIVED = 'DEPOSIT_RECEIVED';

export const WITHDRAWAL_ACCEPTED = 'WITHDRAWAL_ACCEPTED';
export const WITHDRAWAL_COMPLIANCE_CHECK = 'WITHDRAWAL_COMPLIANCE_CHECK';
export const WITHDRAWAL_REJECTED = 'WITHDRAWAL_REJECTED';
export const WITHDRAWAL_PAID_OUT = 'WITHDRAWAL_PAID_OUT';

const KYCMsgBody = ({status}) => {
  return (
    <span>
      {status === 'CORRECTED' && (
        <span>
          <I18n tKey='kyc_notify.new.msg' />
        </span>
      )}
      {status === 'CORRECTED' && (
        <span>
          <I18n tKey='kyc_notify.corrected.msg' />{' '}
          {/*<Link to="/profile/Verifications"><I18n tKey="kyc_notify.rejected.link"/></Link>*/}
        </span>
      )}
      {status === 'VERIFIED' && (
        <span>
          <I18n tKey='kyc_notify.verified.msg' />{' '}
          <Link to='/balances'>
            <I18n tKey='kyc_notify.verified.link' />
          </Link>
        </span>
      )}
      {status === 'REJECTED' && (
        <span>
          <I18n tKey='kyc_notify.rejected.msg' />{' '}
        </span>
      )}
    </span>
  );
};

const DepositWithdrawMsgBody = ({type, description, language}) => {
  const {amount, currency, timestamp} = description;
  return (
    <span>
      <div>{moment(timestamp).locale(language).format('MMMM DD YYYY, HH:mm')}</div>

      {type === DEPOSIT_RECEIVED && <I18n tKey='deposit_notify.received.msg' />}
      {type === DEPOSIT_CONFIRMED && <I18n tKey='deposit_notify.confirmed.msg' />}

      {type === WITHDRAWAL_ACCEPTED && <I18n tKey='withdraw_notify.accepted.msg' />}
      {type === WITHDRAWAL_COMPLIANCE_CHECK && <I18n tKey='withdraw_notify.compliance.msg' />}
      {type === WITHDRAWAL_REJECTED && <I18n tKey='withdraw_notify.rejected.msg' />}
      {type === WITHDRAWAL_PAID_OUT && <I18n tKey='withdraw_notify.paid.msg' />}

      <div>
        <I18n tKey='common:amount' />: <CurrencyView type={currency} amount={amount} showType />
      </div>
    </span>
  );
};

const MsgIcon = ({type, description}) => {
  if (typeof description === 'string') {
    description = JSON.parse(description || '{}');
  }
  switch (type) {
    case 'ORDER_OPENED':
    case 'ORDER_EXECUTED':
    case DEPOSIT_CONFIRMED:
    case WITHDRAWAL_ACCEPTED:
    case WITHDRAWAL_COMPLIANCE_CHECK:
      return <SvgWrapper className='ic-size-06' SvgCmp={OkIc} />;
    case 'ORDER_CANCELLED':
    case WITHDRAWAL_REJECTED:
      return <SvgWrapper className='ic-size-06' SvgCmp={ErrIc} />;
    case 'ORDER_PARTIALLY_FILLED':
    case KYC_STATUS_CHANGED:
      if (description.status === 'REJECTED') {
        return <SvgWrapper className='ic-size-06' SvgCmp={ErrIc} />;
      }
      if (description.status === 'VERIFIED' || 'NEW') {
        return <SvgWrapper className='ic-size-06' SvgCmp={OkIc} />;
      }
      return <SvgWrapper className='ic-size-06' SvgCmp={WarningIc} />;
    case DEPOSIT_RECEIVED:
    case 'INCOMING_DIVIDEND':
    case WITHDRAWAL_PAID_OUT:
    case 'BALANCE_UPDATED':
      return <SvgWrapper className='ic-size-06' SvgCmp={IncomeIc} />;
    case 'DOCUMENT_REQUEST':
      return <SvgWrapper className='ic-size-06 document-pending' SvgCmp={DocumentIc} />;
    default:
      return null;
  }
};

const RenderOrder = (type, description) => {
  let showPrice = true;
  let showAvgPrice = false;
  if (description.orderType === 'MARKET') {
    showPrice = false;
  }
  if (!!description.avgPrice) {
    showPrice = false;
    showAvgPrice = true;
  }

  return (
    <span>
      <span>
        <I18n tKey='common:order_type' />: {description.orderType}, {description.side}
      </span>
      <span>
        &nbsp;
        <I18n tKey='common:market' />: {description.marketTitle}
      </span>
      <div>
        <span>
          <I18n tKey='common:currency' />: {description.currency}
        </span>

        {showPrice && (
          <span>
            &nbsp;
            <I18n tKey='common:price' />: <CurrencyView type={description.currency} amount={description.price} />
          </span>
        )}
        {showAvgPrice && (
          <span>
            &nbsp;
            <I18n tKey='common:avg_price' />: <CurrencyView type={description.currency} amount={description.avgPrice} />
          </span>
        )}

        <span>
          &nbsp;
          <I18n tKey='common:amount' />: {parseFloat(description.amount).toString()}
        </span>
      </div>
    </span>
  );
};

const isOrderMessage = type => {
  switch (type) {
    case 'ORDER_OPENED':
    case 'ORDER_EXECUTED':
    case 'ORDER_CANCELLED':
    case 'ORDER_PARTIALLY_FILLED':
      return true;
  }
  return false;
};

const MsgDescription = ({type, description, currentMsgT, language}) => {
  if (typeof description === 'string') {
    description = JSON.parse(description || '{}');
  }
  switch (type) {
    case 'ORDER_OPENED':
    case 'ORDER_EXECUTED':
    case 'ORDER_CANCELLED':
    case 'ORDER_PARTIALLY_FILLED':
      return RenderOrder(type, description);
    case 'DEPOSIT_RECEIVED':
    case 'INCOMING_DIVIDEND':
      return (
        <span>
          <I18n tKey='common:currency' />: {description.currency}. <I18n tKey='common:amount' />:{' '}
          <CurrencyView type={description.currency} amount={description.amount} />.
        </span>
      );
    case KYC_STATUS_CHANGED:
      return (
        <span>
          <span>{description.status}</span> <KYCMsgBody status={description.status} />
        </span>
      );
    case DEPOSIT_RECEIVED:
    case DEPOSIT_CONFIRMED:
    case WITHDRAWAL_ACCEPTED:
    case WITHDRAWAL_COMPLIANCE_CHECK:
    case WITHDRAWAL_REJECTED:
    case WITHDRAWAL_PAID_OUT:
      return <DepositWithdrawMsgBody language={language} description={description} type={currentMsgT} />;
    case 'DOCUMENT_REQUEST': {
      return (
        <div>
          <I18n tKey={`documents.notification_${description.status.toLowerCase()}`} />
        </div>
      );
    }
    default:
      return null;
  }
};

class NotificationItem extends React.Component {
  displayName: 'NotificationItem';

  read(id, isDocumentNotification) {
    this.props.enableRead && this.props.readMsg(null, {msgIdentifier: id});

    if (this.props.history.location.pathname !== URLMAP.DOCUMENTS_HISTORY && isDocumentNotification) {
      this.props.history.push(URLMAP.DOCUMENTS_HISTORY);
      this.props.onClose();
    }
  }

  render() {
    const {cTime, currentMsgT, addCharValues, identificatSymbol, isRead} = this.props.notification;
    const language = this.props.LngReducer.lng;
    let messageParams = {};
    if (typeof addCharValues === 'string') {
      messageParams = JSON.parse(addCharValues || '{}');
    }
    return (
      <div
        className={`notification-item ${this.props.enableRead ? 'able-to-read' : ''}`}
        onClick={this.read.bind(this, identificatSymbol, currentMsgT === 'DOCUMENT_REQUEST')}
      >
        <div>
          <MsgIcon type={currentMsgT} description={addCharValues} />
        </div>
        <div className='description'>
          <div className='text'>
            <span className='value'>
              <I18n tKey={(currentMsgT || '').replace(/^KYS_/, 'KYC_').replace(/_/g, ' ')} />
            </span>
            <br />
            <MsgDescription
              language={language}
              currentMsgT={currentMsgT}
              type={currentMsgT}
              description={addCharValues}
            />
          </div>
          <div className='time'>
            {moment(cTime).locale(language).format('MMMM DD YYYY, HH:mm')}
            {isOrderMessage(currentMsgT) && messageParams.orderId && (
              <span style={{float: 'right'}}>Id: {messageParams.orderId}</span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({NotificationReducer, LngReducer}) => {
  return {NotificationReducer, LngReducer};
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        readMsg: Actions.ReadMsg.action,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationItem));
