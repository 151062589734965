import React, {Component} from 'react';
import I18n from '../../cmp/helpers/I18n';
import {REJECT_MESSAGE, STATUS} from './GlobalPass';

export class StatusHeader extends Component {
  returnStatusParams = () => {
    const {status = '', token} = this.props;
    const {vendorStatus = ''} = token;
    const params = {
      statusTitle: 'verify:wasnt_started',
      statusName: status,
      statusColorClass: 'red',
    };
    if (status === STATUS.PENDING) {
      params.statusTitle = 'verify:app_in_progress';
      params.statusColorClass = 'wait';
    } else if (status === STATUS.VERIFIED) {
      params.statusTitle = 'verify:app_approved';
      params.statusColorClass = 'green';
    } else if (status === STATUS.REJECTED || (status === STATUS.NEW && vendorStatus === STATUS.REJECTED)) {
      params.statusName = 'NULL';
      params.statusTitle = 'verify:app_rejected';
    }
    return params;
  };

  render() {
    const {status = '', message = '', comments = '', tryAgain, isFetching, token} = this.props;
    const {vendorStatus = ''} = token;
    if (new RegExp(STATUS.NEW).test(status) && new RegExp(STATUS.NEW).test(vendorStatus)) {
      return null;
    }
    const isRejectedButCanReapply =
      vendorStatus === STATUS.REJECTED && status === STATUS.NEW && message === REJECT_MESSAGE.CAN_REAPPLY;
    const statusParams = this.returnStatusParams();
    return (
      status && (
        <div className='block-account'>
          <div className='block-account-control'>
            <div className='block-account-control_value'>
              <div className='title'>
                <I18n tKey='account_overview:verification_status' />
              </div>
              <div className={`status ${statusParams.statusColorClass}`}>
                {statusParams.statusName !== 'NULL' && (
                  <span>
                    <I18n tKey={statusParams.statusName} />,{' '}
                  </span>
                )}
                <I18n tKey={statusParams.statusTitle} />
              </div>
            </div>
            {isRejectedButCanReapply && (
              <div className='block-account-control_action'>
                <button onClick={tryAgain} disabled={isFetching} className='link-control with_btn'>
                  <I18n tKey={isFetching ? 'common:loading' : 'common:try_again'} />
                </button>
              </div>
            )}
          </div>
          {isRejectedButCanReapply && !!comments && <div className='block-account-control'>{comments}</div>}
        </div>
      )
    );
  }
}
