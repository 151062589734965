import {MARKET_SORTING} from '../constants';
import BigNumber from 'bignumber.js';

const sortPriceAcs = (leftMarket, rightMarket, additionalParams) => {
  const {MarketPricesReducer, WsMarketStatsReducer, tickers} = additionalParams;
  const leftStats = WsMarketStatsReducer[leftMarket.identificatSymbol] || {};
  const rightStats = WsMarketStatsReducer[rightMarket.identificatSymbol] || {};

  const leftPrice =
    tickers.next[leftMarket.identificatSymbol] ||
    MarketPricesReducer.pricesByMarket[leftMarket.identificatSymbol] ||
    leftStats.lastPrice;
  const rightPrice =
    tickers.next[rightMarket.identificatSymbol] ||
    MarketPricesReducer.pricesByMarket[rightMarket.identificatSymbol] ||
    rightStats.lastPrice;

  return Number(leftPrice) - Number(rightPrice);
};

const sortPriceDesc = (leftMarket, rightMarket, additionalParams) => {
  const {MarketPricesReducer, WsMarketStatsReducer, tickers} = additionalParams;
  const leftStats = WsMarketStatsReducer[leftMarket.identificatSymbol] || {};
  const rightStats = WsMarketStatsReducer[rightMarket.identificatSymbol] || {};

  const leftPrice =
    tickers.next[leftMarket.identificatSymbol] ||
    MarketPricesReducer.pricesByMarket[leftMarket.identificatSymbol] ||
    leftStats.lastPrice;
  const rightPrice =
    tickers.next[rightMarket.identificatSymbol] ||
    MarketPricesReducer.pricesByMarket[rightMarket.identificatSymbol] ||
    rightStats.lastPrice;

  return Number(rightPrice) - Number(leftPrice);
};

const sortPairAsc = (left, right) => {
  return left.mBanner.localeCompare(right.mBanner);
};

const sortPairDesc = (left, right) => {
  return right.mBanner.localeCompare(left.mBanner);
};

const sortChangeAsc = (left, right) => {
  return left.dailyPriceChangePercent - right.dailyPriceChangePercent;
};

const sortChangeDesc = (left, right) => {
  return right.dailyPriceChangePercent - left.dailyPriceChangePercent;
};

const sortVolumeDesc = (left, right) => {
  const {usdVolume: rightUsdVolume} = right;
  const {usdVolume: leftUsdVolume} = left;

  return Number(rightUsdVolume) - Number(leftUsdVolume);
};

const sortVolumeAcs = (left, right) => {
  const {usdVolume: rightUsdVolume} = right;
  const {usdVolume: leftUsdVolume} = left;

  return Number(leftUsdVolume) - Number(rightUsdVolume);
};

export const sortingHandlers = {
  [MARKET_SORTING.price_asc]: sortPriceAcs,
  [MARKET_SORTING.price_desc]: sortPriceDesc,
  [MARKET_SORTING.pair_asc]: sortPairAsc,
  [MARKET_SORTING.pair_desc]: sortPairDesc,
  [MARKET_SORTING.change_asc]: sortChangeAsc,
  [MARKET_SORTING.change_desc]: sortChangeDesc,
  [MARKET_SORTING.volume_desc]: sortVolumeDesc,
  [MARKET_SORTING.volume_asc]: sortVolumeAcs,
};
