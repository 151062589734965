import {Fragment} from 'react';
import NotificationItem from './NotificationItem';
import {SvgWrapper} from '../helpers/SvgWrapper';
import {ReactComponent as CrossIc} from '../../../../assets/images/icon/close-gray.svg';
import {ReactComponent as Plus} from '../../../../assets/images/icon/plus.svg';
import React from 'react';
import {ReactComponent as EditIc} from '../../../../assets/images/icon/edit.svg';
import I18n from '../helpers/I18n';

class PriceAlertTab extends React.Component {
  displayName: 'PriceAlertTab';

  render() {
    return (
      <Fragment>
        <div className='notification-center_title'>
          <I18n tKey='notification.price_alert' />
          <a onClick={() => this.setState({isOpenModalAlert: true})} className='btn btn-add'>
            <I18n tKey='common.add_alert' />
            <SvgWrapper className='icon icon-max ic-size-02' themeWatch SvgCmp={Plus} />
          </a>
        </div>
        <div className='notification-center_list'>
          <div className='scrollbar-inner'>
            <div className='list-price'>
              <div className='list-price_item'>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' defaultValue='on' />
                    <span className='checkmark' />
                    <span className='value'>BTCUSD</span>
                    <I18n tKey='common.cross_val' />
                    <span className='value'>6129.12</span>
                  </label>
                </div>
                <div className='action'>
                  <a href='#'>
                    <SvgWrapper className='icon icon-edit ic-size-02' SvgCmp={EditIc} />
                  </a>
                  <a href='#'>
                    <SvgWrapper className='icon icon-cross ic-size-02 icon-close ic-color-00' SvgCmp={CrossIc} />
                  </a>
                </div>
              </div>
              <div className='list-price_item'>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' defaultValue='on' />
                    <span className='checkmark' />
                    <span className='value'>BTCUSD</span>
                    <I18n tKey='common.cross_val' />
                    <span className='value'>6129.12</span>
                  </label>
                </div>
                <div className='action'>
                  <a href='#'>
                    <SvgWrapper className='icon icon-edit ic-size-02' SvgCmp={EditIc} />
                  </a>
                  <a href='#'>
                    <SvgWrapper className='icon icon-cross ic-size-02' SvgCmp={CrossIc} />
                  </a>
                </div>
              </div>
            </div>
            <NotificationItem Type='var' />
            <NotificationItem Type='var' />
            <NotificationItem Type='var' />
            <NotificationItem Type='var' />
            <NotificationItem Type='var' />
            <NotificationItem Type='var' />
            <NotificationItem Type='var' />
            <NotificationItem Type='var' />
            <NotificationItem Type='var' />
            <NotificationItem Type='var' />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PriceAlertTab;
