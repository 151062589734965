const FALLBACK_PRECISION = 8;

class Currency {
  precisionMap = {};
  symbolMap = {};
  markets = [];

  updateMarketList(markets) {
    this.markets = [];
    if (!markets || !markets.length) return;
    markets.forEach(m => {
      this.markets.push({
        identificatSymbol: m.identificatSymbol,
        left: m.leftMonetaryUnitCode.toLowerCase(),
        right: m.rightMonetaryUnitCode.toLowerCase(),
      });
    });
    this.updMarketPrecisions();
  }

  initCurrencies(currencies) {
    this.precisionMap = {};
    this.symbolMap = {};
    if (!currencies || !currencies.length) return;
    currencies.forEach(cur => {
      let code = cur.mUCryptogram.toLowerCase();
      this.precisionMap[code] = cur.mURigor;
      this.symbolMap[code] = cur.mUMark;
    });
    this.updMarketPrecisions();
  }

  updMarketPrecisions() {
    if (!this.markets || !this.markets.length || !this.precisionMap || !Object.keys(this.precisionMap).length) return;
    this.markets.forEach(m => {
      m.leftPrecision = this.getCurrencyPrecision(m.left);
      m.rightPrecision = this.getCurrencyPrecision(m.right);
    });
  }

  getCurrencyPrecision(curCode) {
    return this.precisionMap[curCode.toLowerCase()] || FALLBACK_PRECISION;
  }

  getCurSymbol(curCode) {
    return this.symbolMap[curCode.toLowerCase()] || 'n/a';
  }

  stripTailingZeroes(value) {
    let ret = value.replace(/0+$/, '');
    if (ret[ret.length - 1] === '.') ret = ret.slice(0, ret.length - 1);
    return ret;
  }

  //arg can be either currency code or int value of desired precision
  show(value, arg, stripTailingZeroes = false) {
    let precision = typeof arg === 'string' ? this.getCurrencyPrecision(arg) : arg;
    precision = precision || 2;
    //console.log(value, precision, arg, typeof arg)

    let val = parseFloat(value);
    if (isNaN(val)) return 'n/a';
    val = val.toString();

    if (val.indexOf('.') === -1) {
      if (!stripTailingZeroes) val += '.' + Array(precision + 1).join('0');
    } else {
      let [int, dec] = val.split('.');

      if (dec.length > precision) {
        dec = dec.substr(0, precision);
      } else if (!stripTailingZeroes && dec.length < precision) {
        dec += Array(precision - dec.length + 1).join('0');
      }

      val = int + '.' + dec;
    }

    return val;
  }

  showWithSign(val, curCode, stripTailingZeroes = false) {
    let ret = this.show(val, curCode, stripTailingZeroes);
    return this.applySign(ret, curCode);
  }

  applySign(str, curCode) {
    const code = this.getCurSymbol(curCode);
    return code === '$' ? code + str : str + ' ' + code;
  }

  calcPriceChangePercent(price, lastPrice, marketId) {
    const market = this.markets.find(m => m.identificatSymbol === marketId);
    if (!market) return null;
    let priceFloat = parseFloat(price);
    let prevPriceFloat = parseFloat(lastPrice);
    if (isNaN(priceFloat) || isNaN(prevPriceFloat)) return null;
    // priceFloat = priceFloat.toFixed(market.rightPrecision);
    // prevPriceFloat = prevPriceFloat.toFixed(market.rightPrecision);
    // console.log(priceFloat, prevPriceFloat);
    if (priceFloat === prevPriceFloat) return null;

    return (((priceFloat - prevPriceFloat) / prevPriceFloat) * 100).toFixed(2);
  }
}

const currency = new Currency();
export default currency;
