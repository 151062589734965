import React, {Component} from 'react';

class CurrencyLabel extends Component {
  render() {
    const {currency} = this.props;

    if (!currency) {
      return null;
    }
    const {src, name} = currency.value;

    return (
      <div className='coin-stats__icon'>
        <img src={src} alt='' />
        <div>{name} Price</div>
      </div>
    );
  }
}

export default CurrencyLabel;
