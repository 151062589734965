import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {ReactComponent as FavIc} from '../../../../../../assets/images/icon/favorite-icon.svg';

import {CurrencyIcon} from '../../../../cmp/helpers/CurrencyIcon';
import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';

import Formatter from '../../../../utils/formatter';
import {URLMAP} from '../../../../utils/const';

import * as Actions from '../../../../api/Actions';

import {SET_ACTIVE_MARKET} from '../../../../reducers/ActiveMarketReducer';
import classNames from 'classnames';
import {MarketVolume} from './components';
import BigNumber from 'bignumber.js';

class Component extends React.Component {
  addToFav = ev => {
    ev.stopPropagation();

    if (!this.props.SessionReducer.masterPersonality) {
      this.props.history.push(URLMAP.LOGIN);

      return;
    }
    this.props.addToFav({ctcInstrumentIdentifier: this.props.market.identificatSymbol});
  };

  selectMarket(market) {
    this.props.history.push(URLMAP.MARKET.replace(':code', market.mBanner));
  }

  render() {
    const {
      isFavourite,
      market,
      isActive,
      WsTickersReducer,
      WsMarketStatsReducer,
      MarketPricesReducer,
      isVolumeDisplayed,
    } = this.props;
    const tickers = WsTickersReducer.tickers || {};
    const stats = WsMarketStatsReducer[market.identificatSymbol] || {};
    const price =
      tickers.next[market.identificatSymbol] ||
      MarketPricesReducer.pricesByMarket[market.identificatSymbol] ||
      stats.lastPrice;
    const dailyPriceChange = price - stats.dailyOpen;
    const dailyPriceChangePercent = !!stats.dailyOpen ? (dailyPriceChange / stats.dailyOpen) * 100 : '0';
    const isUp = price > stats.dailyOpen;

    return (
      <div onClick={() => this.selectMarket(market)} className={classNames('grid-market-item', {['active']: isActive})}>
        <div className='market-icon'>
          <CurrencyIcon curName={market.leftMonetaryUnitCode} hideCurName />
          <SvgWrapper
            onClick={this.addToFav}
            className={classNames('i con icon-star ic-size-02', {['active']: isFavourite})}
            SvgCmp={FavIc}
          />
        </div>

        <div className='row-name'>{market.mBanner}</div>

        <div className='row-price'>{price ? <span>{new BigNumber(price).toFixed(4, 1)} </span> : 'n/a'}</div>

        {isVolumeDisplayed ? (
          <div className='row-percentage'>
            <MarketVolume usdVolume={market.usdVolume} />
          </div>
        ) : (
          <div className='row-percentage'>
            <span className={`${isUp ? 'green' : 'red'}`}>{Formatter.percent(dailyPriceChangePercent)}</span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({WsTickersReducer, WsMarketStatsReducer, MarketPricesReducer, SessionReducer}) => {
  return {
    WsTickersReducer,
    WsMarketStatsReducer,
    MarketPricesReducer,
    SessionReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        addToFav: Actions.ToggleMarketInFavourite.action,
      },
      dispatch
    ),
    selectMarket: market => dispatch({type: SET_ACTIVE_MARKET, market}),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
