import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import BigNumber from 'bignumber.js';

import {DateRangeFilter} from './DateRangeFilter';
import {DropFilter} from './DropFilter';

import I18n from '../../../../cmp/helpers/I18n';
import {CurrencyView} from '../../../../cmp/helpers/CurrencyView';

import {UserTradeHistory} from '../../../../api/Actions';

import Currency from '../../../../utils/currency.js';
import {translate} from '../../../../utils/i18n';
class TradesHistory extends React.PureComponent {
  state = {
    params: {
      markets: null,
      dateFrom: null,
      accountId: null,
      dateTo: null,
      limit: 50,
      offset: 0,
    },
    typeFilter: null,
    tradeHistory: [],
    duplicateHistory: [],
    marketFilter: false,
  };

  componentWillReceiveProps(n) {
    const newElementsCount = n.WsRecentTradesReducer.accountRecentTrades.length;
    if (n.UserTradeHistoryReducer.data.elements) {
      this.setState({
        tradeHistory: n.UserTradeHistoryReducer.data.elements,
        duplicateHistory: n.UserTradeHistoryReducer.data.elements,
      });
      !this.state.params.accountId &&
        this.setState({
          params: {...this.state.params, accountId: n.SessionReducer.masterPersonality.masterRespList[0]},
        });
    }
    if (n.UserTradeHistoryReducer.data.elements && !this.state.params.accountId) {
      this.setState({
        tradeHistory: n.UserTradeHistoryReducer.data.elements,
        duplicateHistory: n.UserTradeHistoryReducer.data.elements,
        params: {...this.state.params, accountId: n.SessionReducer.masterPersonality.masterRespList[0]},
      });
    }
    if (newElementsCount && this.state.tradeHistory.length && !this.state.marketFilter) {
      const tradeHistoryCopy = this.state.tradeHistory.map(elem => ({...elem}));
      n.WsRecentTradesReducer.accountRecentTrades.forEach(newTrade => {
        if (tradeHistoryCopy.every(trade => trade.orderId !== newTrade[0])) {
          tradeHistoryCopy.unshift({
            matchedAt: newTrade[3],
            price: Currency.stripTailingZeroes(newTrade[1]),
            amount: Currency.stripTailingZeroes(newTrade[2]),
            side: newTrade[5],
            orderId: newTrade[0],
            fee: newTrade[4],
            marketId: newTrade[8],
          });
          tradeHistoryCopy.splice(tradeHistoryCopy.length - 1, 1); //deletes last element
          // 6: "LIMIT"
          // 7: 923155  - order id
          // 9: 2717
        }
      });
      this.setState({tradeHistory: tradeHistoryCopy, duplicateHistory: tradeHistoryCopy});
    }
    if (newElementsCount && !this.state.tradeHistory.length && !this.state.marketFilter) {
      this.setState({tradeHistory: newElementsCount, duplicateHistory: newElementsCount});
    }
  }

  setTypeFilter = (type = null) => {
    const {fetching} = this.props.UserTradeHistoryReducer;
    !fetching && this.setState({typeFilter: type}, this.useTypeFilter);
  };

  useTypeFilter = () => {
    const data = this.state.duplicateHistory.map(elem => ({...elem}));
    const filteredTradeHistory = this.state.typeFilter
      ? data.filter(trade => trade.side === this.state.typeFilter)
      : data;
    this.setState({tradeHistory: filteredTradeHistory});
  };

  setMarketFilter = (marketId = null) => {
    const {fetching} = this.props.UserTradeHistoryReducer;
    const filterState = marketId ? true : false;
    if (!fetching) {
      this.setState(
        {
          params: {...this.state.params, markets: marketId},
          typeFilter: null,
          marketFilter: filterState,
        },
        () => {
          this.props.tradeHistoryList(null, this.returnQueryParams());
        }
      );
    }
  };

  returnQueryParams = () => ({
    ctcInstrList: this.state.params.markets,
    leftD: this.state.params.dateFrom,
    masterIdentifier: this.state.params.accountId,
    rightD: this.state.params.dateTo,
    rng: this.state.params.limit,
    listOfst: this.state.params.offset,
  });

  setDateRangeFilter = ({dateFrom, dateTo}) => {
    const {fetching} = this.props.UserTradeHistoryReducer;
    const filterState = dateFrom || dateTo ? true : false;
    if (!fetching) {
      this.setState(
        {
          params: {...this.state.params, ...{dateFrom}, ...{dateTo}},
          typeFilter: null,
          marketFilter: filterState,
        },
        () => {
          this.props.tradeHistoryList(null, this.returnQueryParams());
        }
      );
    }
  };

  renderMarketTitle = marketId => {
    const market = (this.props.MarketListReducer.list || []).filter(elem => elem.identificatSymbol === marketId);
    return market[0] ? market[0].mBanner : translate('common.market_not_found');
  };

  render() {
    const {fetching} = this.props.UserTradeHistoryReducer;
    const typeFilters = {SELL: translate('common.sell'), BUY: translate('common.buy')};
    let marketFilters = {};
    if (this.props.MarketListReducer.list) {
      this.props.MarketListReducer.list.forEach(elem => {
        marketFilters[elem.identificatSymbol] = elem.mBanner.replace('-', '/');
      });
    }
    return (
      <section className='history'>
        <div className='table-dropdown'>
          <div className='table-dropdown_list-item'>
            <DropFilter
              title='orders.all_cur'
              value={this.state.params.markets}
              values={marketFilters}
              showReset={true}
              onChange={this.setMarketFilter}
            />
            <DropFilter
              title='orders.all_types'
              value={this.state.typeFilter}
              values={typeFilters}
              onChange={this.setTypeFilter}
              showReset={true}
            />
            <DateRangeFilter onChange={this.setDateRangeFilter} />
          </div>
        </div>
        <div className='history_body'>
          <table className='table table-orders'>
            <thead className='table-heading'>
              <tr>
                <th>
                  <I18n tKey='common.pair' />
                </th>
                <th>
                  <I18n tKey='common.type' />
                </th>
                <th>
                  <I18n tKey='common.time_stamp' />
                </th>
                <th>
                  <I18n tKey='common.price' />
                </th>
                <th>
                  <I18n tKey='common.amount' />
                </th>
                <th>
                  <I18n tKey='common.total' />
                </th>
                <th>
                  <I18n tKey='common.fee' />
                </th>
              </tr>
            </thead>
            <tbody className='table-body'>
              {fetching && (
                <tr>
                  <td colSpan='7'>
                    <I18n tKey='common.loading' />
                  </td>
                </tr>
              )}
              {!fetching &&
                (this.state.tradeHistory || []).map((trade, i) => (
                  <tr key={i}>
                    <td>{this.renderMarketTitle(trade.marketId).replace('-', '/')}</td>
                    <td className={`${trade.side === 'SELL' ? 'red' : 'green'} type-line`}>{trade.side}</td>
                    <td>{moment(trade.matchedAt).format('MMM D YYYY, h:mm a')}</td>
                    <td>
                      <CurrencyView type='USDU' amount={trade.price ? trade.price : ''} />
                    </td>
                    <td>{trade.amount}</td>
                    <td>{new BigNumber(trade.price).multipliedBy(trade.amount).toNumber().toFixed(6)}</td>
                    <td>{trade.fee}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({
  WsRecentTradesReducer,
  ActiveMarketReducer,
  UserTradeHistoryReducer,
  MarketListReducer,
  SessionReducer,
}) => {
  return {WsRecentTradesReducer, ActiveMarketReducer, UserTradeHistoryReducer, MarketListReducer, SessionReducer};
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        tradeHistoryList: UserTradeHistory.action,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradesHistory);
