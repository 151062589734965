import * as React from 'react';
import Formatter from '../utils/formatter';
import I18n from './helpers/I18n';
import DatePicker from 'react-modern-calendar-datepicker';
import {translate} from '../utils/i18n';
import {connect} from 'react-redux';
import {TIME_DISPLAY_PATTERN} from '../utils/const';
import * as dict from '../utils/datePickerLocale';
import classNames from 'classnames';

// const CustomInputCalendar = ({value, error, formError, placeholderKey, ref}) => {

class VerificationInput extends React.PureComponent {
  state = {
    value: this.props.value,
    regex: this.props.regEx,
    isFocused: false,
  };

  focusInput = () => {
    this.setState({isFocused: true});
  };

  unFocusInput = () => {
    this.setState({isFocused: false});
  };

  handleChange = e => {
    if (this.props.regex) {
      let regex = new RegExp(this.props.regEx);
      const val = e.target.value;
      if (regex.test(e.target.value)) {
        this.props.cleanError && this.props.cleanError();
      } else {
        this.props.onError && this.props.onError();
      }
      this.setState({value: val});
      this.props.onChange && this.props.onChange(val);
    } else {
      const val = e.target.value;
      if (val.length > 0) {
        this.props.cleanError && this.props.cleanError();
      } else {
        this.props.onError && this.props.onError();
      }
      this.setState({value: val});
      this.props.onChange && this.props.onChange(val);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({value: nextProps.value});
    }
  }

  customInputCalendar = ({ref}) => {
    let formattedDate = '';
    if (this.props.value) {
      formattedDate = Formatter.verificationDate(this.props.value);
    }

    return (
      <label
        className={classNames('txt-input', {
          ['txt-input--with-title']: !!this.props.placeholder,
          ['txt-input--with-error']: this.props.error,
        })}
      >
        {this.props.placeholder && (
          <span
            className={classNames('txt-input__header', {
              ['txt-input__header--animated']:
                formattedDate || typeof formattedDate === 'number' || this.state.isFocused,
            })}
          >
            {this.props.placeholder}
          </span>
        )}

        <input
          type='text'
          readOnly={true}
          className='txt-input__field'
          onFocus={this.focusInput}
          onBlur={this.unFocusInput}
          ref={ref}
          value={formattedDate}
        />
      </label>
    );
  };

  render() {
    const {error, showDatePicker, ThemeReducer, LngReducer} = this.props;
    if (showDatePicker) {
      return (
        <DatePicker
          onChange={this.props.onChange}
          value={this.props.value}
          renderInput={this.customInputCalendar}
          wrapperClassName={ThemeReducer.darkTheme ? 'date-picker-custom-dark' : 'date-picker-custom-light'}
          colorPrimary='#7f63ff'
          calendarPopperPosition='bottom'
          locale={dict[LngReducer.lng] || 'en'}
        />
      );
    }
    return (
      <div className='ui-form-group'>
        <div
          className={`ui-form-input
          ${this.state.value ? 'focus' : ''}
          ${this.props.error || (this.props.formError && !this.props.value) ? 'error' : ''}
          ${!this.props.error && this.state.value ? 'success' : ''} `}
        >
          <input
            type='text'
            className='ui-form-control'
            onChange={this.handleChange}
            onFocus={this.props.closeAllDatePickers}
            value={this.state.value}
          />
          <div className='ui-form-control-focus' />
          <label className='ui-form-control-placeholder'>
            <I18n tKey={this.props.placeholder} />
          </label>
          {error && (
            <label className='ui-form-control-help'>
              <I18n tKey={error} />
            </label>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({LngReducer, ThemeReducer}) => ({
  LngReducer,
  ThemeReducer,
});

const mapDispatchToProps = () => {
  return {};
};
const VerificationInputConnect = connect(mapStateToProps, mapDispatchToProps)(VerificationInput);
export default VerificationInputConnect;
