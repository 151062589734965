import {ReactComponent as Usd} from '../../../../assets/images/balances/USD.svg';
import {ReactComponent as Eur} from '../../../../assets/images/balances/EUR.svg';
import defaultFiat from '../../../../assets/images/balances/default-fiat.png';

export const coinsSelectNames = {
  BTC: 'bitcoin',
  ETH: 'ethereum',
  MATIC: 'matic-network',
  USDT: 'tether',
  USDC: 'usd-coin',
  TRX: 'tron',
};

export const fontStyle = {
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '19px',
  letterSpacing: '0em',
  textAlign: 'left',
};

export const CHART_TYPE = {
  LINE: 'LINE',
  CANDLE: 'CANDLE',
};

export const CHART_PERIODS = [
  {id: 1, label: '24H', value: '1'},
  {id: 2, label: '7D', value: '7'},
  {id: 3, label: '14D', value: '14'},
  {id: 4, label: '30D', value: '30'},
  {id: 5, label: '90D', value: '90'},
  {id: 6, label: '180D', value: '180'},
];

export const PAY_OPTIONS = [
  {value: 'Itez (Bank Card)', label: 'Itez (Bank Card)'},
  // {value: 'Balance', label: 'Balance'},
  // {value: 'Third-party', label: 'Third-party'},
];

export const STEPS = {
  SELECT_PAIR_STEP: 'SELECT_PAIR_STEP',
  CONFIRMATION_STEP: 'CONFIRMATION_STEP',
};

export const OPERATION_TYPE = {
  BUY: 'BUY',
  SELL: 'SELL',
};

const labelMarkup = (fiat, icon) => {
  return (
    <div className='fast-exchange-block__currency-select-option'>
      {icon ? icon : <img src={defaultFiat} alt={fiat} />}
      <span className='fast-exchange-block__select-label'>{fiat}</span>
    </div>
  );
};

export const ALL_FIATS = [
  'USD',
  'EUR',
  'UAH',
  'INR',
  'KRW',
  'TRY',
  'GBP',
  'BRL',
  'AUD',
  'HKD',
  'TWD',
  'BYN',
  'AMD',
  'KZT',
  'PLN',
  'SGD',
  'VND',
  'SEK',
  'MDL',
  'AZN',
  'CAD',
  'DKK',
  'NOK',
  'HUF',
  'HRK',
  'RON',
  'CZK',
  'BGN',
  'CHF',
  'ILS',
  'SAR',
  'BHD',
  'NZD',
  'MYR',
  'RUB',
];

const CustomIcon = (symbol, size) => <div className={`fast-exchange-icon ${size ? size : null}`}>{symbol}</div>;

const icons = {
  USD: <Usd />,
  EUR: <Eur />,
  UAH: CustomIcon('\u20B4'),
  INR: CustomIcon('\u20B9'),
  KRW: CustomIcon('\u20A9'),
  TRY: CustomIcon('\u20A4'),
  GBP: CustomIcon('\u00A3'),
  BRL: CustomIcon('R\u0024', 'medium'),
  AUD: CustomIcon('A\u0024', 'medium'),
  HKD: CustomIcon('HK\u0024', 'small'),
  TWD: CustomIcon('NT\u0024', 'small'),
  BYN: CustomIcon('p.', 'medium'),
  AMD: CustomIcon('\u058F'),
  KZT: CustomIcon('\u20B8'),
  PLN: CustomIcon('zł', 'medium'),
  SGD: CustomIcon('S\u0024', 'medium'),
  VND: CustomIcon('\u20AB'),
  SEK: CustomIcon('kr', 'medium'),
  MDL: CustomIcon('L'),
  AZN: CustomIcon('\u20BC'),
  CAD: CustomIcon('C\u0024', 'medium'),
  DKK: CustomIcon('kr', 'medium'),
  NOK: CustomIcon('kr', 'medium'),
  HUF: CustomIcon('Ft', 'medium'),
  HRK: CustomIcon('kn', 'medium'),
  RON: CustomIcon('lei', 'small'),
  CZK: CustomIcon('Kč', 'medium'),
  BGN: CustomIcon('ЛВ', 'medium'),
  CHF: CustomIcon('\u20A3'),
  ILS: CustomIcon('\u20AA'),
  SAR: CustomIcon('\uFDFC', 'medium'),
  BHD: CustomIcon('BD', 'medium'),
  NZD: CustomIcon('NZ\u0024', 'small'),
  MYR: CustomIcon('RM', 'medium'),
  RUB: CustomIcon('\u20BD'),
};

export const FIAT_OPTIONS = [
  {
    label: labelMarkup('USD', icons.USD),
    id: 'usd',
    value: {id: 'USD'},
    min: 30,
    max: 5000,
    isFiat: true,
  },
  {
    label: labelMarkup('EUR', icons.EUR),
    id: 'eur',
    value: {id: 'EUR'},
    min: 30,
    max: 5000,
    isFiat: true,
  },
  {
    label: labelMarkup('UAH', icons.UAH),
    id: 'uah',
    value: {id: 'UAH'},
    min: 2000,
    max: 150000,
    isFiat: true,
  },
  {
    label: labelMarkup('INR', icons.INR),
    id: 'inr',
    value: {id: 'INR'},
    min: 5000,
    max: 400000,
    isFiat: true,
  },
  {
    label: labelMarkup('KRW', icons.KRW),
    id: 'krw',
    value: {id: 'KRW'},
    min: 100000,
    max: 20000000,
    isFiat: true,
  },
  {
    label: labelMarkup('TRY', icons.TRY),
    id: 'try',
    value: {id: 'TRY'},
    min: 1000,
    max: 50000,
    isFiat: true,
  },
  {
    label: labelMarkup('GBP', icons.GBP),
    id: 'gbp',
    value: {id: 'GBP'},
    min: 50,
    max: 5000,
    isFiat: true,
  },
  {
    label: labelMarkup('BRL', icons.BRL),
    id: 'brl',
    value: {id: 'BRL'},
    min: 500,
    max: 20000,
    isFiat: true,
  },
  {
    label: labelMarkup('AUD', icons.AUD),
    id: 'aud',
    value: {id: 'AUD'},
    min: 100,
    max: 10000,
    isFiat: true,
  },
  {
    label: labelMarkup('HKD', icons.HKD),
    id: 'hkd',
    value: {id: 'HKD'},
    min: 500,
    max: 500000,
    isFiat: true,
  },
  {
    label: labelMarkup('TWD', icons.TWD),
    id: 'twd',
    value: {id: 'TWD'},
    min: 2000,
    max: 150000,
    isFiat: true,
  },
  {
    label: labelMarkup('BYN', icons.BYN),
    id: 'blr',
    value: {id: 'BYN'},
    min: 200,
    max: 15000,
    isFiat: true,
  },
  {
    label: labelMarkup('AMD', icons.AMD),
    id: 'amd',
    value: {id: 'AMD'},
    min: 50000,
    max: 3000000,
    isFiat: true,
  },
  {
    label: labelMarkup('KZT', icons.KZT),
    id: 'kzt',
    value: {id: 'KZT'},
    min: 40000,
    max: 2000000,
    isFiat: true,
  },
  {
    label: labelMarkup('PLN', icons.PLN),
    id: 'pln',
    value: {id: 'PLN'},
    min: 300,
    max: 20000,
    isFiat: true,
  },
  {
    label: labelMarkup('SGD', icons.SGD),
    id: 'sgd',
    value: {id: 'SGD'},
    min: 100,
    max: 5000,
    isFiat: true,
  },
  {
    label: labelMarkup('VND', icons.VND),
    id: 'vnd',
    value: {id: 'VND'},
    min: 2000000,
    max: 150000000,
    isFiat: true,
  },
  {
    label: labelMarkup('SEK', icons.SEK),
    id: 'sek',
    value: {id: 'SEK'},
    min: 500,
    max: 50000,
    isFiat: true,
  },
  {
    label: labelMarkup('MDL', icons.MDL),
    id: 'mdl',
    value: {id: 'MDL'},
    min: 1000,
    max: 100000,
    isFiat: true,
  },
  {
    label: labelMarkup('AZN', icons.AZN),
    id: 'azn',
    value: {id: 'AZN'},
    min: 150,
    max: 10000,
    isFiat: true,
  },
  {
    label: labelMarkup('CAD', icons.CAD),
    id: 'cad',
    value: {id: 'CAD'},
    min: 100,
    max: 6000,
    isFiat: true,
  },
  {
    label: labelMarkup('DKK', icons.DKK),
    id: 'dkk',
    value: {id: 'DKK'},
    min: 500,
    max: 30000,
    isFiat: true,
  },
  {
    label: labelMarkup('NOK', icons.NOK),
    id: 'nok',
    value: {id: 'NOK'},
    min: 600,
    max: 45000,
    isFiat: true,
  },
  {
    label: labelMarkup('HUF', icons.HUF),
    id: 'huf',
    value: {id: 'HUF'},
    min: 20000,
    max: 1500000,
    isFiat: true,
  },
  {
    label: labelMarkup('HRK', icons.HRK),
    id: 'hrk',
    value: {id: 'HRK'},
    min: 500,
    max: 30000,
    isFiat: true,
  },
  {
    label: labelMarkup('RON', icons.RON),
    id: 'ron',
    value: {id: 'RON'},
    min: 300,
    max: 20000,
    isFiat: true,
  },
  {
    label: labelMarkup('CZK', icons.CZK),
    id: 'czk',
    value: {id: 'CZK'},
    min: 1500,
    max: 100000,
    isFiat: true,
  },
  {
    label: labelMarkup('BGN', icons.BGN),
    id: 'bgn',
    value: {id: 'BGN'},
    min: 150,
    max: 9000,
    isFiat: true,
  },
  {
    label: labelMarkup('CHF', icons.CHF),
    id: 'chf',
    value: {id: 'CHF'},
    min: 60,
    max: 5000,
    isFiat: true,
  },
  {
    label: labelMarkup('ILS', icons.ILS),
    id: 'ils',
    value: {id: 'ILS'},
    min: 200,
    max: 15000,
    isFiat: true,
  },
  {
    label: labelMarkup('SAR', icons.SAR),
    id: 'sar',
    value: {id: 'SAR'},
    min: 250,
    max: 20000,
    isFiat: true,
  },
  {
    label: labelMarkup('BHD', icons.BHD),
    id: 'bhd',
    value: {id: 'BHD'},
    min: 30,
    max: 2000,
    isFiat: true,
  },
  {
    label: labelMarkup('NZD', icons.NZD),
    id: 'nzd',
    value: {id: 'NZD'},
    min: 100,
    max: 8000,
    isFiat: true,
  },
  {
    label: labelMarkup('MYR', icons.MYR),
    id: 'myr',
    value: {id: 'MYR'},
    min: 250,
    max: 20000,
    isFiat: true,
  },
  {
    label: labelMarkup('RUB', icons.RUB),
    id: 'rub',
    value: {id: 'RUB'},
    min: 5000,
    max: 300000,
    isFiat: true,
  },
];
