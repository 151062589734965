import React, {Component} from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';

import I18n from '../../../../cmp/helpers/I18n';
import {CustomInput} from '../../../../cmp/helpers/CustomInput';
import VerificationInput from '../../../../cmp/VerificationInput';
import CheckBox from '../../../../cmp/helpers/CheckBox';
import {COUNTRIES} from '../../../../constants/countries';
import {translate} from '../../../../utils/i18n';
import {GENDER_OPTIONS, CUSTOM_SELECT_STYLES, SANCTIONS_OPTIONS, SELECT_THEME} from '../../constants';
import {mapCountrySelectOptions, prefillFormState, prepareForm} from '../../services';
import Header from '../Header';
import connect from './connect';
import Loader from '../../../../cmp/Loader';
import {STATUS} from '../../../../constants/sanctionCheckStatus';
import {URLMAP} from '../../../../utils/const';

class UserSanctionsForm extends Component {
  constructor(props) {
    super(props);
    const fields = prefillFormState(this.props.SanctionCheckReducer.info);

    this.state = {isLoading: false, requestError: '', ...fields};
  }

  selectCountryOptions = mapCountrySelectOptions(COUNTRIES);

  selectComponents = {
    IndicatorSeparator: () => null,
  };

  handleInputChange = (name, value) => {
    this.setState({[name]: {value, isError: false}, requestError: null});
  };

  handleDatePickerChange = value => {
    this.setState({dateOfBirth: {value, isError: false}, requestError: null});
  };

  handleGenderChange = ({value}) => {
    this.setState({gender: {value: [value], isError: false}, requestError: null});
  };

  handleProvideCorrectInfoChange = () => {
    this.setState({
      provideCorrectInfo: {value: !this.state.provideCorrectInfo.value, isError: false},
      requestError: null,
    });
  };

  handleSanctionCheckboxChange = ({value}) => {
    this.setState({userSanctionsCheckbox: {value: [value], isError: false}, requestError: null});
  };

  handleDocumentCountrySelect = item => {
    this.setState({documentCountryOfIssue: {value: item, isError: false}, requestError: null});
  };

  handleAddressCountrySelect = item => {
    this.setState({country: {value: item, isError: false}, requestError: null});
  };

  checkForm = () => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({isLoading: true, requestError: null});

    const stateCopy = {...this.state};

    for (const [field, body] of Object.entries(this.state)) {
      if (field !== 'isLoading' && field !== 'requestError') {
        const {value} = body;

        if (Array.isArray(value) && !value.length) {
          stateCopy[field] = {value, isError: true};
        } else if (!value) {
          stateCopy[field] = {value, isError: true};
        }
      }
    }

    const formHasError = Object.entries(stateCopy).some(([field, body]) => {
      if (field === 'isLoading' || field === 'requestError') {
        return false;
      }

      return body.isError;
    });

    if (formHasError) {
      this.setState({...stateCopy, isLoading: false, requestError: null});
    } else {
      const data = prepareForm(this.state);

      this.props.sendForm(data).then(({isError, data}) => {
        const result = {isLoading: false};

        if (isError) {
          result.requestError = data.error;
          this.setState(result);

          return;
        }

        if (data.status && data.status === STATUS.failed) {
          this.props.logout().then(r => {
            this.setState(result);
            this.props.history.push(URLMAP.SANCTIONS_BLOCK);
          });

          return;
        }

        this.setState(result);
      });
    }
  };

  sendForm = () => {
    this.checkForm();
  };

  render() {
    const selectedGender = this.state.gender.value[0];
    const selectedSanctionsCheckbox = this.state.userSanctionsCheckbox.value[0];

    return (
      <section className='sanctions-form-block'>
        <Header />

        <div className='form'>
          <Loader className='form-loader' open={this.state.isLoading} />
          <div className='form__section'>
            <h2 className='form__section-header'>
              <I18n tKey='sanctions_form.general_information_header' />
            </h2>
            <div className='form__section-content'>
              <CustomInput
                onChange={this.handleInputChange}
                isError={this.state.fName.isError}
                value={this.state.fName.value}
                name='fName'
                title='sanctions_form.first_name'
                maxLength={50}
              />

              <CustomInput
                onChange={this.handleInputChange}
                isError={this.state.lName.isError}
                value={this.state.lName.value}
                name='lName'
                title='sanctions_form.last_name'
                maxLength={50}
              />

              <VerificationInput
                showDatePicker
                placeholder={translate('sanctions_form.date_of_birth')}
                error={this.state.dateOfBirth.isError}
                value={this.state.dateOfBirth.value}
                onChange={this.handleDatePickerChange}
              />

              <div className='section-gender'>
                <CheckBox
                  onChange={this.handleGenderChange}
                  value={GENDER_OPTIONS.female}
                  isChecked={GENDER_OPTIONS.female === selectedGender}
                  label='sanctions_form.female'
                  hideOffset
                  id={GENDER_OPTIONS.female}
                  isError={this.state.gender.isError}
                />
                <CheckBox
                  onChange={this.handleGenderChange}
                  value={GENDER_OPTIONS.male}
                  isChecked={GENDER_OPTIONS.male === selectedGender}
                  label='sanctions_form.male'
                  hideOffset
                  id={GENDER_OPTIONS.male}
                  isError={this.state.gender.isError}
                />
              </div>

              <CustomInput
                onChange={this.handleInputChange}
                isError={this.state.email.isError}
                value={this.state.email.value}
                name='email'
                title='sanctions_form.email'
                maxLength={200}
              />

              <CustomInput
                onChange={this.handleInputChange}
                isError={this.state.phoneNumber.isError}
                value={this.state.phoneNumber.value}
                name='phoneNumber'
                title='sanctions_form.phone_number'
                useNumberValidation
                maxLength={20}
              />
            </div>
          </div>
          <div className='form__section'>
            <h2 className='form__section-header'>
              <I18n tKey='sanctions_form.document_header' />
            </h2>
            <div className='form__section-content'>
              <div
                className={classNames('form__select-wrapper', {
                  ['form__select-wrapper--error']: this.state.documentCountryOfIssue.isError,
                })}
              >
                <I18n className='form__select-header' tKey='sanctions_form.document_issue' />

                <Select
                  styles={CUSTOM_SELECT_STYLES}
                  components={this.selectComponents}
                  theme={SELECT_THEME}
                  isSearchable
                  noOptionsMessage={() => translate('common.not_found')}
                  placeholder={translate('common.search')}
                  name='documentAddressSelect'
                  options={this.selectCountryOptions}
                  onChange={this.handleDocumentCountrySelect}
                />
              </div>
            </div>
          </div>
          <div className='form__section'>
            <h2 className='form__section-header'>
              <I18n tKey='sanctions_form.address_header' />
            </h2>

            <div className='form__section-content'>
              <div
                className={classNames('form__select-wrapper', {
                  ['form__select-wrapper--error']: this.state.country.isError,
                })}
              >
                <I18n className='form__select-header' tKey='sanctions_form.document_issue' />

                <Select
                  options={this.selectCountryOptions}
                  isSearchable
                  name='countryAddressSelect'
                  noOptionsMessage={() => translate('common.not_found')}
                  placeholder={translate('common.search')}
                  theme={SELECT_THEME}
                  components={this.selectComponents}
                  styles={CUSTOM_SELECT_STYLES}
                  onChange={this.handleAddressCountrySelect}
                />
              </div>
              <CustomInput
                onChange={this.handleInputChange}
                isError={this.state.city.isError}
                value={this.state.city.value}
                name='city'
                title='sanctions_form.city'
                maxLength={100}
              />
              <CustomInput
                onChange={this.handleInputChange}
                isError={this.state.address.isError}
                value={this.state.address.value}
                name='address'
                title='sanctions_form.address'
                maxLength={200}
              />
              <CustomInput
                onChange={this.handleInputChange}
                isError={this.state.postalCode.isError}
                value={this.state.postalCode.value}
                name='postalCode'
                title='sanctions_form.postal_code'
                maxLength={100}
              />
            </div>
          </div>
          <div>
            <CheckBox
              usePreLine
              onChange={this.handleProvideCorrectInfoChange}
              value={this.state.provideCorrectInfo.value}
              id='informationProvided'
              label='sanctions_form.first_checkbox_description'
              isError={this.state.provideCorrectInfo.isError}
              isChecked={this.state.provideCorrectInfo.value}
            />
            <CheckBox
              usePreLine
              useHtmlParser
              onChange={this.handleSanctionCheckboxChange}
              value={SANCTIONS_OPTIONS.outOfList}
              id={SANCTIONS_OPTIONS.outOfList}
              label='sanctions_form.second_checkbox_description'
              isError={this.state.userSanctionsCheckbox.isError}
              isChecked={selectedSanctionsCheckbox === SANCTIONS_OPTIONS.outOfList}
            />
            <CheckBox
              usePreLine
              useHtmlParser
              onChange={this.handleSanctionCheckboxChange}
              value={SANCTIONS_OPTIONS.inList}
              id={SANCTIONS_OPTIONS.inList}
              label='sanctions_form.third_checkbox_description'
              isError={this.state.userSanctionsCheckbox.isError}
              isChecked={selectedSanctionsCheckbox === SANCTIONS_OPTIONS.inList}
            />
          </div>
          <div className='form__link-container'>
            <I18n tKey='sanctions_form:check_sanctions' />{' '}
            <a href='https://www.sanctionsmap.eu/#/main' target='_blank'>
              <I18n tKey='sanctions_form:link_label' />
            </a>
          </div>

          {this.state.requestError && (
            <div className='request-error'>
              <I18n tKey={this.state.requestError} />
            </div>
          )}

          <div className='form__footer'>
            <button
              disabled={this.state.isLoading}
              onClick={this.sendForm}
              type='button'
              className='btn btn-md btn-primary'
            >
              <I18n tKey='common.send' />
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(connect(UserSanctionsForm));
