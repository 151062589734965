import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import type {CurrencyTO, MarketTO} from '../../api/types/models';
import {SvgWrapper} from '../helpers/SvgWrapper';
import {ReactComponent as FavIc} from '../../../../assets/images/icon/favorite-icon.svg';
import React from 'react';
import {ReactComponent as SearchIc} from '../../../../assets/images/icon/search-n.svg';
import {ReactComponent as MoreIc} from '../../../../assets/images/icon/more-small-n.svg';
import {ReactComponent as Plus} from '../../../../assets/images/icon/plus.svg';
import {translate} from '../../utils/i18n';
import I18n from '../helpers/I18n';

class DropdownCur extends React.Component {
  displayName: 'DropdownCur';

  state = {
    activeCurrency: 'All',
  };

  addChart(market: MarketTO) {
    //const name = 'Chart' + market.id;

    const newItemConfig = {
      //title: '<div class="title">New <span class="caret"/> <span class="down"><span class="icon icon-arrow-bottom"/> 9,107.202</span></div>',
      title: '<div class="title">' + market.mBanner + '<span class="caret"/></div>',
      type: 'react-component',
      component: 'Chart',
    };

    this.props.layout.root.getItemsById('chart-panel')[0].addChild(newItemConfig);
    //this.props.layout.registerComponent(name, wrapComponent(ChartContainer, layout));

    $('.chart-panel').addClass('has-child');
  }

  render() {
    const {isAdd, isOpened, CurrencyListReducer, MarketListReducer} = this.props;
    if (!isOpened) return null;

    return (
      <div className={!isAdd ? 'currency-dropdown' : 'currency-dropdown right'}>
        <div className='currency-dropdown_search'>
          <div className='box-control'>
            <div className='form-control-icon'>
              <SvgWrapper themeWatch className='icon icon-search ic-size-02' SvgCmp={SearchIc} />
            </div>
            <input type='text' className='form-control' placeholder={translate('common.search')} />
          </div>
        </div>
        <div className='currency-dropdown_filter'>
          <div className='currency-dropdown_filter-item'>
            <SvgWrapper className='icon icon-star ic-size-02' SvgCmp={FavIc} />
          </div>
          <div
            className={`currency-dropdown_filter-item ${this.state.activeCurrency === 'All' ? 'active' : ''}`}
            onClick={() => this.setState({activeCurrency: 'All'})}
          >
            <I18n tKey='common.all' />
          </div>
          {(CurrencyListReducer.list || []).map((cur: CurrencyTO, i) => {
            return (
              <div
                key={i}
                className={`currency-dropdown_filter-item ${
                  this.state.activeCurrency === cur.mUCryptogram ? 'active' : ''
                }`}
                onClick={() => this.setState({activeCurrency: cur.mUCryptogram})}
              >
                {cur.mUCryptogram}
              </div>
            );
          })}
          <div className='currency-dropdown_filter-item'>
            <SvgWrapper themeWatch className='icon icon-more ic-size-02' SvgCmp={MoreIc} />
          </div>
        </div>
        <div className='currency-dropdown_list scrollbar-inner'>
          {(MarketListReducer.list || []).map((market: MarketTO, i) => (
            <div key={i} className='currency-dropdown_item add-chart' onClick={this.addChart.bind(this, market)}>
              <div className='name'>
                <SvgWrapper className='icon icon-star ic-size-02' SvgCmp={FavIc} />
                {market.leftMonetaryUnitCode}
                {market.rightMonetaryUnitCode}
                <span className='full-name'>{market.mBanner}</span>
              </div>
              <div className='action'>
                <span className='link'>
                  {isAdd && (
                    <span>
                      <SvgWrapper themeWatch className='icon icon-max ic-size-07' SvgCmp={Plus} />
                      <I18n tKey='common.add' />
                    </span>
                  )}
                  {!isAdd && <I18n tKey='common.set' />}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({MarketListReducer, CurrencyListReducer}) => {
  return {MarketListReducer, CurrencyListReducer};
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        /*toggleMarketInFavourite*/
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownCur);
