import DropdownCur from '../chart/DropdownCur';
import {ReactComponent as Minus} from '../../../../assets/images/icon/minus.svg';
import {SvgWrapper} from '../helpers/SvgWrapper';
import {ReactComponent as Plus} from '../../../../assets/images/icon/plus.svg';
import I18n from '../helpers/I18n';

class ModalAlert extends React.Component {
  displayName: 'ModalAlert';

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  render() {
    const {isShow} = this.props;
    return (
      <div className={isShow ? 'modal fade in' : 'modal fade'} style={{display: !this.state.isOpen ? 'none' : ''}}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <button type='button' className='btn close' onClick={() => this.setState({isOpen: !this.state.isOpen})} />
            <div className='modal-header'>
              <h3 className='title'>
                <I18n tKey='modal.alert_title' />
              </h3>
            </div>
            <div className='modal-form'>
              <div className='form-new-alert'>
                <div className='form-group-line'>
                  <div className='box-control'>
                    <div className='form-label'>
                      <I18n tKey='common.market' />
                    </div>
                    <div className='form-input'>
                      <input type='text' placeholder='0.0000' className='form-control' defaultValue='BTCUSD' />
                      <div className='box-control-button'>
                        <a href='/'>
                          <span className='caret' />
                        </a>
                      </div>
                      <DropdownCur isAdd={false} />
                    </div>
                  </div>
                  <div className='box-control'>
                    <div className='form-label'>
                      <I18n tKey='common.price' />
                    </div>
                    <div className='form-input'>
                      <input type='text' placeholder='0.0000' className='form-control ' defaultValue='9738.00' />
                      <div className='box-control-button'>
                        <a href='/'>
                          {' '}
                          <SvgWrapper themeWatch className='icon icon-min ic-size-07' SvgCmp={Minus} />
                        </a>
                        <a href='/'>
                          <SvgWrapper themeWatch className='icon icon-max ic-size-07' SvgCmp={Plus} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group-line'>
                  <div className='box-control'>
                    <div className='form-label'>
                      <I18n tKey='modal.cur_price' />
                    </div>
                    <div className='form-value'>
                      <span className='item-value'>
                        <I18n tKey='common.ask' />
                        <span className='value'>9,107.202</span>
                      </span>
                      <span className='item-value'>
                        <I18n tKey='common.bid' />
                        <span className='value'>9,107.379</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-settings'>
              <div className='form-group-line_title'>
                <I18n tKey='modal.notify_label' />
              </div>
              <div className='form-group-line'>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='radio' name='time' defaultValue='0' />
                    <span className='checkmark' />
                    <span>
                      <I18n tKey='modal.control_once' />
                    </span>
                  </label>
                </div>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='radio' name='time' defaultValue='1' />
                    <span className='checkmark' />
                    <span>
                      <I18n tKey='modal.control_every' />
                    </span>
                  </label>
                </div>
              </div>
              <div className='form-group-line_title'>
                <I18n tKey='modal.alert_label' />
              </div>
              <div className='form-group-line'>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' />
                    <span className='checkmark' />
                    <I18n tKey='modal.show_popup' />
                  </label>
                </div>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' />
                    <span className='checkmark' />
                    <I18n tKey='modal.play_sound' />
                  </label>
                </div>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' />
                    <span className='checkmark' />
                    <I18n tKey='modal.send_email' />
                  </label>
                </div>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' />
                    <span className='checkmark' />
                    <I18n tKey='modal.send_sms' />
                  </label>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='box-btn'>
                <a href='#' className='btn btn-primary btn-lg btn-full'>
                  <span className='upper'>
                    <I18n tKey='modal.set_alert' />
                  </span>
                </a>
              </div>
              <div className='box-btn'>
                <a href='#' className='link link-control'>
                  <I18n tKey='common.cancel' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalAlert;
