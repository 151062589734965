import {Fragment} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ReactComponent as TriangleIc} from '../../../../assets/images/icon/dropdown-small-n.svg';
import {ReactComponent as PagePrev} from '../../../../assets/images/icon/page-prev.svg';
import {ReactComponent as PageFirst} from '../../../../assets/images/icon/page-first.svg';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import {ReactComponent as CloseIc} from '../../../../assets/images/icon/close-gray.svg';
import I18n from '../../cmp/helpers/I18n';

class AllOpenOrders extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            {' '}
            <I18n tKey='balances.all_orders' />
          </div>
          <div className='tab-pane-filter'>
            <a className='tab-pane-filter-item'>
              <I18n tKey='balances.pain' />{' '}
              <SvgWrapper themeWatch className='icon icon-arrow-toggle ic-size-02' SvgCmp={TriangleIc} />
            </a>
          </div>
        </div>
        <div className='tab-pane-body'>
          <table className='table-orders'>
            <thead className='table-orders-header'>
              <tr>
                <th>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox10' />
                    <label htmlFor='checkbox10'></label>
                  </div>
                </th>
                <th>
                  <I18n tKey='common.order' />
                </th>
                <th>
                  <I18n tKey='common.date_time' />
                </th>
                <th>
                  <I18n tKey='common.pair' />
                </th>
                <th className='td text-center'>
                  <I18n tKey='common.type' />
                </th>
                <th className='td text-right'>
                  <I18n tKey='common.amount' />
                </th>
                <th className='td text-right'>
                  <I18n tKey='common.price' />
                </th>
                <th className='td text-right'>
                  <I18n tKey='common.total' />
                </th>
                <th className='td td-amount'>
                  <I18n tKey='common.amount_left' />
                </th>
                <th>
                  <I18n tKey='common.status' />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className='table-orders-body'>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox11' />
                    <label htmlFor='checkbox11'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center green'>
                  <I18n tKey='common.buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount green'>
                      <div className='block-amount-value' style={{width: '65%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox12' />
                    <label htmlFor='checkbox12'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center green'>
                  <I18n tKey='allopenorders:buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount green'>
                      <div className='block-amount-value' style={{width: '83%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox13' />
                    <label htmlFor='checkbox13'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center green'>
                  <I18n tKey='common.buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount green'>
                      <div className='block-amount-value' style={{width: '65%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox14' />
                    <label htmlFor='checkbox14'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center red'>
                  <I18n tKey='common.buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount red'>
                      <div className='block-amount-value' style={{width: '65%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox15' />
                    <label htmlFor='checkbox15'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center green'>
                  <I18n tKey='common.buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount green'>
                      <div className='block-amount-value' style={{width: '65%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox16' />
                    <label htmlFor='checkbox16'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center green'>
                  <I18n tKey='common.buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount green'>
                      <div className='block-amount-value' style={{width: '83%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox17' />
                    <label htmlFor='checkbox17'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center green'>
                  <I18n tKey='common.buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount green'>
                      <div className='block-amount-value' style={{width: '65%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox18' />
                    <label htmlFor='checkbox18'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center red'>
                  <I18n tKey='common.buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount red'>
                      <div className='block-amount-value' style={{width: '65%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox19' />
                    <label htmlFor='checkbox19'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center green'>
                  <I18n tKey='common.buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount green'>
                      <div className='block-amount-value' style={{width: '65%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ui-form-checkbox'>
                    <input type='checkbox' id='checkbox20' />
                    <label htmlFor='checkbox20'></label>
                  </div>
                </td>
                <td>0432594</td>
                <td>Oct 28 2017, 4:04 AM</td>
                <td>XPR/ETH</td>
                <td className='td text-center green'>
                  <I18n tKey='common.buy' />
                </td>
                <td className='td text-right'>0.51465488</td>
                <td className='td text-right'>1,020.3593</td>
                <td className='td text-right'>2.02937</td>
                <td className='td td-amount'>
                  <div className='td-flex'>
                    <div>0.5554846</div>
                    <div className='block-amount green'>
                      <div className='block-amount-value' style={{width: '83%'}}></div>
                    </div>
                  </div>
                </td>
                <td>83%</td>
                <td>
                  <span className='close-tr'>
                    <SvgWrapper className='icon icon-close-order ic-size-14' SvgCmp={CloseIc} />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className='table-pagination'>
            <div className='table-pagination-page'>
              <I18n tKey='common.pagination' />: <span>10</span>{' '}
              <SvgWrapper themeWatch className='icon icon-arrow-toggle ic-size-02' SvgCmp={TriangleIc} />
            </div>
            <div className='table-pagination-number'>
              <span>1-20</span> of <span>1000</span>
            </div>
            <div className='table-pagination-btn'>
              <a>
                <SvgWrapper themeWatch className='icon icon-prev-max ic-size-13' SvgCmp={PageFirst} />
              </a>
              <a>
                <SvgWrapper themeWatch className='icon icon-prev ic-size-12' SvgCmp={PagePrev} />
              </a>
              <a>
                <SvgWrapper themeWatch className='icon icon-next ic-size-12 ic-rotate-02' SvgCmp={PagePrev} />
              </a>
              <a>
                <SvgWrapper themeWatch className='icon icon-next-max ic-size-13 ic-rotate-02' SvgCmp={PageFirst} />
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({SessionReducer}) => ({SessionReducer});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({}, dispatch),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(AllOpenOrders));

export default connectedWithRouter;
