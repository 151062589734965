import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';

import {CLOSE_POPUP} from '../../../reducers/PopupReducer';
import I18n from '../../helpers/I18n';
import {OpenOrder} from '../../../api/Actions';
import {ReactComponent as ErrorIcon} from '../../../../../assets/images/icon/error.svg';

export const OPEN_ORDER_ERROR_POPUP = 'OPEN_ORDER_ERROR_POPUP';

class OpenOrderError extends PureComponent {
  constructor(props) {
    super(props);

    this.outsideClickRef = React.createRef();
    this.excludeBlockRef = React.createRef();
  }

  componentDidMount() {
    this.outsideClickRef.current.addEventListener('click', this.handleOutsideClick);
    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    this.outsideClickRef.current.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('keydown', this.handleKeyPress);

    this.props.clearOpenOrderError();
  }

  handleOutsideClick = ev => {
    if (!this.excludeBlockRef.current.contains(ev.target)) {
      this.props.close();
    }
  };

  handleKeyPress = ev => {
    if (ev.key === 'Escape') {
      ev.preventDefault();

      this.props.close();
    }
  };

  render() {
    return (
      <div ref={this.outsideClickRef} className='custom-modal'>
        <div ref={this.excludeBlockRef} className='modal-content order-create-error-modal'>
          <ErrorIcon />

          <h2>
            <I18n tKey='popup:order_create_error:header' />
          </h2>

          <div className='text-description text-info'>
            {this.props.OpenOrderReducer.error.error === 'USER_SANCTIONS' ? (
              <div>
                <I18n className='order-error-code' tKey='error:sanctions' />
              </div>
            ) : (
              <Fragment>
                {' '}
                <div>
                  <I18n className='order-error-code' tKey={this.props.OpenOrderReducer.error.error} />
                </div>
                {this.props.OpenOrderReducer.error.error !== 'OPEN_ORDER_NOT_ENOUGH_MONEY' && (
                  <span className='order-error-description'>{this.props.OpenOrderReducer.error.message}</span>
                )}
              </Fragment>
            )}
          </div>

          <button onClick={this.props.close} className='btn btn-purple btn-full size'>
            <I18n tKey='common:close' />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({OpenOrderReducer}) => ({
  OpenOrderReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    close: () => dispatch({type: CLOSE_POPUP}),
    clearOpenOrderError: () => dispatch({type: OpenOrder.events.onClear}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenOrderError);
