// @flow

import DropdownFilter from './DropdownFilter';
import {SvgWrapper} from './helpers/SvgWrapper';
import {ReactComponent as PageFirst} from '../../../assets/images/icon/page-first.svg';
import {ReactComponent as PagePrev} from '../../../assets/images/icon/page-prev.svg';
import React from 'react';
import I18n from './helpers/I18n';

const DEFAULT_LIMIT = 20;

class Paging extends React.Component {
  displayName: 'Paging';

  state = {
    offset: 0,
    limit: DEFAULT_LIMIT,
  };

  changeLimit = limit => {
    this.setState({limit, offset: 0}, () => {
      this.props.change(limit, this.state.offset);
    });
  };

  componentDidMount() {
    if (this.props.loadOnInit) {
      const limit = this.props.limit || this.state.limit;
      this.props.change(limit, 0);
    }
  }

  componentWillReceiveProps(n) {
    n.limit && n.limit !== this.props.limit && this.setState({limit: n.limit});
    n.offset !== undefined && n.offset !== this.props.offset && this.setState({offset: n.offset});
  }

  prevMax = () => {
    const limit = this.props.limit || this.state.limit;
    const offset = this.state.offset;
    if (offset > 0) {
      this.setState({offset: 0});
      this.props.change(limit, 0);
    }
  };

  prev = () => {
    const limit = this.props.limit || this.state.limit;
    const offset = this.state.offset;
    if (offset > 0) {
      this.setState({offset: offset - limit});
      this.props.change(limit, offset - limit);
    }
  };

  nextMax = () => {
    const totalElements = this.props.totalElements || 0;
    const limit = this.props.limit || this.state.limit;
    const offset = this.state.offset;
    if (offset + limit < totalElements) {
      const newOffset = Math.floor(totalElements / limit) * limit;
      this.setState({offset: newOffset});
      this.props.change(limit, newOffset);
    }
  };

  next = () => {
    const totalElements = this.props.totalElements || 0;
    const limit = this.props.limit || this.state.limit;
    const offset = this.state.offset;
    if (offset + limit < totalElements) {
      this.setState({offset: offset + limit});
      this.props.change(limit, offset + limit);
    }
  };

  render() {
    const totalElements = this.props.totalElements || 0;
    const limit = this.props.limit || this.state.limit;
    const offset = this.state.offset;
    return (
      <div className='table-pagination'>
        {this.props.onDownloadClick && (
          <div className='download-wrapper'>
            {this.props.isDownloading ? (
              <I18n tKey='common:loading' />
            ) : (
              <button
                disabled={this.props.isDownloading}
                onClick={this.props.onDownloadClick}
                className='download-button'
              >
                <I18n tKey='download:download_info' />
              </button>
            )}
            {this.props.isDownloadError && (
              <div className='error-download'>
                <I18n tKey='download:download_error' />
              </div>
            )}
          </div>
        )}
        <DropdownFilter
          title='common.pagination'
          defaultValue={limit}
          elements={[10, DEFAULT_LIMIT, 50, 100]}
          notNull={true}
          onChange={this.changeLimit}
        />
        <div className='table-pagination-number'>
          <span>
            {offset}-{totalElements < offset + limit ? totalElements : offset + limit}
          </span>{' '}
          of <span>{totalElements}</span>
        </div>
        <div className='table-pagination-btn'>
          {
            <a onClick={this.prevMax}>
              <SvgWrapper themeWatch className='icon icon-prev-max ic-size-13' SvgCmp={PageFirst} />
            </a>
          }
          {
            <a onClick={this.prev}>
              <SvgWrapper themeWatch className='icon icon-prev ic-size-12' SvgCmp={PagePrev} />
            </a>
          }
          {
            <a onClick={this.next}>
              <SvgWrapper themeWatch className='icon icon-next ic-size-12 ic-rotate-02' SvgCmp={PagePrev} />
            </a>
          }
          {
            <a onClick={this.nextMax}>
              <SvgWrapper themeWatch className='icon icon-next-max ic-size-13 ic-rotate-02' SvgCmp={PageFirst} />
            </a>
          }
        </div>
      </div>
    );
  }
}

export default Paging;
