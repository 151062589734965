import React from 'react';
import InputPopup from '../InputPopup';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {EnableAccountApiKey, DisableAccountApiKey, RevokeApiKey} from '../../../api/Actions';
import {translate} from '../../../utils/i18n';
import I18n from '../../helpers/I18n';

export const closeDeleteApiKeyPopup = () => $('#modal-key-delete').modal('hide');

export const REVOKE_KEY = 'REVOKE_KEY';
export const ENABLE_KEY = 'ENABLE_KEY';
export const DISABLE_KEY = 'DISABLE_KEY';

export const ENABLE_KEY_STATUS = 'Enable';
export const DISABLE_KEY_STATUS = 'Disable';

class DeleteApiKey extends React.Component {
  state = {
    code2FA: '',
    code2FAError: '',
    password: '',
    passwordError: '',
    error: '',
  };

  componentWillReceiveProps(n) {
    const reqError = n.RevokeApiKeyReducer.error || n.ApiKeyStateReducer.error;
    reqError ? this.setState({error: reqError.error}) : this.setState({error: ''});
  }

  toggleKey = () => {
    const is2Fa = this.props.SessionReducer.masterPersonality
      ? this.props.SessionReducer.masterPersonality.googleAuthAllowanceFlag
      : false;
    const {status, sid, enableKey, disableKey, revokeKey} = this.props;
    const {code2FA, password} = this.state;
    this.setState({code2FAError: null, passwordError: null});
    let hasError = null;
    if (is2Fa) {
      if (!code2FA) {
        this.setState({code2FAError: {parsedError: 'popup.enter_2fa'}});
        hasError = true;
      }
    } else {
      if (!password) {
        this.setState({passwordError: {parsedError: 'popup.enter_pswd'}});
        hasError = true;
      }
    }
    if (!hasError) {
      const passOrCode = is2Fa ? {tfCryptogram: code2FA} : {lockPickSymbolRow: password};
      if (status) {
        status === ENABLE_KEY_STATUS
          ? enableKey({curSidTs: sid, ...passOrCode})
          : disableKey({curSidTs: sid, ...passOrCode});
      } else {
        revokeKey({curSidTs: sid, ...passOrCode});
      }
    }
    this.setState({code2FA: '', password: ''});
  };

  cleanError = () => {
    this.state.code2FAError && this.setState({code2FAError: null});
    this.state.error && this.setState({error: null});
  };

  render() {
    const is2Fa = this.props.SessionReducer.masterPersonality
      ? this.props.SessionReducer.masterPersonality.googleAuthAllowanceFlag
      : false;
    const {
      status,
      keyLabel,
      sid,
      RevokeApiKeyReducer,
      ApiKeyStateReducer,
      EnableAccountApiKeyReducer,
      DisableAccountApiKeyReducer,
    } = this.props;
    const fetching = RevokeApiKeyReducer.fetching || ApiKeyStateReducer.fetching;
    const {error} = this.state;
    let passwordError = null;

    if (!!DisableAccountApiKeyReducer.error || !!EnableAccountApiKeyReducer.error) {
      const error = !!DisableAccountApiKeyReducer.error
        ? DisableAccountApiKeyReducer.error.error
        : EnableAccountApiKeyReducer.error.error;
      passwordError = error;
    }

    return (
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>
              {status === ENABLE_KEY_STATUS || status === DISABLE_KEY_STATUS ? status : <I18n tKey='common.delete' />}{' '}
              {keyLabel}
            </h2>
            <div className='text'>
              <I18n tKey='common.enter' /> {is2Fa ? <I18n tKey='popup.two_fa' /> : <I18n tKey='common.password' />},
              {status === ENABLE_KEY_STATUS || status === DISABLE_KEY_STATUS
                ? `${translate('common.to')} ${status.toLowerCase()} ${translate('common.api_key')}`
                : `${translate('popup.really_want')}`}
            </div>
          </div>
          <div className='modal-content_form'>
            <form className='ui-form'>
              {is2Fa ? (
                <InputPopup
                  placeholder='popup.two_fa'
                  onKeyDown={this.onKeyDown}
                  value={this.state.code2FA}
                  error={this.state.code2FAError}
                  toCleanError={this.cleanError}
                  onChange={value => this.setState({code2FA: value})}
                />
              ) : (
                <InputPopup
                  placeholder='common.password'
                  type='password'
                  onKeyDown={this.onKeyDown}
                  value={this.state.password}
                  error={this.state.passwordError}
                  toCleanError={this.cleanError}
                  onChange={value => this.setState({password: value})}
                />
              )}

              <div className='ui-form-group-btn'>
                {sid && (
                  <div className='ui-form-btn'>
                    {fetching && (
                      <a className='btn btn-primary btn-md'>
                        <span className='upper'>
                          <I18n tKey='common.loading' />
                        </span>
                      </a>
                    )}
                    {!fetching && (
                      <a className='btn btn-primary btn-md' onClick={this.toggleKey}>
                        <span className='upper'>
                          {status === ENABLE_KEY_STATUS || status === DISABLE_KEY_STATUS ? (
                            status
                          ) : (
                            <I18n tKey='common.delete' />
                          )}{' '}
                          <I18n tKey='common.key' />
                        </span>
                      </a>
                    )}
                    {error && <div className='popup-error-message common'>{error}</div>}
                  </div>
                )}
                <div className='ui-form-btn'>
                  <a data-dismiss='modal' className='btn-link'>
                    <I18n tKey='common.cancel' />
                  </a>
                </div>
              </div>
              {passwordError && (
                <div className='popup-error-message common red'>
                  {passwordError === 'INVALID_LOGIN_OR_PASSWORD' && <I18n tKey='popup.valid_pswd' />}
                  {passwordError === 'BAD_TWOFA_CODE' && <I18n tKey='popup.valid_2fa' />}
                  {passwordError !== 'INVALID_LOGIN_OR_PASSWORD' && passwordError !== 'BAD_TWOFA_CODE' && (
                    <span>{passwordError}</span>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  RevokeApiKeyReducer,
  ApiKeyStateReducer,
  SessionReducer,
  EnableAccountApiKeyReducer,
  DisableAccountApiKeyReducer,
}) => ({
  RevokeApiKeyReducer,
  ApiKeyStateReducer,
  SessionReducer,
  EnableAccountApiKeyReducer,
  DisableAccountApiKeyReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        revokeKey: RevokeApiKey.action,
        enableKey: EnableAccountApiKey.action,
        disableKey: DisableAccountApiKey.action,
      },
      dispatch
    ),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteApiKey));
