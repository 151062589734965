import {COUNTRIES} from '../../../constants/countries';
import {SANCTIONS_OPTIONS as ANCTIONS_OPTIONS} from '../constants';
import moment from 'moment';
import {TIME_DISPLAY_PATTERN} from '../../../utils/const';

export const mapCountrySelectOptions = oprions => {
  return oprions.map(({name, code}) => ({label: name, value: {name, code}}));
};

export const prefillFormState = (data = {}) => {
  const {
    address,
    addressCity,
    addressCountry,
    addressPostalCode,
    dateOfBirth,
    documentCountryOfIssue,
    email,
    firstName,
    gender,
    lastName,
    phoneNumber,
  } = data;

  return {
    address: {value: address || '', isError: false},
    city: {value: addressCity || '', isError: false},
    country: {value: findCountryByName(addressCountry), isError: false},
    postalCode: {value: addressPostalCode || '', isError: false},
    dateOfBirth: {value: convertDateFromUnix(dateOfBirth), isError: false},
    documentCountryOfIssue: {value: findCountryByName(documentCountryOfIssue), isError: false},
    email: {value: email || '', isError: false},
    fName: {value: firstName || '', isError: false},
    gender: {value: gender ? [gender] : [], isError: false},
    lName: {value: lastName || '', isError: false},
    phoneNumber: {value: phoneNumber || '', isError: false},
    provideCorrectInfo: {value: false, isError: false},
    userSanctionsCheckbox: {value: [], isError: false},
  };
};

const findCountryByName = searchName => {
  return mapCountrySelectOptions(COUNTRIES).find(({value}) => searchName === value.name) || null;
};

const convertDateFromUnix = date => {
  if (!date) {
    return '';
  }

  const momentDate = moment(date);

  //date.month() is zero based index;

  return {day: momentDate.date(), month: momentDate.month() + 1, year: momentDate.year()};
};

export const convertDateToUnix = date => {
  if (!date) {
    return 0;
  }

  const {day, month, year} = date;

  return moment(`${day}-${month}-${year}`, TIME_DISPLAY_PATTERN.STRING_PARSE_FORMAT).valueOf();
};

export const displayDateFromObject = date => {
  if (!date) {
    return '';
  }
  const {day, month, year} = date;

  return moment(`${day}-${month}-${year}`, TIME_DISPLAY_PATTERN.STRING_PARSE_FORMAT).format(
    TIME_DISPLAY_PATTERN.STRING_PARSE_FORMAT
  );
};

export const prepareForm = data => {
  const {
    address,
    city,
    country,
    postalCode,
    dateOfBirth,
    documentCountryOfIssue,
    email,
    fName,
    gender,
    lName,
    phoneNumber,
    provideCorrectInfo,
    userSanctionsCheckbox,
  } = data;

  return {
    checkbox1: provideCorrectInfo.value,
    checkbox2: userSanctionsCheckbox.value[0] === ANCTIONS_OPTIONS.outOfList,
    checkbox3: userSanctionsCheckbox.value[0] === ANCTIONS_OPTIONS.inList,
    info: {
      address: address.value,
      addressCity: city.value,
      addressCountry: country.value.label,
      addressPostalCode: postalCode.value,
      dateOfBirth: convertDateToUnix(dateOfBirth.value),
      documentCountryOfIssue: documentCountryOfIssue.value.label,
      email: email.value,
      firstName: fName.value,
      gender: gender.value[0],
      lastName: lName.value,
      phoneNumber: `+${phoneNumber.value}`,
    },
  };
};
