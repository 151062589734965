import storageHelper from 'storage-helper/src';

export const SWITCH_THEME = 'SWITCH_THEME';
const STORAGE_KEY = 'EXCHANGE_DARK_THEME';

const switchTheme = isEnabled => {
  const html = document.getElementsByTagName('html')[0];
  let classRemove = 'dark-theme';
  let classAdd = 'light-theme';
  if (isEnabled) {
    classRemove = 'light-theme';
    classAdd = 'dark-theme';
  }
  storageHelper.setItem(STORAGE_KEY, isEnabled);
  html.classList.remove(classRemove);
  html.classList.add(classAdd);
};

const darkTheme = storageHelper.getItem(STORAGE_KEY, true) || false;

switchTheme(darkTheme);

const initialState = {
  darkTheme: darkTheme,
};

export const ThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_THEME:
      switchTheme(!state.darkTheme);
      return {darkTheme: !state.darkTheme};
    default:
      return state;
  }
};
