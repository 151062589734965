export const getChartConfig = ({darkTheme, clientWidth, locale = 'en'}) => {
  return {
    height: 212,
    width: clientWidth,
    timeScale: {
      timeVisible: true,
    },
    layout: {
      fontSize: 10,
      fontFamily: 'IBM Plex Sans',

      ...(darkTheme && {background: {color: '#222'}, textColor: '#DDD'}: {}),
    },
    ...(darkTheme && {
      grid: {
        vertLines: {color: '#444'},
        horzLines: {color: '#444'},
      },
    }: {}),
    localization: {
      locale,
    },
  };
};
