import * as React from 'react';

import {ReactComponent as Usd} from '../../../../assets/images/balances/USD.svg';
import {ReactComponent as Bch} from '../../../../assets/images/balances/BCH.svg';
import {ReactComponent as Btc} from '../../../../assets/images/balances/BTC.svg';
import {ReactComponent as Eth} from '../../../../assets/images/balances/ETH.svg';
import {ReactComponent as Eur} from '../../../../assets/images/balances/EUR.svg';
import {ReactComponent as Ltc} from '../../../../assets/images/balances/LTC.svg';
import {ReactComponent as Usdt} from '../../../../assets/images/balances/USDT.svg';
import {ReactComponent as Untb} from '../../../../assets/images/balances/UNTB.svg';
import {ReactComponent as Cru} from '../../../../assets/images/balances/CRU.svg';
import {ReactComponent as Eos} from '../../../../assets/images/balances/EOS.svg';
import {ReactComponent as Usdu} from '../../../../assets/images/balances/USDU.svg';
import {ReactComponent as Unknown} from '../../../../assets/images/balances/Unknown.svg';
import {ReactComponent as Rub} from '../../../../assets/images/balances/RUB.svg';
import {ReactComponent as Usdc} from '../../../../assets/images/balances/USDC.svg';
import {ReactComponent as Dai} from '../../../../assets/images/balances/DAI.svg';
import {ReactComponent as Sushi} from '../../../../assets/images/balances/SUSHI.svg';
import {ReactComponent as Uni} from '../../../../assets/images/balances/UNI.svg';
import {ReactComponent as Link} from '../../../../assets/images/balances/LINK.svg';
import {ReactComponent as Wax} from '../../../../assets/images/balances/WAX.svg';
import {ReactComponent as XRP} from '../../../../assets/images/balances/XRP.svg';
import {ReactComponent as BNB} from '../../../../assets/images/balances/BNB.svg';
import {ReactComponent as TRON} from '../../../../assets/images/balances/TRON.svg';
import {ReactComponent as DOGE} from '../../../../assets/images/balances/DOGE.svg';
import {ReactComponent as MATIC} from '../../../../assets/images/balances/MATIC.svg';
import {ReactComponent as BOBC} from '../../../../assets/images/balances/BOBC.svg';
import {ReactComponent as DOT} from '../../../../assets/images/balances/DOT.svg';
import {ReactComponent as BUSD} from '../../../../assets/images/balances/BUSD.svg';
import {ReactComponent as SOL} from '../../../../assets/images/balances/SOL.svg';
import {SvgWrapper} from './SvgWrapper';
import {Fragment} from 'react';

const CurrencyIcons = {
  usd: Usd,
  usdt: Usdt,
  ltc: Ltc,
  btc: Btc,
  eth: Eth,
  untb: Untb,
  cru: Cru,
  eos: Eos,
  usdu: Usdu,
  eur: Eur,
  rub: Rub,
  usdc: Usdc,
  dai: Dai,
  sushi: Sushi,
  uni: Uni,
  link: Link,
  wax: Wax,
  xrp: XRP,
  bnb: BNB,
  trx: TRON,
  doge: DOGE,
  matic: MATIC,
  bobc: BOBC,
  dot: DOT,
  busd: BUSD,
  sol: SOL,
};
export const CurrencyIcon = ({curName, showCurName = true, hideCurName}) => {
  const currency = curName.toLowerCase();

  hideCurName ? (showCurName = false) : (showCurName = true);

  return (
    <Fragment>
      {CurrencyIcons[currency] ? (
        <SvgWrapper
          className={showCurName ? 'svg-icon-balances' : 'svg-icon-balances svg-without-text-icon-balances'}
          SvgCmp={CurrencyIcons[currency]}
        />
      ) : (
        <SvgWrapper className='svg-icon-balances' SvgCmp={Unknown} />
      )}
      {showCurName && curName}
    </Fragment>
  );
};
