import {Fragment} from 'react';
import {bindActionCreators} from 'redux';
import * as Actions from '../../api/Actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import type {UserActivityTO} from '../../api/types/models';
import Formatter from '../../utils/formatter';
import Paging from '../../cmp/Paging';
import DropdownFilter from '../../cmp/DropdownFilter';
import DateRangeFilter from '../../cmp/DateRangeFilter';
import {ReactComponent as LockIc} from '../../../../assets/images/icon/lock_close.svg';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import I18n from '../../cmp/helpers/I18n';

const types = [
  {label: 'SIGNUP', value: 'SIGNUP'},
  {label: 'LOGOUT', value: 'LOGOUT'},
  {label: 'PASSWORD_LOGIN', value: 'PASSWORD_LOGIN'},
  {label: 'GOOGLE_2FA_LOGIN', value: 'REGISTERED_NEW_IP'},
  {label: 'PASSWORD_RESET_REQUEST', value: 'PASSWORD_RESET_REQUEST'},
  {label: 'PASSWORD_RESET', value: 'PASSWORD_RESET'},
  {label: 'GOOGLE_2FA_START_ENABLE', value: 'GOOGLE_2FA_START_ENABLE'},
  {label: 'GOOGLE_2FA_ENABLE', value: 'GOOGLE_2FA_ENABLE'},
  {label: 'GOOGLE_2FA_START_DISABLE', value: 'GOOGLE_2FA_START_DISABLE'},
  {label: 'GOOGLE_2FA_DISABLE', value: 'GOOGLE_2FA_DISABLE'},
  {label: 'KYC_IDENTITY_VERIFICATION_SENT', value: 'KYC_IDENTITY_VERIFICATION_SENT'},
  {label: 'KYC_IDENTITY_VERIFICATION_REJECTED', value: 'KYC_IDENTITY_VERIFICATION_REJECTED'},
  {label: 'KYC_IDENTITY_VERIFICATION_PASSED', value: 'KYC_IDENTITY_VERIFICATION_PASSED'},
  {label: 'KYC_IDENTITY_VERIFICATION_BLOCKED', value: 'KYC_IDENTITY_VERIFICATION_BLOCKED'},
  {label: 'API_KEY_EMAIL_CONFIRMATION', value: 'API_KEY_EMAIL_CONFIRMATION'},
  {label: 'API_KEY_DISABLED', value: 'API_KEY_DISABLED'},
  {label: 'API_KEY_ENABLED', value: 'API_KEY_ENABLED'},
  {label: 'API_KEY_REVOKE_CONFIRMATION', value: 'API_KEY_REVOKE_CONFIRMATION'},
];

class ActivityLog extends React.PureComponent {
  state = {
    limit: null,
    offset: null,
    type: null,
    dateFrom: null,
    dateTo: null,
  };

  changePage = (limit, offset) => {
    this.setState({limit, offset}, this.search);
  };

  changeType = obj => {
    this.setState({type: obj ? obj.value : null}, this.search);
  };

  changeDateRange = ({dateFrom, dateTo}) => {
    this.setState({dateFrom, dateTo}, this.search);
  };

  search = () => {
    const {limit, offset, type, dateFrom, dateTo} = this.state;
    const params = {
      leftD: dateFrom,
      rightD: dateTo,
      rng: limit,
      targetT: type,
      listOfst: offset,
    };
    this.props.list(null, params);
  };

  render() {
    const {UserActivityHistoryReducer} = this.props;
    const list = UserActivityHistoryReducer.list || [];
    return (
      <Fragment>
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <I18n tKey='profile.activity_log' />
          </div>
          <div className='tab-pane-filter'>
            <DropdownFilter
              title='activity_log:activityType'
              elements={types}
              onChange={this.changeType}
              useTranslate={true}
            />
            <DateRangeFilter onChange={this.changeDateRange} />
          </div>
        </div>
        <div className='tab-pane-body'>
          <section className='activity'>
            <Paging
              totalElements={UserActivityHistoryReducer.totalElements}
              offset={this.state.offset}
              limit={this.state.limit}
              change={this.changePage}
              loadOnInit={true}
            />
            {!UserActivityHistoryReducer.fetching && (
              <div className='activity-list'>
                {list.map((log: UserActivityTO, i) => {
                  return (
                    <div className='activity-item' key={i}>
                      <div className='date'>{Formatter.dateTimeShort(log.time)}</div>
                      <div className='item-content'>
                        <div className='item-content-icon'>
                          <SvgWrapper
                            className={`icon ic-size-17 icon-lock ${
                              log.currentResT === 'SUCCESS' ? '' : 'ic-color-00'
                            }`}
                            SvgCmp={LockIc}
                          />
                        </div>
                        <div className='item-content-body'>
                          <div className='head'>
                            <div className='head-name'>
                              <I18n tKey={log.currentT} />
                            </div>

                            <div className='head-ip'>
                              {log.motherland} | {log.urbanArea} | IP: {log.intProtAddr}
                            </div>
                          </div>
                          <div className='text'>{log.userAddInform}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {UserActivityHistoryReducer.fetching && (
              <div className='activity-loading'>
                <I18n tKey='common.loading' />
              </div>
            )}
          </section>
          <Paging
            totalElements={UserActivityHistoryReducer.totalElements}
            offset={this.state.offset}
            limit={this.state.limit}
            change={this.changePage}
            loadOnInit={false}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({UserActivityHistoryReducer}) => ({UserActivityHistoryReducer});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        list: Actions.UserActivityHistory.action,
      },
      dispatch
    ),
  };
};
const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityLog));

export default connectedWithRouter;
