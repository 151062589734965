import {Link} from 'react-router-dom';
import NotificationCenter from '../cmp/notification/index';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import type {NotificationType} from '../reducers/NotificationReducer';
import React, {Fragment} from 'react';
import {withRouter} from 'react-router';
import {OPEN_POPUP} from '../reducers/PopupReducer';
import {ENABLE_2FA_STEP1_POPUP} from '../cmp/popups/top/Enable2FAStep1Popup';
import storageHelper from 'storage-helper';
import type {UserInfoTO} from '../api/types/models';
import {ReactComponent as DsbrdIc} from '../../../assets/images/nav/dashboard.svg';
import {ReactComponent as StockIc} from '../../../assets/images/nav/stock.svg';
import {ReactComponent as SettingsIc} from '../../../assets/images/nav/settings.svg';
import {ReactComponent as ExchangeIc} from '../../../assets/images/nav/exchange.svg';
import {ReactComponent as NotifIc} from '../../../assets/images/nav/notifications.svg';
import {ReactComponent as QuestionIc} from '../../../assets/images/nav/questmark.svg';
import {ReactComponent as PlayIc} from '../../../assets/images/nav/playlight.svg';
import {ReactComponent as BalanceIc} from '../../../assets/images/nav/ballances.svg';
import {ReactComponent as LogoutIc} from '../../../assets/images/nav/logout.svg';
import {ReactComponent as DocumentIcon} from '../../../assets/images/documents-history/document.svg';
import {ReactComponent as AdaptiveIcon} from '../../../assets/images/icon/adaptive.svg';

import {SvgWrapper} from '../cmp/helpers/SvgWrapper';
import Logo from '../cmp/Logo';
import {GetDocumentRequests, UserLogout} from '../api/Actions';
import I18n from '../cmp/helpers/I18n';
import HoverLeftMenu from '../cmp/helpers/HoverLeftMenu';
import {ADAPTIVE_DESIGN_ENABLED, mobilePath, URLMAP} from '../utils/const';

export const NOTIFY_2FA_DISABLED = 'NOTIFY_2FA_DISABLED';

class LeftbarCmp extends React.Component {
  displayName: 'Leftbar';

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isShowDocumentsHistory: false,
      isShowNotification: false,
    };
    this.toggleContainer = React.createRef();
    this.onClickHandlerDocuments = this.onClickHandlerDocuments.bind(this);
    this.onClickHandlerNotification = this.onClickHandlerNotification.bind(this);
    this.onClickOutsideHandlerNotification = this.onClickOutsideHandlerNotification.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.notificationContent = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {
      SessionReducer: {masterPersonality: user},
      documentRequests,
      NotificationReducer: {notifications},
      getDocumentRequests,
    } = this.props;

    if (
      !prevProps.SessionReducer.masterPersonality &&
      user &&
      !Object.keys(documentRequests.data).length &&
      !documentRequests.fetching
    ) {
      getDocumentRequests();
      return;
    }

    if (prevProps.NotificationReducer.notifications !== notifications) {
      const difference = notifications.filter(item => {
        return !prevProps.NotificationReducer.notifications.some(prevItem => {
          return prevItem.identificatSymbol === item.identificatSymbol;
        });
      });

      if (difference.some(item => item.currentMsgT === 'DOCUMENT_REQUEST') && !documentRequests.fetching) {
        getDocumentRequests();
      }
    }
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    const user: UserInfoTO = nextProps.SessionReducer.masterPersonality || {};
    const popupReducer = nextProps.PopupReducer;
    const notifyIsDisabled = JSON.parse(storageHelper.getItem(NOTIFY_2FA_DISABLED));
    if (user.uniqueSymbolRow && !user.googleAuthAllowanceFlag && !popupReducer.name && !notifyIsDisabled) {
      setTimeout(() => {
        !popupReducer.name && user.uniqueSymbolRow && this.props.open2FAStep1Popup();
      }, 1000);
    }
  }

  shouldComponentUpdate(o) {
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.onClickOutsideHandlerNotification);
  }

  onClickHandlerDocuments() {
    this.setState(state => ({
      isShowDocumentsHistory: !state.isShowDocumentsHistory,
    }));
  }

  onClickHandlerNotification() {
    if (this.state.isShowNotification) {
      window.removeEventListener('mousedown', this.onClickOutsideHandlerNotification);
    } else {
      window.addEventListener('mousedown', this.onClickOutsideHandlerNotification);
    }
    this.setState(currentState => ({
      isShowNotification: !currentState.isShowNotification,
    }));
  }

  onClickOutsideHandlerNotification(event) {
    if (
      this.state.isShowNotification &&
      !this.toggleContainer.current.contains(event.target) &&
      !this.notificationContent.current.contains(event.target)
    ) {
      this.onClickHandlerNotification();
    }
  }

  onMouseLeaveHandler() {
    this.setState({isOpen: false});
  }

  onNotificationCenterToggle = () => {
    this.setState(state => ({isShowNotification: !state.isShowNotification}));
  };

  handleAdaptiveClick = () => {
    localStorage.setItem(ADAPTIVE_DESIGN_ENABLED, JSON.stringify(true));
    window.location.replace(`${window.location.origin}${mobilePath}`);
  };

  render() {
    const url = window.location.pathname;
    const notifications = this.props.NotificationReducer.notifications || [];
    const unreadNotificationsCount = notifications.filter((n: NotificationType) => !n.readFlag).length;
    const pendingDocumentRequestsCount = (this.props.documentRequests.data.responseList || []).filter(
      request => request.status === 'PENDING'
    ).length;
    const user: UserInfoTO = this.props.SessionReducer.masterPersonality || {};

    return (
      <section className={!this.state.isOpen ? 'leftbar' : 'leftbar open'}>
        <div className='leftbar-nav' onMouseLeave={this.onMouseLeaveHandler}>
          <ul>
            <li>
              <div className='logo' onClick={() => this.setState({isOpen: !this.state.isOpen})}>
                <Logo isShort isMonoColor className='ic-size-00' />
              </div>
            </li>

            <li className={url === URLMAP.EXCHANGE ? 'active' : ''}>
              <Link className='link-container' to={URLMAP.EXCHANGE}>
                <SvgWrapper themeWatch className='icon icon-menu-exchange ic-size-06' SvgCmp={ExchangeIc} />
                <HoverLeftMenu tKey='common.exchange' visible={!this.state.isOpen} />
                <div className='menu-text'>
                  <I18n tKey='common.exchange' />
                </div>
              </Link>
            </li>

            <li className={url === '' || url === '/' ? 'active' : ''}>
              <Link className='link-container' to={URLMAP.TERMINAL}>
                <SvgWrapper themeWatch className='icon icon-menu-stock ic-size-06' SvgCmp={StockIc} />
                <HoverLeftMenu tKey='common.trade' visible={!this.state.isOpen} />
                <div className='menu-text'>
                  <I18n tKey='common.trade' />
                </div>
              </Link>
            </li>
            {/*<li className={url === URLMAP.MARKETS ? "active" : ""}>*/}
            {/*<Link to={URLMAP.MARKETS}>*/}
            {/*<i className="icon icon-menu-markets"/>*/}
            {/*<div className="menu-text">Markets</div>*/}
            {/*</Link>*/}
            {/*</li>*/}

            {user.uniqueSymbolRow && (
              <li className={url.startsWith(URLMAP.BALANCES) ? 'active' : ''}>
                <Link className='link-container' to={URLMAP.BALANCES}>
                  <SvgWrapper themeWatch className='icon icon-menu-ballances ic-size-06' SvgCmp={BalanceIc} />
                  <HoverLeftMenu tKey='common.balances' visible={!this.state.isOpen} />
                  <div className='menu-text'>
                    <I18n tKey='common.balances' />
                  </div>
                </Link>
              </li>
            )}
            {user.uniqueSymbolRow && (
              <li className={url === URLMAP.DASHBOARD ? 'active' : ''}>
                <Link className='link-container' to={URLMAP.DASHBOARD}>
                  <SvgWrapper themeWatch className='icon icon-menu-dashboard ic-size-06' SvgCmp={DsbrdIc} />
                  <HoverLeftMenu tKey='common.dashboard' visible={!this.state.isOpen} />
                  <div className='menu-text'>
                    <I18n tKey='common.dashboard' />
                  </div>
                </Link>
              </li>
            )}
          </ul>
          <ul>
            {user.uniqueSymbolRow && (
              <li>
                <Link className='link-container' to={URLMAP.DOCUMENTS_HISTORY}>
                  <SvgWrapper themeWatch className='icon leftbar-nav_documents-icon ic-size-06' SvgCmp={DocumentIcon} />
                  {pendingDocumentRequestsCount > 0 && (
                    <span className='new-message'>{pendingDocumentRequestsCount}</span>
                  )}
                  <HoverLeftMenu tKey='documents:documents_key' visible={!this.state.isOpen} />
                </Link>
              </li>
            )}
            {/*<li>*/}
            {/*  <a>*/}
            {/*    <SvgWrapper themeWatch className="icon icon-menu-help ic-size-06" SvgCmp={HelpIc}/>*/}
            {/*    <div className="menu-text">Help center</div>*/}
            {/*  </a>*/}
            {/*</li>*/}
            {user.uniqueSymbolRow && (
              <li>
                <a className='link-container' ref={this.toggleContainer} onClick={this.onClickHandlerNotification}>
                  <SvgWrapper themeWatch className='icon icon-menu-notification ic-size-06' SvgCmp={NotifIc} />
                  {unreadNotificationsCount > 0 && <span className='new-message'>{unreadNotificationsCount}</span>}
                  <HoverLeftMenu tKey='notifications:notifications_key:leftbar' visible={!this.state.isOpen} />
                  <div className='menu-text'>
                    <I18n tKey='notifications:notifications_key:leftbar' />{' '}
                    {unreadNotificationsCount > 0 && <Fragment>{unreadNotificationsCount}</Fragment>}
                  </div>
                </a>
              </li>
            )}
            {user.uniqueSymbolRow && (
              <li className={url.startsWith(URLMAP.PROFILE) ? 'active' : ''}>
                <Link className='link-container' to={URLMAP.PROFILE}>
                  <SvgWrapper themeWatch className='icon icon-menu-settings ic-size-06' SvgCmp={SettingsIc} />
                  <HoverLeftMenu tKey='common.settings' visible={!this.state.isOpen} />
                  <div className='menu-text'>
                    <I18n tKey='common.settings' />
                  </div>
                </Link>
              </li>
            )}

            <li>
              <div className='link-container leftbar-nav_faq'>
                <SvgWrapper
                  themeWatch
                  className='icon icon-menu-logout leftbar-nav_faq_icon ic-size-06'
                  SvgCmp={QuestionIc}
                />
                <HoverLeftMenu tKey='common.issue' big={true} visible={!this.state.isOpen} />
                <div className='menu-text'>
                  <I18n tKey='common.issue' />
                </div>
              </div>
            </li>

            {user.uniqueSymbolRow && (
              <li onClick={this.props.logout}>
                <a className='link-container'>
                  <SvgWrapper themeWatch className='icon icon-menu-logout ic-size-06' SvgCmp={LogoutIc} />
                  <HoverLeftMenu tKey='common.logout' visible={!this.state.isOpen} />
                  <div className='menu-text'>
                    <I18n tKey='common.logout' />
                  </div>
                </a>
              </li>
            )}

            {isMobile && (
              <li className='adaptive-btn' onClick={this.handleAdaptiveClick}>
                <div className='adaptive-btn__icon-wrapper'>
                  <AdaptiveIcon width={22} height={22} />
                </div>

                <div className='menu-text'>
                  <I18n tKey='adaptive-btn:description:desktop' />
                </div>
              </li>
            )}
          </ul>
        </div>
        <NotificationCenter
          isOpen={this.state.isShowNotification}
          notificationContentRef={this.notificationContent}
          onClose={this.onNotificationCenterToggle}
        />
      </section>
    );
  }
}

const mapStateToProps = ({NotificationReducer, SessionReducer, PopupReducer, documentRequestsReducer}) => {
  return {
    NotificationReducer,
    SessionReducer,
    PopupReducer,
    documentRequests: documentRequestsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        logout: UserLogout.action,
        getDocumentRequests: GetDocumentRequests.action,
      },
      dispatch
    ),
    open2FAStep1Popup: () =>
      dispatch({
        type: OPEN_POPUP,
        name: ENABLE_2FA_STEP1_POPUP,
      }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftbarCmp));
