import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import I18n from '../../../../cmp/helpers/I18n';

class FrozenBySanctions extends Component {
  render() {
    return (
      <div className='sanctions-frozen-block'>
        <div className='sanctions-frozen-block__content'>
          <span>
            <I18n tKey='sanctions_account_restrictions:description' />
          </span>{' '}
          <Link to='/page/help-center/accounts'>
            <I18n tKey='sanctions_account_restrictions:link_label' />
          </Link>
        </div>
      </div>
    );
  }
}

export default FrozenBySanctions;
