import React from 'react';
import {connect} from 'react-redux';

const CustomAxisLabel = props => {
  const {x, y, payload, ThemeReducer} = props;
  const {darkTheme} = ThemeReducer;

  return (
    <g transform={`translate(${x},${y})`}>
      <text className='axis-label' fontSize={10} textAnchor='middle' fill={darkTheme ? '#cdd2d6' : '#666'}>
        {payload.value} $
      </text>
    </g>
  );
};

const mapStateToProps = ({ThemeReducer}) => {
  return {
    ThemeReducer,
  };
};

export default connect(mapStateToProps, undefined)(CustomAxisLabel);
