import React, {Component} from 'react';
import {changeFormat} from '../../utils/utils';
import {CURRENCIES_DIGITS} from '../../utils/currenciesDigits';
import {NUMBER_FORMATS} from '../../utils/const';

export class CurrencyHighlightView extends Component {
  returnFormattedAmount = amount => {
    const {type = '', isFiat, appearance} = this.props;
    let digits = 8;
    // if (isFiat || appearance === 'FIAT') {
    //   digits = CURRENCIES_DIGITS.get('FIAT');
    // } else {
    //   digits = CURRENCIES_DIGITS.get(type.toUpperCase()) || 0;
    // }
    return changeFormat(amount, digits, NUMBER_FORMATS.emptySeparator);
  };

  createIterableObject = (diffArrayLength, price) => {
    let diff, originalValue;
    diff = Array.from(new Array(diffArrayLength)).map(el => ({value: 0, additional: true}));
    originalValue = Array.from(new Array(price.length)).map((el, i) => ({value: price[i]}));
    return [...diff, ...originalValue];
  };

  highlightChanges = (prevPrice, price) => {
    if (isNaN(prevPrice)) return (price || 'NaN').split('');

    let concatPrisesArr;

    if (price.length > prevPrice.length) {
      concatPrisesArr = this.createIterableObject(price.length - prevPrice.length, prevPrice);
    } else if (price.length < prevPrice.length) {
      concatPrisesArr = this.createIterableObject(prevPrice.length - price.length, price);
    } else {
      concatPrisesArr = price.split('');
    }

    return concatPrisesArr.reduce((accumulator, curVal, i, arr) => {
      !concatPrisesArr[i].additional &&
        accumulator.push({
          value: concatPrisesArr[i].value || concatPrisesArr[i],
          highlightChanges: concatPrisesArr[i] === prevPrice[i] || concatPrisesArr[i].value === prevPrice[i],
        });
      return accumulator;
    }, []);
  };

  render() {
    const {prevPrice = '', price = ''} = this.props;
    const formattedPrice = this.returnFormattedAmount(price),
      formattedPrevPrice = this.returnFormattedAmount(prevPrice);
    const itemsForRender = this.highlightChanges(formattedPrevPrice, formattedPrice);
    return (
      <span>
        {/* {itemsForRender.map((el, i) => <span className={(el.highlightChanges === undefined || el.highlightChanges) ? "price-opacity" : ""} key={i}>{el.value || el}</span>)} */}
        {itemsForRender.map((el, i) => (
          <span key={i}>{el.value || el}</span>
        ))}
      </span>
    );
  }
}
