import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {GetReferralCode, UserRegister} from '../../api/Actions';
import {CLOSE_POPUP, OPEN_POPUP} from '../../reducers/PopupReducer';
import {FORGOT_PASSWORD_POPUP} from '../../cmp/popups/right/ForgotPasswordPopup';

const mapStateToProps = ({
  UserRegisterReducer,
  ApplicationSettingsReducer,
  GetReferralCodeReducer,
  SessionReducer,
}) => ({UserRegisterReducer, ApplicationSettingsReducer, GetReferralCodeReducer, SessionReducer});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        register: UserRegister.action,
        getReferral: GetReferralCode.action,
      },
      dispatch
    ),
    registrationClear: () => dispatch({type: UserRegister.events.onClear}),
    openForgotPasswordPopup: () => dispatch({type: OPEN_POPUP, name: FORGOT_PASSWORD_POPUP}),
    closePopup: () => dispatch({type: CLOSE_POPUP}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
