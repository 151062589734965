import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import I18n from '../../../../cmp/helpers/I18n';
import {CloseOrder, OrderStopList} from '../../../../api/Actions';
import StopOrdersListHeader from './StopOrdersListHeader';
import StopOrderRow from './StopOrderRow';
import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import {ReactComponent as TriangleIc} from '../../../../../../assets/images/icon/dropdown-small-n.svg';
import {ReactComponent as ArrowDown} from '../../../../../../assets/images/icon/dropdown-mark.svg';
import classNames from 'classnames';

const GROUPS = {
  STOP_WAITING: 'STOP_WAITING',
  STOP_DONE: 'STOP_DONE',
  STOP_CANCELED: 'STOP_CANCELED',
  STOP_ERRORED: 'STOP_ERRORED',
};

class StopOrders extends Component {
  state = {
    isCancelOrderPopupShow: false,
    currentCancelOrder: null,
    openedGroups: {[GROUPS.STOP_WAITING]: true},
  };

  componentDidMount() {
    this.props.getOrderStopList({});
  }

  openGroup = group => () => {
    const isOpen = !!this.state.openedGroups[group];

    this.setState({openedGroups: {...this.state.openedGroups, [group]: !isOpen}});
  };

  showCancelOrderPopup = order => () => {
    this.setState({
      isCancelOrderPopupShow: true,
      currentCancelOrder: order,
    });
  };

  cancelOrder = () => {
    this.setState({
      isCancelOrderPopupShow: false,
      currentCancelOrder: null,
    });

    this.props.cancelOrder(this.state.currentCancelOrder).then(() => {
      this.props.getOrderStopList({});
    });
  };

  render() {
    const {OrderStopListReducer} = this.props;
    const orderStopList = OrderStopListReducer.data ? OrderStopListReducer.data.publicElements || [] : [];

    const groupedOrders = orderStopList.reduce(
      (acc, curValue) => {
        if (curValue.status === GROUPS.STOP_WAITING) {
          acc[0].list.push(curValue);
        }
        if (curValue.status === GROUPS.STOP_DONE) {
          acc[1].list.push(curValue);
        }
        if (curValue.status === GROUPS.STOP_CANCELED) {
          acc[2].list.push(curValue);
        }
        if (curValue.status === GROUPS.STOP_ERRORED) {
          acc[3].list.push(curValue);
        }

        return acc;
      },
      [
        {list: [], group: GROUPS.STOP_WAITING},
        {list: [], group: GROUPS.STOP_DONE},
        {list: [], group: GROUPS.STOP_CANCELED},
        {list: [], group: GROUPS.STOP_ERRORED},
      ]
    );

    return (
      <section className='history'>
        {this.state.isCancelOrderPopupShow && (
          <div className='close-order-modal'>
            <span className='close-order-label'>
              <I18n tKey='popup.cancel_title' />
            </span>
            <div className='buttons-container'>
              <button className='btn btn-purple btn-full size' onClick={this.cancelOrder}>
                <I18n tKey='common.yes' />
              </button>
              <button
                className='btn btn-link btn-full size'
                onClick={() => {
                  this.setState({
                    isCancelOrderPopupShow: false,
                    currentCancelOrder: null,
                  });
                }}
              >
                <I18n tKey='common.no' />
              </button>
            </div>
          </div>
        )}
        <div className='history_body scrollbar-inner'>
          <table className='table table-orders'>
            <StopOrdersListHeader />
            {groupedOrders.map(({group, list}) => {
              if (list.length === 0) {
                return null;
              }

              return (
                <Fragment key={group}>
                  <tbody className={'table-body market-toggler'} onClick={this.openGroup(group)}>
                    <tr className={`open-next ${this.state.openedGroups[group] ? 'open' : ''}`}>
                      <td className='toggle'>
                        <SvgWrapper themeWatch className='icon icon-arrow-toggle ic-size-02' SvgCmp={TriangleIc} />
                        {
                          <I18n
                            className={classNames({
                              ['red']: group === GROUPS.STOP_ERRORED,
                              ['green']: group === GROUPS.STOP_DONE,
                              ['yellow']: group === GROUPS.STOP_CANCELED,
                            })}
                            tKey={`stop_order:status:${group.toLowerCase()}`}
                          />
                        }
                        <span>· {list.length}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody className={`table-body order-list ${this.state.openedGroups[group] ? '' : 'd-none'}`}>
                    {list.map(order => (
                      <StopOrderRow
                        showCancelOrderPopup={group === GROUPS.STOP_WAITING ? this.showCancelOrderPopup : undefined}
                        order={order}
                        key={order.id}
                      />
                    ))}
                  </tbody>
                </Fragment>
              );
            })}
          </table>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({OrderStopListReducer}) => {
  return {
    OrderStopListReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getOrderStopList: OrderStopList.action,
        cancelOrder: CloseOrder.action,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StopOrders);
