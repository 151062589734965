import React from 'react';
import {SvgWrapper} from '../helpers/SvgWrapper';
import {ReactComponent as OrderSvg} from '../../../../assets/images/icon/one-col.svg';
import {ReactComponent as imgClose} from '../../../../assets/images/icon/close.svg';
import {CheckBox} from './CheckBox';
import {Radio} from './Radio';
import I18n from '../helpers/I18n';
import {translate} from '../../utils/i18n';

const returnBoxes = () => {
  const returnOption = (label, name, propName, unitCode) => ({label: translate(label), name, propName, unitCode});
  return [
    {
      left: returnOption('common.count', 'count', null, 'left'),
      right: returnOption('common.price', 'price', null, 'right'),
    },
    {
      left: returnOption('common.amount', 'amount', null, 'left'),
      right: returnOption('common.total', 'total', null, 'right'),
    },
  ];
};

export const ORDER_MODE = {
  ROW: 'ROW',
  COLUMN: 'COLUMN',
};

export const total_amount_option = 'totalAmount';
export const sum_option = 'sum';

class ModalOrderBook extends React.Component {
  displayName: 'ModalOrderBook';

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.boxes = returnBoxes();
  }

  close = () => {
    this.props.close();
  };

  changeBox = obj => {
    this.props.changeBox(obj);
  };

  changeState = (propName, value) => {
    this.props.changeState(propName, value);
  };

  render() {
    const {isShow} = this.props;
    return (
      <div className={isShow ? 'modal fade in' : 'modal fade'} style={{display: !this.state.isOpen ? 'none' : ''}}>
        <div className='modal-dialog modal-sm'>
          <div className='modal-content'>
            <div className='modal-content_header'>
              <I18n tKey='modal.order_book_title' />
              {/*<a className="close" onClick={this.close}/>*/}
              <SvgWrapper className='close' onClick={this.close} SvgCmp={imgClose} themeWatch />
            </div>
            <div className='modal-content_body'>
              <div className='modal-content_title'>
                <I18n tKey='modal.order_display' />
              </div>
              <div className='form-group-line'>
                <a
                  onClick={this.changeState.bind(this, 'orderMode', ORDER_MODE.COLUMN)}
                  className={`form-control-toggle ${this.props.orderMode === ORDER_MODE.COLUMN ? 'active' : ''}`}
                >
                  <SvgWrapper className='icon icon-one ic-size-04' SvgCmp={OrderSvg} />
                  <I18n tKey='modal.one_col' />
                </a>
                <a
                  onClick={this.changeState.bind(this, 'orderMode', ORDER_MODE.ROW)}
                  className={`form-control-toggle ${this.props.orderMode === ORDER_MODE.ROW ? 'active' : ''}`}
                >
                  <SvgWrapper className='icon icon-one ic-size-04 ic-rotate-00' SvgCmp={OrderSvg} />
                  <I18n tKey='modal.two_col' />
                </a>
              </div>
              <div className='modal-content_title'>
                <I18n tKey='common.col_title' />
              </div>
              {this.boxes.map((item, index) => (
                <div key={index} className='form-group-line'>
                  {item.left && (
                    <CheckBox
                      onChange={this.changeBox.bind(this, item.left)}
                      name={item.left.name}
                      checked={!!this.props.boxes[item.left.name] || false}
                      label={item.left.label}
                    />
                  )}
                  {item.right && (
                    <CheckBox
                      onChange={this.changeBox.bind(this, item.right)}
                      name={item.right.name}
                      checked={!!this.props.boxes[item.right.name] || false}
                      label={item.right.label}
                    />
                  )}
                </div>
              ))}
              <div className='modal-content_title'>
                <I18n tKey='common.radio_title' />
              </div>
              <Radio
                onChange={this.changeState.bind(this, 'radio', total_amount_option)}
                checked={this.props.radio === total_amount_option}
                label='modal.radio_label_total'
              />
              <Radio
                onChange={this.changeState.bind(this, 'radio', sum_option)}
                checked={this.props.radio === sum_option}
                label='modal.radio_label_sum'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalOrderBook;
