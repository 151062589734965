import React, {Component} from 'react';
import classNames from 'classnames';
import I18n from './I18n';

export class CustomInput extends Component {
  state = {
    isFocused: false,
  };

  onChange = ev => {
    const {onChange, maxLength, useTrim = false, useNumberValidation = false, validatorRegExp} = this.props;
    const {value, name: inputName} = ev.target;
    let inputValue = value;
    let canSetNewValue = true;

    if (typeof maxLength === 'number') {
      if (inputValue.length > maxLength) {
        return;
      }
    }

    if (useTrim) {
      inputValue = (inputValue || '').trim();
    }

    if (useNumberValidation) {
      canSetNewValue = /^\d+$/.test(inputValue) || !inputValue.length;
    }

    if (validatorRegExp) {
      canSetNewValue = validatorRegExp.test(inputValue) || !inputValue.length;
    }

    if (canSetNewValue && onChange) {
      this.props.onChange && this.props.onChange(inputName, inputValue);
    }
  };

  focusInput = () => {
    if (this.props.isReadonly) {
      return;
    }

    this.setState({isFocused: true});
  };

  unFocusInput = () => {
    if (this.props.isReadonly) {
      return;
    }

    this.setState({isFocused: false});
  };

  render() {
    const {
      isReadonly = false,
      title,
      className,
      name,
      id,
      type = 'text',
      value,
      styleWrapper = {},
      isError,
    } = this.props;
    const {isFocused} = this.state;

    return (
      <label
        className={classNames(
          'txt-input',
          {['txt-input--with-title']: !!title, ['txt-input--with-error']: isError},
          className
        )}
        style={styleWrapper}
      >
        {title && (
          <I18n
            className={classNames('txt-input__header', {
              ['txt-input__header--animated']: value || typeof value === 'number' || isFocused,
            })}
            tKey={title}
          />
        )}

        <input
          readOnly={isReadonly}
          className={classNames('txt-input__field', {['txt-input__field--hoverable']: !isReadonly})}
          onFocus={this.focusInput}
          onBlur={this.unFocusInput}
          name={name}
          type={type}
          value={value}
          id={id}
          onChange={this.onChange}
        />
      </label>
    );
  }
}
