import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Fragment} from 'react';

import {DropFilter} from './DropFilter';
import {OrderItemCell} from './OrderItemCell';
import OrderHelper from './OrderHelper';

import {CloseOrder, OrderList, OrderStopList} from '../../../../api/Actions';
import {ReactComponent as ArrowDown} from '../../../../../../assets/images/icon/dropdown-mark.svg';
import {ReactComponent as TriangleIc} from '../../../../../../assets/images/icon/dropdown-small-n.svg';

import {marker_option} from '../../../../cmp/modal/ModalOrderSet';
import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import I18n from '../../../../cmp/helpers/I18n';

import {translate} from '../../../../utils/i18n';
import {
  changeLocalSettings,
  createOpenOrderBoxSettingObj,
  returnLocalSettings,
  settingNames,
} from '../../../../utils/utils';
import Pagination from 'rc-pagination';

class OpenOrders extends React.PureComponent {
  constructor(props) {
    super(props);
    const returnOption = (label, name) => ({label: translate(label), name});
    const savedSettings = returnLocalSettings(settingNames.openOrders);
    const boxes = {};
    savedSettings.boxes.forEach(elem => (boxes[elem.boxName] = returnOption(elem.label, elem.name)));

    this.state = {
      openedMarkets: [],
      filterByMarket: null,
      filterByType: null,
      boxes,
      radio: marker_option,
      isCancelOrderPopupShow: false,
      currentCancelOrder: null,
    };
  }

  componentDidMount() {
    this.props.getOrderList({rng: this.props.limit, listOfst: 0});
  }

  componentWillReceiveProps(next) {
    if (this.state.openedMarkets.length === 0 && next.marketList && next.marketList.active) {
      this.setState({openedMarkets: [next.marketList.active.id]});
    }
  }

  handlePageChange = current => {
    this.props.getOrderList({rng: this.props.limit, listOfst: current * this.props.limit - this.props.limit});
    this.props.updatePagination(current);
  };

  toggle(marketId) {
    let newOpened = [];
    if (!this.state.openedMarkets) {
      newOpened = [marketId];
    } else if (this.state.openedMarkets.indexOf(marketId) === -1) {
      newOpened = this.state.openedMarkets.slice(0);
      newOpened.push(marketId);
    } else {
      newOpened = this.state.openedMarkets.filter(mId => mId !== marketId);
      if (!newOpened) newOpened = [];
    }

    return this.setState({openedMarkets: newOpened});
  }

  toggleModal = () => {
    this.setState({isShowModal: !this.state.isShowModal});
  };

  showCancelOrderPopup = order => () => {
    this.setState({
      isCancelOrderPopupShow: true,
      currentCancelOrder: order,
    });
  };

  cancelOrder = () => {
    this.setState({
      isCancelOrderPopupShow: false,
      currentCancelOrder: null,
    });

    this.props.cancelOrder(this.state.currentCancelOrder).then(() => {
      this.props.getOrderList({rng: this.props.limit, listOfst: this.props.page * this.props.limit - this.props.limit});
    });
  };

  setMarketFilter(marketId) {
    marketId = parseInt(marketId);
    let newState;
    if (!!marketId) {
      newState = {filterByMarket: marketId, openedMarkets: [marketId]};
    } else {
      const {marketList} = this.props;
      let curMarket = marketList && marketList.active && marketList.active.id;
      newState = {
        filterByMarket: marketId,
        openedMarkets: !!curMarket ? [curMarket] : [],
      };
    }

    this.setState(newState);
  }

  setTypeFilter(type) {
    this.setState({filterByType: type});
  }

  changeBox = obj => {
    let newState;
    if (this.state.boxes[obj.name]) {
      newState = {...this.state.boxes, [obj.name]: null};
    } else {
      newState = {...this.state.boxes, [obj.name]: obj};
    }
    this.saveBoxSettingsLocal(newState);
    this.setState({boxes: newState});
  };

  changeState = (propName, value) => {
    changeLocalSettings(settingNames.openOrders, value, propName);
    this.setState({[propName]: value});
  };

  saveBoxSettingsLocal = boxOptions => {
    let boxes = Object.keys(boxOptions).map(key => {
      if (boxOptions[key]) {
        const option = boxOptions[key];
        return createOpenOrderBoxSettingObj(key, option.label, option.name);
      }
    });
    boxes = boxes.filter(elem => elem);
    changeLocalSettings(settingNames.openOrders, boxes, 'boxes');
  };

  render() {
    const {MarketListReducer, OrderListReducer, ActiveMarketReducer} = this.props;
    const {boxes, radio} = this.state;
    const isOrderCancelling = OrderListReducer.fetching;
    let orderList = OrderListReducer.list || [];
    const market = ActiveMarketReducer;
    const markets = MarketListReducer.list || [];
    if (!market) return null;
    let marketFilters = {},
      typeFilters = {
        SELL: translate('common.sell'),
        BUY: translate('common.buy'),
      };

    if (orderList && markets.length > 0) {
      for (let market of markets) {
        market.orders = [];
        marketFilters[market.identificatSymbol] = market.mBanner;
        if (!!this.state.filterByMarket && market.identificatSymbol !== this.state.filterByMarket) continue;

        for (let order of orderList) {
          if (order.ctcInstrIdentifier === market.identificatSymbol) {
            if (!this.state.filterByType || order.currentS === this.state.filterByType) {
              market.orders.push(order);
            }
          }
        }
      }
    }
    const boxesKeys = Object.keys(boxes),
      colSpanToggleTd = boxesKeys.filter(el => boxes[el]);

    return (
      <section className='history'>
        {this.state.isCancelOrderPopupShow && (
          <div className='close-order-modal'>
            <span className='close-order-label'>
              <I18n tKey='popup.cancel_title' />
            </span>
            <div className='buttons-container'>
              <button className='btn btn-purple btn-full size' onClick={this.cancelOrder}>
                <I18n tKey='common.yes' />
              </button>
              <button
                className='btn btn-link btn-full size'
                onClick={() => {
                  this.setState({
                    isCancelOrderPopupShow: false,
                    currentCancelOrder: null,
                  });
                }}
              >
                <I18n tKey='common.no' />
              </button>
            </div>
          </div>
        )}

        <div className='table-dropdown'>
          <div className='table-dropdown_list-item'>
            <DropFilter
              title='orders.all_cur'
              value={this.state.filterByMarket}
              values={marketFilters}
              onChange={this.setMarketFilter.bind(this)}
              showReset={true}
            />
            <DropFilter
              title='orders.all_types'
              value={this.state.filterByType}
              values={typeFilters}
              onChange={this.setTypeFilter.bind(this)}
              showReset={true}
            />
          </div>
        </div>
        <div className='history_body scrollbar-inner'>
          <table className='table table-orders'>
            <thead className='table-heading'>
              <tr>
                {boxesKeys.map((key, i) => {
                  if (!boxes[key]) {
                    return null;
                  }
                  return <th key={i}>{boxes[key].label}</th>;
                })}
                <th className='action'>
                  <div className='dropdown'>
                    <div className='dropdown_btn'>
                      <I18n tKey='common.close' />{' '}
                      <SvgWrapper themeWatch className='icon icon-arrow-toggle ic-size-02' SvgCmp={TriangleIc} />
                    </div>
                    <div className='dropdown_menu'>
                      <div className='dropdown_menu-item'>
                        <I18n tKey='common.all' />
                      </div>
                      <div className='dropdown_menu-item'>
                        <I18n tKey='orders.buy' />
                      </div>
                      <div className='dropdown_menu-item'>
                        <I18n tKey='orders.sell' />
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            {markets.map(m => {
              const orders = m.orders.sort((a, b) => {
                const aIsBuy = a.currentS === 'BUY';
                const bIsSell = b.currentS === 'SELL';
                if (aIsBuy && !bIsSell) {
                  return b.crTime - a.crTime;
                } else if (!aIsBuy && !bIsSell) {
                  return 1;
                } else if (!aIsBuy && bIsSell) {
                  return a.crTime - b.crTime;
                } else if (aIsBuy && bIsSell) {
                  return -1;
                }
              });
              if (!orders.length) return null;
              return (
                <Fragment key={m.identificatSymbol}>
                  <tbody className={'table-body market-toggler'} onClick={this.toggle.bind(this, m.identificatSymbol)}>
                    <tr
                      className={`open-next ${
                        this.state.openedMarkets.indexOf(m.identificatSymbol) !== -1 ? 'open' : ''
                      }`}
                    >
                      {boxesKeys.map((e, i) => {
                        if (i === 0) {
                          return (
                            <td key={i} className='toggle'>
                              <SvgWrapper
                                themeWatch
                                className='icon icon-arrow-toggle ic-size-02'
                                SvgCmp={TriangleIc}
                              />
                              {m.mBanner}
                              <span>· {orders.length}</span>
                            </td>
                          );
                        } else if (boxesKeys.length - 1 === i) {
                          return (
                            <Fragment key={i}>
                              <td className='arrow' colSpan={colSpanToggleTd.length}>
                                <SvgWrapper
                                  themeWatch
                                  className='icon icon-dropdown ic-color-00 ic-size-03'
                                  SvgCmp={ArrowDown}
                                />
                              </td>
                            </Fragment>
                          );
                        }
                      })}
                    </tr>
                  </tbody>
                  <tbody
                    className={`table-body order-list ${
                      this.state.openedMarkets.indexOf(m.identificatSymbol) !== -1 ? '' : 'd-none'
                    }`}
                  >
                    {orders.map(order => {
                      const o = new OrderHelper(order);
                      return (
                        <tr key={o.identificatSymbol}>
                          {boxesKeys.map((key, i) => {
                            if (!boxes[key]) {
                              return null;
                            }
                            return <OrderItemCell key={i} propName={boxes[key].name} o={o} m={m} />;
                          })}
                          <td className='close'>
                            {!isOrderCancelling && (
                              <a
                                onClick={this.showCancelOrderPopup({
                                  odrIdentifier: o.identificatSymbol,
                                  stop: false,
                                })}
                              >
                                {' '}
                                <I18n tKey='common.cancel' />{' '}
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Fragment>
              );
            })}
          </table>
        </div>
        {(this.props.OrderListReducer.totalElements || 0) > this.props.limit && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Pagination
              simple
              showTitle={false}
              onChange={this.handlePageChange}
              current={this.props.page}
              pageSize={this.props.limit}
              total={OrderListReducer.totalElements}
            />
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = ({MarketListReducer, OrderListReducer, ActiveMarketReducer, LngReducer}) => {
  return {
    MarketListReducer,
    OrderListReducer,
    ActiveMarketReducer,
    LngReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getOrderList: OrderList.action,
        cancelOrder: CloseOrder.action,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenOrders);
