import React, {Component} from 'react';

import Details from './Details';
import Button from '../Button';
// import {CheckBox} from '../../../cmp/modal/CheckBox';
import Loader from '../../../cmp/Loader';
import {ReactComponent as Close} from '../../../../../assets/images/icon/close.svg';
import PairInputs from '../PairInputs';
import {getDepositChainInfo} from '../../../api/Actions';
import {OPERATION_TYPE} from '../constants';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

class ThirdParty extends Component {
  state = {
    disclaimerAgreed: false,
    isError: false,
  };

  componentDidMount() {
    const {GetWithdrawChainsReducer, WsWalletReducer, rightCurrency, operationType, specificCoin} = this.props;

    if (
      operationType === OPERATION_TYPE.BUY &&
      GetWithdrawChainsReducer.data &&
      GetWithdrawChainsReducer.data.respItemCollection
    ) {
      const coinSymbol = rightCurrency.value.symbol.toUpperCase();
      const chainCoin = specificCoin
        ? GetWithdrawChainsReducer.data.respItemCollection.find(
            ({externalCode}) => externalCode === specificCoin.externalCode
          )
        : GetWithdrawChainsReducer.data.respItemCollection.find(({internalCode}) => internalCode === coinSymbol);
      if (!WsWalletReducer.byCurrencyMap) {
        this.setState({isError: true});
        return;
      }
      const identificatSymbol = WsWalletReducer.byCurrencyMap[coinSymbol].identificatSymbol;
      const getDepositParams = chainCoin
        ? {purseIdentifier: identificatSymbol, currencyFireblocksId: chainCoin.id}
        : {purseIdentifier: identificatSymbol};

      this.props.getDepositChainInfo(getDepositParams);
    }
  }

  handleCheckboxChange = () => {
    this.setState({disclaimerAgreed: !this.state.disclaimerAgreed});
  };

  render() {
    const {
      onRightCodeChange,
      onLeftCodeChange,
      leftCurrencyAmount,
      rightCurrencyAmount,
      leftCurrency,
      rightCurrency,
      rightSelectOptions,
      leftSelectOptions,
      onLeftAmountChange,
      onRightAmountChange,
      onBackClick,
      userData,
      operationType,
      paymentType,
      openItezWidget,
      GetWithdrawChainsReducer,
      GetDepositChainInfoReducer,
      specificCoin,
    } = this.props;

    if (GetWithdrawChainsReducer.fetching) {
      return <Loader open />;
    }

    return (
      <div className='third-party'>
        <Loader open={GetDepositChainInfoReducer.fetching} />
        <div onClick={onBackClick} className='third-party__back_btn'>
          <Close />
        </div>
        <div className='third-party__header'>{paymentType.label}</div>
        <div className='third-party__grid'>
          <PairInputs
            onRightCodeChange={onRightCodeChange}
            onLeftCodeChange={onLeftCodeChange}
            leftCurrencyAmount={leftCurrencyAmount}
            rightCurrencyAmount={rightCurrencyAmount}
            leftCurrency={leftCurrency}
            rightCurrency={rightCurrency}
            rightSelectOptions={rightSelectOptions}
            leftSelectOptions={leftSelectOptions}
            onLeftAmountChange={onLeftAmountChange}
            onRightAmountChange={onRightAmountChange}
            operationType={operationType}
            isReadOnly
          />
        </div>
        <Details
          leftCurrencyAmount={leftCurrencyAmount}
          rightCurrencyAmount={rightCurrencyAmount}
          leftCurrency={leftCurrency}
          rightCurrency={rightCurrency}
          operationType={operationType}
          userData={userData}
          paymentType={paymentType}
          specificCoin={specificCoin}
        />
        {/* <div className='third-party__exchange-disclaimer exchange-disclaimer'>
          <div className='exchange-disclaimer__header'>Disclaimer</div>
          <div>
            You are going to leave markets.unitexcrypto.com be taken to Bankera.com Services related to SEPA payments are
            provided by BANKERA, a separate third party platform. Please read and agree to Bankera Terms of Use before
            using their services. For any questions related to the services, please contact Bankera: support@bankera.com
          </div>
        </div>
        <div className='third-party__checkbox'>
          <CheckBox
            label='I have read and agree to the disclaimer'
            onChange={this.handleCheckboxChange}
            isChecked={this.state.disclaimerAgreed}
          />
        </div> */}
        {this.state.isError ||
          (!GetDepositChainInfoReducer.data.targetPlaceName && !GetDepositChainInfoReducer.fetching && (
            <p className='deposit-warning'>Something goes wrong. Please try later.</p>
          ))}
        <div className='third-party__btn'>
          <Button
            disabled={
              (paymentType.value === 'Third-party' && !this.state.disclaimerAgreed) ||
              GetDepositChainInfoReducer.fetching ||
              !GetDepositChainInfoReducer.data.targetPlaceName
            }
            onClick={openItezWidget}
            label='Confirm'
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({GetWithdrawChainsReducer, WsWalletReducer, GetDepositChainInfoReducer}) => ({
  GetWithdrawChainsReducer,
  WsWalletReducer,
  GetDepositChainInfoReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getDepositChainInfo: getDepositChainInfo.action,
      },
      dispatch
    ),
  };
};

const connectedThirdParty = connect(mapStateToProps, mapDispatchToProps)(ThirdParty);
export default connectedThirdParty;
