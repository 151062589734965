import store from '../../../../../store';
import {fetcher} from '../../../../../api/Api';
import * as Actions from '../../../../../api/Actions';
import {addOnOhlcvUpdate} from '../../../../../reducers/ws/WsOhlcvReducer';

const SUPPORTED_RESOLUTIONS = ['1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W'];
const INTRADAY_MULTIPLIERS = ['1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1440', '10080'];

const getStore = () => {
  return store;
};

const convertDataToCandle = arr => {
  const [time, open, high, low, close, volume] = arr;

  return {
    time,
    close,
    open,
    high,
    low,
    volume,
  };
};

const transformTimeResolution = resolution => {
  switch (resolution) {
    case '1':
      return '1m';
    case '3':
      return '3m';
    case '5':
      return '5m';
    case '15':
      return '15m';
    case '30':
      return '30m';
    case '60':
      return '1h';
    case '120':
      return '2h';
    case '240':
      return '4h';
    case '360':
      return '6h';
    case '480':
      return '8h';
    case '720':
      return '12h';
    case 'D':
      return '1D';
    default:
      return resolution;
  }
};

const ohlcvMapper = object => {
  const {whenVal, oAction, loftyVal, bottomVal, cAction, cubicMeasure} = object;

  return {
    time: whenVal,
    open: oAction,
    high: loftyVal,
    low: bottomVal,
    close: cAction,
    volume: cubicMeasure,
  };
};

class DataFeed {
  // !!! setTimeout using is library recommendation

  constructor(marketList = []) {
    this.marketList = marketList;
  }

  store = getStore();
  subscriptions = {};

  onReady(cb) {
    setTimeout(() => {
      cb({
        supported_resolutions: SUPPORTED_RESOLUTIONS,
      });
    }, 0);
  }

  searchSymbols = (userInput, exchange, symbolType, onResult) => {};

  resolveSymbol = (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    const nextMarket = this.marketList.find(({identificatSymbol}) => String(identificatSymbol) === symbolName);

    if (!nextMarket) {
      onResolveErrorCallback('Unsupported market');

      return;
    }

    const rightCurrency = this.store
      .getState()
      .CurrencyListReducer.list.find(item => item.mUCryptogram === nextMarket.rightMonetaryUnitCode);

    setTimeout(() => {
      onSymbolResolvedCallback({
        name: String(nextMarket.identificatSymbol),
        full_name: nextMarket.mBanner,
        listed_exchange: 'listed_exchange',
        description: '',
        type: 'crypto',
        session: '24x7',
        timezone: 'UTC',
        ticker: String(nextMarket.identificatSymbol),
        exchange: '-',
        minmov: 1,
        pricescale: Number('1' + new Array(rightCurrency.mURigor).fill(0).join('')),
        has_intraday: true,
        intraday_multipliers: INTRADAY_MULTIPLIERS,
        supported_resolutions: SUPPORTED_RESOLUTIONS,
        volume_precision: 8,
        data_status: 'streaming',
      });
    }, 0);
  };

  getBars = (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const {from, countBack, to, firstDataRequest} = periodParams;

    fetcher
      .get(Actions.OhlcvHistory.url, {
        params: {
          ctcInstrIdentifier: Number(symbolInfo.ticker),
          ohlcvResolutionReq: transformTimeResolution(resolution),
          leftD: from * 1000,
          rightD: to * 1000,
        },
      })
      .then(response => {
        const candles = response.data.ohlcvCollectionResp;

        onHistoryCallback(candles.map(ohlcvMapper), {noData: !candles.length});
      })
      .catch(err => {
        console.error(err);

        onErrorCallback('getBars smth went wrong');
      });
  };

  subscribeBars = (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
    onResetCacheNeededCallback();
    // this.socket.send(OHLCV_SUBSCRIBE, {
    //   subscribeMessage: {
    //     marketId: symbolInfo.ticker,
    //     resolution: DataFeed.transformTimeResolution(resolution)
    //   }
    // });
    this.subscriptions[subscriberUID] = {
      unsubscribe: addOnOhlcvUpdate(data => {
        data[2].forEach(c => {
          onRealtimeCallback(convertDataToCandle(c));
        });
      }),
      ticker: Number(symbolInfo.ticker),
      resolution,
    };
  };

  unsubscribeBars = listenerGuid => {
    Object.keys(this.subscriptions)
      .filter(item => item !== listenerGuid)
      .forEach(item => {
        this.unsubscribe(item);
      });
  };

  unsubscribe = subscriberUID => {
    const subscription = this.subscriptions[subscriberUID];

    if (subscription) {
      //subscription.unsubscribe();
      // this.socket.send(WS_TYPE_OHLCV_UNSUBSCRIBE, {
      //   unsubscribeMessage: {
      //     marketId: subscription.ticker,
      //     resolution: DataFeed.transformTimeResolution(subscription.resolution)
      //   }
      // });

      delete this.subscriptions[subscriberUID];
    }
  };
}

export default DataFeed;
