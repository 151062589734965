import * as React from 'react';

import {ReactComponent as ArrowDown} from '../../../../../../assets/images/icon/dropdown-mark.svg';
import {ReactComponent as UserIc} from '../../../../../../assets/images/nav/user.svg';
import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import {SwitchBtn} from '../../../../cmp/helpers/SwitchBtn';
import I18n from '../../../../cmp/helpers/I18n';
import {languages, URLMAP} from '../../../../utils/const';
import connect from './connect';
import {ReactComponent as Logo} from '../../../../../../assets/images/logo.svg';

class Header extends React.Component {
  state = {};

  doLogout() {
    this.props.logout();
  }

  changeLng = lng => {
    this.props.changeLng(lng);
  };

  render() {
    const {SessionReducer} = this.props;

    const user = SessionReducer.masterPersonality || {};

    const languagesListKeys = Object.keys(this.props.LngReducer.languages);
    return (
      <header className='sanctions-block-header'>
        <div className='header-logo-wrapper'>
          <SvgWrapper className='ic-size-00 depend-on-theme' SvgCmp={Logo} />
          <span>UNITEX</span>
        </div>

        <div className='header_right'>
          <div className='header-nav'>
            <div>
              <SwitchBtn active={this.props.ThemeReducer.darkTheme} onClick={this.props.switchTheme} />
            </div>

            <div
              className={`dropdown ${this.state.userMenu ? 'open' : ''}`}
              onMouseEnter={() => this.setState({userMenu: true})}
              onMouseLeave={() => this.setState({userMenu: false})}
            >
              <a className='header-nav_item dropdown_btn'>
                <span className='title'>
                  <SvgWrapper themeWatch className='icon icon-user ic-size-06' SvgCmp={UserIc} />{' '}
                  {user.curUName || <I18n tKey='header:user' />}
                </span>
                <SvgWrapper themeWatch className='icon icon-dropdown ic-size-03' SvgCmp={ArrowDown} />
              </a>

              <div className='dropdown_menu'>
                {user.uniqueSymbolRow && (
                  <a onClick={this.doLogout.bind(this)} className='dropdown_menu-item'>
                    <I18n tKey='common.logout' />
                  </a>
                )}
              </div>
            </div>

            <div
              className={`dropdown ${this.state.language ? 'open' : ''}`}
              onMouseEnter={() => this.setState({language: true})}
              onMouseLeave={() => this.setState({language: false})}
            >
              <a className='header-nav_item dropdown_btn'>
                <span className='title'>
                  <span className={`flag flag-${this.props.LngReducer.lng}`} />
                </span>
                <span>{languages[this.props.LngReducer.lng] || this.props.LngReducer.lng}</span>
              </a>
              <div className='dropdown_menu center lng-list'>
                {languagesListKeys.map((key, i) => (
                  <a
                    className={`dropdown_menu-link ${this.props.LngReducer.lng === key ? 'active' : ''}`}
                    onClick={this.changeLng.bind(this, key)}
                    key={i}
                  >
                    <div className='lng-option-wrapper'>
                      <span className={`flag flag-${key}`} /> <span>{languages[key] || key}</span>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default connect(Header);
