// @flow

import {filterActions} from 'redux-ignore';
// import {fetch, GET, POST, PUT, DELETE, remove, put, getUserToken, post, request} from "./Api";
import {GET, POST, PUT, DELETE, getUserToken, request} from './Api';
import type {
  ActionEvents,
  ActionType,
  Combine,
  DataReducerAction,
  DataState,
  HttpMethod,
  ListReducerAction,
  ListState,
  Reducer,
} from './types/internalFlow';
import {sortMarketList} from '../reducers/ActiveMarketReducer';

const ERROR = '_ERROR';
const SUCCESS = '_SUCCESS';
const START = '_START';
const CLEAR = '_CLEAR';

export const LIST_REDUCER: ActionType = 'list';
export const DATA_REDUCER: ActionType = 'data';

export function error(actionName: string): string {
  return actionName + ERROR;
}

export function success(actionName: string): string {
  return actionName + SUCCESS;
}

export function start(actionName: string): string {
  return actionName + START;
}

export function clear(actionName: string): string {
  return actionName + CLEAR;
}

export function actions(actionName: string): ActionEvents {
  return {
    onStart: start(actionName),
    onSuccess: success(actionName),
    onError: error(actionName),
    onClear: clear(actionName),
  };
}

export function actionList(actionName: string): Array<string> {
  return [start(actionName), success(actionName), error(actionName), clear(actionName)];
}

export function createListReducer<T>(actionName: string): Reducer<T> {
  const initialState: ListState<T> = {
    fetching: false,
    list: null,
    totalElements: 0,
  };

  const reducer = (state: ListState<T> = initialState, action: ListReducerAction<T>): ListState<T> => {
    switch (action.type) {
      case start(actionName): {
        return {...state, fetching: true};
      }
      case success(actionName): {
        let list;
        if (Array.isArray(action.data)) {
          list = action.data;
        } else {
          list = action.data ? action.data.respItemCollection : [];
        }
        if (actionName === 'MARKET_LIST_ACTION') {
          list = sortMarketList(list);
        }

        return {
          fetching: false,
          list,
          ...((action.data || {}).trading && {trading: action.data.trading}),
          totalElements: action.data.totalRespItems || action.data.totalElements || 0,
          pageInfo: action.data.pageInfo || {},
        };
      }
      case error(actionName):
        return {...state, totalElements: 0, fetching: false, error: (action.response && action.response.data) || {}};
      case clear(actionName):
        return {...initialState};
      default:
        return state;
    }
  };

  return filterActions(reducer, actionList(actionName));
}

export function createDataReducer<T>(actionName: string): Reducer<T> {
  const initialState: DataState<T> = {
    fetching: false,
    completed: false,
    data: {},
  };

  const reducer = (state: DataState<T> = initialState, action: DataReducerAction<T>): DataState<T> => {
    switch (action.type) {
      case start(actionName): {
        return {...state, fetching: true, error: null};
      }
      case success(actionName): {
        return {
          fetching: false,
          completed: true, // in case api doesnt return any data on success resp
          data: action.data,
        };
      }
      case clear(actionName):
        return {...initialState};
      case error(actionName):
        return {...state, completed: false, fetching: false, error: (action.response && action.response.data) || {}};
      default:
        return state;
    }
  };

  return filterActions(reducer, actionList(actionName));
}

export function createAction<Req, Resp>(
  url: string,
  method: HttpMethod,
  name: string,
  reducerType: ActionType,
  isSecured: boolean,
  isFormData?: Boolean,
  useNewBaseUrl = false
): Combine<Req, Resp> {
  return {
    url,
    method,
    name,
    isSecured,
    events: actions(name),
    action: (query: ?Req, params: ?Req) => {
      if (isSecured && !getUserToken()) {
        return () => {
          console.warn('Rejected for anonymous:' + url);
        };
      }

      let requestUrl = url.trim();
      if (requestUrl.indexOf(':') > 0) {
        requestUrl.match(/:[a-zA-Z]+/gi).forEach(name => {
          requestUrl = requestUrl.replace(name, query[name.substring(1)]);
        });
      }

      if (params) {
        Object.keys(params).forEach((key, i) => {
          if (params[key] !== null && params[key] !== undefined) {
            const delimiter = requestUrl.indexOf('?') === -1 ? '?' : '&';
            requestUrl += `${delimiter}${key}=${encodeURIComponent(params[key])}`;
          }
        });
      }

      const req = {
        url: requestUrl,
        method: method,
        ...actions(name),
        query,
        isFormData,
        useNewBaseUrl,
      };
      return request(req);
    },
    reducer: reducerType === LIST_REDUCER ? createListReducer(name) : createDataReducer(name),
  };
}
