import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {FreezeAccount, UserLogout} from '../../api/Actions';
import I18n from '../../cmp/helpers/I18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import {ReactComponent as War} from '../../../../assets/images/modal/Alert.svg';
import {ReactComponent as Ok} from '../../../../assets/images/modal/Ok.svg';
import {SUPPORT_EMAIL, URLMAP} from '../../utils/const';
import {ReactComponent as Stop} from '../../../../assets/images/icon/stop_grey.svg';

const ContentBlock = ({icon, title, description, action, buttonTitle, mailto, help, children}) => (
  <div className='result_container'>
    <div className='result'>
      {icon && <SvgWrapper className='icon' SvgCmp={icon || War} />}
      <div className='title mt-2'>
        <I18n tKey={title} />
      </div>
      {children && <div className='description mt-2'>{children}</div>}
      <div className='description mt-2 text-center'>
        <I18n tKey={description} />
      </div>
      <div className='mt-2 white'>
        {mailto ? (
          <a target='_blank' className='btn btn-primary white' href={`mailto:${SUPPORT_EMAIL}`}>
            <I18n tKey={buttonTitle} />
          </a>
        ) : (
          <button onClick={action} type='button' className='btn btn-primary white'>
            <I18n tKey={buttonTitle} />
          </button>
        )}
      </div>
      {help && (
        <div className='text-center mt-2'>
          <a href='/page/faq/all' target='_blank'>
            <I18n tKey='helpcenter:header' />
          </a>
        </div>
      )}
    </div>
  </div>
);

class AccountFreezeConfirmation extends React.Component {
  freezeAccount = () => {
    this.props.freezeAccount().then(r => !r.isError && this.props.logout());
  };

  goContact() {
    this.props.history.push(URLMAP.TERMINAL);
  }

  render() {
    const {FreezeAccountReducer, SessionReducer} = this.props;
    const user = SessionReducer.masterPersonality || 0;
    if ((this.props.SessionReducer.accIsFrozen && !user) || FreezeAccountReducer.completed) {
      return (
        <ContentBlock
          icon={Ok}
          title='freezeaccount:title'
          description='freezeaccount:description'
          buttonTitle='freezeaccount:button'
        />
      );
    } else if (user) {
      return (
        <ContentBlock
          title='freezeaccount:warning_title'
          help
          description='freezeaccount:warning_info'
          buttonTitle='freezeaccount:confirm_button'
          action={this.freezeAccount}
        >
          <div className='content-align-center column'>
            <div className='row justify-start'>
              <SvgWrapper themeWatch className='icon icon-search ic-size-06' SvgCmp={Stop} />
              <I18n tKey='freezeaccount:warning_info_1' className='align-center' />
            </div>
            <div className='row justify-start'>
              <SvgWrapper themeWatch className='icon icon-search ic-size-06' SvgCmp={Stop} />
              <I18n tKey='freezeaccount:warning_info_2' className='align-center' />
            </div>
            <div className='row justify-start'>
              <SvgWrapper themeWatch className='icon icon-search ic-size-06' SvgCmp={Stop} />
              <I18n tKey='freezeaccount:warning_info_3' className='align-center' />
            </div>
          </div>
        </ContentBlock>
      );
    } else {
      return (
        <div className='text-center full-height content-align-center'>
          <I18n tKey='profile.empty_account_list' />
        </div>
      );
    }
  }
}

const mapStateToProps = ({FreezeAccountReducer, SessionReducer}) => ({FreezeAccountReducer, SessionReducer});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        freezeAccount: FreezeAccount.action,
        logout: UserLogout.action,
      },
      dispatch
    ),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountFreezeConfirmation));
