import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import FooterPopup from './right/FooterPopup';
import LoginPopup, {LOGIN_POPUP} from './right/LoginPopup';
import ForgotPasswordPopup, {FORGOT_PASSWORD_POPUP} from './right/ForgotPasswordPopup';
import RegistrationPopup, {REGISTRATION_POPUP} from './right/RegistrationPopup';
import {CLOSE_POPUP, OPEN_POPUP} from '../../reducers/PopupReducer';
import {LoginTwoFactorAuth, UserLogin, UserRegister, AddTwoFactorAuth} from '../../api/Actions';
import Enable2FAStep1Popup, {ENABLE_2FA_STEP1_POPUP} from './top/Enable2FAStep1Popup';
import Enable2FAStep2Popup, {ENABLE_2FA_STEP2_POPUP} from './top/Enable2FAStep2Popup';
import Enable2FAStep3Popup, {ENABLE_2FA_STEP3_POPUP} from './top/Enable2FAStep3Popup';
import Enable2FAStep4Popup, {ENABLE_2FA_STEP4_POPUP} from './top/Enable2FAStep4Popup';
import Enable2FAStep5Popup, {ENABLE_2FA_STEP5_POPUP} from './top/Enable2FAStep5Popup';
import CreateApiKeyPopup from './apiKey/CreateApiKeyPopup';
import DeleteApiKey from './apiKey/DeleteApiKey';
import {DISABLE_2FA_STEP1_POPUP} from './top/Disable2FAStep1Popup';
import {DISABLE_2FA_STEP2_POPUP} from './top/Disable2FAStep2Popup';
import Disable2FAStep2Popup from './top/Disable2FAStep2Popup';
import Disable2FAStep1Popup from './top/Disable2FAStep1Popup';
import ExchangePopup, {EXCHANGE_POPUP} from './top/ExchangePopup';
import DepositQRCodePopup, {DEPOSIT_QR_CODE_POPUP} from './top/DepositQRCodePopup';
import storageHelper from 'storage-helper';

import {
  REVOKE_KEY,
  ENABLE_KEY,
  DISABLE_KEY,
  DISABLE_KEY_STATUS,
  ENABLE_KEY_STATUS,
} from '../../cmp/popups/apiKey/DeleteApiKey';
import {CREATE_KEY, EDIT_KEY} from '../../cmp/popups/apiKey/CreateApiKeyPopup';
import {SvgWrapper} from '../helpers/SvgWrapper';
import {ReactComponent as imgClose} from '../../../../assets/images/icon/close.svg';
import {NOTIFY_2FA_DISABLED} from '../../layout/Leftbar';
import {URLMAP} from '../../utils/const';
import TicketCreated, {TICKET_CREATED_POPUP} from './custom/TicketCreated';
import OpenOrderError, {OPEN_ORDER_ERROR_POPUP} from './custom/OpenOrderError';

const RIGHT_POPUP = 'RIGHT_POPUP';
const TOP_POPUP = 'TOP_POPUP';
const CUSTOM_POPUP = 'CUSTOM_POPUP';

class Popup extends React.PureComponent {
  closeModal = () => {
    ///ENABLE_2FA_STEP1_POPUP|ENABLE_2FA_STEP2_POPUP|ENABLE_2FA_STEP3_POPUP|ENABLE_2FA_STEP4_POPUP|ENABLE_2FA_STEP5_POPUP/g
    const regExp = new RegExp(
      '' +
        ENABLE_2FA_STEP1_POPUP +
        '|' +
        ENABLE_2FA_STEP2_POPUP +
        '|' +
        ENABLE_2FA_STEP3_POPUP +
        '|' +
        ENABLE_2FA_STEP4_POPUP +
        '|' +
        ENABLE_2FA_STEP5_POPUP +
        '',
      'g'
    );
    if (regExp.test(this.props.PopupReducer.name)) {
      storageHelper.setItem(NOTIFY_2FA_DISABLED, true);
      this.props.clearAddTwoFactorData();
    }
    this.props.close();
  };

  openLoginPage = () => {
    this.props.history.push(URLMAP.LOGIN);
    this.props.close();
  };

  createRightPopup(Content) {
    return (
      <div className='modal-signup' role='document'>
        <div className='modal-signup-content'>
          <button type='button' className='btn close' data-dismiss='modal' aria-label='Close' />
          {this.props.PopupReducer.name !== LOGIN_POPUP ? (
            <button type='button' className='btn back' onClick={this.openLoginPage} aria-label='Back' />
          ) : null}
          <div className='modal-signup_logo'>
            <div className='logo'>{/*<SvgWrapper SvgCmp={LogoSvg} />*/}</div>
          </div>
          {Content}
        </div>
        <FooterPopup />
      </div>
    );
  }

  createTopPopup(Content) {
    return (
      <div className='modal-2FA' role='document'>
        <div className='modal-2FA-content'>
          <SvgWrapper SvgCmp={imgClose} themeWatch className='btn close' onClick={this.closeModal} />
          {Content}
        </div>
      </div>
    );
  }

  render() {
    let Content = null;
    let type = RIGHT_POPUP;
    let permissions = null;
    let sid = null;
    let keyLabel = null;
    let userKey = null;
    let status = null;
    switch (this.props.ApiAccessKeyPopupReducer.name) {
      case CREATE_KEY:
        permissions = this.props.ApiAccessKeyPopupReducer.data;
        break;
      case EDIT_KEY:
        userKey = this.props.ApiAccessKeyPopupReducer.data;
        break;
      case ENABLE_KEY:
        status = ENABLE_KEY_STATUS;
        keyLabel = this.props.ApiAccessKeyPopupReducer.data.stringTag;
        sid = this.props.ApiAccessKeyPopupReducer.data.curSidTs;
        break;
      case DISABLE_KEY:
        status = DISABLE_KEY_STATUS;
        keyLabel = this.props.ApiAccessKeyPopupReducer.data.stringTag;
        sid = this.props.ApiAccessKeyPopupReducer.data.curSidTs;
        break;
      case REVOKE_KEY:
        keyLabel = this.props.ApiAccessKeyPopupReducer.data.stringTag;
        sid = this.props.ApiAccessKeyPopupReducer.data.curSidTs;
        break;
    }

    switch (this.props.PopupReducer.name) {
      case LOGIN_POPUP:
        this.props.loginClear();
        this.props.login2faClear();
        Content = <LoginPopup />;
        break;
      case FORGOT_PASSWORD_POPUP:
        Content = <ForgotPasswordPopup />;
        break;
      case REGISTRATION_POPUP:
        this.props.registrationClear();
        Content = <RegistrationPopup referralCode={(this.props.PopupReducer.data || {}).referralCode} />;
        break;

      case ENABLE_2FA_STEP1_POPUP:
        type = TOP_POPUP;
        Content = <Enable2FAStep1Popup data={this.props.PopupReducer.data} />;
        break;
      case ENABLE_2FA_STEP2_POPUP:
        type = TOP_POPUP;
        Content = <Enable2FAStep2Popup />;
        break;
      case ENABLE_2FA_STEP3_POPUP:
        type = TOP_POPUP;
        Content = <Enable2FAStep3Popup />;
        break;
      case ENABLE_2FA_STEP4_POPUP:
        type = TOP_POPUP;
        Content = <Enable2FAStep4Popup />;
        break;
      case ENABLE_2FA_STEP5_POPUP:
        type = TOP_POPUP;
        Content = <Enable2FAStep5Popup />;
        break;
      case DISABLE_2FA_STEP1_POPUP:
        type = TOP_POPUP;
        Content = <Disable2FAStep1Popup />;
        break;
      case DISABLE_2FA_STEP2_POPUP:
        type = TOP_POPUP;
        Content = <Disable2FAStep2Popup />;
        break;
      case EXCHANGE_POPUP:
        if (this.props.PopupReducer.data) {
          type = TOP_POPUP;
          Content = <ExchangePopup data={this.props.PopupReducer.data} />;
        }
        break;
      case DEPOSIT_QR_CODE_POPUP:
        if (this.props.PopupReducer.data) {
          type = TOP_POPUP;
          Content = <DepositQRCodePopup data={this.props.PopupReducer.data} />;
        }
        break;
      case TICKET_CREATED_POPUP:
        type = CUSTOM_POPUP;
        Content = <TicketCreated closeCb={this.props.PopupReducer.data.closeCb} />;
        break;
      case OPEN_ORDER_ERROR_POPUP:
        type = CUSTOM_POPUP;
        Content = <OpenOrderError />;
        break;
    }
    return (
      <React.Fragment>
        {type === CUSTOM_POPUP && Content}

        <div className='modal fade' tabIndex='-1' role='dialog' id='popup'>
          {type === RIGHT_POPUP && this.createRightPopup(Content)}
          {type === TOP_POPUP && this.createTopPopup(Content)}
        </div>

        <div className='modal fade small' tabIndex='-1' role='dialog' id='modal-key'>
          <CreateApiKeyPopup permissions={permissions} userKey={userKey} />
        </div>

        <div className='modal fade small ' tabIndex='-1' role='dialog' id='modal-key-delete'>
          <DeleteApiKey sid={sid} status={status} keyLabel={keyLabel} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({PopupReducer, ApiAccessKeyPopupReducer}) => ({PopupReducer, ApiAccessKeyPopupReducer});

const mapDispatchToProps = dispatch => {
  return {
    registrationClear: () => dispatch({type: UserRegister.events.onClear}),
    loginClear: () => dispatch({type: UserLogin.events.onClear}),
    login2faClear: () => dispatch({type: LoginTwoFactorAuth.events.onClear}),
    clearAddTwoFactorData: () => dispatch({type: AddTwoFactorAuth.events.onClear}),
    close: () => dispatch({type: CLOSE_POPUP}),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Popup));

export default connectedWithRouter;
