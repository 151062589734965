export const jivositeConnect = lng => {
  switch (lng) {
    case 'en':
      return '//code.jivosite.com/widget/wVjuPb7NCb';
      break;
    case 'cs':
      return '//code.jivosite.com/widget/wVjuPb7NCb';
      break;
    case 'zh_CN':
      return '//code.jivosite.com/widget/wVjuPb7NCb';
      break;
    case 'et':
      return '//code.jivosite.com/widget/1xHAQNBf7U';
      break;
    case 'de':
      return '//code.jivosite.com/widget/wVjuPb7NCb';
      break;
    case 'hi_IN':
      return '//code.jivosite.com/widget/wVjuPb7NCb';
      break;
    case 'it':
      return '//code.jivosite.com/widget/rzWn4gYSR6';
      break;
    case 'kk':
      return '//code.jivosite.com/widget/DUr1KdBmVe';
      break;
    case 'ro':
      return '//code.jivosite.com/widget/rzWn4gYSR6';
      break;
    case 'ru':
      return '//code.jivosite.com/widget/KIDcEWFrE8';
      break;
    default:
      return '//code.jivosite.com/widget/wVjuPb7NCb';
  }
};
