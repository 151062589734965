import storageHelper from 'storage-helper/src';
import * as Actions from '../api/Actions';
import {enabledLanguages} from '../utils/const';

export const SET_LNG = 'SET_LNG';

export const STORAGE_KEY = 'UNITEX_APP_LNG';
export const STORAGE_DICTIONARY = 'UNITEX_APP_DICTIONARY';

const LNG = storageHelper.getItem(STORAGE_KEY, true) || 'en';

const initialState = {
  lng: LNG,
  isError: false,
  dictionary: storageHelper.getItem(STORAGE_DICTIONARY, true) || {},
  languages: {},
};

export const LngReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LanguagesList.events.onSuccess:
      const languages = Object.fromEntries(
        Object.entries(action.data).filter(([key]) => enabledLanguages.some(lng => lng === key))
      );
      return {...state, languages};

    case Actions.GetDictionary.events.onSuccess:
      storageHelper.setItem(STORAGE_DICTIONARY, JSON.stringify(action.data));
      return {...state, dictionary: {...action.data}};

    case SET_LNG:
      storageHelper.setItem(STORAGE_KEY, JSON.stringify(action.payload.lng));
      location.reload();
      return {...state, lng: action.payload.lng};

    default:
      return state;
  }
};
