import React from 'react';
import {connect} from 'react-redux';

const CustomizedBarLabel = props => {
  const {x, y, width, height, offset, value, ThemeReducer} = props;
  const {darkTheme} = ThemeReducer;

  return (
    <text
      className='bar-label'
      x={x + width / 2}
      y={y + height}
      dy={-6}
      offset={offset}
      fontSize={10}
      textAnchor='middle'
      width={width}
      height={height}
      fill={darkTheme ? '#cdd2d6' : 'black'}
    >
      <tspan>{value.toFixed(0)} %</tspan>
    </text>
  );
};

const mapStateToProps = ({ThemeReducer}) => {
  return {
    ThemeReducer,
  };
};

export default connect(mapStateToProps, undefined)(CustomizedBarLabel);
