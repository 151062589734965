import React from 'react';
import {connect} from 'react-redux';

import ExchangeVolumeStats, {MARKET_MODE} from '../../../../layout/ExchangeVolumeStats';
import {CellHeader} from '../index';
import TradingViewWidget from './TradingViewWidget';

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenDisplay: false,
      market: this.props.ActiveMarketReducer,
    };
  }

  render() {
    const market = this.props.ActiveMarketReducer || {};

    return (
      <div className='chart'>
        <div className='chart-cell-header'>
          <CellHeader>{`${market.leftMonetaryUnitCode || ''}-${market.rightMonetaryUnitCode || ''}`}</CellHeader>
          <ExchangeVolumeStats mode={MARKET_MODE} />
        </div>
        <div className='chart-candle'>
          {/*{market.identificatSymbol && <CandleStickChartContainer market={market} />}*/}
          {market.identificatSymbol && <TradingViewWidget market={market} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ActiveMarketReducer}) => {
  return {ActiveMarketReducer};
};

export default connect(mapStateToProps, undefined)(Chart);
