import React from 'react';

import {CurrencyView} from '../../../../cmp/helpers/CurrencyView';
import {CurrencyHighlightView} from '../../../../cmp/helpers/CurrencyHighlightView';

import Currency from '../../../../utils/currency';

const ContainerBlock = ({value, className, order, boxes, currencyType, children}) => {
  if (!boxes[order]) {
    return null;
  }
  return (
    <div className={className} style={{order: Object.keys(boxes).indexOf(order)}}>
      <span>
        {children ? (
          children
        ) : (
          <CurrencyView
            amount={value || 0}
            type={
              currencyType && boxes[order].unitCode === 'left'
                ? currencyType.leftMonetaryUnitCode
                : currencyType.rightMonetaryUnitCode
            }
          />
        )}
      </span>
    </div>
  );
};

class OrderbookItem extends React.PureComponent {
  selectOrder = (price, amount) => {
    this.props.selectOrder(price, amount);
  };

  render() {
    const {isDown, order, prevOrder, activeMarket, boxes} = this.props;
    const price = Currency.show(order.price, activeMarket.rightMonetaryUnitCode),
      prevPrice =
        prevOrder && prevOrder.price ? Currency.show(prevOrder.price, activeMarket.rightMonetaryUnitCode) : null;
    return (
      <div>
        {boxes && (
          <div
            onClick={this.selectOrder.bind(this, price, order.volume)}
            className={`orderbook_body-value-item orderbook_body-value-item ${isDown ? 'down' : 'top'}`}
          >
            <ContainerBlock
              className='orderbook_body-value-item-pair'
              order='price'
              boxes={boxes}
              currencyType={activeMarket}
            >
              <CurrencyHighlightView
                prevPrice={prevPrice}
                price={price}
                type={
                  activeMarket && boxes['price'] && boxes['price'].unitCode === 'left'
                    ? activeMarket.leftMonetaryUnitCode
                    : activeMarket.rightMonetaryUnitCode
                }
              />
            </ContainerBlock>
            <ContainerBlock value={order.volume} order='amount' boxes={boxes} currencyType={activeMarket} />
            <ContainerBlock value={order.total} boxes={boxes} order='total' currencyType={activeMarket} />
            <div className={order.share ? 'after' : 'd-none'} style={{width: `${order.share}%`}} />
          </div>
        )}
      </div>
    );
  }
}

export default OrderbookItem;
