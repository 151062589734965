import {connect} from 'react-redux';
import React, {Component} from 'react';
import {Switch, withRouter} from 'react-router';
import {Route} from 'react-router-dom';
import numeral from 'numeral';

import CommonMaintenancePage from './page/CommonMaintenancePage';
import TerminalPage from './page/TerminalPage';
import {UserSanctionsForm} from './page/UserSanctionsForm';

import {AppPreloader} from './layout/AppPreloader';
import Leftbar from './layout/Leftbar';
import Header from './layout/Header';

import Teaser from './cmp/helpers/Teaser';
import Popup from './cmp/popups/Popup';
import VersionedTosPopup from './cmp/popups/VersionedTosPopup';

import {fetcher} from './api/Api';
import {ADAPTIVE_VERSION_HINT_DISABLED, numeralDictionaryKeys, URLMAP} from './utils/const';
import {jivositeConnect} from './utils/jivositeConnect';
import {STATUS} from './constants/sanctionCheckStatus';
import {FROZEN_ROUTES, GUEST_ROUTES, USER_ROUTES} from './constants/routes';
import storageHelper from 'storage-helper/src';
import {STORAGE_KEY} from './reducers/LngReducer';
import {element} from 'prop-types';
import EnableMobileVersion from './cmp/EnableMobileVersion';

//!!!be careful MEGA_SHIT
const LNG = storageHelper.getItem(STORAGE_KEY, true) || 'en';
const numeralLocal = numeralDictionaryKeys.some(element => LNG === element) ? LNG : 'en';
import(`numeral/locales/${numeralLocal}`);
numeral.locale(numeralLocal);

//!!!be careful MEGA_SHIT

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdaptiveHintDisabled: !!localStorage.getItem(ADAPTIVE_VERSION_HINT_DISABLED),
      isFetching: true,
      appIsActive: true,
    };
  }

  componentDidMount() {
    // this.addJivoSite(this.props.LngReducer.lng);

    fetcher.get('../../actuator/health').then(
      r => {
        // check server status
        this.setState({isFetching: false, appIsActive: true});
      },
      () => {
        this.setState({isFetching: false, appIsActive: false});
      }
    );
  }

  componentWillReceiveProps(n) {
    // if (this.props.LngReducer.lng !== n.LngReducer.lng) {
    //   const elems = document.getElementsByTagName('script');
    //
    //   [...elems].forEach(e => {
    //     e.getAttribute('src');
    //     if (e.getAttribute('src').indexOf('code.jivosite.com/widget') > 0) {
    //       e.remove();
    //     }
    //   });
    //
    //   this.addJivoSite(n.LngReducer.lng);
    // }

    if (this.props.SessionReducer.accIsFrozen !== n.SessionReducer.accIsFrozen && n.SessionReducer.accIsFrozen) {
      this.props.history.push('/freezeaccount');
    }
  }

  addJivoSite(lng) {
    const jivoScript = document.createElement('script');
    jivoScript.src = jivositeConnect(lng);
    document.getElementsByTagName('head')[0].appendChild(jivoScript);
  }

  returnRoutes = () => {
    const {SessionReducer} = this.props;
    if (SessionReducer.masterPersonality || SessionReducer.accIsFrozen) {
      // is user
      if (SessionReducer.accIsFrozen) {
        return FROZEN_ROUTES;
      }
      return USER_ROUTES;
    }

    return GUEST_ROUTES;
  };

  returnTerminalPath = () => {
    const {SessionReducer} = this.props;
    if (SessionReducer.masterPersonality || SessionReducer.accIsFrozen) {
      // is user
      if (SessionReducer.accIsFrozen) {
        return [URLMAP.CONFIRM_EMAIL, URLMAP.MARKET, '*'];
      }
      return '*';
    }
    return [URLMAP.CONFIRM_EMAIL, URLMAP.MARKET, '*'];
  };

  handleAdaptiveHintClose = value => {
    this.setState({isAdaptiveHintDisabled: value});
  };

  render() {
    const {
      ApplicationInitReducer,
      DictionaryReducer,
      LanguagesListReducer,
      SessionReducer,
      location,
      UserSanctionsStatusReducer,
      MarketListReducer,
      ApplicationSettingsReducer,
      // SanctionCheckReducer,
    } = this.props;
    const {referralReminderIsActive} = ApplicationInitReducer;
    const isStaticPage = location && location.pathname && location.pathname.indexOf('/page/') === 0;

    if (!this.state.isAdaptiveHintDisabled && isMobile) {
      return <EnableMobileVersion onBlockClose={this.handleAdaptiveHintClose} />;
    }

    if (
      ApplicationSettingsReducer.fetching ||
      MarketListReducer.fetching ||
      DictionaryReducer.fetching ||
      LanguagesListReducer.fetching ||
      ApplicationInitReducer.fetching ||
      UserSanctionsStatusReducer.fetching ||
      this.state.isFetching
    ) {
      return <AppPreloader />;
    }

    if (!this.state.appIsActive) {
      return <CommonMaintenancePage />;
    }

    // if (SanctionCheckReducer.status === STATUS.notSubmitted) {
    //   return <UserSanctionsForm />;
    // }

    const routes = this.returnRoutes();
    const terminalPath = this.returnTerminalPath();
    const canShowTeaser =
      SessionReducer.masterPersonality &&
      referralReminderIsActive &&
      !!(ApplicationSettingsReducer.data || {referralFeePercent: 0}).referralFeePercent;

    return (
      <div className={`wrapper ${canShowTeaser ? 'with-reminder' : ''}`} style={{overflowX: 'hidden'}}>
        <Leftbar />
        <Header />
        <Popup />

        {!isStaticPage && !!SessionReducer.masterPersonality && <VersionedTosPopup />}
        {canShowTeaser && <Teaser />}

        <div className='main' id='golden'>
          <Route path={terminalPath} component={TerminalPage} />
        </div>

        <Switch>
          {routes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({
  SessionReducer,
  DictionaryReducer,
  LanguagesListReducer,
  LngReducer,
  ApplicationInitReducer,
  UserSanctionsStatusReducer,
  MarketListReducer,
  ApplicationSettingsReducer,
  // SanctionCheckReducer,
}) => ({
  LanguagesListReducer,
  DictionaryReducer,
  LngReducer,
  ApplicationInitReducer,
  SessionReducer,
  UserSanctionsStatusReducer,
  MarketListReducer,
  ApplicationSettingsReducer,
  // SanctionCheckReducer,
});

export default withRouter(connect(mapStateToProps, {})(Content));
