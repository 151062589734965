import React, {Component} from 'react';
import classNames from 'classnames';

import I18n from '../../../../cmp/helpers/I18n';

class Tab extends Component {
  render() {
    const {label, onClick, isActive, disabled = false} = this.props;

    return (
      <button
        className={classNames('fast-exchange-block__tab', {['active']: isActive})}
        onClick={onClick}
        disabled={disabled}
      >
        <I18n tKey={label} />
      </button>
    );
  }
}

export default Tab;
