// @flow
import {createAction, DATA_REDUCER, LIST_REDUCER} from './ActionHelper';
import type {Combine} from './types/internalFlow';
import type {
  AccountTO,
  ApiKey,
  ApiKeyForm,
  AppPaymentPlan,
  AppSettingsTO,
  AttachDocumentsRequest,
  BaseApiKeyPermission,
  BindTwoFactorAuthForm,
  CancelWithdrawForm,
  CheckSMSCodeForm,
  CheckTwoFaCodeRespTO,
  CheckUserExistForm,
  CheckUserExistRespTO,
  CloseOrderForm,
  ConfirmApiEmailResponseTO,
  ConfirmApiKeyForm,
  ConfirmEmailForm,
  ConfirmEmailRespTO,
  ConfirmWithdrawForm,
  CurrencyConvertorForm,
  CurrencyConvertRespTO,
  CurrencyTO,
  DepositAddressTO,
  DepositForm,
  DocumentRequests,
  GeoIPResponseTO,
  GetDepositAddressForm,
  GetWithdrawUsageRespTo,
  HistoryOrderForm,
  IncomingDepositTO,
  KycAccountTO,
  KycDocumentResponseTO,
  KycValidationRespTO,
  LoginForm,
  LoginRespTO,
  LoginTwoFactorAuthForm,
  MarketTO,
  MoneyTransactionTO,
  OpenOrderForm,
  OrderTO,
  PasswordChangeForm,
  PasswordChangeRespTO,
  PasswordForm,
  PasswordResetRequest,
  PasswordResetRequestEmailRespTO,
  PasswordSetNewRequest,
  RegisterForm,
  RegisterUserRespTO,
  RemoveTwoFactorAuthForm,
  ResendApiKeyConfirmationEmailResponseTO,
  ResendApiKeyEmailConfirmationForm,
  ResendConfirmationEmailResponseTO,
  ResendEmailConfirmationForm,
  SendSmsTwoFaCodeRespTO,
  TransactionsHistoryForm,
  TwoFactorAuthAddRespTO,
  TwoFactorAuthUpdateRespTO,
  UpdateUserInfoForm,
  UserActivityHistoryForm,
  UserActivityTO,
  UserInfoTO,
  UserProfileTO,
  UserTradeHistoryTO,
  WalletTO,
  WithdrawalTO,
  WithdrawForm,
  WithdrawListFilterForm,
} from './types/models';
import {GET, POST, PUT} from './Api';

export const AccountList: Combine<void, AccountTO> = createAction(
  '/master/schindler',
  GET,
  'ACCOUNT_LIST_ACTION',
  LIST_REDUCER,
  true
);

export const GetAccountApiKeys: Combine<void, ApiKey> = createAction(
  '/api-lock-picks',
  GET,
  'GET_ALL_API_KEYS_BY_UUID_ACTION',
  DATA_REDUCER,
  true
);

export const CreateAccountApiKey: Combine<ApiKeyForm, ApiKey> = createAction(
  '/api-lock-picks',
  POST,
  'CREATE_ACCOUNT_API_KEY_ACTION',
  DATA_REDUCER,
  true
);

export const UpdateAccountApiKey: Combine<ApiKeyForm, ApiKey> = createAction(
  '/api-lock-picks',
  PUT,
  'UPDATE_ACCOUNT_API_KEY_ACTION',
  DATA_REDUCER,
  true
);

export const RevokeApiKey: Combine<void, ApiKey> = createAction(
  '/api-lock-picks/void',
  POST,
  'REVOKE_API_KEY_ACTION',
  DATA_REDUCER,
  true
);

export const DisableAccountApiKey: Combine<void, ApiKey> = createAction(
  '/api-lock-picks/incapacitate-lock-pick',
  POST,
  'DISABLE_ACCOUNT_API_KEY_ACTION',
  DATA_REDUCER,
  true
);

export const EnableAccountApiKey: Combine<void, ApiKey> = createAction(
  '/api-lock-picks/prove-lock-pick',
  POST,
  'ENABLE_ACCOUNT_API_KEY_ACTION',
  DATA_REDUCER,
  true
);

export const ConfirmApiKey: Combine<ConfirmApiKeyForm, ConfirmApiEmailResponseTO> = createAction(
  '/api-lock-picks/acknowledgment/ack',
  POST,
  'CONFIRM_API_KEY_ACTION',
  DATA_REDUCER,
  true
);

export const ConfirmRevokeApiKey: Combine<void, void> = createAction(
  '/api-lock-picks/acknowledgment/void/ack',
  POST,
  'CONFIRM_REVOKE_API_KEY_ACTION',
  DATA_REDUCER,
  true
);

export const ResendApiKeyConfirmationEmail: Combine<
  ResendApiKeyEmailConfirmationForm,
  ResendApiKeyConfirmationEmailResponseTO
> = createAction(
  '/api-lock-picks/acknowledgment/resend-ack',
  POST,
  'RESEND_API_KEY_CONFIRMATION_EMAIL_ACTION',
  DATA_REDUCER,
  true
);

export const BaseApiKeyPermissionList: Combine<void, BaseApiKeyPermission> = createAction(
  '/baseApiLockPickPrmsn/schindler',
  GET,
  'BASE_API_KEY_PERMISSION_LIST_ACTION',
  DATA_REDUCER,
  true
);

export const CurrencyList: Combine<void, CurrencyTO> = createAction(
  '/monetary-unit/schindler',
  GET,
  'CURRENCY_LIST_ACTION',
  LIST_REDUCER,
  false
);

export const CurrencyConvert: Combine<CurrencyConvertorForm, CurrencyConvertRespTO> = createAction(
  '/monetary-unit-cnvtr/reconstruct',
  POST,
  'CURRENCY_CONVERT_ACTION',
  DATA_REDUCER,
  false
);

export const CryptoPrices: Combine<void, void> = createAction(
  '/monetary-unit-cnvtr',
  GET,
  'CRYPTO_PRICES_ACTION',
  DATA_REDUCER,
  false
);

export const InitKycValidation: Combine<void, KycValidationRespTO> = createAction(
  '/ky-cust-ep/ky-cust-init-val',
  POST,
  'INIT_KYC_VALIDATION_ACTION',
  DATA_REDUCER,
  true
);

export const KycCallback: Combine<void, any> = createAction(
  '/ky-cust-ep/ky-cust-cb',
  POST,
  'KYC_CALLBACK_ACTION',
  DATA_REDUCER,
  true
);

export const KycAccountInfo: Combine<void, KycAccountTO> = createAction(
  '/ky-cust-ep/crypto-app',
  GET,
  'KYC_ACCOUNT_INFO_ACTION',
  DATA_REDUCER,
  true
);

export const KycAccountSave: Combine<void, KycAccountTO> = createAction(
  '/ky-cust-ep/crypto-app/checkpoint',
  POST,
  'KYC_ACCOUNT_SAVE_ACTION',
  DATA_REDUCER,
  true
);

export const KycAccountDelete: Combine<void, KycAccountTO> = createAction(
  '/ky-cust-ep/clean',
  POST,
  'KYC_ACCOUNT_DELETE_ACTION',
  DATA_REDUCER,
  true
);

export const KycDocumentList: Combine<void, KycDocumentResponseTO> = createAction(
  '/ky-cust-ep/chronicle/schindler',
  GET,
  'KYC_DOCUMENT_LIST_ACTION',
  LIST_REDUCER,
  true
);

export const KycUploadDocument: Combine<void, KycDocumentResponseTO> = createAction(
  '/ky-cust-ep/chronicle/outgoing-load',
  POST,
  'KYC_UPLOAD_DOCUMENT_ACTION',
  DATA_REDUCER,
  true
);

export const MarketList: Combine<void, MarketTO> = createAction(
  '/ctc-instrument/schindler',
  GET,
  'MARKET_LIST_ACTION',
  LIST_REDUCER,
  false
);

export const OpenOrder: Combine<OpenOrderForm, OrderTO> = createAction(
  '/ord1886/unbar-ordr',
  POST,
  'OPEN_ORDER_ACTION',
  DATA_REDUCER,
  true
);

export const GetDictionary: Combine<CloseOrderForm, OrderTO> = createAction(
  '/translations/:locale',
  GET,
  'GET_DICTIONARY_ACTION',
  DATA_REDUCER,
  false
);

export const CloseOrder: Combine<CloseOrderForm, OrderTO> = createAction(
  '/ord1886/off-ordr',
  POST,
  'CLOSE_ORDER_ACTION',
  DATA_REDUCER,
  true
);

export const OrderList: Combine<HistoryOrderForm, OrderTO> = createAction(
  '/ord1886/schindler?rng=:rng&listOfst=:listOfst',
  GET,
  'ORDER_LIST_ACTION',
  LIST_REDUCER,
  true
);

export const OrderStopList: Combine<HistoryOrderForm, OrderTO> = createAction(
  '/ord1886/list-stop',
  GET,
  'ORDER_LIST_STOP_ACTION',
  DATA_REDUCER,
  true
);

export const OrderHistory: Combine<HistoryOrderForm, OrderTO> = createAction(
  '/ord1886/antiquity',
  GET,
  'ORDER_HISTORY_ACTION',
  LIST_REDUCER,
  true
);

export const OrderDetails: Combine<void, OrderTO> = createAction(
  '/ord1886/spcl/:odrIdentifier',
  GET,
  'ORDER_DETAILS_ACTION',
  DATA_REDUCER,
  true
);

export const ApplicationSettings: Combine<any, AppSettingsTO> = createAction(
  '/gear-icon',
  GET,
  'APPLICATION_SETTINGS_ACTION',
  DATA_REDUCER,
  false
);

export const FeesSettings: Combine<any, any> = createAction(
  '/gear-icon/monetary-unit',
  GET,
  'FEES_SETTINGS_ACTION',
  DATA_REDUCER,
  false
);

export const PaymentPlan: Combine<any, AppPaymentPlan> = createAction(
  '/ppc/schindler',
  GET,
  'PAYMENT_PLAN_ACTION',
  LIST_REDUCER,
  false
);

export const OhlcvHistory: Combine<any, any> = createAction(
  '/biz-trd/ohlcv-antiquity',
  GET,
  'OHLCV_HISTORY_ACTION',
  DATA_REDUCER,
  false
);

export const UserTradeHistory: Combine<any, UserTradeHistoryTO> = createAction(
  '/biz-trd/biz-trd-antiquity',
  GET,
  'USER_TRADE_HISTORY_ACTION',
  DATA_REDUCER,
  true
);

export const UserLastTrades: Combine<any, any> = createAction(
  '/biz-trd/ultima-trd',
  GET,
  'USER_LAST_TRADES_HISTORY_ACTION',
  DATA_REDUCER,
  false
);

export const UserRegister: Combine<RegisterForm, RegisterUserRespTO> = createAction(
  '/master-personality/up-action',
  POST,
  'USER_REGISTER_ACTION',
  DATA_REDUCER,
  false
);

export const GetReferralCode: Combine<RegisterForm, RegisterUserRespTO> = createAction(
  '/master-personality/referral',
  GET,
  'GET_REFERRAL_CODE_ACTION',
  DATA_REDUCER,
  false
);

export const GetUsersListRegisteredReferral: Combine<any, any> = createAction(
  '/referral/users',
  GET,
  'GET_USERS_LIST_REGISTERED_REFERRAL_ACTION',
  DATA_REDUCER,
  false
);

export const GetReferralStatsBrakedown: Combine<any, any> = createAction(
  '/referral/stats/brake-down',
  GET,
  'GET_REFERRAL_STATS_BRAKEDOWN_ACTION',
  DATA_REDUCER,
  false
);

export const GetParentReferralUser: Combine<any, any> = createAction(
  '/referral/parent',
  GET,
  'GET_PARENT_REFERRAL_USER_ACTION',
  DATA_REDUCER,
  false
);

export const UserLogin: Combine<LoginForm, LoginRespTO> = createAction(
  '/master-personality/in-action',
  POST,
  'USER_LOGIN_ACTION',
  DATA_REDUCER,
  false
);

export const AddTwoFactorAuth: Combine<PasswordForm, TwoFactorAuthAddRespTO> = createAction(
  '/master-personality/author/duo-component/:tfVec/originate',
  POST,
  'ADD_TWO_FACTOR_AUTH_ACTION',
  DATA_REDUCER,
  true
);

export const BindTwoFactorAuth: Combine<BindTwoFactorAuthForm, any> = createAction(
  '/master-personality/author/duo-component/enable/:tfVec/email',
  POST,
  'BIND_TWO_FACTOR_AUTH_ACTION',
  DATA_REDUCER,
  true
);

export const LoginTwoFactorAuth: Combine<LoginTwoFactorAuthForm, LoginRespTO> = createAction(
  '/master-personality/author/duo-component/:tfVec/in-action',
  POST,
  'LOGIN_TWO_FACTOR_AUTH_ACTION',
  DATA_REDUCER,
  false
);

export const RemoveTwoFactorAuth: Combine<RemoveTwoFactorAuthForm, TwoFactorAuthUpdateRespTO> = createAction(
  '/master-personality/author/duo-component/disable/:tfVec/email',
  POST,
  'REMOVE_TWO_FACTOR_AUTH_ACTION',
  DATA_REDUCER,
  true
);

export const UserLogout: Combine<void, any> = createAction(
  '/master-personality/out-action',
  POST,
  'USER_LOGOUT_ACTION',
  DATA_REDUCER,
  true
);

export const ResendConfirmationEmail: Combine<ResendEmailConfirmationForm, ResendConfirmationEmailResponseTO> =
  createAction(
    '/master-personality/post-addr/acknowledgment/send-action',
    POST,
    'RESEND_EMAIL_CONFIRMATION_ACTION',
    DATA_REDUCER,
    false
  );

export const ConfirmEmail: Combine<ConfirmEmailForm, ConfirmEmailRespTO> = createAction(
  '/master-personality/post-addr/acknowledgment/ack',
  POST,
  'EMAIL_CONFIRMATION_ACTION',
  DATA_REDUCER,
  false
);

export const RequestPasswordReset: Combine<PasswordResetRequest, PasswordResetRequestEmailRespTO> = //there isn't in table
  createAction(
    '/master-personality/symbol-row/outgoing-msg-reset',
    POST,
    'REQUEST_PASSWORD_RESET_ACTION',
    DATA_REDUCER,
    false
  );

export const SetNewPassword: Combine<PasswordSetNewRequest, LoginRespTO> = createAction(
  '/master-personality/symbol-row/reinstall',
  POST,
  'SET_NEW_PASSWORD_ACTION',
  DATA_REDUCER,
  false
);

export const UserActivityHistory: Combine<UserActivityHistoryForm, UserActivityTO> = //todo time field
  createAction('/master-personality/personality-chronicles', GET, 'USER_ACTIVITY_HISTORY_ACTION', LIST_REDUCER, true);

export const ChangePassword: Combine<PasswordChangeForm, PasswordChangeRespTO> = createAction(
  '/master-personality/symbol-row/alter',
  POST,
  'CHANGE_PASSWORD_ACTION',
  DATA_REDUCER,
  true
);

export const UpdateUserInfo: Combine<UpdateUserInfoForm, UserProfileTO> = createAction(
  '/master-personality/data-new',
  POST,
  'UPDATE_USER_INFO_ACTION',
  DATA_REDUCER,
  true
);

export const UploadUserLogo: Combine<void, UserProfileTO> = createAction(
  '/master-personality/icon-new',
  POST,
  'UPLOAD_USER_LOGO_ACTION',
  DATA_REDUCER,
  true
);

export const GeoInfo: Combine<void, GeoIPResponseTO> = createAction(
  '/master-personality/where-data',
  GET,
  'GEO_INFO_ACTION',
  DATA_REDUCER,
  false
);

export const UserSettings: Combine<void, UserInfoTO> = createAction(
  '/master-personality/gear-icon',
  GET,
  'USER_SETTINGS_ACTION',
  DATA_REDUCER,
  true
);

export const ToggleMarketInFavourite: Combine<ResendEmailConfirmationForm, any> = createAction(
  '/master-personality/like/:ctcInstrumentIdentifier',
  GET,
  'TOGGLE_MARKET_IN_FAVOURITE_ACTION',
  DATA_REDUCER,
  true
);

export const GetFavouriteMarkets: Combine<void, any> = createAction(
  '/master-personality/liked-instr',
  GET,
  'GET_FAVOURITE_MARKETS_ACTION',
  LIST_REDUCER,
  true
);

export const CheckUserExist: Combine<CheckUserExistForm, CheckUserExistRespTO> = createAction(
  '/master-personality/personality-existence',
  POST,
  'CHECK_USER_EXIST_ACTION',
  DATA_REDUCER,
  false
);

export const SendSmsTwoFaCode: Combine<void, SendSmsTwoFaCodeRespTO> = createAction(
  '/master-personality/sms-send-action',
  GET,
  'SEND_SMS_TWO_FACTOR_CODE_ACTION',
  DATA_REDUCER,
  false
);

export const CheckSmsTwoFaCode: Combine<CheckSMSCodeForm, CheckTwoFaCodeRespTO> = //there isn't in table
  createAction('/master-personality/checkSMS2FACode', POST, 'CHECK_SMS_TWO_FACTOR_CODE_ACTION', DATA_REDUCER.false);

export const GetUserInfo: Combine<void, UserProfileTO> = //there isn't in table
  createAction('/master-personality/getUserInfo', GET, 'GET_USER_INFO_ACTION', DATA_REDUCER, true);

export const FreezeAccount: Combine<void, any> = createAction(
  '/master-personality/freeze-account',
  GET,
  'FREEZE_ACCOUNT_ACTION',
  DATA_REDUCER,
  true
);

export const WalletList: Combine<void, WalletTO> = createAction(
  '/pocket-scroll/schindler',
  GET,
  'WALLET_LIST_ACTION',
  LIST_REDUCER,
  true
);

export const DepositsList: Combine<void, IncomingDepositTO> = //there isn't in table
  createAction('/pocket-scroll/listDeposits', GET, 'DEPOSITS_LIST_ACTION', LIST_REDUCER, true);

export const WalletDeposit: Combine<DepositForm, WalletTO> = //there isn't in table
  createAction('/pocket-scroll/deposit', POST, 'WALLET_DEPOSIT_ACTION', DATA_REDUCER, true);

export const DepositAddressesList: Combine<void, DepositAddressTO> = createAction(
  '/pocket-scroll/:psIdentifier/show-dpst-addr',
  GET,
  'DEPOSIT_ADDRESSES_LIST_ACTION',
  LIST_REDUCER,
  true
);

export const AccountDepositAddressesList: Combine<void, DepositAddressTO> = createAction(
  '/pocket-scroll/show-master-dpst-addr',
  GET,
  'ACCOUNT_DEPOSIT_ADDRESSES_LIST_ACTION',
  LIST_REDUCER,
  true
);

export const GetDepositAddress: Combine<GetDepositAddressForm, DepositAddressTO> = createAction(
  '/pocket-scroll/receive-dpst-addr',
  POST,
  'GET_DEPOSIT_ADDRESS_ACTION',
  DATA_REDUCER,
  true
);

export const WalletTransactions: Combine<TransactionsHistoryForm, MoneyTransactionTO> = createAction(
  '/pocket-scroll/biz-list',
  GET,
  'WALLET_TRANSACTIONS_ACTION',
  LIST_REDUCER,
  true
);

export const WithdrawRequest: Combine<WithdrawForm, WithdrawalTO> = createAction(
  '/extraction/outgoing-msg',
  POST,
  'WITHDRAW_REQUEST_ACTION',
  DATA_REDUCER,
  true
);

export const WithdrawList: Combine<WithdrawListFilterForm, WithdrawalTO> = //there isn't in table
  createAction('/extraction/list', GET, 'WITHDRAW_LIST_ACTION', LIST_REDUCER, true);

export const WithdrawCancel: Combine<CancelWithdrawForm, WithdrawalTO> = createAction(
  '/extraction/annulment',
  POST,
  'WITHDRAW_CANCEL_ACTION',
  DATA_REDUCER,
  true
);

export const WithdrawUsage: Combine<void, GetWithdrawUsageRespTo> = createAction(
  '/extraction/usa-age',
  GET,
  'WITHDRAW_USAGE_ACTION',
  DATA_REDUCER,
  true
);

export const WithdrawConfirm: Combine<ConfirmWithdrawForm, WithdrawalTO> = createAction(
  '/extraction/acknowledge',
  POST,
  'WITHDRAW_CONFIRM_ACTION',
  DATA_REDUCER,
  true
);

export const ResendWithdrawConfirmEmail: Combine<any, any> = createAction(
  '/extraction/:id/resend-msg',
  POST,
  'RESEND_WITHDRAW_CONFIRM_EMAIL_ACTION',
  DATA_REDUCER,
  true
);

export const CountryList: Combine<any, CountryTO> = createAction(
  '/motherland/schindler',
  GET,
  'GET_COUNTRIES_ACTION',
  LIST_REDUCER,
  false
);

export const LanguagesList: Combine<any, any> = createAction(
  '/translations/lng/list',
  GET,
  'GET_LANGUAGES_ACTION',
  DATA_REDUCER,
  false
);

export const MsgsList: Combine<any, any> = createAction(
  '/user/mesaga/schindler',
  GET,
  'GET_MSGS_LIST_ACTION',
  LIST_REDUCER,
  true
);

export const ReadMsg: Combine<any, any> = createAction(
  '/user/mesaga/scan-msg',
  POST,
  'READ_MSG_ACTION',
  DATA_REDUCER,
  true
);

export const ConfirmEmail2Fa: Combine<any, any> = createAction(
  '/master-personality/author/duo-component/enable/:tfVec/confirm',
  POST,
  'CONFIRM_2FA_EMAIL_ACTION',
  DATA_REDUCER,
  true
);

export const DisableEmail2Fa: Combine<any, any> = createAction(
  '/master-personality/author/duo-component/disable/:tfVec/confirm',
  POST,
  'DISABLE_2FA_EMAIL_ACTION',
  DATA_REDUCER,
  true
);

export const VerifyCountryList: Combine<any, any> = createAction(
  '/geo/countries',
  GET,
  'GET_COUNTIES_VERIFICATION',
  DATA_REDUCER,
  true
);

export const CheckTokenResetPassword: Combine<any, any> = createAction(
  '/master-personality/reset-password/:token/check',
  GET,
  'CHECK-TOKEN-RESET-PASSWORD',
  DATA_REDUCER,
  true
);

export const ReferralProgramLink: Combine<any, any> = createAction(
  '/master-personality/gear-icon',
  GET,
  'GET_LINK_REFERRAL_PROGRAM',
  DATA_REDUCER,
  true
);

export const UserTradeFeeUsing: Combine<any, any> = createAction(
  '/biz-trd/fee',
  GET,
  'GET_USER_TRADE_FEE',
  DATA_REDUCER,
  true
);

export const GlobalPassToken: Combine<any, any> = createAction(
  '/global-pass/token',
  GET,
  'GET_GLOBAL_PASS_TOKEN',
  DATA_REDUCER,
  true
);

export const UpdateGlobalPassToken: Combine<any, any> = createAction(
  '/global-pass/token/update',
  POST,
  'GET_GLOBAL_PASS_TOKEN_UPDATE',
  DATA_REDUCER,
  true
);

export const GtiRequest: Combine<any, any> = createAction(
  '/gtinet/identity/linking',
  POST,
  'GTI_REQUEST_ACTION',
  DATA_REDUCER,
  true
);

export const GetGtiStatus: Combine<any, any> = createAction(
  '/gtinet/identity/linking',
  GET,
  'GET_GTI_STATUS_ACTION',
  DATA_REDUCER,
  true
);

export const GetDocumentRequests: Combine<void, DocumentRequests> = createAction(
  '/documentRequests',
  GET,
  'GET_DOCUMENT_REQUESTS_ACTION',
  DATA_REDUCER,
  true
);

export const AttachDocuments: Combine<void, AttachDocumentsRequest> = createAction(
  '/documentRequests',
  POST,
  'ATTACH_DOCUMENTS_ACTION',
  DATA_REDUCER,
  true,
  true
);

export const GtiDepositRequest: Combine<any, any> = createAction(
  '/gtinet/payment/deposit',
  POST,
  'GTI_DEPOSIT_REQUEST_ACTION',
  DATA_REDUCER,
  true
);

export const GetVolumeStatsRequests: Combine<void, any> = createAction(
  '/ctc-instrument/volume-stats',
  GET,
  'GET_VOLUME_STATS_ACTION',
  DATA_REDUCER,
  false
);

export const GetMarketVolumeStatsRequests: Combine<void, any> = createAction(
  '/ctc-instrument/volume-stats',
  GET,
  'GET_MARKET_VOLUME_STATS_ACTION',
  DATA_REDUCER,
  false
);

export const AcceptTosVersion: Combine<void, any> = createAction(
  '/master-personality/accept-tos/:version',
  GET,
  'USER_ACCEPT_TOS_ACTION',
  DATA_REDUCER,
  true
);

export const DefaultFees: Combine<void, any> = createAction(
  '/defaultFees/get',
  GET,
  'GET_DEFAULT_FEES_ACTION',
  DATA_REDUCER,
  false
);

export const CreateTicket: Combine<void, any> = createAction(
  '/tickets/create',
  POST,
  'CREATE_TICKET_ACTION',
  DATA_REDUCER,
  false,
  false
);

export const RedeemVoucher: Combine<void, any> = createAction(
  '/voucher/redeem',
  POST,
  'REDEEM_VOUCHER_ACTION',
  DATA_REDUCER,
  true,
  false
);

export const GetCurrencyXRates: Combine<any, any> = createAction(
  '/monetary-unit-cnvtr/xrates',
  POST,
  'CURRENCY_LIST_XRATES_ACTION',
  DATA_REDUCER,
  false
);

export const CheckUserSanctionStatus = createAction(
  '/master-personality/sanction-status',
  GET,
  'CHECK_USER_SANCTION_STATUS',
  DATA_REDUCER,
  true
);

export const SubmitSanctionsData = createAction(
  '/master-personality/submit-sanction-status',
  POST,
  'SUBMIT_SANCTIONS_DATA',
  DATA_REDUCER,
  true
);

export const getWithdrawChains = createAction(
  '/monetary-unit/schindler/fireblocks',
  GET,
  'WITHDRAW_CHAINS',
  DATA_REDUCER,
  false
);

export const getDepositChainInfo = createAction(
  '/pocket-scroll/receive-dpst-addr',
  POST,
  'DEPOSIT_CHAIN_INFO',
  DATA_REDUCER,
  true
);

export const GetSepaWithdrawIds = createAction(
  '/extraction/bankeraWithdrawAddresses',
  GET,
  'SEPA_WITHDRAW_IDS',
  LIST_REDUCER,
  true
);

export const getUserMarketsSettings = createAction(
  '/user/settings',
  GET,
  'USER_MARKETS_SETTINGS',
  DATA_REDUCER,
  true,
  false,
  true
);
