import React from 'react';

export const CRYPTO_ASSETS = [
  {value: '10324', label: <div className='label__wrapper'>BTC</div>, crypto: 'BTC'},
  {value: '10325', label: <div className='label__wrapper'>BNB</div>, crypto: 'BNB'},
  {value: '10329', label: <div className='label__wrapper'>ETH</div>, crypto: 'ETH'},
  {value: '10334', label: <div className='label__wrapper'>USDT</div>, crypto: 'USDT'},
  {value: '10328', label: <div className='label__wrapper'>EOS</div>, crypto: 'EOS'},
  {value: '10326', label: <div className='label__wrapper'>CRU</div>, crypto: 'CRU'},
  {value: '10335', label: <div className='label__wrapper'>USDU</div>, crypto: 'USDU'},
  {value: '10332', label: <div className='label__wrapper'>UNTB</div>, crypto: 'UNTB'},
  {value: '10327', label: <div className='label__wrapper'>DAI</div>, crypto: 'DAI'},
  {value: '10331', label: <div className='label__wrapper'>UNI</div>, crypto: 'UNI'},
  {value: '10330', label: <div className='label__wrapper'>LINK</div>, crypto: 'LINK'},
  {value: '10336', label: <div className='label__wrapper'>WAX</div>, crypto: 'WAX'},
  {value: '10333', label: <div className='label__wrapper'>USDC</div>, crypto: 'USDC'},
  {value: '10337', label: <div className='label__wrapper'>XRP</div>, crypto: 'XRP'},
  {value: '10339', label: <div className='label__wrapper'>TRX</div>, crypto: 'TRX'},
  {value: '10344', label: <div className='label__wrapper'>MATIC</div>, crypto: 'MATIC'},
  {value: '10341', label: <div className='label__wrapper'>DOGE</div>, crypto: 'DOGE'},
  {value: '10342', label: <div className='label__wrapper'>BOBC</div>, crypto: 'BOBC'},
  {value: '10340', label: <div className='label__wrapper'>LTC</div>, crypto: 'LTC'},
  {value: '10345', label: <div className='label__wrapper'>DOT </div>, crypto: 'DOT'},
  {value: '10346', label: <div className='label__wrapper'>ATOM</div>, crypto: 'ATOM'},
  {value: '10347', label: <div className='label__wrapper'>AVAX</div>, crypto: 'AVAX'},
  {value: '10348', label: <div className='label__wrapper'>SOLANA</div>, crypto: 'SOLANA'},
  {value: '10349', label: <div className='label__wrapper'>ASTR</div>, crypto: 'ASTR'},
  {value: '10350', label: <div className='label__wrapper'>CHZ</div>, crypto: 'CHZ'},
  {value: '10351', label: <div className='label__wrapper'>FLOW</div>, crypto: 'FLOW'},
  {value: '10352', label: <div className='label__wrapper'>XCH ( Chia)</div>, crypto: 'XCH ( Chia)'},
  {value: '10353', label: <div className='label__wrapper'>KSM</div>, crypto: 'KSM'},
  {value: '10354', label: <div className='label__wrapper'>SAND</div>, crypto: 'SAND'},
].sort(function (a, b) {
  if (a.crypto < b.crypto) {
    return -1;
  }
  if (a.crypto > b.crypto) {
    return 1;
  }
  return 0;
});

export const CUSTOM_SELECT_STYLES = {
  control: (base, state) => ({
    ...base,
    border: 'none',
    background: 'var(--bg_clr_11)',
  }),
  menu: base => ({
    ...base,
    border: 'none',
    zIndex: 100,
  }),
  menuList: base => ({
    ...base,
    background: 'var(--bg_clr_11)',
    border: 'none',
  }),
};

export const CUSTOM_THEME = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    text: 'black',
    primary25: '#F4F5F7',
    primary: '#F4F5F7',
  },
});
