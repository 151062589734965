import React, {Fragment} from 'react';
import I18n from '../../cmp/helpers/I18n';
import Paging from '../../cmp/Paging';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {GetParentReferralUser, GetUsersListRegisteredReferral, GetReferralStatsBrakedown} from '../../api/Actions';
import {ReferralLink} from '../../cmp/referrals/ReferralLink';
import {connect} from 'react-redux';
import Formatter from '../../utils/formatter';
import {CurrencyIcon} from '../../cmp/helpers/CurrencyIcon';

class Referrals extends React.PureComponent {
  state = {
    orderType: null,
    side: null,
    limit: 5,
    offset: 0,
  };

  changePage = (limit, offset) => {
    this.setState({limit, offset}, this.search);
  };

  componentDidMount() {
    this.props.getUsersList();
    this.props.getParentUserReferral();
    this.props.getReferralStatsBrakedown();
  }

  render() {
    const {
      UserSettingsReducer,
      GetParentReferralUserReducer,
      GetReferralStatsBrakedownReducer,
      GetUsersListRegisteredReferralReducer,
    } = this.props;
    if (GetReferralStatsBrakedownReducer.fetching || GetParentReferralUserReducer.fetching) {
      return (
        <div className='text-center'>
          <I18n tKey='common.loading' />
        </div>
      );
    }
    const registeredList = GetUsersListRegisteredReferralReducer.data.userInfoList || [];
    const userList = GetReferralStatsBrakedownReducer.data.stats || [];
    const parentUserReferral = GetParentReferralUserReducer.data;
    let combinedList = [];
    registeredList.map(value => {
      const tmpUser = userList.filter(x => x.payerUsername === value.curUName) || [
        {...value, payerUsername: value.curUName, amount: 0, currency: 'USDU'},
      ];

      combinedList.push(...tmpUser);
    });
    return (
      <Fragment>
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <I18n tKey='referral.invintation' />
          </div>
        </div>
        <div className='block-account'>
          {parentUserReferral.curUName && (
            <div className='block-account-control'>
              <div className='block-account-control_value'>
                <div className='title'>
                  <I18n tKey='referral.name_invited' />
                </div>
                <div className='label-value'>{parentUserReferral.curUName}</div>
              </div>
            </div>
          )}
          <ReferralLink UserSettingsReducer={UserSettingsReducer} />
        </div>
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <I18n tKey='referral:list_registered_accounts' />
          </div>
        </div>

        <Paging
          offset={this.state.offset}
          limit={this.state.limit}
          totalElements={0}
          change={this.changePage}
          loadOnInit={true}
        />
        <div className='tab-pane-body'>
          <table className='table-balance'>
            <thead className='table-balance-header'>
              <tr>
                <th className='text-left'>
                  <I18n tKey='referral.account_name' />
                </th>
                <th className='text-left'>
                  <I18n tKey='referral.currency' />
                </th>
                <th className='text-right'>
                  <I18n tKey='referral.amount' />
                </th>
                {/* <th className="text-right"><I18n tKey="referral.registartion_date" /></th> */}
              </tr>
            </thead>
            <tbody className='table-balance-body'>
              {(combinedList || []).map((user, i) => (
                <tr key={user.curUName + i.toString()}>
                  <td>{user.payerUsername}</td>
                  <td>
                    <CurrencyIcon curName={user.currency} showCurName={true} />{' '}
                  </td>
                  <td className='text-right'>{user.amount}</td>
                  {/* <td className="text-right">{Formatter.dateTimeShort(user.regTime)}</td> */}
                </tr>
              ))}
              {(combinedList || []).length === 0 && (
                <tr className='tr text-center'>
                  <td colSpan={4}>
                    <I18n tKey='common:no_data' />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Paging
            offset={this.state.offset}
            limit={this.state.limit}
            totalElements={0}
            change={this.changePage}
            loadOnInit={false}
          />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = ({
  RequestPasswordResetReducer,
  UserSettingsReducer,
  ConfirmEmailReducer,
  GetParentReferralUserReducer,
  GetReferralStatsBrakedownReducer,
  GetUsersListRegisteredReferralReducer,
}) => ({
  RequestPasswordResetReducer,
  ConfirmEmailReducer,
  UserSettingsReducer,
  GetParentReferralUserReducer,
  GetReferralStatsBrakedownReducer,
  GetUsersListRegisteredReferralReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getUsersList: GetUsersListRegisteredReferral.action,
        getParentUserReferral: GetParentReferralUser.action,
        getReferralStatsBrakedown: GetReferralStatsBrakedown.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Referrals));

export default connectedWithRouter;
