import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {SubmitSanctionsData, UserLogout} from '../../../../api/Actions';

const mapStateToProps = ({SanctionCheckReducer}) => {
  return {
    SanctionCheckReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({sendForm: SubmitSanctionsData.action, logout: UserLogout.action}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
