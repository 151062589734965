const remapTrades = arr => {
  const copyArr = [...arr];

  copyArr.forEach((trade, index) => {
    if (index === 0) {
      trade.isDecreasing = false;
    } else {
      const prevTrade = copyArr[index - 1];
      if (trade.price < prevTrade.price) {
        trade.isDecreasing = true;
      }

      if (trade.price === prevTrade.price) {
        trade.isDecreasing = prevTrade.isDecreasing;
      }
    }
  });

  return copyArr;
};

export default remapTrades;
