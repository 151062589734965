import React from 'react';
import moment from 'moment';

import {ReactComponent as ChildMark} from '../../../../../../assets/images/icon/childmark-small-n.svg';

import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import {CurrencyView} from '../../../../cmp/helpers/CurrencyView';

import Formatter from '../../../../utils/formatter';
import Currency from '../../../../utils/currency';
import I18n from '../../../../cmp/helpers/I18n';

export const OrderItemCell = ({propName, o, m}) => {
  switch (propName) {
    case 'market':
      return (
        <td className='value'>
          <SvgWrapper themeWatch className='ic-size-02 icon-cons' SvgCmp={ChildMark} />
          {o.identificatSymbol}
        </td>
      );
    case 'type':
      return <td className={o.isSell ? 'red' : 'green'}>{o.currentS}</td>;
    case 'openTime':
      return <td>{moment(o.crTime).format('MMM DD YYYY h:mm A')}</td>;
    case 'amount':
      return <td>{Currency.show(o.originalActualQty, m.leftMonetaryUnitCode, true)}</td>;
    case 'price':
      return (
        <td>
          {o.currentT === 'LIMIT' ? (
            <CurrencyView appearance={o.currentT} amount={o.odrValue} type={m.rightMonetaryUnitCode} />
          ) : (
            <I18n tKey='common.market' />
          )}
        </td>
      );
    case 'total':
      return (
        <td>
          {o.currentT === 'LIMIT' ? (
            <CurrencyView appearance={o.currentT} amount={o.total} type={m.rightMonetaryUnitCode} />
          ) : (
            <I18n tKey='common.market' />
          )}
        </td>
      );
    case 'status':
      return (
        <td className='status'>
          <div className='status-info order-info'>
            <div className='status-info-text'>{Formatter.percent(o.execPercent)}</div>
          </div>
          <div
            className={'status-info-bg ' + (o.isSell ? 'sell' : 'buy')}
            style={{width: Formatter.percent(o.execPercent)}}
          >
            &nbsp;
          </div>
        </td>
      );
    default:
      return <td></td>;
  }
};
