import React, {Component} from 'react';
import {createChart} from 'lightweight-charts';
import {connect} from 'react-redux';

import {CHART_TYPE} from '../../../constants';
import {getChartConfig} from './services';

class Chart extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  chart = undefined;

  componentDidMount() {
    if (this.props.chartType === CHART_TYPE.LINE) {
      this.createLineChart();
    } else {
      this.createCandleChart();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.ThemeReducer.darkTheme !== this.props.ThemeReducer.darkTheme ||
      prevProps.chartType !== this.props.chartType ||
      this.props.OHLC !== prevProps.OHLC
    ) {
      if (this.chart) {
        this.chart.remove();
      }

      if (this.props.chartType === CHART_TYPE.LINE) {
        this.createLineChart();
      } else {
        this.createCandleChart();
      }
    }
  }

  createCandleChart = () => {
    if (this.ref.current) {
      const {ThemeReducer} = this.props;
      const config = getChartConfig({darkTheme: ThemeReducer.darkTheme, clientWidth: this.ref.current.clientWidth});

      this.chart = createChart(this.ref.current, config);

      this.chart.timeScale().fitContent();
      const lineSeries = this.chart.addCandlestickSeries({
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderVisible: false,
        wickUpColor: '#26a69a',
        wickDownColor: '#ef5350',
      });

      lineSeries.setData(this.props.OHLC);
    }
  };

  createLineChart = () => {
    if (this.ref.current) {
      const {ThemeReducer} = this.props;
      const config = getChartConfig({darkTheme: ThemeReducer.darkTheme, clientWidth: this.ref.current.clientWidth});

      this.chart = createChart(this.ref.current, config);

      this.chart.timeScale().fitContent();
      const lineSeries = this.chart.addLineSeries({color: 'rgb(1, 188, 141)'});

      lineSeries.setData(this.props.OHLC.map(({time, close}) => ({time, value: close})));
    }
  };

  render() {
    return <div style={{width: '100%'}} ref={this.ref}></div>;
  }
}

const mapStateToProps = ({ThemeReducer, MarketListReducer}) => {
  return {ThemeReducer, MarketListReducer};
};

export default connect(mapStateToProps, undefined)(Chart);
