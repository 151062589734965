import React from 'react';
import axios from 'axios';

const returnOption = ({id, image, symbol, name}) => {
  return {
    label: returnOptionLabel(image, symbol),
    id,
    value: {
      src: image,
      name,
      symbol,
    },
  };
};

export const returnOptionLabel = (imageSrc, currencyCode) => {
  return (
    <div className='fast-exchange-block__currency-select-option'>
      <img alt={currencyCode} src={imageSrc} />
      <span className='fast-exchange-block__select-label'>{currencyCode}</span>
    </div>
  );
};

export const mapMarketToSelectOption = markets => {
  return markets.map(({id, image, symbol, name}) => returnOption({id, image, symbol, name}));
};

const coinsMarketsIds = 'bitcoin,ethereum,tether,tron,usd-coin,matic-network';

export const getCoinsMarketsByFiat = fiatId =>
  axios(
    `https://api.coingecko.com/api/v3/coins/markets?vs_currency=${fiatId}&ids=${coinsMarketsIds}&order=market_cap_desc&per_page=10&page=1&sparkline=false`
  );

export const getCoinStats = coinId =>
  axios(
    `https://api.coingecko.com/api/v3/coins/${coinId}?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false`
  );

export const getCoinOHLCByFiat = ({fiatId, coinId, days}) =>
  axios(`https://api.coingecko.com/api/v3/coins/${coinId}/ohlc?vs_currency=${fiatId}&days=${days}`);
