// @flow

/* Main imports and helpers */
import * as React from 'react';
import {Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link, withRouter} from 'react-router-dom';
/* Actions */
import InputPopup from '../InputPopup';
import {determineSecurityLevels} from '../../../utils/validation';
import $ from 'jquery';
import Recaptcha from 'react-recaptcha';
import {GetReferralCode, UserRegister} from '../../../api/Actions';
import {CLOSE_POPUP, OPEN_POPUP} from '../../../reducers/PopupReducer';
import {LOGIN_POPUP} from './LoginPopup';
import {FORGOT_PASSWORD_POPUP} from './ForgotPasswordPopup';
import {SvgWrapper} from '../../helpers/SvgWrapper';
import {ReactComponent as Envelope} from '../../../../../assets/images/modal/envelope.svg';
import {translate} from '../../../utils/i18n';
import I18n from '../../helpers/I18n';

export const REGISTRATION_POPUP = 'REGISTRATION_POPUP';

class RegistrationPopup extends React.PureComponent<void, ComponentState> {
  state = {
    email: '',
    password: '',
    password2: '',
    username: '',
    secureType: {},
    referralCode: (this.props.referralCode || '').trim(),
    // referralCodeError: !this.props.referralCode, todo, redo it if code is required
  };
  timerRef = null;

  changeReferralCode = code => {
    if (this.timerRef) {
      clearTimeout(this.timerRef);
    }
    this.timerRef = setTimeout(() => {
      this.setState({referralCode: (code || '').trim()}, this.checkCode);
    }, 1000);
  };

  checkCode = () => {
    this.props
      .getReferral(null, {referralCode: this.state.referralCode})
      .then(r => this.setState({referralCodeError: r.isError}));
  };

  componentDidMount() {
    this.props.referralCode && this.checkCode();
  }

  componentWillUnmount() {
    //this.props.clearError();
  }

  clearForm = () => {
    //this.props.clearError();
    this.setState({
      email: '',
      password: '',
      password2: '',
      username: '',
    });
  };

  validateEmail = val => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(val);
  };

  handlePasswordFieldChange = value => {
    let secureType = determineSecurityLevels(value);
    this.setState(
      {
        password: value,
        secureType,
      },
      () =>
        (!secureType.numberRegex ||
          !secureType.uppercaseRegex ||
          !secureType.lowercaseRegex ||
          /*!secureType.symbolsRegex ||*/ !secureType.size) &&
        this.setState({passwordError: {parsedError: 'common.weak_pswd'}})
    );
  };

  checkValidation = (inputName, val) => {
    const {password, password2} = this.state;
    switch (inputName) {
      case 'email':
        !this.validateEmail(val)
          ? this.setState({emailError: {parsedError: 'popup.valid_email'}})
          : this.setState({emailError: null});
        break;
      case 'username':
        !val
          ? this.setState({usernameError: {parsedError: 'popup.enter_username'}})
          : this.setState({usernameError: null});
        break;
      case 'password':
        !val
          ? this.setState({passwordError: {parsedError: 'common.create_pswd'}})
          : this.setState({passwordError: null});
        val !== password2
          ? this.setState({password2Error: {parsedError: 'common.pswd_equal'}})
          : this.setState({password2Error: null});
        break;
      case 'password2':
        !val
          ? this.setState({password2Error: {parsedError: 'common.repeat_pswd'}})
          : this.setState({password2Error: null});
        password !== val
          ? this.setState({password2Error: {parsedError: 'common.pswd_equal'}})
          : this.setState({password2Error: null});
        break;
    }
  };

  handleConfirmationField = value => {
    this.setState({
      password2: value,
    });
  };

  signup = () => {
    const {username, email, password, password2, secureType} = this.state,
      {GetReferralCodeReducer} = this.props;
    this.setState({
      emailError: null,
      usernameError: null,
      passwordError: null,
      password2Error: null,
      termsAccepted: null,
    });

    let hasError = false;
    if (!email) {
      this.setState({emailError: {parsedError: 'popup.enter_email'}});
      hasError = true;
    }

    if (!this.validateEmail(email)) {
      this.setState({emailError: {parsedError: 'popup.valid_email'}});
      hasError = true;
    }

    if (!username) {
      this.setState({usernameError: {parsedError: 'popup.enter_username'}});
    }

    if (!password) {
      this.setState({passwordError: {parsedError: 'common.create_pswd'}});
      hasError = true;
    } else if (
      !secureType.numberRegex ||
      !secureType.uppercaseRegex ||
      !secureType.lowercaseRegex ||
      /*!secureType.symbolsRegex ||*/ !secureType.size
    ) {
      this.setState({passwordError: {parsedError: 'common.weak_pswd'}});
      hasError = true;
    }

    if (!password2) {
      this.setState({password2Error: {parsedError: 'common.repeat_pswd'}});
      hasError = true;
    }

    if (password !== password2) {
      this.setState({password2Error: {parsedError: 'common.pswd_equal'}});
      hasError = true;
    }

    if (!$('#agree1').prop('checked')) {
      this.setState({termsAccepted: translate('popup.read_agree')});
      hasError = true;
    }

    if (!hasError) {
      //add this path in case if code is required && this.state.referralCode && !this.state.referralCodeError
      this.resetRecaptcha();
      const data = {
        personalityName: username,
        electronicPostAddr: email,
        lockPickSymbolRow: password,
        referralCode: this.state.referralCode,
      };
      //todo recaptcha
      const recaptcha =
        this.props.ApplicationSettingsReducer.data && this.props.ApplicationSettingsReducer.data.recaptcha;
      if (recaptcha && recaptcha.enabled) {
        data.captchaResponseToken = $('.g-recaptcha-response:first').val();
      }
      this.props.register(data);
    }
  };

  recaptchaInstance = null;

  resetRecaptcha = () => {
    if (this.recaptchaInstance) {
      this.recaptchaInstance.reset();
    }
  };

  findValidationError = (arr, inputName) => {
    const details = (arr || []).find(element => element.field === inputName);
    return details ? {error: details.details} : null;
  };

  onPressEnter = event => {
    if (event.key === 'Enter') {
      this.signup();
    }
  };

  render() {
    const {UserRegisterReducer, ApplicationSettingsReducer, GetReferralCodeReducer, SessionReducer} = this.props,
      error = UserRegisterReducer.error,
      fetching = UserRegisterReducer.fetching,
      nextStep = UserRegisterReducer.data.followingLevel;

    const recaptcha = ApplicationSettingsReducer.recaptcha || {};

    if (SessionReducer.masterPersonality && SessionReducer.masterPersonality.uniqueSymbolRow) {
      this.props.closePopup();
      return null;
    }

    return (
      <Fragment>
        {nextStep !== 'EMAIL_CONFIRM_REQUIRED' && (
          <div className='modal-signup_form'>
            <h2>
              <I18n tKey='popup.create_ac' />
            </h2>
            <form className='ui-form'>
              <div className='ui-form-text text-center'>
                <I18n tKey='popup.already_sign' /> &nbsp;
                <a onClick={this.props.openLoginPopup}>
                  <I18n tKey='common.login' />
                </a>
              </div>
              <div className='relative'>
                {GetReferralCodeReducer.fetching && (
                  <div className='loading-block'>
                    <I18n tKey='common.loading' />
                  </div>
                )}
                <InputPopup
                  onChange={code => this.changeReferralCode(code)}
                  error={
                    this.state.referralCodeError && this.state.referralCode ? {error: 'common.user_not_found'} : null
                  }
                  inputName={'referralcode'}
                  value={this.state.referralCode}
                  placeholder='referral:name_inviter'
                  label='referral:enter_name_inviter:'
                />
              </div>
              <InputPopup
                error={this.state.emailError || (error && this.findValidationError(error.details, 'email'))}
                placeholder='common.email'
                onKeyDown={this.onKeyDown}
                value={this.state.email}
                onChange={value => this.setState({email: value})}
                validation={this.checkValidation}
                inputName={'email'}
                label='popup.enter_email'
                onPressEnter={this.onPressEnter}
              />
              <InputPopup
                error={this.state.usernameError || (error && this.findValidationError(error.details, 'username'))}
                placeholder='popup.username'
                onKeyDown={this.onKeyDown}
                value={this.state.username}
                validation={this.checkValidation}
                inputName={'username'}
                onChange={value => this.setState({username: value})}
                label='popup.enter_username'
                onPressEnter={this.onPressEnter}
              />
              <InputPopup
                error={this.state.passwordError}
                placeholder='common.password'
                onKeyDown={this.onKeyDown}
                value={this.state.password}
                onChange={this.handlePasswordFieldChange}
                validation={this.checkValidation}
                inputName={'password'}
                label='popup.imagine_pswd'
                className={'ui-form-control-secure'}
                onPressEnter={this.onPressEnter}
                type='password'
              >
                <Fragment>
                  <div className={`ui-form-control-rules_item ${this.state.secureType.lowercaseRegex ? 'active' : ''}`}>
                    <I18n tKey='popup.one_lowercase' />
                  </div>
                  <div className={`ui-form-control-rules_item ${this.state.secureType.uppercaseRegex ? 'active' : ''}`}>
                    <I18n tKey='popup.one_uppercase' />
                  </div>
                  <div className={`ui-form-control-rules_item ${this.state.secureType.numberRegex ? 'active' : ''}`}>
                    <I18n tKey='popup.one_num' />
                  </div>
                  {/*<div className={`ui-form-control-rules_item ${this.state.secureType.symbolsRegex ? "active" : ""}`}>One special character</div>*/}
                  <div className={`ui-form-control-rules_item ${this.state.secureType.size ? 'active' : ''}`}>
                    <I18n tKey='popup.min_characters' />
                  </div>
                </Fragment>
              </InputPopup>
              <InputPopup
                error={this.state.password2Error}
                placeholder='popup.confirm_pswd'
                onKeyDown={this.onKeyDown}
                value={this.state.password2}
                onChange={this.handleConfirmationField}
                validation={this.checkValidation}
                inputName={'password2'}
                label='common.repeat_pswd'
                className={'ui-form-control-secure'}
                onPressEnter={this.onPressEnter}
                isPasteEventPrevented
                type='password'
              />

              {recaptcha && recaptcha.enabled && recaptcha.publicKey && (
                <div className='ui-form-сaptcha'>
                  <Recaptcha ref={e => (this.recaptchaInstance = e)} sitekey={recaptcha.publicKey} />
                </div>
              )}

              <div className={`ui-form-checkbox ${this.state.termsAccepted ? 'alert' : ''}`}>
                <input type='checkbox' id='agree1' />
                <label htmlFor='agree1'>
                  <I18n tKey='popup.by_click' /> <br /> {translate('popup.to_the')}{' '}
                  <Link to='/page/information/user-agreement' target='_blank'>
                    <I18n tKey='common.terms' />
                  </Link>
                </label>
                {/*{this.state.termsAccepted && <div className="popup-error-message">*/}
                {/*{this.state.termsAccepted}*/}
                {/*</div>}*/}
              </div>
              <div className='ui-form-btn'>
                {fetching && (
                  <button disabled type='button' className='btn btn-md btn-primary btn-full'>
                    <I18n tKey='common.sign_up' />
                  </button>
                )}
                {!fetching && (
                  <button
                    type='button'
                    disabled={this.state.referralCode && this.state.referralCodeError}
                    onClick={this.signup}
                    className='btn btn-md btn-primary btn-full'
                  >
                    <I18n tKey='common.sign_up' />
                  </button>
                )}
              </div>
              <div className='popup-error-message common sign-up-login-popup-error-message'>
                {error && (
                  <div>
                    <I18n tKey={error.parsedError || error.error} />
                  </div>
                )}
              </div>
            </form>
          </div>
        )}

        {nextStep === 'EMAIL_CONFIRM_REQUIRED' && (
          <div className='modal-signup_confirm'>
            <div className='image'>
              <SvgWrapper className='ic-size-19' SvgCmp={Envelope} />
              {/*<div id="open"/>*/}
            </div>
            <h2>
              <I18n tKey='popup.email_confirm' />
            </h2>
            <div className='text'>
              <I18n tKey='popup.complete_register' />
            </div>
            <div className='box-btn'>
              <a
                data-target='#login'
                onClick={this.clearForm}
                data-toggle='modal'
                data-dismiss='modal'
                className='btn btn-md btn-primary btn-full'
              >
                <I18n tKey='common.proceed' />
              </a>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  UserRegisterReducer,
  ApplicationSettingsReducer,
  GetReferralCodeReducer,
  SessionReducer,
}) => ({UserRegisterReducer, ApplicationSettingsReducer, GetReferralCodeReducer, SessionReducer});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        register: UserRegister.action,
        getReferral: GetReferralCode.action,
      },
      dispatch
    ),
    openRegistrationPopup: () => dispatch({type: OPEN_POPUP, name: REGISTRATION_POPUP}),
    openForgotPasswordPopup: () => dispatch({type: OPEN_POPUP, name: FORGOT_PASSWORD_POPUP}),
    openLoginPopup: () => dispatch({type: OPEN_POPUP, name: LOGIN_POPUP}),
    closePopup: () => dispatch({type: CLOSE_POPUP}),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationPopup));

export default connectedWithRouter;
