import {ReactComponent as Btc} from '../../../../../../../assets/images/currency/BTC.svg';
import {ReactComponent as Eth} from '../../../../../../../assets/images/currency/ETH.svg';
import {ReactComponent as Cru} from '../../../../../../../assets/images/currency/CRU.svg';
import {ReactComponent as Eos} from '../../../../../../../assets/images/currency/eos.svg';
import {ReactComponent as Usdu} from '../../../../../../../assets/images/currency/USDU.svg';
import {ReactComponent as Untb} from '../../../../../../../assets/images/currency/UNTB.svg';
import {ReactComponent as Usdt} from '../../../../../../../assets/images/currency/USDT.svg';
import {ReactComponent as Usdc} from '../../../../../../../assets/images/currency/USDC.svg';
import {ReactComponent as Link} from '../../../../../../../assets/images/currency/LINK.svg';
import {ReactComponent as Dai} from '../../../../../../../assets/images/currency/DAI.svg';
import {ReactComponent as Uni} from '../../../../../../../assets/images/currency/UNI.svg';
import {ReactComponent as Usd} from '../../../../../../../assets/images/currency/usd.svg';
import {ReactComponent as Eur} from '../../../../../../../assets/images/currency/eur.svg';
import {ReactComponent as Wax} from '../../../../../../../assets/images/currency/WAX.svg';
import {ReactComponent as Xrp} from '../../../../../../../assets/images/currency/XRP.svg';
import {ReactComponent as Bnb} from '../../../../../../../assets/images/currency/BNB.svg';
import {ReactComponent as Tron} from '../../../../../../../assets/images/currency/TRON.svg';
import {ReactComponent as Ltc} from '../../../../../../../assets/images/balances/LTC.svg';
import {ReactComponent as Doge} from '../../../../../../../assets/images/balances/DOGE.svg';
import {ReactComponent as Matic} from '../../../../../../../assets/images/balances/MATIC.svg';

export const USER_MARKET_COLUMN_PARAMS = 'USER_MARKET_COLUMN_PARAMS';

export const ICONS = {
  btc: Btc,
  eth: Eth,
  cru: Cru,
  eos: Eos,
  usdu: Usdu,
  untb: Untb,
  usdt: Usdt,
  usdc: Usdc,
  link: Link,
  dai: Dai,
  uni: Uni,
  usd: Usd,
  eur: Eur,
  wax: Wax,
  xrp: Xrp,
  bnb: Bnb,
  trx: Tron,
  ltc: Ltc,
  doge: Doge,
  matic: Matic,
};

export const MARKET_SORTING = {
  pair_asc: 'pair_asc',
  pair_desc: 'pair_desc',
  price_asc: 'price_asc',
  price_desc: 'price_desc',
  change_asc: 'change_asc',
  change_desc: 'change_desc',
  volume_desc: 'volume_desc',
  volume_asc: 'volume_asc',
};
