import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ReactComponent as SearchIcon} from '../../../assets/images/documents-history/search.svg';

import I18n from '../cmp/helpers/I18n';
import Table from '../cmp/documentsHistory/Table';
import FilesUpload from '../cmp/documentsHistory/FilesUpload';
import SuccessDialog from '../cmp/notification/SuccessDialog';

import {GetDocumentRequests} from '../api/Actions';

class DocumentsHistoryPage extends PureComponent {
  static propTypes = {
    documentRequests: PropTypes.shape({
      fetching: PropTypes.bool,
      completed: PropTypes.bool,
      data: PropTypes.shape({
        responseList: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    darkTheme: PropTypes.bool,
    getDocumentRequests: PropTypes.func,
  };

  state = {
    searchFragment: '',
    displayedData: [],
    filesUploadIsOpen: false,
    selectedRequest: null,
    successDialogIsOpen: false,
  };

  componentDidMount() {
    const {
      documentRequests: {data},
    } = this.props;
    if (data.responseList && data.responseList.length) {
      this.setState({displayedData: data.responseList});
    } else this.props.getDocumentRequests();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      documentRequests: {data},
      documentRequests,
      attachedDocumentRequest,
    } = this.props;
    const {searchFragment} = this.state;
    const lowerFragment = searchFragment.toLowerCase();

    if (prevProps.attachedDocumentRequest.fetching && attachedDocumentRequest.completed && !documentRequests.fetching) {
      this.setState({successDialogIsOpen: true});
      this.props.getDocumentRequests();
    }

    if (data.responseList && data.responseList.length) {
      if (!searchFragment) {
        return this.setState({displayedData: data.responseList});
      }

      if (prevState.searchFragment !== searchFragment) {
        const filteredList = data.responseList.filter(item => {
          if (item.status === 'ABANDONED') {
            return 'approved'.includes(lowerFragment) || item.comment.toLowerCase().includes(lowerFragment);
          }

          return (
            item.status.toLowerCase().includes(lowerFragment) || item.comment.toLowerCase().includes(lowerFragment)
          );
        });
        return this.setState({displayedData: filteredList});
      }
    }
  }

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  handleOpenFilesUploadModal = request => () => {
    if (request) {
      this.setState({selectedRequest: request});
    }

    this.setState(state => ({filesUploadIsOpen: !state.filesUploadIsOpen}));
  };

  closeUploadModal = () => {
    this.setState({filesUploadIsOpen: false});
  };

  handleOpenSuccessDialog = () => {
    this.setState(state => ({successDialogIsOpen: !state.successDialogIsOpen}));
  };

  render() {
    const {documentRequests, darkTheme} = this.props;
    const {searchFragment, displayedData, filesUploadIsOpen, selectedRequest, successDialogIsOpen} = this.state;

    return (
      <div className='documents-history'>
        <div className='documents-history_wrapper'>
          <I18n tKey='documents.document_upload_history' className='documents-history_title' />
          <div
            className={!darkTheme ? 'documents-history_content' : 'documents-history_content documents-history__dark'}
          >
            <div className={'documents-history_content_search'}>
              <SearchIcon className='documents-history_content_search_icon' />
              <input
                name='searchFragment'
                value={searchFragment}
                onChange={this.handleChange}
                className={
                  !darkTheme
                    ? 'documents-history_content_search_input'
                    : 'documents-history_content_search_input documents-history__dark'
                }
              />
            </div>
            <Table
              isLoading={documentRequests.fetching}
              data={displayedData}
              onOpenFilesUploadModal={this.handleOpenFilesUploadModal}
            />
          </div>
        </div>
        <FilesUpload open={filesUploadIsOpen} onClose={this.closeUploadModal} request={selectedRequest} />
        <SuccessDialog open={successDialogIsOpen} onClose={this.handleOpenSuccessDialog}>
          <I18n tKey='documents.you_successfully_submitted_new_document' />
        </SuccessDialog>
      </div>
    );
  }
}

const mapStateToProps = ({documentRequestsReducer, attachedDocumentRequestReducer, ThemeReducer}) => ({
  documentRequests: documentRequestsReducer,
  attachedDocumentRequest: attachedDocumentRequestReducer,
  darkTheme: ThemeReducer.darkTheme,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDocumentRequests: GetDocumentRequests.action,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsHistoryPage);
