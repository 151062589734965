import React, {Fragment} from 'react';
import I18n from '../helpers/I18n';

class TradingNotificationTab extends React.Component {
  displayName: 'TradingNotificationTab';

  render() {
    return (
      <Fragment>
        <div className='notification-center_title'>
          Trading notifications
          <I18n tKey='notification.trading' />
        </div>
      </Fragment>
    );
  }
}

export default TradingNotificationTab;
