import React, {Fragment} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Axios from 'axios';

import {CustomInput} from '../../cmp/helpers/CustomInput';
import AssetDetailItem from './AssetDetailItem';
import connect from './connect';
import classNames from 'classnames';
import Loader from '../../cmp/Loader';
import {ReactComponent as Search} from '../../../../assets/images/icon/search.svg';
import {HARDCODED_DESCRIPTION, HARDCODED_TOKENS_DATA, TOKEN_DATA} from './constants';

class AssetInfo extends React.Component {
  state = {search: '', activeAsset: '', assetInfo: null, isInfoLoading: false};

  componentDidMount() {
    if (this.props.match.params.assetName) {
      this.setState({activeAsset: this.props.match.params.assetName, isInfoLoading: true});
      this.getAssetInfo(this.props.match.params.assetName);
    }
  }

  getAssetInfo = async shortName => {
    if (HARDCODED_TOKENS_DATA.some(token => token.toUpperCase() === shortName.toUpperCase())) {
      this.setState({assetInfo: TOKEN_DATA[shortName], isInfoLoading: false});
      return;
    }

    const {data, msg} = await Axios.get(
      `https://www.cointiger.com/exchange/internal/sperate/public/coin/introduce/web?shortName=${shortName}`
    );

    this.setState({assetInfo: data.data, isInfoLoading: false});
  };

  getAssetClickHandler = activeAsset => () => {
    if (activeAsset !== this.state.activeAsset) {
      this.setState({activeAsset, isInfoLoading: true});
      this.getAssetInfo(activeAsset);
    }
  };

  handleInputChange = (inputName, value) => {
    this.setState({[inputName]: value});
  };

  getAssetInfoProp = propName => {
    if (!this.state.assetInfo) {
      return '--';
    }

    if (propName === 'browser' && this.state.activeAsset === 'EOS') {
      return 'https://eosauthority.com';
    }

    if (propName === 'issueTime') {
      if (moment(this.state.assetInfo[propName]).isValid()) {
        return moment(this.state.assetInfo[propName]).format('YYYY-MM-DD');
      }

      return '--';
    }

    return this.state.assetInfo[propName] || '--';
  };

  render() {
    let assetsList = (this.props.CurrencyListReducer.list || []).filter(({currentT}) => currentT !== 'FIAT');

    if (this.state.search) {
      assetsList = assetsList.filter(
        ({mUCryptogram}) => mUCryptogram.toUpperCase().indexOf(this.state.search.toUpperCase()) !== -1
      );
    }

    return (
      <div className='assets-info-page'>
        <div className='assets-block'>
          <div className='block-left'>
            <div className='asset-input-wrapper'>
              <Search className='search-icon' />
              <CustomInput onChange={this.handleInputChange} name='search' value={this.state.search} />
            </div>

            <ul className='assets-list'>
              {assetsList.map(({mUBanner, mUCryptogram}) => (
                <li>
                  <Link
                    onClick={this.getAssetClickHandler(mUCryptogram)}
                    className={classNames({['active']: this.state.activeAsset === mUCryptogram})}
                    to={`/asset_info/${mUCryptogram}`}
                  >
                    {mUCryptogram} ({mUBanner})
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className='block-right'>
            {this.state.isInfoLoading ? (
              <div className='asset-info-loader-container'>
                <Loader className='loader-bg' open />
              </div>
            ) : (
              <Fragment>
                {this.state.activeAsset && (
                  <Fragment>
                    <h2>{this.state.activeAsset}</h2>

                    <div className='asset-details'>
                      <AssetDetailItem value={this.getAssetInfoProp('issueTime')} label='asset_info:issue_time' />
                      <AssetDetailItem value={this.getAssetInfoProp('issueVolume')} label='asset_info:total_supply' />
                      <AssetDetailItem
                        value={this.getAssetInfoProp('circulateValume')}
                        label='asset_info:circulation'
                      />
                      <AssetDetailItem value={this.getAssetInfoProp('whiteBook')} label='asset_info:white_paper' />
                      <AssetDetailItem value={this.getAssetInfoProp('website')} label='asset_info:website' />
                      <AssetDetailItem value={this.getAssetInfoProp('browser')} label='asset_info:block_explorer' />
                    </div>

                    <div className='asset-description'>
                      {(this.state.assetInfo && this.state.assetInfo.description) ||
                        HARDCODED_DESCRIPTION[this.state.activeAsset]}
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(AssetInfo);
