// @flow
import * as Actions from '../api/Actions';
import {OrderStopList, UserLogin} from '../api/Actions';
import {APPLICATION_STARTED} from '../store';
import storageHelper from 'storage-helper';
import jwtDecode from 'jwt-decode';
import {USER_STORAGE_KEY} from './SessionReducer';
import {STORAGE_KEY} from './LngReducer';
import {SUBSCRIBE_TO_ALL_MARKET_STATS} from './WsReducer';

export const REFERRAL_REMINDER = 'REFERRAL_REMINDER';
export const DEACTIVATE_REMINDER = 'DEACTIVATE_REMINDER';
const savedLocalFlag = storageHelper.getItem(REFERRAL_REMINDER, true);
const initState = {
  referralReminderIsActive: savedLocalFlag !== null ? savedLocalFlag : true,
};

function ApplicationInitReducer(state = initState, action) {
  switch (action.type) {
    case APPLICATION_STARTED: {
      //start rewrite init app here
      const savedSession = JSON.parse(storageHelper.getItem(USER_STORAGE_KEY)) || {};
      if (savedSession.specialMark) {
        const decoded = jwtDecode(savedSession.specialMark);
        if (decoded.exp * 1000 > new Date().getTime()) {
          if (!savedSession.followingLevel || savedSession.followingLevel === 'NO') {
            action.asyncDispatch({
              type: UserLogin.events.onSuccess,
              data: savedSession,
            });
          }
        } else {
          storageHelper.removeItem(USER_STORAGE_KEY);
        }
      }
      const LNG = storageHelper.getItem(STORAGE_KEY, true) || 'en';
      Actions.GetDictionary.action({locale: LNG})(action.asyncDispatch);
      Actions.GetCurrencyXRates.action({baseCurrency: 'USDT'})(action.asyncDispatch);
      Actions.LanguagesList.action()(action.asyncDispatch);
      Actions.ApplicationSettings.action()(action.asyncDispatch);
      Actions.CryptoPrices.action()(action.asyncDispatch);
      Actions.MarketList.action()(action.asyncDispatch);
      action.asyncDispatch({type: SUBSCRIBE_TO_ALL_MARKET_STATS});
      return state;
    }

    case Actions.CurrencyList.events.onSuccess: {
      const usdObj = action.data.respItemCollection.filter(elem => elem.mUCryptogram === 'USD')[0] || {
        leftMonetaryUnitIdentifier: 7,
      };
      const btcObj = action.data.respItemCollection.filter(elem => elem.mUCryptogram === 'BTC')[0] || {
        leftMonetaryUnitIdentifier: 1,
      };
      const usduObj = action.data.respItemCollection.filter(elem => elem.mUCryptogram === 'USDU')[0] || {
        leftMonetaryUnitIdentifier: 4,
      };
      Actions.CurrencyConvert.action({
        leftMonetaryUnitIdentifier: usdObj.identificatSymbol,
        actualQty: 1,
      })(action.asyncDispatch); //
      Actions.CurrencyConvert.action({
        leftMonetaryUnitIdentifier: btcObj.identificatSymbol,
        actualQty: 1,
      })(action.asyncDispatch); //
      Actions.CurrencyConvert.action({
        leftMonetaryUnitIdentifier: usduObj.identificatSymbol,
        actualQty: 1,
      })(action.asyncDispatch); //
      return state;
    }

    case DEACTIVATE_REMINDER: {
      storageHelper.setItem(REFERRAL_REMINDER, JSON.stringify(!action.payload.isChecked));
      return {...state, referralReminderIsActive: false};
    }

    case Actions.UserLogin.events.onSuccess:
    case Actions.LoginTwoFactorAuth.events.onSuccess: {
      const savedLocalFlag = storageHelper.getItem(REFERRAL_REMINDER, true);
      if (!action.data.masterPersonality.isFrozen) {
        setTimeout(() => {
          Actions.UserSettings.action()(action.asyncDispatch);
          Actions.MsgsList.action()(action.asyncDispatch);
          Actions.WalletList.action()(action.asyncDispatch);
          Actions.AccountList.action()(action.asyncDispatch);
          Actions.OrderList.action({rng: 100, listOfst: 0})(action.asyncDispatch);
          Actions.OrderStopList.action()(action.asyncDispatch);
          Actions.UserTradeHistory.action(null, {
            masterIdentifier: action.data.masterPersonality.masterRespList[0],
            rng: 50,
            listOfst: 0,
          })(action.asyncDispatch);
        }, 100);
        return {...state, referralReminderIsActive: savedLocalFlag !== null ? savedLocalFlag : true};
      }
    }

    default:
      return state;
  }
}

export default ApplicationInitReducer;
