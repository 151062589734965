import * as React from 'react';
import {Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import $ from 'jquery';
import Recaptcha from 'react-recaptcha';
import classNames from 'classnames';

import InputPopup from '../../cmp/popups/InputPopup';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import I18n from '../../cmp/helpers/I18n';
import Logo from '../../cmp/Logo';

import {ReactComponent as Envelope} from '../../../../assets/images/modal/envelope.svg';

import {determineSecurityLevels} from '../../utils/validation';
import {translate} from '../../utils/i18n';
import {URLMAP} from '../../utils/const';
import {getReferralCode} from './services/idnex';
import connect from './connects';
import {
  MAX_USER_NAME_LENGTH,
  MIN_USER_NAME_LENGTH,
  CUSTOM_SELECT_STYLES,
  SELECT_THEME,
  COUNTRIES,
  EU_COUNTRIES,
  OTHER_COUNTRIES,
} from './constants';
import Select from 'react-select';

class SignUp extends React.PureComponent<void> {
  state = {
    email: '',
    password: '',
    password2: '',
    username: '',
    countryOfCitizenship: undefined,
    countryOfResidence: undefined,
    countryOfDomicile: undefined,
    secureType: {},
    referralCode: (getReferralCode(this.props.location.search) || '').trim(),
    // referralCodeError: !this.props.referralCode, todo, redo it if code is required
  };
  timerRef = null;

  selectComponents = {
    IndicatorSeparator: () => null,
  };

  changeReferralCode = code => {
    if (this.timerRef) {
      clearTimeout(this.timerRef);
    }
    this.timerRef = setTimeout(() => {
      this.setState({referralCode: (code || '').trim()}, this.checkCode);
    }, 1000);
  };

  checkCode = () => {
    this.props
      .getReferral(null, {referralCode: this.state.referralCode})
      .then(r => this.setState({referralCodeError: r.isError}));
  };

  componentDidMount() {
    this.props.registrationClear();

    getReferralCode(this.props.location.search) && this.checkCode();
  }

  clearForm = () => {
    //this.props.clearError();
    this.setState({
      email: '',
      password: '',
      password2: '',
      username: '',
    });

    this.props.history.push(URLMAP.TERMINAL);
  };

  validateEmail = val => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(val);
  };

  handlePasswordFieldChange = value => {
    let secureType = determineSecurityLevels(value);
    this.setState(
      {
        password: value,
        secureType,
      },
      () =>
        (!secureType.numberRegex ||
          !secureType.uppercaseRegex ||
          !secureType.lowercaseRegex ||
          /*!secureType.symbolsRegex ||*/ !secureType.size) &&
        this.setState({passwordError: {parsedError: 'common.weak_pswd'}})
    );
  };

  checkValidation = (inputName, val) => {
    const {password, password2} = this.state;
    switch (inputName) {
      case 'email':
        !this.validateEmail(val)
          ? this.setState({emailError: {parsedError: 'popup.valid_email'}})
          : this.setState({emailError: null});
        break;
      case 'username':
        if (!val) {
          this.setState({usernameError: {parsedError: 'popup.enter_username'}});
          return;
        }

        if (val.length < MIN_USER_NAME_LENGTH) {
          this.setState({usernameError: {parsedError: 'popup.username_short'}});
          return;
        }

        this.setState({usernameError: null});
        break;
      case 'password':
        !val
          ? this.setState({passwordError: {parsedError: 'common.create_pswd'}})
          : this.setState({passwordError: null});
        val !== password2
          ? this.setState({password2Error: {parsedError: 'common.pswd_equal'}})
          : this.setState({password2Error: null});
        break;
      case 'password2':
        !val
          ? this.setState({password2Error: {parsedError: 'common.repeat_pswd'}})
          : this.setState({password2Error: null});
        password !== val
          ? this.setState({password2Error: {parsedError: 'common.pswd_equal'}})
          : this.setState({password2Error: null});
        break;
    }
  };

  handleConfirmationField = value => {
    this.setState({
      password2: value,
    });
  };

  signup = () => {
    const {username, email, password, password2, secureType} = this.state,
      {GetReferralCodeReducer} = this.props;
    this.setState({
      emailError: null,
      usernameError: null,
      passwordError: null,
      password2Error: null,
      termsAccepted: null,
    });

    let hasError = false;
    if (!email) {
      this.setState({emailError: {parsedError: 'popup.enter_email'}});
      hasError = true;
    }

    if (!this.validateEmail(email)) {
      this.setState({emailError: {parsedError: 'popup.valid_email'}});
      hasError = true;
    }

    if (!username) {
      this.setState({usernameError: {parsedError: 'popup.enter_username'}});
      hasError = true;
    }

    if (username.length < MIN_USER_NAME_LENGTH) {
      this.setState({usernameError: {parsedError: 'popup.username_short'}});
      hasError = true;
    }

    if (!password) {
      this.setState({passwordError: {parsedError: 'common.create_pswd'}});
      hasError = true;
    } else if (
      !secureType.numberRegex ||
      !secureType.uppercaseRegex ||
      !secureType.lowercaseRegex ||
      /*!secureType.symbolsRegex ||*/ !secureType.size
    ) {
      this.setState({passwordError: {parsedError: 'common.weak_pswd'}});
      hasError = true;
    }

    if (!password2) {
      this.setState({password2Error: {parsedError: 'common.repeat_pswd'}});
      hasError = true;
    }

    if (password !== password2) {
      this.setState({password2Error: {parsedError: 'common.pswd_equal'}});
      hasError = true;
    }

    if (!$('#agree').prop('checked')) {
      this.setState({termsAccepted: translate('popup.read_agree')});
      hasError = true;
    }

    if (!hasError) {
      const {countryOfCitizenship, countryOfResidence, countryOfDomicile} = this.state;
      const countryInfo = {
        countryOfCitizenship,
      };

      if (this.isEuCountry()) {
        countryInfo.countryOfResidence = countryOfResidence;
        countryInfo.countryOfDomicile = countryOfDomicile;
      }

      if (this.isOtherCountry()) {
        countryInfo.countryOfResidence = countryOfResidence;
      }

      //add this path in case if code is required && this.state.referralCode && !this.state.referralCodeError
      this.resetRecaptcha();
      const data = {
        personalityName: username,
        electronicPostAddr: email,
        lockPickSymbolRow: password,
        referralCode: this.state.referralCode,
        ...countryInfo,
      };
      //todo recaptcha
      const recaptcha =
        this.props.ApplicationSettingsReducer.data && this.props.ApplicationSettingsReducer.data.recaptcha;
      if (recaptcha && recaptcha.enabled) {
        data.captchaResponseToken = $('.g-recaptcha-response:first').val();
      }
      this.props.register(data);
    }
  };

  recaptchaInstance = null;

  resetRecaptcha = () => {
    if (this.recaptchaInstance) {
      this.recaptchaInstance.reset();
    }
  };

  findValidationError = (arr, inputName) => {
    const details = (arr || []).find(element => element.field === inputName);
    return details ? {error: details.details} : null;
  };

  onPressEnter = event => {
    if (event.key === 'Enter') {
      this.signup();
    }
  };

  openLoginPage = () => {
    this.props.history.push(URLMAP.LOGIN);
  };

  handleCountryOptionChange = stateField => {
    return ({value}) => {
      this.setState({[stateField]: value});
    };
  };

  isEuCountry = () => {
    return EU_COUNTRIES.some(code => code === this.state.countryOfCitizenship);
  };

  isOtherCountry = () => {
    return OTHER_COUNTRIES.some(code => code === this.state.countryOfCitizenship);
  };

  render() {
    const {UserRegisterReducer, ApplicationSettingsReducer, GetReferralCodeReducer, SessionReducer} = this.props;
    const error = UserRegisterReducer.error;
    const fetching = UserRegisterReducer.fetching;
    const nextStep = UserRegisterReducer.data.followingLevel;

    const recaptcha = ApplicationSettingsReducer.recaptcha || {};

    if (SessionReducer.masterPersonality && SessionReducer.masterPersonality.uniqueSymbolRow) {
      this.props.closePopup();
      return null;
    }

    return (
      <div className='sign_up_page'>
        <div className='sign_up_page__content'>
          <div className='login_page__header'>
            <Logo isMonoColor className='logo' />
          </div>

          {nextStep !== 'EMAIL_CONFIRM_REQUIRED' && (
            <div className='sign_up_page__main-wrapper'>
              <h2>
                <I18n tKey='popup.create_ac' />
              </h2>

              <div className='ui-form-text text-center'>
                <I18n tKey='popup.already_sign' /> &nbsp;
                <a onClick={this.openLoginPage}>
                  <I18n tKey='common.login' />
                </a>
              </div>

              <div className='form-container'>
                <div className='relative'>
                  {GetReferralCodeReducer.fetching && (
                    <div className='loading-block'>
                      <I18n tKey='common.loading' />
                    </div>
                  )}

                  <InputPopup
                    onChange={code => this.changeReferralCode(code)}
                    error={
                      this.state.referralCodeError && this.state.referralCode ? {error: 'common.user_not_found'} : null
                    }
                    inputName={'referralcode'}
                    value={this.state.referralCode}
                    placeholder='referral:name_inviter'
                    label='referral:enter_name_inviter:'
                  />
                </div>
                <InputPopup
                  error={this.state.passwordError}
                  placeholder='common.password'
                  onKeyDown={this.onKeyDown}
                  value={this.state.password}
                  onChange={this.handlePasswordFieldChange}
                  validation={this.checkValidation}
                  inputName={'password'}
                  label='popup.imagine_pswd'
                  className='ui-form-control-secure'
                  onPressEnter={this.onPressEnter}
                  type='password'
                  showPasswordActive
                >
                  <Fragment>
                    <div
                      className={classNames('ui-form-control-rules_item', {
                        ['active']: this.state.secureType.lowercaseRegex,
                      })}
                    >
                      <I18n tKey='popup.one_lowercase' />
                    </div>
                    <div
                      className={classNames('ui-form-control-rules_item', {
                        ['active']: this.state.secureType.uppercaseRegex,
                      })}
                    >
                      <I18n tKey='popup.one_uppercase' />
                    </div>
                    <div
                      className={classNames('ui-form-control-rules_item', {
                        ['active']: this.state.secureType.numberRegex,
                      })}
                    >
                      <I18n tKey='popup.one_num' />
                    </div>
                    {/*<div className={`ui-form-control-rules_item ${this.state.secureType.symbolsRegex ? "active" : ""}`}>One special character</div>*/}
                    <div
                      className={classNames('ui-form-control-rules_item', {
                        ['active']: this.state.secureType.size,
                      })}
                    >
                      <I18n tKey='popup.min_characters' />
                    </div>
                  </Fragment>
                </InputPopup>
                <InputPopup
                  error={this.state.emailError || (error && this.findValidationError(error.details, 'email'))}
                  placeholder='common.email'
                  onKeyDown={this.onKeyDown}
                  value={this.state.email}
                  onChange={value => this.setState({email: value})}
                  validation={this.checkValidation}
                  inputName={'email'}
                  label='popup.enter_email'
                  onPressEnter={this.onPressEnter}
                />
                <InputPopup
                  error={this.state.password2Error}
                  placeholder='popup.confirm_pswd'
                  onKeyDown={this.onKeyDown}
                  value={this.state.password2}
                  onChange={this.handleConfirmationField}
                  validation={this.checkValidation}
                  inputName={'password2'}
                  label='common.repeat_pswd'
                  className='ui-form-control-secure'
                  onPressEnter={this.onPressEnter}
                  type='password'
                />
                <InputPopup
                  error={this.state.usernameError || (error && this.findValidationError(error.details, 'username'))}
                  placeholder='popup.username'
                  onKeyDown={this.onKeyDown}
                  value={this.state.username}
                  validation={this.checkValidation}
                  maxValLength={MAX_USER_NAME_LENGTH}
                  inputName={'username'}
                  onChange={value => this.setState({username: value})}
                  label='popup.enter_username'
                  onPressEnter={this.onPressEnter}
                />
                {recaptcha && recaptcha.enabled && recaptcha.publicKey && (
                  <div className='ui-form-сaptcha'>
                    <Recaptcha ref={e => (this.recaptchaInstance = e)} sitekey={recaptcha.publicKey} />
                  </div>
                )}
                <div className='ui-form-input ui-form-input--with-select'>
                  <div className='su-select-label'>
                    <I18n tKey='common.citizenship' />
                  </div>
                  <Select
                    isSearchable
                    components={this.selectComponents}
                    noOptionsMessage={() => translate('common.not_found')}
                    placeholder={translate('common.search')}
                    name='countryOfCitizenship'
                    theme={SELECT_THEME}
                    styles={CUSTOM_SELECT_STYLES}
                    onChange={this.handleCountryOptionChange('countryOfCitizenship')}
                    options={COUNTRIES}
                  />
                </div>
                {(this.isEuCountry() || this.isOtherCountry()) && (
                  <div className='ui-form-input ui-form-input--with-select'>
                    <div className='su-select-label'>
                      <I18n tKey='common.residence' />
                    </div>
                    <Select
                      isSearchable
                      components={this.selectComponents}
                      noOptionsMessage={() => translate('common.not_found')}
                      placeholder={translate('common.search')}
                      name='countryOfResidence'
                      theme={SELECT_THEME}
                      styles={CUSTOM_SELECT_STYLES}
                      onChange={this.handleCountryOptionChange('countryOfResidence')}
                      options={COUNTRIES}
                    />
                  </div>
                )}
                {this.isEuCountry() && (
                  <div className='ui-form-input ui-form-input--with-select'>
                    <div className='su-select-label'>
                      <I18n tKey='common.domicile' />
                    </div>
                    <Select
                      isSearchable
                      components={this.selectComponents}
                      noOptionsMessage={() => translate('common.not_found')}
                      placeholder={translate('common.search')}
                      name='countryOfDomicile'
                      theme={SELECT_THEME}
                      styles={CUSTOM_SELECT_STYLES}
                      onChange={this.handleCountryOptionChange('countryOfDomicile')}
                      options={COUNTRIES}
                    />
                  </div>
                )}
                <div
                  className={classNames('ui-form-checkbox ui-form-checkbox--without-offset form-container__agreement', {
                    ['alert']: this.state.termsAccepted,
                  })}
                >
                  <input type='checkbox' id='agree' />
                  <label htmlFor='agree'>
                    <I18n tKey='popup.by_click' /> <br /> {translate('popup.to_the')}{' '}
                    <Link to='/page/information/user-agreement' target='_blank'>
                      <I18n tKey='common.terms' />
                    </Link>
                  </label>
                  {/*{this.state.termsAccepted && <div className="popup-error-message">*/}
                  {/*{this.state.termsAccepted}*/}
                  {/*</div>}*/}
                </div>
                <div />
                <div className='ui-form-btn'>
                  {fetching && (
                    <button disabled type='button' className='btn btn-md btn-primary btn-full'>
                      <I18n tKey='common.sign_up' />
                    </button>
                  )}

                  {!fetching && (
                    <button
                      type='button'
                      disabled={
                        !this.state.countryOfCitizenship ||
                        (this.isEuCountry() && (!this.state.countryOfResidence || !this.state.countryOfDomicile)) ||
                        (this.isOtherCountry() && !this.state.countryOfResidence) ||
                        (this.state.referralCode && this.state.referralCodeError)
                      }
                      onClick={this.signup}
                      className='btn btn-md btn-primary btn-full'
                    >
                      <I18n tKey='common.sign_up' />
                    </button>
                  )}
                </div>
              </div>

              <div className='popup-error-message common sign-up-login-popup-error-message'>
                {error && (
                  <div>
                    <I18n tKey={error.parsedError || error.error} />
                  </div>
                )}
              </div>
            </div>
          )}

          {nextStep === 'EMAIL_CONFIRM_REQUIRED' && (
            <div className='modal-signup_confirm'>
              <div className='image'>
                <SvgWrapper className='ic-size-19' SvgCmp={Envelope} />
                {/*<div id="open"/>*/}
              </div>
              <h2>
                <I18n tKey='popup.email_confirm' />
              </h2>
              <div className='text'>
                <I18n tKey='popup.complete_register' />
              </div>
              <div className='box-btn'>
                <a
                  data-target='#login'
                  onClick={this.clearForm}
                  data-toggle='modal'
                  data-dismiss='modal'
                  className='btn btn-md btn-primary btn-full'
                >
                  <I18n tKey='common.proceed' />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(SignUp));
