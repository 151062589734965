import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import Balances from './balances/Balances';
import TradingHistory from './balances/TradingHistory';
import FeesAndLimits from './balances/FeesAndLimits';
import TransactionHistory from './balances/TransactionHistory';
import AllOpenOrders from './balances/AllOpenOrders';
import Portfolio from './balances/Portfolio';
import {Link} from 'react-router-dom';
import * as React from 'react';
import Footer from './Footer';
import I18n from '../cmp/helpers/I18n';
import {URLMAP} from '../utils/const';

class BaseProfileLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.TABS = {
      Balances: <Balances setTab={this.setTab} />,
      Portfolio: <Portfolio setTab={this.setTab} />,
      TradingHistory: <TradingHistory setTab={this.setTab} />,
      FeesAndLimits: <FeesAndLimits setTab={this.setTab} />,
      TransactionHistory: <TransactionHistory setTab={this.setTab} />,
      AllOpenOrders: <AllOpenOrders setTab={this.setTab} />,
    };

    this.state = {
      tab: this.TABS.TradingHistory,
    };
  }

  setTab = tab => {
    this.setState({tab: typeof tab === 'string' ? this.TABS[tab] : tab});
  };

  componentDidMount() {
    if (this.TABS[this.props.match.params.tab]) {
      this.setTab(this.props.match.params.tab);
    } else {
      const balancesIsActive = this.returnBalancesActivity();
      this.setTab(
        typeof balancesIsActive === 'undefined' || balancesIsActive ? this.TABS.Balances : this.TABS.TradingHistory
      );
    }
  }

  componentWillReceiveProps(n) {
    if (n.match.params.tab !== this.props.match.params.tab) {
      if (this.TABS[n.match.params.tab]) {
        this.setTab(n.match.params.tab);
      } else {
        const balancesIsActive = this.returnBalancesActivity();
        this.setTab(balancesIsActive ? this.TABS.Balances : this.TABS.TradingHistory);
      }
    }
  }

  returnBalancesActivity = () => {
    const {ApplicationSettingsReducer} = this.props;
    return ApplicationSettingsReducer.data.withdrawActivityFlag && ApplicationSettingsReducer.data.depositActivityFlag;
  };

  render() {
    const balancesIsActive = this.returnBalancesActivity();
    const Component = this.state.tab;
    return (
      <div className='page-inner'>
        <div className='page-inner-up'>
          <div className='page-inner_navigate'>
            <div className='container-center'>
              <ul className='nav nav-tabs' role='tablist'>
                {balancesIsActive && (
                  <li className={`nav-item ${this.state.tab === this.TABS.Balances ? 'active' : ''}`}>
                    <Link className='nav-link' to={URLMAP.BALANCES}>
                      <I18n tKey='balances.title' />
                    </Link>
                  </li>
                )}
                <li className={`nav-item ${this.state.tab === this.TABS.Portfolio ? 'active' : ''}`}>
                  <Link className='nav-link' to={URLMAP.BALANCES + '/Portfolio'}>
                    <I18n tKey='common.portfolio' />
                  </Link>
                </li>
                <li className={`nav-item ${this.state.tab === this.TABS.TradingHistory ? 'active' : ''}`}>
                  <Link className='nav-link' to={URLMAP.BALANCES + '/TradingHistory'}>
                    <I18n tKey='common.link_history' />
                  </Link>
                </li>
                <li className={`nav-item ${this.state.tab === this.TABS.FeesAndLimits ? 'active' : ''}`}>
                  <Link className='nav-link' to={URLMAP.BALANCES + '/FeesAndLimits'}>
                    <I18n tKey='common.link_limit' />
                  </Link>
                </li>
                <li className={`nav-item ${this.state.tab === this.TABS.TransactionHistory ? 'active' : ''}`}>
                  <Link className='nav-link' to={URLMAP.BALANCES + '/TransactionHistory'}>
                    <I18n tKey='common.link_trx' />
                  </Link>
                </li>
                {/*<li className={`nav-item ${this.state.tab === this.TABS.AllOpenOrders ? "active" : ""}`}>*/}
                {/*<a className="nav-link" id="tab-activity" data-toggle="tab" onClick={this.setTab.bind(this, this.TABS.AllOpenOrders)} role="tab"*/}
                {/*aria-controls="contact" aria-selected="false">All Open Orders</a>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
          <div className='container-center'>
            <div className='tab-content'>
              <div className='tab-pane fade active' id='profile' role='tabpanel' aria-labelledby='tab-profile'>
                {Component}
              </div>
            </div>
          </div>
        </div>
        <div className='page-inner-down'>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({SessionReducer, ApplicationSettingsReducer}) => ({
  SessionReducer,
  ApplicationSettingsReducer,
});
const mapDispatchToProps = dispatch => {
  return {...bindActionCreators({}, dispatch)};
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseProfileLayout));

export default connectedWithRouter;
