import React, {Component} from 'react';
import classNames from 'classnames';

import {CHART_PERIODS, CHART_TYPE} from '../../../constants';

class ChartSettings extends Component {
  render() {
    const {chartType, onChartTypeChange, OHLCPeriod, onOHLCPeriodChange} = this.props;

    return (
      <div className='exchange-chart-settings'>
        <div className='exchange-chart-settings__periods chart-settings-periods'>
          {CHART_PERIODS.map(period => (
            <div
              onClick={() => onOHLCPeriodChange(period)}
              className={classNames('chart-settings-periods__item', {['active']: period.id === OHLCPeriod.id})}
              key={period.id}
            >
              {period.label}
            </div>
          ))}
        </div>

        <div className='exchange-chart-settings__types chart-settings-types'>
          <div
            onClick={() => onChartTypeChange(CHART_TYPE.LINE)}
            className={classNames('chart-settings-types__item', {['active']: chartType === CHART_TYPE.LINE})}
          >
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5 5V18V18.5H5.5H19.5V17.5H6V5H5ZM14.3322 12.8737L18.8322 8.8737L18.1678 8.1263L14.0202 11.8131L11.3536 9.14645L11 8.79289L10.6464 9.14645L7.14645 12.6464L7.85355 13.3536L11 10.2071L13.6464 12.8536L13.9798 13.1869L14.3322 12.8737Z'
                fill='currentColor'
              ></path>
            </svg>
          </div>

          <div
            onClick={() => onChartTypeChange(CHART_TYPE.CANDLE)}
            className={classNames('chart-settings-types__item', {['active']: chartType === CHART_TYPE.CANDLE})}
          >
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18 5H17V9H16V16H17V19H18V16H19V9H18V5ZM13 10H14V17H13V19H12V17H11V10H12V7H13V10ZM8 13H9V17H8V19H7V17H6V13H7V10H8V13Z'
                fill='currentColor'
              ></path>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default ChartSettings;
