// @flow

import React, {Fragment} from 'react';
import {ReactComponent as TriangleIc} from '../../../assets/images/icon/dropdown-small-n.svg';
import {SvgWrapper} from './helpers/SvgWrapper';
import I18n from './helpers/I18n';
import {translate} from '../utils/i18n';

class DropdownFilter extends React.Component {
  displayName: 'DropdownFilter';

  state = {
    opened: false,
    selected: null,
  };

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({selected: this.props.elements.filter(e => e === this.props.defaultValue)[0]});
    } else if (this.props.notNull) {
      this.setState({selected: this.props.elements[0]});
    }
  }

  componentWillReceiveProps(n) {
    n.defaultValue && this.setState({selected: n.defaultValue});
  }

  choose = value => {
    if (value && value.currencyId) {
      const label = value.label;
      const currencyId = value.currencyId;
      this.setState({selected: label});
      this.props.onChange && this.props.onChange(currencyId);
    } else {
      if (value === this.props.title) {
        value = null;
      }
      this.setState({selected: value});
      this.props.onChange && this.props.onChange(value);
    }
  };

  getLabel = value => {
    if (typeof value === 'object') {
      return this.props.useTranslate ? translate(value.label) : value.label;
    } else {
      return this.props.useTranslate ? translate(value) : value;
    }
  };

  render() {
    let elements = this.props.elements || [];

    return (
      <div
        className={`dropdown  ${this.state.opened ? 'open' : ''}`}
        onMouseOver={() => this.setState({opened: true})}
        onMouseOut={() => this.setState({opened: false})}
      >
        <a className='tab-pane-filter-item dropdown_btn' onClick={this.choose.bind(this, null)}>
          <span>
            {translate(this.props.title) + (this.state.selected ? ': ' + this.getLabel(this.state.selected) : '')}
          </span>
          <SvgWrapper themeWatch className='icon icon-arrow-toggle ic-size-02' SvgCmp={TriangleIc} />
        </a>
        <div
          className='dropdown_menu'
          onClick={() => this.setState({opened: false})}
          style={{maxHeight: '200px', overflow: 'auto'}}
        >
          {this.state.selected && (
            <a style={{whiteSpace: 'nowrap'}} className='dropdown_menu-item' onClick={this.choose.bind(this, null)}>
              <I18n tKey='news.show_all' />
            </a>
          )}
          {elements.map((value, i) => {
            return (
              <a
                key={i}
                style={{whiteSpace: 'nowrap'}}
                className='dropdown_menu-item'
                onClick={this.choose.bind(this, value)}
              >
                {this.getLabel(value)}
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DropdownFilter;
