import * as React from 'react';
import {SvgWrapper} from '../cmp/helpers/SvgWrapper';
import {ReactComponent as OkIc} from '../../../assets/images/icon/success.svg';
import {ReactComponent as Error} from '../../../assets/images/icon/error.svg';
import I18n from '../cmp/helpers/I18n';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {WithdrawConfirm, ResendWithdrawConfirmEmail} from '../api/Actions';
import {URLMAP} from '../utils/const';

const ResultMsg = ({icon, title, description, action, button}) => (
  <div className='result_container'>
    <div className='result'>
      <SvgWrapper className='icon' SvgCmp={icon || Error} />
      <div className='title mt-2'>
        <I18n tKey={title} />
      </div>
      <div className='description mt-2'>
        <I18n tKey={description} />
      </div>

      <div className='mt-2 white'>
        <button onClick={action} type='button' className='btn btn-primary white'>
          <I18n tKey={button} />
        </button>
      </div>
    </div>
  </div>
);

class ConfirmWithdrawEmail extends React.Component {
  componentDidMount() {
    const {confirmWithdrawId, confirmWithdrawToken} = this.props.match.params;
    if (confirmWithdrawId && confirmWithdrawToken) {
      this.props.confirmWithdraw({specialMark: confirmWithdrawToken, wtdIdentifier: confirmWithdrawId});
    }
  }

  componentWillUnmount() {
    this.props.clearResendWithdrawConfirmEmail();
    this.props.clearWithdrawConfirm();
  }

  resendWithdraw = () => {
    this.props.resendWithdrawConfirmEmail({id: this.props.match.params.confirmWithdrawId});
  };
  goBalanses = () => {
    this.props.history.push(URLMAP.BALANCES);
  };

  render() {
    const {ResendWithdrawConfirmEmailReducer, WithdrawConfirmReducer} = this.props;
    console.log(ResendWithdrawConfirmEmailReducer, WithdrawConfirmReducer);
    if (ResendWithdrawConfirmEmailReducer.error) {
      return (
        <ResultMsg
          icon={OkIc}
          title='withdraw:already_confirmed_title'
          description=''
          button='withdraw:go_balances'
          action={this.goBalanses}
        />
      );
    }
    if (ResendWithdrawConfirmEmailReducer.completed) {
      return (
        <ResultMsg
          icon={OkIc}
          title='withdraw:re_mail_sent_title'
          description='withdraw:re_mail_sent_description'
          button='withdraw:go_balances'
          action={this.goBalanses}
        />
      );
    }
    if (WithdrawConfirmReducer.error) {
      return (
        <ResultMsg
          icon={Error}
          title='withdraw:confirm_email_error_title'
          description='withdraw:confirm_email_error_description'
          button='withdraw:confirm_email_error_button'
          action={this.resendWithdraw}
        />
      );
    }
    if (WithdrawConfirmReducer.completed) {
      return (
        <ResultMsg
          icon={OkIc}
          title='withdraw:confirm_email_success_title'
          description='withdraw:confirm_email_success_description'
          button='withdraw:go_balances'
          action={this.goBalanses}
        />
      );
    } else
      return (
        <div className='result_container'>
          <div className='result'>
            <div className='title mt-2'>
              <I18n tKey='common.loading' />
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = ({user, history, WithdrawConfirmReducer, ResendWithdrawConfirmEmailReducer}) => ({
  ...user,
  ...history,
  WithdrawConfirmReducer,
  ResendWithdrawConfirmEmailReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    clearResendWithdrawConfirmEmail: () => dispatch({type: ResendWithdrawConfirmEmail.events.onClear}),
    clearWithdrawConfirm: () => dispatch({type: WithdrawConfirm.events.onClear}),
    ...bindActionCreators(
      {
        confirmWithdraw: WithdrawConfirm.action,
        resendWithdrawConfirmEmail: ResendWithdrawConfirmEmail.action,
      },
      dispatch
    ),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmWithdrawEmail));
