import {filterActions} from 'redux-ignore';

export const SELECT_ORDER = 'SELECT_ORDER';

const initialState = {
  price: null,
  amount: '',
};

function SelectedOrderReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ORDER: {
      return {price: action.price, amount: action.amount};
    }
    default:
      return state;
  }
}

export default filterActions(SelectedOrderReducer, [SELECT_ORDER]);
