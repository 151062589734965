import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Dialog from '../Dialog';
import {ReactComponent as EmailCheckingIcon} from '../../../../assets/images/icon/email-checking.svg';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';

class EmailCheckingDialog extends PureComponent {
  static propTypes = {
    darkTheme: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    autoClose: PropTypes.shape({
      active: PropTypes.bool.isRequired,
      time: PropTypes.number,
    }),
  };

  handleClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  render() {
    const {darkTheme, open, children} = this.props;

    return (
      <Dialog open={open} onClose={this.handleClose} height={170}>
        <div className={`success-dialog ${darkTheme ? 'dialog-success__dark' : ''}`}>
          <SvgWrapper SvgCmp={EmailCheckingIcon} style={{marginBottom: 10}} />
          {children}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ThemeReducer}) => ({
  darkTheme: ThemeReducer.darkTheme,
});

export default connect(mapStateToProps)(EmailCheckingDialog);
