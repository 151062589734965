import React, {Component, Fragment} from 'react';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';

import {translate} from '../../../../utils/i18n';
import I18n from '../../../../cmp/helpers/I18n';
import connect from './connect';

class BaseForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: props.userName,
      email: props.email,
      actualEmail: props.email,
      newEmail: '',
      description: '',
      isLoading: false,
    };
  }

  componentWillReceiveProps(props) {
    if (props.userName !== this.props.userName || props.email !== this.props.email) {
      this.setState({userName: props.userName, email: props.email, actualEmail: props.email});
    }
  }

  getIsFormReady = () => {
    const {description, userName, email, actualEmail, newEmail} = this.state;
    const isChangeEmail = this.props.activeSubSection.value === '182';
    let isReady = true;

    if (isChangeEmail) {
      if (!userName.length || !actualEmail.length || !newEmail.length) {
        isReady = false;
      }
    } else {
      if (!description.length || !userName.length || !email.length) {
        isReady = false;
      }
    }

    return !isReady;
  };

  handleChange = e => {
    const {value, name} = e.target;

    this.setState({[name]: value});
  };

  getForm = () => {
    const isChangeEmail = this.props.activeSubSection.value === '182';
    const otherRequestTypeId = this.props.activeSubSection.value === '257';

    if (isChangeEmail) {
      return {
        customfield_10060: this.state.userName,
        customfield_10062: this.state.actualEmail,
        customfield_10063: this.state.newEmail,
        attachment: [],
      };
    }

    if (otherRequestTypeId) {
      return {
        customfield_10060: this.state.userName,
        customfield_10062: this.state.email,
        description: this.state.description,
        summary: 'Other Question',
      };
    }

    return {
      customfield_10060: this.state.userName,
      customfield_10062: this.state.email,
      description: this.state.description,
      attachment: [],
    };
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.props.cleanCreatedTicket();
    this.setState({isLoading: true});

    const {executeRecaptcha} = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.debug('NO CAPTCHA');
      return;
    }

    try {
      const token = await executeRecaptcha('attachment');

      const form = this.getForm();

      const {status} = await this.props.createTicket({
        body: {
          serviceDeskId: this.props.serviceDeskId,
          requestTypeId: this.props.activeSubSection.value,
          requestFieldValues: form,
        },
        gToken: token,
      });

      if (status === 200) {
        this.props.openTicketCreateModal(this.onCloseModal);
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({isLoading: false});
  };

  onCloseModal = () => {
    this.setState({newEmail: '', description: ''});
  };

  render() {
    const isChangeEmail = this.props.activeSubSection.value === '182';

    return (
      <form onSubmit={this.handleSubmit} className='help__form'>
        <div className='form__block'>
          <input
            value={this.state.userName}
            onChange={e => this.handleChange(e)}
            name='userName'
            id='username'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.username')}
            readOnly={this.props.userLoggedIn}
            required
          />
          <label htmlFor='username'>
            <I18n tKey='helpcenter.username' />
          </label>
        </div>

        {isChangeEmail ? (
          <Fragment>
            <div className='form__block'>
              <input
                value={this.state.actualEmail}
                onChange={e => this.handleChange(e)}
                name='actualEmail'
                id='actualEmail'
                type='email'
                className='form__control'
                placeholder={translate('helpcenter.actual_email')}
                readOnly={this.props.email}
                required
              />
              <label htmlFor='email'>
                <I18n tKey='helpcenter.email' />
              </label>
            </div>
            <div className='form__block'>
              <input
                value={this.state.newEmail}
                onChange={e => this.handleChange(e)}
                name='newEmail'
                id='newEmail'
                type='email'
                className='form__control'
                placeholder={translate('helpcenter.new_email')}
                required
              />
              <label htmlFor='email'>
                <I18n tKey='helpcenter.email_new' />
              </label>
            </div>
          </Fragment>
        ) : (
          <div className='form__block'>
            <input
              value={this.state.email}
              onChange={e => this.handleChange(e)}
              name='email'
              id='email'
              type='email'
              className='form__control'
              placeholder={translate('helpcenter.email')}
              readOnly={this.props.userLoggedIn}
              required
            />
            <label htmlFor='email'>
              <I18n tKey='helpcenter.email' />
            </label>
          </div>
        )}

        {!isChangeEmail && (
          <div className='form__block'>
            <label htmlFor='message' className='header__label'>
              <I18n tKey='helpcenter.description_short' />*
            </label>
            <textarea
              name='description'
              id='message'
              className='form__control'
              required
              value={this.state.description}
              onChange={e => this.handleChange(e)}
            />
          </div>
        )}

        <div className='form__block button__wrapper d-flex  '>
          <button className='btn btn-cancel '>
            <span>
              <I18n tKey='common.cancel' />
            </span>
          </button>

          <button type='submit' className='btn btn-purple ' disabled={this.getIsFormReady() || this.state.isLoading}>
            <span>
              <I18n tKey='common.send' />
            </span>
          </button>
        </div>
      </form>
    );
  }
}

export default withGoogleReCaptcha(connect(BaseForm));
