import BigNumber from 'bignumber.js';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {CurrencyView} from '../../cmp/helpers/CurrencyView';
import I18n from '../../cmp/helpers/I18n';
import {URLMAP} from '../../utils/const';
import {BASE_CURRENCY, INTERIM_CURRENCY} from './Balances';
import RedeemVoucher from './RedeemVoucher';

class BalancesBar extends Component {
  render() {
    const {
      WithdrawRequestReducer,
      WsWalletReducer,
      CurrencyListReducer,
      AccountListReducer,
      WithdrawUsageReducer,
      SessionReducer,
      title,
    } = this.props;
    const user = SessionReducer.masterPersonality;
    if ([CurrencyListReducer.list, WsWalletReducer.list, AccountListReducer.list].some(val => val === null)) {
      return null;
    }
    if ([CurrencyListReducer.fetching, WsWalletReducer.fetching, AccountListReducer.fetching].some(val => val)) {
      return <div>Loading...</div>;
    }
    const account = AccountListReducer.list[0];
    if (!account) {
      return (
        <div className='text-center'>
          <I18n tKey='profile.empty_account_list' />
        </div>
      );
    }
    const plan = account.payScheme;

    const crossCourses = WsWalletReducer.crossCourses || {};
    let interimValue;
    const totalInUSD = Object.keys(WsWalletReducer.byCurrencyMap || {}).reduce((s, cc) => {
      const wallet: WalletTO = WsWalletReducer.byCurrencyMap[cc];
      if (cc.toUpperCase() === BASE_CURRENCY) {
        return s + wallet.aValue + wallet.resValue;
      } else if (
        wallet &&
        crossCourses &&
        crossCourses[BASE_CURRENCY] &&
        crossCourses[BASE_CURRENCY][wallet.monetaryUnitCode]
      ) {
        return s + (wallet.aValue + wallet.resValue) / crossCourses[BASE_CURRENCY][wallet.monetaryUnitCode];
      } else if (
        crossCourses &&
        crossCourses[INTERIM_CURRENCY] &&
        crossCourses[INTERIM_CURRENCY][wallet.monetaryUnitCode] &&
        crossCourses[BASE_CURRENCY] &&
        crossCourses[BASE_CURRENCY][INTERIM_CURRENCY]
      ) {
        interimValue = wallet.aValue / crossCourses[INTERIM_CURRENCY][wallet.monetaryUnitCode];
        return s + interimValue / crossCourses[BASE_CURRENCY][INTERIM_CURRENCY];
      } else {
        return s;
      }
    }, 0);
    let dailyLeft,
      withdrawedLimit,
      monthlyLeft = 0;
    if (plan && WithdrawUsageReducer.data) {
      dailyLeft = plan.eDayWithdrawTop - WithdrawUsageReducer.data.eDay;
      monthlyLeft = plan.eMonthWithdrawTop - WithdrawUsageReducer.data.eMonth;
      withdrawedLimit = new BigNumber(WithdrawUsageReducer.data.eDay || 0)
        .multipliedBy(100)
        .div(plan.eDayWithdrawTop || 0)
        .toNumber();
    }

    let withdrawError,
      withdrawErrorParam = null;
    if (WithdrawRequestReducer.error) {
      let message = WithdrawRequestReducer.error.message;
      if (message) {
        if (message.indexOf('NOT_ENOUGH_BALANCE') === 0) {
          withdrawError = 'error:NOT_ENOUGH_BALANCE';
        } else if (message.indexOf('PAY_PLAN_MIN_TX_LIMIT_NOT_REACHED') === 0) {
          withdrawError = 'error:PAY_PLAN_MIN_TX_LIMIT_NOT_REACHED';
          if (message.indexOf('|') !== -1) {
            withdrawErrorParam = '(' + message.split('|')[1] + ')';
          }
        }
      }
      if (!withdrawError) {
        withdrawError = WithdrawRequestReducer.error.error;
      }
    }
    return (
      <div>
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <I18n tKey={`${title ? 'common.exchange' : 'balances.title'}`} />
          </div>
          <div className='tab-pane-filter' />
        </div>
        <div className='tab-pane-info'>
          <div style={{float: 'left'}}>
            <div className='tab-pane-info-title'>
              <I18n tKey='balances.est_value' />
              :&nbsp;
              {totalInUSD ? <CurrencyView isFiat={true} amount={totalInUSD} type='USD' showType /> : 'n/a'}
            </div>
            <div className='tab-pane-info-load type_1'>
              <div className='tab-pane-info-load-value' style={{width: withdrawedLimit + '%'}} />
            </div>
            <div className='tab-pane-info-text'>
              {<CurrencyView amount={dailyLeft} type={plan.monetaryUnitCode} appearance={plan.currentT} />}
              <I18n tKey='balances:remaining' />
              {
                <CurrencyView amount={plan.eDayWithdrawTop} type={plan.monetaryUnitCode} appearance={plan.currentT} />
              }{' '}
              {plan.monetaryUnitCode}
              <Link to={URLMAP.BALANCES + '/FeesAndLimits'}>
                {' '}
                <I18n tKey='common.daily_limit' />
              </Link>
            </div>
          </div>
          <div style={{float: 'right'}}>
            <RedeemVoucher />
          </div>
          <br clear={'both'} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  WithdrawRequestReducer,
  WsWalletReducer,
  CurrencyListReducer,
  AccountListReducer,
  WithdrawUsageReducer,
  SessionReducer,
}) => ({
  WithdrawRequestReducer,
  WsWalletReducer,
  CurrencyListReducer,
  AccountListReducer,
  WithdrawUsageReducer,
  SessionReducer,
});

export default BalancesBar = connect(mapStateToProps)(BalancesBar);
