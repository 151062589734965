import * as React from 'react';

import {ReactComponent as ArrowDown} from '../../../../../../assets/images/icon/dropdown-mark.svg';

import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import I18n from '../../../../cmp/helpers/I18n';

export class DropFilter extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isHovered: false,
    };
  }

  select(val) {
    if (!!this.props.onChange) this.props.onChange(val);
    this.setState({isHovered: false});
  }

  render() {
    const {title, value, values, children, showReset} = this.props;

    return (
      <div
        className={'table-dropdown-item ' + (this.state.isHovered ? 'hovered' : '')}
        onMouseEnter={() => this.setState({isHovered: true})}
        onMouseLeave={() => this.setState({isHovered: false})}
      >
        <div className='table-dropdown-item-header '>
          {!value ? <I18n tKey={title} /> : values[value]}{' '}
          <SvgWrapper themeWatch className='icon icon-dropdown ic-size-03' SvgCmp={ArrowDown} />
        </div>
        <div className='table-dropdown-item-body'>
          {showReset && !!value && (
            <a className='table-dropdown-item-body-link' onClick={this.select.bind(this, null)}>
              <I18n tKey={title} />
            </a>
          )}
          {Object.keys(values).map(key => {
            return (
              <a className='table-dropdown-item-body-link' key={key} onClick={this.select.bind(this, key)}>
                {values[key]}
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}
