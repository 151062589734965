// @flow
// import {SET_MARKET_LIST_ACTIVE_MARKET} from './constants/marketList';
import {
  ACCOUNTS_TRADE,
  SUBSCRIBE_TO_MARKET,
  SUBSCRIBE_TO_PRECISION,
  SUBSCRIBE_TO_RESOLUTION,
  TRADE_HISTORY,
  TRADE_HISTORY_UPDATE,
} from '../WsReducer';

const initialState = {
  recentTrades: [],
  accountRecentTrades: [],
  marketId: null,
};

const cutTrades = trades => {
  if (trades.length > 50) {
    return trades.slice(0, 20);
  } else {
    return trades;
  }
};

function WsRecentTradesReducer(state = initialState, action) {
  switch (action.type) {
    case TRADE_HISTORY: {
      let trades = cutTrades(action.payload[1]);
      trades = trades.filter(t => !!t);
      return {...state, recentTrades: trades, marketId: action.payload[0]};
    }

    case TRADE_HISTORY_UPDATE: {
      let trades = cutTrades(action.payload[1].concat(state.recentTrades));
      trades = trades.filter(t => !!t);
      return {...state, recentTrades: trades, marketId: action.payload[0]};
    }

    case ACCOUNTS_TRADE: {
      let trades = cutTrades([action.payload].concat(state.accountRecentTrades));
      trades = trades.filter(t => !!t);
      return {...state, accountRecentTrades: trades};
    }

    //reset table
    case SUBSCRIBE_TO_PRECISION:
    case SUBSCRIBE_TO_MARKET: {
      return {...state, recentTrades: []};
    }

    // case SET_MARKET_LIST_ACTIVE_MARKET: {
    //   if (parseInt(action.payload.id) !== parseInt(state.marketId)) {
    //     return {...state, recentTrades: [], marketId: null};
    //   } else {
    //     return state;
    //   }
    // }

    default:
      return state;
  }
}

export default WsRecentTradesReducer;
