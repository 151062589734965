import React, {Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import * as Actions from '../../api/Actions';
import {OPEN_POPUP} from '../../reducers/PopupReducer';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import {ENABLE_2FA_STEP1_POPUP} from '../../cmp/popups/top/Enable2FAStep1Popup';
import {DISABLE_2FA_STEP1_POPUP} from '../../cmp/popups/top/Disable2FAStep1Popup';
import {ReactComponent as Envelope} from '../../../../assets/images/modal/envelope.svg';
import I18n from '../../cmp/helpers/I18n';
import {ReactComponent as imgClose} from '../../../../assets/images/icon/close.svg';
import ReferralLink from '../../cmp/referrals/ReferralLink';

class Settings extends React.PureComponent {
  state = {
    sendLink: false,
    fetching: false,
    isSent: false,
    error: '',
  };

  removeState = () => {
    this.setState({
      sendLink: false,
      fetching: false,
      isSent: false,
      error: '',
    });
  };

  componentDidMount() {
    this.props.clearEmailRes();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.RequestPasswordResetReducer) {
      const {fetching, error} = nextProps.RequestPasswordResetReducer;
      this.setState({fetching, error});
    }

    if (
      nextProps.RequestPasswordResetReducer.data &&
      !nextProps.RequestPasswordResetReducer.error &&
      !nextProps.RequestPasswordResetReducer.fetching
    ) {
      const {sendingFlag} = nextProps.RequestPasswordResetReducer.data;
      this.setState({isSent: sendingFlag});
    }
    nextProps.RequestPasswordResetReducer.error && this.setState({isSent: false});
  }

  modalSendLink = () => {
    this.setState({sendLink: !this.state.sendLink});
  };

  closeModal = () => {
    this.removeState();
  };

  sendLink = () => {
    const email = this.props.SessionReducer.masterPersonality.electronicMailVal;
    this.props.requestPasswordReset({electronicPostAddr: email});
  };

  mailTo = () => {
    this.props.clearEmailRes();
    this.props.mailTo({specialMark: this.props.SessionReducer.specialMark});
  };

  render() {
    const user = this.props.SessionReducer.masterPersonality || {};
    const {ConfirmEmailReducer, UserSettingsReducer} = this.props;
    const {fetching, error, isSent, sendLink} = this.state;
    let modalStyle = {
      display: 'block',
      paddingRight: '17px',
    };
    return (
      //need to check data attributs
      <Fragment>
        {sendLink && (
          <div className='modal fade in' style={modalStyle}>
            <div className='modal-dialog modal-md' role='document'>
              <div className='modal-content'>
                {/*<button type="button" className="btn close" data-dismiss="modal" aria-label="Close"*/}
                {/*        onClick={this.modalSendLink}/>*/}
                <SvgWrapper className='btn close' onClick={this.modalSendLink} SvgCmp={imgClose} themeWatch />
                <div className='modal-header'>
                  <div className='text-center'>
                    {isSent && <SvgWrapper className='ic-size-19' SvgCmp={Envelope} />}
                    <h2 className='title'>
                      <I18n tKey='common.change_pswd' />
                    </h2>
                    <div className='text'>
                      {isSent ? <I18n tKey='common.check_email' /> : <I18n tKey='common.send_link_change' />}
                      {error && <div className='status red'>{error.parsedError}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-btn'>
                  {fetching && !isSent && (
                    <button
                      type='button'
                      disabled
                      className='btn btn-primary btn-md btn-full'
                      data-dismiss='modal'
                      data-target='#change-password-confirm'
                      data-toggle='modal'
                    >
                      <span className='upper'>
                        <I18n tKey='profile.send_pswd_link' />
                      </span>
                    </button>
                  )}
                  {!fetching && !isSent && (
                    <button
                      type='button'
                      className='btn btn-primary btn-md btn-full'
                      data-dismiss='modal'
                      data-target='#change-password-confirm'
                      data-toggle='modal'
                      onClick={this.sendLink}
                    >
                      <span className='upper'>
                        <I18n tKey='profile.send_pswd_link' />
                      </span>
                    </button>
                  )}
                  {isSent && (
                    <button
                      type='button'
                      className='btn btn-primary btn-md btn-full'
                      data-dismiss='modal'
                      onClick={this.closeModal}
                    >
                      <span className='upper'>ok</span>
                    </button>
                  )}
                </div>
                <div className='modal-help'>
                  <a href='/page/faq/all' target='_blank'>
                    <I18n tKey='helpcenter:header' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <I18n tKey='common.profile' />
          </div>
        </div>
        <div className='tab-pane-body'>
          <div className='block-account'>
            <div className='block-account-control'>
              <div className='block-account-control_value'>
                <div className='title'>
                  <I18n tKey='common.email' />
                </div>
                <div className='label-value'>{user.electronicMailVal}</div>
              </div>
              <div className='block-account-control_action'>
                {user.emailConfirmationFlag ? (
                  <span className='link-control'>
                    <I18n tKey='common.confirmed' />
                  </span>
                ) : (
                  <a onClick={!ConfirmEmailReducer.fetching && this.mailTo} className='link-control'>
                    {ConfirmEmailReducer.fetching ? <I18n tKey='common.sending' /> : <I18n tKey='common.mail_to' />}
                  </a>
                )}
                {ConfirmEmailReducer.error && (
                  <div>{ConfirmEmailReducer.error.parsedError || ConfirmEmailReducer.error.error}</div>
                )}
              </div>
            </div>
            <div className='block-account-control'>
              <div className='block-account-control_value'>
                <div className='title'>
                  <I18n tKey='common.verification_status' />
                </div>
                <div className={`status ${user.curKYCustS !== 'NOT_VERIFIED' ? 'green' : 'red'}`}>
                  {user.curKYCustS !== 'NOT_VERIFIED' ? (
                    <I18n tKey='common.verified' />
                  ) : (
                    <I18n tKey='common.unverified' />
                  )}
                </div>
                {user.curKYCustS === 'NOT_VERIFIED' && (
                  <div className='text'>
                    <I18n tKey='profile.before_provide' />
                  </div>
                )}
              </div>
              {user.curKYCustS === 'NOT_VERIFIED' && (
                <div className='block-account-control_action'>
                  <a
                    className='link-control'
                    onClick={this.props.setTab.bind(this, 'Verifications')}
                    data-toggle='modal'
                  >
                    <I18n tKey='common.verify' />
                  </a>
                </div>
              )}
            </div>
            <ReferralLink UserSettingsReducer={UserSettingsReducer} />

            {/*<div className="block-account-control">*/}
            {/*<div className="block-account-control_value">*/}
            {/*<div className="title">Verification status</div>*/}
            {/*<div className="status wait">Pending</div>*/}
            {/*<div className="text">Application sent and is under the Compliance check.</div>*/}
            {/*</div>*/}
            {/*<div className="block-account-control_action">*/}
            {/*<a className="link-control">RETRY</a>*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>

          {/*<div className="block-account-name">*/}
          {/*Notifications*/}
          {/*</div>*/}
          {/*<div className="block-account">*/}
          {/*<div className="block-account-control">*/}
          {/*<div className="block-account-control_value">*/}
          {/*<div className="label-value">Email notifications</div>*/}
          {/*<div className="status green">Enabled</div>*/}
          {/*</div>*/}
          {/*<div className="block-account-control_action">*/}
          {/*<a className="btn btn-toggle on btn-middle"><i className="toggle"/></a>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="block-account-control">*/}
          {/*<div className="block-account-control_value">*/}
          {/*<div className="label-value">SMS notifications</div>*/}
          {/*<div className="status red">Disabled</div>*/}
          {/*</div>*/}
          {/*<div className="block-account-control_action">*/}
          {/*<a className="btn btn-toggle btn-middle"><i className="toggle"/></a>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}

          <div className='block-account-name'>
            <I18n tKey='profile.security_settings' />
          </div>
          <div className='block-account'>
            <div className='block-account-control'>
              <div className='block-account-control_value'>
                <div className='title'>
                  <I18n tKey='common.password' />
                </div>
                <div className='label-value'>*****</div>
              </div>
              <div className='block-account-control_action'>
                <a className='link-control' onClick={this.modalSendLink}>
                  <I18n tKey='common.change' />
                </a>
              </div>
            </div>
            <div className='block-account-control'>
              <div className='block-account-control_value'>
                <div className='label-value'>
                  <I18n tKey='common.2_fa_auth' />
                </div>
                <div className={`status ${user.googleAuthAllowanceFlag ? 'green' : 'red'}`}>
                  {user.googleAuthAllowanceFlag ? <I18n tKey='profile.enabled' /> : <I18n tKey='profile.disabled' />}
                </div>
              </div>
              <div className='block-account-control_action'>
                <a
                  className='link-control'
                  onClick={user.googleAuthAllowanceFlag ? this.props.startDisable2FA : this.props.startEnable2FA}
                >
                  {user.googleAuthAllowanceFlag ? <I18n tKey='common.disable' /> : <I18n tKey='common.enable' />}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({SessionReducer, RequestPasswordResetReducer, UserSettingsReducer, ConfirmEmailReducer}) => ({
  SessionReducer,
  RequestPasswordResetReducer,
  ConfirmEmailReducer,
  UserSettingsReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        requestPasswordReset: Actions.RequestPasswordReset.action,
        mailTo: Actions.ConfirmEmail.action,
      },
      dispatch
    ),
    clearEmailRes: () => dispatch({type: Actions.ConfirmEmail.events.onClear}),
    startEnable2FA: () => dispatch({type: OPEN_POPUP, name: ENABLE_2FA_STEP1_POPUP}),
    startDisable2FA: () => dispatch({type: OPEN_POPUP, name: DISABLE_2FA_STEP1_POPUP}),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));

export default connectedWithRouter;
