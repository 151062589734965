import Resolution from '../../utils/resolution';
import {OHLCV_DATA_UPDATE, SUBSCRIBE_TO_RESOLUTION} from '../WsReducer';
import * as Actions from '../../api/Actions';
import {OhlcvHistory} from '../../api/Actions';

function convertDataToCandle(obj) {
  return {
    time: obj.whenVal,
    open: obj.oAction,
    high: obj.loftyVal,
    close: obj.cAction,
    low: obj.bottomVal,
    volume: obj.cubicMeasure,
    // isLastBar: arr[6],
    // isBarClosed: arr[7],
  };
}

function mergeArrays(oldArr, newArr) {
  let data = oldArr.map(candle => {
    let candleTime = candle.time;
    let duplicate = newArr.find(item => item.time === candleTime);
    if (duplicate) {
      candle = duplicate;
    }
    return candle;
  });
  return data;
}

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export const initialObject = {
  resolution: '1',
  id: 1,
  data: {1: []},
};

const resolution = new Resolution();
let onOhlcvUpdateCallbackList = [];

export const addOnOhlcvUpdate = cb => {
  onOhlcvUpdateCallbackList.push(cb);

  //unsubscribe fn
  return () => {
    onOhlcvUpdateCallbackList = onOhlcvUpdateCallbackList.filter(e => e !== cb);
  };
};

//todo needs refactoring
const processOhlcvData = (payload, state, historyLoaded) => {
  if (typeof payload[0] === 'undefined') {
    return state;
  }
  let nextId = payload[0];
  let dataArr = payload[2];
  let res = resolution.determineTime(payload[1]);

  const key = nextId + ':' + res;

  let data = dataArr.map(obj => convertDataToCandle(obj));
  let mutated = [];
  if (!state[key]) {
    state[key] = {...initialObject};
  }

  const object = state[key];
  if (!object.historyLoaded && !historyLoaded) {
    return state;
  }

  if (Object.keys(object.data).indexOf(nextId.toString()) >= 0) {
    mutated = [...object.data[nextId]];
    let merged = mergeArrays(mutated, data);
    if (merged.length > 0) {
      let finalArr = removeDuplicates([...merged, ...data], 'time');
      mutated = finalArr;
    } else {
      mutated.push(...data);
    }
  } else {
    mutated = data;
  }

  state[key] = {
    ...object,
    data: {...object.data, [nextId]: mutated},
    id: nextId,
    resolution: res,
    historyLoaded: object.historyLoaded || historyLoaded,
  };

  return {...state};
};

function WsOhlcvReducer(state = {}, action) {
  switch (action.type) {
    case SUBSCRIBE_TO_RESOLUTION: {
      OhlcvHistory.action(null, {ctcInstrIdentifier: action.marketId, ohlcvResolutionReq: action.payload})(
        action.asyncDispatch
      );
      return state;
    }

    case Actions.OhlcvHistory.events.onSuccess: {
      const {ctcInstrIdentifier, ohlcvCollectionResp, ohlcvResolutionResp} = action.data;
      return processOhlcvData([ctcInstrIdentifier, ohlcvResolutionResp, ohlcvCollectionResp], state, true);
    }

    case OHLCV_DATA_UPDATE: {
      if (onOhlcvUpdateCallbackList.length > 0) {
        onOhlcvUpdateCallbackList.forEach(cb => cb(action.payload));
      }
      return processOhlcvData(action.payload, state);
    }

    default:
      return state;
  }
}

export default WsOhlcvReducer;
