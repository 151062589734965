// @flow

import {filterActions} from 'redux-ignore';
import {TICKET_CREATED_POPUP} from '../cmp/popups/custom/TicketCreated';
import {OPEN_ORDER_ERROR_POPUP} from '../cmp/popups/custom/OpenOrderError';

export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';

const initialState = {
  name: null,
};

const openPopup = () => $('#popup').modal('show');
const closePopup = () => $('#popup').modal('hide');

function PopupReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_POPUP: {
      if (action.name !== TICKET_CREATED_POPUP && action.name !== OPEN_ORDER_ERROR_POPUP) {
        openPopup();
      }

      return {name: action.name, data: action.data};
    }

    case CLOSE_POPUP: {
      closePopup();
      return {...initialState};
    }

    default:
      return state;
  }
}

export default filterActions(PopupReducer, [OPEN_POPUP, CLOSE_POPUP]);
