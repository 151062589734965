// @flow

import {ALL_MARKET_STATS, MARKET_STATS} from '../WsReducer';

const initialState = {};

function WsMarketStatsReducer(state = initialState, action) {
  switch (action.type) {
    case MARKET_STATS: {
      const marketId = action.payload[0];
      state[marketId] = {
        lastPrice: action.payload[1],
        dailyOpen: action.payload[2],
        dailyHigh: action.payload[3],
        dailyLow: action.payload[4],
        dailyAverage: action.payload[5],
        dailyVolumeBase: action.payload[6],
        dailyVolumeQuote: action.payload[7],
      };
      const result = {...state};
      return result;
    }

    case ALL_MARKET_STATS: {
      for (const marketStat of action.payload) {
        const marketId = marketStat[0];
        state[marketId] = {
          lastPrice: marketStat[1],
          dailyOpen: marketStat[2],
          dailyHigh: marketStat[3],
          dailyLow: marketStat[4],
          dailyAverage: marketStat[5],
          dailyVolumeBase: marketStat[6],
          dailyVolumeQuote: marketStat[7],
        };
      }
      return {...state};
    }

    default:
      return state;
  }
}

export default WsMarketStatsReducer;
