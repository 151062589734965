import {EnableAccountApiKey, DisableAccountApiKey} from '../api/Actions';

const initialState = {
  data: {},
};

function ApplicationInitReducer(state = initialState, action) {
  switch (action.type) {
    case DisableAccountApiKey.events.onSuccess:
    case EnableAccountApiKey.events.onSuccess: {
      return {data: action.data};
    }
    case DisableAccountApiKey.events.onStart:
    case EnableAccountApiKey.events.onStart: {
      return {...state, fetching: true};
    }
    case DisableAccountApiKey.events.onError:
    case EnableAccountApiKey.events.onError: {
      return {...state, error: action.response.data.error, fetching: false};
    }
    case DisableAccountApiKey.events.onClear:
    case EnableAccountApiKey.events.onClear: {
      return {...initialState};
    }
    default:
      return state;
  }
}

export default ApplicationInitReducer;
