import React from 'react';

import Trading from './Trading';
import History from './History';

class TradingAndHistory extends React.PureComponent {
  state = {
    page: 1,
    limit: 100,
  };

  updatePagination = page => {
    this.setState({page});
  };

  render() {
    return (
      <div className='ordering-trading content-wrapper'>
        <Trading limit={this.state.limit} updatePagination={this.updatePagination} />
        <History page={this.state.page} limit={this.state.limit} updatePagination={this.updatePagination} />
      </div>
    );
  }
}

export default TradingAndHistory;
