import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ReactComponent as DocumentIcon} from '../../../../assets/images/documents-history/document.svg';
import {ReactComponent as UploadIcon} from '../../../../assets/images/documents-history/upload.svg';
import {ReactComponent as PendingIcon} from '../../../../assets/images/documents-history/statuses/pending.svg';
import {ReactComponent as ApprovedIcon} from '../../../../assets/images/documents-history/statuses/approved.svg';
import {ReactComponent as SubmittedIcon} from '../../../../assets/images/documents-history/statuses/submitted.svg';
import {ReactComponent as AttentionIcon} from '../../../../assets/images/documents-history/statuses/attention.svg';
import {ReactComponent as RejectedIcon} from '../../../../assets/images/documents-history/statuses/rejected.svg';

import I18n from '../helpers/I18n';
import Row from './Row';
import Column from './Column';
import Loader from 'react-loader-spinner';
import moment from 'moment';

const getSortedList = list => {
  const result = list.reduce((acc, item) => {
    switch (item.status) {
      case 'PENDING':
        acc['1'] = [...(acc['1'] || []), item];
        break;
      case 'REJECTED':
        acc['2'] = [...(acc['2'] || []), item];
        break;
      case 'SUBMITTED':
        acc['3'] = [...(acc['3'] || []), item];
        break;
      case 'COMPLETED':
        acc['4'] = [...(acc['4'] || []), item];
        break;
    }

    return acc;
  }, {});

  let statuses = [];

  Object.values(result).forEach(array => (statuses = [...statuses, ...array.reverse()]));

  return statuses;
};

const pdfLinkRegExp = new RegExp(/^https?:\/\/unitex\.ee.*?\.pdf$/i);

class Table extends PureComponent {
  static propTypes = {
    darkTheme: PropTypes.bool,
    isLoading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
    onOpenFilesUploadModal: PropTypes.func,
  };

  static defaultProps = {
    data: [],
  };

  handleOpenFilesUploadModal = request => () => {
    const {onOpenFilesUploadModal} = this.props;

    onOpenFilesUploadModal(request)();
  };

  renderCommentString(str) {
    return str
      .replace(/\s+/g, ' ')
      .trim()
      .split(' ')
      .map(word => {
        const matchesLink = word.match(pdfLinkRegExp);
        if (!matchesLink) {
          return word + ' ';
        }
        const anchor = matchesLink[0];
        return (
          <span>
            <a href={anchor} target='_blank'>
              {anchor}
            </a>
            &nbsp;
          </span>
        );
      });
  }

  renderComment(comment) {
    return comment.split('/br/').map((str, index) => <p key={index}>{this.renderCommentString(str)}</p>);
  }

  render() {
    const {isLoading, data, darkTheme, lng} = this.props;
    return (
      <table>
        <thead>
          <Row darkTheme={darkTheme}>
            <Column type='th' className='documents-history_content_table_th_attention' />
            <Column type='th'>
              <I18n tKey='common.time' />
            </Column>
            <Column type='th'>
              <I18n tKey='common.status' />
            </Column>
            <Column type='th'>
              <I18n tKey='common.type' />
            </Column>
            <Column type='th'>
              <I18n tKey='common.action' />
            </Column>
            <Column type='th' className='documents-history_content_table_th_comment'>
              <I18n tKey='common.comment' />
            </Column>
          </Row>
        </thead>
        <tbody>
          {isLoading && (
            <Row key='loader' darkTheme={darkTheme}>
              <Column>
                <div className='documents-history_content_table_loader'>
                  <Loader type='ThreeDots' color='rgba(127, 99, 255, 0.75)' height={52} width={52} />
                </div>
              </Column>
            </Row>
          )}
          {!isLoading && !data.length && (
            <Row key='loader' darkTheme={darkTheme}>
              <Column>
                <div className='documents-history_content_table__empty'>
                  <I18n tKey='documents.not_available_data' />
                </div>
              </Column>
            </Row>
          )}
          {getSortedList(data).map(item => {
            const isPending = item.status === 'PENDING';
            const isCompleted = item.status === 'COMPLETED';
            const isSubmitted = item.status === 'SUBMITTED';
            const isRejected = item.status === 'REJECTED';

            return (
              <Row key={item.id} isPending={isPending} darkTheme={darkTheme}>
                <Column key='attention' className='documents-history_content_table_td_wrapper__attention'>
                  {isPending && <AttentionIcon />}
                </Column>
                <Column key='time'>{moment(item.createdAt).locale(lng).format('MMMM DD YYYY, HH:mm')}</Column>
                <Column key='status'>
                  {isPending && <PendingIcon className={`status-${item.status.toLowerCase()}`} />}
                  {isCompleted && <ApprovedIcon className={`status-${item.status.toLowerCase()}`} />}
                  {isSubmitted && <SubmittedIcon className={`status-${item.status.toLowerCase()}`} />}
                  {isRejected && <RejectedIcon className={`status-${item.status.toLowerCase()}`} />}
                  <span className={`documents-history_content_table_td_status status-${item.status.toLowerCase()}`}>
                    <I18n tKey={`documents.${item.status.toLowerCase()}`} />
                  </span>
                </Column>
                <Column key='type' className='documents-history_content_table_td_type'>
                  {item.documentType}
                </Column>
                <Column key='action'>
                  {isPending && (
                    <button
                      type='button'
                      className='documents-history_content_table_button'
                      onClick={this.handleOpenFilesUploadModal(item)}
                    >
                      <UploadIcon className='documents-history_content_table_button_icon' />
                      <I18n tKey='documents.upload_documents' />
                    </button>
                  )}
                </Column>
                <Column key='comment' className='comment-column'>
                  {!!item.comment && (
                    <React.Fragment>
                      <div>
                        <DocumentIcon
                          className={`${
                            isPending
                              ? 'documents-history_content_table_td_icon document-pending'
                              : 'documents-history_content_table_td_icon'
                          }
                    `}
                        />
                      </div>
                      <div className={isPending ? 'document-pending' : undefined}>
                        {this.renderComment(item.comment)}
                      </div>
                    </React.Fragment>
                  )}
                </Column>
              </Row>
            );
          })}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = ({ThemeReducer, LngReducer}) => ({
  darkTheme: ThemeReducer.darkTheme,
  lng: LngReducer.lng,
});

export default connect(mapStateToProps)(Table);
