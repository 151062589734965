import React, {Component} from 'react';
import Logo from '../cmp/Logo';

export class AppPreloader extends Component {
  render() {
    return (
      <div className='app-preloader'>
        <Logo isMonoColor className='loader-logo' />
      </div>
    );
  }
}
