import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {ReactComponent as LogoShort} from '../../../assets/images/logo-short.svg';
import {ReactComponent as FullLogoDark} from '../../../assets/images/Unitex_logo_color_dark.svg';
import {ReactComponent as FullMonoColorLogo} from '../../../assets/images/Unitex_logo_dark.svg';
import {ReactComponent as FullLogoWhite} from '../../../assets/images/Unitex_logo_color_white.svg';
import {ReactComponent as LogoMonoColor} from '../../../assets/images/logo.svg';

class Logo extends Component {
  render() {
    const {isShort, isMonoColor, className, ThemeReducer} = this.props;
    const {darkTheme} = ThemeReducer;

    if (isShort) {
      return isMonoColor ? (
        <LogoMonoColor className={classNames('svg-icon', className)} />
      ) : (
        <LogoShort className={classNames('svg-icon', {['light-content']: darkTheme}, className)} />
      );
    }

    if (isMonoColor) {
      return darkTheme ? (
        <FullLogoWhite className={classNames('svg-icon', className)} />
      ) : (
        <FullLogoDark className={classNames('svg-icon', className)} />
      );
    }

    return <FullMonoColorLogo className={classNames('svg-icon', {['light-content']: darkTheme}, className)} />;
  }
}

const mapStateToProps = ({ThemeReducer}) => ({ThemeReducer});

export default connect(mapStateToProps, undefined)(Logo);
