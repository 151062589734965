import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as Actions from '../../../api/Actions';
import {bindActionCreators} from 'redux';
import I18n from '../../helpers/I18n';

const openPopup = () => $('#modal-key-confirm').modal('show');
const closePopup = () => $('#modal-key-confirm').modal('hide');

export const API_KEY_ACTIONS = {
  confirm: 'confirmApiKey',
  delete: 'deleteApiKey',
  enable2fa: 'enable-two-fa',
  disable2fa: 'disable-two-fa',
};

class ConfirmUserSettingsAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      operationError: '',
    };
    const {actionName} = props.match.params;
    switch (actionName) {
      case API_KEY_ACTIONS.confirm:
      case API_KEY_ACTIONS.delete: {
        this.goBack = () => props.history.push('/profile/ApiAccess');
        this.i18nKey = actionName === API_KEY_ACTIONS.confirm ? 'confirm' : 'delete';
        this.makeAction = actionName === API_KEY_ACTIONS.confirm ? props.confirmKey : props.deleteKey;
        break;
      }

      case API_KEY_ACTIONS.disable2fa:
      case API_KEY_ACTIONS.enable2fa: {
        this.goBack = () => props.history.push('/profile/');
        this.i18nKey = actionName === API_KEY_ACTIONS.enable2fa ? '2f_confirm_enable' : '2f_confirm_disable';
        this.makeAction = actionName === API_KEY_ACTIONS.enable2fa ? props.confirmEmail2Fa : props.disableEmail2Fa;
        break;
      }
      default:
        return null;
    }
  }

  componentDidMount() {
    // openPopup();
    this.callAction();
  }

  componentWillReceiveProps(n) {
    const {actionName} = this.props.match.params;
    let error = null;
    switch (actionName) {
      case API_KEY_ACTIONS.confirm:
      case API_KEY_ACTIONS.delete: {
        error =
          actionName === API_KEY_ACTIONS.confirm ? n.ConfirmApiKeyReducer.error : n.ConfirmRevokeApiKeyReducer.error;
        if (n.ConfirmApiKeyReducer.data.confirmationFlag || n.ConfirmRevokeApiKeyReducer.data.confirmationFlag) {
          this.goBack();
        }
        break;
      }
      case API_KEY_ACTIONS.disable2fa: {
        error = n.DisableEmail2FaReducer.error;
        if (!n.DisableEmail2FaReducer.data.allowanceFlag) {
          this.goBack();
        }
        break;
      }
      case API_KEY_ACTIONS.enable2fa: {
        error = n.ConfirmEmail2FaReducer.error;
        if (n.ConfirmEmail2FaReducer.data.allowanceFlag) {
          this.goBack();
        }
        break;
      }
      default:
        return null;
    }
    error && this.setState({operationError: error.error});
  }

  callAction = () => {
    const {curSidTs, specialMark, actionName} = this.props.match.params;
    this.setState({operationError: null});
    const is2FaOp = actionName === API_KEY_ACTIONS.enable2fa || actionName === API_KEY_ACTIONS.disable2fa;
    const params = {
      curSidTs,
      specialMark,
      ...(is2FaOp && {tfVec: 'google'}),
    };
    this.makeAction(params);
    closePopup();
  };

  cancel = () => {
    closePopup();
    this.goBack();
  };

  returnFetchingState = actionName => {
    let fetching = false;
    if (actionName === API_KEY_ACTIONS.confirm) {
      fetching = this.props.ConfirmApiKeyReducer.fetching;
    } else if (actionName === API_KEY_ACTIONS.delete) {
      fetching = this.props.ConfirmRevokeApiKeyReducer.fetching;
    } else if (actionName === API_KEY_ACTIONS.disable2fa) {
      fetching = this.props.DisableEmail2FaReducer.fetching;
    } else {
      fetching = this.props.ConfirmEmail2FaReducer.fetching;
    }
    return fetching;
  };

  render() {
    const {actionName} = this.props.match.params;
    const possibleOperation = Object.keys(API_KEY_ACTIONS).some(key => API_KEY_ACTIONS[key] === actionName);
    const fetching = this.returnFetchingState(actionName);
    return (
      <div className='modal fade small in' tabIndex='-1' role='dialog' id='modal-key-confirm'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              {possibleOperation ? (
                <Fragment>
                  <h2>
                    <I18n tKey={`popup.${this.i18nKey}_title`} />
                  </h2>
                  {this.state.operationError ? (
                    <div className='text'>{this.state.operationError}</div>
                  ) : (
                    <div className='text'>
                      <I18n tKey={`popup.btn_title_${this.i18nKey}`} />
                    </div>
                  )}
                </Fragment>
              ) : (
                <h2>
                  <I18n tKey='popup.unknown_title' />
                </h2>
              )}
            </div>
            <div className='modal-content_form'>
              <form className='ui-form'>
                {possibleOperation ? (
                  <div className='ui-form-group-btn'>
                    {fetching && (
                      <div className='ui-form-btn'>
                        <a className='btn btn-primary btn-md'>
                          <span className='upper'>
                            <I18n tKey='common.loading' />
                          </span>
                        </a>
                      </div>
                    )}
                    {!fetching && (
                      <div className='ui-form-btn'>
                        <a className='btn btn-primary btn-md' onClick={this.callAction}>
                          <span className='upper'>
                            <I18n tKey={`common.${this.i18nKey}`} />
                          </span>
                        </a>
                      </div>
                    )}
                    <div className='ui-form-btn'>
                      <a data-dismiss='modal' className='btn-link' onClick={this.cancel}>
                        <I18n tKey='common.cancel' />
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className='ui-form-group-btn'>
                    <div className='ui-form-btn'>
                      <a data-dismiss='modal' className='btn btn-primary btn-md' onClick={this.cancel}>
                        <I18n tKey='popup.return_to_terminal' />
                      </a>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  ConfirmApiKeyReducer,
  DisableEmail2FaReducer,
  ConfirmEmail2FaReducer,
  ConfirmRevokeApiKeyReducer,
}) => ({ConfirmApiKeyReducer, DisableEmail2FaReducer, ConfirmEmail2FaReducer, ConfirmRevokeApiKeyReducer});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        confirmKey: Actions.ConfirmApiKey.action,
        confirmEmail2Fa: Actions.ConfirmEmail2Fa.action,
        disableEmail2Fa: Actions.DisableEmail2Fa.action,
        deleteKey: Actions.ConfirmRevokeApiKey.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmUserSettingsAction));

export default connectedWithRouter;
