import React, {Component} from 'react';

import {ReactComponent as Symbol} from '../assets/symbol.svg';

class Button extends Component {
  render() {
    const {disabled, onClick, label} = this.props;

    return (
      <button className='fast-exchange-btn' onClick={onClick} disabled={disabled}>
        {label}
        <Symbol />
      </button>
    );
  }
}
export default Button;
