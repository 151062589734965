import NotificationItem from './NotificationItem';
import React, {Fragment} from 'react';
import {bindActionCreators} from 'redux';
import type {NotificationType} from '../../reducers/NotificationReducer';
import {connect} from 'react-redux';
import moment from 'moment';
import I18n from '../helpers/I18n';

class NotificationTab extends React.Component {
  displayName: 'NotificationTab';

  render() {
    const {NotificationReducer} = this.props;
    const nots = (NotificationReducer.notifications || []).sort((a, b) => b.cTime - a.cTime);

    const today = nots.filter((n: NotificationType) => moment(n.cTime).isSame(new Date(), 'day') && n.readFlag);
    const unread = nots.filter((n: NotificationType) => !n.readFlag);
    // const older = nots.filter((n: NotificationType) => !moment(n.cTime).isSame(new Date(), "day"));

    return (
      <Fragment>
        <div className='notification-center_title'>
          <I18n tKey='common.notifications' />
        </div>
        <div className='notification-center_list'>
          <div className='notification-center_list scrollbar-inner'>
            {nots.length === 0 && (
              <div style={{paddingLeft: '20px'}}>
                <I18n tKey='common.no_notice' />
              </div>
            )}
            {unread.length > 0 &&
              unread.map((n: NotificationType, i) => {
                return <NotificationItem enableRead={true} key={i} notification={n} onClose={this.props.onClose} />;
              })}
            {today.length > 0 &&
              today.map((n: NotificationType, i) => {
                return <NotificationItem key={i} notification={n} />;
              })}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({NotificationReducer}) => {
  return {NotificationReducer};
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTab);
