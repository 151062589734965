import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class Column extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['td', 'th']),
    className: PropTypes.string,
  };

  static defaultProps = {
    type: 'td',
  };

  render() {
    const {type, className, children} = this.props;

    return type === 'th' ? (
      <th className={`documents-history_content_table_th ${className}`}>{children}</th>
    ) : (
      <td className='documents-history_content_table_td'>
        <div className={`documents-history_content_table_td_wrapper ${className}`}>{children}</div>
      </td>
    );
  }
}

export default Column;
