// @flow
import {TICKERS} from '../WsReducer';
import Currency from '../../utils/currency';

const initialState = {tickers: {next: {}, prev: {}, changes: {}}};
const TICKER_PRECISION = 18;

function WsTickersReducer(state = initialState, action) {
  switch (action.type) {
    case TICKERS: {
      state.tickers.prev = {...state.tickers.next};
      for (let ticker of action.payload) {
        const marketId = ticker[0];
        const price = ticker[1];
        if (price && state.tickers.prev[marketId]) {
          const priceChange = Currency.calcPriceChangePercent(price, state.tickers.prev[marketId], marketId);
          if (priceChange != null && !isNaN(priceChange) && priceChange !== 0) {
            state.tickers.changes[marketId] = priceChange;
          }
        }
        state.tickers.next[marketId] = price;
      }
      return {tickers: {...state.tickers}};
    }
    default:
      return state;
  }
}

export default WsTickersReducer;
