import React from 'react';
import I18n from '../helpers/I18n';

export const Radio = ({checked, onChange, label}) => (
  <div className='form-group-line'>
    <div className='box-control'>
      <label className='form-checkbox'>
        <input onChange={onChange} type='radio' checked={checked} />
        <span className='checkmark' />
        <I18n tKey={label} />
      </label>
    </div>
  </div>
);
