// @flow
import * as React from 'react';
import {Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import qrcode from 'qrcode-generator';
import {CLOSE_POPUP} from '../../../reducers/PopupReducer';
import I18n from '../../helpers/I18n';
import {translate} from '../../../utils/i18n';

export const DEPOSIT_QR_CODE_POPUP = 'DEPOSIT_QR_CODE_POPUP';

class DepositQRCodePopup extends React.PureComponent<void, ComponentState> {
  render() {
    if (!this.props.data.address) return null;

    const {} = this.props;
    const typeNumber = 4;
    const errorCorrectionLevel = 'L';
    const qr = qrcode(typeNumber, errorCorrectionLevel);

    qr.addData(this.props.data.address);
    qr.make();
    const html = qr.createImgTag(5);

    return (
      <Fragment>
        <div className='modal-2FA-form text-center'>
          <span dangerouslySetInnerHTML={{__html: html}} />
          <h2>
            <I18n tKey='popup.scan_qr' />
          </h2>
          <div className='text-info'>
            <I18n tKey='popup.qr_code' /> {this.props.data.currency}{' '}
            {this.props.data.isMemo ? <I18n tKey='common.memo' /> : <I18n tKey='balances.deposit_address' />}
          </div>
          <div className='modal-2FA-form-text'>{this.props.data.address}</div>
          <a className='btn btn-primary btn-lg' onClick={this.props.close}>
            <I18n tKey='common.close' />
          </a>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({}, dispatch),
    close: () => dispatch({type: CLOSE_POPUP}),
  };
};
const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(DepositQRCodePopup));

export default connectedWithRouter;
