import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import BuySellForm from './BuySellForm';
import ThirdParty from './ThirdParty';
import {bindActionCreators} from 'redux';
import * as Actions from '../../api/Actions';
import {CHART_PERIODS, CHART_TYPE, FIAT_OPTIONS, OPERATION_TYPE, STEPS, ALL_FIATS, coinsSelectNames} from './constants';
import {getUserMarketsSettings, getWithdrawChains} from '../../api/Actions';
import {getCoinOHLCByFiat, getCoinsMarketsByFiat, getCoinStats, mapMarketToSelectOption} from './services';
import {request} from '../../api/Api';
import WidgetContainer from './WidgetContainer';

function loadScript(url, callback) {
  const script = document.createElement('script');
  script.src = url;
  script.type = 'text/javascript';

  // Обработчик события загрузки скрипта
  script.onload = function () {
    callback();
  };

  // Обработчик ошибки при загрузке скрипта
  script.onerror = function () {
    console.error('Не удалось загрузить скрипт:', url);
  };

  document.body.appendChild(script);
}

function openItezWidget(fromCur, toCur, fromAmount, email, lang, address, specificCoin) {
  let toCurrency = toCur;
  if (toCur === 'USDT' && specificCoin && specificCoin.value.includes('TRC20')) {
    toCurrency = 'TRC20USDT';
  }
  if (toCur === 'USDC') {
    toCurrency = 'TRC20USDC';
  }
  const correctAmount = ALL_FIATS.includes(fromCur) ? fromAmount * 100 : fromAmount * 100000000;

  const query = {
    target_element: 'widget-itez',
    timestamp: Date.now(),
    to_account: address,
    user_login: email,
    from_currency: fromCur,
    to_currency: toCurrency,
    from_amount: correctAmount,
    lang,
  };

  return request({url: '/itez/sign', method: 'POST', useNewBaseUrl: true, query: {json: JSON.stringify(query)}})(
    ({}) => {}
  ).then(resp => {
    ItezWidget.run(
      {
        partner_token: resp.data.key,
        ...query,
        signature: resp.data.signature,
      },
      'POST'
    );
  });
}

class Exchange extends Component {
  state = {
    step: STEPS.SELECT_PAIR_STEP,
    isWidgetShown: false,
    operationType: OPERATION_TYPE.BUY,
    leftCurrencyAmount: '',
    leftCurrency: FIAT_OPTIONS[0],
    leftSelectOptions: FIAT_OPTIONS,
    rightCurrencyAmount: '',
    rightCurrency: undefined,
    rightSelectOptions: [],
    coinDetails: {},
    paymentType: undefined,
    OHLC: [],
    OHLCPeriod: CHART_PERIODS[0],
    chartType: CHART_TYPE.LINE,
    specificCoin: null,
    coinRange: null,
    isError: false,
    isWidgetLoading: false,
  };

  componentDidMount() {
    loadScript('https://pay.itez.com/static/main/share/merchant.js', () => {});

    const getMarkets = async () => {
      const {data} = await getCoinsMarketsByFiat(this.state.leftCurrency.id);
      const rightSelectOptions = mapMarketToSelectOption(data);
      const hasGottenCurrencyQuery = this.props.location.query && this.props.location.query.itezCurr;
      const serverCoinName = hasGottenCurrencyQuery && coinsSelectNames[hasGottenCurrencyQuery];
      const coinExistAsOption = serverCoinName && rightSelectOptions.find(({id}) => id === serverCoinName);
      const defaultSelectedCoin = coinExistAsOption || rightSelectOptions[0];

      if (hasGottenCurrencyQuery) {
        this.checkSevralBlockchains(hasGottenCurrencyQuery);
      }
      this.getCoinOHLC({
        fiatId: this.state.leftCurrency.id,
        coinId: defaultSelectedCoin.id,
        days: this.state.OHLCPeriod.value,
      });
      this.getCoinDetails(defaultSelectedCoin);
      this.setState({rightSelectOptions: mapMarketToSelectOption(data), rightCurrency: defaultSelectedCoin});
    };
    this.props.listDepositAddresses();
    this.props.getWithdrawChains();
    getMarkets();
  }

  getCoinOHLC = ({fiatId, coinId, days}) => {
    getCoinOHLCByFiat({
      fiatId,
      coinId,
      days,
    }).then(r => {
      this.setState({
        OHLC: r.data.map(([time, open, high, low, close]) => ({time: time / 1000, open, high, low, close})),
      });
    });
  };

  handleChartTypeChange = type => {
    this.setState({chartType: type});
  };

  getCoinDetails = (coin, shouldUpdateAmounts, fiat) => {
    return getCoinStats(coin.id).then(r => {
      const {market_data} = r.data;
      if (!shouldUpdateAmounts) {
        this.setState({
          coinDetails: market_data,
        });
        return;
      }

      if (this.state.operationType === OPERATION_TYPE.SELL) {
        const rightCurrencyAmount = this.calculateOtherInputAmount(
          this.state.leftCurrencyAmount,
          true,
          fiat,
          market_data.current_price
        );

        this.setState({
          coinDetails: market_data,
          leftCurrency: coin,
          leftCurrencyAmount: this.calculateOtherInputAmount(rightCurrencyAmount),
          rightCurrencyAmount,
        });
        return;
      }

      const leftCurrencyAmount = this.calculateOtherInputAmount(
        this.state.rightCurrencyAmount,
        false,
        fiat,
        market_data.current_price
      );

      this.setState({
        coinDetails: market_data,
        rightCurrency: coin,
        leftCurrencyAmount,
        rightCurrencyAmount: this.calculateOtherInputAmount(leftCurrencyAmount, true),
      });
    });
  };

  handleOHLCPeriodChange = period => {
    this.getCoinOHLC({
      fiatId: this.getSelectedFiat().id,
      coinId: this.getSelectedCoin().id,
      days: period.value,
    });
    this.setState({OHLCPeriod: period});
  };

  calculateOtherInputAmount = (amount, isRight, currency, prices) => {
    if (!amount) {
      return '';
    }

    const comparedCurrency = currency ? currency : this.getSelectedFiat();
    const comparedCurrencyId = currency ? currency.id : this.getSelectedFiat().id;
    const comparedAmount = prices
      ? prices[comparedCurrencyId]
      : this.state.coinDetails.current_price[comparedCurrencyId];
    const isBuyOperation = this.state.operationType === OPERATION_TYPE.BUY;

    if ((isRight && isBuyOperation) || (!isRight && !isBuyOperation)) {
      const finalAmount = amount / comparedAmount;
      const fixedAmount =
        comparedCurrency.isFiat && String(finalAmount).includes('.') ? finalAmount : Number(finalAmount).toFixed(0);
      return fixedAmount;
    }
    const finalAmount = amount * comparedAmount;
    const fixedAmount =
      comparedCurrency.isFiat && String(finalAmount).includes('.') ? Number(finalAmount).toFixed(0) : finalAmount;
    return fixedAmount;
  };

  checkSevralBlockchains = digitalCurrency => {
    const {coinRange} = this.state;
    const {GetWithdrawChainsReducer} = this.props;
    const existCoin = GetWithdrawChainsReducer.data.respItemCollection.filter(
      ({internalCode}) => digitalCurrency === internalCode
    );
    const isSeveralCoins = existCoin && existCoin.length > 1;

    if (!isSeveralCoins && coinRange) {
      this.setState({coinRange: null, specificCoin: null});
    }

    if (isSeveralCoins) {
      const coinRange = existCoin.map(element => {
        return {...element, value: element.description};
      });
      this.setState({coinRange, specificCoin: coinRange[0]});
    }
  };

  handleRightAmountChange = (_, amount) => {
    this.setState({rightCurrencyAmount: amount, leftCurrencyAmount: this.calculateOtherInputAmount(amount)});
  };

  handleLeftAmountChange = (_, amount) => {
    this.setState({leftCurrencyAmount: amount, rightCurrencyAmount: this.calculateOtherInputAmount(amount, true)});
  };

  handleLeftCodeChange = currency => {
    const {operationType, rightCurrency, OHLCPeriod, leftCurrencyAmount, coinRange} = this.state;
    const {GetWithdrawChainsReducer} = this.props;

    const isSellOperation = operationType === OPERATION_TYPE.SELL;
    const fiat = isSellOperation ? rightCurrency : currency;
    const coinId = isSellOperation ? currency.id : rightCurrency.id;

    this.getCoinOHLC({
      fiatId: fiat.id,
      coinId,
      days: OHLCPeriod.value,
    });

    if (isSellOperation) {
      this.getCoinDetails(currency, true, fiat);

      const existCoin = GetWithdrawChainsReducer.data.respItemCollection.filter(
        ({internalCode}) => currency.value.symbol.toUpperCase() === internalCode
      );
      const isSeveralCoins = existCoin && existCoin.length > 1;

      if (!isSeveralCoins && coinRange) {
        this.setState({coinRange: null, specificCoin: null});
      }

      if (isSeveralCoins) {
        const coinRange = existCoin.map(element => {
          return {...element, value: element.description};
        });
        this.setState({coinRange, specificCoin: coinRange[0]});
      }
      return;
    }

    const rightCurrencyAmount = this.calculateOtherInputAmount(leftCurrencyAmount, true, fiat);
    this.setState({
      leftCurrency: currency,
      leftCurrencyAmount: this.calculateOtherInputAmount(rightCurrencyAmount),
      rightCurrencyAmount,
    });
  };

  handleRightCodeChange = currency => {
    const {operationType, leftCurrency, OHLCPeriod, rightCurrencyAmount} = this.state;

    const isBuyOperation = operationType === OPERATION_TYPE.BUY;
    const fiat = isBuyOperation ? leftCurrency : currency;
    const coinId = isBuyOperation ? currency.id : leftCurrency.id;

    this.getCoinOHLC({
      fiatId: fiat.id,
      coinId,
      days: OHLCPeriod.value,
    });

    if (isBuyOperation) {
      this.getCoinDetails(currency, true, fiat);
      this.checkSevralBlockchains(currency.value.symbol.toUpperCase());
      return;
    }

    const leftCurrencyAmount = this.calculateOtherInputAmount(rightCurrencyAmount, false, fiat);
    this.setState({
      rightCurrency: currency,
      leftCurrencyAmount,
      rightCurrencyAmount: this.calculateOtherInputAmount(leftCurrencyAmount, true),
    });
  };

  handlePaymentChange = payment => {
    this.setState({paymentType: payment});
  };

  handleBlockchainChange = coin => {
    this.setState({specificCoin: coin});
  };

  handleBuyClick = () => {
    this.setState({step: STEPS.CONFIRMATION_STEP});
  };

  openItezWidgetAfterConfirm = () => {
    this.setState({isWidgetShown: true, isWidgetLoading: true});
    const {GetDepositChainInfoReducer, SessionReducer, LngReducer} = this.props;
    const {specificCoin} = this.state;

    const userEmail =
      SessionReducer && SessionReducer.masterPersonality && SessionReducer.masterPersonality.electronicMailVal;
    const depositAddress = GetDepositChainInfoReducer.data.targetPlaceName;

    openItezWidget(
      this.state.leftCurrency.value.id || this.state.leftCurrency.value.symbol.toUpperCase(),
      this.state.rightCurrency.value.id || this.state.rightCurrency.value.symbol.toUpperCase(),
      Number(this.state.leftCurrencyAmount),
      userEmail,
      LngReducer.lng,
      depositAddress,
      specificCoin
    )
      .catch(() => {
        this.setState({isError: true});
      })
      .finally(() => {
        this.setState({isWidgetLoading: false});
      });
  };

  handleBackClick = () => {
    this.setState({step: STEPS.SELECT_PAIR_STEP});
  };

  closeWidget = () => this.setState({isWidgetShown: false, isError: false});

  handleChangeOperationType = type => {
    const {
      leftCurrency,
      rightCurrency,
      leftCurrencyAmount,
      rightCurrencyAmount,
      rightSelectOptions,
      leftSelectOptions,
      operationType,
    } = this.state;

    if (operationType !== type) {
      this.setState({
        operationType: type,
        leftCurrency: rightCurrency,
        rightCurrency: leftCurrency,
        rightSelectOptions: leftSelectOptions,
        leftSelectOptions: rightSelectOptions,
        leftCurrencyAmount: rightCurrencyAmount,
        rightCurrencyAmount: leftCurrencyAmount,
      });
    }
  };

  getIsBtnDisabled = () => {
    const {paymentType, leftCurrencyAmount, leftCurrency, rightCurrencyAmount, rightCurrency} = this.state;
    const {SessionReducer} = this.props;
    const isAvailaableAmount =
      (leftCurrency && leftCurrency.min && leftCurrencyAmount < leftCurrency.min) ||
      (leftCurrency && leftCurrency.max && leftCurrencyAmount > leftCurrency.max) ||
      (rightCurrency && rightCurrency.min && rightCurrencyAmount < rightCurrency.min) ||
      (rightCurrency && rightCurrency.max && rightCurrencyAmount > rightCurrency.max);

    return (
      !paymentType ||
      !+leftCurrencyAmount ||
      !+rightCurrencyAmount ||
      !leftCurrency ||
      !rightCurrency ||
      !SessionReducer.masterPersonality ||
      isAvailaableAmount
    );
  };

  getSelectedCoin = () => {
    const {operationType, rightCurrency, leftCurrency} = this.state;

    return operationType === OPERATION_TYPE.BUY ? rightCurrency : leftCurrency;
  };

  getSelectedFiat = () => {
    const {operationType, rightCurrency, leftCurrency} = this.state;

    return operationType === OPERATION_TYPE.BUY ? leftCurrency : rightCurrency;
  };

  render() {
    const {
      rightSelectOptions,
      paymentType,
      leftCurrencyAmount,
      leftCurrency,
      rightCurrencyAmount,
      rightCurrency,
      leftSelectOptions,
      operationType,
      OHLCPeriod,
      chartType,
      coinDetails,
      OHLC,
      isWidgetShown,
      coinRange,
      specificCoin,
      isError,
      isWidgetLoading,
    } = this.state;
    const btnBuyDisabled = this.getIsBtnDisabled();

    return (
      <div className='fast-exchange-page'>
        <div className='fast-exchange-block'>
          {isWidgetShown ? (
            <WidgetContainer onClose={this.closeWidget} isError={isError} isWidgetLoading={isWidgetLoading}>
              <div id='widget-itez' />
            </WidgetContainer>
          ) : (
            <React.Fragment>
              {this.state.step === STEPS.SELECT_PAIR_STEP && (
                <BuySellForm
                  onChangeOperationType={this.handleChangeOperationType}
                  onLeftCodeChange={this.handleLeftCodeChange}
                  onRightCodeChange={this.handleRightCodeChange}
                  onLeftAmountChange={this.handleLeftAmountChange}
                  onRightAmountChange={this.handleRightAmountChange}
                  onPaymentChange={this.handlePaymentChange}
                  onBuyClick={this.handleBuyClick}
                  onOHLCPeriodChange={this.handleOHLCPeriodChange}
                  onChartTypeChange={this.handleChartTypeChange}
                  onBlockchainChange={this.handleBlockchainChange}
                  operationType={operationType}
                  coin={this.getSelectedCoin()}
                  fiat={this.getSelectedFiat()}
                  OHLCPeriod={OHLCPeriod}
                  rightSelectOptions={rightSelectOptions}
                  leftSelectOptions={leftSelectOptions}
                  paymentType={paymentType}
                  rightCurrency={rightCurrency}
                  rightCurrencyAmount={rightCurrencyAmount}
                  leftCurrency={leftCurrency}
                  leftCurrencyAmount={leftCurrencyAmount}
                  chartType={chartType}
                  OHLC={OHLC}
                  coinDetails={coinDetails}
                  btnBuyDisabled={btnBuyDisabled}
                  userData={this.props.SessionReducer}
                  coinRange={coinRange}
                  specificCoin={specificCoin}
                />
              )}
              {this.state.step === STEPS.CONFIRMATION_STEP && (
                <ThirdParty
                  onBackClick={this.handleBackClick}
                  onLeftCodeChange={this.handleLeftCodeChange}
                  onRightCodeChange={this.handleRightCodeChange}
                  onLeftAmountChange={this.handleLeftAmountChange}
                  onRightAmountChange={this.handleRightAmountChange}
                  operationType={operationType}
                  rightCurrency={rightCurrency}
                  rightCurrencyAmount={rightCurrencyAmount}
                  leftCurrency={leftCurrency}
                  leftCurrencyAmount={leftCurrencyAmount}
                  rightSelectOptions={rightSelectOptions}
                  leftSelectOptions={leftSelectOptions}
                  userData={this.props.SessionReducer}
                  paymentType={paymentType}
                  openItezWidget={this.openItezWidgetAfterConfirm}
                  specificCoin={specificCoin}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  MarketListReducer,
  WsTickersReducer,
  ActiveMarketReducer,
  SessionReducer,
  WsMarketStatsReducer,
  CurrencyListReducer,
  CryptoPricesReducer,
  WsWalletReducer,
  OpenOrderReducer,
  UserMarketsSettings,
  GetDepositAddressReducer,
  GetDepositChainInfoReducer,
  LngReducer,
  GetWithdrawChainsReducer,
}) => {
  return {
    MarketListReducer,
    WsTickersReducer,
    ActiveMarketReducer,
    SessionReducer,
    WsMarketStatsReducer,
    CurrencyListReducer,
    CryptoPricesReducer,
    WsWalletReducer,
    OpenOrderReducer,
    UserMarketsSettings,
    GetDepositAddressReducer,
    GetDepositChainInfoReducer,
    LngReducer,
    GetWithdrawChainsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getCryptoPrices: Actions.CryptoPrices.action,
        getUserMarketsSettings: getUserMarketsSettings.action,
        listDepositAddresses: Actions.AccountDepositAddressesList.action,
        getWithdrawChains: getWithdrawChains.action,
      },
      dispatch
    ),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exchange));
