export const TOKEN_DATA = {
  CRU: {
    issueTime: null,
    issueVolume: '9,454,397,054',
    circulateValume: '9,069,339,706',
    whiteBook: 'https://wcru.io/wp-content/uploads/2022/02/doc_en.pdf',
    website: 'www.cryptounit.cc',
    browser: 'https://explorer.cryptounit.cc/',
    description:
      'CRU is a crypto-currency asset issued on the Cryptounit blockchain. It is a utility token that provides the right to obtain funding from the company, for new start-ups and operating businesses through pledging, i.e. buying and locking tokens for a certain period of time, during which the obligations of the parties must be fulfilled. In addition, CRU tokens are required by each user when paying for a personal business account, the advantage of a business account on the Cryptounit blockchain, is the ability to issue new tokens and maintain legal paperwork for legal compliance. CRU tokens are also used as a discount for target investors wishing to become co-owners of the company, providing the opportunity to purchase security WCRU tokens on preferential terms via a one-way CRU to WCRU 1:1 bridge.',
  },
  UNTB: {
    issueTime: null,
    issueVolume: '8,123,456,789',
    circulateValume: '2,897,396,831',
    whiteBook: 'https://wcru.io/wp-content/uploads/2022/02/doc_en.pdf',
    website: 'www.cryptounit.cc',
    browser: 'https://explorer.cryptounit.cc/',
    description:
      'The cryptocurrency of the Cryptounit blockchain network. The user only needs to hold a certain amount of UNTB, and not spend it (as is the case of gas spending on the Ethereum blockchain), in order to use network resources. Every user is required to have a minimum 10 UNTB that provides the crypto wallet functioning. To open a personal crypto wallet on the blockchain, one needs to buy a certain amount of UNTB, this amount of UNTB can rise and fall depending on the growth of the blockchain users and market demand. Also, the UNTB is required for community voting.',
  },
  USDU: {
    issueTime: null,
    issueVolume: '1,000,000,000,000',
    circulateValume: '6,200,000',
    whiteBook: 'https://wcru.io/wp-content/uploads/2022/02/doc_en.pdf',
    website: 'www.cryptounit.cc',
    browser: 'https://explorer.cryptounit.cc/',
    description:
      'USDU token is a stable coin, the equivalent of the US dollar, used for payments of bonuses on different loyalty programs and for dividends.\n' +
      'Each USDU coin is backed by the US dollar, positioned in custodian bank or by USDC coin, positioned in custodian escrow firm. When sending USDU, there is no gas fee, but it is necessary to always keep 10 or more UNTB to have RAM, CPU, NET of blockchain.',
  },
  BOBC: {
    issueTime: null,
    issueVolume: '1,000,000,000',
    circulateValume: null,
    whiteBook: 'https://bob.eco/Bob_eco_Whitepaper.pdf',
    website: 'https://bob.eco/',
    browser: 'https://etherscan.io/token/0xe803178b48A0e560C2b19F3b3d4e504f79D229ce',
    description:
      'Bobcoin is the first cryptonization of a for-profit social enterprise in the world. Each Bobcoin is backed by Assets.Dedicated to supplying eco-friendly vehicles that are designed to be beneficial for our planet.Bobcoin offers people a chance to build a future. Bobcoin is helping people help themselves.',
  },
  WAX: {
    issueTime: null,
    issueVolume: '3,891,795,446',
    circulateValume: '2,103,380,970',
    whiteBook: 'https://github.com/worldwide-asset-exchange/whitepaper',
    website: 'https://on.wax.io/wax-io/',
    browser: 'https://wax.bloks.io/',
    description:
      'WAX (The Worldwide Asset eXchange™) is the most used and transacted blockchain ecosystem in the world for NFTs, dApps and video games — providing the safest and most convenient way to create, buy, sell and trade both virtual and physical items to anyone, anywhere in the world.\n' +
      '\n' +
      'Known best as “The King of NFTs”, WAX is the leading NFT network and has successfully facilitated the trade of millions of NFTs from partners including Topps (Major League Baseball), Capcom (Street Fighter), Funko, Atari, Sony’s Funimation, and famous films (Princess Bride and SAW), world-renowned entertainers (Deadmau5, Weezer and William Shatner), and many more.\n' +
      '\n' +
      'Furthermore, WAX has innovated vIRL® NFTs, which are different from the standard NFTs you’ll find on other blockchains. They feature a host of dynamic functionality including app/video game integrations, marketing tools and V-commerce capabilities — linking a vIRL® NFT to a real-world item, so you can transfer ownership without needing to physically ship anything until a collector is ready to claim it as their own. Every vIRL® NFT is minted on the energy efficient and carbon neutral WAX Blockchain that puts the environment first.\n' +
      '\n',
  },
};

export const HARDCODED_DESCRIPTION = {
  EOS: 'EOSIO is a highly performant open-source blockchain platform, built to support and operate safe, compliant, and predictable digital infrastructures. The EOSIO platform provides industry-leading transaction speeds and a sub-second block time latency rate, providing the ability to support mission-critical applications. Perform transactions at a lower cost through efficient resource management to yield greater benefits from digital integration. EOSIO is designed to allow for a higher degree of configurability, particularly valuable for the creation and management of programmable architectures. Deploy public, private, permissioned, or permissionless blockchain infrastructures. Implement custom governance and business logic through executable smart contracts. Benefit from the latest in blockchain and web security verification standards with end-to-end authentication that enables superior data integrity. Build to accommodate varying compliance and regulatory frameworks with a wide range of permissions. Whether you’re a blockchain novice or expert, you can quickly onboard and scale projects on EOSIO with help from our experts. Get access to our ever-expanding sets of products, features, and tools as well as extensive training courses to take your skills to the next level.',
  LTC: 'Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities. Mathematics secures the network and empowers individuals to control their own finances. Litecoin features faster transaction confirmation times and improved storage efficiency than the leading math-based currency. With substantial industry support, trade volume and liquidity, Litecoin is a proven medium of commerce complementary to Bitcoin.',
};

export const HARDCODED_TOKENS_DATA = Object.keys(TOKEN_DATA);
