import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class Dialog extends PureComponent {
  static propTypes = {
    darkTheme: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  render() {
    const {darkTheme, open, onClose, width, height, children} = this.props;

    return (
      <section className={open ? 'dialog dialog__visible' : 'dialog'}>
        <div className={`dialog_modal ${darkTheme ? 'dialog__dark' : ''}`} style={{width, height}}>
          <button type='button' className='dialog_modal_btn-close' onClick={onClose}>
            <i className={`dialog_modal_btn-close_mark ${darkTheme ? 'dialog_button__dark' : ''}`}></i>
            <i className={`dialog_modal_btn-close_mark ${darkTheme ? 'dialog_button__dark' : ''}`}></i>
          </button>
          {children}
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ThemeReducer}) => ({
  darkTheme: ThemeReducer.darkTheme,
});

export default connect(mapStateToProps)(Dialog);
