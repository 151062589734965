import React, {Component} from 'react';

export class SwitchBtn extends Component {
  // state = {
  //   active: false
  // }
  toggleState = () => {
    // this.setState({active: !this.state.active})
    this.props.onClick && this.props.onClick();
  };

  render() {
    return (
      <div className='theme-switcher'>
        <div className='toggleWrapper'>
          <input
            defaultChecked={!this.props.active}
            onClick={this.toggleState}
            type='checkbox'
            className='dn'
            id='dn'
          />
          <label htmlFor='dn' className='toggle'>
            <span className='toggle__handler'></span>
          </label>
        </div>
      </div>
    );
  }
}
