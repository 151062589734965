import * as Actions from '../api/Actions';

export const CLEAN_SANCTION_CHECK = 'CLEAN_SANCTION_CHECK';

const initialState = {};

export const SanctionCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CheckUserSanctionStatus.events.onSuccess:
    case Actions.SubmitSanctionsData.events.onSuccess: {
      return {...action.data};
    }

    case CLEAN_SANCTION_CHECK: {
      return initialState;
    }

    default:
      return state;
  }
};
