import {bindActionCreators} from 'redux';
import * as Actions from '../api/Actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import Settings from './profile/Settings';
import Referrals from './profile/Referrals';
import ApiAccess from './profile/ApiAccess';
import Verifications from './profile/GlobalPass';
import ActivityLog from './profile/ActivityLog';
import {Link} from 'react-router-dom';
import * as React from 'react';
import Footer from './Footer';
import I18n from '../cmp/helpers/I18n';
import {URLMAP} from '../utils/const';

class BaseProfileLayout extends React.PureComponent {
  setTab = tab => {
    this.setState({tab: typeof tab === 'string' ? this.TABS[tab] : tab});
  };

  TABS = {
    Settings: <Settings setTab={this.setTab} />,
    Referrals: <Referrals setTab={this.setTab} />,
    ApiAccess: <ApiAccess setTab={this.setTab} />,
    Verifications: <Verifications setTab={this.setTab} />,
    ActivityLog: <ActivityLog setTab={this.setTab} />,
  };

  state = {
    tab: this.TABS.Settings,
  };

  componentDidMount() {
    if (this.TABS[this.props.match.params.tab]) {
      this.setTab(this.props.match.params.tab);
    } else {
      this.setTab(this.TABS.Settings);
    }
  }

  componentWillReceiveProps(n) {
    if (n.match.params.tab !== this.props.match.params.tab) {
      if (this.TABS[n.match.params.tab]) {
        this.setTab(n.match.params.tab);
      } else {
        this.setTab(this.TABS.Settings);
      }
    }
  }

  render() {
    const Component = this.state.tab;
    return (
      <div className='page-inner'>
        <div className='page-inner-up'>
          <div className='page-inner_navigate'>
            <div className='container-center'>
              <ul className='nav nav-tabs' role='tablist'>
                <li className={`nav-item ${this.state.tab === this.TABS.Settings ? 'active' : ''}`}>
                  <Link className='nav-link' to={URLMAP.PROFILE + '/'}>
                    <I18n tKey='profile.profile_settings' />
                  </Link>
                </li>
                <li className={`nav-item ${this.state.tab === this.TABS.ApiAccess ? 'active' : ''}`}>
                  <Link className='nav-link' to={URLMAP.PROFILE + '/ApiAccess'}>
                    <I18n tKey='common.api_access' />
                  </Link>
                </li>
                <li className={`nav-item ${this.state.tab === this.TABS.Verifications ? 'active' : ''}`}>
                  <Link className='nav-link' to={URLMAP.PROFILE + '/Verifications'}>
                    <I18n tKey='footer.verifications' />
                  </Link>
                </li>
                <li className={`nav-item ${this.state.tab === this.TABS.ActivityLog ? 'active' : ''}`}>
                  <Link className='nav-link' to={URLMAP.PROFILE + '/ActivityLog'}>
                    <I18n tKey='profile.activity_log' />
                  </Link>
                </li>
                <li className={`nav-item ${this.state.tab === this.TABS.Referrals ? 'active' : ''}`}>
                  <Link className='nav-link' to={URLMAP.PROFILE + '/Referrals'}>
                    <I18n tKey='profile.referrals' />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='container-center'>
            <div className='tab-content'>
              <div className='tab-pane fade active' id='profile' role='tabpanel' aria-labelledby='tab-profile'>
                {Component}
              </div>
            </div>
          </div>
        </div>
        <div className='page-inner-down'>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({SessionReducer}) => ({SessionReducer});
const mapDispatchToProps = dispatch => {
  return {...bindActionCreators({getSettings: Actions.UserSettings.action}, dispatch)};
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseProfileLayout));

export default connectedWithRouter;
