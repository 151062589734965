import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';

import {SvgWrapper} from '../cmp/helpers/SvgWrapper';
import {SwitchBtn} from '../cmp/helpers/SwitchBtn';
import I18n from '../cmp/helpers/I18n';
import {CurrencyView} from '../cmp/helpers/CurrencyView';
import ModalRegistrationNotWorking from '../cmp/modal/ModalRegistrationNotWorking';
import ExchangeVolumeStats, {EXCHANGE_MODE} from './ExchangeVolumeStats';

import {ReactComponent as UserIc} from '../../../assets/images/nav/user.svg';
import {ReactComponent as ArrowDown} from '../../../assets/images/icon/dropdown-mark.svg';

import type {CurrencyTO, WalletTO} from '../api/types/models';
import {UserLogout} from '../api/Actions';

import {SWITCH_THEME} from '../reducers/ThemeReducer';
import {SET_LNG} from '../reducers/LngReducer';

import Currency from '../utils/currency';
import {languages, URLMAP} from '../utils/const';

class Header extends React.Component {
  displayName: 'Header';

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      date: new Date(),
      modalIsShow: false,
    };
  }

  doLogout() {
    this.props.logout();
  }

  // componentDidMount() {
  //   this.clockIntervalId = setInterval(
  //     () => this.setState({date: new Date()}),
  //     1000,
  //   );
  // }

  // componentWillUnmount() {
  //   clearInterval(this.clockIntervalId);
  // }

  changeLng = lng => {
    this.props.changeLng(lng);
  };

  changeStateModal = () => {
    this.setState({modalIsShow: !this.state.modalIsShow});
  };

  openLoginPage = () => {
    this.props.history.push(URLMAP.LOGIN);
  };

  openSignUpPage = () => {
    this.props.history.push(URLMAP.SIGNUP);
  };

  render() {
    const {CurrencyListReducer, WsWalletReducer, ActiveMarketReducer, SessionReducer, ApplicationSettingsReducer} =
      this.props;

    const user = SessionReducer.masterPersonality || {};
    let leftWallet,
      rightWallet = null;

    if (ActiveMarketReducer && WsWalletReducer) {
      leftWallet = WsWalletReducer.byCurrencyMap[ActiveMarketReducer.leftMonetaryUnitCode];
      rightWallet = WsWalletReducer.byCurrencyMap[ActiveMarketReducer.rightMonetaryUnitCode];
    }

    const crossCourses = WsWalletReducer.crossCourses || {};
    const destinationCurrency = 'USDU';

    const totalInUSD = Object.keys(WsWalletReducer.byCurrencyMap || {}).reduce((s, cc) => {
      const wallet: WalletTO = WsWalletReducer.byCurrencyMap[cc];
      if (cc.toUpperCase() === destinationCurrency) {
        return s + wallet.aValue + wallet.resValue;
      } else if (
        wallet &&
        crossCourses &&
        crossCourses[destinationCurrency] &&
        crossCourses[destinationCurrency][wallet.monetaryUnitCode]
      ) {
        return s + (wallet.aValue + wallet.resValue) / crossCourses[destinationCurrency][wallet.monetaryUnitCode];
      } else {
        return s;
      }
    }, 0);
    const languagesListKeys = Object.keys(this.props.LngReducer.languages);

    if (this.state.modalIsShow === true) {
      return <ModalRegistrationNotWorking changeStateModal={this.changeStateModal} />;
    }

    return (
      <header className='header'>
        <div className='header_left'>
          {user.uniqueSymbolRow && (
            <div className='header_left'>
              <div className={`header-balance dropdown ${this.state.isOpenTotal ? 'open' : ''}`}>
                {' '}
                {/*onMouseEnter={() => this.setState({isOpenTotal: true})} onMouseLeave={() => this.setState({isOpenTotal: false})}*/}
                <div className='header-balance_item'>
                  {/*onClick={() => this.setState({isOpenTotal: !this.state.isOpenTotal})}*/}
                  <I18n tKey='header.total_balance' />
                  &#58;{' '}
                  <span className='value'>
                    <CurrencyView isFiat={true} amount={totalInUSD} type='USD' showType />
                  </span>
                  <SvgWrapper themeWatch className='icon icon-dropdown ic-size-03' SvgCmp={ArrowDown} />
                </div>
                <div className='header-balance_list dropdown_menu'>
                  <div className='header-balance_list-title'>
                    <I18n tKey='layout.est_cur' />
                  </div>
                  {(CurrencyListReducer.list || []).map((c: CurrencyTO, i) => {
                    const wallet = WsWalletReducer.byCurrencyMap[c.mUCryptogram];
                    return wallet ? (
                      <div key={i} className='header-balance_list-item' style={{whiteSpace: 'nowrap'}}>
                        <span>{Currency.show(wallet.aValue, wallet.monetaryUnitCode, true)}</span>{' '}
                        {wallet.monetaryUnitCode}
                      </div>
                    ) : (
                      <span key={i} />
                    );
                  })}
                </div>
              </div>

              <div
                className={`header-balance dropdown ${this.state.isOpenAvailable ? 'open' : ''}`}
                onMouseEnter={() => this.setState({isOpenAvailable: true})}
                onMouseLeave={() => this.setState({isOpenAvailable: false})}
              >
                <div className='header-balance_item'>
                  <I18n tKey='layout.available' />
                  &#58;{' '}
                  <span className='value'>
                    {leftWallet && (
                      <React.Fragment>
                        <span>
                          <CurrencyView
                            amount={leftWallet.aValue}
                            type={leftWallet.monetaryUnitCode}
                            appearance={leftWallet.currentT}
                          />
                        </span>{' '}
                        {leftWallet.monetaryUnitCode}
                      </React.Fragment>
                    )}{' '}
                    /&nbsp;
                    {rightWallet && (
                      <React.Fragment>
                        <span>
                          <CurrencyView
                            amount={rightWallet.aValue}
                            type={rightWallet.monetaryUnitCode}
                            appearance={rightWallet.currentT}
                          />
                        </span>{' '}
                        {rightWallet.monetaryUnitCode}
                      </React.Fragment>
                    )}
                  </span>
                  <SvgWrapper themeWatch className='icon icon-dropdown ic-size-03' SvgCmp={ArrowDown} />
                </div>
                <div className='dropdown_menu'>
                  <Link className='dropdown_menu-item' style={{whiteSpace: 'nowrap'}} to={URLMAP.BALANCES}>
                    <I18n tKey='layout.link_balance' />
                  </Link>
                  <Link className='dropdown_menu-item' to={URLMAP.BALANCES + '/TradingHistory'}>
                    <I18n tKey='common.link_history' />
                  </Link>
                  <Link className='dropdown_menu-item' to={URLMAP.BALANCES + '/FeesAndLimits'}>
                    <I18n tKey='common.link_limit' />
                  </Link>
                  <Link className='dropdown_menu-item' to={URLMAP.BALANCES + '/TransactionHistory'}>
                    <I18n tKey='common.link_trx' />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='header_center'>
          <ExchangeVolumeStats mode={EXCHANGE_MODE} />
        </div>

        <div className='header_right'>
          <div className='header-nav'>
            {/*<div className="header-nav_item connect">*/}
            {/*<span className="title"><i className="icon icon-menu-connect"/>{Formatter.time(this.state.date)}</span>*/}
            {/*</div>*/}
            {/*<div className={`dropdown ${this.state.panels ? "open" : ""}`} onMouseEnter={() => this.setState({panels: true})} onMouseLeave={() => this.setState({panels: false})}>*/}
            {/*<a className="header-nav_item dropdown_btn"><span className="title">Manage panels</span><SvgWrapper className="icon-dropdown ic-size-03" SvgCmp={ArrowDown}/></a>*/}
            {/*<div className="dropdown_menu widget">*/}
            {/*<div className="dropdown_menu-title">Drag or click to add</div>*/}
            {/*<a className="dropdown_menu-item widget">Orderbook<span className="action"><i className="icon icon-max"/>add</span></a>*/}
            {/*<a className="dropdown_menu-item widget enabled">Recent Trades <i className="icon icon-checked"/></a>*/}
            {/*<a className="dropdown_menu-item widget enabled">Market Overview <i className="icon icon-checked"/></a>*/}
            {/*<a className="dropdown_menu-item widget widget-drag">Widget #4<span className="action"><i className="icon icon-max"/> add</span></a>*/}
            {/*<a className="dropdown_menu-item widget widget-drag">Widget #3<span className="action"><i className="icon icon-max"/> add</span></a>*/}
            {/*<a className="dropdown_menu-item widget enabled">Price alerts <i className="icon icon-checked"/></a>*/}
            {/*<a className="dropdown_menu-item widget enabled">Market depth <i className="icon icon-checked"/></a>*/}
            {/*<a className="dropdown_menu-item widget widget-drag">Widget #2<span className="action"><i className="icon icon-max"/> add</span></a>*/}
            {/*<a className="dropdown_menu-item widget widget-drag">Widget #1<span className="action"><i className="icon icon-max"/> add</span></a>*/}
            {/*<a className="dropdown_menu-item widget enabled">Trade History <i className="icon icon-checked"/></a>*/}
            {/*<a className="dropdown_menu-item widget enabled">Balances <i className="icon icon-checked"/></a>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div className={`dropdown ${this.state.workplace ? "open" : ""}`} onMouseEnter={() => this.setState({workplace: true})} onMouseLeave={() => this.setState({workplace: false})}>*/}
            {/*<a className="header-nav_item dropdown_btn"><span className="title"><i className="icon icon-menu-workplace"/>Default workplace</span><SvgWrapper className="icon-dropdown ic-size-03" SvgCmp={ArrowDown}/></a>*/}
            {/*<div className="dropdown_menu">*/}
            {/*<div className="dropdown_menu-title">Presets</div>*/}
            {/*<a className="dropdown_menu-item"><span className="title"><i className="icon icon-menu-workplace"/>Default workplace</span></a>*/}
            {/*<a className="dropdown_menu-item"><span className="title"><i className="icon icon-menu-simple"/>Simple</span></a>*/}
            {/*<a className="dropdown_menu-item"><span className="title"><i className="icon icon-menu-advanced"/>Advanced</span></a>*/}

            {/*<div className="dropdown_menu-title">Presets</div>*/}
            {/*<a className="dropdown_menu-item">New workplace <i className="icon icon-menu-deposit"/></a>*/}
            {/*<a className="dropdown_menu-item">New workplace #1<i className="icon icon-close"/></a>*/}

            {/*<div className="dropdown_menu-text">*/}
            {/*Changes made in Custom workspaces are automatically saved.*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            <div>
              <SwitchBtn active={this.props.ThemeReducer.darkTheme} onClick={this.props.switchTheme} />
            </div>

            <div
              className={`dropdown ${this.state.userMenu ? 'open' : ''}`}
              onMouseEnter={() => this.setState({userMenu: true})}
              onMouseLeave={() => this.setState({userMenu: false})}
            >
              <a className='header-nav_item dropdown_btn'>
                <span className='title'>
                  <SvgWrapper themeWatch className='icon icon-user ic-size-06' SvgCmp={UserIc} />{' '}
                  {user.curUName || <I18n tKey='header:user' />}
                </span>
                <SvgWrapper themeWatch className='icon icon-dropdown ic-size-03' SvgCmp={ArrowDown} />
              </a>
              <div className='dropdown_menu'>
                {user.uniqueSymbolRow && (
                  <React.Fragment>
                    <Link to={URLMAP.PROFILE} className='dropdown_menu-item'>
                      <I18n tKey='common.profile' />
                    </Link>
                    <a onClick={this.doLogout.bind(this)} className='dropdown_menu-item'>
                      <I18n tKey='common.logout' />
                    </a>
                  </React.Fragment>
                )}
                {!user.uniqueSymbolRow && (
                  <React.Fragment>
                    <a onClick={this.openLoginPage} className='dropdown_menu-item'>
                      <I18n tKey='common.login' />
                    </a>
                    <a
                      onClick={
                        ApplicationSettingsReducer.data.signupInabilityFlag
                          ? this.changeStateModal
                          : this.openSignUpPage
                      }
                      className='dropdown_menu-item'
                    >
                      <I18n tKey='common.sign_up' />
                    </a>
                  </React.Fragment>
                )}
              </div>
            </div>

            <div
              className={`dropdown ${this.state.language ? 'open' : ''}`}
              onMouseEnter={() => this.setState({language: true})}
              onMouseLeave={() => this.setState({language: false})}
            >
              <a className='header-nav_item dropdown_btn'>
                <span className='title'>
                  <span className={`flag flag-${this.props.LngReducer.lng}`} />
                </span>
                <span>{languages[this.props.LngReducer.lng] || this.props.LngReducer.lng}</span>
              </a>
              <div className='dropdown_menu center lng-list'>
                {languagesListKeys.map((key, i) => (
                  <a
                    className={`dropdown_menu-link ${this.props.LngReducer.lng === key ? 'active' : ''}`}
                    onClick={this.changeLng.bind(this, key)}
                    key={i}
                  >
                    <div className='lng-option-wrapper'>
                      <span className={`flag flag-${key}`} /> <span>{languages[key] || key}</span>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({
  ActiveMarketReducer,
  ApplicationSettingsReducer,
  SessionReducer,
  WsWalletReducer,
  CurrencyListReducer,
  CountryListReducer,
  ThemeReducer,
  LngReducer,
}) => {
  return {
    ActiveMarketReducer,
    ApplicationSettingsReducer,
    SessionReducer,
    WsWalletReducer,
    CurrencyListReducer,
    CountryListReducer,
    ThemeReducer,
    LngReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({logout: UserLogout.action}, dispatch),
    switchTheme: () => dispatch({type: SWITCH_THEME}),
    changeLng: lng => dispatch({type: SET_LNG, payload: {lng}}),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
