import React, {Component} from 'react';
import {changeFormat} from '../../utils/utils';
import {CURRENCIES_DIGITS} from '../../utils/currenciesDigits';
import BigNumber from 'bignumber.js';

export const WITHDRAW_CURRENCY_PRECISION_SUFFIX = '_withdraw';

export class CurrencyView extends Component {
  returnFormattedAmount = () => {
    const {
      type = '',
      amount = 0,
      isFiat,
      accuracy,
      appearance,
      converterCourse,
      useWithdrawPrecision,
      maxDigits,
    } = this.props;
    let digits;

    if (maxDigits) {
      digits = maxDigits;
    } else {
      if (isFiat || appearance === 'FIAT') {
        digits = CURRENCIES_DIGITS.get('FIAT');
      } else {
        let getDigitsForCurCode = type.toUpperCase();
        if (useWithdrawPrecision && CURRENCIES_DIGITS.has(getDigitsForCurCode + WITHDRAW_CURRENCY_PRECISION_SUFFIX)) {
          getDigitsForCurCode += WITHDRAW_CURRENCY_PRECISION_SUFFIX;
        }
        digits = CURRENCIES_DIGITS.get(getDigitsForCurCode) || 0;
      }
    }

    if (converterCourse) {
      return new BigNumber(amount).multipliedBy(converterCourse).toFixed(accuracy || digits);
    }
    // return changeFormat(amount, digits);
    return new BigNumber(amount).multipliedBy(1).toFixed(accuracy || digits);
  };

  render() {
    const {type = '', showType, amount = 0, showOriginal} = this.props;
    if (showOriginal) {
      return (
        <span>
          {' '}
          {amount} {showType && <span>{type}</span>}
        </span>
      );
    }
    const formattedAmount = this.returnFormattedAmount();
    return (
      <span>
        {formattedAmount} {showType && <span>{type}</span>}
      </span>
    );
  }
}
