// @flow

import axios from 'axios';
import type {FetchRequest, HttpMethod} from './types/internalFlow';
import * as React from 'react';
import {parseError} from '../utils/i18n';
import {TOKEN_EXPIRED, USER_STORAGE_KEY} from '../reducers/SessionReducer';
import * as storageHelper from 'storage-helper';

export const GET: HttpMethod = 'GET';
export const POST: HttpMethod = 'POST';
export const PUT: HttpMethod = 'PUT';
export const DELETE: HttpMethod = 'DELETE';

export const fetcher = axios.create({
  baseURL: window.settings.APIURL,
});

export const newApiFetcher = axios.create({
  baseURL: window.settings.APIURL2,
});

function checkError(error, dispatch) {
  if (error.response && error.response.status && error.response.status === 401) {
    dispatch({type: TOKEN_EXPIRED});
  }
}

export function getUserToken() {
  return (JSON.parse(storageHelper.getItem(USER_STORAGE_KEY)) || {}).specialMark;
}

function makeRequest<REQ, RESP>({
  url,
  method,
  onStart,
  onSuccess,
  onError,
  query,
  isFormData,
  useNewBaseUrl,
}: FetchRequest<REQ>) {
  return async (dispatch: any) => {
    dispatch({type: onStart, responseURL: url, query: query});
    let response = null;
    const requester = useNewBaseUrl ? newApiFetcher : fetcher;

    try {
      response = await requester.request({
        url: url,
        method: method,
        headers: isFormData
          ? {
              'Content-Type': 'multipart/form-data',
              'x-token': getUserToken(),
            }
          : {
              'x-token': getUserToken(),
            },
        data: query,
      });
      // console.debug(url, method, response);
      dispatch({type: onSuccess, data: response.data, responseURL: url, query: query});
    } catch (error) {
      const {response} = await error;
      console.warn(url, method, response);

      checkError(error, dispatch);

      if (response && response.data) {
        response.data.parsedError = parseError(response.data);
      }

      dispatch({type: onError, response, responseURL: url, query: query});

      return Promise.resolve({isError: true, params: query, data: response.data});
    }
    return response;
  };
}

export function request<REQ, RESP>({
  url,
  method,
  onStart,
  onSuccess,
  onError,
  query,
  isFormData,
  useNewBaseUrl,
}: FetchRequest<REQ>) {
  return makeRequest({url, method, onStart, onSuccess, onError, query, isFormData, useNewBaseUrl});
}
