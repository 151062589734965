import React, {Component} from 'react';

import I18n from '../helpers/I18n';

import {ReactComponent as Icon} from '../../../../assets/images/icon/adaptive.svg';
import Logo from '../Logo';
import {ADAPTIVE_VERSION_HINT_DISABLED, ADAPTIVE_DESIGN_ENABLED, mobilePath} from '../../utils/const';

class EnableMobileVersion extends Component {
  componentDidMount() {
    document.body.style.minWidth = 'initial';
    document.body.style.minHeight = 'initial';
  }

  componentWillUnmount() {
    document.body.style.minWidth = '1280px';
    document.body.style.minHeight = '700px';
  }

  handleCancelClick = () => {
    localStorage.setItem(ADAPTIVE_VERSION_HINT_DISABLED, JSON.stringify(true));
    localStorage.setItem(ADAPTIVE_DESIGN_ENABLED, JSON.stringify(false));
    this.props.onBlockClose(true);
  };

  handleEnableClick = () => {
    localStorage.setItem(ADAPTIVE_VERSION_HINT_DISABLED, JSON.stringify(true));
    localStorage.setItem(ADAPTIVE_DESIGN_ENABLED, JSON.stringify(true));
    window.location.replace(`${window.location.origin}${mobilePath}`);
    this.props.onBlockClose(true);
  };

  render() {
    return (
      <div className='adaptive-version-popup'>
        <Logo className='adaptive-version-popup__logo' />

        <div className='adaptive-version-popup__icon-wrapper'>
          <Icon />
        </div>

        <div className='adaptive-version-popup__description'>
          <I18n tKey='enable_mobile_version:description' />
        </div>

        <div className='adaptive-version-popup__footer'>
          <button onClick={this.handleEnableClick} className='btn btn-purple'>
            <I18n tKey='common.enable' />
          </button>
          <button onClick={this.handleCancelClick} className='btn'>
            <I18n tKey='common.cancel' />
          </button>
        </div>
      </div>
    );
  }
}

export default EnableMobileVersion;
