// @flow

/* Main imports and helpers */
import * as React from 'react';
import {Fragment} from 'react';
import * as Actions from '../../../api/Actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
/* Actions */
import InputPopup from '../InputPopup';
import {translate} from '../../../utils/i18n';
import I18n from '../../helpers/I18n';

export const FORGOT_PASSWORD_POPUP = 'FORGOT_PASSWORD_POPUP';

class ForgotPasswordPopup extends React.PureComponent<void, ComponentState> {
  state = {
    email: '',
    isResetHasbeenSent: '',
    resetPwdError: '',
  };

  restore = () => {
    const {email} = this.state;
    this.setState({emailError: null});

    let hasError = false;

    if (!email) {
      this.setState({resetPwdError: {error: 'popup.not_be_empty'}});
      hasError = true;
    }

    if (!hasError) {
      this.props.requestPasswordReset({electronicPostAddr: email});
    }
  };

  setEmail = value => {
    this.setState({email: value.trim()});
    if (value === '') {
      this.setState({resetPwdError: ''});
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.RequestPasswordResetReducer) {
      if (nextProps.RequestPasswordResetReducer.data.sendingFlag && !nextProps.RequestPasswordResetReducer.error) {
        this.setState({isResetHasbeenSent: nextProps.RequestPasswordResetReducer.data.sendingFlag});
      }
      if (nextProps.RequestPasswordResetReducer.error) {
        this.setState({isResetHasbeenSent: false, resetPwdError: nextProps.RequestPasswordResetReducer.error});
      }
    }
  }

  onPressEnter = event => {
    if (event.key === 'Enter') {
      this.restore();
    }
  };

  render() {
    const {fetching} = this.props;
    const {isResetHasbeenSent, resetPwdError} = this.state;
    return !isResetHasbeenSent ? (
      <Fragment>
        <div className='modal-signup_forgot'>
          <h2>
            <I18n tKey='common.forgot_pswd' />
          </h2>
          <div className='text'>
            <I18n tKey='popup.dont_worry' />
          </div>
        </div>
        <div className='modal-signup_form'>
          <form className='ui-form'>
            <InputPopup
              error={resetPwdError}
              placeholder='common.email'
              onKeyDown={this.onKeyDown}
              value={this.state.email}
              onChange={this.setEmail}
              label='popup.enter_email'
              onPressEnter={this.onPressEnter}
            />
            <div className='ui-form-btn'>
              {fetching && (
                <button disabled type='button' className='btn btn-md btn-primary btn-full'>
                  <I18n tKey='popup.send_link' />
                </button>
              )}
              {!fetching && (
                <button type='button' onClick={this.restore} className='btn btn-md btn-primary btn-full'>
                  <I18n tKey='popup.send_link' />
                </button>
              )}
            </div>
            {/*<div className="popup-error-message common">*/}
            {/*{resetPwdError && <div>{resetPwdError.error}</div>}*/}
            {/*</div>*/}
          </form>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className='modal-signup_forgot'>
          <h2>
            <I18n tKey='common.forgot_pswd' />
          </h2>
          <div className='text'>
            <I18n tKey='common.check_email' />
          </div>
        </div>
        <div className='modal-signup_form'>
          <form className='ui-form'>
            <div className='ui-form-btn'>
              <a data-dismiss='modal' className='btn btn-md btn-primary btn-full'>
                <I18n tKey='common.close' />
              </a>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({RequestPasswordResetReducer}) => ({
  RequestPasswordResetReducer,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      requestPasswordReset: Actions.RequestPasswordReset.action,
    },
    dispatch
  );

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPopup));

export default connectedWithRouter;
