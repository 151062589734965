// @flow
import {CLOSE, MESSAGE, OPEN} from '../middleware/ws';
import {USER_SESSION_FINISHED, USER_SESSION_STARTED} from './SessionReducer';
import {UserLogout} from '../api/Actions';
import {SAVED_PRECISION, UPDATE_ORDER_BOOK} from './OrderbookReducer';
import axios from 'axios';

//subscribing actions
export const SEND_TO_WS = 'SEND_TO_WS';
export const SUBSCRIBE_TO_MARKET = 'SUBSCRIBE_TO_MARKET';
export const SUBSCRIBE_TO_RESOLUTION = 'SUBSCRIBE_TO_RESOLUTION';
export const SUBSCRIBE_TO_PRECISION = 'SUBSCRIBE_TO_PRECISION';
export const OHLCV_SUBSCRIBE = 'OHLCV_SUBSCRIBE';
export const OHLCV_UNSUBSCRIBE = 'OHLCV_UNSUBSCRIBE';
export const SUBSCRIBE = 'SUBSCRIBE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const SUBSCRIBE_TO_ALL_MARKET_STATS = 'SUBSCRIBE_TO_ALL_MARKET_STATS';

//ws events actions
export const MARKET_STATS: string = 'MARKET_STATS';
export const ALL_MARKET_STATS: string = 'ALL_MARKET_STATS';
export const MARKET_CHANGED: string = 'MARKET_CHANGED';
export const TICKERS: string = 'TICKERS';
export const OHLCV_DATA: string = 'OHLCV_DATA';
export const OHLCV_DATA_UPDATE: string = 'OHLCV_DATA_UPDATE';
export const ORDER_BOOK: string = 'ORDER_BOOK';
export const ORDER_BOOK_DIFF: string = 'ORDER_BOOK_DIFF';
export const TRADE_HISTORY: string = 'TRADE_HISTORY';
export const TRADE_HISTORY_UPDATE: string = 'TRADE_HISTORY_UPDATE';
export const BALANCE_UPDATED: string = 'BALANCE_UPDATED';
export const ACCOUNTS_TRADE: string = 'ACCOUNTS_TRADE';

const initialState = {};

const orderBookApiUrl = window.settings.APIURL2;

const orderBookSubscription = {
  intervalId: undefined,
  precision: undefined,
  marketId: undefined,
};

const unsubscribes = {
  market: null,
  ohlcv: null,
  user: null,
  reconnectMarket: null,
  reconnectOhlcv: null,
  reconnectUser: null,
};

let connected = false;
let ws;
export const getSocket = () => ws;

const send = (message, onSent) => {
  let mes = message;
  if (typeof message !== 'string') {
    mes = JSON.stringify(message);
  }
  if (!ws || !connected) {
    setTimeout(() => send(mes, onSent), 200);
  } else {
    try {
      ws.send(mes);
      onSent && onSent();
    } catch (e) {
      console.warn(e);
    }
  }
};

const ohlcvSubscribe = marketId => {
  unsubscribes.ohlcv && unsubscribes.ohlcv();

  const message = {
    messageType: OHLCV_SUBSCRIBE,
    subscribeMessage: {
      marketId: marketId,
      resolution: '1m',
    },
  };

  send(message, () => {
    unsubscribes.ohlcv = () => {
      send({
        messageType: OHLCV_UNSUBSCRIBE,
        unsubscribeMessage: message.subscribeMessage,
      });
      unsubscribes.ohlcv = null;
    };

    unsubscribes.reconnectOhlcv = () => send(message);
  });
};

const userSubscribe = (accountId, token) => {
  unsubscribes.user && unsubscribes.user();
  const message = {
    messageType: 'LOGIN',
    loginMessage: {
      accountId: accountId,
      jwt: token,
    },
  };

  send(message, () => {
    unsubscribes.user = () => {
      send({
        messageType: 'LOGOUT',
      });
      unsubscribes.user = null;
    };
    unsubscribes.reconnectUser = () => send(message);
  });
};

const orderBookSubscribe = (subscribeMarketId, asyncDispatch, subscribePrecision) => {
  clearInterval(orderBookSubscription.intervalId);

  const nextPrecision = subscribePrecision || localStorage.getItem(SAVED_PRECISION) || 0;
  orderBookSubscription.marketId = subscribeMarketId;
  orderBookSubscription.precision = nextPrecision;

  const getOrders = async () => {
    const {data} = await axios.get(
      `${orderBookApiUrl}exchange/orderbook?marketId=${subscribeMarketId}&precision=P${nextPrecision}`
    );

    const {marketId, precision, buyOrderbookList, sellOrderbookList} = data || {
      marketId: subscribeMarketId,
      precision: nextPrecision,
      buyOrderbookList: [],
      sellOrderbookList: [],
    };

    if (marketId === orderBookSubscription.marketId && +precision === +orderBookSubscription.precision) {
      //prevent old data rewrite new one, in case response will be late
      asyncDispatch({type: UPDATE_ORDER_BOOK, payload: {marketId, precision, buyOrderbookList, sellOrderbookList}});
    }
  };

  getOrders();

  orderBookSubscription.intervalId = setInterval(() => {
    getOrders();
  }, 5000);
};

const marketSubscribe = (marketId, precision) => {
  unsubscribes.market && unsubscribes.market();

  const message = {
    messageType: SUBSCRIBE,
    subscribeMessage: {
      marketId: marketId,
      precision: precision || localStorage.getItem(SAVED_PRECISION) || 'P0',
      resolution: '1m',
    },
  };

  send(message, () => {
    unsubscribes.market = () => {
      send({
        messageType: UNSUBSCRIBE,
        unsubscribeMessage: message.subscribeMessage,
      });
      unsubscribes.market = null;
    };
    unsubscribes.reconnectMarket = () => send(message);
  });
};

function WsReducer(state = initialState, action) {
  ws = action.meta && action.meta.webSocket ? action.meta.webSocket : ws;
  switch (action.type) {
    case `@@websocket/${OPEN}`: {
      connected = true;
      [unsubscribes.reconnectMarket, unsubscribes.reconnectOhlcv, unsubscribes.reconnectUser].forEach(cb => cb && cb()); // when we lose connection, wi will call open action, and will try to reconnect via cb
      return state;
    }
    case `@@websocket/${CLOSE}`: {
      connected = false;
      return state;
    }
    case `@@websocket/${MESSAGE}`: {
      return state;
    }

    case SEND_TO_WS: {
      send(action.payload);
      return state;
    }

    case SUBSCRIBE_TO_MARKET: {
      marketSubscribe(action.id);
      orderBookSubscribe(action.id, action.asyncDispatch);
      ohlcvSubscribe(action.id);
      return state;
    }

    case SUBSCRIBE_TO_PRECISION: {
      const precision = isNaN(action.precision) ? action.precision : 'P' + action.precision;
      marketSubscribe(action.ctcInstrIdentifier, precision);
      orderBookSubscribe(action.ctcInstrIdentifier, action.asyncDispatch, action.precision);
      return state;
    }

    case 'WS_ERROR': {
      const error = (action.payload && action.payload.errorType) || null;
      if (error === 'AUTH_ERROR') {
        action.asyncDispatch({type: UserLogout.events.onSuccess});
      }
      return state;
    }

    case USER_SESSION_STARTED: {
      userSubscribe(action.session.masterPersonality.masterRespList[0], action.jwt);
      return state;
    }

    case USER_SESSION_FINISHED: {
      unsubscribes.user && unsubscribes.user();
      return state;
    }

    // case LOCATION_CHANGE: {
    //   //do not unsubscribe, because golden layout has never unmounted just hidden
    //   if (isTerminal(action.payload.pathname)) {
    //   } else {
    //   }
    //   return state;
    // }

    case SUBSCRIBE_TO_ALL_MARKET_STATS: {
      send({messageType: 'SUBSCRIBE_TO_MARKET_STATS'});
      return state;
    }

    default:
      return state;
  }
}

export default WsReducer;
