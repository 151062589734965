import {validate} from 'wallet-address-validator';

const VALID_WAX_ADDRESS_ENDPOINT = 'https://wax.eosdac.io/v1/chain/get_account';
const VALID_CRU_USDU_UNTB_ADDRESS_ENDPOINT = 'https://api.cryptounit.cc/v1/chain/get_account';
const VALID_TRON_ADDRESS_ENDPOINT = 'https://apilist.tronscan.org/api/accountv2?address=';

const UNITEX_EOS_ADDRESS = 'exchangewall';
const UNITEX_WAX_ADDRESS = 'exchangeuser';
const UNITEX_XRP_ADDRESS = 'rQfw3vHzLTU4Scg8Yk4KrBcXatA2s2xFme';

const USDU_CURRENCY = 'usdu';
const USDU_RESTRICTED_ADDRESS = 'interchange';
const BAD_REQUEST_CODE = 400;

import {isChecksumAddress} from 'eth-connect';

export const isValidEthAddress = address => {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
    return false;
  } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
    return true;
  } else {
    return isChecksumAddress(address);
  }
};

export const isValidEosAddress = address => {
  if (!address) {
    return 'Empty address';
  }
  if (address === UNITEX_EOS_ADDRESS) {
    return 'Unitex Exchange address can not be used';
  }

  return 'OK';
};

export const isValidWaxAddress = async address => {
  if (address === UNITEX_WAX_ADDRESS) {
    return 'Unitex Exchange address can not be used';
  }
  const rawResponse = await fetch(VALID_WAX_ADDRESS_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({account_name: address}),
  });
  return rawResponse.status;
};

export const isValidXrpAddress = async address => {
  if (address.toLowerCase() === UNITEX_XRP_ADDRESS.toLowerCase()) {
    return false;
  }
  const result = validate(address, 'XRP');
  return result;
};

export const isValidCruUsduUntbAddress = async (currencyName, address) => {
  if (currencyName.toLowerCase() === USDU_CURRENCY && address.toLowerCase() === USDU_RESTRICTED_ADDRESS) {
    return BAD_REQUEST_CODE;
  }
  if (address.toLowerCase() === UNITEX_EOS_ADDRESS) {
    return BAD_REQUEST_CODE;
  }
  const rawResponse = await fetch(VALID_CRU_USDU_UNTB_ADDRESS_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({account_name: address}),
  });
  return rawResponse.status;
};

export const isValidTronAddress = async address => {
  const response = await fetch(VALID_TRON_ADDRESS_ENDPOINT + address);
  try {
    const body = await response.json();
    return !!body && !!body.address && body.address.toLowerCase() === address.toLowerCase();
  } catch (e) {
    return false;
  }
};
