import {filterActions} from 'redux-ignore';

export const CREATE_API_KEY = 'CREATE_API_KEY';
export const EDIT_API_KEY = 'EDIT_API_KEY';
export const REVOKE_API_KEY = 'REVOKE_API_KEY';
export const ENABLE_API_KEY = 'ENABLE_API_KEY';
export const DISABLE_API_KEY = 'DISABLE_API_KEY';

const initialState = {
  name: null,
};

function ApiAccessKeyPopupReducer(state = initialState, action) {
  switch (action.type) {
    case DISABLE_API_KEY:
    case ENABLE_API_KEY:
    case REVOKE_API_KEY:
    case CREATE_API_KEY:
    case EDIT_API_KEY: {
      return {name: action.name, data: action.data};
    }

    default:
      return state;
  }
}

export default filterActions(ApiAccessKeyPopupReducer, [
  CREATE_API_KEY,
  EDIT_API_KEY,
  REVOKE_API_KEY,
  DISABLE_API_KEY,
  ENABLE_API_KEY,
]);
