import storageHelper from 'storage-helper/src';
import React from 'react';
import moment from 'moment';

import App from './App';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {STORAGE_KEY} from './reducers/LngReducer';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import template1 from '../../assets/images/templates/exclamation.png';
import template2 from '../../assets/images/templates/cross.png';
import template3 from '../../assets/images/templates/check_mark.png';
import template4 from '../../assets/images/templates/logo.png';
import newLogo1 from '../../assets/images/templates/newLogo1.png';
import newLogo2 from '../../assets/images/templates/newLogo2.png';
import newLogo3 from '../../assets/images/templates/newLogo3.png';
import newLogo4 from '../../assets/images/templates/newLogo4.png';
import 'rc-pagination/assets/index.css';

const LNG = storageHelper.getItem(STORAGE_KEY, true) || 'en';
moment.locale(LNG);

ReactDOM.render(<App />, document.getElementById('root'));
