import React, {Component} from 'react';

import CurrencySelect from './CurrencySelect';

const validator = new RegExp(`(^\\d{0,9}$)|(^\\d*\\.\\d{0,2}$)`);

class ExchangeInput extends Component {
  handleChange = ev => {
    const {onChange, selectedOption} = this.props;
    const {value, name: inputName} = ev.target;
    const canSetNewValue = validator.test(value) || !value.length;

    if (canSetNewValue && onChange) {
      const changeValue = (selectedOption && selectedOption.isFiat && value.replace('.', '')) || value;
      onChange(inputName, changeValue);
    }

    if (this.props.isError && selectedOption && value > selectedOption.min && value < selectedOption.max) {
      this.props.changeIsError(false);
    }
  };

  render() {
    return (
      <div className='exchange-input-block'>
        <span className='exchange-input-block__title'>{this.props.label}</span>

        <div
          className={`exchange-input-block__wrapper ${
            this.props.isError &&
            this.props.selectedOption &&
            (this.props.selectedOption.min || this.props.selectedOption.max)
              ? 'input-error'
              : ''
          }`}
        >
          <label>
            <input
              value={this.props.value}
              onChange={this.handleChange}
              placeholder='Amount'
              onBlur={this.props.onBlur}
              disabled={this.props.isReadOnly}
            />
          </label>
          <div className='exchange-input-block__select'>
            <CurrencySelect
              onChange={this.props.onSelectChange}
              value={this.props.selectedOption}
              options={this.props.selectOptions || []}
              onBlur={this.props.onBlur}
              isReadOnly={this.props.isReadOnly}
            />
          </div>
        </div>
        {this.props.selectedOption && (this.props.selectedOption.max || this.props.selectedOption.min) && (
          <p>
            <span>{this.props.selectedOption.min && `min ${this.props.selectedOption.min},`}</span>
            <span> {this.props.selectedOption.max && `max ${this.props.selectedOption.max}`}</span>
          </p>
        )}
      </div>
    );
  }
}

export default ExchangeInput;
