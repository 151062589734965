import React from 'react';
import {Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis} from 'recharts';

import {CustomizedBarLabel, CustomizedChartDot, CustomShape, CustomTooltip, CustomAxisLabel} from './components';

class CurrencyBarChart extends React.Component {
  render() {
    return (
      <div>
        <ResponsiveContainer height={300} width='100%'>
          <BarChart margin={{top: 27, right: 5, bottom: 5, left: 5}} data={this.props.data}>
            <XAxis
              interval={0}
              tickLine={false}
              tickMargin={15}
              tick={<CustomAxisLabel />}
              axisLine={false}
              dataKey='convertedBalanceLabel'
            />
            <Tooltip wrapperStyle={{outline: 'none'}} cursor={{fill: 'transparent'}} content={<CustomTooltip />} />
            <Bar
              shape={<CustomShape />}
              label={<CustomizedBarLabel />}
              dataKey='amountFromTotal'
              isAnimationActive={false}
              minPointSize={30}
              barSize={30}
            >
              <LabelList
                dataKey='currency'
                content={props => <CustomizedChartDot {...props} value={String(props.value)} />}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default CurrencyBarChart;
