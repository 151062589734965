import React, {Component} from 'react';
import I18n from './I18n';

export class BlockPreloader extends Component {
  render() {
    return (
      <div className='text-center'>
        <I18n tKey='common.loading' />
      </div>
    );
  }
}
export default BlockPreloader;
