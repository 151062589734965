import React from 'react';
import {connect} from 'react-redux';

import * as TradingView from '../../../../../../assets/charting_library-new/charting_library/charting_library.esm.js';
import Timezone from '../../../../utils/timezone';
import {DataFeed} from './services';

class TradingViewWidget extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  widgetCustomConfig = {
    library_path: '/charting_library-new/charting_library/',
    custom_css_url: '/charting_library-new/trvThemed.css',
    chart_storage_api_version: '1.1',
    fullscreen: false,
    autosize: true,
    timezone: new Timezone().userTimezone(),
    // debug: true,
    time_frames: [
      {text: '1D', resolution: '60', description: '1 Day'},
      {text: '3D', resolution: '180', description: '3 Days'},
    ],
    enabled_features: ['keep_left_toolbar_visible_on_small_screens', 'use_localstorage_for_settings'],
    disabled_features: ['property_pages', 'header_compare', 'header_symbol_search', 'volume_force_overlay'],
  };

  componentDidMount() {
    const market = this.props.market;
    const {darkTheme} = this.props.ThemeReducer;

    if (market.identificatSymbol) {
      this.setupChart(market, darkTheme);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.market !== null && nextProps.market.identificatSymbol !== this.props.market.identificatSymbol) {
      this.widget.activeChart().setSymbol(String(nextProps.market.identificatSymbol));
    } else if (this.props.ThemeReducer.darkTheme !== nextProps.ThemeReducer.darkTheme && this.widget) {
      const theme = nextProps.ThemeReducer.darkTheme ? 'Dark' : 'Light';

      this.widget.changeTheme(theme).then(() => {
        nextProps.ThemeReducer.darkTheme &&
          this.widget.applyOverrides({
            'paneProperties.background': '#222327',
            'paneProperties.backgroundType': 'solid',
          });
      });
    }
  }

  setupChart = (market, isDarkTheme) => {
    this.widget = new TradingView.widget({
      symbol: String(market.identificatSymbol),
      container: this.containerRef.current,
      datafeed: new DataFeed(this.props.MarketListReducer.list),
      interval: localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution') || '30',
      locale: 'en',
      theme: isDarkTheme ? 'Dark' : 'Light',
      ...this.widgetCustomConfig,
    });

    this.widget.onChartReady(() => {
      isDarkTheme &&
        this.widget.applyOverrides({'paneProperties.background': '#222327', 'paneProperties.backgroundType': 'solid'});
    });
  };

  render() {
    return <div ref={this.containerRef} />;
  }
}

const mapStateToProps = ({ThemeReducer, MarketListReducer}) => {
  return {ThemeReducer, MarketListReducer};
};

export default connect(mapStateToProps, undefined)(TradingViewWidget);
