import React, {Component, Fragment} from 'react';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import {ReactComponent as SearchIc} from '../../../../assets/images/icon/search-n.svg';
import Formatter from '../../utils/formatter';
import {ReactComponent as ChildMark} from '../../../../assets/images/icon/childmark-small-n.svg';
import I18n from '../../cmp/helpers/I18n';
import {CurrencyView} from '../../cmp/helpers/CurrencyView';
import {CurrencyIcon} from '../../cmp/helpers/CurrencyIcon';
import {FIAT_PROVIDER, getFiatWithdrawFee} from '../../utils/utils';

// const getDepositFee = (currency) => {
//   switch (currency) {
//     case 'USD':
//     case 'EUR':
//       return '1.5% GUPay, 7% Quantum';
//     default:
//       return '0';
//   }
// }

// const getWithdrawalFee = (currency) => {
//   switch (currency) {
//     case 'CRU':
//     case 'USDU':
//     case 'EOS':
//       return '1';
//     case 'UNTB':
//       return '10';
//     case 'ETH':
//       return '0.01';
//     case 'BTC':
//       return '0.0005';
//     case 'USD':
//     case 'EUR':
//       return '3.5% GUPay';
//     default:
//       return '';
//   }
// }

export class WithdrawDeposits extends Component {
  state = {
    paymentFilter: '',
  };
  onChange = ev => {
    this.setState({[ev.target.name]: ev.target.value});
  };
  compareCurrencyTitle = title => {
    const {paymentFilter} = this.state;
    if (!paymentFilter) {
      return true;
    } else {
      const filter = paymentFilter.toLowerCase();
      let curTitle = title.toLowerCase();
      return curTitle.includes(filter);
    }
  };

  getWithdrawFee = (feeAmount, elem) => {
    if (elem.currentT === 'FIAT') {
      return getFiatWithdrawFee(feeAmount, elem.mUCryptogram, FIAT_PROVIDER.GTI);
    }
    let feeValue = feeAmount.find(el => el.currencyCode === elem.mUCryptogram).withdrawFee;

    return !!feeValue && feeValue != 0 ? feeValue.replace(/\.0+$/, '').replace(/\.(\d+?)0+$/, '.$1') : feeValue;
  };

  getTransactionLimit = (elem, maxAmount) => {
    const {cryptoPrices} = this.props;
    const cryptoList = cryptoPrices.data.respItemCollection || [];
    const price =
      cryptoList.find(el => el.leftMonetaryUnitCode === elem.mUCryptogram.toLowerCase()) &&
      cryptoList.find(el => el.leftMonetaryUnitCode === elem.mUCryptogram.toLowerCase()).transformed;
    const formattedNumber = Formatter.cleanNumber(
      parseFloat(price ? maxAmount / price : maxAmount / 1),
      null,
      elem.mUCryptogram
    );

    return formattedNumber + ' ' + elem.mUCryptogram;
  };

  render() {
    const {fees, isDeposit, wsWallet, account, wallets, tradingParams} = this.props,
      crossCourses = wsWallet.crossCourses || {},
      destinationCurrency = 'USDU';
    let paymentPlanAccount = [];
    let isFreeTransactions = false;

    if (fees) {
      paymentPlanAccount = fees.filter(elem => {
        return elem.ppBanner === account.payScheme.ppBanner;
      });
      if (paymentPlanAccount.length && paymentPlanAccount[0].feelessTransactions) {
        isFreeTransactions = true;
      }
    }
    const {paymentFilter} = this.state;
    const feeAmount = tradingParams.data && tradingParams.data.actionsFees;

    return (
      <div className='block-account'>
        <table className='table-limit'>
          <tbody>
            <tr>
              <th className='cell-cur'>
                <I18n tKey='common.currency' />
                <div className='table-limit-search'>
                  <div className='box-control'>
                    <input
                      onChange={this.onChange}
                      name='paymentFilter'
                      value={paymentFilter}
                      type='text'
                      placeholder=''
                      className='form-control '
                    />
                    <SvgWrapper themeWatch className='icon icon-search ic-size-02' SvgCmp={SearchIc} />
                  </div>
                </div>
              </th>
              <th>
                <I18n tKey='common.commission' />
              </th>
              <th>
                <I18n tKey='common.trx_limit' />
              </th>
              <th>
                <I18n tKey='common.daily_limit' />
              </th>
              <th>
                <I18n tKey='common.monthly_limit' />
              </th>
              {/* <th><I18n tKey="common.min"/></th>
          <th><I18n tKey="common.max"/></th> */}
            </tr>

            {wallets.map((elem, i) => {
              let cCurs;
              try {
                cCurs = crossCourses[destinationCurrency][elem.mUCryptogram];
              } catch (e) {
                cCurs = 1;
              }
              const isValidToFilter = this.compareCurrencyTitle(elem.mUCryptogram);
              if (!isValidToFilter) {
                return null;
              }

              return (
                <Fragment key={i}>
                  <tr className='open-next open'></tr>
                  {paymentPlanAccount.map(elements => {
                    return (
                      <tr className='next-info' key={elements.ppBanner}>
                        <td className='cell-cur'>
                          <CurrencyIcon curName={elem.mUCryptogram} />
                        </td>
                        {/* <td>{isDeposit ? getDepositFee(elem.mUCryptogram) : getWithdrawalFee(elem.mUCryptogram)} {!isDeposit && elem.mUCryptogram !== "USD" && elem.mUCryptogram !== "EUR" }</td> */}
                        {isFreeTransactions ? (
                          <td>0</td>
                        ) : (
                          <td>
                            {isDeposit
                              ? feeAmount.find(el => el.currencyCode === elem.mUCryptogram).depositFee
                              : this.getWithdrawFee(feeAmount, elem)}
                          </td>
                        )}

                        <td>
                          <I18n tKey='fees_and_limits:no_limit' />
                        </td>
                        <td>
                          {isDeposit ? (
                            <I18n tKey='fees_and_limits:no_limit' />
                          ) : (
                            <div>
                              <I18n tKey='fees_and_limits:up_to' />{' '}
                              <CurrencyView
                                converterCourse={cCurs}
                                type={elem.mUCryptogram}
                                amount={isDeposit ? elements.dpstEDayTop : elements.eDayWithdrawTop}
                              />
                            </div>
                          )}
                        </td>
                        <td>
                          {isDeposit ? (
                            <I18n tKey='fees_and_limits:no_limit' />
                          ) : (
                            <div>
                              <CurrencyView
                                converterCourse={cCurs}
                                type={elem.mUCryptogram}
                                amount={isDeposit ? elements.dpstEMonthTop : elements.eMonthWithdrawTop}
                              />
                            </div>
                          )}
                        </td>
                        {/* <td><CurrencyView converterCourse={cCurs} type={elem.mUCryptogram} amount={isDeposit ? "20.00" : elements.leastTransactionWithdraw}/> </td>
                  <td><CurrencyView converterCourse={cCurs} type={elem.mUCryptogram} amount={isDeposit ? elements.dpstTxMax : elements.topTransactionWithdraw}/> </td> */}
                      </tr>
                    );
                  })}
                </Fragment>
              );
            })}
            {/*<tr className="open-next open">*/}
            {/*  <td colSpan="7">*/}
            {/*    <SvgWrapper themeWatch className="icon icon-arrow-toggle ic-size-02" SvgCmp={TriangleIc}/>*/}
            {/*    <SvgWrapper className="icon icon-payment ic-size-18" SvgCmp={MasterCardIc}/>Master card <span>· 4</span></td>*/}
            {/*</tr>*/}
            {/*<tr className="next-info">*/}
            {/*  <td className="cell-cur">*/}
            {/*    <SvgWrapper themeWatch className="ic-size-02 icon-cons" SvgCmp={ChildMark}/>United State Dollar (USD)*/}
            {/*    <div className="hover-info"><span className="green">For verified user:</span></div>*/}
            {/*  </td>*/}
            {/*  <td>2.99%*/}
            {/*    <div className="hover-info">0%</div>*/}
            {/*  </td>*/}
            {/*  <td>$ 3,000.00*/}
            {/*    <div className="hover-info">$ 15,000.00</div>*/}
            {/*  </td>*/}
            {/*  <td>up to $ 1,000.00*/}
            {/*    <div className="hover-info">up to $ 2,000.00</div>*/}
            {/*  </td>*/}
            {/*  <td>$ 3,000.00*/}
            {/*    <div className="hover-info">$ 15,000.00</div>*/}
            {/*  </td>*/}
            {/*  <td>$ 20.00*/}
            {/*    <div className="hover-info">$ 20.00</div>*/}
            {/*  </td>*/}
            {/*  <td>$ 1,000.00*/}
            {/*    <div className="hover-info">$ 10,000.00</div>*/}
            {/*  </td>*/}
            {/*</tr>*/}
            {/*<tr className="next-info">*/}
            {/*  <td className="cell-cur">*/}
            {/*    <SvgWrapper themeWatch className="ic-size-02 icon-cons" SvgCmp={ChildMark}/>Russian ruble (RUB)*/}
            {/*    <div className="hover-info"><span className="green">For verified user:</span></div>*/}
            {/*  </td>*/}
            {/*  <td>1.99% + 3$*/}
            {/*    <div className="hover-info">0%</div>*/}
            {/*  </td>*/}
            {/*  <td>$ 3,000.00*/}
            {/*    <div className="hover-info">$ 15,000.00</div>*/}
            {/*  </td>*/}
            {/*  <td>up to $ 1,000.00*/}
            {/*    <div className="hover-info">up to $ 2,000.00</div>*/}
            {/*  </td>*/}
            {/*  <td>$ 3,000.00*/}
            {/*    <div className="hover-info">$ 15,000.00</div>*/}
            {/*  </td>*/}
            {/*  <td>$ 20.00*/}
            {/*    <div className="hover-info">$ 20.00</div>*/}
            {/*  </td>*/}
            {/*  <td>$ 1,000.00*/}
            {/*    <div className="hover-info">$ 10,000.00</div>*/}
            {/*  </td>*/}
            {/*</tr>*/}

            {/*<tr className="open-next">*/}
            {/*  <td colSpan="7">*/}
            {/*    <SvgWrapper themeWatch className="icon icon-arrow-toggle ic-size-02" SvgCmp={TriangleIc}/>*/}
            {/*    <SvgWrapper className="icon icon-payment ic-size-18" SvgCmp={VisaIc}/>Visa <span>· 6</span></td>*/}
            {/*</tr>*/}

            {/*<tr className="open-next">*/}
            {/*  <td colSpan="7">*/}
            {/*    <SvgWrapper themeWatch className="icon icon-arrow-toggle ic-size-02" SvgCmp={TriangleIc}/>*/}
            {/*    <SvgWrapper className="icon icon-payment ic-size-18" SvgCmp={PayeerIc}/>Payeer <span>· 2</span></td>*/}
            {/*</tr>*/}

            {/*<tr className="open-next">*/}
            {/*  <td colSpan="7">*/}
            {/*    <SvgWrapper themeWatch className="icon icon-arrow-toggle ic-size-02" SvgCmp={TriangleIc}/>*/}
            {/*    <SvgWrapper className="icon icon-payment ic-size-18" SvgCmp={QwIc}/>Qiwi <span>· 5</span></td>*/}
            {/*</tr>*/}

            {/*<tr className="open-next">*/}
            {/*  <td colSpan="7"><SvgWrapper themeWatch className="icon icon-arrow-toggle ic-size-02" SvgCmp={TriangleIc}/>*/}
            {/*    <SvgWrapper className="icon icon-payment ic-size-18" SvgCmp={CryptoIc}/>Crypto <span>· 200</span></td>*/}
            {/*</tr>*/}
            {/*<tr className="next-info">*/}
            {/*  <td className="cell-cur">*/}
            {/*    <SvgWrapper themeWatch className="ic-size-02 icon-cons" SvgCmp={ChildMark}/>Bitcoin (BTC)*/}
            {/*  </td>*/}
            {/*  <td>no fee</td>*/}
            {/*  <td>unlimited</td>*/}
            {/*  <td>unlimited</td>*/}
            {/*  <td>unlimited</td>*/}
            {/*  <td>0.00010000</td>*/}
            {/*  <td>unlimited</td>*/}
            {/*</tr>*/}
            {/*<tr className="next-info">*/}
            {/*  <td className="cell-cur">*/}
            {/*    <SvgWrapper themeWatch className="ic-size-02 icon-cons" SvgCmp={ChildMark}/>Bitcoin (BTC)*/}
            {/*  </td>*/}
            {/*  <td>no fee</td>*/}
            {/*  <td>unlimited</td>*/}
            {/*  <td>unlimited</td>*/}
            {/*  <td>unlimited</td>*/}
            {/*  <td>0.00010000</td>*/}
            {/*  <td>unlimited</td>*/}
            {/*</tr>*/}
          </tbody>
        </table>
      </div>
    );
  }
}
