// @flow

import * as React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as Actions from '../../api/Actions';
import {AcceptTosVersion} from '../../api/Actions';
import I18n from '../helpers/I18n';

// Once daemon storing user's last accepted ToS version is down - backend will return this string.
// There is no reason to show this popup in this case as the accepted version won't be persisted in any case.
const UNKNOWN_VERSION = 'UNKNOWN';

class VersionedTosPopup extends React.PureComponent {
  state = {
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    checked5: false,
    checked6: false,
  };

  confirm() {
    const {ApplicationSettingsReducer} = this.props;
    const {currentTosVersion} = ApplicationSettingsReducer.data;

    const request = {version: currentTosVersion};

    this.props.acceptTosVersion(request).then(() => {
      this.props.getUserSettings();
    });
  }

  renderPopup() {
    const {checked1, checked2, checked3, checked4, checked5} = this.state;
    const isBtnEnabled = checked1 && checked2 && checked3 && checked4 && checked5;

    return (
      <div className='modal fade in tos' tabIndex='-1' role='dialog'>
        <div className='content d-flex flex-column'>
          <h1>
            <I18n tKey='tos.title' />
          </h1>
          <div className='tos'>
            <label className='form-checkbox'>
              <input
                onChange={() => this.setState({checked1: !this.state.checked1})}
                type='checkbox'
                checked={checked1}
              />
              <span className='checkmark' />
              <I18n tKey='tos.tos1_1' />
            </label>

            <label className='form-checkbox'>
              <input
                onChange={() => this.setState({checked2: !this.state.checked2})}
                type='checkbox'
                checked={checked2}
              />
              <span className='checkmark' />
              <I18n tKey='tos.tos1_2' />
            </label>

            <label className='form-checkbox'>
              <input
                onChange={() => this.setState({checked3: !this.state.checked3})}
                type='checkbox'
                checked={checked3}
              />
              <span className='checkmark' />
              <I18n tKey='tos.tos1_3' />
            </label>

            <label className='form-checkbox'>
              <input
                onChange={() => this.setState({checked4: !this.state.checked4})}
                type='checkbox'
                checked={checked4}
              />
              <span className='checkmark' />
              <I18n tKey='tos.agree_to' />
              &nbsp;
              <Link to='/page/information/user-agreement' target='_blank'>
                <I18n tKey='common.terms' />
              </Link>
              ,&nbsp;
              <Link to='/page/privacy-policy/terms-and-definitions' target='_blank'>
                <I18n tKey='common.privacy_policy' />
              </Link>
            </label>

            <label className='form-checkbox'>
              <input
                onChange={() => this.setState({checked5: !this.state.checked5})}
                type='checkbox'
                checked={checked5}
              />
              <span className='checkmark' />
              <I18n tKey='tos.tos1_5' />
            </label>
          </div>

          <div className='box-btn'>
            <button className='btn btn-purple' disabled={!isBtnEnabled} onClick={() => this.confirm()}>
              <I18n tKey='tos.button' />
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {UserSettingsReducer, ApplicationSettingsReducer} = this.props;
    if (!UserSettingsReducer.completed || !ApplicationSettingsReducer.completed) {
      return null;
    }

    const {lastAcceptedTosVersion} = UserSettingsReducer.data;
    const {currentTosVersion} = ApplicationSettingsReducer.data;
    if (
      lastAcceptedTosVersion !== UNKNOWN_VERSION &&
      !!currentTosVersion &&
      lastAcceptedTosVersion != currentTosVersion
    ) {
      return this.renderPopup();
    }
    return null;
  }
}

const mapStateToProps = ({UserSettingsReducer, ApplicationSettingsReducer}) => ({
  UserSettingsReducer,
  ApplicationSettingsReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getUserSettings: Actions.UserSettings.action,
        acceptTosVersion: Actions.AcceptTosVersion.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(VersionedTosPopup));

export default connectedWithRouter;
