import {ReactComponent as TronSVG} from '../../../../../assets/images/blockchains/tron.svg';
import {ReactComponent as EtherSVG} from '../../../../../assets/images/blockchains/etherscan.svg';
import {ReactComponent as BinanceSVG} from '../../../../../assets/images/blockchains/binance.svg';
import {ReactComponent as SolSVG} from '../../../../../assets/images/blockchains/sol.svg';
import {ReactComponent as DotSVG} from '../../../../../assets/images/balances/DOT.svg';
import {ReactComponent as BtcSVG} from '../../../../../assets/images/balances/BTC.svg';
import {ReactComponent as DogeSVG} from '../../../../../assets/images/balances/DOGE.svg';

export const blockchainIcons = {
  TRON: <TronSVG />,
  ETHEREUM: <EtherSVG />,
  POLKADOT: <DotSVG />,
  BITCOIN: <BtcSVG />,
  BINANCE: <BinanceSVG />,
  SOLANA: <SolSVG />,
  'DogeCoin (Doge)': <DogeSVG />,
};

export const itezCoinsAvailable = ['BTC', 'ETH', 'USDT', 'USDC', 'MATIC', 'TRX'];

export const SELECT_THEME = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    text: 'black',
    primary25: '#F4F5F7',
    primary: '#F4F5F7',
  },
});

export const CUSTOM_SELECT_STYLES = {
  control: (base, state) => ({
    ...base,
    border: '0',
    borderBottom: state.isFocused ? '1px solid var(--brdr_clr_10)!important' : '1px solid var(--brdr_clr_08)',
    background: 'var(--bg_clr_11)',
    margin: 0,
    height: 41,
    width: '100%',
    minWidth: '250px',
    maxWidth: '250px',
    boxShadow: 'none',
    '&:hover': {
      borderBottomColor: 'var(--brdr_clr_01);',
    },
  }),
  menu: base => ({
    ...base,
    border: 'none',
    zIndex: 100,
  }),
  menuList: base => ({
    ...base,

    background: 'var(--bg_clr_11)',
    border: 'none',
  }),
  valueContainer: base => ({
    ...base,
    paddingRight: 0,
    paddingLeft: 0,
  }),
  input: base => ({
    ...base,
    color: 'var(--color_text_01)',
  }),
  singleValue: base => ({
    ...base,
    color: 'var(--color_text_01)',
  }),
  placeholder: base => ({
    ...base,
    color: 'var(--color_text_02)',
    fontSize: '16px',
  }),
  option: (base, {isSelected, isFocused}) => ({
    ...base,
    color: 'var(--color_text_02)',
    ...(isSelected && {backgroundColor: 'var(--brdr_clr_05)!important'}),
    ...(isFocused && {backgroundColor: 'var(--brdr_clr_05)'}),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'var(--brdr_clr_05)',
    },
  }),
};
