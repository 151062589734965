import * as React from 'react';
import {Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {bindActionCreators} from 'redux';
import * as Actions from '../api/Actions';
import {Link} from 'react-router-dom';
import copy from 'copy-to-clipboard';
import type {UserActivityTO, WalletTO} from '../api/types/models';
import {OPEN_POPUP} from '../reducers/PopupReducer';
import {ENABLE_2FA_STEP1_POPUP} from '../cmp/popups/top/Enable2FAStep1Popup';
import {DISABLE_2FA_STEP1_POPUP} from '../cmp/popups/top/Disable2FAStep1Popup';
import {ReactComponent as ShieldIc} from '../../../assets/images/icon/shield.svg';
import Formatter from '../utils/formatter';
import {SvgWrapper} from '../cmp/helpers/SvgWrapper';
import I18n from '../cmp/helpers/I18n';
import {CurrencyView} from '../cmp/helpers/CurrencyView';
import BlockPreloader from '../cmp/helpers/BlockPreloader';
import {URLMAP} from '../utils/const';
import DashboardAccountInfo from './DashboardAccountInfo';

class DashboardPage extends React.PureComponent {
  componentDidMount() {
    this.props.getActivityLog();
    this.props.getWithdrawUsage();
    this.props.getAccountList();
    if (!Object.keys(this.props.UserTradeFeeReducer.data).length) {
      this.props.UserTradeFeeUsing();
    }
  }

  componentWillUpdate() {
    //do not remove, it needs for goldenlayout
  }

  copyToClipboard(addr) {
    copy(addr);
  }

  calculateTotal(destinationCurrency, WsWalletReducer, crossCourses) {
    return Object.keys(WsWalletReducer.byCurrencyMap || {}).reduce((s, cc) => {
      const wallet: WalletTO = WsWalletReducer.byCurrencyMap[cc];
      if (
        wallet &&
        crossCourses &&
        crossCourses[destinationCurrency] &&
        crossCourses[destinationCurrency][wallet.monetaryUnitCode]
      ) {
        return s + (wallet.aValue + wallet.resValue) / crossCourses[destinationCurrency][wallet.monetaryUnitCode];
      } else {
        return s + wallet.aValue + wallet.resValue;
      }
    }, 0);
  }

  render() {
    const {WithdrawUsageReducer, AccountListReducer, UserTradeFeeReducer} = this.props;
    const user = this.props.SessionReducer.masterPersonality || {};
    const account = (AccountListReducer.list || [])[0];
    if (!account) {
      return (
        <div className='text-center full-height content-align-center'>
          <I18n tKey='profile.empty_account_list' />
        </div>
      );
    }
    const {UserActivityHistoryReducer, WsWalletReducer} = this.props;
    const lastActivity: UserActivityTO = (UserActivityHistoryReducer.list || [])[0] || {};
    const monetaryUnitCode = WithdrawUsageReducer.data.leftMonetaryUnitCode
      ? WithdrawUsageReducer.data.leftMonetaryUnitCode
      : '$';
    const dailyWithdraw = WithdrawUsageReducer.data.hasOwnProperty('eDay') ? WithdrawUsageReducer.data.eDay : null;
    const monthlyWithdraw = WithdrawUsageReducer.data.hasOwnProperty('eMonth')
      ? WithdrawUsageReducer.data.eMonth
      : null;
    const dailyWithdrawMax = AccountListReducer.list && AccountListReducer.list[0].payScheme.eDayWithdrawTop;
    const monthlyWithdrawMax = AccountListReducer.list && AccountListReducer.list[0].payScheme.eMonthWithdrawTop;
    const crossCourses = WsWalletReducer.crossCourses || {};
    const destinationCurrencyUSD = 'USD';
    const destinationCurrencyBTC = 'BTC';
    const totalInUSD = this.calculateTotal(destinationCurrencyUSD, WsWalletReducer, crossCourses);
    const totalInBTC = this.calculateTotal(destinationCurrencyBTC, WsWalletReducer, crossCourses);

    if (UserTradeFeeReducer.fetching) {
      return <BlockPreloader />;
    }
    const tradingVolumeUSD = UserTradeFeeReducer.data.tradeVolumeLastMonthUSD;
    const tradingVolumeMax = AccountListReducer.list[0].paySetQty.cubicMeasureQty;
    return (
      <Fragment>
        <div className='container-center'>
          <div className='tab-pane active'>
            <div className='tab-pane-header'>
              <div className='tab-pane-name'>
                <I18n tKey='profile.account_overview' />
              </div>
            </div>
            {lastActivity && (
              <div className='tab-pane-info'>
                <div className='tab-pane-info-text'>
                  <I18n tKey='profile.last_login' />: IP {lastActivity.intProtAddr}, <I18n tKey='common.location' />:{' '}
                  {lastActivity.motherland || <I18n tKey='common.is_undefined' />},&nbsp;
                  <I18n tKey='common.date' />: {Formatter.dateTimeShort(lastActivity.time)}{' '}
                  <Link to={URLMAP.PROFILE + '/ActivityLog'}>
                    <I18n tKey='profile.activity_log' />
                  </Link>
                </div>
              </div>
            )}
            <div className='tab-pane-body'>
              <div className='block-account'>
                <div className='block-account-item'>
                  <div className='block-account-item_control'>
                    <div className='box-control'>
                      <label className='label'>
                        <I18n tKey='common.email' />
                      </label>
                      <input
                        readOnly={true}
                        type='text'
                        defaultValue={user.electronicMailVal}
                        className='form-control '
                      />
                      {/*<a>CHANGE</a>*/}
                    </div>

                    <div className='block-account-item_group'>
                      <div className='title'>
                        <I18n tKey='profile.total_account_val' />
                      </div>
                      <div className='value'>
                        <CurrencyView isFiat={true} amount={totalInUSD} /> {destinationCurrencyUSD}
                      </div>
                      <div className='link'>
                        <Link to={URLMAP.BALANCES}>
                          <I18n tKey='common.balances' />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='block-account-item_status'>
                    <div className='block-account-item_status-icon'>
                      <SvgWrapper className='icon icon-shield ic-size-15' SvgCmp={ShieldIc} />
                      <span>{!user.googleAuthAllowanceFlag ? '20' : '100'}%</span>
                    </div>
                    <div className='block-account-item_status-info'>
                      <div className='text'>
                        <I18n tKey='common.security_status' />
                      </div>
                      <div className='name'>
                        {!user.googleAuthAllowanceFlag ? <I18n tKey='profile.basic' /> : <I18n tKey='profile.full' />}{' '}
                        <I18n tKey='account_overview:protection' />
                      </div>

                      {!user.googleAuthAllowanceFlag && (
                        <div className='active' onClick={this.props.startEnable2FA} style={{cursor: 'pointer'}}>
                          <I18n tKey='account_overview:activate_2fa' />
                        </div>
                      )}
                      {user.googleAuthAllowanceFlag && (
                        <div onClick={this.props.startDisable2FA} style={{cursor: 'pointer'}} className=''>
                          <I18n tKey='dashboardpage:deactiv_2fa' />
                        </div>
                      )}

                      <div className='verification'>
                        <div>
                          <I18n tKey='account_overview:verification_status' />
                        </div>
                        <Link
                          to={URLMAP.PROFILE + '/Verifications'}
                          className={`chip ${
                            user.curKYCustS === 'NOT_VERIFIED' || user.curKYCustS === 'UNRECOGNIZED'
                              ? 'red bg_red unverified'
                              : 'green bg_green'
                          }`}
                        >
                          {user.curKYCustS === 'NOT_VERIFIED' || user.curKYCustS === 'UNRECOGNIZED' ? (
                            <I18n tKey='common.unverified' />
                          ) : (
                            <I18n tKey='common.verified' />
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='block-account-name'>
                <I18n tKey='dashboard:current_trade' />
              </div>
              <div className='block-account'>
                <div className='block-account-item'>
                  <div className='block-account-item_trade'>
                    <div className='title'>
                      <I18n tKey='dashboard:trading_volume' />
                    </div>
                    <div className='value'>
                      <CurrencyView isFiat={true} amount={tradingVolumeUSD} /> {destinationCurrencyUSD}/
                      <CurrencyView isFiat={true} amount={tradingVolumeMax} /> {destinationCurrencyUSD}
                    </div>
                    <div className='block-account-item-load green'>
                      <div
                        className='block-account-item-load-value'
                        style={{width: `${100 / (tradingVolumeMax / tradingVolumeUSD)}%`}}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='block-account-name'>
                <I18n tKey='common.current_fee' />
              </div>
              <div className='block-account'>
                <div className='block-account-item'>
                  <div className='block-account-item_form'>
                    <div className='title'>
                      <I18n tKey='common.monthly_limit' />
                    </div>
                    <div className='value'>
                      <CurrencyView type={monetaryUnitCode} amount={monthlyWithdraw !== null ? monthlyWithdraw : ''} />
                      <span>/</span>
                      <CurrencyView
                        type={monetaryUnitCode}
                        amount={monthlyWithdrawMax !== null ? monthlyWithdrawMax : ''}
                      />{' '}
                      {monetaryUnitCode}
                    </div>
                    <div className='block-account-item-load green'>
                      {monthlyWithdraw !== null && monthlyWithdrawMax !== null && (
                        <div
                          className='block-account-item-load-value'
                          style={{width: `${100 / (monthlyWithdrawMax / monthlyWithdraw)}%`}}
                        />
                      )}
                    </div>
                  </div>
                  <div className='block-account-item_form'>
                    <div className='title'>
                      <I18n tKey='common.daily_limit' />
                    </div>
                    <div className='value'>
                      <CurrencyView type={monetaryUnitCode} amount={dailyWithdraw !== null ? dailyWithdraw : ''} />
                      <span> / </span>
                      <CurrencyView
                        type={monetaryUnitCode}
                        amount={dailyWithdrawMax !== null ? dailyWithdrawMax : ''}
                      />{' '}
                      {monetaryUnitCode}
                    </div>
                    <div className='block-account-item-load green'>
                      {dailyWithdraw !== null && dailyWithdrawMax !== null && (
                        <div
                          className='block-account-item-load-value'
                          style={{width: `${100 / (dailyWithdrawMax / dailyWithdraw)}%`}}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/*<div className="block-account-name">Affiliate Programm</div>*/}
              {/*<div className="block-account">*/}
              {/*<div className="block-account-item">*/}
              {/*<div className="block-account-item_affiliate">*/}
              {/*<div className="box-control">*/}
              {/*<label className="label">Total earned</label>*/}
              {/*<input type="text" defaultValue="0.45776879 BTC" className="form-control "/>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*<div className="block-account-item_affiliate">*/}
              {/*<div className="box-control">*/}
              {/*<label className="label">Amount of referrals</label>*/}
              {/*<input type="text" defaultValue="32 people" className="form-control "/>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*<div className="block-account-item_affiliate">*/}
              {/*<div className="box-control">*/}
              {/*<label className="label">My Referal ID</label>*/}
              {/*<input type="text" defaultValue="19733673" className="form-control "/>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*<div className="block-account-item_affiliate">*/}
              {/*<div className="box-control">*/}
              {/*<label className="label">Your Refferal Link</label>*/}
              {/*<input type="text" defaultValue="https://domain.com/swg98457892475irhgijkldehswg" className="form-control "/>*/}
              {/*<a onClick={this.copyToClipboard.bind(this)}>COPY</a>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}

              {/*<div className="block-account">*/}
              {/*<div className="block-account-item">*/}
              {/*<div className="block-account-item_soon">*/}
              {/*<i className="icon icon-soon"/>*/}
              {/*<span>Coming soon</span>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>

            {/*<DashboardAccountInfo />*/}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  WsWalletReducer,
  SessionReducer,
  UserActivityHistoryReducer,
  WithdrawUsageReducer,
  AccountListReducer,
  UserTradeFeeReducer,
}) => ({
  WsWalletReducer,
  SessionReducer,
  UserActivityHistoryReducer,
  WithdrawUsageReducer,
  AccountListReducer,
  UserTradeFeeReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    startEnable2FA: () => dispatch({type: OPEN_POPUP, name: ENABLE_2FA_STEP1_POPUP}),
    startDisable2FA: () => dispatch({type: OPEN_POPUP, name: DISABLE_2FA_STEP1_POPUP}),
    ...bindActionCreators(
      {
        getSettings: Actions.UserSettings.action,
        getActivityLog: Actions.UserActivityHistory.action,
        getWithdrawUsage: Actions.WithdrawUsage.action,
        getAccountList: Actions.AccountList.action,
        UserTradeFeeUsing: Actions.UserTradeFeeUsing.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));

export default connectedWithRouter;
