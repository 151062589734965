import React from 'react';
import {fetcher, getUserToken} from '../../../api/Api';
import {parseError} from '../../../utils/i18n';

export const downloadRequest = async (url, fileName) => {
  let response = null;
  try {
    response = await fetcher.request({
      url: url,
      responseType: 'arraybuffer',
      method: 'GET',
      headers: {
        'x-token': getUserToken(),
      },
    });
    const blob = new Blob([response.data], {type: 'application/octet-stream'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.xls`;
    link.click();
  } catch (error) {
    const {response} = await error;
    console.warn(url, response, error);
    if (response && response.data) {
      response.data.parsedError = parseError(response.data);
    }
    return Promise.resolve({isError: true, response});
  }
  return response;
};
