import React from 'react';

import I18n from '../../../../cmp/helpers/I18n';
import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import {ReactComponent as TriangleIc} from '../../../../../../assets/images/icon/dropdown-small-n.svg';

const StopOrdersListHeader = () => (
  <thead className='table-heading'>
    <tr>
      <th>
        <I18n tKey='common.market' />
      </th>
      <th>
        <I18n tKey='common.type' />
      </th>
      <th>
        <I18n tKey='common.open_time' />
      </th>
      <th>
        <I18n tKey='common.amount' />
      </th>
      <th>
        <I18n tKey='common.price' />
      </th>
      <th>
        <I18n tKey='common.total' />
      </th>
      <th>
        <I18n tKey='common.order_stop' />
      </th>
      <th className='action'>
        <div className='dropdown'>
          <div className='dropdown_btn'>
            <I18n tKey='common.close' />{' '}
            <SvgWrapper themeWatch className='icon icon-arrow-toggle ic-size-02' SvgCmp={TriangleIc} />
          </div>
        </div>
      </th>
    </tr>
  </thead>
);

export default StopOrdersListHeader;
