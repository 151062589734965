import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import PageNotFound_d from '../../../assets/images/ui/lost_page_day.png';
import PageNotFound_n from '../../../assets/images/ui/lost_page_night.png';

import I18n from '../cmp/helpers/I18n';
import {URLMAP} from '../utils/const';

class NotFound extends Component {
  render() {
    const {darkTheme} = this.props.ThemeReducer;
    return (
      <div className='not-found-page'>
        <div className='not-found-content'>
          <div className='not-found-img'>
            <img src={darkTheme ? PageNotFound_n : PageNotFound_d} />
          </div>
          <p>
            <I18n tKey='common.page_not_found' />
          </p>
          <Link to={URLMAP.TERMINAL} className='btn btn-primary'>
            <I18n tKey='common.back_to_home' />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ThemeReducer}) => ({ThemeReducer});

export default connect(mapStateToProps, {})(NotFound);
