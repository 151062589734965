import React, {Component} from 'react';
import numeral from 'numeral';

class MarketVolume extends Component {
  render() {
    const {usdVolume} = this.props;

    return <span>{numeral(usdVolume).format('0.00a')}</span>;
  }
}

export default MarketVolume;
