import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Dialog from '../Dialog';

class SuccessDialog extends PureComponent {
  static propTypes = {
    darkTheme: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    autoClose: PropTypes.shape({
      active: PropTypes.bool.isRequired,
      time: PropTypes.number,
    }),
  };

  constructor(props) {
    super(props);

    this.timer = 0;
  }

  componentDidUpdate(prevProps) {
    const {open, time, onClose} = this.props;

    // if (!prevProps.open && open) {
    //   this.timer = window.setTimeout(() => onClose(), time || 2000);
    // }
  }

  componentWillUnmount() {
    // window.clearTimeout(this.timer);
  }

  handleClose = () => {
    const {onClose} = this.props;

    // window.clearTimeout(this.timer);
    onClose();
  };

  render() {
    const {darkTheme, open, children} = this.props;

    return (
      <Dialog open={open} onClose={this.handleClose} height={205}>
        <div className={`success-dialog ${darkTheme ? 'dialog-success__dark' : ''}`}>
          <svg
            className={`success-dialog_checkmark ${darkTheme ? 'checkmark__dark' : ''}`}
            xmlns='http://www.w3.org/2000/svg'
            style={{minHeight: 40}}
            viewBox='0 0 52 52'
          >
            <circle className='success-dialog_checkmark_circle' cx='26' cy='26' r='25' fill='none' />
            <path className='success-dialog_checkmark_check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
          </svg>
          {children}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ThemeReducer}) => ({
  darkTheme: ThemeReducer.darkTheme,
});

export default connect(mapStateToProps)(SuccessDialog);
