import {Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Paging from '../../cmp/Paging';
import type {CurrencyTO, MoneyTransactionTO} from '../../api/types/models';
import * as Actions from '../../api/Actions';
import DropdownFilter from '../../cmp/DropdownFilter';
import DateRangeFilter from '../../cmp/DateRangeFilter';
import Formatter from '../../utils/formatter';
import {translate} from '../../utils/i18n';
import I18n from '../../cmp/helpers/I18n';
import {CurrencyView} from '../../cmp/helpers/CurrencyView';
import {downloadRequest} from './downloadsUserInfo/utils';

const showStatus = (status, dict) => {
  switch (status) {
    case 'PENDING':
      return translate('common.pending', dict);
    case 'CONFIRMED':
      return translate('common.confirmed', dict);
    case 'REJECTED':
      return translate('common.rejected', dict);
    case 'PAID_OUT':
      return translate('common.paid_out', dict);
    case 'CANCELED_BY_USER':
      return translate('common.canceled', dict);
    case 'WAITING_FOR_EMAIL_CONFIRM':
      return translate('common.waiting_confirm', dict);
    case 'SUSPENDED':
      return translate('common.suspended', dict);
    case 'WAITING_FOR_BLOCKCHAIN_CONFIRM':
      return translate('common.waiting_for_blockchain_confirm', dict);
  }
};

class TransactionHistory extends React.PureComponent {
  state = {
    type: null,
    currencyIds: null,
    dateFrom: null,
    dateTo: null,
    limit: null,
    offset: null,
    isDownloading: false,
    isDownloadError: false,
  };

  changePage = (limit, offset) => {
    this.setState({limit, offset}, this.search);
  };

  changeType = type => {
    this.setState({type}, this.search);
  };

  changeCurrency = currencyIds => {
    this.setState({currencyIds, offset: 0}, this.search);
  };

  changeDateRange = ({dateFrom, dateTo}) => {
    this.setState({dateFrom, dateTo, offset: 0}, this.search);
  };

  search = () => {
    const {dateFrom, type, dateTo, limit, offset, currencyIds} = this.state;
    const params = {
      dateFrom,
      dateTo,
      type,
      currencyIds,
    };
    params.pageSize = limit;
    params.pageNumber = Math.ceil(offset / limit) || 0;
    this.props.list(null, params);
  };

  renderCurrencyName(currencyList, currencyType) {
    const curr = currencyList.find(elem => elem.mUCryptogram === currencyType);
    return (curr && curr.mUBanner) || currencyType;
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  renderAdditionalInfo = tx => {
    if ((tx.currentT || '').toLowerCase() === 'referral' || (tx.currentT || '').toLowerCase() === 'dividend') {
      return '-';
    }
    let txUrl = null;

    if (!!tx.payGateType && tx.payGateType !== 'UNKNOWN_PAYGATE' && tx.payGateType !== 'CRYPTO') {
      return <React.Fragment>{tx.payGateType}</React.Fragment>;
    }

    switch (tx.monetaryUnitCode) {
      case 'BTC':
        txUrl = 'https://live.blockcypher.com/btc/tx/' + tx.txIdentifier + '/';
        break;
      case 'ETH':
        txUrl = 'https://etherscan.io/tx/' + tx.txIdentifier; //todo: include erc20 here
        break;
      case 'BCH':
        txUrl = 'https://www.blocktrail.com/tBCC/tx/' + tx.txIdentifier;
        break;
      case 'BTG':
        txUrl = 'https://test-explorer.bitcoingold.org/insight/tx/' + tx.txIdentifier;
        break;
      case 'LTC':
        txUrl = 'https://live.blockcypher.com/ltc/tx/' + tx.txIdentifier + '/';
        break;
      case 'DOGE':
        txUrl = 'https://live.blockcypher.com/doge/tx/' + tx.txIdentifier + '/';
        break;
      case 'DASH':
        txUrl = 'http://insight.test.dash.crowdnode.io/tx/' + tx.txIdentifier;
        break;
      case 'EOS':
        txUrl = 'https://bloks.io/transaction/' + tx.txIdentifier;
        break;
      case 'CRU':
        txUrl = 'https://explorer.cryptounit.cc/transactions/' + tx.txIdentifier;
        break;
      case 'USDU':
        txUrl = 'https://explorer.cryptounit.cc/transactions/' + tx.txIdentifier;
        break;
      case 'USDT':
        txUrl = 'https://etherscan.io/tx/' + tx.txIdentifier;
        break;
      case 'DAI':
        txUrl = 'https://etherscan.io/tx/' + tx.txIdentifier;
        break;
      case 'UNI':
        txUrl = 'https://etherscan.io/tx/' + tx.txIdentifier;
        break;
      case 'USDC':
        txUrl = 'https://etherscan.io/tx/' + tx.txIdentifier;
        break;
      case 'LINK':
        txUrl = 'https://etherscan.io/tx/' + tx.txIdentifier;
        break;
      case 'UNTB':
        txUrl = 'https://explorer.cryptounit.cc/transactions/' + tx.txIdentifier;
        break;
      case 'USD':
        //txUrl = 'https://currencyclearingchain.com/tx/' + tx.txIdentifier;
        break;
      case 'EUR':
        //txUrl = 'https://currencyclearingchain.com/tx/' + tx.txIdentifier;
        break;
      case 'WAX':
        txUrl = 'https://wax.bloks.io/transaction/' + tx.txIdentifier;
        break;
      case 'XRP':
        txUrl = 'https://livenet.xrpl.org/transactions/' + tx.txIdentifier;
        break;
      case 'BNB':
        txUrl = 'https://bscscan.com/tx/' + tx.txIdentifier;
        break;
      case 'TRX':
        txUrl = 'https://tronscan.org/#/transaction/' + tx.txIdentifier;
        break;
      case 'BOBC':
      case 'MATIC':
        txUrl = 'https://etherscan.io/tx/' + tx.txIdentifier;
        break;
    }

    let confirmationsStr, percent;
    if ((tx.currentT || '').toLowerCase() === 'deposit' && tx.confsRequiredQty != 0) {
      confirmationsStr = tx.confsQty + ' of ' + tx.confsRequiredQty + ' confirmations';
      percent = (tx.confsQty / tx.confsRequiredQty) * 100;
    }

    if (tx.confirmationFlag) {
      if (!!txUrl)
        return (
          <a href={txUrl} target='_blank'>
            <I18n tKey='common.trx_details' />
          </a>
        );
      else return null;
    }
    return (
      <React.Fragment>
        {!!txUrl && !!confirmationsStr && (
          <React.Fragment>
            <div className='block-amount pending-deposit-stat'>
              <div className='block-amount-value' style={{width: percent + '%'}} />
            </div>
            <a href={txUrl} target='_blank'>
              {confirmationsStr}
            </a>
          </React.Fragment>
        )}
        {!txUrl && confirmationsStr}
      </React.Fragment>
    );
  };

  search = () => {
    const {dateFrom, type, dateTo, limit, offset, currencyIds} = this.state;
    const params = {
      dateFrom,
      dateTo,
      type,
      currencyIds,
    };
    params.pageSize = limit;
    params.pageNumber = Math.ceil(offset / limit) || 0;
    this.props.list(null, params);
  };

  onDownloadClick = () => {
    const {dateFrom, type, dateTo, currencyIds} = this.state;
    const params = {};
    if (!!dateFrom) {
      params.dateFrom = dateFrom;
    }
    if (!!dateTo) {
      params.dateTo = dateTo;
    }
    if (!!type) {
      params.type = type;
    }
    if (!!currencyIds) {
      params.currencyIds = currencyIds;
    }
    let url = '/export/transactions';
    if (Object.keys(params).length) {
      url += '?' + new URLSearchParams(params).toString();
    }
    this.setState({isDownloading: true, isDownloadError: false});
    downloadRequest(url, 'transactions').then(r => {
      this.setState({isDownloading: false, isDownloadError: r.isError});
    });
  };

  render() {
    const {WalletTransactionsReducer, CurrencyListReducer, LngReducer} = this.props;
    const pageInfo = WalletTransactionsReducer.pageInfo || {};
    const list = WalletTransactionsReducer.list || [];
    const currencyList = CurrencyListReducer.list || [];
    const currencies = (CurrencyListReducer.list || []).map((c: CurrencyTO) => ({
      label: `${c.mUBanner}(${c.mUCryptogram})`,
      currencyId: c.identificatSymbol,
    }));
    const types = ['DEPOSIT', 'WITHDRAW'];

    return (
      <Fragment>
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <I18n tKey='common.trx_history' />
          </div>
          <div className='tab-pane-filter'>
            <DropdownFilter title='common.currency' elements={currencies} onChange={this.changeCurrency} />
            <DropdownFilter title='common.trx_type' elements={types} onChange={this.changeType} />
            <DateRangeFilter onChange={this.changeDateRange} />
          </div>
        </div>
        <Paging
          totalElements={pageInfo.totalElements}
          offset={this.state.offset}
          limit={pageInfo.size}
          change={this.changePage}
          loadOnInit={true}
        />
        <div className='tab-pane-body'>
          <table className='table-transaction'>
            <thead className='table-transaction-header'>
              <tr>
                <th>
                  <I18n tKey='common.trx_id' />
                </th>
                <th>
                  <I18n tKey='common.currency' />
                </th>
                <th>
                  <I18n tKey='common.name' />
                </th>
                <th>
                  <I18n tKey='common.trx_type' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.amount' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.fee' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.trx_details' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.status' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.time_stamp' />
                </th>
              </tr>
            </thead>
            <tbody className='table-transaction-body'>
              {WalletTransactionsReducer.fetching && (
                <tr className='tr text-center'>
                  <td colSpan={20}>
                    <I18n tKey='common.loading' />
                  </td>
                </tr>
              )}

              {!WalletTransactionsReducer.fetching && list.length === 0 && (
                <tr className='tr text-center'>
                  <td colSpan={20}>
                    <I18n tKey='common.no_trx' />.
                  </td>
                </tr>
              )}
              {list.length > 0 &&
                list.map((t: MoneyTransactionTO, i) => {
                  const isReferral = /referral|dividend/.test(t.currentT.toLowerCase());
                  return (
                    <tr key={i}>
                      <td>{t.id}</td>
                      <td>{t.monetaryUnitCode}</td>
                      <td>{currencyList.length && this.renderCurrencyName(currencyList, t.monetaryUnitCode)}</td>
                      <td className={/deposit|referral|dividend/.test(t.currentT.toLowerCase()) ? 'green' : 'red'}>
                        {this.capitalizeFirstLetter(t.currentT)}
                      </td>
                      <td className='text-right'>
                        <CurrencyView
                          showOriginal={isReferral}
                          amount={t.curQty}
                          type={t.monetaryUnitCode}
                          appearance={t.currentT}
                        />
                      </td>
                      <td className='text-right'>{isReferral ? '-' : t.feeQtyVal}</td>
                      <td className='text-right'>{this.renderAdditionalInfo(t)}</td>
                      <td className='text-right'>{showStatus(t.currentSt, LngReducer.dictionary)}</td>
                      <td className='text-right'>{Formatter.dateTimeShort(t.cTime)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Paging
            isDownloadError={this.state.isDownloadError}
            isDownloading={this.state.isDownloading}
            onDownloadClick={this.onDownloadClick}
            totalElements={pageInfo.totalElements}
            offset={this.state.offset}
            limit={pageInfo.size}
            change={this.changePage}
            loadOnInit={false}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({WalletTransactionsReducer, CurrencyListReducer, LngReducer}) => ({
  WalletTransactionsReducer,
  CurrencyListReducer,
  LngReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        list: Actions.WalletTransactions.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionHistory));

export default connectedWithRouter;
