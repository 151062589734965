import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ReactComponent as AttachIcon} from '../../../../assets/images/documents-history/attach.svg';
import {ReactComponent as AttentionIcon} from '../../../../assets/images/documents-history/statuses/attention.svg';
import {ReactComponent as ApprovedIcon} from '../../../../assets/images/documents-history/statuses/approved_filled.svg';
import {ReactComponent as DocumentIcon} from '../../../../assets/images/documents-history/document.svg';
import {ReactComponent as RemoveIcon} from '../../../../assets/images/documents-history/remove.svg';

import Loader from '../Loader';
import Dialog from '../Dialog';
import I18n from '../helpers/I18n';

import {AttachDocuments} from '../../api/Actions';

import {ALLOWED_DOCUMENT_TYPES} from '../../utils/const';

const MAX_ALLOWED_FILE_SIZE = 10 * 1024 * 1024;
const ALLOWED_EXTENSTIONS = ['PDF', 'XLS', 'CSV', 'XLSX', 'ODS'];
const ALLOWED_TYPES = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/csv',
  'text/comma-separated-values',
  'application/excel',
  'application/vnd.msexcel',
];

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

class FileUpload extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    request: PropTypes.any,
    attachDocuments: PropTypes.func,
    attachedDocumentRequest: PropTypes.object,
  };

  state = {
    files: [],
    error: false,
  };

  componentDidUpdate(prevProps) {
    const {open, attachedDocumentRequest, onClose} = this.props;
    const {files} = this.state;

    if (prevProps.open && !open) {
      this.setState({files: []});
    }

    if (prevProps.files !== files) {
      const error =
        files.some(file => file.size > MAX_ALLOWED_FILE_SIZE || !ALLOWED_TYPES.includes(file.type.toLowerCase())) ||
        !files.length;

      this.setState({error});
    }

    if (prevProps.attachedDocumentRequest.fetching && attachedDocumentRequest.completed) {
      onClose();
    }
  }

  handleDropFiles = files => {
    const filteredFiles = files.filter(
      file =>
        !this.state.files.some(
          item => item.name === file.name && item.size === file.size && item.lastModified === file.lastModified
        )
    );

    this.setState(state => ({files: [...state.files, ...filteredFiles]}));
  };

  handleRemoveFIle = file => () => {
    this.setState(state => ({
      files: state.files.filter(item => item !== file),
    }));
  };

  handleSubmit = () => {
    const {files} = this.state;
    const {request, attachDocuments} = this.props;

    const formData = new FormData();

    formData.append('documentRequestId', request.id);
    formData.append('adminName', request.adminName);
    files.forEach(file => {
      formData.append('attachments', file);
    });

    attachDocuments(formData);
  };

  render() {
    const {open, attachedDocumentRequest, onClose} = this.props;
    const {files, error} = this.state;

    return (
      <Dialog open={open} onClose={onClose}>
        <Loader open={attachedDocumentRequest.fetching} type='Oval' />
        <div className='documents-history_modal_container_title'>
          <I18n tKey='documents.files_upload' />
        </div>

        <Dropzone
          onDrop={this.handleDropFiles}
          className='documents-history_modal_container_dropzone'
          accept={ALLOWED_DOCUMENT_TYPES}
        >
          {() => (
            <section className='documents-history_modal_container_dropzone_wrapper'>
              <I18n tKey='documents.drag_and_drop_files_here_or' />
              <button type='button' className='documents-history_modal_container_dropzone_button'>
                <AttachIcon className='documents-history_modal_container_dropzone_button_icon' />
                <I18n tKey='documents.attach_files' />
              </button>
            </section>
          )}
        </Dropzone>
        <div className='documents-history_modal_container_limit'>
          <I18n tKey='documents.maximum_file_size' />
          <span> - 10MB</span>
        </div>
        <div className='documents-history_modal_container_limit'>
          <I18n tKey='documents.allowed_document_formats' />
          <span> - {ALLOWED_EXTENSTIONS.join(', ')}</span>
        </div>
        {!!files.length && (
          <div className='documents-history_modal_container_grid'>
            {files.map((file, index) => (
              <div key={`${file}${index}`} className='documents-history_modal_container_grid_row'>
                <div className='documents-history_modal_container_grid_cell'>
                  {file.size < MAX_ALLOWED_FILE_SIZE ? (
                    <ApprovedIcon />
                  ) : (
                    <AttentionIcon className='documents-history_modal_container_grid_icon__error' />
                  )}
                </div>
                <div className='documents-history_modal_container_grid_cell'>
                  <DocumentIcon />
                </div>
                <div className='documents-history_modal_container_grid_cell'>
                  <p>{`${file.name} (${bytesToSize(file.size)})`}</p>
                  {file.size > MAX_ALLOWED_FILE_SIZE && (
                    <div className='documents-history_modal_container_grid_cell__error'>
                      <I18n tKey='documents.maximum_file_size' />
                      <span> - 10MB</span>
                    </div>
                  )}
                  {!ALLOWED_TYPES.includes(file.type.toLowerCase()) && (
                    <div className='documents-history_modal_container_grid_cell__error'>
                      <I18n tKey='documents.allowed_document_formats' />
                      <span> - {ALLOWED_EXTENSTIONS.join(', ')}</span>
                    </div>
                  )}
                </div>
                <div className='documents-history_modal_container_grid_cell'>
                  <button
                    type='button'
                    className='documents-history_modal_container_grid_cell_button'
                    onClick={this.handleRemoveFIle(file)}
                  >
                    <RemoveIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className='documents-history_modal_container_button'>
          <button
            type='button'
            className={
              error
                ? 'documents-history_modal_container_button_submit documents-history_modal_container_button__disabled'
                : 'documents-history_modal_container_button_submit'
            }
            onClick={this.handleSubmit}
            disabled={error}
          >
            <I18n tKey='documents.submit_documents' />
          </button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = ({attachedDocumentRequestReducer}) => ({
  attachedDocumentRequest: attachedDocumentRequestReducer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      attachDocuments: AttachDocuments.action,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
