import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Row extends Component {
  static propTypes = {
    darkTheme: PropTypes.bool,
    isPending: PropTypes.bool,
  };

  render() {
    const {children, isPending, darkTheme} = this.props;

    return (
      <tr
        className={
          isPending
            ? `documents-history_content_table_row documents-history_content_table_row__active ${
                darkTheme ? 'documents-history__dark' : ''
              }`
            : `documents-history_content_table_row ${darkTheme ? 'documents-history__dark' : ''}`
        }
      >
        {children}
      </tr>
    );
  }
}

export default Row;
