import React from 'react';
import I18n from '../helpers/I18n';

const ModalRegistrationNotWorking = props => {
  return (
    <div className='modal-signup-not-working'>
      <div className='modal-overlay-signup' onClick={props.changeStateModal} />
      <div className='modal-signup-container'>
        <span>
          <I18n tKey='registration:not_working' />
        </span>
        <div className='button-close-modal-signup' onClick={props.changeStateModal}>
          <button type='button' className='btn btn-primary white'>
            <I18n tKey='common:close' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalRegistrationNotWorking;
