import React from 'react';

import I18n from '../../../cmp/helpers/I18n';

import {ReactComponent as AdminIcon} from '../../../../../assets/images/icon/admin-laptop.svg';
import {ReactComponent as UnfreezeIcon} from '../../../../../assets/images/icon/unfreezeIcon.svg';
import {ReactComponent as QuestionIcon} from '../../../../../assets/images/icon/question.svg';
import {ReactComponent as WarningIcon} from '../../../../../assets/images/icon/warning-purple.svg';
import {ReactComponent as VerificationStatus} from '../../../../../assets/images/icon/verification-status.svg';
import {ReactComponent as EmailIcon} from '../../../../../assets/images/icon/emailIcon.svg';
import {ReactComponent as DollarIcon} from '../../../../../assets/images/icon/dollar-ic.svg';
import {ReactComponent as BusinessIcon} from '../../../../../assets/images/icon/business-ic.svg';

export const MAX_ALLOWED_FILE_SIZE = 10 * 1024 * 1024;
export const ALLOWED_EXTENSTIONS = ['PDF', 'XLS', 'CSV', 'XLSX', 'ODS', 'PNG', 'JPEG'];
export const ALLOWED_TYPES = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/csv',
  'text/comma-separated-values',
  'application/excel',
  'application/vnd.msexcel',
];

export const ACCOUNT_USER_OPTIONS = [
  {
    value: 'suspicious-activity',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <WarningIcon />
        <I18n tKey='helpcenter.suspicious_activity' />
      </div>
    ),
    requestTypeId: 227,
  },
  {
    value: 'verification-status',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <VerificationStatus />
        <I18n tKey='helpcenter.verification_denied' />
      </div>
    ),
    requestTypeId: 196,
  },
  {
    value: 'change-email',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <EmailIcon />
        <I18n tKey='helpcenter.email_change' />
      </div>
    ),
    requestTypeId: 182,
  },
];

export const ACCOUNT_GUEST_OPTIONS = [
  {
    value: '2fa',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <AdminIcon />
        <I18n tKey='helpcenter.2fa' />
      </div>
    ),
    requestTypeId: 228,
  },

  {
    value: 'unfreeze-account',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <UnfreezeIcon /> <I18n tKey='helpcenter.unfreezing_account' />
      </div>
    ),
    requestTypeId: 181,
  },
  {
    value: 'other-questions',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <QuestionIcon /> <I18n tKey='helpcenter:other_questions' />
      </div>
    ),
    requestTypeId: 257,
  },
];

export const ACCOUNT_OPTIONS = [...ACCOUNT_GUEST_OPTIONS, ...ACCOUNT_USER_OPTIONS];

export const CRYPTO_OPTIONS = [
  {
    value: 'crypto-deposit',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <DollarIcon />
        <I18n tKey='helpcenter.crypto_deposit' />
      </div>
    ),
    requestTypeId: 189,
  },
  {
    value: 'crypto-withdrawal',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <DollarIcon /> <I18n tKey='helpcenter.crypto_withdrawal' />
      </div>
    ),
    requestTypeId: 190,
  },
];

export const TRADING_OPTIONS = [
  {
    value: 'trading-issues',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <WarningIcon />
        <I18n tKey='helpcenter.trading_issues' />
      </div>
    ),
    requestTypeId: 193,
  },
  {
    value: 'fee-issues',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <WarningIcon />
        <I18n tKey='helpcenter.fees_issues' />
      </div>
    ),
    requestTypeId: 194,
  },
];

export const FIAT_OPTIONS = [
  {
    value: 'fiat-deposit',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <DollarIcon />
        <I18n tKey='helpcenter.fiat_deposit' />
      </div>
    ),
    requestTypeId: 185,
  },
  {
    value: 'fiat-withdrawal',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <DollarIcon />
        <I18n tKey='helpcenter.fiat_withdrawal' />
      </div>
    ),
    requestTypeId: 186,
  },
];

export const BUSINESS_OPTIONS = [
  {
    value: 'Individual Entrepreneur',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <BusinessIcon />
        <I18n tKey='helpcenter.entrepreneur' />
      </div>
    ),
    requestTypeId: 226,
  },
  {
    value: 'Other',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <BusinessIcon />
        <I18n tKey='helpcenter.other' />
      </div>
    ),
    requestTypeId: 225,
  },
  {
    value: 'Corporation',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <BusinessIcon />
        <I18n tKey='helpcenter.corporation' />
      </div>
    ),
    requestTypeId: 224,
  },
  {
    value: 'Joint-Stock Company',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <BusinessIcon />
        <I18n tKey='helpcenter.joint_stock' />
      </div>
    ),
    requestTypeId: 223,
  },
  {
    value: 'Limited Liability Company (LLC) / Private Limited Company (LTD)',
    label: (
      <div className='d-flex align-items-center label__wrapper'>
        <BusinessIcon />
        <I18n tKey='helpcenter.llc' />
      </div>
    ),
    requestTypeId: 221,
  },
];

export const TICKET_CATEGORY = {
  accounts: 'accounts',
  crypto: 'crypto',
  trading: 'trading',
  fiat: 'fiat',
  business: 'business',
};

export const SERVICE_DESK_ID = {
  [TICKET_CATEGORY.accounts]: '11',
  [TICKET_CATEGORY.crypto]: '13',
  [TICKET_CATEGORY.trading]: '14',
  [TICKET_CATEGORY.fiat]: '12',
  [TICKET_CATEGORY.business]: '18',
};

export const TICKET_SUBCATEGORY_OPTIONS = isUserExist => {
  const ACCOUNT_USER_OPTIONS = [
    {
      value: '227',
      label: (
        <div className='d-flex align-items-center label__wrapper'>
          <WarningIcon />
          <I18n tKey='helpcenter.suspicious_activity' />
        </div>
      ),
      requestTypeId: '227',
    },
    {
      value: '196',
      label: (
        <div className='d-flex align-items-center label__wrapper'>
          <VerificationStatus />
          <I18n tKey='helpcenter.verification_denied' />
        </div>
      ),
      requestTypeId: '196',
    },
    {
      value: '182',
      label: (
        <div className='d-flex align-items-center label__wrapper'>
          <EmailIcon />
          <I18n tKey='helpcenter.email_change' />
        </div>
      ),
      requestTypeId: '182',
    },
  ];

  const ACCOUNT_GUEST_OPTIONS = [
    {
      value: '228',
      label: (
        <div className='d-flex align-items-center label__wrapper'>
          <AdminIcon />
          <I18n tKey='helpcenter.2fa' />
        </div>
      ),
      requestTypeId: '228',
    },

    {
      value: '181',
      label: (
        <div className='d-flex align-items-center label__wrapper'>
          <UnfreezeIcon /> <I18n tKey='helpcenter.unfreezing_account' />
        </div>
      ),
      requestTypeId: '181',
    },
    {
      value: '257',
      label: (
        <div className='d-flex align-items-center label__wrapper'>
          <QuestionIcon /> <I18n tKey='helpcenter:other_questions' />
        </div>
      ),
      requestTypeId: '257',
    },
  ];

  return {
    [TICKET_CATEGORY.accounts]: isUserExist
      ? [...ACCOUNT_GUEST_OPTIONS, ...ACCOUNT_USER_OPTIONS]
      : ACCOUNT_GUEST_OPTIONS,
    [TICKET_CATEGORY.crypto]: [
      {
        value: '189',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <DollarIcon />
            <I18n tKey='helpcenter.crypto_deposit' />
          </div>
        ),
        requestTypeId: '189',
      },
      {
        value: '190',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <DollarIcon /> <I18n tKey='helpcenter.crypto_withdrawal' />
          </div>
        ),
        requestTypeId: '190',
      },
    ],
    [TICKET_CATEGORY.trading]: [
      {
        value: '193',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <WarningIcon />
            <I18n tKey='helpcenter.trading_issues' />
          </div>
        ),
        requestTypeId: '193',
      },
      {
        value: '194',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <WarningIcon />
            <I18n tKey='helpcenter.fees_issues' />
          </div>
        ),
        requestTypeId: '194',
      },
    ],
    [TICKET_CATEGORY.fiat]: [
      {
        value: '185',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <DollarIcon />
            <I18n tKey='helpcenter.fiat_deposit' />
          </div>
        ),
        requestTypeId: '185',
      },
      {
        value: '186',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <DollarIcon />
            <I18n tKey='helpcenter.fiat_withdrawal' />
          </div>
        ),
        requestTypeId: '186',
      },
    ],
    [TICKET_CATEGORY.business]: [
      {
        value: '226',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <BusinessIcon />
            <I18n tKey='helpcenter.entrepreneur' />
          </div>
        ),
        requestTypeId: '226',
      },
      {
        value: '225',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <BusinessIcon />
            <I18n tKey='helpcenter.other' />
          </div>
        ),
        requestTypeId: '225',
      },
      {
        value: '224',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <BusinessIcon />
            <I18n tKey='helpcenter.corporation' />
          </div>
        ),
        requestTypeId: '224',
      },
      {
        value: '223',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <BusinessIcon />
            <I18n tKey='helpcenter.joint_stock' />
          </div>
        ),
        requestTypeId: '223',
      },
      {
        value: '221',
        label: (
          <div className='d-flex align-items-center label__wrapper'>
            <BusinessIcon />
            <I18n tKey='helpcenter.llc' />
          </div>
        ),
        requestTypeId: '221',
      },
    ],
  };
};

export const CUSTOM_SELECT_THEME = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    text: 'black',
    primary25: '#F4F5F7',
    primary: '#F4F5F7',
  },
});

export const CUSTOM_SELECT_STYLES = {
  control: (base, state) => ({
    ...base,
    background: 'var(--bg_clr_11)',
    border: 'none',
  }),
  menu: base => ({
    ...base,
    zIndex: 100,
    border: 'none',
  }),
  menuList: base => ({
    ...base,
    background: 'var(--bg_clr_11)',
  }),
};
