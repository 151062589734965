import React, {Component} from 'react';
import I18n from './I18n';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import {DEACTIVATE_REMINDER} from '../../reducers/ApplicationInitReducer';
import {CheckBox} from '../modal/CheckBox';
import {Link} from 'react-router-dom';
import {translate} from '../../utils/i18n';

class Teaser extends Component {
  state = {
    isChecked: false,
  };

  toggleState = () => {
    this.setState({isChecked: !this.state.isChecked});
  };

  closeBanner = () => {
    this.props.closeBanner(this.state.isChecked);
  };

  render() {
    const percent = this.props.ApplicationSettingsReducer.data.referralFeePercent;
    const label = translate('referral:welcome_title', this.props.LngReducer.dictionary).replace(
      '%amount%',
      `${percent}%`
    );

    return (
      <div className='teaser-banner'>
        <div className='banner-content'>
          {label}
          <Link className='profile-link' to='/profile'>
            <I18n tKey='referral:go:to_profile' />
          </Link>
        </div>
        <div className='control-block'>
          <CheckBox checked={this.state.isChecked} onChange={this.toggleState} label='common:do_not:show_againg' />
          <button className='hide-btn' onClick={this.closeBanner}>
            <I18n tKey='common:hide' />
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  closeBanner: isChecked => dispatch({type: DEACTIVATE_REMINDER, payload: {isChecked}}),
});

export default withRouter(
  connect(
    ({LngReducer, ApplicationSettingsReducer}) => ({LngReducer, ApplicationSettingsReducer}),
    mapDispatchToProps
  )(Teaser)
);
