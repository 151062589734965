// @flow
import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import {ReactComponent as TwIc} from '../../../assets/images/socialicons/twitter.svg';
import {ReactComponent as LiIc} from '../../../assets/images/socialicons/linkedin.svg';
import {ReactComponent as TelIc} from '../../../assets/images/socialicons/telegram.svg';
import {ReactComponent as InstaIc} from '../../../assets/images/socialicons/instagram.svg';
import {SvgWrapper} from '../cmp/helpers/SvgWrapper';
import {returnInfoTabs} from './static/TopMenu';
import I18n from '../cmp/helpers/I18n';

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.infoTabs = returnInfoTabs(props.LngReducer.dictionary);
  }

  render() {
    return (
      <footer className='footer'>
        <div className='container-center'>
          <div className='footer-nav'>
            {this.infoTabs.map((tab, i) => (
              <div key={i} className='footer-nav_item'>
                <div className='title'>{tab.label.toUpperCase()}</div>
                {tab.subRoutes.map((subTab, j) => (
                  <div key={j} className='nav'>
                    <a target='_blank' href={subTab.url}>
                      {subTab.label}
                    </a>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className='footer-copy'>
            <div className='text'>
              &#169; {moment().format('YYYY')} <I18n tKey='common.footer' />
            </div>
            <div className='footer-follow'>
              <a target='_blank' href='https://t.me/unitex_official' className='link'>
                <SvgWrapper SvgCmp={TelIc} className='icon icon-social' />
              </a>
              <a target='_blank' href='https://twitter.com/unit_exchange' className='link'>
                <SvgWrapper SvgCmp={TwIc} className='icon icon-social' />
              </a>
              <a target='_blank' href='https://www.linkedin.com/company/unitexchange' className='link'>
                <SvgWrapper SvgCmp={LiIc} className='icon icon-social' />
              </a>
              <a target='_blank' href='https://www.instagram.com/unit_exchange/' className='link'>
                <SvgWrapper SvgCmp={InstaIc} className='icon icon-social' />
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = ({LngReducer}) => ({LngReducer});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({}, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
