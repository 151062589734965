import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {CreateTicket} from '../../../api/Actions';
import {CLOSE_POPUP} from '../../../reducers/PopupReducer';
import I18n from '../../helpers/I18n';

export const TICKET_CREATED_POPUP = 'TICKET_CREATED_POPUP';

class TicketCreated extends PureComponent {
  constructor(props) {
    super(props);

    this.outsideClickRef = React.createRef();
    this.excludeBlockRef = React.createRef();
  }

  componentDidMount() {
    this.outsideClickRef.current.addEventListener('click', this.handleOutsideClick);
    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    this.outsideClickRef.current.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('keydown', this.handleKeyPress);

    this.props.clearCreatedTicket();
  }

  handleOutsideClick = ev => {
    if (!this.excludeBlockRef.current.contains(ev.target)) {
      this.props.close();
      this.props.closeCb();
    }
  };

  handleKeyPress = ev => {
    if (ev.key === 'Escape') {
      ev.preventDefault();

      this.props.close();
      this.props.closeCb();
    }
  };

  render() {
    return (
      <div ref={this.outsideClickRef} className='custom-modal'>
        <div ref={this.excludeBlockRef} className='modal-content ticket-created-modal'>
          <h2>
            <I18n tKey='popup:ticket_created:header' />
          </h2>

          <div className='text-description text-info'>
            <I18n tKey='popup:ticket_created:description' />
          </div>

          <button
            onClick={() => {
              this.props.close();
              this.props.closeCb();
            }}
            className='btn btn-purple btn-full size'
          >
            <I18n tKey='common:proceed' />
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    close: () => dispatch({type: CLOSE_POPUP}),
    clearCreatedTicket: () => dispatch({type: CreateTicket.events.onClear}),
  };
};

export default withRouter(connect(undefined, mapDispatchToProps)(TicketCreated));
