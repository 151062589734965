import React, {Component} from 'react';
import {OPERATION_TYPE} from '../../constants';

class Details extends Component {
  render() {
    const {
      leftCurrencyAmount,
      rightCurrencyAmount,
      leftCurrency,
      rightCurrency,
      operationType,
      userData,
      paymentType,
      specificCoin,
    } = this.props;

    return (
      <div className='third-party__exchange-details exchange-details'>
        <div>You pay:</div>
        <div>
          {operationType === OPERATION_TYPE.BUY ? (
            <p>
              <span className='exchange-details__bold'>{leftCurrencyAmount}</span> {leftCurrency.value.id}
            </p>
          ) : (
            <p>
              <span className='exchange-details__bold'>{rightCurrencyAmount}</span>{' '}
              {rightCurrency.value.symbol.toUpperCase()}
              {specificCoin && ` (${specificCoin.value})`}
            </p>
          )}
        </div>
        <div>You Receive:</div>
        <div>
          {operationType === OPERATION_TYPE.BUY ? (
            <p>
              <span className='exchange-details__bold'>{rightCurrencyAmount}</span>{' '}
              {rightCurrency.value.symbol.toUpperCase()}
              {specificCoin && ` (${specificCoin.value})`}
            </p>
          ) : (
            <p>
              <span className='exchange-details__bold'>{leftCurrencyAmount}</span> {leftCurrency.value.id}
            </p>
          )}
        </div>
        <div>Fee:</div>
        <div>5 %</div>
        <div>Account:</div>
        <div>{userData && userData.masterPersonality && userData.masterPersonality.electronicMailVal}</div>
        <div>Payment Chanel:</div>
        <div>{paymentType.label}</div>
      </div>
    );
  }
}

export default Details;
