import * as React from 'react';
import {bindActionCreators} from 'redux';
import {GetMarketVolumeStatsRequests, GetVolumeStatsRequests} from '../api/Actions';
import {connect} from 'react-redux';
import {CurrencyView} from '../cmp/helpers/CurrencyView';
import VolumeDatePicker from './VolumeDatePicker';
import Loader from '../cmp/Loader';

export const MARKET_MODE = 'market';
export const EXCHANGE_MODE = 'exchange';

const REFRESH_INTERVAL = 10 * 60 * 1000;
const BASE_STAT_CURRENCY = 'USDU';
const SECONDARY_STAT_CURRENCY = 'BTC';

class ExchangeVolumeStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {},
    };
  }

  getActiveMarketId() {
    const {ActiveMarketReducer} = this.props;
    if (!ActiveMarketReducer.identificatSymbol) {
      return null;
    }

    return ActiveMarketReducer.identificatSymbol;
  }

  componentDidMount() {
    this.scheduleMarketStatsUpdate();
  }

  scheduleMarketStatsUpdate() {
    clearTimeout(this.refreshInterval);
    this.fetchMarketStats();
    this.refreshInterval = setInterval(() => {
      this.fetchMarketStats();
    }, REFRESH_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    const {ActiveMarketReducer} = this.props;

    if (
      ActiveMarketReducer.identificatSymbol !== prevProps.ActiveMarketReducer.identificatSymbol &&
      this.isMarketMode()
    ) {
      this.fetchMarketStats();
    }
  }

  convertCurrencyRate(amount) {
    const {WsWalletReducer} = this.props;
    const {crossCourses} = WsWalletReducer;
    const currencyTo = SECONDARY_STAT_CURRENCY;
    const currencyFrom = BASE_STAT_CURRENCY;

    if (crossCourses && crossCourses[currencyTo] && crossCourses[currencyTo][currencyFrom]) {
      return amount / crossCourses[currencyTo][currencyFrom];
    }

    return null;
  }

  fetchMarketStats() {
    const {query} = this.state;
    if (!Object.keys(query).length) {
      return;
    }

    if (this.isMarketMode()) {
      query.marketId = this.getActiveMarketId();
      this.props.getMarketVolumeStats(null, query);
      return;
    }

    this.props.getVolumeStats(null, query);
  }

  changeMode(arg) {
    this.setState({query: arg});
    this.fetchMarketStats();
  }

  renderConvertedVolume(volume) {
    let component;
    if (parseFloat(volume) === 0) {
      component = <CurrencyView type={SECONDARY_STAT_CURRENCY} amount={0} showType={true} maxDigits={2} />;
    } else {
      const converted = this.convertCurrencyRate(volume);
      if (converted !== null)
        component = <CurrencyView type={SECONDARY_STAT_CURRENCY} amount={converted} showType={true} maxDigits={2} />;
    }

    if (!component) {
      return null;
    }

    return <React.Fragment>&nbsp;/&nbsp;{component}</React.Fragment>;
  }

  isMarketMode() {
    return this.props.mode === MARKET_MODE;
  }

  renderExchangeStats(data) {
    return (
      <span className='value'>
        :&nbsp;
        <CurrencyView type={BASE_STAT_CURRENCY} amount={data.totalPrice} showType={true} maxDigits={2} />
        {this.renderConvertedVolume(data.totalPrice)}
      </span>
    );
  }

  renderMarketStats(data) {
    const {ActiveMarketReducer} = this.props;
    const {leftMonetaryUnitCode, rightMonetaryUnitCode} = ActiveMarketReducer;

    return (
      <span className='value'>
        :&nbsp;
        <CurrencyView type={leftMonetaryUnitCode} amount={data.amount} showType={true} maxDigits={2} />
        &nbsp;/&nbsp;
        <CurrencyView type={rightMonetaryUnitCode} amount={data.totalPrice} showType={true} maxDigits={2} />
      </span>
    );
  }

  render() {
    const isMarketMode = this.isMarketMode();
    const reducer = this.props[isMarketMode ? 'MarketVolumeStatsReducer' : 'VolumeStatsReducer'];
    const {data} = reducer;
    if (isMarketMode && !this.getActiveMarketId()) {
      return null;
    }
    const hasData = data && !reducer.fetching && data.hasOwnProperty('amount');

    return (
      <div className='market-volume-stats'>
        <VolumeDatePicker onChange={this.changeMode.bind(this)} type={this.props.mode} />
        {!hasData && <Loader open={true} />}
        {hasData && !isMarketMode && this.renderExchangeStats(data)}
        {hasData && isMarketMode && this.renderMarketStats(data)}
      </div>
    );
  }
}

const mapStateToProps = ({
  VolumeStatsReducer,
  MarketVolumeStatsReducer,
  LngReducer,
  WsWalletReducer,
  ActiveMarketReducer,
}) => {
  return {
    VolumeStatsReducer,
    MarketVolumeStatsReducer,
    LngReducer,
    WsWalletReducer,
    ActiveMarketReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getVolumeStats: GetVolumeStatsRequests.action,
        getMarketVolumeStats: GetMarketVolumeStatsRequests.action,
      },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeVolumeStats);
