export const numberRegex = new RegExp('^(?=.*[0-9])');
export const uppercaseRegex = new RegExp('^(?=.*[A-Z])');
export const lowercaseRegex = new RegExp('^(?=.*[a-z])');
export const symbolsRegex = new RegExp('^(?=.*[@#$%^&+=_\\!\\?;:,(){}])');

export const PASSWORD_SECURITY_STATES = ['To weak', 'Not recommended', 'Secure password', 'Extremely secure password'];

export function determineSecurityType(password) {
  let type =
    [numberRegex, uppercaseRegex, lowercaseRegex, symbolsRegex].map(regex => regex.test(password)).filter(bool => bool)
      .length - 1;
  return type;
}

export function determineSecurityLevels(password) {
  return {
    numberRegex: numberRegex.test(password),
    uppercaseRegex: uppercaseRegex.test(password),
    lowercaseRegex: lowercaseRegex.test(password),
    symbolsRegex: symbolsRegex.test(password),
    size: password.length >= 8,
  };
}
