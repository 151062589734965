// @flow
import storageHelper from 'storage-helper';
import * as Actions from '../api/Actions';
import jwtDecode from 'jwt-decode';

import {LoginTwoFactorAuth, OrderStopList, SetNewPassword, UserLogin, UserLogout, UserRegister} from '../api/Actions';
import {NOTIFY_2FA_DISABLED} from '../layout/Leftbar';
import {REFERRAL_REMINDER} from './ApplicationInitReducer';
import {CLEAN_SANCTION_CHECK} from './SanctionCheckReducer';

export const USER_STORAGE_KEY = 'USER_STORAGE_KEY';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const USER_SESSION_STARTED = 'USER_SESSION_STARTED';
export const USER_SESSION_FINISHED = 'USER_SESSION_FINISHED';

const initialState = {
  accIsFrozen: false,
};

let intervalRef = null;

function user(state = initialState, action) {
  switch (action.type) {
    case LoginTwoFactorAuth.events.onSuccess:
    case SetNewPassword.events.onSuccess:
    case UserRegister.events.onSuccess:
    case UserLogin.events.onSuccess: {
      if (action.data.isFrozen) {
        // Actions.CheckUserSanctionStatus.action()(action.asyncDispatch);

        return {accIsFrozen: true};
      }

      let uuid = null;

      if (action.data) {
        if (action.data.uuid) {
          uuid = action.data.uuid;
        } else if (action.data.masterPersonality && action.data.masterPersonality.uniqueSymbolRow) {
          uuid = action.data.masterPersonality.uniqueSymbolRow;
        }
      }

      if (action.data.specialMark && action.data.followingLevel === 'NO') {
        storageHelper.setItem(USER_STORAGE_KEY, JSON.stringify(action.data));
        action.asyncDispatch({type: USER_SESSION_STARTED, session: action.data, jwt: action.data.specialMark});

        if (action.type === LoginTwoFactorAuth.events.onSuccess || action.type === UserLogin.events.onSuccess) {
          Actions.GetFavouriteMarkets.action()(action.asyncDispatch);
          Actions.CurrencyList.action()(action.asyncDispatch);
        }

        // Actions.CheckUserSanctionStatus.action()(action.asyncDispatch);

        return {...state, ...action.data, accIsFrozen: false, uuid};
      } else {
        // Actions.CheckUserSanctionStatus.action()(action.asyncDispatch);

        return {...state, accIsFrozen: false, uuid};
      }
    }

    case Actions.UserSettings.events.onSuccess: {
      const result = {...state, masterPersonality: action.data};
      storageHelper.setItem(USER_STORAGE_KEY, JSON.stringify(result));
      return result;
    }

    case Actions.ToggleMarketInFavourite.events.onSuccess: {
      Actions.GetFavouriteMarkets.action()(action.asyncDispatch);
      return state;
    }

    case Actions.ConfirmEmail.events.onSuccess:
    case Actions.ConfirmEmail2Fa.events.onSuccess:
    case Actions.DisableEmail2Fa.events.onSuccess: {
      console.log('refresh user');
      Actions.UserSettings.action()(action.asyncDispatch);
      return state;
    }

    case TOKEN_EXPIRED:
    case UserLogout.events.onSuccess: {
      console.log('logouted...');
      intervalRef && clearInterval(intervalRef);
      action.asyncDispatch({type: USER_SESSION_FINISHED});
      action.asyncDispatch({type: CLEAN_SANCTION_CHECK});
      action.asyncDispatch({type: Actions.OrderList.events.onClear});
      action.asyncDispatch({type: Actions.OrderStopList.events.onClear});
      action.asyncDispatch({type: Actions.GetFavouriteMarkets.events.onClear});
      storageHelper.removeItem(USER_STORAGE_KEY);
      storageHelper.removeItem(NOTIFY_2FA_DISABLED);
      return {};
    }

    default:
      return state;
  }
}

export default user;
