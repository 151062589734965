// @flow
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {isMobile} from 'react-device-detect';

import {createWebSocketMiddleware} from './middleware/ws';
import * as Actions from './api/Actions';

import ApiAccessKeyPopupReducer from './reducers/ApiAccessKeyPopupReducer';
import WsRecentTradesReducer from './reducers/ws/WsRecentTradesReducer';
import ApplicationInitReducer from './reducers/ApplicationInitReducer';
import WsMarketStatsReducer from './reducers/ws/WsMarketStatsReducer';
import SelectedOrderReducer from './reducers/SelectedOrderReducer';
import {NotificationReducer} from './reducers/NotificationReducer';
import ActiveMarketReducer from './reducers/ActiveMarketReducer';
import ApiKeyStateReducer from './reducers/ApiKeyStateReducer';
import WsTickersReducer from './reducers/ws/WsTickersReducer';
import WsWalletReducer from './reducers/ws/WsWalletReducer';
import OrderbookReducer from './reducers/OrderbookReducer';
import WsOhlcvReducer from './reducers/ws/WsOhlcvReducer';
import SessionReducer from './reducers/SessionReducer';
import {ThemeReducer} from './reducers/ThemeReducer';
import PopupReducer from './reducers/PopupReducer';
import {LngReducer} from './reducers/LngReducer';
import WsReducer from './reducers/WsReducer';
import MarketPricesReducer from './reducers/MarketPricesReducer';
import {
  GetCurrencyXRates,
  getDepositChainInfo,
  getUserMarketsSettings,
  getWithdrawChains,
  OrderStopList,
  SubmitSanctionsData,
} from './api/Actions';
import {SanctionCheckReducer} from './reducers/SanctionCheckReducer';

export const APPLICATION_STARTED = 'APPLICATION_STARTED';

const handleMobileRedirect = () => {
  const REDIRECT_IF_PRESENT = 'checkRedirect';
  const DO_NOT_REDIRECT_IF_PRESENT = 'noRedirect';
  const queryParams = window.location.search;
  if (!queryParams.includes(REDIRECT_IF_PRESENT)) {
    //todo: remove this condition if we need to always redirect
    return;
  }
  if (queryParams.includes(DO_NOT_REDIRECT_IF_PRESENT)) {
    return;
  }
  if (isMobile) {
    window.location = settings.MOBILE_FRONTEND_URL;
  }
};

const asyncDispatchMiddleware = store => next => action => {
  let syncActivityFinished = false;
  let actionQueue = [];

  function flushQueue() {
    actionQueue.forEach(a => store.dispatch(a)); // flush queue
    actionQueue = [];
  }

  function asyncDispatch(asyncAction) {
    actionQueue = actionQueue.concat([asyncAction]);
    if (syncActivityFinished) {
      flushQueue();
    }
  }

  const actionWithAsyncDispatch = Object.assign({}, action, {asyncDispatch});
  next(actionWithAsyncDispatch);
  syncActivityFinished = true;
  flushQueue();
};

const middleware = [
  thunk,
  asyncDispatchMiddleware,
  createWebSocketMiddleware(
    window.settings.WSURL,
    {
      unfold: frame => {
        const data = JSON.parse(frame);
        return {type: data[0], payload: data[1]};
      },
    },
    true
  ),
];

/* eslint-disable */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const store = (window.globalStore = createStore(
  combineReducers({
    MarketListReducer: Actions.MarketList.reducer,
    CurrencyListReducer: Actions.CurrencyList.reducer,
    ResendConfirmationEmailReducer: Actions.ResendConfirmationEmail.reducer,
    UserLoginReducer: Actions.UserLogin.reducer,
    AccountListReducer: Actions.AccountList.reducer,
    LoginTwoFactorAuthReducer: Actions.LoginTwoFactorAuth.reducer,
    UserRegisterReducer: Actions.UserRegister.reducer,
    ConfirmEmailReducer: Actions.ConfirmEmail.reducer,
    UserLogoutReducer: Actions.UserLogout.reducer,
    OpenOrderReducer: Actions.OpenOrder.reducer,
    OrderListReducer: Actions.OrderList.reducer,
    OrderHistoryReducer: Actions.OrderHistory.reducer,
    CloseOrderReducer: Actions.CloseOrder.reducer,
    UserSettingsReducer: Actions.UserSettings.reducer,
    ChangePasswordReducer: Actions.ChangePassword.reducer,
    WalletTransactionsReducer: Actions.WalletTransactions.reducer,
    RequestPasswordResetReducer: Actions.RequestPasswordReset.reducer,
    KycAccountInfoReducer: Actions.KycAccountInfo.reducer,
    KycAccountSaveReducer: Actions.KycAccountSave.reducer,
    KycDocumentListReducer: Actions.KycDocumentList.reducer,
    ApplicationSettingsReducer: Actions.ApplicationSettings.reducer,
    AddTwoFactorAuthReducer: Actions.AddTwoFactorAuth.reducer,
    BindTwoFactorAuthReducer: Actions.BindTwoFactorAuth.reducer,
    RemoveTwoFactorAuthReducer: Actions.RemoveTwoFactorAuth.reducer,
    SetNewPasswordReducer: Actions.SetNewPassword.reducer,
    GetAccountApiKeysReducer: Actions.GetAccountApiKeys.reducer,
    BaseApiKeyPermissionListReducer: Actions.BaseApiKeyPermissionList.reducer,
    EnableAccountApiKeyReducer: Actions.EnableAccountApiKey.reducer,
    DisableAccountApiKeyReducer: Actions.DisableAccountApiKey.reducer,
    CreateAccountApiKeyReducer: Actions.CreateAccountApiKey.reducer,
    RevokeApiKeyReducer: Actions.RevokeApiKey.reducer,
    UpdateAccountApiKeyReducer: Actions.UpdateAccountApiKey.reducer,
    ResendApiKeyConfirmationEmailReducer: Actions.ResendApiKeyConfirmationEmail.reducer,
    ConfirmApiKeyReducer: Actions.ConfirmApiKey.reducer,
    WalletListReducer: Actions.WalletList.reducer,
    DepositAddressesListReducer: Actions.DepositAddressesList.reducer,
    GetDepositAddressReducer: Actions.GetDepositAddress.reducer,
    WithdrawUsageReducer: Actions.WithdrawUsage.reducer,
    WithdrawRequestReducer: Actions.WithdrawRequest.reducer,
    WithdrawCancelReducer: Actions.WithdrawCancel.reducer,
    WithdrawListReducer: Actions.WithdrawList.reducer,
    WithdrawConfirmReducer: Actions.WithdrawConfirm.reducer,
    ResendWithdrawConfirmEmailReducer: Actions.ResendWithdrawConfirmEmail.reducer,
    CurrencyConvertReducer: Actions.CurrencyConvert.reducer,
    OhlcvHistoryReducer: Actions.OhlcvHistory.reducer,
    UserTradeHistoryReducer: Actions.UserTradeHistory.reducer,
    UserLastTradesReducer: Actions.UserLastTrades.reducer,
    CountryListReducer: Actions.CountryList.reducer,
    FeesSettingsReducer: Actions.FeesSettings.reducer,
    PaymentPlanReducer: Actions.PaymentPlan.reducer,
    GetFavouriteMarketsReducer: Actions.GetFavouriteMarkets.reducer,
    ToggleMarketInFavouriteReducer: Actions.ToggleMarketInFavourite.reducer,
    ConfirmRevokeApiKeyReducer: Actions.ConfirmRevokeApiKey.reducer,
    DictionaryReducer: Actions.GetDictionary.reducer,
    UserActivityHistoryReducer: Actions.UserActivityHistory.reducer,
    LanguagesListReducer: Actions.LanguagesList.reducer,
    ConfirmEmail2FaReducer: Actions.ConfirmEmail2Fa.reducer,
    DisableEmail2FaReducer: Actions.DisableEmail2Fa.reducer,
    CheckTokenReducer: Actions.CheckTokenResetPassword.reducer,
    ReferralProgramLinkReducer: Actions.ReferralProgramLink.reducer,
    CountriesListReducer: Actions.VerifyCountryList.reducer,
    FreezeAccountReducer: Actions.FreezeAccount.reducer,
    UserTradeFeeReducer: Actions.UserTradeFeeUsing.reducer,
    GetReferralCodeReducer: Actions.GetReferralCode.reducer,
    GetUsersListRegisteredReferralReducer: Actions.GetUsersListRegisteredReferral.reducer,
    GetParentReferralUserReducer: Actions.GetParentReferralUser.reducer,
    GetReferralStatsBrakedownReducer: Actions.GetReferralStatsBrakedown.reducer,
    GlobalPassTokenReducer: Actions.GlobalPassToken.reducer,
    GtiRequestReducer: Actions.GtiRequest.reducer,
    GetGtiStatusReducer: Actions.GetGtiStatus.reducer,
    documentRequestsReducer: Actions.GetDocumentRequests.reducer,
    attachedDocumentRequestReducer: Actions.AttachDocuments.reducer,
    GtiDepositReduser: Actions.GtiDepositRequest.reducer,
    VolumeStatsReducer: Actions.GetVolumeStatsRequests.reducer,
    MarketVolumeStatsReducer: Actions.GetMarketVolumeStatsRequests.reducer,
    DefaultFeesReducer: Actions.DefaultFees.reducer,
    CreateTicketReducer: Actions.CreateTicket.reducer,
    CryptoPricesReducer: Actions.CryptoPrices.reducer,
    RedeemVoucherReducer: Actions.RedeemVoucher.reducer,
    CurrencyXRatesReducer: Actions.GetCurrencyXRates.reducer,
    UserSanctionsStatusReducer: Actions.CheckUserSanctionStatus.reducer,
    UserSanctionsStatusData: Actions.CheckUserSanctionStatus.reducer,
    GetWithdrawChainsReducer: Actions.getWithdrawChains.reducer,
    GetDepositChainInfoReducer: Actions.getDepositChainInfo.reducer,
    OrderStopListReducer: Actions.OrderStopList.reducer,
    SepaWithdrawIdsReducer: Actions.GetSepaWithdrawIds.reducer,
    UserMarketsSettings: Actions.getUserMarketsSettings.reducer,
    MarketPricesReducer,
    SelectedOrderReducer,
    WsReducer,
    ThemeReducer,
    LngReducer,
    WsWalletReducer,
    WsTickersReducer,
    WsRecentTradesReducer,
    WsOhlcvReducer,
    WsMarketStatsReducer,
    ApiAccessKeyPopupReducer,
    SessionReducer,
    ApiKeyStateReducer,
    PopupReducer,
    ActiveMarketReducer,
    OrderbookReducer,
    ApplicationInitReducer,
    NotificationReducer,
    SanctionCheckReducer,
  }),
  composeEnhancers(applyMiddleware(...middleware))
));

handleMobileRedirect();

store.dispatch({type: APPLICATION_STARTED});

export default store;
