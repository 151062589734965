import I18n from '../../../cmp/helpers/I18n';
import Loader from '../../../cmp/Loader';

class WidgetContainer extends React.Component {
  render() {
    const {onClose, isWidgetLoading, isError, children} = this.props;

    return (
      <div className='widget-container'>
        <div className='widget-content'>
          <Loader open={isWidgetLoading} />
          <button type='button' className='btn close' onClick={onClose} />
          {children}
          {isError && <p className='exchange-error'>Something goes wrong. Please try later.</p>}
        </div>
        <button type='button' className='btn btn-primary' onClick={onClose}>
          <I18n tKey='common.cancel' />
        </button>
      </div>
    );
  }
}

export default WidgetContainer;
