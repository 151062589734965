import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import React, {Fragment} from 'react';

import OrderbookItem from './item';

import {SET_ORDERBOOK_PRECISION} from '../../../../reducers/OrderbookReducer';
import {SELECT_ORDER} from '../../../../reducers/SelectedOrderReducer';

import {ReactComponent as Minus} from '../../../../../../assets/images/icon/minus.svg';
import {ReactComponent as Plus} from '../../../../../../assets/images/icon/plus.svg';

import {ORDER_MODE} from '../../../../cmp/modal/ModalOrderBook';
import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import I18n from '../../../../cmp/helpers/I18n';

import {
  changeLocalSettings,
  createOrderBoxSettingObj,
  returnLocalSettings,
  settingNames,
} from '../../../../utils/utils';
import {translate} from '../../../../utils/i18n';
import Currency from '../../../../utils/currency';

class OrderBook extends React.PureComponent {
  constructor() {
    super();
    const returnOption = (label, name, propName, unitCode) => ({label: translate(label), name, propName, unitCode});
    const savedSettings = returnLocalSettings(settingNames.orderBook);
    const boxes = {};
    savedSettings.boxes.forEach(
      elem => (boxes[elem.boxName] = returnOption(elem.title, elem.name, elem.propName, elem.unitCode))
    );
    this.state = {
      modalIsOpen: false,
      boxes,
      orderMode: savedSettings.orderMode,
      radio: savedSettings.radio,
    };
  }

  componentDidUpdate() {
    setTimeout(this.scrollSellList, 100); //todo
  }

  scrollSellList() {
    $('.orderbook_body-value_down').scrollTop(100000);
  }
  toggleModal = () => {
    this.setState({modalIsOpen: !this.state.modalIsOpen});
  };

  changeState = (propName, value) => {
    changeLocalSettings(settingNames.orderBook, value, propName);
    this.setState({[propName]: value});
  };

  saveBoxSettingsLocal = boxOptions => {
    let boxes = Object.keys(boxOptions).map(key => {
      if (boxOptions[key]) {
        const option = boxOptions[key];
        return createOrderBoxSettingObj(key, option.label, option.name, option.propName, option.unitCode);
      }
    });
    boxes = boxes.filter(elem => elem);
    changeLocalSettings(settingNames.orderBook, boxes, 'boxes');
  };

  changeBox = obj => {
    let newState;
    if (this.state.boxes[obj.name]) {
      newState = {...this.state.boxes, [obj.name]: null};
    } else {
      newState = {...this.state.boxes, [obj.name]: obj};
    }
    this.saveBoxSettingsLocal(newState);
    this.setState({boxes: newState});
  };

  selectOrder = (price, amount) => {
    this.props.selectOrder(price, amount);
  };

  render() {
    const {boxes} = this.state;
    const activeMarket = this.props.ActiveMarketReducer;
    const orderbook = this.props.OrderbookReducer;
    const tickers = this.props.WsTickersReducer.tickers || {};
    const lastPrice = tickers.next[activeMarket.identificatSymbol];
    const change = tickers.changes[activeMarket.identificatSymbol];
    let orderbookSell = orderbook.sell.slice().reverse();

    let prevSellOrder = false;
    let prevBuyOrder = false;

    const delimiter = (
      <div
        style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', wordBreak: 'break-word'}}
        className='orderbook_body-value-item-stroke'
      >
        {!!orderbook.spread && (
          <Fragment>
            <div className='orderbook_body-value-item-title'>
              <span>{Currency.show(lastPrice, activeMarket.rightMonetaryUnitCode)}</span>
              {(!!change || change === 0) && <i className={`icon icon-arrow-${change < 0 ? 'bottom' : 'top'}`} />}
            </div>
            <div className='orderbook_body-value-item-text orderbook_spread'>
              <span>Spread</span> {Currency.showWithSign(orderbook.spread, activeMarket.rightMonetaryUnitCode)}
            </div>
          </Fragment>
        )}
      </div>
    );
    return (
      <section className='orderbook'>
        <div className='orderbook_body'>
          <div className='orderbook_body-aggregation'>
            <div className='orderbook_body-aggregation-info-cur'>{activeMarket.mBanner}</div>

            <div className='orderbook_body-aggregation-info'>
              <div className='orderbook_body-aggregation-info-title'>
                <I18n tKey='order_book.aggreg' />
                &nbsp;{orderbook.precision}
              </div>
              <div className='box-control-button'>
                {orderbook.hasLessPrecision && (
                  <a
                    onClick={() =>
                      orderbook.hasLessPrecision && this.props.changePrecision(-1, activeMarket.identificatSymbol)
                    }
                  >
                    <SvgWrapper themeWatch className='icon icon-min ic-size-07' SvgCmp={Minus} />
                  </a>
                )}
                {orderbook.hasMorePrecision && (
                  <a
                    onClick={() =>
                      orderbook.hasMorePrecision && this.props.changePrecision(1, activeMarket.identificatSymbol)
                    }
                  >
                    <SvgWrapper themeWatch className='icon icon-max ic-size-07' SvgCmp={Plus} />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className='orderbook_body-value-info'>
            {Object.keys(boxes).map((key, i) => {
              let currency = null;
              const option = boxes[key];
              if (!option) {
                return null;
              }
              if (option.unitCode) {
                currency =
                  option.unitCode === 'left' ? activeMarket.leftMonetaryUnitCode : activeMarket.rightMonetaryUnitCode;
              }
              return (
                <div key={i} className='orderbook_body-value-info-pair'>
                  <I18n tKey={option.label} /> {currency}
                </div>
              );
            })}
          </div>
          {this.state.orderMode === ORDER_MODE.ROW && delimiter}
          <div className={`orderbook_body-value ${this.state.orderMode === ORDER_MODE.ROW ? 'row' : ''}`}>
            <div className='orderbook_body-value_down'>
              {(orderbookSell || []).map((order, i) => {
                const result = (
                  <OrderbookItem
                    boxes={boxes}
                    selectOrder={this.selectOrder}
                    key={i}
                    isDown={true}
                    order={order}
                    prevOrder={prevSellOrder}
                    activeMarket={activeMarket}
                  />
                );
                prevSellOrder = order;
                return result;
              })}
            </div>
            <div>{this.state.orderMode === ORDER_MODE.COLUMN && delimiter}</div>
            <div className='orderbook_body-value_top'>
              {orderbook.buy.map((order, i) => {
                const result = (
                  <OrderbookItem
                    boxes={boxes}
                    selectOrder={this.selectOrder}
                    key={i}
                    isDown={false}
                    order={order}
                    prevOrder={prevBuyOrder}
                    activeMarket={activeMarket}
                  />
                );
                prevBuyOrder = order;
                return result;
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ActiveMarketReducer, OrderbookReducer, WsTickersReducer}) => {
  return {ActiveMarketReducer, OrderbookReducer, WsTickersReducer};
};

const mapDispatchToProps = dispatch => {
  return {
    changePrecision: (delta, ctcInstrIdentifier) =>
      dispatch({type: SET_ORDERBOOK_PRECISION, delta, ctcInstrIdentifier}),
    selectOrder: (price, amount) => dispatch({type: SELECT_ORDER, price, amount}),
    ...bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderBook);
