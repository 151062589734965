import * as React from 'react';
import {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import Recaptcha from 'react-recaptcha';

import Logo from '../../cmp/Logo';
import {LoginTwoFactorAuth, UserLogin} from '../../api/Actions';
import InputPopup from '../../cmp/popups/InputPopup';
import I18n from '../../cmp/helpers/I18n';
import {URLMAP} from '../../utils/const';
import connect from './connect';

const SAVED_EMAIL = 'SAVED_EMAIL';

type Props = {
  login: UserLogin.action,
  login2fa: LoginTwoFactorAuth.action,
};

class Login extends React.PureComponent<Props> {
  state = {
    email: localStorage.getItem(SAVED_EMAIL) || '',
    password: '',
    twoFA: '',
    isFromConfirmation: false,
  };

  onKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.login();
    }
  };

  onKeyDown2FA = (token, twoFactorVector, event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.login2FA(token, twoFactorVector);
    }
  };

  validateEmail = val => {
    return val && val.length >= 3 && val.indexOf('@') >= 0;
  };

  validatePassword = val => {
    return val && val.length > 3;
  };

  validate2FA = val => {
    return val && val.length === 6;
  };

  login2FA = (token, twoFactorVector) => {
    this.props.cleanEmailResendErr();
    if (!this.validate2FA(this.state.twoFA)) {
      this.setState({
        twoFaError: {parsedError: 'popup.valid_2fa'},
      });
    } else {
      this.setState({
        twoFaError: null,
      });
      this.props.login2FA({code: this.state.twoFA, token, tfVec: twoFactorVector});
    }
  };

  login = () => {
    const {email, password} = this.state;
    this.props.cleanEmailResendErr();

    if (!this.validatePassword(password) || !this.validateEmail(email)) {
      this.setState({
        passwordError: !this.validatePassword(password) ? {parsedError: 'popup.valid_pswd'} : '',
        emailError: !this.validateEmail(email) ? {parsedError: 'popup.valid_email'} : '',
      });
    } else {
      this.setState({
        passwordError: null,
        emailError: null,
      });
      const data = {electronicPostAddr: email.trim(), lockPickSymbolRow: password};
      //todo recaptcha
      const recaptcha =
        this.props.ApplicationSettingsReducer.data && this.props.ApplicationSettingsReducer.data.recaptcha;
      if (recaptcha && recaptcha.enabled) {
        data.captchaResponseToken = $('.g-recaptcha-response:first').val();
      }
      this.props.login(data).then(user => {
        if (user && user.data && user.data.followingLevel === 'NO') {
          this.props.closePopup();
        }
      });
      this.resetRecaptcha();
      if ($('#remember').prop('checked')) {
        localStorage.setItem(SAVED_EMAIL, email);
      } else {
        localStorage.setItem(SAVED_EMAIL, '');
      }
    }
  };

  componentDidMount() {
    this.props.loginClear();
    this.props.login2faClear();

    const confirmedEmail = this.props.location && this.props.location.state && this.props.location.state.confirmedEmail;
    if (!!confirmedEmail) {
      this.setState({
        email: this.props.location.state.confirmedEmail,
        isFromConfirmation: true,
      });
    }
  }

  recaptchaInstance = null;

  resetRecaptcha = () => {
    if (this.recaptchaInstance) {
      this.recaptchaInstance.reset();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.user && nextProps.user && nextProps.user.uuid) {
      if (this.props.history.location.pathname === URLMAP.LOGIN) {
        this.props.history.push(URLMAP.TERMINAL);
      }
      this.resetRecaptcha();
    }
  }

  resendMail = () => {
    const {UserLoginReducer, cleanEmailResendErr, resendConfirmationEmail} = this.props;
    cleanEmailResendErr();
    resendConfirmationEmail({
      electronicPostAddr:
        UserLoginReducer.data.masterPersonality && UserLoginReducer.data.masterPersonality.electronicMailVal,
    });
  };

  onPressEnter = event => {
    if (event.key === 'Enter') {
      this.login();
    }
  };

  openSignUpPage = () => {
    this.props.history.push(URLMAP.SIGNUP);
  };

  render() {
    const {
      SessionReducer,
      ResendConfirmationEmailReducer,
      LoginTwoFactorAuthReducer,
      UserLoginReducer,
      ApplicationSettingsReducer,
    } = this.props;
    const recaptcha = ApplicationSettingsReducer.data ? ApplicationSettingsReducer.data.recaptcha || {} : {};
    // console.log(recaptcha)
    let nextStep = UserLoginReducer.data.followingLevel;
    if (nextStep === 'NO') {
      nextStep = null;
    }

    let needEmailVerification = false;
    if (nextStep === 'EMAIL_CONFIRM_REQUIRED') {
      nextStep = null;
      needEmailVerification = true;
    }

    let error = !nextStep ? UserLoginReducer.error : LoginTwoFactorAuthReducer.error;
    const fetching = !nextStep ? UserLoginReducer.fetching : LoginTwoFactorAuthReducer.fetching;

    //console.log(SessionReducer.user && SessionReducer.user.uuid, nextStep)
    if (
      SessionReducer.masterPersonality &&
      SessionReducer.masterPersonality.uniqueSymbolRow &&
      (!nextStep || (LoginTwoFactorAuthReducer.data && LoginTwoFactorAuthReducer.data.followingLevel === 'NO'))
    ) {
      this.props.closePopup();
      return null;
    }
    return (
      <div className='login_page'>
        <div className='login_page__content'>
          <div className='login_page__header'>
            <Logo isMonoColor className='logo' />
          </div>

          <div className='modal-signup_form'>
            {!nextStep && (
              <h2>
                <I18n tKey='login-form:title' />
              </h2>
            )}

            {nextStep && (
              <h2>
                <I18n tKey='login_form:2fa:note' />
              </h2>
            )}

            <div className='ui-form'>
              <div className='ui-form-text text-center'>
                {!nextStep && (
                  <Fragment>
                    <I18n tkey='login-form:note' />
                    <a onClick={this.openSignUpPage}>
                      <I18n tkey='login-form:sing-up' />
                    </a>
                  </Fragment>
                )}

                {nextStep && (
                  <Fragment>
                    <I18n tKey='login_form:2fa:title' />
                  </Fragment>
                )}
              </div>

              {!nextStep && (
                <div>
                  <InputPopup
                    error={this.state.emailError}
                    placeholder='login-form:email'
                    onKeyDown={this.onKeyDown}
                    value={this.state.email}
                    onChange={value => this.setState({email: value.trim()})}
                    onPressEnter={this.onPressEnter}
                  />
                  <InputPopup
                    error={this.state.passwordError}
                    placeholder='login-form:password'
                    onKeyDown={this.onKeyDown}
                    value={this.state.password}
                    onChange={value => this.setState({password: value})}
                    className='ui-form-control-secure'
                    onPressEnter={this.onPressEnter}
                    type='password'
                    showPasswordActive
                  />
                  <div className='ui-form-group'>
                    <div className='ui-form-group-line'>
                      <div className='ui-form-checkbox'>
                        <input type='checkbox' id='remember' defaultChecked={!!localStorage.getItem(SAVED_EMAIL)} />
                        <label htmlFor='remember'>
                          <I18n tKey='login-form:remember' />
                        </label>
                      </div>
                      <div className='ui-form-link'>
                        <a onClick={this.props.openForgotPasswordPopup}>
                          <I18n tKey='common:forgot_pswd' />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {nextStep && (
                <div>
                  <InputPopup
                    useMaskPassword={false}
                    error={error || this.state.twoFaError}
                    label='login_form:2fa:code'
                    placeholder='login_form:2fa:code_placeholder'
                    onKeyDown={this.onKeyDown2FA.bind(this, UserLoginReducer.data.specialMark, nextStep.toLowerCase())}
                    value={this.state.twoFA}
                    onChange={value => this.setState({twoFA: value})}
                    regEx={/^\d+$/}
                    maxValLength='6'
                  />
                </div>
              )}

              {!nextStep && recaptcha && recaptcha.enabled && recaptcha.publicKey && (
                <div className='ui-form-сaptcha'>
                  <Recaptcha ref={e => (this.recaptchaInstance = e)} sitekey={recaptcha.publicKey} />
                </div>
              )}

              <div className='ui-form-btn'>
                {!fetching && (
                  <button
                    type='button'
                    onClick={
                      !nextStep
                        ? this.login
                        : () => {
                            this.login2FA(UserLoginReducer.data.specialMark, nextStep.toLowerCase());
                          }
                    }
                    className='btn btn-md btn-primary btn-full'
                  >
                    <I18n tKey='common:login' />
                  </button>
                )}

                {fetching && (
                  <button disabled type='button' className='btn btn-md btn-primary btn-full'>
                    <I18n tKey='common:login' />
                  </button>
                )}
              </div>

              <div className='popup-error-message common sign-up-login-popup-error-message'>
                {error && !nextStep && (
                  <div>
                    {error.error === 'USER_SANCTIONS' ? (
                      <I18n tKey='error:sanctions' />
                    ) : (
                      error.parsedError || error.error
                    )}
                  </div>
                )}
              </div>

              {nextStep && (
                <div className='ui-form-text text-center'>
                  <br />
                  <a href='/page/faq/all' target='_blank'>
                    <I18n tKey='faq.lost_phone_question' />
                  </a>
                </div>
              )}

              {needEmailVerification && !ResendConfirmationEmailReducer.fetching && (
                <div className='common'>
                  {!ResendConfirmationEmailReducer.data.sendingFlag && (
                    <span>
                      <I18n tKey='login_form:2fa:email:confirm' />{' '}
                      <a onClick={this.resendMail}>
                        <I18n tKey='login_form:2fa:email:confirmation' />
                      </a>{' '}
                    </span>
                  )}

                  {ResendConfirmationEmailReducer.data.sendingFlag && (
                    <span>
                      <I18n tKey='login_form:2fa:email_sent' />
                    </span>
                  )}

                  {ResendConfirmationEmailReducer.error && <span>{ResendConfirmationEmailReducer.error.message}</span>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(Login));
