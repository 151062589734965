import React from 'react';
import {CheckBox} from './CheckBox';
import {Radio} from './Radio';
import {translate} from '../../utils/i18n';
import I18n from '../helpers/I18n';
import {SvgWrapper} from '../helpers/SvgWrapper';
import {ReactComponent as imgClose} from '../../../../assets/images/icon/close.svg';

const returnBoxes = dict => {
  const returnOption = (label, name, propName) => ({label: translate(label, dict), name, propName});
  return [
    {left: returnOption('common.market', 'market'), right: returnOption('common.amount', 'amount')},
    {left: returnOption('common.type', 'type'), right: returnOption('common.price', 'price')},
    {left: returnOption('common.status', 'status'), right: returnOption('common.total', 'total')},
    {left: returnOption('common.open_time', 'openTime'), right: null},
    // {left: returnOption("Order Id", "orderId"), right: returnOption("Target", "target")},
    // {left: returnOption("Account", "account"), right: returnOption("Actions", "actions")},
    // {left: returnOption("Fee", "fee"), right: returnOption("Open Time", "openTime"),},
  ];
};

export const marker_option = 'market';
export const side_option = 'side';

class ModalOrderSet extends React.Component {
  displayName: 'ModalOrderSet';

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.boxes = returnBoxes(props.dict);
  }

  close = () => {
    this.props.close();
  };

  changeBox = obj => {
    this.props.changeBox(obj);
  };

  changeState = (propName, value) => {
    this.props.changeState(propName, value);
  };

  render() {
    const {isShow} = this.props;
    return (
      <div className={isShow ? 'modal fade in' : 'modal fade'} style={{display: !this.state.isOpen ? 'none' : ''}}>
        <div className='modal-dialog modal-sm'>
          <div className='modal-content'>
            <div className='modal-content_header'>
              <I18n tKey='modal.open_orders' />
              {/*<a className="close" onClick={this.close}/>*/}
              <SvgWrapper className='close' onClick={this.close} SvgCmp={imgClose} themeWatch />
            </div>
            <div className='modal-content_body'>
              <div className='modal-content_title'>
                <I18n tKey='common.col_title' />
              </div>
              {this.boxes.map((elem, i) => (
                <div key={i} className='form-group-line'>
                  {elem.left && (
                    <CheckBox
                      onChange={this.changeBox.bind(this, elem.left)}
                      name={elem.left.name}
                      checked={this.props.boxes[elem.left.name] || false}
                      label={elem.left.label}
                    />
                  )}
                  {elem.right && (
                    <CheckBox
                      onChange={this.changeBox.bind(this, elem.right)}
                      name={elem.right.name}
                      checked={this.props.boxes[elem.right.name] || false}
                      label={elem.right.label}
                    />
                  )}
                </div>
              ))}
              <div className='modal-content_title'>
                <I18n tKey='common.radio_title' />
              </div>
              <Radio
                onChange={this.changeState.bind(this, 'radio', marker_option)}
                checked={this.props.radio === marker_option}
                label='modal.radio_market'
              />
              <Radio
                onChange={this.changeState.bind(this, 'radio', side_option)}
                checked={this.props.radio === side_option}
                label='modal.radio_side'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalOrderSet;
