import React, {Component, Fragment} from 'react';
import connect from './connect';

import I18n from '../../cmp/helpers/I18n';
import {displayDateFromObject, prefillFormState} from '../UserSanctionsForm/services';

class DashboardAccountInfo extends Component {
  constructor(props) {
    super(props);

    this.state = prefillFormState(this.props.SanctionCheckReducer.info);
  }

  render() {
    const {
      address,
      city,
      country,
      postalCode,
      dateOfBirth,
      documentCountryOfIssue,
      email,
      fName,
      gender,
      lName,
      phoneNumber,
    } = this.state;

    return (
      <Fragment>
        <div className='block-account-name'>
          <I18n tKey='dashboard:personal_data' />
        </div>

        <div className='block-account'>
          <div className='account__info-block'>
            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.first_name' />
              </div>
              <div className='account__info-block-value'>{fName.value}</div>
            </div>

            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.last_name' />
              </div>
              <div className='account__info-block-value'>{lName.value}</div>
            </div>

            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.date_of_birth' />
              </div>
              <div className='account__info-block-value'>{displayDateFromObject(dateOfBirth.value)}</div>
            </div>

            <div>
              <div className='account__info-block-header'>
                <I18n tKey='common.gender' />
              </div>
              <div className='account__info-block-value'>{gender.value[0]}</div>
            </div>

            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.email' />
              </div>
              <div className='account__info-block-value'>{email.value}</div>
            </div>

            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.phone_number' />
              </div>
              <div className='account__info-block-value'>{phoneNumber.value}</div>
            </div>
          </div>

          <div className='account__info-block'>
            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.document_issue' />
              </div>
              <div className='account__info-block-value'>{(documentCountryOfIssue.value || {}).label}</div>
            </div>
          </div>

          <div className='account__info-block'>
            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.document_issue' />
              </div>
              <div className='account__info-block-value'>{(country.value || {}).label}</div>
            </div>

            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.city' />
              </div>
              <div className='account__info-block-value'>{city.value}</div>
            </div>

            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.address' />
              </div>
              <div className='account__info-block-value'>{address.value}</div>
            </div>

            <div>
              <div className='account__info-block-header'>
                <I18n tKey='sanctions_form.postal_code' />
              </div>
              <div className='account__info-block-value'>{postalCode.value}</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default connect(DashboardAccountInfo);
