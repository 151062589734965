// @flow
import * as React from 'react';
import {Fragment} from 'react';
import {OPEN_POPUP} from '../../../reducers/PopupReducer';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import * as Actions from '../../../api/Actions';
import I18n from '../../helpers/I18n';
import {DISABLE_2FA_STEP2_POPUP} from './Disable2FAStep2Popup';

export const DISABLE_2FA_STEP1_POPUP = 'DISABLE_2FA_STEP1_POPUP';

class Enable2FAStep1Popup extends React.PureComponent<void, ComponentState> {
  state = {
    code: '',
    password: '',
  };

  componentWillReceiveProps(n) {
    if (n.RemoveTwoFactorAuthReducer && n.RemoveTwoFactorAuthReducer.completed) {
      this.props.nextStep();
    }
  }

  sendEmail = () => {
    this.props.remove({code: this.state.code, password: this.state.password, tfVec: 'google'});
  };

  onPressEnter = event => {
    if (event.key === 'Enter') {
      this.sendEmail();
    }
  };

  render() {
    const {RemoveTwoFactorAuthReducer} = this.props;
    return (
      <Fragment>
        <div className='modal-2FA-form text-center'>
          <h2>
            <I18n tKey='popup.disable_2fa' />
          </h2>
          <div className='modal-2FA-form-text'>
            <I18n tKey='popup.type_2fa' />
          </div>

          <form className='ui-form'>
            <div className='ui-form-input focus'>
              <input
                type='password'
                value={this.state.password}
                onChange={e => this.setState({password: e.target.value})}
                className='ui-form-control ui-form-control-secure'
                onKeyPress={this.onPressEnter}
              />
              <div className='ui-form-control-focus' />
              <label className='ui-form-control-placeholder'>
                <I18n tKey='common.password_2fa_disabling' />
              </label>
            </div>
            <div className='ui-form-input focus'>
              <input
                type='text'
                value={this.state.code}
                onChange={e => this.setState({code: e.target.value})}
                className='ui-form-control ui-form-control-secure'
                onKeyPress={this.onPressEnter}
              />
              <div className='ui-form-control-focus' />
              <label className='ui-form-control-placeholder'>
                <I18n tKey='common.2fa_code' />
              </label>
            </div>
            {!RemoveTwoFactorAuthReducer.fetching && (
              <a className='btn btn-primary btn-lg' onClick={this.sendEmail}>
                <I18n tKey='common.disable' />
              </a>
            )}
            {RemoveTwoFactorAuthReducer.fetching && <I18n tKey='common.loading' />}
            {RemoveTwoFactorAuthReducer.error && (
              <div className='error'>
                {RemoveTwoFactorAuthReducer.error.parsedError || RemoveTwoFactorAuthReducer.error.error}
              </div>
            )}
            <div className='modal-text-bottom'>
              <a href='/page/faq/all' target='_blank'>
                <I18n tKey='popup.lost_device' />
              </a>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({RemoveTwoFactorAuthReducer}) => ({RemoveTwoFactorAuthReducer});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        remove: Actions.RemoveTwoFactorAuth.action,
      },
      dispatch
    ),
    nextStep: () => dispatch({type: OPEN_POPUP, name: DISABLE_2FA_STEP2_POPUP}),
  };
};
const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Enable2FAStep1Popup));

export default connectedWithRouter;
