import React, {Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as Actions from '../../api/Actions';
import Formatter from '../../utils/formatter';
import Currency from '../../utils/currency';
import Paging from '../../cmp/Paging';
import DropdownFilter from '../../cmp/DropdownFilter';
import DateRangeFilter from '../../cmp/DateRangeFilter';
import type {MarketTO, OrderTO} from '../../api/types/models';
import I18n from '../../cmp/helpers/I18n';
import {CurrencyView} from '../../cmp/helpers/CurrencyView';
import {downloadRequest} from './downloadsUserInfo/utils';
import OrderHelper from '../Trading/components/TradingAndHistory/OrderHelper';

class TradingHistory extends React.PureComponent {
  state = {
    orderType: null,
    side: null,
    marketId: null,
    dateFrom: null,
    dateTo: null,
    limit: null,
    offset: null,
    isDownloading: false,
    isDownloadError: false,
  };

  changePage = (limit, offset) => {
    this.setState({limit, offset}, this.search);
  };

  changeType = orderType => {
    this.setState({orderType}, this.search);
  };

  changePair = title => {
    if (!title) {
      this.setState({marketId: null}, this.search);
      return;
    }
    const markets = this.props.MarketListReducer.list || [];
    const market: MarketTO = markets.filter(m => m.mBanner === title)[0];
    if (market) {
      this.setState({marketId: market.identificatSymbol}, this.search);
    }
  };

  changeSide = side => {
    this.setState({side}, this.search);
  };

  changeDateRange = ({dateFrom, dateTo}) => {
    this.setState({dateFrom, dateTo}, this.search);
  };

  search = () => {
    const {marketId, limit, offset, orderType, side, dateFrom, dateTo} = this.state;
    const params = {
      leftD: dateFrom,
      rightD: dateTo,
      rng: limit,
      listOfst: offset,
      odrCurrentSide: side,
      odrCurrentT: orderType,
      ctcInstrList: marketId,
    };
    this.props.getOrderHistory(null, params);
  };

  onDownloadClick = () => {
    const {marketId, orderType, side, dateFrom, dateTo} = this.state;
    const params = {};
    if (!!marketId) {
      params.ctcInstrList = marketId;
    }
    if (!!orderType) {
      params.odrCurrentT = orderType;
    }
    if (!!side) {
      params.odrCurrentSide = side;
    }
    if (!!dateFrom) {
      params.leftD = dateFrom;
    }
    if (!!dateTo) {
      params.rightD = dateTo;
    }
    let url = '/export/orders';
    if (Object.keys(params).length) {
      url += '?' + new URLSearchParams(params).toString();
    }
    this.setState({isDownloading: true, isDownloadError: false});
    downloadRequest(url, 'orders').then(r => {
      this.setState({isDownloading: false, isDownloadError: r.isError});
    });
  };

  renderOrderTotal(o, market) {
    if (!o.moneyResult) {
      return '0';
    }
    const moneyString = o.moneyResult + ' ' + market.rightMonetaryUnitCode;
    return (o.currentS === 'BUY' ? '' : '+') + moneyString;
  }

  render() {
    const {MarketListReducer, OrderHistoryReducer} = this.props;
    const history = OrderHistoryReducer.list || [];
    const markets = MarketListReducer.list || [];
    let pairFilters = {};

    history.forEach(o => {
      const market = markets.filter(m => m.identificatSymbol === o.ctcInstrIdentifier)[0];
      if (market) {
        pairFilters[o.ctcInstrIdentifier] = market.mBanner;
      }
    });

    let filtered = history;

    const pairs = markets.map((m: MarketTO) => m.mBanner);
    const types = ['LIMIT', 'MARKET'];
    const sides = ['BUY', 'SELL'];

    return (
      <Fragment>
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <I18n tKey='common.link_history' />
          </div>
          <div className='tab-pane-filter'>
            <DropdownFilter title='common.side' elements={sides} onChange={this.changeSide} />
            <DropdownFilter title='common.pair' elements={pairs} onChange={this.changePair} />
            <DropdownFilter title='common.order_type' elements={types} onChange={this.changeType} />
            <DateRangeFilter onChange={this.changeDateRange} />
          </div>
        </div>
        <Paging
          offset={this.state.offset}
          limit={this.state.limit}
          totalElements={OrderHistoryReducer.totalElements}
          change={this.changePage}
          loadOnInit={true}
        />
        <div className='tab-pane-body'>
          <table className='table-orders'>
            <thead className='table-orders-header'>
              <tr>
                <th>
                  <I18n tKey='orders.col_id' />
                </th>
                <th className='text-center'>
                  <I18n tKey='common.pair' />
                </th>
                <th className='text-center'>
                  <I18n tKey='orders.col_order' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.amount' />
                </th>
                {/*<th className="text-right">Amount matched</th>*/}
                <th className='text-right'>
                  <I18n tKey='common.price' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.filled' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.fee' />
                </th>
                <th className='text-center'>
                  <I18n tKey='common.total' />
                </th>
                <th className='text-center'>
                  <I18n tKey='common.status' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.time_stamp' />
                </th>
              </tr>
            </thead>
            <tbody className='table-orders-body'>
              {OrderHistoryReducer.fetching && (
                <tr className='tr text-center'>
                  <td colSpan={20}>Loading...</td>
                </tr>
              )}

              {!OrderHistoryReducer.fetching && filtered.length === 0 && (
                <tr className='tr text-center'>
                  <td colSpan={20}>
                    <I18n tKey='trade_history:no_orders' />
                  </td>
                </tr>
              )}

              {!OrderHistoryReducer.fetching &&
                filtered.length > 0 &&
                filtered.map((o: OrderTO) => {
                  const amountMatched = o.originalActualQty - o.actualQty,
                    market: MarketTO = markets.filter(m => m.identificatSymbol === o.ctcInstrIdentifier)[0],
                    isMarketO = o.currentT.toLowerCase() === 'market',
                    isSell = o.currentS.toLowerCase() === 'sell';
                  if (!market) return null;

                  if (
                    o.closeWhyString === 'TOO_LOW_AMOUNT' ||
                    o.closeWhyString === 'TOO_LOW_MATCH' ||
                    o.closeWhyString === 'EMPTY_AMOUNT' ||
                    o.closeWhyString === 'MARKET_ORDER_NOT_FILLED' ||
                    o.closeWhyString === 'PENDING_CANCEL'
                  ) {
                    return null;
                  }

                  return (
                    <tr key={o.identificatSymbol}>
                      <td>{o.identificatSymbol}</td>
                      <td className='text-center'>{market.mBanner}</td>
                      <td className={'text-center ' + (isSell ? 'red' : 'green')}>
                        {Formatter.capFirst(o.currentT) + ' ' + Formatter.capFirst(o.currentS)}
                      </td>
                      <td className='text-right'>
                        <CurrencyView
                          amount={o.originalActualQty}
                          type={market.leftMonetaryUnitCode}
                          appearance={market.currentT}
                        />
                      </td>
                      <td className='text-right'>
                        {isMarketO ? 'Market' : Currency.show(o.odrValue, market.rightMonetaryUnitCode, true)}
                      </td>
                      <td className='text-right'>
                        <CurrencyView
                          amount={amountMatched}
                          type={market.leftMonetaryUnitCode}
                          appearance={market.currentT}
                        />
                      </td>
                      <td className='text-right'>
                        {!!o.feeQtyVal ? Currency.show(o.feeQtyVal, market.rightMonetaryUnitCode, true) : '0'}
                      </td>
                      <td className='text-right'>{this.renderOrderTotal(o, market)}</td>
                      <td className='text-center'>{OrderHelper.closeReason(o.closeWhyString)}</td>
                      <td className='text-right'>{Formatter.dateTimeShort(o.crTime)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Paging
            isDownloadError={this.state.isDownloadError}
            isDownloading={this.state.isDownloading}
            onDownloadClick={this.onDownloadClick}
            offset={this.state.offset}
            limit={this.state.limit}
            totalElements={OrderHistoryReducer.totalElements}
            change={this.changePage}
            loadOnInit={false}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({MarketListReducer, OrderHistoryReducer}) => ({MarketListReducer, OrderHistoryReducer});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getOrderHistory: Actions.OrderHistory.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(TradingHistory));

export default connectedWithRouter;
