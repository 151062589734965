import React, {Fragment} from 'react';

import I18n from '../../cmp/helpers/I18n';

const AssetDetailItem = ({label, value}) => {
  return (
    <Fragment>
      <div className='asset-info-item label'>
        <I18n tKey={label} />
      </div>
      <div className='asset-info-item'>{value}</div>
    </Fragment>
  );
};

export default AssetDetailItem;
