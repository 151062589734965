import React from 'react';
import debounce from 'lodash.debounce';
import classNames from 'classnames';

import {Chart, Markets, OrderBook, TradingAndHistory, RecentTrades, CellHeader} from './components';

const SCREEN_SIZE_TRIGGER = 1870;
const TAB_ORDER_BOOK = 'ORDER_BOOK';
const TAB_RECENT_TRADES = 'RECENT_TRADES';

class Trading extends React.PureComponent {
  state = {
    selectedTab: TAB_ORDER_BOOK,
    isNarrowView: window.innerWidth <= SCREEN_SIZE_TRIGGER,
  };

  handleResize = debounce(() => {
    const isNarrowView = window.innerWidth <= SCREEN_SIZE_TRIGGER;

    this.setState({isNarrowView, selectedTab: TAB_ORDER_BOOK});
  }, 300);

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getHeaderClickHandler = tabName => {
    return () => {
      this.setState({selectedTab: tabName});
    };
  };

  render() {
    const isRecentTradesTabShown = this.state.isNarrowView && this.state.selectedTab === TAB_RECENT_TRADES;

    return (
      <div className={classNames('grid-layout', {['narrow']: this.state.isNarrowView})}>
        <div className='markets-block content-wrapper overflow-h'>
          <CellHeader label='layout.market_over' />
          <Markets />
        </div>

        <Chart />

        <div className='content-wrapper order-book'>
          <div className={classNames('tabs-header', {['with-bg']: this.state.isNarrowView})}>
            <CellHeader
              className={classNames({['active']: this.state.selectedTab === TAB_ORDER_BOOK})}
              onClick={this.getHeaderClickHandler(TAB_ORDER_BOOK)}
              label='layout.orderbook'
            />

            {this.state.isNarrowView && (
              <CellHeader
                className={classNames({['active']: this.state.selectedTab === TAB_RECENT_TRADES})}
                onClick={this.getHeaderClickHandler(TAB_RECENT_TRADES)}
                label='layout.recent_trades'
              />
            )}
          </div>
          {this.state.selectedTab === TAB_ORDER_BOOK && <OrderBook />}

          {isRecentTradesTabShown && <RecentTrades />}
        </div>

        <TradingAndHistory />

        {!this.state.isNarrowView && <RecentTrades />}
      </div>
    );
  }
}

export default Trading;
