import {translate} from '../../../../utils/i18n';

export default class Order {
  constructor(order) {
    for (let prop in order) {
      this[prop] = order[prop];
    }

    this.createdAt = Math.round(this.crTime / 1000);
    this.isSell = this.currentS === 'SELL';
    this.total = this.originalActualQty * this.odrValue;
    this.left = this.actualQty;
    this.execPercent = this.originalActualQty === this.actualQty ? 0 : (this.left / this.originalActualQty) * 100;
  }

  static closeReason(reason) {
    switch (reason) {
      case 'COMPLETED':
        return translate('common.filled');
      case 'CANCELLED_BY_OWNER':
        return translate('common.canceled');
      case 'MARKET_DISABLED':
      case 'CANCELLED_BY_ADMIN':
        return translate('common.rejected');
      case 'TOO_LOW_AMOUNT':
      case 'TOO_LOW_MATCH':
      case 'EMPTY_AMOUNT':
      case 'MARKET_ORDER_NOT_FILLED':
      case 'PENDING_CANCEL':
        return '';
      case 'PARTIALLY_FILLED':
        return translate('order_book.part_fill');
      case 'TTL_EXPIRED':
        return translate('order_book.expired');
      case 'UNKNOWN_CLOSE_REASON':
        return translate('common.unknown');
      case 'ERROR':
        return translate('common.unknown_er');
    }
    return '???';
  }
}
