import React, {Component} from 'react';
import {ReactComponent as Plus} from '../../../assets/images/plus.svg';
import {ReactComponent as Minus} from '../../../assets/images/minus.svg';
import I18n from '../cmp/helpers/I18n';
import Enable2FaAnswer from './faq/Enable2FaAnswer';
import {connect} from 'react-redux';
import ProblemsActivating2FaAnswer from './faq/ProblemsActivating2FaAnswer';
import {Link} from 'react-router-dom';

class Faq extends Component {
  constructor(props) {
    super(props);
    const {lng} = this.props.LngReducer;

    const twoFaQuestions = [
      {
        id: 1,
        question: <I18n tKey='faq.what_2fa_question' />,
        answer: <I18n tKey='faq.what_2fa_answer' />,
        isExpanded: false,
      },
      {
        id: 2,
        question: <I18n tKey='faq.enable_2fa_question' />,
        answer: <Enable2FaAnswer />,
        isExpanded: false,
      },
    ];

    let twoFaId = 3;
    if (lng === 'ru' || lng === 'en') {
      twoFaQuestions.push({
        id: twoFaId,
        question: <I18n tKey='faq.issues_activating_2fa' />,
        answer: <ProblemsActivating2FaAnswer />,
        isExpanded: false,
      });
      ++twoFaId;
    }

    twoFaQuestions.push({
      id: twoFaId,
      question: <I18n tKey='faq.lost_phone_question' />,
      answer: <I18n tKey='faq.lost_phone_answer' />,
      isExpanded: false,
    });

    this.state = {
      activeSection: 1,
      activeSubSection: 1,
      activeSectionName: 'All questions about 2fa',
      DICT_KEYS: [
        {
          id: 1,
          active: true,
          data: twoFaQuestions,
        },
        {
          id: 2,
          active: false,
          data: [
            {
              id: 1,
              question: <I18n tKey='faq.verify_denied_question' />,
              answer: <I18n tKey='faq.verify_denied_answer' />,
              isExpanded: false,
            },
            {
              id: 2,
              question: <I18n tKey='faq.id_card_question' />,
              answer: <I18n tKey='faq.id_card_answer' />,
              isExpanded: false,
            },
            {
              id: 3,
              question: <I18n tKey='faq.correct_passport_question' />,
              answer: <I18n tKey='faq.correct_passport_answer' />,
              isExpanded: false,
            },
            // {
            //   id: 4,
            //   question: <I18n tKey='faq.trade_verified_question' />,
            //   answer: <I18n tKey='faq.trade_verified_answer' />,
            // },
            // {
            //   id: 5,
            //   question: <I18n tKey='faq.gpass_verification_question' />,
            //   answer: <I18n tKey='faq.gpass_verification_answer' />,
            // },
            // {
            //   id: 6,
            //   question: <I18n tKey='faq.rejection_verification_question' />,
            //   answer: <I18n tKey='faq.rejection_verification_answer' />,
            // },
            // {
            //   id: 7,
            //   question: <I18n tKey='faq.gup_verified_question' />,
            //   answer: <I18n tKey='faq.gup_verified_answer' />,
            // },
          ],
        },
        {
          id: 3,
          active: false,
          data: [
            {
              id: 1,
              question: <I18n tKey='faq.reset_password_question' />,
              answer: <I18n tKey='faq.reset_password_answer' />,
              isExpanded: false,
            },
            {
              id: 2,
              question: <I18n tKey='faq.forgotten_password_question' />,
              answer: <I18n tKey='faq.forgotten_password_answer' />,
              isExpanded: false,
            },
          ],
        },

        {
          id: 4,
          active: false,
          data: [
            {
              id: 1,
              tableColumns: [
                <I18n tKey='faqtable.payment_plan' />,
                <I18n tKey='faqtable.basic_unverified' />,
                <I18n tKey='faqtable.personal_verified' />,
              ],
              tableData: [
                {
                  firstEntry: <I18n tKey='faqtable.minimum_withdrawal' />,
                  secondEntry: '1 - 50',
                  thirdEntry: '1 - 50',
                },
                {
                  firstEntry: <I18n tKey='faqtable.max_withdrawal' />,
                  secondEntry: 5000,
                  thirdEntry: 10000,
                },
                {
                  firstEntry: <I18n tKey='faqtable.max_withdrawal_day' />,
                  secondEntry: 10000,
                  thirdEntry: 30000,
                },
                {
                  firstEntry: <I18n tKey='faqtable.max_withdrawal_month' />,
                  secondEntry: 50000,
                  thirdEntry: 1000000,
                },
                {
                  firstEntry: <I18n tKey='faqtable.tx_max' />,
                  secondEntry: <I18n tKey='faqtable.no_limits' />,
                  thirdEntry: <I18n tKey='faqtable.no_limits' />,
                },
                {
                  firstEntry: <I18n tKey='faqtable.max_daily' />,
                  secondEntry: <I18n tKey='faqtable.no_limits' />,
                  thirdEntry: <I18n tKey='faqtable.no_limits' />,
                },
                {
                  firstEntry: <I18n tKey='faqtable.max_monthly' />,
                  secondEntry: <I18n tKey='faqtable.no_limits' />,
                  thirdEntry: <I18n tKey='faqtable.no_limits' />,
                },
              ],
              question: <I18n tKey='faqtable.exchange_limits' />,
              answer: <I18n tKey='faqtable.more_than_fifteen' />,
              isExpanded: false,
            },

            {
              id: 2,
              question: <I18n tKey='faq.limits_small_question' />,
              answer: <I18n tKey='faq.limits_small_answer' />,
              isExpanded: false,
            },
            {
              id: 3,
              question: <I18n tKey='faq.deposit_request_question' />,
              answer: <I18n tKey='faq.deposit_request_answer' />,
              isExpanded: false,
            },
            {
              id: 4,
              question: <I18n tKey='faq.funds_origin_question' />,
              answer: <I18n tKey='faq.funds_origin_answer' />,
              isExpanded: false,
            },
            {
              id: 5,
              question: <I18n tKey='faq.trading_fees_question' />,
              answer: <I18n tKey='faq.trading_fees_answer' />,
              isExpanded: false,

              tableColumns: [
                <I18n tKey='faqtable.trading_volume' />,
                <I18n tKey='faqtable.trading_comission' />,
                <I18n tKey='faqtable.maker' />,
                <I18n tKey='faqtable.taker' />,
              ],
              feesTable: true,
              tableData: [
                {
                  firstEntry: '0 - 10 000',
                  secondEntry: '0.4%',
                  thirdEntry: '0.5%',
                },
                {
                  firstEntry: '10 001 – 50 000',
                  secondEntry: '0.3%',
                  thirdEntry: '0.4%',
                },
                {
                  firstEntry: '50 001 – 100 000',
                  secondEntry: '0.2%',
                  thirdEntry: '0.3%',
                },
                {
                  firstEntry: '100 001 – 250 000',
                  secondEntry: '0.15%',
                  thirdEntry: '0.25%',
                },
                {
                  firstEntry: '250 001– 500 000',
                  secondEntry: '0.1%',
                  thirdEntry: '0.2%',
                },
                {
                  firstEntry: '500 001 – 1 000 000',
                  secondEntry: '0.08%',
                  thirdEntry: '0.18%',
                },
                {
                  firstEntry: '1 000 001 - 2 500 000',
                  secondEntry: '0.06%',
                  thirdEntry: '0.16%',
                },
                {
                  firstEntry: '2 500 001 +',
                  secondEntry: '0.04%',
                  thirdEntry: '0.14%',
                },
              ],
            },
            // {
            //   id: 6,
            //   tableColumns: [
            //     <I18n tKey='faqtable.currency' />,
            //     <I18n tKey='faqtable.deposit' />,
            //     <I18n tKey='faqtable.output' />,
            //   ],
            //   tableData: [
            //     {
            //       firstEntry: 'BTC Bitcoin',
            //       secondEntry: '0%',
            //       thirdEntry: '0.0005 BTC',
            //     },
            //     {
            //       firstEntry: 'ETH Etherium',
            //       secondEntry: '0%',
            //       thirdEntry: '0.01 ETH',
            //     },
            //     {
            //       firstEntry: 'EOS',
            //       secondEntry: '0%',
            //       thirdEntry: '1 EOS',
            //     },
            //     {
            //       firstEntry: 'КРИПТОЮНИТ  (UNTB)',
            //       secondEntry: '0%',
            //       thirdEntry: '10 UNTB',
            //     },
            //     {
            //       firstEntry: 'КРИПТОЮНИТ (USDU)',
            //       secondEntry: '0%',
            //       thirdEntry: '1 USDU',
            //     },
            //     {
            //       firstEntry: 'USD/EUR by VISA/MC',
            //       secondEntry: '7%',
            //       thirdEntry: <I18n tKey='faqtable.not_available_yet' />,
            //     },
            //     {
            //       firstEntry: 'USD/EUR by GlobalUnitPay Будет доступно в апреле 2021',
            //       secondEntry: '0%',
            //       thirdEntry: '3.5%',
            //     },
            //   ],
            //   question: <I18n tKey='faqtable.what_are_fees' />,

            //   isExpanded: false,
            // },
            {
              id: 7,
              question: <I18n tKey='faq.secure_transactions_question' />,
              answer: <I18n tKey='faq.secure_transactions_answer' />,
              isExpanded: false,
            },
            {
              id: 8,
              question: <I18n tKey='faq.my_application_question' />,
              answer: <I18n tKey='faq.my_application_answer' />,
              isExpanded: false,
            },
            {
              id: 9,
              question: <I18n tKey='faq.forgot_memo_question' />,
              answer: <I18n tKey='faq.forgot_memo_answer' />,
              isExpanded: false,
            },
            {
              id: 10,
              question: <I18n tKey='faq.crypto_wallet_question' />,
              answer: <I18n tKey='faq.crypto_wallet_answer' />,
              isExpanded: false,
            },
            {
              id: 11,
              question: <I18n tKey='faq.limit_order_question' />,
              answer: <I18n tKey='faq.limit_order_answer' />,
              isExpanded: false,
            },

            {
              id: 12,
              question: <I18n tKey='faq.cancel_order_question' />,
              answer: <I18n tKey='faq.cancel_order_answer' />,
              isExpanded: false,
            },
            {
              id: 13,
              question: <I18n tKey='faq.tx_suspended_question' />,
              answer: <I18n tKey='faq.tx_suspended_answer' />,
            },
            {
              id: 14,
              question: <I18n tKey='faq.get_funds_question' />,
              answer: <I18n tKey='faq.get_funds_answer' />,
            },
            {
              id: 15,
              question: <I18n tKey='faq.quantum_deposit_question' />,
              answer: <I18n tKey='faq.quantum_deposit_answer' />,
            },
            {
              id: 16,
              question: <I18n tKey='faq.incorrect_info_question' />,
              answer: <I18n tKey='faq.quantum_deposit_answer' />,
              isExpanded: false,
            },
            {
              id: 17,
              question: <I18n tKey='faq.proof_funds_request_question' />,
              answer: <I18n tKey='faq.proof_funds_request_answer' />,
              isExpanded: false,
            },
            // {
            //   id: 18,
            //   question: <I18n tKey='faq.after_reaching_question' />,
            //   answer: <I18n tKey='faq.after_reaching_answer' />,
            //   isExpanded: false,
            // },
            {
              id: 19,
              question: <I18n tKey='faq.proof_of_funds_question' />,
              answer: (
                <ul>
                  <li>
                    <I18n tKey='faq.proof_of_funds_answer1' />
                  </li>
                  <li>
                    <I18n tKey='faq.proof_of_funds_answer2' />
                  </li>
                  <li>
                    <I18n tKey='faq.proof_of_funds_answer3' />
                  </li>
                  <li>
                    <I18n tKey='faq.proof_of_funds_answer4' />
                  </li>
                  <li>
                    <I18n tKey='faq.proof_of_funds_answer5' />
                  </li>
                </ul>
              ),
              isExpanded: false,
            },
          ],
        },
        {
          id: 5,
          active: false,
          data: [
            {
              id: 1,
              question: <I18n tKey='faq.referral_link_question' />,
              answer: <I18n tKey='faq.referral_link_answer' />,
              isExpanded: false,
            },

            {
              id: 2,
              question: <I18n tKey='faq.account_deleting_question' />,
              answer: <I18n tKey='faq.quantum_deposit_answer' />,
              isExpanded: false,
            },
            {
              id: 3,
              question: <I18n tKey='faq.requested_documents_question' />,
              answer: <I18n tKey='faq.quantum_deposit_answer' />,
              isExpanded: false,
            },
            {
              id: 4,
              question: <I18n tKey='faq.accepted_format_question' />,
              answer: <I18n tKey='faq.accepted_format_answer' />,
              isExpanded: false,
            },
            // {
            //   id: 5,
            //   question: <I18n tKey='faq.cru_agreement_question' />,
            //   answer: <I18n tKey='faq.cru_agreement_answer' />,
            //   isExpanded: false,
            // },
            // {
            //   id: 6,
            //   question: <I18n tKey='faq.cryptounit_question' />,
            //   answer: <I18n tKey='faq.cryptounit_answer' />,
            //   isExpanded: false,
            // },
            {
              id: 7,
              question: <I18n tKey='faq.funds_origin_question' />,
              answer: <I18n tKey='faq.funds_origin_answer' />,
              isExpanded: false,
            },
            {
              id: 8,
              question: <I18n tKey='faq.document_review_question' />,
              answer: <I18n tKey='faq.document_review_answer' />,
              isExpanded: false,
            },
            // {
            //   id: 9,
            //   question: <I18n tKey='faq.buy_outside_question' />,
            //   answer: <I18n tKey='faq.buy_outside_answer' />,
            //   isExpanded: false,
            // },
            // {
            //   id: 10,
            //   question: <I18n tKey='faq.assist_partners_question' />,
            //   answer: <I18n tKey='faq.assist_partners_answer' />,
            //   isExpanded: false,
            // },
            // {
            //   id: 11,
            //   question: <I18n tKey='faq.p2p_question' />,
            //   answer: <I18n tKey='faq.p2p_answer' />,
            //   isExpanded: false,
            // },
          ],
        },
        {
          id: 6,
          active: false,
          data: [
            {
              id: 1,
              question: <I18n tKey='faq.about_unitex_question' />,
              answer: <I18n tKey='faq.about_unitex_answer' />,
              isExpanded: false,
            },

            {
              id: 2,
              question: <I18n tKey='faq.offers_question' />,
              answer: (
                <span>
                  <I18n tKey='faq.offers_answer' />
                </span>
              ),
              isExpanded: false,
            },
          ],
        },
      ],
      LEFT_KEYS: [
        {
          id: 6,
          category: <I18n tKey='faq.about_unitex' />,
          url: '/page/faq/about',
        },
        {
          id: 1,
          category: <I18n tKey='faq.about_2fa' />,
          url: '/page/faq/all',
        },
        {
          id: 2,
          category: <I18n tKey='faq.verification' />,
          url: '/page/faq/verification',
        },
        {
          id: 3,
          category: <I18n tKey='faq.login' />,
          url: '/page/faq/account',
        },
        {
          id: 4,
          category: <I18n tKey='faq.finance' />,
          url: '/page/faq/finance',
        },
        {
          id: 5,
          category: <I18n tKey='faq.general' />,
          url: '/page/faq/general',
        },
      ],
    };
  }

  componentDidMount() {
    this.toggleCategory(this.props.section);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.toggleCategory(nextProps.section);
  }

  toggleCategory = category => {
    let tmp = {...this.state.DICT_KEYS};
    let id = 0;
    let activeSectionName = {...this.state.activeSectionName};
    if (category === 'about') {
      id = 6;
      activeSectionName = 'About FNT Exchange';
    } else if (category === 'all') {
      id = 1;
      activeSectionName = 'All questions about 2fa';
    } else if (category === 'verification') {
      id = 2;
      activeSectionName = 'Verification';
    } else if (category === 'problems') {
      id = 3;
      activeSectionName = 'Problems';
    } else if (category === 'finance') {
      id = 4;
      activeSectionName = 'Finance';
    } else if (category === 'general') {
      id = 5;
      activeSectionName = 'General';
    }

    for (let i = 0; i < this.state.DICT_KEYS.length; i++) {
      tmp[i].active = false;
    }
    tmp[id - 1].active = true;
    this.setState({
      tmp,
      activeSection: id,
      activeSectionName: activeSectionName,
    });
  };

  toggleAnswer = obj => {
    let id = obj.id;
    let tmp = {...this.state.DICT_KEYS};

    tmp[this.state.activeSection - 1].data[id - 1].isExpanded = !obj.isExpanded;
    this.setState({tmp});
  };

  render() {
    const {DICT_KEYS} = this.state;
    const {tabs, section} = this.props;

    const sectionTitle = tabs.find(tab => {
      const splitArr = tab.url.split('/');
      return section && section === splitArr[splitArr.length - 1];
    }).label;

    return (
      <div className='faq-page'>
        <div className=''>
          <div className='d-flex flex-row'>
            <div className='d-flex flex-column faq-right'>
              <h1 className='faq-title'>{sectionTitle}</h1>

              <ul className=''>
                {DICT_KEYS.map((index, elem) => {
                  if (DICT_KEYS[elem].active) {
                    return DICT_KEYS[elem].data.map((subElem, i) => {
                      return (
                        <li key={'***' + i} className={subElem.isExpanded ? 'active' : ''}>
                          <div className='d-flex flex-row question-box' onClick={() => this.toggleAnswer(subElem)}>
                            <div className='icon-wrapper'>{subElem.isExpanded ? <Minus /> : <Plus />}</div>
                            <p className='faq-text'>{subElem.question}</p>
                          </div>
                          <div className='flex-column'>
                            <div className={subElem.isExpanded ? 'expanded' : 'collapsed'}>
                              {subElem.tableColumns ? (
                                subElem.feesTable ? (
                                  <table className='table-balance'>
                                    <thead className='table-balance-header'>
                                      <tr>
                                        <th scope='col' rowSpan='2'>
                                          {' '}
                                          {subElem.tableColumns[0]}
                                        </th>
                                        <th scope='col' colSpan='2'>
                                          {subElem.tableColumns[1]}
                                        </th>
                                      </tr>
                                      <tr>
                                        <th>{subElem.tableColumns[2]}</th>
                                        <th>{subElem.tableColumns[3]}</th>
                                      </tr>
                                    </thead>
                                    <tbody className='table-balance-body'>
                                      {subElem.tableData.map((tableRow, index) => {
                                        return (
                                          <tr key={index} className='table-balance-header'>
                                            <td>{tableRow.firstEntry}</td>
                                            <td>{tableRow.secondEntry}</td>
                                            <td>{tableRow.thirdEntry}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                ) : (
                                  <table className='table-balance'>
                                    <thead className='table-balance-header'>
                                      <tr>
                                        {subElem.tableColumns.map((tableHeader, index) => {
                                          return <th key={index}>{tableHeader}</th>;
                                        })}
                                      </tr>
                                    </thead>

                                    <tbody className='table-balance-body'>
                                      {subElem.tableData.map((tableRow, index) => {
                                        return (
                                          <tr key={index} className='table-balance-header'>
                                            <td>{tableRow.firstEntry}</td>
                                            <td>{tableRow.secondEntry}</td>
                                            <td>{tableRow.thirdEntry}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                )
                              ) : (
                                subElem.answer
                              )}

                              {subElem.tableData && <div className='mt-30'>{subElem.answer} </div>}
                            </div>
                          </div>
                        </li>
                      );
                    });
                  }
                })}
              </ul>
              <div className='d-flex flex-row btn-wrapper'>
                <p>
                  <I18n tKey='faq.no_answer' />
                </p>
                <Link to='/page/help-center/accounts' className='btn btn-purple btn-full' rel='noopener noreferrer'>
                  <I18n tKey='faq.get_support' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({LngReducer}) => {
  return {LngReducer};
};

export default connect(mapStateToProps)(Faq);
