// @flow
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as React from 'react';

import NotFound from '../layout/NotFound';

import Footer from './Footer';

import {SET_ACTIVE_MARKET} from '../reducers/ActiveMarketReducer';

import type {Action} from '../api/types/internalFlow';
import {ConfirmEmail, CurrencyList, MarketList, WithdrawConfirm} from '../api/Actions';

import Currency from '../utils/currency';
import {URLMAP} from '../utils/const';
import Trading from './Trading/Trading';

type Props = {
  marketList: Action,
  children: React.Node,
};

const emailConfirmationRegExp = URLMAP.CONFIRM_EMAIL.replace(':confirmEmailToken/', '[A-Za-z0-9]+').replace(
  ':confirmEmail?',
  '/{0,1}[A-Za-z0-9]*'
);

const marketRegExp = URLMAP.MARKET.replace(':code', '(.+)');

export const isTerminal = path => {
  const regExp = new RegExp(`^(${URLMAP.TERMINAL}|${emailConfirmationRegExp}|${marketRegExp})$`);

  return regExp.test(path);
};

export const hideGoldenLayout = () => {
  $('#golden').hide();
};

export const showGoldenLayout = () => {
  $('#golden').show();
};

class TerminalPage extends React.PureComponent<Props> {
  state = {
    marketNotFound: false,
  };

  setMarket(market) {
    this.props.selectMarket(market);
    this.setState({marketNotFound: false});
  }

  checkPath(path) {
    const {MarketListReducer, ActiveMarketReducer} = this.props;
    if (path === '/' || path === URLMAP.LOGIN || path === URLMAP.SIGNUP) {
      if (ActiveMarketReducer && ActiveMarketReducer.identificatSymbol) {
        this.setMarket(ActiveMarketReducer);
      }
      return;
    }

    if (!MarketListReducer || !MarketListReducer.list) {
      return;
    }
    const regExp = new RegExp(`^${marketRegExp}$`);
    const matches = path.match(regExp);
    const marketCode = matches && matches[1];
    if (!marketCode) {
      this.setState({marketNotFound: true});
      return;
    }
    const marketToSet = MarketListReducer.list.find(m => {
      return m.mBanner === marketCode;
    });
    if (!marketToSet) {
      this.setState({marketNotFound: true});
      return;
    }

    if (!ActiveMarketReducer || ActiveMarketReducer.identificatSymbol !== marketToSet.identificatSymbol) {
      this.setMarket(marketToSet);
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    this.checkPath(this.props.location.pathname);
  }

  componentDidMount() {
    this.props.currencyList().then(responce => {
      if (responce && responce.data && responce.data.respItemCollection) {
        Currency.initCurrencies(responce.data.respItemCollection);
      }
    });

    const {confirmEmailToken, confirmEmail} = this.props.match.params;
    if (confirmEmailToken) {
      this.props
        .confirmEmail({
          specialMark: confirmEmailToken,
          email: decodeURIComponent(confirmEmail || ''),
        })
        .then(r => {
          this.props.history.push(URLMAP.LOGIN);
        });
    }

    this.checkPath(this.props.location.pathname);
    isTerminal(this.props.location.pathname) ? showGoldenLayout() : hideGoldenLayout();
  }

  componentWillReceiveProps(nextProps) {
    this.checkPath(nextProps.location.pathname);
    isTerminal(nextProps.location.pathname) ? showGoldenLayout() : hideGoldenLayout();
  }

  render() {
    const {marketNotFound} = this.state;

    return (
      <div style={{height: '100%'}}>
        {!marketNotFound && (
          <React.Fragment>
            <Trading />
            {/*<Footer />*/}
          </React.Fragment>
        )}
        {marketNotFound && <NotFound />}
      </div>
    );
  }
}

const mapStateToProps = ({user, history, MarketListReducer, ActiveMarketReducer}) => ({
  ...user,
  ...history,
  MarketListReducer,
  ActiveMarketReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    selectMarket: market => dispatch({type: SET_ACTIVE_MARKET, market}),
    ...bindActionCreators(
      {
        currencyList: CurrencyList.action,
        confirmEmail: ConfirmEmail.action,
        confirmWithdraw: WithdrawConfirm.action,
      },
      dispatch
    ),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TerminalPage));
