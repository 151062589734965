import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import {Provider} from 'react-redux';

import store from './store';
import Content from './Content';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Content />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
