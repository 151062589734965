import React from 'react';
import debounce from 'lodash.debounce';

import BuySell, {LIMIT_ORDER, LIMIT_ORDER_STOP, MARKET_ORDER, MARKET_ORDER_STOP} from './BuySell';
import classNames from 'classnames';
import I18n from '../../../../cmp/helpers/I18n';
import {connect} from 'react-redux';
import {OPEN_POPUP} from '../../../../reducers/PopupReducer';
import {OPEN_ORDER_ERROR_POPUP} from '../../../../cmp/popups/custom/OpenOrderError';

class Trading extends React.PureComponent {
  state = {
    orderType: LIMIT_ORDER,
    isLoginHintShown: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.OpenOrderReducer.error && this.props.OpenOrderReducer.error) {
      this.props.openErrorModal();
    }
  }

  hideHint = debounce(() => {
    this.setState({isLoginHintShown: false});
  }, 1000);

  showHint = () => {
    this.setState({isLoginHintShown: true});
    this.hideHint();
  };

  setOrderType = type => {
    this.setState({orderType: type});
  };

  render() {
    return (
      <div className='terminal-trading'>
        <div className='terminal-trading__header'>
          <div
            className={classNames('terminal-trading__header-item no-border', {
              ['active']: this.state.orderType === LIMIT_ORDER,
            })}
            onClick={() => this.setOrderType(LIMIT_ORDER)}
          >
            <I18n tKey='common.limit' />
          </div>
          <div
            className={classNames('terminal-trading__header-item no-border', {
              ['active']: this.state.orderType === LIMIT_ORDER_STOP,
            })}
            onClick={() => this.setOrderType(LIMIT_ORDER_STOP)}
          >
            <I18n tKey='common.limit_order_stop' />
          </div>
          <div
            className={classNames('terminal-trading__header-item no-border', {
              ['active']: this.state.orderType === MARKET_ORDER,
            })}
            onClick={() => this.setOrderType(MARKET_ORDER)}
          >
            <I18n tKey='common.market' />
          </div>
          <div
            className={classNames('terminal-trading__header-item no-border', {
              ['active']: this.state.orderType === MARKET_ORDER_STOP,
            })}
            onClick={() => this.setOrderType(MARKET_ORDER_STOP)}
          >
            <I18n tKey='common.market_order_stop' />
          </div>
        </div>
        <div className='terminal-trading__content'>
          <BuySell
            limit={this.props.limit}
            updatePagination={this.props.updatePagination}
            showHint={this.showHint}
            setOrderType={this.setOrderType}
            orderType={this.state.orderType}
            tradingType='buy'
          />
          <BuySell
            limit={this.props.limit}
            updatePagination={this.props.updatePagination}
            showHint={this.showHint}
            setOrderType={this.setOrderType}
            orderType={this.state.orderType}
            tradingType='sell'
          />
        </div>
        {this.state.isLoginHintShown && (
          <div className='terminal-trading__hint'>
            <I18n tKey='ACCESS_DENIED' />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({OpenOrderReducer}) => ({
  OpenOrderReducer,
});

const mapDispatchToProps = dispatch => ({
  openErrorModal: () => dispatch({type: OPEN_POPUP, name: OPEN_ORDER_ERROR_POPUP}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Trading);
