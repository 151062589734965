import React, {Component} from 'react';

import Tab from './Tab';
import SingleSelect from '../SingleSelect';
import Button from '../Button';
import Statistics from './Statistics';
import PairInputs from '../PairInputs';
import {OPERATION_TYPE, PAY_OPTIONS} from '../constants';
import {connect} from 'react-redux';

class BuySellForm extends Component {
  getTabClickHandler = tab => () => {
    this.props.onChangeOperationType(OPERATION_TYPE[tab]);
  };

  render() {
    const {
      onRightCodeChange,
      onLeftCodeChange,
      leftCurrencyAmount,
      rightCurrencyAmount,
      leftCurrency,
      rightSelectOptions,
      leftSelectOptions,
      onLeftAmountChange,
      onRightAmountChange,
      operationType,
      rightCurrency,
      paymentType,
      onPaymentChange,
      OHLCPeriod,
      coin,
      chartType,
      onOHLCPeriodChange,
      onChartTypeChange,
      coinDetails,
      OHLC,
      fiat,
      userData,
      btnBuyDisabled,
      onBuyClick,
      coinRange,
      specificCoin,
      onBlockchainChange,
    } = this.props;
    const {current_price = {}} = coinDetails || {};

    return (
      <div>
        <div className='fast-exchange-buy-sell'>
          <div className='fast-exchange-buy-sell__left'>
            <div className='fast-exchange-buy-sell__tabs'>
              <Tab
                onClick={this.getTabClickHandler(OPERATION_TYPE.BUY)}
                isActive={operationType === OPERATION_TYPE.BUY}
                label='common.buy'
              />
              <Tab
                onClick={this.getTabClickHandler(OPERATION_TYPE.SELL)}
                isActive={operationType === OPERATION_TYPE.SELL}
                label='common.sell'
                disabled
              />
            </div>

            <div className='buy-sell-tabs-inputs'>
              <PairInputs
                onRightCodeChange={onRightCodeChange}
                onLeftCodeChange={onLeftCodeChange}
                leftCurrencyAmount={leftCurrencyAmount}
                rightCurrencyAmount={rightCurrencyAmount}
                leftCurrency={leftCurrency}
                rightCurrency={rightCurrency}
                rightSelectOptions={rightSelectOptions}
                leftSelectOptions={leftSelectOptions}
                onLeftAmountChange={onLeftAmountChange}
                onRightAmountChange={onRightAmountChange}
                operationType={operationType}
              />
              {coinRange && (
                <SingleSelect
                  label='blockchain to use'
                  onChange={onBlockchainChange}
                  value={specificCoin}
                  options={coinRange}
                />
              )}
              <SingleSelect label='Pay with' onChange={onPaymentChange} value={paymentType} options={PAY_OPTIONS} />
            </div>
            {leftCurrency && rightCurrency && (
              <div className='fast-exchange-buy-sell__reference-price'>
                Reference Price: 1 {rightCurrency.id} ≈ {current_price[fiat.id]} {leftCurrency.id}
              </div>
            )}

            <div className='fast-exchange-buy-sell__action-btn-wrapper'>
              <Button
                disabled={btnBuyDisabled}
                onClick={onBuyClick}
                label={operationType === OPERATION_TYPE.BUY ? 'Buy' : 'Sell'}
              />
              {!userData.masterPersonality && <p>You need to be authorized</p>}
            </div>
          </div>

          <div className='fast-exchange-buy-sell__right'>
            <Statistics
              OHLCPeriod={OHLCPeriod}
              onChartTypeChange={onChartTypeChange}
              onOHLCPeriodChange={onOHLCPeriodChange}
              chartType={chartType}
              coin={coin}
              coinDetails={coinDetails}
              OHLC={OHLC}
              fiat={fiat}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({UserMarketsSettings}) => {
  return {
    UserMarketsSettings,
  };
};

export default connect(mapStateToProps, undefined)(BuySellForm);
