export const CURRENCIES_DIGITS = new Map([
  ['CRU', 4],
  ['WCRU', 4],
  ['USDU', 6],
  ['USDU_withdraw', 4],
  ['FIAT', 2],
  ['USD', 4],
  ['USD_withdraw', 2],
  ['RUB', 2],
  ['EUR', 4],
  ['EUR_withdraw', 2],
  ['JUNGLE', 4],
  ['EOS', 4],
  ['USDT', 6],
  ['UNTB', 4],
  ['BTC', 8],
  ['BCH', 8],
  ['ETH', 8],
  ['LTC', 8],
  ['DOGE', 8],
  ['USDC', 6],
  ['LINK', 6],
  ['DAI', 6],
  ['UNI', 6],
  ['WAX', 4],
  ['XRP', 6],
  ['BNB', 8],
  ['TRX', 6],
  ['MATIC', 6],
  ['BOBC', 8],
  ['DOT', 8],
  ['BUSD', 6],
  ['SOL', 8],
]);
