// import {USER_WAS_LOGGED_OUT} from './constants/user';
import {BALANCE_UPDATED} from '../WsReducer';
import * as Actions from '../../api/Actions';
import type {CurrencyConvertRespTO} from '../../api/types/models';

const initialState = {
  fetching: false,
  hasError: false,
  error: null,
  list: [],
  byAccountMap: {},
  byCurrencyMap: {},

  isDepositAddressLoading: false,
  depositAddressMap: {},
  depositAddressErrorMap: {},
  crossCourses: {},
};

function remapWallets(list) {
  let byAccountMap = {},
    byCurrencyMap = {};
  list.forEach(w => {
    byAccountMap[w.masterIdentifier] = w;
    byCurrencyMap[w.monetaryUnitCode] = w;
  });

  return {byAccountMap: byAccountMap, byCurrencyMap: byCurrencyMap};
}

//@shit
function WsWalletReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.WalletList.events.onStart: {
      return {...state, fetching: true};
    }

    case Actions.UserLogout.events.onSuccess: {
      return {...state, list: [], byAccountMap: {}, byCurrencyMap: {}};
    }

    case Actions.WalletList.events.onSuccess: {
      const list = (action.data && action.data.respItemCollection) || [],
        maps = remapWallets(list);

      return {
        ...state,
        list: list,
        byAccountMap: maps.byAccountMap,
        byCurrencyMap: maps.byCurrencyMap,
        fetching: false,
        hasError: false,
        error: null,
      };
    }

    case Actions.WalletList.events.onError: {
      return {
        ...state,
        hasError: true,
        error: action.data && action.data.parsedError,
      };
    }

    case 'CLEAR_WALLET_ERROR': {
      return {
        ...state,
        hasError: false,
        error: null,
      };
    }

    case Actions.DepositAddressesList.events.onStart: {
      return {
        ...state,
        isDepositAddressLoading: true,
      };
    }
    case Actions.DepositAddressesList.events.onSuccess: {
      const list = action.data && action.data.respItemCollection;
      let newState = {...state};
      newState.isDepositAddressLoading = false;
      if (list) {
        const walletId = action.data.purseIdentifier;
        list.sort((a, b) => b.createdDate - a.createdDate); //first is always most recent
        newState.depositAddressMap[walletId] = list;
      }

      return newState;
    }

    case Actions.DepositAddressesList.events.onError: {
      return {
        ...state,
        isDepositAddressLoading: false,
      };
    }

    case Actions.GetDepositAddress.events.onStart: {
      let walletId = action.query.purseIdentifier;
      state.depositAddressErrorMap[walletId] = null;
      return {...state, isDepositAddressLoading: true};
    }

    case Actions.GetDepositAddress.events.onSuccess: {
      const walletId = action.data.personalityIdentifier;
      let newState = {...state, isDepositAddressLoading: false};
      //delete(newState.depositAddressMap[walletId]);
      //Actions.DepositAddressesList.action({id: walletId})(action.asyncDispatch);
      if (!newState.depositAddressMap[walletId]) {
        newState.depositAddressMap[walletId] = [];
      }
      newState.depositAddressMap[walletId].unshift({
        targetPlaceName: action.data.targetPlaceName,
        memas: action.data.memas,
        minDepositAmount: action.data.minDepositAmount,
      });
      return newState;
    }

    case Actions.GetDepositAddress.events.onError: {
      let walletId = action.query.purseIdentifier;
      state.depositAddressErrorMap[walletId] = action.response ? action.response.data.parsedError : 'Cant get address';
      return {...state, isDepositAddressLoading: false};
    }

    case Actions.CurrencyConvert.events.onSuccess: {
      action.data.respItemCollection.map((cc: CurrencyConvertRespTO) => {
        if (!state.crossCourses[cc.leftMonetaryUnitCode]) {
          state.crossCourses[cc.leftMonetaryUnitCode] = {};
        }
        state.crossCourses[cc.leftMonetaryUnitCode][cc.rightMonetaryUnitCode] = cc.transformed;
      });
      return {...state, crossCourses: {...state.crossCourses}};
    }

    case BALANCE_UPDATED: {
      const updatedW = action.payload;
      let list = state.list;
      list.forEach(w => {
        if (parseInt(w.identificatSymbol) === parseInt(updatedW.id)) {
          w.aValue = parseFloat(updatedW.available);
          w.resValue = parseFloat(updatedW.reserved);
        }
      });
      let maps = remapWallets(list);

      return {...state, list: list, byAccountMap: maps.byAccountMap, byCurrencyMap: maps.byCurrencyMap};
    }

    case Actions.AccountDepositAddressesList.events.onStart: {
      return {
        ...state,
        isDepositAddressLoading: true,
      };
    }
    case Actions.AccountDepositAddressesList.events.onError: {
      return {
        ...state,
        isDepositAddressLoading: false,
      };
    }
    case Actions.AccountDepositAddressesList.events.onSuccess: {
      let newState = {...state, isDepositAddressLoading: false};
      let list = action.data && action.data.respItemCollection;
      if (list) {
        let map = {};

        list.forEach(depA => {
          let walletId = depA.purseResp.identificatSymbol;
          if (!map[walletId]) {
            map[walletId] = [];
          }
          const {targetPlaceName, memas, minDepositAmount, cDMY} = depA;
          map[walletId].push({targetPlaceName, memas, minDepositAmount, cDMY});
        });

        for (const walletId in map) {
          map[walletId].sort((a, b) => b.cDMY - a.cDMY); //first is always most recent
        }
        newState.depositAddressMap = map;
      }

      return newState;
    }

    default:
      return state;
  }
}

export default WsWalletReducer;
