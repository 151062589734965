import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import {bindActionCreators} from 'redux';

import {fetcher} from '../../api/Api';
import * as Actions from '../../api/Actions';

import {ReactComponent as AttentionIcon} from '../../../../assets/images/documents-history/statuses/attention.svg';
import {ReactComponent as ApprovedIcon} from '../../../../assets/images/documents-history/statuses/approved_filled.svg';
import {ReactComponent as DocumentIcon} from '../../../../assets/images/documents-history/document.svg';
import {ReactComponent as RemoveIcon} from '../../../../assets/images/documents-history/remove.svg';

import I18n from '../../cmp/helpers/I18n';
import {bytesToSize} from '../../cmp/documentsHistory/FilesUpload';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {translate} from '../../utils/i18n';
import {
  ACCOUNT_GUEST_OPTIONS,
  ACCOUNT_OPTIONS,
  ACCOUNT_USER_OPTIONS,
  ALLOWED_EXTENSTIONS,
  ALLOWED_TYPES,
  BUSINESS_OPTIONS,
  CRYPTO_OPTIONS,
  CUSTOM_SELECT_STYLES,
  CUSTOM_SELECT_THEME,
  FIAT_OPTIONS,
  MAX_ALLOWED_FILE_SIZE,
  TRADING_OPTIONS,
} from './constants';
import {CreateTicket} from '../../api/Actions';

class HelpCenter extends Component {
  constructor() {
    super();
    this.state = {
      form: {
        attachment: [],
      },
      selectionIsMade: false,
      formIsValid: false,
      files: [],
      filesUploaded: false,
      fileUploadStatus: '',
      filesUploadError: null,
      serviceDeskId: 0,
      activeSection: 'account',
      activeOptions: ACCOUNT_OPTIONS,
      activeSubSection: '2fa',
    };
  }

  componentWillReceiveProps(props) {
    const {SessionReducer} = this.props;
    const user = SessionReducer.masterPersonality || {};
    let selectOptions = this.getSelectOptions(false);

    let initialForm = {};

    if (props.section !== 'business') {
      initialForm = {
        customfield_10060: user.curUName,
        customfield_10062: user.electronicMailVal,
        attachment: [],
      };
    }

    if (props.section === 'accounts' && user.curUName) {
      selectOptions = this.getSelectOptions(true);
    }

    this.setState({
      activeSection: props.section,
      activeSubSection: selectOptions[props.section][0].value,
      serviceDeskId: this.getserviceDeskId(props.section),
      activeOptions: selectOptions[props.section],
      requestTypeId: String(selectOptions[props.section][0].requestTypeId),
      userLoggedIn: user.curUName ? true : false,
      form: initialForm,
      files: [],
    });
  }

  getSelectOptions = isLoggedIn => {
    return {
      accounts: [...ACCOUNT_GUEST_OPTIONS, ...(isLoggedIn && ACCOUNT_USER_OPTIONS)],
      crypto: CRYPTO_OPTIONS,
      trading: TRADING_OPTIONS,
      fiat: FIAT_OPTIONS,
      business: BUSINESS_OPTIONS,
    };
  };

  getserviceDeskId = category => {
    switch (category) {
      case 'accounts':
        return '11';

      case 'business':
        return '18';

      case 'fiat':
        return '12';

      case 'crypto':
        return '13';

      case 'trading':
        return '14';

      default:
        return '11';
    }
  };

  getSelectionCustomField = () => {
    const {serviceDeskId} = this.state;
    switch (serviceDeskId) {
      case '18':
        return 'customfield_10078';
      default:
        return 'customfield_10072';
    }
  };

  handleSelect = (e, type) => {
    const {value} = e;
    const {form, serviceDeskId} = this.state;
    const selectionIsMade = true;
    if (value === 'change-email') {
      const SessionReducer = this.props || {SessionReducer: {}};
      const user = SessionReducer.SessionReducer.masterPersonality || {};

      let initialForm = {
        customfield_10060: user.curUName,
        customfield_10062: user.electronicMailVal,
        attachment: [],
      };
      this.setState({
        requestTypeId: String(e.requestTypeId),
        form: initialForm,
        selectionIsMade,
      });
    } else if (value === 'other-questions') {
      const SessionReducer = this.props;
      const user = (SessionReducer.SessionReducer || {masterPersonality: {}}).masterPersonality;

      let initialForm = {
        customfield_10060: user.curUName,
        customfield_10062: user.electronicMailVal,
        summary: 'Other questions',
      };

      this.setState({
        requestTypeId: String(e.requestTypeId),
        form: initialForm,
        selectionIsMade,
      });
    } else if (type === 'serviceDeskType') {
      const formObj = serviceDeskId === '18' ? {...form} : {...form, attachment: []};
      this.setState({
        requestTypeId: String(e.requestTypeId),
        activeSubSection: value,
        form: formObj,
        selectionIsMade,
      });
    } else if (type === 'crypto') {
      const payloadCryptoObj = {
        id: value,
      };

      const formObj = {...form, [this.getSelectionCustomField()]: payloadCryptoObj};
      this.setState({
        form: formObj,
        selectionIsMade,
      });
    } else if (type === 'country') {
      const formObj = {...form, [this.getSelectionCustomField(serviceDeskId)]: {id: String(value)}};
      this.setState({
        form: formObj,
        selectionIsMade,
      });
    } else {
      const formObj = {...form, attachment: []};
      this.setState({
        activeSubSection: value,
        requestTypeId: String(e.requestTypeId),
        form: formObj,
        selectionIsMade,
      });
    }
  };

  handleOnSubmit = async e => {
    e.preventDefault();
    const {executeRecaptcha} = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.debug('NO CAPTCHA');
      return;
    }

    const token = await executeRecaptcha('ticket');

    const {form, serviceDeskId, requestTypeId} = this.state;
    this.props.createTicket({
      body: {
        serviceDeskId: serviceDeskId,
        requestTypeId: requestTypeId,
        requestFieldValues: {
          ...form,
        },
      },
      gToken: token,
    });
    this.props.cleanCreatedTicket();
  };

  handleRemoveFIle = file => {
    const {form} = this.state;
    const formObj = {...form, attachment: form.attachment.filter(item => item !== file.attachmentId)};
    this.setState(state => ({
      files: state.files.filter(item => item !== file),
      form: formObj,
    }));
  };

  uploadFiles = async file => {
    const {files, form} = this.state;
    const size = 10000000; // 10mb

    if (file.length && files.length < 5) {
      let attachmentArr = [];
      let fileArr = [];

      for (const f of file) {
        if (f.size <= size) {
          const correctFormat = /(.jpeg|.png|.jpg|.pdf|.docx|.doc|.csv)$/.test(
            ((file[0] || {}).name || '').toLowerCase()
          );

          if (correctFormat) {
            var fileId = await this.getAttachmentId(f);

            if (fileId) {
              attachmentArr = [...form.attachment, ...attachmentArr, fileId] || [];
              fileArr = [...files, ...fileArr, f];
            } else {
            }
          } else {
          }
        }
      }

      const formObj = {...form, attachment: attachmentArr};
      this.setState({
        filesUploaded: true,
        files: fileArr,
        form: formObj,
        filesUploadError: false,
      });
    }
  };

  getAttachmentId = async file => {
    var formData = new FormData();

    const {serviceDeskId} = this.state;
    const {executeRecaptcha} = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.debug('NO CAPTCHA');
      return;
    }

    const token = await executeRecaptcha('attachment');

    !formData.has('gToken') && formData.append('gToken', token);
    !formData.has('file') && formData.append('file', file);

    formData.append('serviceDeskId', serviceDeskId);

    let response = null;

    const url = '/tickets/uploadAttachment';

    response = await fetcher.request({
      url: url,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    if (!response) {
      return false;
    } else {
      const fileId = response.data.temporaryAttachments[0].temporaryAttachmentId;
      file.attachmentId = fileId;
      return fileId;
    }
  };

  handleChange = e => {
    const formObj = {...this.state.form, [e.target.name]: e.target.value};
    this.setState({
      form: formObj,
    });
  };

  validateForm = activeSection => {
    let invalidForm = true;
    const {form, activeSubSection, selectionIsMade, requestTypeId} = this.state;
    const fieldsSum =
      Object.keys(form).length +
      (form.attachment && activeSection !== 'business' ? Object.keys(form.attachment).length : 0);
    if (!selectionIsMade) {
      return true;
    }
    if (activeSection === 'accounts') {
      invalidForm = fieldsSum > 3 ? false : true;
      if (requestTypeId === '257') {
        invalidForm = fieldsSum > 2 ? false : true;
      }
    } else if (activeSection === 'crypto') {
      invalidForm = fieldsSum > 8 ? false : true;
      if (activeSubSection === 'crypto-withdrawal') {
        invalidForm = fieldsSum > 7 ? false : true;
      }
    } else if (activeSection === 'trading') {
      invalidForm = fieldsSum > 3 ? false : true;
    } else if (activeSection === 'fiat') {
      invalidForm = fieldsSum > 5 ? false : true;
    } else if (activeSection === 'business') {
      invalidForm = fieldsSum > 5 ? false : true;
    }

    return invalidForm;
  };

  render() {
    const options = this.state.activeOptions;
    const {activeSection, activeSubSection, userLoggedIn, files, form, requestTypeId} = this.state;
    const {CreateTicketReducer} = this.props;

    return (
      <div>
        <div className='page-legacy_content-block'>
          <div className='title'>
            <h2>
              <I18n tKey='helpcenter.title' />
            </h2>
          </div>

          <div className='text'>
            <I18n tKey='helpcenter.header' />
          </div>
          <form className='help__form' onSubmit={e => this.handleOnSubmit(e)} key={this.state.serviceDeskId}>
            <div className='form__block'>
              <Select
                styles={CUSTOM_SELECT_STYLES}
                placeholder={translate('helpcenter.select')}
                isSearchable={false}
                theme={CUSTOM_SELECT_THEME}
                onChange={e => this.handleSelect(e, 'serviceDeskType')}
                options={options}
              />
            </div>

            {activeSection !== 'business' && (
              <div className='form__block'>
                <input
                  type='text'
                  placeholder={translate('helpcenter.username')}
                  required
                  className='form__control'
                  id='username'
                  name='customfield_10060'
                  value={this.state.form.customfield_10060}
                  disabled={userLoggedIn}
                  onChange={e => this.handleChange(e)}
                />
                <label htmlFor='username'>
                  <I18n tKey='helpcenter.username' />
                </label>
              </div>
            )}

            {activeSection === 'accounts' && activeSubSection === 'change-email' && (
              <div>
                <div className='form__block'>
                  <input
                    type='email'
                    placeholder={translate('helpcenter.actual_email')}
                    required
                    className='form__control'
                    id='email'
                    name='customfield_10062'
                    onChange={e => this.handleChange(e)}
                  />
                  <label htmlFor='email'>
                    <I18n tKey='helpcenter.email' />
                  </label>
                </div>
                <div className='form__block'>
                  <input
                    type='email'
                    placeholder={translate('helpcenter.new_email')}
                    required
                    className='form__control'
                    id='email'
                    name='customfield_10063'
                    onChange={e => this.handleChange(e)}
                  />
                  <label htmlFor='email'>
                    {' '}
                    <I18n tKey='helpcenter.email_new' />
                  </label>
                </div>
              </div>
            )}

            {activeSubSection !== 'change-email' && (
              <div className='form__block'>
                <input
                  type='email'
                  placeholder={translate('helpcenter.email')}
                  value={this.state.form.customfield_10062}
                  disabled={userLoggedIn && activeSection !== 'business'}
                  required
                  className='form__control'
                  id='email'
                  name='customfield_10062'
                  onChange={e => this.handleChange(e)}
                />
                {activeSection !== 'business' && (
                  <label htmlFor='email'>
                    <I18n tKey='helpcenter.email' />
                  </label>
                )}
              </div>
            )}

            {(activeSection === 'crypto' ||
              activeSection === 'business' ||
              activeSection === 'fiat' ||
              activeSubSection !== 'change-email') && (
              <div className='form__block'>
                <label htmlFor='message' className='header__label'>
                  {' '}
                  <I18n tKey='helpcenter.description_short' />*
                </label>
                <textarea
                  type='text'
                  className='form__control'
                  id='message'
                  required
                  name='description'
                  value={this.state.form.description}
                  onChange={e => this.handleChange(e)}
                />
              </div>
            )}

            {/* {
              (activeSection !== 'accounts'   && activeSection !== 'crypto' && activeSection !== 'fiat' && activeSection !== 'business') &&
              <div className="form__block">
                <label htmlFor="message" className="header__label"> <I18n tKey="helpcenter.description_short"/>*</label>
                <textarea type="text" className="form__control" id="message" required name="summary"
                          value={this.state.form.summary} onChange={(e) => this.handleChange(e)}/>
              </div>
            } */}
            {activeSection !== 'business' && requestTypeId !== '257' && (
              <div className='form__block '>
                <label htmlFor='dropzone' className='header__label'>
                  {' '}
                  <I18n tKey='helpcenter.attachment' />
                </label>
                <Dropzone className='dropzone' onDrop={acceptedFiles => this.uploadFiles(acceptedFiles)}>
                  <div className='d-flex align-items-center justify-content-center flex-column text-center'>
                    <div>
                      <p>
                        <I18n tKey='helpcenter.drag_and_drop' />
                      </p>
                      <button type='button' className='btn btn-white'>
                        <I18n tKey='helpcenter.browse' />
                      </button>
                    </div>
                  </div>
                </Dropzone>
                <div className='documents-history_modal_container_limit'>
                  <I18n tKey='documents.maximum_file_size' />
                  <span> - 10MB</span>
                </div>
                <div className='documents-history_modal_container_limit'>
                  <I18n tKey='documents.allowed_document_formats' />
                  <span> - {ALLOWED_EXTENSTIONS.join(', ')}</span>
                </div>
                {!!files.length && form.attachment.length > 0 && (
                  <div className='documents-history_modal_container_grid'>
                    {files.map((file, index) => (
                      <div
                        key={`${file}${index}${activeSubSection}`}
                        className='documents-history_modal_container_grid_row'
                      >
                        <div className='documents-history_modal_container_grid_cell'>
                          {file.size < MAX_ALLOWED_FILE_SIZE ? (
                            <ApprovedIcon />
                          ) : (
                            <AttentionIcon className='documents-history_modal_container_grid_icon__error' />
                          )}
                        </div>
                        <div className='documents-history_modal_container_grid_cell'>
                          <DocumentIcon />
                        </div>
                        <div className='documents-history_modal_container_grid_cell'>
                          <p>{`${file.name} (${bytesToSize(file.size)})`}</p>
                          {file.size > MAX_ALLOWED_FILE_SIZE && (
                            <div className='documents-history_modal_container_grid_cell__error'>
                              <I18n tKey='documents.maximum_file_size' />
                              <span> - 10MB</span>
                            </div>
                          )}
                          {!ALLOWED_TYPES.includes(file.type.toLowerCase()) && (
                            <div className='documents-history_modal_container_grid_cell__error'>
                              <I18n tKey='documents.allowed_document_formats' />
                              <span> - {ALLOWED_EXTENSTIONS.join(', ')}</span>
                            </div>
                          )}
                        </div>
                        <div className='documents-history_modal_container_grid_cell'>
                          <button
                            type='button'
                            className='documents-history_modal_container_grid_cell_button'
                            onClick={e => this.handleRemoveFIle(file)}
                          >
                            <RemoveIcon />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {/* <div className="form__block">
                <label htmlFor  ="message" className="header__label"> Email confirmation to *</label>
                <input type="text" placeholder="Username" className="form__control" id="username" required name="email" onChange={(e) => this.handleChange(e)}/>
                </div> */}

            <p className={'response__message'}>
              {CreateTicketReducer.data && CreateTicketReducer.completed && (
                <span className='success'>
                  <b>Ticket created successfully</b>. Click{' '}
                  <a target='_blank' href={CreateTicketReducer.data._links ? CreateTicketReducer.data._links.web : '#'}>
                    here
                  </a>{' '}
                  to go to your ticket.
                </span>
              )}

              {CreateTicketReducer.error && CreateTicketReducer.error !== {} && (
                <span className='warning'>Ticket creation error.</span>
              )}
            </p>

            <div className='form__block button__wrapper d-flex  '>
              <button className='btn btn-cancel '>
                <span>
                  <I18n tKey='common.cancel' />
                </span>
              </button>

              <button type='submit' className='btn btn-purple ' disabled={this.validateForm(activeSection)}>
                <span>
                  <I18n tKey='common.send' />
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({SessionReducer, CreateTicketReducer}) => {
  return {
    SessionReducer,
    CreateTicketReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        createTicket: Actions.CreateTicket.action,
      },
      dispatch
    ),
    cleanCreatedTicket: () => dispatch({type: CreateTicket.events.onClear}),
  };
};

export default withGoogleReCaptcha(withRouter(connect(mapStateToProps, mapDispatchToProps)(HelpCenter)));
