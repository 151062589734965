import moment from 'moment';
import {TIME_DISPLAY_PATTERN} from './const';

class Formatter {
  curDate = () => {
    return moment().format('HH:mm');
  };

  dateTime = val => {
    return moment(val).format('MMMM DD YYYY, HH:mm:ss');
  };

  verificationDate = date => {
    if (!date) {
      return '';
    }
    if (typeof date === 'object') {
      const {year, month, day} = date;
      return moment(`${day}-${month}-${year}`, TIME_DISPLAY_PATTERN.STRING_PARSE_FORMAT).format(
        TIME_DISPLAY_PATTERN.DOC_PATTERN
      );
    } else {
      return moment(date).format(TIME_DISPLAY_PATTERN.DOC_PATTERN);
    }
  };

  date = val => {
    return moment(val).format('MMMM DD YYYY');
  };

  dateTimeShort = val => {
    return moment(val).format('MMMM DD YYYY, HH:mm');
  };

  time = val => {
    return moment(val).format('HH:mm:ss');
  };

  percent = val => {
    var numVal = parseFloat(val);
    if (isNaN(numVal)) return '?';
    return numVal.toFixed(2) + '%';
  };

  // Oct 28 2017, 4:04 AM

  capFirst = s => {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  cleanNumber = (number, prev = null, currency) => {
    let maxDecimal = 8;
    if (/CRU|UNTB|EOS|WCRU|WAX/.test(currency)) {
      maxDecimal = 4;
    }
    if (/USD|EUR/.test(currency)) {
      maxDecimal = 4;
    }
    if (/RUB/.test(currency)) {
      maxDecimal = 2;
    }
    if (/BTC|ETH|LTC|BCH|BNB|DOGE/.test(currency)) {
      maxDecimal = 8;
    }
    if (/USDT|USDU|USDC|LINK|DAI|UNI|XRP|TRX|MATIC/.test(currency)) {
      maxDecimal = 6;
    }
    if (!number) return '';
    let ret = number.toString();
    if (ret === '.') return '';
    prev = prev + '';
    if (!!prev && prev.indexOf('.') !== -1 && ret.length && ret[ret.length - 1] === '.') {
      ret = ret.slice(0, ret.length - 1);
    }
    ret = ret.replace(/[^0-9\.]/, '');
    if (ret.indexOf('.') !== -1) {
      let afterDot = ret.substr(ret.indexOf('.') + 1);
      if (afterDot.length > maxDecimal) {
        ret = ret.substr(0, ret.indexOf('.')) + '.' + afterDot.substr(0, maxDecimal);
      }
    }
    return ret.toString();
  };
}

const instance = new Formatter();
export default instance;
