import React, {PureComponent} from 'react';
import classNames from 'classnames';

import {ReactComponent as Arrow} from '../../../../../../../assets/images/icon/up-arrow-up.svg';

class Sorting extends PureComponent {
  handleAscClick = () => {
    const {onSortingChange, ascValue} = this.props;

    onSortingChange(ascValue);
  };

  handleDescClick = () => {
    const {onSortingChange, descValue} = this.props;

    onSortingChange(descValue);
  };

  render() {
    const {activeSorting, ascValue, descValue} = this.props;

    return (
      <div className='market-ordering'>
        <div
          onClick={this.handleDescClick}
          className={classNames('ordering-btn', {['active']: activeSorting === descValue})}
        >
          <Arrow />
        </div>
        <div
          onClick={this.handleAscClick}
          className={classNames('ordering-btn', {['active']: activeSorting === ascValue})}
        >
          <Arrow />
        </div>
      </div>
    );
  }
}

export default Sorting;
