import * as React from 'react';
import {DefinedRange} from 'react-date-range';
import moment from 'moment';

import {ReactComponent as ArrowDown} from '../../../../../../assets/images/icon/dropdown-mark.svg';

import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import I18n from '../../../../cmp/helpers/I18n';

import Formatter from '../../../../utils/formatter';

export class DateRangeFilter extends React.PureComponent {
  state = {
    opened: false,

    range: {
      startDate: null,
      endDate: null,
    },
  };

  clearDate = () => {
    this.setState({range: {startDate: null, endDate: null}}, () => {
      this.props.onChange(this.state.range);
    });
  };

  changeDateRange = range => {
    this.setState({range: range.range1});
    this.props.onChange({
      dateFrom: moment(range.range1.startDate).toISOString(),
      dateTo: moment(range.range1.endDate).toISOString(),
    });
  };

  render() {
    return (
      <div
        className={'table-dropdown-item ' + (this.state.isHovered ? 'hovered' : '')}
        onMouseEnter={() => this.setState({isHovered: true})}
        onMouseLeave={() => this.setState({isHovered: false})}
      >
        <div className='table-dropdown-item-header '>
          {this.state.range.startDate && this.state.range.endDate && (
            <span onClick={this.clearDate}>
              {Formatter.date(this.state.range.startDate) + ' - ' + Formatter.date(this.state.range.endDate)}
            </span>
          )}
          {(!this.state.range.startDate || !this.state.range.endDate) && <I18n tKey='common.select_date' />}{' '}
          <SvgWrapper className='icon icon-dropdown ic-size-03' SvgCmp={ArrowDown} themeWatch />
        </div>
        <div className='table-dropdown-item-body datePicker-menu-item'>
          <DefinedRange ranges={[this.state.range]} onChange={this.changeDateRange} />
        </div>
      </div>
    );
  }
}
