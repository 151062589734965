import React from 'react';
import {connect} from 'react-redux';

import MarketsItem from './item';

import type {CurrencyTO, MarketTO} from '../../../../api/types/models';

import {ReactComponent as FavIc} from '../../../../../../assets/images/icon/favorite-icon.svg';
import {ReactComponent as SearchIc} from '../../../../../../assets/images/icon/search-n.svg';

import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import I18n from '../../../../cmp/helpers/I18n';
import {CurrencyView} from '../../../../cmp/helpers/CurrencyView';

import Currency from '../../../../utils/currency';
import Formatter from '../../../../utils/formatter';
import {translate} from '../../../../utils/i18n';

import {SET_ACTIVE_MARKET} from '../../../../reducers/ActiveMarketReducer';

import {ICONS, MARKET_SORTING, USER_MARKET_COLUMN_PARAMS} from './constants';
import {Sorting} from './components';
import {sortingHandlers} from './services';
import {URLMAP} from '../../../../utils/const';
import {withRouter} from 'react-router';
import RadioBtn from '../../../../cmp/helpers/RadioBtn';
import storageHelper from 'storage-helper/src';
import {STORAGE_KEY} from '../../../../reducers/LngReducer';
import BigNumber from 'bignumber.js';

class Markets extends React.PureComponent {
  constructor(props) {
    super(props);

    let marketColumnParams = {marketSorting: MARKET_SORTING.volume_desc, isVolumeDisplayed: true};
    const savedSorting = storageHelper.getItem(USER_MARKET_COLUMN_PARAMS, true);

    if (props.SessionReducer.masterPersonality && savedSorting) {
      marketColumnParams = savedSorting;
    }

    this.state = {
      favouritesOnly: false,
      marketFilter: '',
      ...marketColumnParams,
    };
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    if (!this.props.SessionReducer.masterPersonality && nextProps.SessionReducer.masterPersonality) {
      const marketSorting = storageHelper.getItem(USER_MARKET_COLUMN_PARAMS, true);
      if (marketSorting) {
        this.setState({...marketSorting});
      }
    }
  }

  changeState = (propName, value) => {
    if (propName === 'favouritesOnly' && !this.props.SessionReducer.masterPersonality) {
      this.props.history.push(URLMAP.LOGIN);

      return;
    }

    this.setState({[propName]: value});
  };

  returnCurrencyIcon = name => {
    if (!name) {
      return null;
    }
    const currency = name.toLowerCase();

    return ICONS[currency] ? <SvgWrapper className='ic-size-00' SvgCmp={ICONS[currency]} /> : null;
  };

  handleSortingChange = value => {
    if (this.props.SessionReducer.masterPersonality) {
      localStorage.setItem(
        USER_MARKET_COLUMN_PARAMS,
        JSON.stringify({marketSorting: value, isVolumeDisplayed: this.state.isVolumeDisplayed})
      );
    }

    this.setState({marketSorting: value});
  };

  handleRadioChange = (propName, value) => {
    const isVolumeOption = value === 'volume';
    const newState = {
      isVolumeDisplayed: isVolumeOption,
      marketSorting: this.state.marketSorting,
    };
    const regExp = new RegExp(
      `^(${MARKET_SORTING.volume_desc}|${MARKET_SORTING.volume_asc}|${MARKET_SORTING.change_asc}|${MARKET_SORTING.change_desc})$`
    );

    if (regExp.test(this.state.marketSorting)) {
      newState.marketSorting = isVolumeOption ? MARKET_SORTING.volume_desc : MARKET_SORTING.change_desc;
    }

    if (this.props.SessionReducer.masterPersonality) {
      localStorage.setItem(USER_MARKET_COLUMN_PARAMS, JSON.stringify(newState));
    }

    this.setState(newState);
  };

  render() {
    const {
      WsMarketStatsReducer,
      MarketListReducer,
      WsTickersReducer,
      CurrencyListReducer,
      ActiveMarketReducer,
      GetFavouriteMarketsReducer,
      MarketPricesReducer,
      CurrencyXRatesReducer,
    } = this.props;
    const {marketFilter, favouritesOnly} = this.state;
    const tickers = WsTickersReducer.tickers || {};

    let marketList = (MarketListReducer.list || [])
      .filter(market => market.activityFlag)
      .map(market => {
        const stats = WsMarketStatsReducer[market.identificatSymbol] || {};
        const lastPrice =
          tickers.next[market.identificatSymbol] ||
          MarketPricesReducer.pricesByMarket[market.identificatSymbol] ||
          stats.lastPrice;

        const dailyPriceChange = lastPrice - stats.dailyOpen;
        const dailyPriceChangePercent = !!stats.dailyOpen ? (dailyPriceChange / stats.dailyOpen) * 100 : 0;

        const courses = CurrencyXRatesReducer.data.entries || [];
        const course = courses.find(({code}) => code.toUpperCase() === market.leftMonetaryUnitCode.toUpperCase());
        const usdVolume =
          course && stats.dailyVolumeBase
            ? new BigNumber(stats.dailyVolumeBase).multipliedBy(course.price).toNumber()
            : 0;

        return {...market, lastPrice, dailyPriceChangePercent, usdVolume};
      });

    const currencyList = CurrencyListReducer.list || [];
    const market = ActiveMarketReducer || {};
    const favMarkets = GetFavouriteMarketsReducer.list || [];
    const currenciesForMarkets = {};

    marketList.forEach((market: MarketTO) => {
      currenciesForMarkets[market.leftMonetaryUnitCode] = true;
      currenciesForMarkets[market.rightMonetaryUnitCode] = true;
    });

    if (tickers) {
      marketList.forEach(m => {
        m.price = tickers.next[m.identificatSymbol];
      });
    }

    if (this.state.marketSorting) {
      marketList = marketList.sort((leftItem, rightItem) => {
        const sortingFunction = sortingHandlers[this.state.marketSorting];
        const tickers = WsTickersReducer.tickers || {};

        return sortingFunction(leftItem, rightItem, {tickers, WsMarketStatsReducer, MarketPricesReducer});
      });
    }

    const rCurr: CurrencyTO =
      currencyList.filter((c: CurrencyTO) => c.mUCryptogram === market.rightMonetaryUnitCode)[0] || {};
    const lCurr: CurrencyTO =
      currencyList.filter((c: CurrencyTO) => c.mUCryptogram === market.leftMonetaryUnitCode)[0] || {};

    const stats = WsMarketStatsReducer[market.identificatSymbol] || {};

    const price = tickers.next[market.identificatSymbol] || stats.lastPrice;

    const dailyPriceChange = price - stats.dailyOpen;
    const dailyPriceChangePercent = !!stats.dailyOpen ? (dailyPriceChange / stats.dailyOpen) * 100 : '0';

    const isUp = price > stats.dailyOpen;

    const volume = Currency.show(stats.dailyVolumeBase, 4, true);

    return (
      <section className='markets'>
        <div className='markets_stats'>
          <div className='markets_stats_header'>
            <div className='markets_stats_header-icon'>{this.returnCurrencyIcon(market.leftMonetaryUnitCode)}</div>
            <div className='markets_stats_header-info'>
              <div className='title'>
                <span>
                  {lCurr.mUBanner} / {rCurr.mUBanner}
                </span>
                <I18n tKey='markets.last_price' />
              </div>
              <div className='value'>
                <span>{market.mBanner}</span>
                <span>
                  <i className={`icon icon-arrow-${isUp > 0 ? 'top' : 'bottom'}`} />
                  {price ? (
                    <CurrencyView appearance={market.currentT} amount={price} type={market.rightMonetaryUnitCode} />
                  ) : (
                    'n/a'
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className='markets_stats_body'>
            <div className='text'>
              <span>
                <I18n tKey='common.vol' /> {!isNaN(Number(volume)) ? Number(volume).toLocaleString('en') : volume}
              </span>
              <span>
                <I18n tKey='markets.low' /> {Currency.show(stats.dailyLow, market.rightMonetaryUnitCode)}
              </span>
            </div>
            <div className='text'>
              <span>
                24h <i className={`icon icon-arrow-${isUp > 0 ? 'top' : 'bottom'}`} />
                <span className={`${isUp ? 'green' : 'red'}`}>{Formatter.percent(dailyPriceChangePercent)}</span> (
                {Currency.show(dailyPriceChange, market.rightMonetaryUnitCode)}){' '}
              </span>
              <span>
                <I18n tKey='markets:high' /> {Currency.show(stats.dailyHigh, market.rightMonetaryUnitCode)}
              </span>
            </div>
          </div>
        </div>

        <div className='markets_body'>
          <div className='markets_body-current'>
            <div className='search-wrapper'>
              <SvgWrapper themeWatch className='icon icon-search ic-size-02' SvgCmp={SearchIc} />

              <label className='search-input'>
                <input
                  placeholder={translate('common.search')}
                  type='text'
                  value={this.state.marketFilter}
                  onChange={ev => {
                    this.setState({marketFilter: ev.target.value});
                  }}
                />
              </label>
            </div>
            <div className='radio-block'>
              <RadioBtn
                isSmall
                onChange={this.handleRadioChange}
                name='change'
                label='markets.toggle_label_change'
                value='change'
                isChecked={!this.state.isVolumeDisplayed}
              />
              <RadioBtn
                isSmall
                onChange={this.handleRadioChange}
                name='volume'
                label='markets.toggle_label_volume'
                value='volume'
                isChecked={this.state.isVolumeDisplayed}
              />
            </div>
            <div
              className='markets_body-current-item'
              onClick={this.changeState.bind(this, 'favouritesOnly', !favouritesOnly)}
            >
              <SvgWrapper className={`icon icon-star ${favouritesOnly ? 'active' : ''} ic-size-02`} SvgCmp={FavIc} />
            </div>
          </div>

          <div className='markets-grid-header'>
            <div className='row-name'>
              <I18n tKey='markets.value_pair' />
              <Sorting
                ascValue={MARKET_SORTING.pair_asc}
                descValue={MARKET_SORTING.pair_desc}
                onSortingChange={this.handleSortingChange}
                activeSorting={this.state.marketSorting}
              />
            </div>

            <div className='row-price'>
              <I18n tKey='markets.value_price' />
              <Sorting
                ascValue={MARKET_SORTING.price_asc}
                descValue={MARKET_SORTING.price_desc}
                onSortingChange={this.handleSortingChange}
                activeSorting={this.state.marketSorting}
              />
            </div>

            {this.state.isVolumeDisplayed ? (
              <div className='row-volume'>
                <div className='volume-label'>
                  <I18n tKey='markets.value_volume' />
                  USD
                </div>
                <Sorting
                  ascValue={MARKET_SORTING.volume_asc}
                  descValue={MARKET_SORTING.volume_desc}
                  onSortingChange={this.handleSortingChange}
                  activeSorting={this.state.marketSorting}
                />
              </div>
            ) : (
              <div className='row-percentage'>
                <I18n tKey='markets.value_change' />
                <Sorting
                  ascValue={MARKET_SORTING.change_asc}
                  descValue={MARKET_SORTING.change_desc}
                  onSortingChange={this.handleSortingChange}
                  activeSorting={this.state.marketSorting}
                />
              </div>
            )}
          </div>
          <div className='grid-markets-block'>
            {marketList.map((market: MarketTO) => {
              if (market.disablingFlag) {
                return null;
              }

              const isFav = favMarkets.includes(market.identificatSymbol);

              if (favouritesOnly === true && !isFav) {
                return null;
              }

              const marketTitle = `${market.leftMonetaryUnitCode}-${market.rightMonetaryUnitCode}`.toLowerCase();

              if (marketFilter && !marketTitle.includes(marketFilter.toLowerCase())) {
                return null;
              }

              return (
                <MarketsItem
                  isVolumeDisplayed={this.state.isVolumeDisplayed}
                  key={market.identificatSymbol}
                  market={market}
                  isFavourite={isFav}
                  history={this.props.history}
                  isActive={market.identificatSymbol === ActiveMarketReducer.identificatSymbol}
                />
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({
  WsMarketStatsReducer,
  GetFavouriteMarketsReducer,
  ActiveMarketReducer,
  MarketListReducer,
  CurrencyListReducer,
  WsTickersReducer,
  MarketPricesReducer,
  SessionReducer,
  CurrencyXRatesReducer,
}) => {
  return {
    WsMarketStatsReducer,
    ActiveMarketReducer,
    GetFavouriteMarketsReducer,
    MarketListReducer,
    CurrencyListReducer,
    WsTickersReducer,
    MarketPricesReducer,
    SessionReducer,
    CurrencyXRatesReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectMarket: market => dispatch({type: SET_ACTIVE_MARKET, market}),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Markets));
