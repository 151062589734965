import React, {useState} from 'react';
import I18n from './I18n';
import {ReactComponent as CloseIc} from '../../../../assets/images/close.svg';
import {Link} from 'react-router-dom';
function HoverLeftMenu({tKey, visible, big}) {
  const [disabled, setDisabled] = useState('');
  return (
    <div>
      {visible ? (
        <div className={big ? `hover-left-menu-wrapper big ${disabled}` : `hover-left-menu-wrapper ${disabled}`}>
          {big ? (
            <div className='d-flex flex-column h-100 w-100'>
              <I18n className='tooltip-heading' tKey={tKey} />
              <CloseIc
                onMouseLeave={() => setDisabled('')}
                onClick={() => setDisabled('disabled')}
                className='close-icon'
              ></CloseIc>
              <p className='tooltip-subtext'>
                {' '}
                <I18n tKey={'common.left_menu'} />
              </p>
              <Link className='btn btn-purple btn-full small' to='/page/faq/about'>
                <I18n tKey={'common.go_faq'} />
              </Link>
            </div>
          ) : (
            <I18n tKey={tKey} />
          )}
        </div>
      ) : null}
    </div>
  );
}
export default HoverLeftMenu;
