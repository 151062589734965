import React, {Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as Actions from '../../api/Actions';

import {TradingFees} from './TradingFees';
import {WithdrawDeposits} from './WithdrawDeposits';
import I18n from '../../cmp/helpers/I18n';
import {CurrencyView} from '../../cmp/helpers/CurrencyView';
import BlockPreloader from '../../cmp/helpers/BlockPreloader';

const PAGE_TABS = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
  TRADING: 'TRADING',
};

class FeesAndLimits extends React.PureComponent {
  state = {
    activeTab: PAGE_TABS.DEPOSIT,
    activeClass: {
      [PAGE_TABS.DEPOSIT]: true,
    },
  };

  switchTab = tabName => {
    this.setState({activeTab: tabName, activeClass: {[tabName]: true}});
  };

  componentDidMount() {
    this.props.getFees();
    this.props.getPayment();
    if (!Object.keys(this.props.UserTradeFeeReducer.data).length) {
      this.props.UserTradeFeeUsing();
    }
  }

  render() {
    const {
      WsWalletReducer,
      AccountListReducer,
      PaymentPlanReducer,
      FeesSettingsReducer,
      UserTradeFeeReducer,
      CurrencyListReducer,
      MarketListReducer,
      CryptoPricesReducer,
    } = this.props;
    const tradeVolumeBTC = UserTradeFeeReducer.data.tradeVolumeBTC;
    const tradeVolumeUSD = UserTradeFeeReducer.data.tradeVolumeUSD;
    const tradeVolumeLastMonthUSD = UserTradeFeeReducer.data.tradeVolumeLastMonthUSD;
    const tradeVolumeLastMonthBTC = UserTradeFeeReducer.data.tradeVolumeLastMonthBTC;

    const wallets = CurrencyListReducer.list;
    const markets = MarketListReducer.list;

    if (
      WsWalletReducer.fetching ||
      FeesSettingsReducer.fetching ||
      UserTradeFeeReducer.fetching ||
      !wallets ||
      !markets
    ) {
      return <BlockPreloader />;
    }
    const trading = FeesSettingsReducer.data !== null && FeesSettingsReducer.data.trading;

    return (
      <Fragment>
        <div className='block-account-info'>
          {trading ? (
            <div className='block-account-item'>
              <div className='block-account-item_form'>
                <div className='title'>
                  <I18n tKey='balances.total_trading' />
                </div>
                {/* <div className="value">{tradeVolumeBTC  ?
                  <CurrencyView type="BTC" amount={tradeVolumeBTC}/> : 'n/a'} BTC
                </div> */}
                <div className='calc'>
                  {tradeVolumeUSD ? <CurrencyView isFiat amount={tradeVolumeUSD} /> : 'n/a'} USD
                </div>
              </div>
              <div className='block-account-item_form'>
                <div className='title'>
                  <I18n tKey='balances.days_trading' />
                </div>
                {/* <div className="value"> {tradeVolumeLastMonthBTC ?
                    <CurrencyView type="BTC" amount={tradeVolumeLastMonthBTC}/> : 'n/a'} BTC
                </div> */}
                <div className='calc'>
                  {tradeVolumeLastMonthUSD ? <CurrencyView isFiat amount={tradeVolumeLastMonthUSD} /> : 'n/a'} USD
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <div>
              <I18n tKey='common.link_limit' />
            </div>

            {this.state.activeClass[PAGE_TABS.DEPOSIT] && (
              <div className='tab-pane-subtext'>
                <div>
                  <I18n tKey='fees.diligation' />{' '}
                </div>
                <div>
                  {' '}
                  <a href='https://www.riigiteataja.ee' target='_blank' /> <b>riigiteataja.ee</b>
                </div>
                <a href='https://www.riigiteataja.ee/en/eli/530062021006/consolide' target='_blank'>
                  <I18n tKey='fees.prevention_act' />
                </a>
                <div> Riigi Teataja </div>
              </div>
            )}
            {this.state.activeTab === PAGE_TABS.WITHDRAW && (
              <div className='tab-pane-msg'>
                <I18n tKey='common.withdraw_confirmatiom_time_msg' />
              </div>
            )}
          </div>
          <div className='tab-pane-filter'>
            <a
              className={`item-filter ${this.state.activeClass[PAGE_TABS.DEPOSIT] ? 'active' : ''}`}
              onClick={() => this.switchTab(PAGE_TABS.DEPOSIT)}
            >
              <I18n tKey='common.deposit' />
            </a>
            <a
              className={`item-filter ${this.state.activeClass[PAGE_TABS.WITHDRAW] ? 'active' : ''}`}
              onClick={() => this.switchTab(PAGE_TABS.WITHDRAW)}
            >
              <I18n tKey='common.withdraw' />
            </a>
            <a
              className={`item-filter ${this.state.activeClass[PAGE_TABS.TRADING] ? 'active' : ''}`}
              onClick={() => this.switchTab(PAGE_TABS.TRADING)}
            >
              <I18n tKey='common.trading' />
            </a>
          </div>
        </div>
        <div className='tab-pane-body'>
          {this.state.activeTab === PAGE_TABS.DEPOSIT || this.state.activeTab === PAGE_TABS.WITHDRAW ? (
            <WithdrawDeposits
              account={(AccountListReducer.list || [])[0]}
              wsWallet={WsWalletReducer}
              cryptoPrices={CryptoPricesReducer}
              isDeposit={this.state.activeTab === PAGE_TABS.DEPOSIT}
              fees={PaymentPlanReducer.list}
              wallets={wallets}
              tradingParams={FeesSettingsReducer}
            />
          ) : (
            <TradingFees
              account={(AccountListReducer.list || [])[0]}
              fees={PaymentPlanReducer.list}
              tradingParams={FeesSettingsReducer}
              markets={markets}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  PaymentPlanReducer,
  FeesSettingsReducer,
  WsWalletReducer,
  AccountListReducer,
  UserTradeFeeReducer,
  CurrencyListReducer,
  MarketListReducer,
  CryptoPricesReducer,
}) => ({
  PaymentPlanReducer,
  FeesSettingsReducer,
  WsWalletReducer,
  AccountListReducer,
  UserTradeFeeReducer,
  CurrencyListReducer,
  MarketListReducer,
  CryptoPricesReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getFees: Actions.FeesSettings.action,
        getPayment: Actions.PaymentPlan.action,
        UserTradeFeeUsing: Actions.UserTradeFeeUsing.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(FeesAndLimits));

export default connectedWithRouter;
