import React, {Component} from 'react';
import classNames from 'classnames';

import I18n from './I18n';

class CheckBox extends Component {
  handleOnChange = () => {
    const {name = null, value = null, onChange} = this.props;

    onChange && onChange({name, value});
  };

  render() {
    const {
      isChecked = false,
      label,
      name,
      hideOffset = false,
      id,
      isError = false,
      usePreLine,
      useHtmlParser,
      labelClassName,
    } = this.props;
    return (
      <div
        className={classNames('ui-form-checkbox', {
          ['ui-form-checkbox--without-offset']: hideOffset,
          ['ui-form-checkbox--error']: isError,
        })}
      >
        <input checked={isChecked} name={name} onChange={this.handleOnChange} type='checkbox' id={id} />
        <label htmlFor={id}>
          {label && (
            <I18n useHtmlParser={useHtmlParser} className={labelClassName} usePreLine={usePreLine} tKey={label} />
          )}
        </label>
      </div>
    );
  }
}

export default CheckBox;
