import React, {Component, Fragment} from 'react';

import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import {ReactComponent as WarningGtiIc} from '../../../../assets/images/icon/warning.svg';
import I18n from '../../cmp/helpers/I18n';
import {bindActionCreators} from 'redux';
import {GetSepaWithdrawIds} from '../../api/Actions';
import {connect} from 'react-redux';
import Select, {components} from 'react-select';
import {CUSTOM_SELECT_STYLES, SELECT_THEME} from './constants';
import {translate} from '../../utils/i18n';
import {CustomInput} from '../../cmp/helpers/CustomInput';
import BigNumber from 'bignumber.js';

const validator = new RegExp(`(^\\d{0,9}$)|(^\\d*\\.\\d{0,2}$)`);
const EXCHANGE_FEE_MIN_AMOUNT = 5;
const EXCHANGE_FEE = 0.5;
const BANKERA_FEE = 0.04;
const BANKERA_FEE_MIN_AMOUNT = 2;

const Option = props => {
  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: e => {
          e.stopPropagation(); // doesn't do anything, sadly
          e.preventDefault(); // doesn't do anything, sadly
          setTimeout(() => props.innerProps.onClick(e));
        },
      }}
    />
  );
};

class SepaWithdraw extends Component {
  state = {
    withdrawalInfo: undefined,
    amount: '',
    twofaCode: '',
    options: [],
  };

  componentDidMount() {
    this.props.getSepaIds();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.SepaWithdrawIdsReducer.list || []).length) {
      const options = nextProps.SepaWithdrawIdsReducer.list.map(data => {
        return {label: data.payerAccountNumber, value: data};
      });

      this.setState({options, withdrawalInfo: options.length ? options[0] : undefined});
    }
  }

  handleInputChange = (name, value) => {
    this.setState({[name]: value});
  };

  handleSelect = item => {
    this.setState({withdrawalInfo: item});
  };

  calcFee = () => {
    if (!Number(this.state.amount)) {
      return '';
    }

    const exchangeFee = new BigNumber(this.state.amount)
      .multipliedBy(new BigNumber(EXCHANGE_FEE).div(100).toNumber())
      .toNumber();
    const minExchangeFee = exchangeFee < EXCHANGE_FEE_MIN_AMOUNT ? EXCHANGE_FEE_MIN_AMOUNT : exchangeFee;
    const bankeraFee = new BigNumber(this.state.amount).multipliedBy(new BigNumber(BANKERA_FEE).div(100)).toNumber();
    const minBankeraFee = bankeraFee < BANKERA_FEE_MIN_AMOUNT ? BANKERA_FEE_MIN_AMOUNT : bankeraFee;

    return new BigNumber(this.state.amount).plus(minExchangeFee).plus(minBankeraFee).toFixed(2);
  };

  makeWithdraw = () => {
    const {amount, twofaCode, withdrawalInfo} = this.state;

    this.props.requestSepaWithdraw({
      bankeraDepositId: withdrawalInfo.value.id,
      receivingTargetAddr: withdrawalInfo.value.payerAccountNumber,
      currencyId: this.props.currencyId,
      amount,
      twofaCode,
    });
  };

  render() {
    const {SepaWithdrawIdsReducer, UserSettingsReducer, isWithdrawFetching, requestSepaWithdraw, availableToWithdraw} =
      this.props;
    const {list = [], fetching} = SepaWithdrawIdsReducer;
    const settings = UserSettingsReducer.data || {};
    const {withdrawalInfo, amount = 0, twofaCode, options} = this.state;

    if (fetching) {
      return (
        <div className='withdraw_sepa_form'>
          <I18n tKey='common:loading' />
        </div>
      );
    }

    if (!fetching && !(list || []).length) {
      return (
        <div className='withdraw_sepa_form warning'>
          <SvgWrapper className='ic-size-06' SvgCmp={WarningGtiIc} />
          <I18n tKey='withdraw_sepa.deposit_warning' />
        </div>
      );
    }

    const amountWithFee = this.calcFee();

    const btnDisabled =
      !withdrawalInfo || !amount || !twofaCode || isWithdrawFetching || +availableToWithdraw < +amountWithFee;
    return (
      <div>
        {!!withdrawalInfo && (
          <div className='withdrawal-info-details'>
            <div>
              <I18n tKey='withdraw_sepa:payerAccountNumber' />: {withdrawalInfo.value.payerAccountNumber}
            </div>
            <div>
              <I18n tKey='withdraw_sepa:payerBank' />: {withdrawalInfo.value.payerBank}
            </div>
            <div>
              <I18n tKey='withdraw_sepa:payerName' />: {withdrawalInfo.value.payerName}
            </div>
            <div style={{minHeight: 19}}>
              {amountWithFee && (
                <Fragment>
                  <I18n tKey='balances.amount_with_fee' />: {amountWithFee}
                </Fragment>
              )}
            </div>
          </div>
        )}

        <div className='withdraw_sepa_form with-fields'>
          <Select
            components={{Option}}
            styles={CUSTOM_SELECT_STYLES}
            theme={SELECT_THEME}
            placeholder={translate('withdraw_sepa.select_deposit_id')}
            isSearchable={false}
            name='withdrawId'
            options={options}
            onChange={this.handleSelect}
            value={withdrawalInfo}
          />

          <div className='input-form-wrapper'>
            <CustomInput
              onChange={this.handleInputChange}
              value={this.state.amount}
              name='amount'
              title='balances:amount_to_receive'
              useNumberValidation
              validatorRegExp={validator}
            />
          </div>

          {settings.googleAuthAllowanceFlag && (
            <div className='input-form-wrapper'>
              <CustomInput
                onChange={this.handleInputChange}
                value={this.state.twofaCode}
                name='twofaCode'
                title='common.2fa_code'
                useNumberValidation
                maxLength={6}
              />
            </div>
          )}

          <button
            style={{minWidth: 221, minHeight: 56}}
            disabled={btnDisabled}
            onClick={this.makeWithdraw}
            type='button'
            className='btn btn-md btn-primary'
          >
            <I18n tKey='common.withdraw' />
          </button>
        </div>

        <div className='sepa-withdraw-fee'>
          <SvgWrapper className='ic-size-06' SvgCmp={WarningGtiIc} />
          <I18n tKey='withdraw_sepa:fees_hint' />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({SepaWithdrawIdsReducer, UserSettingsReducer}) => ({
  SepaWithdrawIdsReducer,
  UserSettingsReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({getSepaIds: GetSepaWithdrawIds.action}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SepaWithdraw);
