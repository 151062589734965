// @flow
import * as React from 'react';
import {Fragment} from 'react';
import {connect} from 'react-redux';
import copy from 'copy-to-clipboard';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';

import {ENABLE_2FA_STEP4_POPUP} from './Enable2FAStep4Popup';
import {CLOSE_POPUP, OPEN_POPUP} from '../../../reducers/PopupReducer';
import {ReactComponent as KeyPrintIc} from '../../../../../assets/images/icon/print-n.svg';
import {ReactComponent as CopyIc} from '../../../../../assets/images/icon/copy.svg';
import {SvgWrapper} from '../../helpers/SvgWrapper';
import I18n from '../../helpers/I18n';

export const ENABLE_2FA_STEP3_POPUP = 'ENABLE_2FA_STEP3_POPUP';

class Enable3FAStep2Popup extends React.PureComponent<void, ComponentState> {
  state = {
    isCopied: false,
  };

  copyToClipboard(data) {
    copy(data);
    this.setState({isCopied: true});
    setTimeout(() => {
      this.setState({isCopied: false});
    }, 1000);
  }

  downloadSecret(secret) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(secret));
    element.setAttribute('download', '2fa_secret.txt');

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  render() {
    const {AddTwoFactorAuthReducer} = this.props;
    const twofaData = AddTwoFactorAuthReducer.data;

    if (!twofaData) {
      return;
    }

    return (
      <Fragment>
        <div className='modal-2FA-form text-center'>
          <h2>
            <I18n tKey='popup.long' />
          </h2>
          <div className='modal-2FA-form-important'>
            <i className='icon icon-attention' />
            <I18n tKey='popup.important' />
          </div>
          <div className='modal-2FA-form-text'>
            <I18n tKey='popup.backup_key' />
          </div>
          <div className='block-key'>
            <div className='block-key-text'>{twofaData.mysteryInf}</div>
            <div className='block-key-btn'>
              <a onClick={this.downloadSecret.bind(this, twofaData.mysteryInf)}>
                <SvgWrapper themeWatch className='icon ic-size-10' SvgCmp={KeyPrintIc} />
                <I18n tKey='popup.save_key' />
              </a>
              <a onClick={this.copyToClipboard.bind(this, twofaData.mysteryInf)}>
                <SvgWrapper themeWatch className='icon icon-key-print ic-size-11' SvgCmp={CopyIc} />
                {this.state.isCopied ? <I18n tKey='common:copied' /> : <I18n tKey='popup.copy_key' />}
              </a>
            </div>
          </div>
          <br />
          <a className='btn btn-primary btn-lg' onClick={this.props.nextStep}>
            <I18n tKey='common.next' />
          </a>
          <div className='modal-text-bottom'>
            <a href='/page/faq/all' target='_blank'>
              <I18n tKey='faq.about_2fa' />
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({AddTwoFactorAuthReducer}) => ({AddTwoFactorAuthReducer});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({}, dispatch),
    nextStep: () => dispatch({type: OPEN_POPUP, name: ENABLE_2FA_STEP4_POPUP}),
    close: () => dispatch({type: CLOSE_POPUP}),
  };
};
const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Enable3FAStep2Popup));

export default connectedWithRouter;
