import * as React from 'react';
import {connect} from 'react-redux';
import {translate} from '../utils/i18n';
import {DateRangePicker, DateRange} from 'react-date-range';
import {formatCalendarLngPrefix} from '../utils/utils';
import moment from 'moment';
import {ReactComponent as ArrowDown} from '../../../assets/images/icon/dropdown-mark.svg';
import {SvgWrapper} from '../cmp/helpers/SvgWrapper';

const PERIODS = [
  {title: 'volume_hour', value: 'HOUR'},
  {title: 'volume_day', value: 'DAY'},
  {title: 'volume_week', value: 'WEEK'},
  {title: 'volume_month', value: 'MONTH'},
  {title: 'volume_year', value: 'YEAR'},
];
const STORAGE_PERIOD_KEY = 'header_volume_key';
const STORAGE_MODE_KEY = 'header_volume_mode_key';

const initialRange = [
  {
    startDate: null,
    endDate: null,
    key: 'selected',
  },
];

class VolumeDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      period: localStorage.getItem(this.getStorageKey(STORAGE_PERIOD_KEY)) || 'MONTH',
      mode: localStorage.getItem(this.getStorageKey(STORAGE_MODE_KEY)) || 'exchange',
      isOpen: false,
      isDatePickerOpen: false,
      range: initialRange,
    };
  }

  componentDidMount() {
    this.propagateChange();
  }

  getStorageKey(key) {
    return this.props.type + ':' + key;
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    const {SessionReducer} = this.props;
    if (SessionReducer.uuid !== prevProps.SessionReducer.uuid) {
      if (this.state.mode === 'user') {
        this.setState({mode: 'exchange'});
      }
      this.propagateChange();
    }
  }

  propagateChange() {
    this.hide();
    setTimeout(() => {
      const {period, mode} = this.state;
      const req = {period};
      const uuid = this.isLoggedIn() && mode === 'user' && this.getUuid();
      if (uuid) {
        req.uuid = uuid;
      }
      if (period === 'CUSTOM') {
        const {range} = this.state;
        const {startDate, endDate} = range[0];
        req.dateFrom = this.formatDate(startDate);
        req.dateTo = this.formatDate(endDate);
      }
      this.props.onChange(req);
    }, 0);
  }

  isLoggedIn() {
    const {SessionReducer} = this.props;
    return !!SessionReducer.masterPersonality && !!SessionReducer.masterPersonality.uniqueSymbolRow;
  }

  getUuid() {
    const {SessionReducer} = this.props;
    return SessionReducer.uuid;
  }

  changePeriod(period) {
    this.setState({period, range: initialRange});
    try {
      localStorage.setItem(this.getStorageKey(STORAGE_PERIOD_KEY), period);
    } catch (e) {
      console.warn(e);
    }
    this.propagateChange();
  }

  changeMode(mode) {
    this.setState({mode});
    try {
      localStorage.setItem(this.getStorageKey(STORAGE_MODE_KEY), mode);
    } catch (e) {
      console.warn(e);
    }
    this.propagateChange();
  }

  show() {
    this.setState({isOpen: true});
  }

  hide() {
    this.setState({isOpen: false});
  }

  setCustomRange() {
    this.setState({isDatePickerOpen: true});
  }

  formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  renderCustomPeriod() {
    const {range} = this.state;
    const {startDate, endDate} = range[0];

    return (
      <span>
        {this.formatDate(startDate)}&nbsp;-&nbsp;{this.formatDate(endDate)}
      </span>
    );
  }

  renderCurrentPeriod() {
    const {LngReducer} = this.props;
    const {period} = this.state;

    if (period === 'CUSTOM') {
      return this.renderCustomPeriod();
    }

    const periodToShow = PERIODS.find(p => p.value === period);
    if (!periodToShow) {
      return null;
    }

    return <span>{translate(`header.${periodToShow.title}`, LngReducer.dictionary)}</span>;
  }

  renderCurrentMode() {
    const {LngReducer} = this.props;
    let translationKey = 'exchange_volume';
    if (this.isLoggedIn() && this.state.mode === 'user') {
      translationKey = 'user_volume';
    }

    return <span>&nbsp;{translate(`header.${translationKey}`, LngReducer.dictionary)}</span>;
  }

  changeDateRange(update) {
    const {selected} = update;
    this.setState({
      range: [selected],
    });
    if (selected.startDate.getTime() !== selected.endDate.getTime()) {
      this.setState({
        isDatePickerOpen: false,
        period: 'CUSTOM',
      });
      this.propagateChange();
    }
  }

  render() {
    const {LngReducer} = this.props;
    const {isOpen} = this.state;

    let minDate, maxDate;
    if (this.state.isDatePickerOpen) {
      maxDate = new Date();
      minDate = new Date(new Date().setFullYear(maxDate.getFullYear() - 1));
    }

    return (
      <div
        className={'volume-stats-mode-selector-container ' + (isOpen ? 'open' : '')}
        onMouseOver={() => this.show()}
        onMouseOut={() => this.hide()}
      >
        <div className='current-mode'>
          <SvgWrapper themeWatch className='icon icon-dropdown ic-size-03' SvgCmp={ArrowDown} />
          {this.renderCurrentPeriod()}
          {this.renderCurrentMode()}
        </div>

        <div className='selector'>
          {this.isLoggedIn() && (
            <ul className='mode'>
              <li
                key={0}
                onClick={() => this.changeMode('exchange')}
                className={this.state.mode === 'exchange' ? 'active' : ''}
              >
                {translate(`header.exchange_volume`, LngReducer.dictionary)}
              </li>
              <li
                key={1}
                onClick={() => this.changeMode('user')}
                className={this.state.mode === 'user' ? 'active' : ''}
              >
                {translate(`header.user_volume`, LngReducer.dictionary)}
              </li>
            </ul>
          )}

          <ul className='period'>
            {PERIODS.map(p => {
              return (
                <li
                  onClick={() => this.changePeriod(p.value)}
                  key={p.value}
                  className={this.state.period === p.value ? 'active' : ''}
                >
                  {translate(`header.${p.title}`, LngReducer.dictionary)}
                </li>
              );
            })}
            {this.isLoggedIn() && (
              <li
                onClick={() => this.setCustomRange()}
                key='custom'
                className={this.state.period === 'CUSTOM' ? 'active' : ''}
              >
                {translate(`date_range.select`, LngReducer.dictionary)}
              </li>
            )}
          </ul>

          {this.state.isDatePickerOpen && (
            <div className='range-picker'>
              <DateRangePicker
                ranges={this.state.range}
                staticRanges={[]}
                inputRanges={[]}
                minDate={minDate}
                maxDate={maxDate}
                onChange={item => {
                  this.changeDateRange(item);
                }}
                locale={formatCalendarLngPrefix(this.props.LngReducer.lng)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({LngReducer, SessionReducer}) => ({LngReducer, SessionReducer});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VolumeDatePicker);
