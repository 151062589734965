import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';

import Currency from '../../../../utils/currency';
import {CurrencyView} from '../../../../cmp/helpers/CurrencyView';
import I18n from '../../../../cmp/helpers/I18n';

class StopOrderRow extends Component {
  render() {
    const {order, isOrderCancelling, showCancelOrderPopup} = this.props;
    const market = (this.props.MarketListReducer.list || []).find(
      ({identificatSymbol}) => identificatSymbol === order.marketId
    );

    if (!market) {
      return <tr></tr>;
    }

    return (
      <tr>
        <td className='value'>{market.mBanner}</td>
        <td className={order.side !== 'BUY' ? 'red' : 'green'}>{order.side}</td>
        <td>{moment(order.createdAt).format('MMM DD YYYY h:mm A')}</td>
        <td>{Currency.show(order.amount, market.leftMonetaryUnitCode, true)}</td>
        <td>
          {order.type === 'LIMIT' ? (
            <CurrencyView amount={order.price} type={market.rightMonetaryUnitCode} />
          ) : (
            <I18n tKey='common.market' />
          )}
        </td>
        <td>
          {order.type === 'LIMIT' ? (
            <CurrencyView amount={order.amount * order.price} type={market.rightMonetaryUnitCode} />
          ) : (
            <I18n tKey='common.market' />
          )}
        </td>
        <td>
          <CurrencyView amount={order.stop} type={market.rightMonetaryUnitCode} />
        </td>
        <td>
          {!isOrderCancelling && this.props.showCancelOrderPopup && (
            <a
              onClick={showCancelOrderPopup({
                odrIdentifier: order.id,
                stop: true,
              })}
            >
              {' '}
              <I18n tKey='common.cancel' />{' '}
            </a>
          )}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = ({MarketListReducer}) => {
  return {
    MarketListReducer,
  };
};

export default connect(mapStateToProps, undefined)(StopOrderRow);
