// @flow
import * as React from 'react';
import I18n from '../../helpers/I18n';
import moment from 'moment';

export default class FooterPopup extends React.PureComponent {
  render() {
    return (
      <div className='modal-signup_footer'>
        ©{moment().format('YYYY')} <I18n tKey='common.footer' />
      </div>
    );
  }
}
