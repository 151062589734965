// import dictionary from "../../js/dictionary.yml";
import store from '../store';
import React from 'react';
import I18n from '../cmp/helpers/I18n';

export const translate = (key, dictionary) => {
  const vocabulary = dictionary || store.getState().LngReducer.dictionary || {};
  if (typeof key != 'string') {
    return key;
  }
  key = key.replace('.', ':');
  return vocabulary[key] || key;
};

export const i18n = {
  UNKNOWN_ERROR: {
    EN: 'Unknown server error. Please contact to customer support ',
  },
  WRONG_ORDER: {
    EN: 'Wrong order',
  },
  WRONG_ACCOUNT: {
    EN: 'Wrong account',
  },
  WRONG_MARKET: {
    EN: 'Wrong market',
  },
  ORDER_ALREADY_CLOSED: {
    EN: 'Order already closed',
  },
  CLOSE_ORDER_NOT_ENOUGH_RESERVE: {
    EN: 'Not enough reserve',
  },
  TRADE_WRONG_AMOUNT: {
    EN: 'Wrong amount',
  },
  OPEN_ORDER_NOT_ENOUGH_MONEY: {
    EN: 'Not enough money',
  },
  INCORRECT_USER_REGISTRATION: {
    EN: 'Incorrect registration',
  },
  WITHDRAW_DISABLED: {
    EN: 'Withdraw is disabled',
  },
  TRADING_DISABLED: {
    EN: 'Trading is disabled',
  },
  INCORRECT_AMOUNT: {
    EN: 'Incorrect amount',
  },
  DUPLICATED_EMAIL: {
    EN: 'Email already registered',
  },
  DUPLICATED_USERNAME: {
    EN: 'Username already registered',
  },
  INVALID_LOGIN_OR_PASSWORD: {
    EN: 'User not found or password is invalid',
  },
  ACCOUNT_NOT_FOUND: {
    EN: 'User not found or password is invalid',
  },
  INVALID_PASSWORD: {
    EN: 'User not found or password is invalid',
  },
  USER_NOT_FOUND: {
    EN: 'User not found or password is invalid',
  },
  PASSWORDS_ARE_SAME: {
    EN: 'It was your old password, please enter new one',
  },
  USER_DISABLED: {
    EN: 'User was disabled. Please contact to customer support',
  },
  TWO_FACTOR_VECTOR_ALREADY_ENABLED: {
    EN: 'Two factor authentication already enabled',
  },
  TWO_FACTOR_VECTOR_DISABLED: {
    EN: 'Two factor authentication is disabled',
  },
  TWO_FACTOR_INVALID_CODE: {
    EN: 'Invalid code',
  },
  TWO_FACTOR_TOKEN_EXPIRED: {
    EN: 'Token was expired',
  },
  TWO_FACTOR_INVALID_TOKEN: {
    EN: 'Invalid token',
  },
  TWO_FACTOR_VECTOR_UNKNOWN: {
    EN: 'Wrong two factor',
  },
  EMAIL_CONFIRM_TOKEN_EXPIRED: {
    EN: 'Confirmation token was expired',
  },
  EMAIL_CONFIRM_BAD_TOKEN: {
    EN: 'Invalid token',
  },
  EMAIL_ALREADY_CONFIRMED: {
    EN: 'Email already confirmed',
  },
  NO_PHONE_NUMBER: {
    EN: 'Phone number cannot be empty',
  },
  TOO_OFTEN: {
    EN: 'Too often login attempts',
  },
  BAD_TOKEN: {
    EN: 'Token was invalid. Please relogin',
  },
  EXPIRED_TOKEN: {
    EN: 'Token was expired. Please relogin',
  },
  ACCESS_DENIED: {
    EN: 'Please login',
  },
  BAD_REQUEST: {
    EN: 'Bad request',
  },
  RECAPTCHA_ERROR: {
    EN: 'Recaptcha error',
  },
  NOT_FOUND: {
    EN: 'User not found',
  },
  EMAIL_CONFIRM_REQUIRED: {
    EN: 'Please confirm your email address',
  },
  WRONG_WITHDRAW: {
    EN: 'Wrong token or withdraw id',
  },
  WITHDRAW_ACCOUNT_TOO_NEW: {
    EN: 'You will be able to request withdraw in 48 hours after registration',
  },
  NOT_SUPPORTED_CURRENCY: {
    EN: 'Currency is not supported',
  },
  REQUESTING_ADDRESS_TOO_OFTEN: {
    EN: 'Too often request, please try again later',
  },
  BAD_TWOFA_CODE: {
    EN: 'Bed 2fa code',
  },
};

export const parseError = error => {
  let result = null;
  if (!error) {
    return null;
  }

  const message = error.info || error.error || error + '';

  if (error.error === 'VALIDATION_ERROR') {
    if (error.details && Array.isArray(error.details)) {
      result =
        'Validation error: ' +
        error.details
          .map(r => {
            return r.field + ' ' + r.details + ' ' + r.value;
          })
          .join(' and ');
    } else {
      result = error.error;
    }
  } else {
    const keys = Object.keys(i18n);

    const item = keys.filter(key => {
      return message === key;
    });

    if (item && item[0]) {
      result = i18n[item[0]].EN;
    } else {
      result = i18n.UNKNOWN_ERROR.EN;
    }
  }

  return result;
};
