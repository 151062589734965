import React from 'react';
import PropTypes from 'prop-types';

import ReactSpinner from 'react-loader-spinner';
import classNames from 'classnames';

const Loader = ({open, type = 'Oval', className}) => {
  return (
    open && (
      <section className={classNames('loader', className)}>
        <ReactSpinner type={type} color='#9F8AFF' />
      </section>
    )
  );
};

Loader.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([
    'Audio',
    'BallTriangle',
    'Bars',
    'Circles',
    'Grid',
    'Hearts',
    'Oval',
    'Puff',
    'Rings',
    'TailSpin',
    'Watch',
    'RevolvingDot',
    'Triangle',
    'Plane',
    'MutatingDots',
    'CradleLoader',
  ]),
};

export default Loader;
