import PropTypes from 'prop-types';
import I18n from '../helpers/I18n';

class ModalNotify extends React.Component {
  displayName: 'ModalNotify';
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  render() {
    const {isShow} = this.props;
    return (
      <div className={isShow ? 'modal fade in' : 'modal fade'} style={{display: !this.state.isOpen ? 'none' : ''}}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <button
              type='button'
              className='btn close'
              onClick={() => this.setState({isOpen: !this.state.isOpen})}
            ></button>
            <div className='modal-header'>
              <h3 className='title'>
                <I18n tKey='modal.notify_title' />
              </h3>
            </div>
            <div className='modal-settings'>
              <div className='form-group-line'>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' />
                    <span className='checkmark'></span>
                    <I18n tKey='common.trading' />
                  </label>
                </div>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' />
                    <span className='checkmark'></span>
                    <I18n tKey='common.system' />
                  </label>
                </div>
              </div>
              <div className='form-group-line'>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' />
                    <span className='checkmark'></span>
                    <I18n tKey='common.finance' />
                  </label>
                </div>
                <div className='box-control'>
                  <label className='form-checkbox'>
                    <input type='checkbox' />
                    <span className='checkmark'></span>
                    <I18n tKey='common.news_events' />
                  </label>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='box-btn'>
                <a href='#' className='btn btn-primary btn-lg btn-full'>
                  <span className='upper'>
                    <I18n tKey='common.accept' />
                  </span>
                </a>
              </div>
              <div className='box-btn'>
                <a href='#' className='link link-control'>
                  <I18n tKey='common.cancel' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalNotify;
