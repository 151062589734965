import React, {Component} from 'react';
import {CURRENCIES_DIGITS} from '../../utils/currenciesDigits';
import {changeFormat} from '../../utils/utils';
import {WITHDRAW_CURRENCY_PRECISION_SUFFIX} from './CurrencyView';

export class CurrencyInput extends Component {
  digitsCash = null;

  returnDigits = () => {
    const {currencyType = '', useWithdrawPrecision} = this.props;
    if (!this.digitsCash || this.digitsCash.curType !== currencyType) {
      let getDigitsForCurCode = currencyType.toUpperCase();
      if (useWithdrawPrecision && CURRENCIES_DIGITS.has(getDigitsForCurCode + WITHDRAW_CURRENCY_PRECISION_SUFFIX)) {
        getDigitsForCurCode += WITHDRAW_CURRENCY_PRECISION_SUFFIX;
      }
      this.digitsCash = {curType: currencyType, digits: currencyType ? CURRENCIES_DIGITS.get(getDigitsForCurCode) : 2};
    }
    return this.digitsCash.digits;
  };

  onChange = e => {
    let numberRegex;
    const digits = this.returnDigits();
    if (digits > 0) {
      numberRegex = new RegExp(`(^\\d{0,9}$)|(^\\d*\\.\\d{0,${digits}}$)`);
    } else {
      numberRegex = /^[\d\s]*$/;
    }
    if (numberRegex.test(e.target.value)) {
      this.props.onChange(e.target.name, e.target.value);
    }
  };

  onBlur = ev => {
    if (!this.props.readOnly) {
      let defaultValue = '';
      const currencyDigits = this.returnDigits();
      const splitedAmount = this.props.value.toString().split('.');
      if (!splitedAmount[0] || currencyDigits === 0) {
        return;
      }
      if (!splitedAmount[1]) {
        let digits = '';
        Array.from(new Array(currencyDigits)).forEach(i => (digits += '0'));
        defaultValue = `${splitedAmount[0]}.${digits}`;
      } else if (splitedAmount[1].length < currencyDigits) {
        let emptyFigures = currencyDigits - splitedAmount[1].length;
        defaultValue = this.props.value;
        Array.from(new Array(emptyFigures)).forEach(i => (defaultValue += '0'));
      }
      defaultValue && this.props.onChange(ev.target.name, defaultValue);
    }
  };

  render() {
    const {
      name,
      placeholder,
      value,
      className,
      readOnly,
      currencyType = '',
      useAutoDigits,
      useAutoDigitsPlaceholder,
    } = this.props;
    let inputValue = value;
    let inputPlaceHolder = placeholder;
    if (useAutoDigits && value) {
      inputValue = changeFormat(value, CURRENCIES_DIGITS.get(currencyType.toUpperCase()) || 0);
    }
    if (useAutoDigitsPlaceholder) {
      inputPlaceHolder = changeFormat(placeholder, CURRENCIES_DIGITS.get(currencyType.toUpperCase()) || 0);
    }
    return (
      <div>
        <input
          placeholder={inputPlaceHolder}
          type='text'
          onChange={this.onChange}
          onBlur={this.onBlur}
          readOnly={readOnly}
          name={name}
          value={inputValue}
          className={className ? className : 'form-control'}
          autoComplete='off'
        />
      </div>
    );
  }
}
