// @flow
import * as React from 'react';
import {Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {SvgWrapper} from '../../helpers/SvgWrapper';
import {ENABLE_2FA_STEP2_POPUP} from './Enable2FAStep2Popup';
import {CLOSE_POPUP, OPEN_POPUP} from '../../../reducers/PopupReducer';
import {ReactComponent as Pic2Fa} from '../../../../../assets/images/modal/enable2Fa.svg';
import I18n from '../../helpers/I18n';
import storageHelper from 'storage-helper';
import {NOTIFY_2FA_DISABLED} from '../../../layout/Leftbar';

export const ENABLE_2FA_STEP1_POPUP = 'ENABLE_2FA_STEP1_POPUP';

class Enable2FAStep1Popup extends React.PureComponent<void, ComponentState> {
  closeModal = () => {
    this.props.close();
    storageHelper.setItem(NOTIFY_2FA_DISABLED, true);
  };

  render() {
    return (
      <Fragment>
        <div className='modal-2FA-form'>
          <div className='img-left'>
            <SvgWrapper className='enable-2fa-ic ic-size-01' SvgCmp={Pic2Fa} />
          </div>
          <h2>
            <I18n tKey='popup.increase_protect' />
          </h2>
          <div className='modal-2FA-form-text'>
            <I18n tKey='popup.recommended' />
          </div>
          <div className='modal-2FA-form-btn'>
            <a onClick={this.props.nextStep} className='btn btn-primary btn-md'>
              <I18n tKey='popup.enable_2fa' />
            </a>
            <a onClick={this.closeModal} className='btn-link'>
              <I18n tKey='common.do_later' />
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => {
  return {
    nextStep: () => dispatch({type: OPEN_POPUP, name: ENABLE_2FA_STEP2_POPUP}),
    close: () => dispatch({type: CLOSE_POPUP}),
  };
};
const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Enable2FAStep1Popup));

export default connectedWithRouter;
