import React, {Component} from 'react';
import copy from 'copy-to-clipboard';

import BlockPreloader from '../helpers/BlockPreloader';
import I18n from '../helpers/I18n';
import {URLMAP} from '../../utils/const';
import {setReferralCode} from './services';

export class ReferralLink extends Component {
  state = {
    isCopied: false,
  };

  changeCopyState = value => {
    this.setState({isCopied: value});
  };

  copyToClipboard(addr) {
    copy(addr);
    this.changeCopyState(true);
    setTimeout(this.changeCopyState.bind(this, false), 1000);
  }

  render() {
    const {UserSettingsReducer} = this.props;
    if (UserSettingsReducer.fetching) {
      return <BlockPreloader />;
    }

    return (
      <div className='block-account-control high-lighted'>
        <div className='block-account-control_value'>
          <div className='title'>
            <I18n tKey='referral.referral_link' />
          </div>
          {UserSettingsReducer.data.referralCode ? (
            <div className='label-value'>
              {window.location.origin}
              {URLMAP.SIGNUP}
              {setReferralCode(UserSettingsReducer.data.referralCode)}
            </div>
          ) : (
            <div>
              <I18n tKey='referral:link:empty_txt' />
            </div>
          )}
        </div>
        {UserSettingsReducer.data.referralCode && (
          <div className='block-account-control_action'>
            <div
              className='link-control'
              onClick={this.copyToClipboard.bind(
                this,
                `${window.location.origin}${URLMAP.SIGNUP}${setReferralCode(UserSettingsReducer.data.referralCode)}`
              )}
            >
              {this.state.isCopied ? <I18n tKey='common:copied' /> : <I18n tKey='common:copy' />}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ReferralLink;
