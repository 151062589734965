import {CurrencyView} from '../../../../cmp/helpers/CurrencyView';
import Formatter from '../../../../utils/formatter';

const getAccuracy = (currencyCode, price) => {
  if (currencyCode === 'BTC' || currencyCode === 'ETH') {
    return 2;
  }

  if (+price < 10) {
    return 8;
  }

  return 4;
};

class TradesItem extends React.PureComponent {
  render() {
    const {trade, market} = this.props;
    const accuracy = getAccuracy(market.leftMonetaryUnitCode.toUpperCase(), trade.price);

    return (
      <div className={`trader_body-value-item ${trade.isDecreasing ? 'down' : 'top'}`}>
        <div className='trader_body-value-item-pair'>
          <CurrencyView
            accuracy={accuracy}
            amount={trade.price}
            type={market.rightMonetaryUnitCode}
            appearance={market.currentT}
          />
        </div>
        <div className='trader_body-value-item-price'>
          <CurrencyView amount={trade.amount} type={market.leftMonetaryUnitCode} appearance={market.currentT} />
        </div>

        <div className='trader_body-value-item-change'>
          <span className=''>{Formatter.time(Math.round(trade.matchedAt))}</span>
        </div>
        <div className='after' style={{opacity: '0'}} />
      </div>
    );
  }
}

export default TradesItem;
