// @flow

import * as Actions from '../api/Actions';

export const NOTIFICATION_INFO = 'check';
export const NOTIFICATION_WARNING = 'var';
export const NOTIFICATION_ERROR = 'error';
export const NOTIFICATION_TOP = 'top';
export const NOTIFICATION_DOWN = 'down';

const USER_KYC_STATUS_CHANGED = 'USER_KYC_STATUS_CHANGED';
const USER_MESSAGE_RECEIVED = 'USER_MESSAGE_RECEIVED';

export const NOTIFICATION_STORAGE_KEY = 'NOTIFICATION_STORAGE_KEY';

export type NotificationType = {|
  date: any,
  level: NOTIFICATION_INFO | NOTIFICATION_WARNING | NOTIFICATION_ERROR | NOTIFICATION_TOP | NOTIFICATION_DOWN,
  title: string,
  description: string,
  isRead: boolean,
  id: string,
|};

const initialState = {notifications: []};

export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ReadMsg.events.onSuccess: {
      const elemIndex = state.notifications.findIndex(elem => elem.identificatSymbol === action.data.identificatSymbol);
      if (elemIndex === -1) {
        return state;
      } else {
        const newNots = [...state.notifications];
        newNots[elemIndex] = {...newNots[elemIndex], readFlag: true};
        return {notifications: newNots};
      }
    }

    case Actions.MsgsList.events.onSuccess: {
      const newMsgs = (action.data.msgItemResp || []).filter(message => !message.readFlag).splice(0, 30);
      const oldMsgs = (action.data.msgItemResp || []).filter(message => message.readFlag).splice(0, 30);
      return {notifications: [...newMsgs, ...oldMsgs] || []};
    }

    case USER_MESSAGE_RECEIVED: {
      const {id, createdAt, messageType, params, read} = action.payload;
      if (read) {
        // if message contains read field which is true, it means that we dont need to add it into message list and duplicate it
        return state;
      }
      const newMessage = {cTime: createdAt, currentMsgT: messageType, addCharValues: params, identificatSymbol: id};
      return {notifications: [...state.notifications, newMessage]};
    }

    default:
      return state;
  }
};
