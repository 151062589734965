import React from 'react';

const colorMap = {
  BTC: '#E9983C',
  ETH: '#627EEA',
  EOS: '#50377C',
  USDT: '#26A17B',
  BNB: '#F3BA2F',
  CRU: '#4C4849',
  UNTB: '#3350BC',
  USDU: '#0D7C67',
  USD: '#2685DB',
  EUR: '#2953E7',
  WAX: '#f89022',
  XRP: '#23292f',
  DOGE: '#C3A634',
  LTC: '#345D9D',
  USDC: '#2775CA',
  Unknown: '#ddd',
  DAI: '#F5AC37',
  UNI: '#FF007A',
  LINK: '#2A5ADA',
  TRX: '#23292f',
  MATIC: '#732BCA',
  DOT: '#E6007A',
  BOBC: '#FFFFFF',
  BUSD: '#F0B90B',
  SOL: '#DC1FFF',
};

const CustomShape = props => {
  const {width, height, currency, x, y} = props;

  return (
    <rect
      x={x}
      y={y}
      rx='10'
      ry='10'
      width={width}
      height={height}
      opacity={0.5}
      fill={colorMap[currency.toUpperCase()] || colorMap.Unknown}
    />
  );
};

export default CustomShape;
