import React, {Component} from 'react';
import Select, {components} from 'react-select';

const fontStyle = {
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '19px',
  letterSpacing: '0em',
  textAlign: 'left',
};

const colourStyles = {
  container: styles => ({...styles, borderRadius: '8px'}),
  control: styles => ({
    ...styles,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 0,
    boxShadow: 'none',
    padding: ' 9px 16px',
  }),
  placeholder: styles => ({...styles, ...fontStyle, color: '#9ba2ac'}),
  singleValue: styles => ({...styles, ...fontStyle, color: 'var(--black)'}),
  menuList: styles => ({...styles, maxHeight: 250, padding: 0, backgroundColor: 'var(--select-menu-bg)'}),
  valueContainer: styles => ({...styles, ...fontStyle, padding: '0px 8px'}),
};

const Option = ({innerProps, isDisabled, data}) => {
  return !isDisabled ? (
    <div className='single-select__option' {...innerProps}>
      <span>{data.value}</span>
    </div>
  ) : null;
};

const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      <div className='single-select__option-selected'>
        <span>{props.data.value}</span>
      </div>
    </components.SingleValue>
  );
};

class SingleSelect extends Component {
  render() {
    const {options, value, onChange, label} = this.props;

    return (
      <div className='single-select'>
        <div className='single-select__title'>{label}</div>
        <div className='single-select__wrapper'>
          <Select
            components={{IndicatorSeparator: null, Option, SingleValue}}
            isSearchable={false}
            value={value}
            onChange={onChange}
            options={options}
            styles={colourStyles}
          />
        </div>
      </div>
    );
  }
}

export default SingleSelect;
