// @flow

import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link, withRouter} from 'react-router-dom';
import I18n from '../../cmp/helpers/I18n';
import {translate} from '../../utils/i18n';
import {URLMAP} from '../../utils/const';

/*Tabs logic flow: exchangePath and other variables are names of folders, the have to include the same files as their subRoutes
 * example:
 * folder: privacy-policy
 * file: terms-and-definitions.[lng].html
 * /assets/static_pages/privacy-policy/terms-and-definitions.EN.html`
 * */

const rootUrl = '/page';
const exchangePath = `${rootUrl}/exchange`;
const infoPath = `${rootUrl}/information`;
const securityPath = `${rootUrl}/security`;
const policyPath = `${rootUrl}/privacy-policy`;
const faqPath = `${rootUrl}/faq`;
const helpCenter = `${rootUrl}/help-center`;

export const returnInfoTabs = (dict, userIsLoggedIn) => {
  const createRouteOption = (label, url) => ({label: translate(label, dict), url});
  return [
    // {
    //   ...createRouteOption("common.exchange", `${exchangePath}/buy-bitcoins`),
    //   subRoutes: [
    //     createRouteOption("footer.buy_bitcoins", `${exchangePath}/buy-bitcoins`,),
    //     createRouteOption("footer.buy_ethereum", `${exchangePath}/buy-ethereum`),
    //     createRouteOption("footer.buy_dash", `${exchangePath}/buy-dash`),
    //   ]
    // },

    // {
    //   ...createRouteOption('common.information', `${infoPath}/license`),
    //   subRoutes: [
    //     createRouteOption('common.license', `${infoPath}/license`),
    //     createRouteOption('footer.user_agreement', `${infoPath}/user-agreement`),
    //     createRouteOption('footer.rules', `${infoPath}/procedure-rules`),
    //     createRouteOption('common.link_limit', `${infoPath}/fees-information`),
    //     createRouteOption('common.api', `${infoPath}/api-doc`),
    //     createRouteOption('footer.asset_info', `/asset_info`),
    //     // createRouteOption("footer.api_doc", `${infoPath}/api-documentation`),
    //     // createRouteOption("common.link_limit", `${infoPath}/fees-and-limits`),
    //   ],
    // },

    // {
    //   ...createRouteOption("footer.security", `${securityPath}/2factor-authentication`),
    //   subRoutes: [
    //     createRouteOption("common.2_fa_auth", `${securityPath}/2factor-authentication`),
    //     // createRouteOption("footer.verification", `${securityPath}/verification`),
    //     createRouteOption("common.api_access", `${securityPath}/api-access`),
    //   ]
    // },

    {
      ...createRouteOption('common.privacy_policy', `${policyPath}/terms-and-definitions`),
      subRoutes: [
        createRouteOption('common.terms_and', `${policyPath}/terms-and-definitions`),
        createRouteOption('common.provisions', `${policyPath}/general-provisions`),
        createRouteOption('common.collection', `${policyPath}/collection-info`),
        createRouteOption('common.use_info', `${policyPath}/use-info`),
        createRouteOption('common.disclosure', `${policyPath}/disclosure-info`),
        createRouteOption('common.cookies', `${policyPath}/cookies`),
        createRouteOption('common.amendments', `${policyPath}/amendments`),
      ],
    },

    {
      ...createRouteOption('faq.faq', `${faqPath}/about`),
      subRoutes: [
        createRouteOption('faq.about_unitex', `${faqPath}/about`),
        createRouteOption('faq.about_2fa', `${faqPath}/all`),
        createRouteOption('faq.verification', `${faqPath}/verification`),
        createRouteOption('faq.login', `${faqPath}/problems`),
        createRouteOption('faq.finance', `${faqPath}/finance`),
        createRouteOption('faq.general', `${faqPath}/general`),
      ],
    },

    {
      ...createRouteOption('helpcenter.title', `${helpCenter}/accounts`),
      subRoutes: userIsLoggedIn
        ? [
            createRouteOption('helpcenter.accounts', `${helpCenter}/accounts`),
            createRouteOption('helpcenter.crypto', `${helpCenter}/crypto`),
            createRouteOption('helpcenter.trading', `${helpCenter}/trading`),
            createRouteOption('helpcenter.fiat', `${helpCenter}/fiat`),
            createRouteOption('helpcenter.business_clients', `${helpCenter}/business`),
          ]
        : [
            createRouteOption('helpcenter.accounts', `${helpCenter}/accounts`),
            createRouteOption('helpcenter.business_clients', `${helpCenter}/business`),
          ],
    },
  ];
};

class TopMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.infoTabs = returnInfoTabs(props.LngReducer.dictionary);
  }

  render() {
    const name = this.props.match.params.name;
    return (
      <div className='page-legacy_tabs'>
        <ul className='nav nav-tabs' role='tablist'>
          {this.infoTabs.map((tab, i) => (
            <li key={i} className='nav-item'>
              <Link className={`nav-link ${tab.url.includes(name) ? 'active' : ''}`} to={tab.url}>
                <I18n tKey={tab.label} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({LngReducer}) => ({LngReducer});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({}, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopMenu));
