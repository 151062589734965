import moment from 'moment-timezone';

export default class Timezone {
  userTimezone = () => {
    let currentTZ = moment.tz.guess();
    let TZOffset = moment.tz(currentTZ).format('zz');
    if (TZOffset === 'EET') {
      return 'Europe/Berlin';
    }
    if (TZOffset === 'EEST') {
      return 'Europe/Athens';
    }
    if (TZOffset === '+03' || TZOffset === 'MST') {
      return 'Europe/Moscow';
    }
    return currentTZ;
  };
}
