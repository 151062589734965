import moment from 'moment/moment';
import React, {Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';

import * as Actions from '../../api/Actions';
import {connect} from 'react-redux';

import {EDIT_KEY} from '../popups/apiKey/CreateApiKeyPopup';
import {REVOKE_KEY, ENABLE_KEY, DISABLE_KEY} from '../popups/apiKey/DeleteApiKey';
import {EnableAccountApiKey, RevokeApiKey, GetAccountApiKeys} from '../../api/Actions';
import {EDIT_API_KEY, REVOKE_API_KEY, ENABLE_API_KEY, DISABLE_API_KEY} from '../../reducers/ApiAccessKeyPopupReducer';
import {ReactComponent as CloseIc} from '../../../../assets/images/icon/close-gray.svg';
import {ReactComponent as MoreIc} from '../../../../assets/images/icon/key-act.svg';
import {ReactComponent as InfoIc} from '../../../../assets/images/icon/key-info.svg';
import {SvgWrapper} from '../helpers/SvgWrapper';
import {translate} from '../../utils/i18n';
import I18n from '../helpers/I18n';

class ApiKey extends React.Component {
  state = {
    visibleKey: false,
    visibleOperations: false,
  };

  keyVisibility = () => {
    this.setState({visibleKey: !this.state.visibleKey});
  };

  operationsVisibility = () => {
    this.setState({visibleOperations: !this.state.visibleOperations});
  };

  onMouseOut = e => {
    const id = e.relatedTarget ? e.relatedTarget.id : null;
    if (id !== 'key-menu') {
      this.setState({visibleOperations: false});
    }
  };

  editApiKey = () => {
    const {accountKeysClear, toggleClear, clearCreateKey, userKey, editKey, clearUpdateKey} = this.props;
    editKey(userKey);
    clearCreateKey();
    accountKeysClear();
    toggleClear();
    clearUpdateKey();
  };

  deleteApiKey = () => {
    const {revokeKeyClear, toggleClear, accountKeysClear, revokeKey, userKey} = this.props;
    revokeKey(userKey);
    accountKeysClear();
    revokeKeyClear();
    toggleClear();
  };

  checkPermissionAccess = (read, write) => {
    if (!read && !write) {
      return null;
    }
    if (read && write) {
      return 'Read & Write';
    }
    if (read) {
      return 'Read only';
    }
    if (write) {
      return 'Write only';
    }
  };

  toggleState = () => {
    const {userKey, revokeKeyClear, toggleClear, accountKeysClear} = this.props;
    accountKeysClear();
    revokeKeyClear();
    toggleClear();
    userKey.currentSt === 'VERIFIED' ? this.props.disableApiKey(userKey) : this.props.enableApiKey(userKey);
  };

  render() {
    const showOperations = {display: 'block'};
    const hideOperations = {};
    const {userKey} = this.props;
    return (
      <tr className={userKey.currentSt === 'VERIFIED' ? '' : 'disabled'}>
        <td className='td'>{moment(userKey.cTime).format('MMM D YYYY, h:mm a')}</td>
        <td className='td'>{userKey.stringTag}</td>
        <td className='td'>
          <div
            className={`box-reveal ${this.state.visibleKey ? 'no-blur' : ''}${
              userKey.currentSt === 'DISABLED' ? '-closed' : ''
            }`}
          >
            <div className='box-reveal-key' onClick={this.keyVisibility}>
              <div>
                <b>Sid: </b>
                {userKey.curSidTs}{' '}
              </div>
              <div>
                <b>Key: </b>
                {userKey.currentClef}{' '}
              </div>
              <div>
                <b>Secret: </b>
                {userKey.mysteryInf}
              </div>
            </div>
            <a className='btn-reveal-key' onClick={this.keyVisibility}>
              <I18n tKey='api_key.reveal_key' />
            </a>
          </div>
        </td>
        <td className='td'>
          <div className='box-status'>
            {userKey.currentSt === 'NEW' ? (
              <div className='text'>
                <I18n tKey='common.pending_confirm' />
              </div>
            ) : userKey.currentSt === 'REVOKED' ? (
              <div className='text'>
                <I18n tKey='common.deleted' />
              </div>
            ) : (
              <Fragment>
                <a
                  className={`btn btn-toggle btn-middle ${userKey.currentSt === 'VERIFIED' ? 'on' : ''}`}
                  onClick={this.toggleState}
                  data-toggle='modal'
                  data-target='#modal-key-delete'
                >
                  <i className='toggle' />
                </a>
                <span>{userKey.currentSt === 'VERIFIED' ? 'Enabled' : 'Disabled'}</span>
              </Fragment>
            )}
          </div>
        </td>

        {/*<td>
          <div className="add-function">*/}
        {/*<div className="add-function-qr">*/}
        {/*<a><i className="icon icon-key-qr"></i></a>*/}
        {/*</div>*/}
        {/*<div className="add-function-info">*/}
        {/*  <a>*/}
        {/*    <SvgWrapper themeWatch className="icon icon-key-info ic-size-06" SvgCmp={InfoIc}/>*/}
        {/*    <div className="add-function-info-box">*/}
        {/*      <ul>*/}
        {/*        {(userKey.permissions || []).map((permission, index) => {*/}
        {/*          return (*/}
        {/*            <li key={index}*/}
        {/*                className={this.checkPermissionAccess(permission.readAccess, permission.writeAccess) ? "" : "close"}>*/}
        {/*              {permission.baseApiKeyPermission.label}*/}
        {/*              <span>{this.checkPermissionAccess(permission.readAccess, permission.writeAccess)}</span>*/}
        {/*              <SvgWrapper className="icon icon-close ic-size-02" SvgCmp={CloseIc}/>*/}
        {/*            </li>*/}
        {/*          )*/}
        {/*        })}*/}
        {/*      </ul>*/}
        {/*    </div>*/}
        {/*  </a>*/}
        {/*</div>*/}
        {/*(userKey.currentSt !== "REVOKED" && userKey.currentSt !== "NEW") &&
            <div className="add-function-act">
              <a className="open-info">
                <SvgWrapper themeWatch className="icon icon-key-act ic-size-06" SvgCmp={MoreIc} onClick={this.operationsVisibility}/>
              </a>
              <div className="add-function-act-box"
                   style={this.state.visibleOperations ? showOperations : hideOperations} onMouseLeave={this.onMouseOut}>
                <div data-toggle="modal" data-target="#modal-key" onClick={this.editApiKey} id="key-menu">
                  <I18n tKey="api_key.edit"/>
                </div>
                <div data-toggle="modal" data-target="#modal-key-delete" onClick={this.deleteApiKey}
                     id="key-menu">
                  <I18n tKey="api_key.delete"/>
                </div>
              </div>
            </div>*/}
        {/*</div>
        </td>*/}
      </tr>
    );
  }
}

const mapStateToProps = ({ApiAccessKeyPopupReducer}) => ({
  ApiAccessKeyPopupReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({}, dispatch),
    editKey: userKey => dispatch({type: EDIT_API_KEY, name: EDIT_KEY, data: userKey}),
    revokeKey: userKey => dispatch({type: REVOKE_API_KEY, name: REVOKE_KEY, data: userKey}),
    enableApiKey: userKey => dispatch({type: ENABLE_API_KEY, name: ENABLE_KEY, data: userKey}),
    disableApiKey: userKey => dispatch({type: DISABLE_API_KEY, name: DISABLE_KEY, data: userKey}),
    revokeKeyClear: () => dispatch({type: RevokeApiKey.events.onClear}),
    toggleClear: () => dispatch({type: EnableAccountApiKey.events.onClear}),
    accountKeysClear: () => dispatch({type: GetAccountApiKeys.events.onClear}),
    clearCreateKey: () => dispatch({type: Actions.CreateAccountApiKey.events.onClear}),
    clearUpdateKey: () => dispatch({type: Actions.UpdateAccountApiKey.events.onClear}),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiKey));
