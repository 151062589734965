import React, {Fragment} from 'react';
import I18n from '../helpers/I18n';

class FinanceTab extends React.Component {
  displayName: 'TradingNotificationTab';

  render() {
    return (
      <Fragment>
        <div className='notification-center_title'>
          <I18n tKey='common.finance' />
        </div>
      </Fragment>
    );
  }
}

export default FinanceTab;
