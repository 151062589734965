import {Fragment} from 'react';
import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import InputPopup from '../cmp/popups/InputPopup';
import I18n from '../cmp/helpers/I18n';
import CheckToken, {badToken} from './CheckToken';

import Logo from '../cmp/Logo';
import {SetNewPassword, LoginTwoFactorAuth, ChangePassword, CheckTokenResetPassword} from '../api/Actions';

import {SUPPORT_EMAIL, URLMAP} from '../utils/const';
import {determineSecurityLevels} from '../utils/validation';

class NewPassword extends React.Component {
  state = {
    userPassword: '',
    userPassword2: '',
    code2FA: '',
    userEmail: decodeURIComponent(this.props.match.params.email),
    userOldPassword: '',
    secureType: {},
    userIsLoggedIn: this.props.SessionReducer.masterPersonality,
  };

  componentDidMount() {
    this.props.checkToken({token: decodeURIComponent(this.props.match.params.token)});
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    if (nextProps.LoginTwoFactorAuthReducer.completed === true) {
      this.props.history.push('/');
    }
  }

  login2FA = (token, twoFactorVector) => {
    this.props.login2FA({code: this.state.code2FA, token, tfVec: twoFactorVector}).then(user => {
      if (user && user.data && user.data.nextStep === 'NO') {
        this.props.history.push('/');
      }
    });
  };

  handlePasswordFieldChange = value => {
    let secureType = determineSecurityLevels(value);
    this.setState({
      userPassword: value,
      secureType,
    });
  };

  handleConfirmationField = value => {
    this.setState({
      userPassword2: value,
    });
  };

  openLogIn = () => {
    this.props.history.push(URLMAP.LOGIN);
  };

  resetPassword = () => {
    const {userPassword, userPassword2, userOldPassword, secureType, code2FA} = this.state;
    this.setState({
      passwordError: null,
      password2Error: null,
      oldPasswordError: null,
      code2FAError: null,
    });
    let hasError = false;

    if (!userPassword) {
      this.setState({passwordError: {parsedError: 'common.create_pswd'}});
      hasError = true;
    } else if (
      !secureType.numberRegex ||
      !secureType.uppercaseRegex ||
      !secureType.lowercaseRegex ||
      /*!secureType.symbolsRegex ||*/ !secureType.size
    ) {
      this.setState({passwordError: {parsedError: 'common.weak_pswd'}});
      hasError = true;
    }

    if (!userPassword2) {
      this.setState({password2Error: {parsedError: 'common.repeat_pswd'}});
      hasError = true;
    }

    if (!userOldPassword) {
      this.setState({oldPasswordError: {parsedError: 'common.repeat_pswd'}});
      hasError = true;
    }

    if (!code2FA) {
      this.setState({code2FAError: {parsedError: 'common.repeat_pswd'}});
      hasError = true;
    }

    if (userPassword !== userPassword2) {
      this.setState({password2Error: {parsedError: 'common.pswd_equal'}});
      hasError = true;
    }

    if (!hasError) {
      const data = {cryptogram: code2FA, newLockPickSymbolRow: userPassword, oldLockPickSymbolRow: userOldPassword};
      this.props.changePassword(data).then(user => {
        if (user && user.data && user.data.changingFlag) {
          this.props.history.push('/');
        }
      });
    }
  };

  setNewPassword = () => {
    const {userPassword, userPassword2, secureType, userEmail} = this.state;
    this.setState({
      passwordError: null,
      password2Error: null,
      emailError: null,
    });

    let hasError = false;
    if (!userEmail) {
      this.setState({emailError: {parsedError: 'popup.enter_email'}});
      hasError = true;
    }
    if (!userPassword) {
      this.setState({passwordError: {parsedError: 'common.create_pswd'}});
      hasError = true;
    } else if (
      !secureType.numberRegex ||
      !secureType.uppercaseRegex ||
      !secureType.lowercaseRegex ||
      /*!secureType.symbolsRegex ||*/ !secureType.size
    ) {
      this.setState({passwordError: {parsedError: 'common.weak_pswd'}});
      hasError = true;
    }

    if (!userPassword2) {
      this.setState({password2Error: {parsedError: 'common.repeat_pswd'}});
      hasError = true;
    }

    if (userPassword !== userPassword2) {
      this.setState({password2Error: {parsedError: 'common.pswd_equal'}});
      hasError = true;
    }

    if (!hasError) {
      const userToken = this.props.match.params.token;
      const data = {electronicPostAddr: userEmail, newLockPickSymbolRow: userPassword, specialMark: userToken};
      this.props.setNewPassword(data).then(user => {
        if (user && user.data && user.data.followingLevel === 'NO') {
          this.props.history.push('/');
        }
      });
    }
  };

  render() {
    const {SetNewPasswordReducer, LoginTwoFactorAuthReducer, ChangePasswordReducer} = this.props;
    const {userIsLoggedIn} = this.state;
    const twoFaEnabled = userIsLoggedIn ? userIsLoggedIn.googleAuthAllowanceFlag : false;
    let nextStep = SetNewPasswordReducer.data.followingLevel;
    if (nextStep === 'NO') {
      nextStep = null;
    }
    const error = !nextStep ? SetNewPasswordReducer.error : LoginTwoFactorAuthReducer.error;
    const fetching = !nextStep ? SetNewPasswordReducer.fetching : LoginTwoFactorAuthReducer.fetching;
    if (this.props.CheckTokenReducer.error) {
      return <CheckToken descriptionObj={badToken} />;
    } else {
      return (
        <section className='reset-password'>
          <div className='reset-password_logo'>
            <Logo isMonoColor />
          </div>
          <div className='reset-password_form'>
            <div className='ui-form'>
              {!nextStep && !userIsLoggedIn && (
                <h2>
                  <I18n tKey='common.set_pswd' />
                </h2>
              )}
              {nextStep && (
                <h2>
                  <I18n tKey='common.enter_2fa' />
                </h2>
              )}
              {userIsLoggedIn && (
                <h2>
                  <I18n tKey='common.reset_pswd' />
                </h2>
              )}
              {/*Check it in future*/}

              {!userIsLoggedIn && (
                <div className='ui-form-text text-center'>
                  <I18n tKey='common.dont_need' />
                  {nextStep && (
                    <Fragment>
                      <I18n tKey='common.enter_code' />
                    </Fragment>
                  )}
                  {!nextStep && (
                    <a onClick={this.openLogIn}>
                      <I18n tKey='common.login' />
                    </a>
                  )}
                </div>
              )}
              {!nextStep && !twoFaEnabled && (
                <InputPopup
                  error={this.state.emailError}
                  onChange={value => this.setState({userEmail: value})}
                  placeholder='common.email'
                  value={this.state.userEmail}
                  toCleanError={() => this.state.emailError && this.setState({emailError: null})}
                  label='popup.enter_email'
                />
              )}
              {!nextStep && (
                <Fragment>
                  <InputPopup
                    error={this.state.passwordError}
                    onChange={this.handlePasswordFieldChange}
                    value={this.state.userPassword}
                    placeholder='common.new_pswd'
                    toCleanError={() => this.state.passwordError && this.setState({passwordError: null})}
                    label='common.create_new_pswd'
                    className='ui-form-control-secure'
                    type='password'
                  >
                    <Fragment>
                      <div
                        className={`ui-form-control-rules_item ${this.state.secureType.lowercaseRegex ? 'active' : ''}`}
                      >
                        <I18n tKey='popup.one_lowercase' />
                      </div>
                      <div
                        className={`ui-form-control-rules_item ${this.state.secureType.uppercaseRegex ? 'active' : ''}`}
                      >
                        <I18n tKey='popup.one_uppercase' />
                      </div>
                      <div
                        className={`ui-form-control-rules_item ${this.state.secureType.numberRegex ? 'active' : ''}`}
                      >
                        <I18n tKey='popup.one_num' />
                      </div>
                      {/*<div className={`ui-form-control-rules_item ${this.state.secureType.symbolsRegex ? "active" : ""}`}>One special character</div>*/}
                      <div className={`ui-form-control-rules_item ${this.state.secureType.size ? 'active' : ''}`}>
                        <span>8</span> <I18n tKey='popup.min_characters' />
                      </div>
                    </Fragment>
                  </InputPopup>
                  <InputPopup
                    error={this.state.password2Error}
                    onChange={this.handleConfirmationField}
                    value={this.state.userPassword2}
                    placeholder='common.confirm_pswd'
                    toCleanError={() => this.state.password2Error && this.setState({password2Error: null})}
                    label='common.repeat_pswd'
                    className={'ui-form-control-secure'}
                    type='password'
                  />
                  {twoFaEnabled && (
                    <InputPopup
                      error={this.state.oldPasswordError}
                      onChange={value => this.setState({userOldPassword: value})}
                      value={this.state.userOldPassword}
                      placeholder='common.enter_old_pswd'
                      toCleanError={() => this.state.oldPasswordError && this.setState({oldPasswordError: null})}
                      label='common.enter_old_pswd'
                      className={'ui-form-control-secure'}
                      type='password'
                    />
                  )}
                </Fragment>
              )}
              {(nextStep || twoFaEnabled) && (
                <InputPopup
                  error={LoginTwoFactorAuthReducer.error || this.state.code2FAError}
                  onChange={value => this.setState({code2FA: value})}
                  value={this.state.code2FA}
                  placeholder='common.2fa_code'
                  toCleanError={() => this.state.code2FAError && this.setState({code2FAError: null})}
                  label='popup.enter_2fa'
                  useMaskPassword={true}
                />
              )}

              <div className='form-btn'>
                {!fetching && !twoFaEnabled && (
                  <button
                    type='button'
                    className='btn btn-md btn-primary btn-full'
                    onClick={
                      !nextStep
                        ? this.setNewPassword
                        : this.login2FA.bind(this, SetNewPasswordReducer.data.specialMark, nextStep.toLowerCase())
                    }
                  >
                    <I18n tKey='common.upd_pswd' />
                  </button>
                )}
                {!fetching && twoFaEnabled && (
                  <button type='button' className='btn btn-md btn-primary btn-full' onClick={this.resetPassword}>
                    <I18n tKey='common.upd_pswd' />
                  </button>
                )}
                {fetching && (
                  <button disabled type='button' className='btn btn-md btn-primary btn-full'>
                    <I18n tKey='common.upd_pswd' />
                  </button>
                )}
                <div className='popup-error-message common'>
                  {error && !nextStep && (
                    <div>
                      <I18n tKey={error.parsedError || error.error} />
                    </div>
                  )}
                  {ChangePasswordReducer && ChangePasswordReducer.error && !nextStep && (
                    <div>
                      <I18n tKey={ChangePasswordReducer.error.parsedError || ChangePasswordReducer.error.error} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='reset-password_footer'>
            &#169;{moment().format('YYYY')} <I18n tKey='common.footer' />
          </div>
        </section>
      );
    }
  }
}

const mapStateToProps = ({
  SetNewPasswordReducer,
  LoginTwoFactorAuthReducer,
  SessionReducer,
  ChangePasswordReducer,
  CheckTokenReducer,
}) => ({
  SetNewPasswordReducer,
  LoginTwoFactorAuthReducer,
  SessionReducer,
  ChangePasswordReducer,
  CheckTokenReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        setNewPassword: SetNewPassword.action,
        changePassword: ChangePassword.action,
        login2FA: LoginTwoFactorAuth.action,
        checkToken: CheckTokenResetPassword.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPassword));

export default connectedWithRouter;
