import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {UserLogout} from '../../../../api/Actions';
import {SWITCH_THEME} from '../../../../reducers/ThemeReducer';
import {SET_LNG} from '../../../../reducers/LngReducer';

const mapStateToProps = ({SessionReducer, ThemeReducer, LngReducer}) => {
  return {
    SessionReducer,
    ThemeReducer,
    LngReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({logout: UserLogout.action}, dispatch),
    switchTheme: () => dispatch({type: SWITCH_THEME}),
    changeLng: lng => dispatch({type: SET_LNG, payload: {lng}}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
