import React from 'react';
import numeral from 'numeral';
import {currencyFormatter} from '../../../../../utils/utils';

const CustomTooltip: React.FC = (props: any) => {
  const {active, payload, label} = props;

  if (active && payload && payload.length) {
    return (
      <div className='portfolio-chart-tooltip'>
        {currencyFormatter.format(payload[0].payload.convertedBalance || 0)}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
