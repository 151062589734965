import React, {Component} from 'react';
import I18n from './I18n';
import classNames from 'classnames';

class RadioBtn extends Component {
  handleChange = event => {
    const {value, name} = event.target;

    this.props.onChange && this.props.onChange(name, value);
  };

  render() {
    const {label, name, value, className, isChecked, isSmall} = this.props;

    return (
      <label className={classNames('custom-radio', {['sm']: isSmall}, className)}>
        <input onChange={this.handleChange} type='radio' name={name} value={value} checked={isChecked} />
        <span className='checkmark-container'>
          <span className='checkmark' />
        </span>
        <I18n tKey={label} />
      </label>
    );
  }
}

export default RadioBtn;
