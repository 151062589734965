// @flow
import * as React from 'react';
import {Fragment} from 'react';
import {CLOSE_POPUP} from '../../../reducers/PopupReducer';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import I18n from '../../helpers/I18n';
import {SvgWrapper} from '../../helpers/SvgWrapper';
import {OpenOrder} from '../../../api/Actions';
import Loader from '../../Loader';
import SuccessDialog from '../../notification/SuccessDialog';
import ErrorDialog from '../../notification/ErrorDialog';
export const EXCHANGE_POPUP = 'EXCHANGE_POPUP';
export const INCORRECT_AMOUNT = 'INCORRECT_AMOUNT';
export const parseErrorMessage = error => {
  if (error.error === 'USER_SANCTIONS') {
    return <I18n tKey='error:sanctions' />;
  }

  if (error.message) {
    const splitArr = error.message.split(' ');
    const amount = splitArr[splitArr.length - 1];
    return (
      <span>
        <I18n tKey={error.error} />
        <br />
        {error.error === INCORRECT_AMOUNT && (
          <span>
            {' '}
            <I18n tKey={'errors.min_amount'} />
            {parseFloat(amount).toFixed(2)}
          </span>
        )}
      </span>
    );
  }

  return <I18n tKey={error.error} />;
};

class ExchangePopup extends React.PureComponent<void, ComponentState> {
  state = {
    total: null,
    amount: null,
    price: null,
    successDialogIsOpen: false,
    errorDialogIsOpen: false,
  };
  openOrder = (market, isBuy) => {
    const order: Order = {
      odrActualQty: this.props.data.amount,
      odrValue: this.state.price, // same as current situation in widget, null
      marketId: market.identificatSymbol,
      odrCurrentS: !isBuy ? 'SELL' : 'BUY',
      odrCurrentT: 'MARKET',
    };

    this.props.openOrder(order);
  };

  handleOpenSuccessDialog = () => {
    this.setState(state => ({successDialogIsOpen: !state.successDialogIsOpen}));
  };

  handleOpenErrorDialog = () => {
    this.setState(state => ({errorDialogIsOpen: !state.errorDialogIsOpen}));
  };

  componentDidUpdate(prevProps, prevState) {
    const {OpenOrderReducer} = this.props;
    if (OpenOrderReducer) {
      prevProps.OpenOrderReducer.fetching && OpenOrderReducer.error && this.handleOpenErrorDialog();

      OpenOrderReducer.completed && prevProps.OpenOrderReducer.fetching && this.handleOpenSuccessDialog();
    }
  }

  render() {
    const {total, currenyFrom, fee, exchangeRate, selectedAction, amount} = this.props.data;
    const {OpenOrderReducer} = this.props;
    const activeMarket = this.props.data.activeMarket;
    const {successDialogIsOpen, errorDialogIsOpen} = this.state;
    const isBuy = selectedAction === 'buy';
    return (
      <Fragment>
        <div className='modal-2FA-form '>
          <h2>
            <I18n tKey='common.exchange' />
          </h2>
          <div className='modal-signup_confirm'>
            <div className='add-info exchange__dialog__header'>
              <Loader open={OpenOrderReducer.fetching} />
              <SuccessDialog open={successDialogIsOpen} onClose={this.handleOpenSuccessDialog}>
                <I18n tKey='exchange.success' />
                <button
                  style={{marginTop: 20}}
                  className='btn btn-purple btn-full size'
                  onClick={() => {
                    this.handleOpenSuccessDialog();
                    this.props.close();
                  }}
                >
                  <I18n tKey='common.close' />
                </button>
              </SuccessDialog>
              {OpenOrderReducer.error && (
                <ErrorDialog height={300} open={errorDialogIsOpen} onClose={this.handleOpenErrorDialog}>
                  {parseErrorMessage(OpenOrderReducer.error)}
                  <button
                    onClick={() => {
                      this.handleOpenErrorDialog();
                      this.props.close();
                    }}
                    style={{marginTop: 20}}
                    className='btn btn-purple btn-full size'
                  >
                    <I18n tKey='common.close' />
                  </button>
                </ErrorDialog>
              )}
            </div>
            <table>
              <tbody className='table-balance-body'>
                <tr>
                  <td className='text-left'>
                    <I18n tKey='exchange.pay_using' />
                  </td>
                  <td className='text-right text-dark'>
                    <span className='text-dark'>{currenyFrom}</span>
                  </td>
                </tr>
                <tr>
                  <td className='text-left'>
                    <I18n tKey='exchange.exchange_rate' />
                  </td>
                  <td className='text-right text-dark'>{exchangeRate} </td>
                </tr>
                <tr>
                  <td className='text-left'>
                    <I18n tKey='exchange.unitex_comission' />
                  </td>
                  <td className='text-right text-dark'>{fee} %</td>
                </tr>
                <tr>
                  <td className='text-left'>
                    <I18n tKey='exchange.total' />
                  </td>
                  <td className='text-right text-dark'>
                    {isBuy ? total : amount} {isBuy ? 'USDU' : currenyFrom}{' '}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className='text-end' style={{marginTop: 50}}>
              <a onClick={e => this.openOrder(activeMarket, isBuy)} className='btn btn-md btn-primary text-end'>
                <I18n tKey='common.convert' />
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({OpenOrderReducer}) => {
  return {
    OpenOrderReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        openOrder: OpenOrder.action,
      },
      dispatch
    ),
    close: () => dispatch({type: CLOSE_POPUP}),
  };
};
const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExchangePopup));

export default connectedWithRouter;
