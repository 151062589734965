import React, {Component, Fragment} from 'react';
import PriceLabel from '../../../PriceLabel';

class PriceSlider extends Component {
  render() {
    const {low, high, currentPrice} = this.props;
    const sliderThumbPosition = Math.floor((currentPrice / high) * 100);

    return (
      <div className='price-stats-wrapper'>
        <div className='price-stats-wrapper__label'>Price Range (24h)</div>

        <div className='price-stats'>
          <div className='price-stats__price'>
            24 Low <PriceLabel value={low} />
          </div>
          <div className='price-slider-wrapper'>
            <div className='price-slider'>
              <div style={{left: `${sliderThumbPosition}%`}} className='price-slider__thumb' />
            </div>
          </div>
          <div className='price-stats__price'>
            24 High <PriceLabel value={high} />
          </div>
        </div>
      </div>
    );
  }
}

export default PriceSlider;
