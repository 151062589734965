import {connect} from 'react-redux';

import TradesItem from './item';
import I18n from '../../../../cmp/helpers/I18n';
import {CellHeader} from '../index';
import React from 'react';
import {translate} from '../../../../utils/i18n';
import remapTrades from './services';

class RecentTrades extends React.PureComponent {
  state = {
    trades: [],
  };

  componentDidMount() {
    if (Array.isArray(this.props.UserLastTradesReducer.data) && this.props.UserLastTradesReducer.data.length) {
      this.setState({
        trades: remapTrades(this.props.UserLastTradesReducer.data),
      });
    }
  }

  componentWillReceiveProps(n) {
    const newElementsCount = n.WsRecentTradesReducer.recentTrades.length;
    if (
      n.ActiveMarketReducer.identificatSymbol &&
      Array.isArray(n.UserLastTradesReducer.data) &&
      !n.UserLastTradesReducer.error
    ) {
      //check error in future
      this.setState({
        trades: remapTrades(n.UserLastTradesReducer.data),
        error: null,
      });
    }

    n.UserLastTradesReducer.error && this.setState({error: n.UserLastTradesReducer.error.parsedError});

    if (newElementsCount && this.state.trades.length) {
      const tradesCopy = this.state.trades.map(trade => ({...trade}));

      n.WsRecentTradesReducer.recentTrades.forEach(newTrade => {
        if (tradesCopy.every(trade => trade.uuid !== newTrade[4])) {
          tradesCopy.unshift({
            price: newTrade[1],
            amount: newTrade[2],
            side: newTrade[3],
            uuid: newTrade[4],
            matchedAt: newTrade[0],
          });

          tradesCopy.splice(tradesCopy.length - 1, 1); //deletes last element
        }
      });

      this.setState({trades: remapTrades(tradesCopy)});
    }

    if (newElementsCount && !this.state.trades.length) {
      this.setState({
        trades: remapTrades(
          n.WsRecentTradesReducer.recentTrades.map(s => {
            return {
              price: s[1],
              amount: s[2],
              side: s[3],
              uuid: s[4],
              matchedAt: s[0],
            };
          })
        ),
      });
    }
  }

  render() {
    const activeMarket = this.props.ActiveMarketReducer;
    const fetching = this.props.UserLastTradesReducer.fetching;
    let prevPrice = 0;
    return (
      <section className='trader content-wrapper resent-trades'>
        <CellHeader label='layout.recent_trades' />
        <div className='trader_body'>
          <div className='trader_body-value-info'>
            <div className='trader_body-value-info-pair'>
              {translate('common.price')} {activeMarket.rightMonetaryUnitCode}
            </div>
            <div className='trader_body-value-info-price'>
              {translate('common.amount')} {activeMarket.leftMonetaryUnitCode}
            </div>
            {/*<div className='trader_body-value-info-price'>*/}
            {/*  <I18n tKey='common.amount' /> {activeMarket.rightMonetaryUnitCode}*/}
            {/*</div>*/}
            <div className='trader_body-value-info-change'>
              <I18n tKey='common.time' />
            </div>
          </div>
          <div className='trader_body-value'>
            {fetching ? (
              <div>
                <I18n tKey='common.loading' />
              </div>
            ) : this.state.error ? (
              <div>{this.state.error}</div>
            ) : (
              this.state.trades.map((trade, i) => <TradesItem key={i} trade={trade} market={activeMarket} />)
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({WsRecentTradesReducer, ActiveMarketReducer, UserLastTradesReducer}) => {
  return {WsRecentTradesReducer, ActiveMarketReducer, UserLastTradesReducer};
};

export default connect(mapStateToProps, undefined)(RecentTrades);
