import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import Select from 'react-select';

import I18n from '../../cmp/helpers/I18n';

import {
  CUSTOM_SELECT_STYLES,
  CUSTOM_SELECT_THEME,
  SERVICE_DESK_ID,
  TICKET_CATEGORY,
  TICKET_SUBCATEGORY_OPTIONS,
} from './constants';
import {translate} from '../../utils/i18n';
import BaseForm from './components/BaseForm';
import FiatForm from './components/FiatForm';
import CryptoForm from './components/CryptoForm';
import BusinessForm from './components/BusinessForm';
import {CreateTicket} from '../../api/Actions';
import {OPEN_POPUP} from '../../reducers/PopupReducer';
import {TICKET_CREATED_POPUP} from '../../cmp/popups/custom/TicketCreated';

class HelpCenter extends Component {
  constructor(props) {
    super(props);

    const userExists = (props.SessionReducer.masterPersonality || {}).curUName;
    const options = TICKET_SUBCATEGORY_OPTIONS(userExists);

    this.state = {
      activeSection: props.section,
      activeOptions: options[props.section],
      activeSubSection: options[props.section][0],
      serviceDeskId: SERVICE_DESK_ID[props.section],
    };
  }

  componentWillReceiveProps(props) {
    const userExists = (props.SessionReducer.masterPersonality || {}).curUName;
    const prevUserExists = (this.props.SessionReducer.masterPersonality || {}).curUName;

    if (this.props.section !== props.section || userExists !== prevUserExists) {
      const options = TICKET_SUBCATEGORY_OPTIONS(userExists);

      this.setState({
        activeSection: props.section,
        activeOptions: options[props.section],
        activeSubSection: options[props.section][0],
        serviceDeskId: SERVICE_DESK_ID[props.section],
      });
    }
  }

  handleOptionSelect = activeSubSection => {
    this.setState({activeSubSection});
    this.props.cleanCreatedTicket();
  };

  render() {
    const {SessionReducer} = this.props;
    const user = SessionReducer.masterPersonality || {};

    return (
      <div>
        <div className='page-legacy_content-block'>
          <div className='title'>
            <h2>
              <I18n tKey='helpcenter.title' />
            </h2>
          </div>

          <div className='text'>
            <I18n tKey='helpcenter.header' />
          </div>

          <div className='help__form'>
            <div className='form__block'>
              <Select
                styles={CUSTOM_SELECT_STYLES}
                placeholder={translate('helpcenter.select')}
                isSearchable={false}
                theme={CUSTOM_SELECT_THEME}
                onChange={e => this.handleOptionSelect(e)}
                options={this.state.activeOptions}
                value={this.state.activeSubSection}
              />
            </div>
          </div>

          {this.state.activeSection === TICKET_CATEGORY.accounts && (
            <BaseForm
              activeSubSection={this.state.activeSubSection}
              serviceDeskId={this.state.serviceDeskId}
              userLoggedIn={!!user.curUName}
              userName={user.curUName || ''}
              email={user.electronicMailVal || ''}
            />
          )}

          {this.state.activeSection === TICKET_CATEGORY.crypto && (
            <CryptoForm
              activeSubSection={this.state.activeSubSection}
              serviceDeskId={this.state.serviceDeskId}
              userLoggedIn={!!user.curUName}
              userName={user.curUName || ''}
              email={user.electronicMailVal || ''}
            />
          )}

          {this.state.activeSection === TICKET_CATEGORY.trading && (
            <BaseForm
              activeSubSection={this.state.activeSubSection}
              serviceDeskId={this.state.serviceDeskId}
              userLoggedIn={!!user.curUName}
              userName={user.curUName || ''}
              email={user.electronicMailVal || ''}
            />
          )}

          {this.state.activeSection === TICKET_CATEGORY.fiat && (
            <FiatForm
              activeSubSection={this.state.activeSubSection}
              serviceDeskId={this.state.serviceDeskId}
              userLoggedIn={!!user.curUName}
              userName={user.curUName || ''}
              email={user.electronicMailVal || ''}
            />
          )}

          {this.state.activeSection === TICKET_CATEGORY.business && (
            <BusinessForm activeSubSection={this.state.activeSubSection} serviceDeskId={this.state.serviceDeskId} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({SessionReducer, CreateTicketReducer}) => {
  return {
    SessionReducer,
    CreateTicketReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cleanCreatedTicket: () => dispatch({type: CreateTicket.events.onClear}),
    openTicketCreateModal: () => dispatch({type: OPEN_POPUP, name: TICKET_CREATED_POPUP}),
  };
};

export default withGoogleReCaptcha(withRouter(connect(mapStateToProps, mapDispatchToProps)(HelpCenter)));
