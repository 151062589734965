// @flow

import {Fragment} from 'react';
import {DateRangePicker} from 'react-date-range';
import Formatter from '../utils/formatter';
import moment from 'moment';
import {ReactComponent as TriangleIc} from '../../../assets/images/icon/dropdown-small-n.svg';
import {SvgWrapper} from './helpers/SvgWrapper';
import I18n from './helpers/I18n';
import {connect} from 'react-redux';
import {useState} from 'react';
import {formatCalendarLngPrefix} from '../utils/utils';

const DropdownFilter = props => {
  const [isDatePickerOpened, openDatePicker] = useState(false);

  const initialDateState = [
    {
      startDate: null,
      endDate: null,
    },
  ];

  const [dateRange, chooseDateRange] = useState(initialDateState);

  const clearDate = () => {
    chooseDateRange(initialDateState);
    props.onChange(initialDateState);
  };

  const changeDateRange = range => {
    chooseDateRange([range.range1]);
    props.onChange({
      dateFrom: moment(range.range1.startDate).toISOString(),
      dateTo: moment(range.range1.endDate).toISOString(),
    });
  };

  const calendarDictionary = formatCalendarLngPrefix(props.LngReducer.lng);

  return (
    <Fragment>
      <div
        className={`dropdown ${isDatePickerOpened ? 'open' : ''}`}
        onMouseOver={() => openDatePicker(true)}
        onMouseOut={() => openDatePicker(false)}
      >
        <a className='tab-pane-filter-item dropdown_btn'>
          {dateRange[0].startDate && dateRange[0].endDate && (
            <span onClick={clearDate}>
              {Formatter.date(dateRange[0].startDate) + ' - ' + Formatter.date(dateRange[0].endDate)}
            </span>
          )}
          {(!dateRange[0].startDate || !dateRange[0].endDate) && <I18n tKey='date_range.select' />}
          <SvgWrapper themeWatch className='icon icon-arrow-toggle ic-size-02' SvgCmp={TriangleIc} />
        </a>
        <div className='dropdown_menu' onClick={() => openDatePicker(true)}>
          <DateRangePicker
            ranges={dateRange}
            onChange={item => {
              changeDateRange(item);
            }}
            locale={calendarDictionary}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({LngReducer}) => ({LngReducer});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownFilter);
