import React, {Component} from 'react';
import Select from 'react-select';

import {fontStyle} from '../../constants';

const colourStyles = {
  control: styles => ({
    ...styles,
    cursor: 'pointer',
    minHeight: 'initial',
    backgroundColor: 'transparent',
    border: 0,
    boxShadow: 'none',
  }),
  placeholder: styles => ({...styles, ...fontStyle, color: '#9ba2ac'}),
  menuList: styles => ({...styles, maxHeight: 250, padding: 0, backgroundColor: 'var(--select-menu-bg)'}),
  valueContainer: styles => ({...styles, ...fontStyle, padding: '0px 8px'}),
};

const Option = ({innerProps, isDisabled, data}) => {
  return !isDisabled ? (
    <div
      className='fast-exchange-block__currency-select-option fast-exchange-block__currency-select-option--in-list'
      {...innerProps}
    >
      {data.label}
    </div>
  ) : null;
};

class CurrencySelect extends Component {
  handleChange = value => {
    const {onChange, onBlur} = this.props;
    onChange(value);
    setTimeout(() => onBlur(), 100);
  };
  render() {
    const {options, value, onBlur, isReadOnly} = this.props;

    return (
      <Select
        components={{IndicatorSeparator: null, Option}}
        isSearchable={false}
        options={options}
        value={value}
        styles={colourStyles}
        onChange={this.handleChange}
        onBlur={onBlur}
        isDisabled={isReadOnly}
      />
    );
  }
}

export default CurrencySelect;
