import * as React from 'react';
import {SvgWrapper} from '../cmp/helpers/SvgWrapper';
import {ReactComponent as OkIc} from '../../../assets/images/icon/success.svg';
import {ReactComponent as Error} from '../../../assets/images/icon/error.svg';
import I18n from '../cmp/helpers/I18n';
import {Link} from 'react-router-dom';
import {URLMAP} from '../utils/const';

const withdrawResultSucces = {
  icon: OkIc,
  title: 'withdraw:title_success',
  description: 'withdraw:description_success',
};

const withdrawResultError = {
  icon: Error,
  title: 'withdraw:title_error',
  description: 'withdraw:description_error',
};

export const badToken = {
  icon: Error,
  title: 'bad:token_resetpass:title',
  description: 'bad:token_resetpass:discription',
};

class CheckToken extends React.Component {
  constructor(props) {
    super(props);
    const {descriptionObj} = props;
    if (descriptionObj) {
      this.result = descriptionObj;
    } else {
      this.props.asSuccess ? (this.result = withdrawResultSucces) : (this.result = withdrawResultError);
    }
  }

  render() {
    return (
      <div className='result_container'>
        <div className='result'>
          <SvgWrapper className='icon' SvgCmp={this.result.icon} />
          <div className='title mt-2'>
            <I18n tKey={this.result.title} />
          </div>
          <div className='description mt-2'>
            <I18n tKey={this.result.description} />
          </div>
          <Link to={URLMAP.TERMINAL} className='mt-2 white'>
            <button type='button' className='btn btn-primary white'>
              <I18n tKey='common:close' />
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default CheckToken;
