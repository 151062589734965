import React from 'react';

import {ReactComponent as Usd} from '../../../../../../../assets/images/chartIcons/USD.svg';
import {ReactComponent as Btc} from '../../../../../../../assets/images/chartIcons/BTC.svg';
import {ReactComponent as Eth} from '../../../../../../../assets/images/chartIcons/ETH.svg';
import {ReactComponent as Eur} from '../../../../../../../assets/images/chartIcons/EUR.svg';
import {ReactComponent as Ltc} from '../../../../../../../assets/images/chartIcons/LTC.svg';
import {ReactComponent as Usdt} from '../../../../../../../assets/images/chartIcons/USDT.svg';
import {ReactComponent as Untb} from '../../../../../../../assets/images/chartIcons/UNTB.svg';
import {ReactComponent as Cru} from '../../../../../../../assets/images/chartIcons/CRU.svg';
import {ReactComponent as Eos} from '../../../../../../../assets/images/chartIcons/EOS.svg';
import {ReactComponent as Usdu} from '../../../../../../../assets/images/chartIcons/USDU.svg';
import {ReactComponent as Unknown} from '../../../../../../../assets/images/chartIcons/Unknown.svg';
import {ReactComponent as Usdc} from '../../../../../../../assets/images/chartIcons/USDC.svg';
import {ReactComponent as Dai} from '../../../../../../../assets/images/chartIcons/DAI.svg';
import {ReactComponent as Sushi} from '../../../../../../../assets/images/chartIcons/SUSHI.svg';
import {ReactComponent as Uni} from '../../../../../../../assets/images/chartIcons/UNI.svg';
import {ReactComponent as Link} from '../../../../../../../assets/images/chartIcons/LINK.svg';
import {ReactComponent as Wax} from '../../../../../../../assets/images/chartIcons/WAX.svg';
import {ReactComponent as XRP} from '../../../../../../../assets/images/chartIcons/XRP.svg';
import {ReactComponent as BNB} from '../../../../../../../assets/images/chartIcons/BNB.svg';
import {ReactComponent as TRON} from '../../../../../../../assets/images/chartIcons/TRON.svg';
import {ReactComponent as DOGE} from '../../../../../../../assets/images/chartIcons/DOGE.svg';
import {ReactComponent as MATIC} from '../../../../../../../assets/images/chartIcons/MATIC.svg';
import {ReactComponent as BOBC} from '../../../../../../../assets/images/chartIcons/BOBC.svg';
import {ReactComponent as DOT} from '../../../../../../../assets/images/chartIcons/DOT.svg';
import {ReactComponent as BUSD} from '../../../../../../../assets/images/chartIcons/BUSD.svg';
import {ReactComponent as SOL} from '../../../../../../../assets/images/chartIcons/SOL.svg';

const CurrencyIcons = {
  usd: Usd,
  usdt: Usdt,
  ltc: Ltc,
  btc: Btc,
  eth: Eth,
  untb: Untb,
  cru: Cru,
  eos: Eos,
  usdu: Usdu,
  eur: Eur,
  usdc: Usdc,
  dai: Dai,
  sushi: Sushi,
  uni: Uni,
  link: Link,
  wax: Wax,
  xrp: XRP,
  bnb: BNB,
  trx: TRON,
  doge: DOGE,
  matic: MATIC,
  bobc: BOBC,
  dot: DOT,
  busd: BUSD,
  sol: SOL,
};

const CustomizedChartDot = ({x, y, width, value: currency}) => {
  const Icon = CurrencyIcons[currency.toLowerCase()] || Unknown;
  return <Icon height={width} width={width} x={x} y={y - width / 2} />;
};

export default CustomizedChartDot;
