import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Dialog from '../Dialog';

class ErrorDialog extends PureComponent {
  static propTypes = {
    darkTheme: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  handleClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  render() {
    const {darkTheme, open, children, height} = this.props;

    return (
      <Dialog open={open} onClose={this.handleClose} height={height || 205}>
        <div className={`success-dialog ${darkTheme ? 'dialog-success__dark' : ''}`}>
          <svg
            width='40'
            height='40'
            viewBox='0 0 56 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{marginBottom: 10, minHeight: 40}}
          >
            <circle cx='28' cy='28' r='26' stroke='#f54865' strokeWidth='3' />
            <path d='M16 40L40 16' stroke='#f54865' strokeWidth='3' />
            <path d='M40 40L16 16' stroke='#f54865' strokeWidth='3' />
          </svg>
          {children}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ThemeReducer}) => ({
  darkTheme: ThemeReducer.darkTheme,
});

export default connect(mapStateToProps)(ErrorDialog);
