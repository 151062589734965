import React, {Component} from 'react';
import numeral from 'numeral';

const SIGN = {
  eur: '€',
  usd: '$',
};

class PriceLabel extends Component {
  render() {
    const {value = 0, fiat, format = '0,0'} = this.props;
    return (
      <span>
        {SIGN[fiat] || ''} {numeral(value).format(format)}
      </span>
    );
  }
}

export default PriceLabel;
