import React, {Component} from 'react';
import Select from 'react-select';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';

import {translate} from '../../../../utils/i18n';
import I18n from '../../../../cmp/helpers/I18n';
import {COUNTRIES, CUSTOM_SELECT_STYLES, CUSTOM_THEME} from './constants';
import connect from './connect';

class BusinessForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      userName: '',
      userSurname: '',
      companyName: '',
      country: null,
      description: '',
      isLoading: false,
    };
  }

  getIsFormReady = () => {
    const {email, userName, userSurname, companyName, country, description} = this.state;

    let isReady = true;

    if (!country) {
      isReady = false;

      return !isReady;
    }

    if (!email.length || !userName.length || !userSurname.length || !companyName.length || !description.length) {
      isReady = false;
    }

    return !isReady;
  };

  handleInputChange = e => {
    const {value, name} = e.target;

    this.setState({[name]: value});
  };

  handleSelect = country => {
    this.setState({country});
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.props.cleanCreatedTicket();
    this.setState({isLoading: true});

    const {executeRecaptcha} = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.debug('NO CAPTCHA');
      return;
    }

    try {
      const token = await executeRecaptcha('attachment');

      const {status} = await this.props.createTicket({
        body: {
          serviceDeskId: this.props.serviceDeskId,
          requestTypeId: this.props.activeSubSection.value,
          requestFieldValues: {
            customfield_10062: this.state.email,
            customfield_10075: this.state.userName,
            customfield_10077: this.state.userSurname,
            customfield_10076: this.state.companyName,
            customfield_10078: {id: this.state.country.value},
            description: this.state.description,
          },
        },
        gToken: token,
      });

      if (status === 200) {
        this.props.openTicketCreateModal(this.onCloseModal);
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({isLoading: false});
  };

  onCloseModal = () => {
    this.setState({email: '', userName: '', userSurname: '', companyName: '', country: null, description: ''});
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className='help__form'>
        <div className='form__block'>
          <input
            value={this.state.email}
            onChange={e => this.handleInputChange(e)}
            name='email'
            id='email'
            type='email'
            className='form__control'
            placeholder={translate('helpcenter.email')}
            required
          />
        </div>

        <div className='form__block'>
          <input
            value={this.state.userName}
            onChange={e => this.handleInputChange(e)}
            name='userName'
            id='username'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.name')}
            required
          />
        </div>

        <div className='form__block'>
          <input
            value={this.state.userSurname}
            onChange={e => this.handleInputChange(e)}
            name='userSurname'
            id='userSurname'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.surname')}
            required
          />
        </div>

        <div className='form__block'>
          <input
            value={this.state.companyName}
            onChange={e => this.handleInputChange(e)}
            name='companyName'
            id='companyName'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.cmp_name')}
            required
          />
        </div>

        <div className='form__block'>
          <Select
            value={this.state.country}
            styles={CUSTOM_SELECT_STYLES}
            isSearchable={true}
            theme={CUSTOM_THEME}
            name='country'
            placeholder={translate('helpcenter.country')}
            onChange={e => this.handleSelect(e, 'country')}
            options={COUNTRIES}
          />
        </div>

        <div className='form__block'>
          <label htmlFor='message' className='header__label'>
            <I18n tKey='helpcenter.description_short' />*
          </label>
          <textarea
            name='description'
            id='message'
            className='form__control'
            required
            value={this.state.description}
            onChange={e => this.handleInputChange(e)}
          />
        </div>

        <div className='form__block button__wrapper d-flex  '>
          <button className='btn btn-cancel '>
            <span>
              <I18n tKey='common.cancel' />
            </span>
          </button>

          <button type='submit' className='btn btn-purple ' disabled={this.getIsFormReady() || this.state.isLoading}>
            <span>
              <I18n tKey='common.send' />
            </span>
          </button>
        </div>
      </form>
    );
  }
}

export default withGoogleReCaptcha(connect(BusinessForm));
