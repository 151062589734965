export const SUPPORT_EMAIL = 'support@unitex.one';
export const TIME_DISPLAY_PATTERN = {
  DOC_PATTERN: 'DD MMMM YYYY',
  REQUEST_FORMAT: 'yyyy-MM-D',
  STRING_PARSE_FORMAT: 'DD-MM-YYYY',
};
export const NUMBER_FORMATS = {
  emptySeparator: {groupSeparator: '', decimalSeparator: '.'},
};
export const mobilePath = '/mobile';
export const ADAPTIVE_VERSION_HINT_DISABLED = 'ADAPTIVE_VERSION_HINT_DISABLED';
export const ADAPTIVE_DESIGN_ENABLED = 'ADAPTIVE_DESIGN_ENABLED';

export const numeralDictionaryKeys = ['ru', 'en', 'it', 'es', 'et'];

export const enabledLanguages = ['ru', 'en', 'it', 'es', 'et', 'hi_IN'];

export const languages = {
  cs: 'Сeština',
  kk: 'Қазақ',
  de: 'Deutsche',
  ru: 'Русский',
  pt: 'Portugueses',
  en: 'English',
  hi_IN: 'הינדיש',
  zh_CN: '中國傳統的',
  it: 'Italiano',
  ro: 'Română',
  es: 'Español',
  et: 'Eesti',
  tr: 'Turkey',
  fr: 'French',
};
export const URLMAP = {
  TERMINAL: '/',
  DASHBOARD: '/dashboard',
  MARKET: '/market/:code',
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  BALANCES: '/balances',
  TRANSACTIONS: '/transactions',
  MARKETS: '/markets',
  CONFIRM_EMAIL: '/user/confirmEmail/:confirmEmailToken/:confirmEmail?',
  CONFIRM_WITHDRAW_EMAIL: '/withdrawal/confirm/:confirmWithdrawId/:confirmWithdrawToken',
  CONFIRM_SETTING_ACTION: '/account/:actionName/:curSidTs/:specialMark',
  PROFILE: '/profile',
  RESTORE_PASSWORD: '/forgot',
  SET_NEW_PASSWORD: '/user/resetPassword/:token/',
  SET_NEW_PASSWORD2: '/user/resetPassword/:token/:email',
  OPEN_ORDERS: '/openOrders',
  TRADING_HISTORY: '/orderHistory',
  STATIC_PAGE: '/page/:name/:subName',
  MAINTENANCE: '/maintenance',
  WITHDRAW_SUCCESS: '/withdraw/success',
  WITHDRAW_ERROR: '/withdraw/error',
  FREEZE_ACCOUNT: '/freezeaccount',
  DOCUMENTS_HISTORY: '/documentsHistory',
  EXCHANGE: '/convert',
  NEW_EXCHANGE: '/new-exchange',
  SANCTIONS_BLOCK: '/sanctions-block',
  ASSET_INFO: '/asset_info/:assetName?',
};

export const ALLOWED_DOCUMENT_TYPES = `
  .jpeg,
  .jpg,
  .png,
  .pdf,
  .xls,
  .xlsx,
  .csv,
  .ods
`;
