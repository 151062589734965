import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import numeral from 'numeral';

import * as Actions from '../../api/Actions';
import BlockPreloader from '../../cmp/helpers/BlockPreloader';
import I18n from '../../cmp/helpers/I18n';
import {CurrencyIcon} from '../../cmp/helpers/CurrencyIcon';
import CurrencyBarChart from './CurrencyBarChart';
import {CURRENCIES_DIGITS} from '../../utils/currenciesDigits';
import {currencyFormatter} from '../../utils/utils';

const BASE_CURRENCY = 'USDU';

class Portfolio extends React.PureComponent {
  state = {
    isReady: false,
    wallets: [],
    hovered: undefined,
    totalConvertedBalance: 0,
  };

  componentDidMount() {
    this.props.getCurrencyXRates({baseCurrency: BASE_CURRENCY});
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    const {WsWalletReducer} = this.props;
    const {byCurrencyMap} = WsWalletReducer;

    if (this.state.isReady) {
      return;
    }

    if (!byCurrencyMap || !Object.keys(byCurrencyMap).length) {
      return;
    }

    const destinationCurrency = 'USDU';
    let totalConvertedBalance = 0;

    const walletsData = Object.keys(byCurrencyMap || {}).reduce((accumulator, currencyKey) => {
      const wallet = byCurrencyMap[currencyKey];
      const currencyUpper = currencyKey.toUpperCase();
      const crossCourses = WsWalletReducer.crossCourses || {};
      const balance = wallet.aValue + wallet.resValue;

      if (currencyUpper === destinationCurrency) {
        totalConvertedBalance += +balance;
        accumulator.push({
          currency: currencyKey,
          balance,
          convertedBalance: balance,
          convertedBalanceLabel: numeral(balance).format('0.00a'),
          value: balance,
        });
      } else if (
        wallet &&
        crossCourses &&
        crossCourses[destinationCurrency] &&
        crossCourses[destinationCurrency][wallet.monetaryUnitCode]
      ) {
        const convertedBalance = balance / crossCourses[destinationCurrency][wallet.monetaryUnitCode];
        totalConvertedBalance += +convertedBalance;

        accumulator.push({
          currency: currencyKey,
          balance,
          convertedBalance,
          convertedBalanceLabel: numeral(convertedBalance).format('0.00a'),
          value: convertedBalance,
        });
      }

      return accumulator;
    }, []);

    for (const w of walletsData) {
      w.amountFromTotal = (w.convertedBalance / totalConvertedBalance) * 100 || 0;
    }

    this.setState({
      isReady: true,
      wallets: walletsData,
      totalConvertedBalance,
    });
  }

  renderTable() {
    return (
      <React.Fragment>
        <div className='tab-pane-body table-portfolio'>
          <table className='table-orders'>
            <thead className='table-orders-header'>
              <tr>
                <th>
                  <I18n tKey='common.currency' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.amount' />
                </th>
                <th className='text-right'>
                  $ <I18n tKey='common.amount' />
                </th>
                <th className='text-right'>
                  <I18n tKey='common.balances' /> %
                </th>
              </tr>
            </thead>
            <tbody className='table-orders-body'>
              {this.state.wallets.map(w => (
                <tr key={w.currency}>
                  <td>
                    <CurrencyIcon curName={w.currency} />
                  </td>
                  <td className='text-right'>
                    {numeral(w.balance).format(`0,0.${'0'.repeat(CURRENCIES_DIGITS.get(w.currency) || 0)}`)}
                  </td>
                  <td className='text-right'>{currencyFormatter.format(w.convertedBalance)}</td>
                  <td className='text-right'>{numeral(w.amountFromTotal).format('0.0000')} %</td>
                </tr>
              ))}
            </tbody>
            <tfoot className='table-orders-footer'>
              <tr key={'total'}>
                <th>
                  <I18n tKey='balances.total_balance' />
                </th>
                <th className='text-right'>&nbsp;</th>
                <th className='text-right'>{currencyFormatter.format(this.state.totalConvertedBalance)}</th>
                <th className='text-right'>100 %</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </React.Fragment>
    );
  }

  render() {
    if (!this.state.isReady) {
      return <BlockPreloader />;
    }
    return (
      <React.Fragment>
        <CurrencyBarChart data={this.state.wallets} />

        {this.renderTable()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({WsWalletReducer, CurrencyXRatesReducer}) => ({
  WsWalletReducer,
  CurrencyXRatesReducer,
});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getCurrencyXRates: Actions.GetCurrencyXRates.action,
      },
      dispatch
    ),
  };
};
const connectedWithRouter = connect(mapStateToProps, mapDispatchToProps)(Portfolio);

export default connectedWithRouter;
