import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import React from 'react';
import * as Actions from '../../api/Actions';
import I18n from '../../cmp/helpers/I18n';
import {StatusHeader} from './StatusHeader';
import {URLMAP} from '../../utils/const';

export const STATUS = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  VERIFIED: 'VERIFIED',
};

export const REJECT_MESSAGE = {
  CAN_REAPPLY: 'BadData',
};

class Verifications extends React.PureComponent {
  displayName: 'DropdownFilter';

  state = {
    isFetching: true,
    tokenIsUpdating: false,
    accInfoError: false,
    globalPassError: false,
    updateGlobalPassError: false,
  };

  renderScreeningWidget = r => {
    return setTimeout(() => {
      $('#global-pass-element').html(
        '<iframe allow="camera; microphone" src="https://idv.ondato.com?id=' +
          r.data.token +
          '" width="100%" height="100%" frameborder="0"></iframe>'
      );
      // window.GPScreeningWidget.init({
      //   elementId: 'global-pass-element',
      //   token: r.data.token,
      //   redirectUri: window.location.origin + URLMAP.PROFILE + '/Verifications',
      // })
    }, 0);
  };

  updateToken = () => {
    this.setState({tokenIsUpdating: true, updateGlobalPassError: false});
    this.props.updateGlobalPassToken().then(r => {
      const newState = {tokenIsUpdating: false, updateGlobalPassError: false};
      if (r.isError) {
        //cant get token
        newState.updateGlobalPassError = true;
      } else {
        newState.kycInfo = {currentSt: STATUS.NEW};
        newState.token = {vendorStatus: STATUS.NEW};
      }
      this.setState(newState, () => {
        !r.isError && this.renderScreeningWidget(r);
      });
    });
  };

  componentDidMount() {
    const {getGlobalPassToken} = this.props;
    this.props.getAccountInfo().then(r => {
      const newState = {accInfoError: r.isError, globalPassError: false, isFetching: false, kycInfo: null};

      if (r.isError) {
        //cant get current kyc state
        return this.setState(newState);
      } else {
        newState.kycInfo = r.data;
        getGlobalPassToken().then(r => {
          if (r.isError) {
            //cant get token
            newState.globalPassError = true;
          } else {
            if (
              r.data.status === STATUS.NEW &&
              r.data.vendorStatus !== STATUS.PENDING &&
              r.data.vendorStatus !== STATUS.REJECTED
            ) {
              this.renderScreeningWidget(r);
            }
            newState.token = r.data;
          }
          this.setState(newState);
        });
      }
    });
  }

  render() {
    const {isFetching, tokenIsUpdating} = this.state;
    let status;
    this.state.kycInfo && this.state.kycInfo.currentSt ? (status = this.state.kycInfo.currentSt) : (status = '');

    if (this.state.accInfoError || this.state.globalPassError || this.state.updateGlobalPassError) {
      return (
        <div>
          <div className='tab-pane-header'>
            <div className='tab-pane-name'>
              {this.state.accInfoError && <I18n tKey='verify:account_status_is_not_definded' />}
              {this.state.globalPassError && <I18n tKey='verify:global_status_token_err' />}
              {this.state.updateGlobalPassError && <I18n tKey='verify:global_update_token_err' />}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <I18n tKey='verify:title' />
          </div>
        </div>
        {this.state.kycInfo && this.state.kycInfo.currentSt && (
          <StatusHeader
            isFetching={tokenIsUpdating}
            tryAgain={this.updateToken}
            status={this.state.kycInfo.currentSt}
            message={this.state.kycInfo.statMsg}
            comments={this.state.kycInfo.innerCmt}
            token={this.state.token}
          />
        )}
        <div className='tab-pane-body' id='global-pass-element' style={{height: '800px'}}></div>

        {/*isFetching ? <div><I18n tKey="common:loading"/></div> : <div>
          <div className="tab-pane-body" id="global-pass-element">
            {status === STATUS.NEW && <span><I18n tKey="verify:global_pass_in_progress"/></span>}
          </div>
        </div>*/}
      </div>
    );
  }
}

const mapStateToProps = ({LngReducer, CountriesListReducer, GlobalPassTokenReducer}) => ({
  LngReducer,
  CountriesListReducer,
  GlobalPassTokenReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getAccountInfo: Actions.KycAccountInfo.action,
        saveAccountInfo: Actions.KycAccountSave.action,
        saveUploadedDocument: Actions.KycUploadDocument.action,
        getCountries: Actions.VerifyCountryList.action,
        getGlobalPassToken: Actions.GlobalPassToken.action,
        updateGlobalPassToken: Actions.UpdateGlobalPassToken.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Verifications));

export default connectedWithRouter;
