// @flow
import * as React from 'react';
import {Fragment} from 'react';
import {CLOSE_POPUP} from '../../../reducers/PopupReducer';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import I18n from '../../helpers/I18n';
import {SvgWrapper} from '../../helpers/SvgWrapper';
import {ReactComponent as Envelope} from '../../../../../assets/images/modal/envelope.svg';

export const DISABLE_2FA_STEP2_POPUP = 'DISABLE_2FA_STEP2_POPUP';

class Enable2FAStep1Popup extends React.PureComponent<void, ComponentState> {
  render() {
    const {} = this.props;

    return (
      <Fragment>
        <div className='modal-2FA-form text-center'>
          <h2>
            <I18n tKey='popup:2fa_confirmation_mail:disable:title' />
          </h2>
          <div className='modal-signup_confirm'>
            <div className='image'>
              <SvgWrapper className='ic-size-19' SvgCmp={Envelope} />
            </div>
            <div className='text'>
              <I18n tKey='popup:2fa_confirmation_mail:disable:description' />
            </div>
            <div className='box-btn'>
              <a
                data-target='#login'
                onClick={this.props.close}
                data-toggle='modal'
                data-dismiss='modal'
                className='btn btn-md btn-primary btn-full'
              >
                <I18n tKey='common.proceed' />
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({}, dispatch),
    close: () => dispatch({type: CLOSE_POPUP}),
  };
};
const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Enable2FAStep1Popup));

export default connectedWithRouter;
