import React, {Component} from 'react';
import classNames from 'classnames';

class PriceChange extends Component {
  render() {
    const {children, isNegative, className} = this.props;
    return (
      <span className={classNames('price-change', {['red']: isNegative, ['green']: !isNegative}, className)}>
        {isNegative ? '-' : '+'}
        {children}
      </span>
    );
  }
}

export default PriceChange;
