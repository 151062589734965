import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import BigNumber from 'bignumber.js';
import React, {Fragment} from 'react';
import classNames from 'classnames';

import {OpenOrder, OrderList, OrderStopList} from '../../../../api/Actions';

import {ReactComponent as Minus} from '../../../../../../assets/images/icon/minus.svg';
import {ReactComponent as Plus} from '../../../../../../assets/images/icon/plus.svg';

import I18n from '../../../../cmp/helpers/I18n';
import {SvgWrapper} from '../../../../cmp/helpers/SvgWrapper';
import {CurrencyInput} from '../../../../cmp/helpers/CurrencyInput';
import {CurrencyView} from '../../../../cmp/helpers/CurrencyView';

import {translate} from '../../../../utils/i18n';
import Formatter from '../../../../utils/formatter';

export const MARKET_ORDER = 'MARKET_ORDER';
export const MARKET_ORDER_STOP = 'MARKET_ORDER_STOP';
export const LIMIT_ORDER = 'LIMIT_ORDER';
export const LIMIT_ORDER_STOP = 'LIMIT_ORDER_STOP';

const getOrderType = type => {
  switch (type) {
    case MARKET_ORDER:
    case MARKET_ORDER_STOP:
      return 'MARKET';
    case LIMIT_ORDER:
    case LIMIT_ORDER_STOP:
      return 'LIMIT';
    default:
      return '';
  }
};

class BuySell extends React.PureComponent {
  state = {
    price: this.props.ActiveMarketReducer.fixPrice || null,
    isFixPrice: !!this.props.ActiveMarketReducer.fixPrice,
    amount: '',
    stop: '',
    errors: {
      price: null,
      amount: null,
      stop: null,
    },
    tradingType: this.props.tradingType,
  };

  calculateTotal = (price, amount, fee) => {
    let total = null;
    if (price && amount) {
      total = (price * amount).toFixed(8);
      total = total.replace(/0+$/, '');
      if (total[total.length - 1] === '.') total = total.slice(0, total.length - 1);
    }
    return this.state.tradingType === 'sell' ? total * 1 - (total * fee) / 100 : total * 1 + (total * fee) / 100;
  };

  setAmountByPercent = (percent, available, fee) => {
    if (isNaN(available)) {
      return;
    }

    const isBuy = this.state.tradingType === 'buy';
    const {ActiveMarketReducer, CurrencyListReducer} = this.props;
    const leftPrecision = CurrencyListReducer.list.find(
      curr => curr.identificatSymbol === ActiveMarketReducer.leftMonetaryUnitIdentifier
    ).mURigor;
    const rightPrecision =
      CurrencyListReducer.list.find(curr => curr.identificatSymbol === ActiveMarketReducer.rightMonetaryUnitIdentifier)
        .mURigor || 4;
    const balanceFraction = new BigNumber(available)
      .multipliedBy(percent)
      .div(100)
      .toFixed(isBuy ? rightPrecision : leftPrecision);

    this.inputChanges(fee, isBuy ? 'total' : 'amount', balanceFraction);
  };

  addPrice = (num, fee) => {
    const price = (parseFloat(this.state.price) || 0) + parseFloat(num);
    this.inputChanges(fee, 'price', price);
  };

  getActiveMarketCurrentPrice = () => {
    const tickers = this.props.WsTickersReducer.tickers;
    const market = this.props.ActiveMarketReducer;
    const stats = this.props.WsMarketStatsReducer[market.identificatSymbol];

    return tickers.next[market.identificatSymbol] || (stats && stats.lastPrice) || 0;
  };

  setPrice = (type, fee) => {
    const orderBook = this.props.OrderbookReducer;
    let value = 0;
    if (type === 'last') {
      value = this.getActiveMarketCurrentPrice();
    } else if (type === 'ask' && orderBook && (orderBook.sell || []).length > 0) {
      value = orderBook.sell[0].price;
    } else if (type === 'bid' && orderBook && (orderBook.buy || []).length > 0) {
      value = orderBook.buy[0].price;
    } else {
      value = type;
    }

    this.changePrice({target: {value}}, fee);
  };

  changePrice = (e, fee) => {
    const price = Formatter.cleanNumber(e.target.value, this.state.price);
    this.inputChanges(fee, 'price', price);
  };

  handleStopChange = (propName, value) => {
    this.setState({[propName]: value});
  };

  inputChanges = (fee, propName, newValue) => {
    const {ActiveMarketReducer, CurrencyListReducer} = this.props;
    const leftPrecision = CurrencyListReducer.list.find(
      curr => curr.identificatSymbol === ActiveMarketReducer.leftMonetaryUnitIdentifier
    ).mURigor;
    const rightPrecision =
      CurrencyListReducer.list.find(curr => curr.identificatSymbol === ActiveMarketReducer.rightMonetaryUnitIdentifier)
        .mURigor || 4;
    const {orderType} = this.props;
    let price;

    if (propName === 'price') {
      price = newValue;
    } else {
      if (orderType === MARKET_ORDER || orderType === MARKET_ORDER_STOP) {
        price = this.getActiveMarketCurrentPrice();
      } else {
        price = this.state.price;
      }
    }

    const amount = propName === 'amount' ? newValue : this.state.amount;

    if (propName !== 'total') {
      this.setState({
        [propName]: newValue,
      });
    }

    if (!price) {
      this.setState({total: ''});
      return;
    }

    if (propName === 'total') {
      const isBuy = this.state.tradingType === 'buy';
      const feeFraction = new BigNumber(fee).div(100).toFixed(3);
      let feeMultiplier;

      if (isBuy) {
        feeMultiplier = new BigNumber(1).plus(feeFraction).toFixed(leftPrecision);
      } else {
        feeMultiplier = new BigNumber(1).minus(feeFraction).toFixed(leftPrecision);
      }

      const feePrice = new BigNumber(price).multipliedBy(feeMultiplier).toNumber();
      const amountWithFee = +new BigNumber(newValue).dividedBy(feePrice).toFixed(leftPrecision, 1);

      this.setState({
        amount: amountWithFee,
        total: newValue,
      });

      return;
    }

    if (!amount) {
      this.setState({total: ''});
      return;
    }
    const total = this.calculateTotal(price, amount, fee).toFixed(rightPrecision) * 1;
    this.setState({total});
  };

  openOrder = (market, isBuy) => {
    if (!this.props.SessionReducer.masterPersonality) {
      this.props.showHint();

      return;
    }

    const errors = {
      price:
        (this.props.orderType === LIMIT_ORDER || this.props.orderType === LIMIT_ORDER_STOP) && this.state.price <= 0
          ? `${translate('common.price_empty')}`
          : null,
      amount: this.state.amount <= 0 ? `${translate('common.amount_empty')}` : null,
      stop:
        (this.props.orderType === MARKET_ORDER_STOP || this.props.orderType === LIMIT_ORDER_STOP) &&
        this.state.stop <= 0
          ? `${translate('common.stop_order_empty')}`
          : null,
    };

    this.setState({
      errors: errors,
    });

    if (errors.price || errors.amount || errors.stop) return;

    const order: Order = {
      odrActualQty: this.state.amount,
      odrValue: this.state.price,
      marketId: market.identificatSymbol,
      odrCurrentS: !isBuy ? 'SELL' : 'BUY',
      odrCurrentT: getOrderType(this.props.orderType),
      ...((this.props.orderType === MARKET_ORDER_STOP || this.props.orderType === LIMIT_ORDER_STOP) && {
        stop: this.state.stop,
      }),
    };

    this.props.openOrder(order).then(() => {
      if (!this.props.OpenOrderReducer.error) {
        this.setState({price: null, amount: '', total: ''});
        this.props.updatePagination(1);
        this.props.getOrderList({rng: this.props.limit, listOfst: 0});
        this.props.getOrderStopList();
      }
    });
  };

  componentWillReceiveProps(next) {
    const {SelectedOrderReducer, SessionReducer, ActiveMarketReducer, CurrencyListReducer} = this.props;
    const currencyList = CurrencyListReducer.list || [];

    if (this.props.ActiveMarketReducer.fixPrice) {
      this.setState({
        price: this.props.ActiveMarketReducer.fixPrice,
        isFixPrice: true,
      });
      this.props.setOrderType(LIMIT_ORDER);
    } else {
      this.setState({isFixPrice: false});
    }

    if (
      SelectedOrderReducer.price !== next.SelectedOrderReducer.price ||
      SelectedOrderReducer.amount !== next.SelectedOrderReducer.amount
    ) {
      const market = ActiveMarketReducer || {};
      const fee =
        (
          (SessionReducer.masterPersonality &&
            SessionReducer.masterPersonality.ctcInstrListResp &&
            SessionReducer.masterPersonality.ctcInstrListResp[market.identificatSymbol]) ||
          {}
        ).tFeeVal || 0;
      const rightPrecision =
        CurrencyListReducer.list.find(
          curr => curr.identificatSymbol === ActiveMarketReducer.rightMonetaryUnitIdentifier
        ).mURigor || 4;
      const price =
        this.props.orderType === LIMIT_ORDER || this.props.orderType === LIMIT_ORDER_STOP
          ? next.SelectedOrderReducer.price
          : this.getActiveMarketCurrentPrice();
      const stateToSet = {
        price: price,
        amount: next.SelectedOrderReducer.amount,
        total: this.calculateTotal(price, next.SelectedOrderReducer.amount, fee).toFixed(rightPrecision) * 1,
      };
      this.setState(stateToSet);
    }
    if (next.ActiveMarketReducer.mBanner !== ActiveMarketReducer.mBanner) {
      this.setState({price: null, amount: '', total: ''});
    }
    if (next.currencyList !== currencyList) {
      this.setState({errors: {...this.state.errors, price: null, amount: null, stop: null}});
    }
    if (next.ActiveMarketReducer.mBanner !== this.props.ActiveMarketReducer.mBanner) {
      this.props.clearOpenOrderDataError();
    }
    if (next.MarketListReducer.fetching !== this.props.MarketListReducer.fetching) {
      this.props.clearOpenOrderDataError();
    }
  }

  render() {
    const {CurrencyListReducer, ActiveMarketReducer, OpenOrderReducer, SessionReducer} = this.props;
    const {isFixPrice} = this.state;
    const currencyList = CurrencyListReducer.list || [];
    if (!ActiveMarketReducer) return null;
    const market = ActiveMarketReducer || {};
    const {valueRng} = market;
    const priceLimitPercent = valueRng;
    const rCurr = currencyList.filter(c => c.mUCryptogram === market.rightMonetaryUnitCode)[0] || {};
    const lCurr = currencyList.filter(c => c.mUCryptogram === market.leftMonetaryUnitCode)[0] || {};
    const isBuy = this.state.tradingType === 'buy';
    const isLimit = this.props.orderType === LIMIT_ORDER || this.props.orderType === LIMIT_ORDER_STOP;
    let fee = 0;

    let leftWallet,
      rightWallet = null,
      wallets = this.props.WsWalletReducer;
    if (!!market) {
      leftWallet = wallets.byCurrencyMap[market.leftMonetaryUnitCode] || {};
      rightWallet = wallets.byCurrencyMap[market.rightMonetaryUnitCode] || {};
      // fee = (((SessionReducer.masterPersonality && SessionReducer.masterPersonality.ctcInstrListResp && SessionReducer.masterPersonality.ctcInstrListResp[market.identificatSymbol]) || {}).tFeeVal) || 0;
      fee =
        (
          (SessionReducer.masterPersonality &&
            SessionReducer.masterPersonality.ctcInstrListResp &&
            SessionReducer.masterPersonality.ctcInstrListResp.find(
              item => item.ctcInstrIdentifier === market.identificatSymbol
            )) ||
          {}
        ).tFeeVal || 0;
    }

    let minPrice, maxPrice;
    if (!!priceLimitPercent) {
      const lastPrice = this.getActiveMarketCurrentPrice();
      minPrice = parseFloat(
        Formatter.cleanNumber(lastPrice - (lastPrice * priceLimitPercent) / 100, null, rCurr.mUCryptogram)
      );
      maxPrice = parseFloat(
        Formatter.cleanNumber(lastPrice + (lastPrice * priceLimitPercent) / 100, null, rCurr.mUCryptogram)
      );
    }

    let {error: openOrderError} = OpenOrderReducer;

    return (
      <section className='buysell'>
        <div className='buysell_body'>
          <div className='buysell_body_form'>
            <div className='buysell_body-item'>
              <div className='buysell_body-item-text' />
              <div className='buysell_body-item-value'>
                <div className='buysell_body-item-value-factor'>
                  {[25, 50, 75, 100].map(p => {
                    return (
                      <div
                        key={p}
                        className='buysell_body-item-value-factor-item'
                        onClick={this.setAmountByPercent.bind(
                          this,
                          p,
                          isBuy ? rightWallet.aValue : leftWallet.aValue,
                          fee
                        )}
                      >
                        {p}%
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className='buysell_body-item'>
              <div className='buysell_body-item-text'>
                <I18n tKey='common.amount' />
              </div>

              <div
                className={classNames('buysell_body-item-value', {
                  ['market-view left-auto']:
                    this.props.orderType === MARKET_ORDER || this.props.orderType === MARKET_ORDER_STOP,
                })}
              >
                <div className='buysell_body-item-value-form'>
                  <div className='box-control'>
                    <CurrencyInput
                      onChange={this.inputChanges.bind(this, fee)}
                      placeholder='0'
                      name='amount'
                      value={this.state.amount || ''}
                      currencyType={lCurr.mUCryptogram}
                    />
                    <span className='value'>{lCurr.mUCryptogram}</span>
                  </div>
                </div>

                {this.state.errors.amount && (
                  <div style={{color: 'red', fontSize: 'small'}}>{this.state.errors.amount}</div>
                )}
              </div>

              {(this.props.orderType === MARKET_ORDER || this.props.orderType === MARKET_ORDER_STOP) && (
                <div className='buysell_body-item-value market-view'>
                  <div className='buysell_body-item-value-form'>
                    <div className='box-control'>
                      <CurrencyInput
                        onChange={this.inputChanges.bind(this, fee)}
                        placeholder='0'
                        name='total'
                        value={this.state.total || ''}
                        currencyType={rCurr.mUCryptogram}
                      />
                      <span className='value'>{rCurr.mUCryptogram}</span>
                    </div>
                  </div>

                  {this.state.errors.amount && (
                    <div style={{color: 'red', fontSize: 'small'}}>{this.state.errors.amount}</div>
                  )}
                </div>
              )}
            </div>

            {(this.props.orderType === MARKET_ORDER || this.props.orderType === MARKET_ORDER_STOP) && (
              <div className='buysell_body-item-value-text'>
                <I18n tKey='common.approximate_amount' />
              </div>
            )}

            {isLimit && !isFixPrice && (
              <div className='buysell_body-item'>
                <div className='buysell_body-item-text' />
                <div className='buysell_body-item-value'>
                  <div className='buysell_body-item-value-factor'>
                    <div className='buysell_body-item-value-factor-item' onClick={this.setPrice.bind(this, 'ask', fee)}>
                      <I18n tKey='common.ask' />
                    </div>
                    <div className='buysell_body-item-value-factor-item' onClick={this.setPrice.bind(this, 'bid', fee)}>
                      <I18n tKey='common.bid' />
                    </div>
                    <div
                      className='buysell_body-item-value-factor-item'
                      onClick={this.setPrice.bind(this, 'last', fee)}
                    >
                      <I18n tKey='common.last' />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className='buysell_body-item'>
              <div className='buysell_body-item-text'>
                <I18n tKey='common.price' />
              </div>
              <div className='buysell_body-item-value'>
                <div className='buysell_body-item-value-form'>
                  {isLimit && (
                    <div className='box-control'>
                      <CurrencyInput
                        onChange={this.inputChanges.bind(this, fee)}
                        readOnly={isFixPrice}
                        placeholder='0.0000'
                        value={this.state.price || ''}
                        currencyType={rCurr.mUCryptogram}
                        name='price'
                      />
                      <span className='value'>{rCurr.mUCryptogram}</span>
                      {!isFixPrice && (
                        <div className='box-control-button'>
                          <a style={{visibility: this.state.price - 1 > 0 ? 'visible' : 'hidden'}}>
                            <SvgWrapper
                              themeWatch
                              className='icon icon-min ic-size-07'
                              SvgCmp={Minus}
                              onClick={this.addPrice.bind(this, -1, fee)}
                            />
                          </a>
                          <a>
                            <SvgWrapper
                              themeWatch
                              className='icon icon-max ic-size-07'
                              SvgCmp={Plus}
                              onClick={this.addPrice.bind(this, 1, fee)}
                            />
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                  {!isLimit && (
                    <div className='box-control'>
                      <input
                        type='text'
                        placeholder={translate('common.market')}
                        className='form-control '
                        disabled={true}
                      />
                      <span className='value'>{rCurr.mUCryptogram}</span>
                    </div>
                  )}
                </div>
                {this.state.errors.price && (
                  <div style={{color: 'red', fontSize: 'small'}}>
                    <I18n tKey={this.state.errors.price} />
                  </div>
                )}
              </div>
            </div>

            {(this.props.orderType === LIMIT_ORDER_STOP || this.props.orderType === MARKET_ORDER_STOP) && (
              <div className='buysell_body-item'>
                <div className='buysell_body-item-text'>
                  <I18n tKey='common.order_stop' />
                </div>

                <div className='buysell_body-item-value'>
                  <div className='buysell_body-item-value-form'>
                    <div className='box-control'>
                      <CurrencyInput
                        onChange={this.handleStopChange}
                        placeholder='0'
                        name='stop'
                        value={this.state.stop}
                        currencyType={rCurr.mUCryptogram}
                      />
                      <span className='value'>{rCurr.mUCryptogram}</span>
                    </div>
                  </div>
                  {this.state.errors.stop && (
                    <div style={{color: 'red', fontSize: 'small'}}>
                      <I18n tKey={this.state.errors.stop} />
                    </div>
                  )}
                </div>
              </div>
            )}

            {isLimit && !isFixPrice && !!priceLimitPercent && (
              <div className='buysell_body-item-value-text price-range'>
                <I18n tKey='common.allowed_range' />
                :&nbsp;
                <span className='setPrice' onClick={this.setPrice.bind(this, minPrice, fee)}>
                  {minPrice || ''}
                </span>{' '}
                -{' '}
                <span className='setPrice' onClick={this.setPrice.bind(this, maxPrice, fee)}>
                  {maxPrice || ''}
                </span>{' '}
                {rCurr.mUCryptogram}
              </div>
            )}

            {isLimit && (
              <div className='buysell_body-item'>
                <div className='buysell_body-item-text'>
                  <I18n tKey='common.total' />
                </div>
                <div className='buysell_body-item-value'>
                  <div className='buysell_body-item-value-form'>
                    <div className='box-control'>
                      <CurrencyInput
                        placeholder='0'
                        onChange={this.inputChanges.bind(this, fee)}
                        name='total'
                        readOnly={!(this.state.price > 0)}
                        value={this.state.total || ''}
                        currencyType={rCurr.mUCryptogram}
                      />
                      <span className='value'>{rCurr.mUCryptogram}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='buysell_body-item'>
              <div className='buysell_body-item-text' />
              <div className='buysell_body-item-value'>
                <div className='buysell_body-item-value-text'>
                  <I18n tKey='common.include_fee' />: {fee}%
                </div>
              </div>
            </div>
          </div>

          <div className='box-btn-double'>
            <button
              className={classNames('btn', {
                ['btn-buy']: this.state.tradingType === 'buy',
                ['btn-sell']: this.state.tradingType !== 'buy',
              })}
              disabled={OpenOrderReducer.fetching}
              onClick={() => !OpenOrderReducer.fetching && this.openOrder(market, this.state.tradingType === 'buy')}
            >
              <I18n tKey={this.state.tradingType === 'buy' ? 'common.buy' : 'common.sell'} />{' '}
              {ActiveMarketReducer.leftMonetaryUnitCode}
            </button>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({
  ActiveMarketReducer,
  WsWalletReducer,
  SelectedOrderReducer,
  MarketListReducer,
  CurrencyListReducer,
  OpenOrderReducer,
  SessionReducer,
  WsTickersReducer,
  OrderbookReducer,
  WsMarketStatsReducer,
}) => {
  return {
    ActiveMarketReducer,
    MarketListReducer,
    WsWalletReducer,
    CurrencyListReducer,
    OpenOrderReducer,
    SessionReducer,
    WsTickersReducer,
    SelectedOrderReducer,
    OrderbookReducer,
    WsMarketStatsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        openOrder: OpenOrder.action,
        getOrderList: OrderList.action,
        getOrderStopList: OrderStopList.action,
      },
      dispatch
    ),
    clearOpenOrderDataError: () => dispatch({type: OpenOrder.events.onClear}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuySell);
