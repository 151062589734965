import React from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import InputPopup from '../InputPopup';
import * as Actions from '../../../api/Actions';
import {translate} from '../../../utils/i18n';
import I18n from '../../helpers/I18n';

export const CREATE_KEY = 'CREATE_KEY';
export const EDIT_KEY = 'EDIT_KEY';

export const closeCreateApiKeyPopup = () => $('#modal-key').modal('hide');

class CreateApiKeyPopup extends React.Component {
  state = {
    ipWhithelist: '',
    code2FA: '',
    password: '',
    passwordError: '',
    permissionsState: {},
    error: '',
  };

  componentWillReceiveProps(n) {
    let permissionsObj = {};
    const reqError = n.CreateAccountApiKeyReducer.error || n.UpdateAccountApiKeyReducer.error;
    reqError ? this.setState({error: reqError.error}) : this.setState({error: ''});
    (n.permissions || []).map(permission => {
      permissionsObj[permission.label] = {
        baseApiKeyPermissionId: permission.id,
        id: 0,
        readAccess: false,
        writeAccess: false,
      };
    });
    ((n.userKey && n.userKey.permissions) || []).map(permission => {
      permissionsObj[permission.baseApiKeyPermission.label] = {
        baseApiKeyPermissionId: permission.baseApiKeyPermission.id,
        id: permission.id,
        readAccess: permission.readAccess,
        writeAccess: permission.writeAccess,
      };
    });
    n.userKey
      ? this.setState({
          permissionsState: permissionsObj,
          keyLabel: n.userKey.stringTag,
        })
      : this.setState({permissionsState: permissionsObj, keyLabel: ''});
  }

  changeAccessPermission = (permissionLabel, prop) => {
    this.setState({
      permissionsState: {
        ...this.state.permissionsState,
        [permissionLabel]: {
          ...this.state.permissionsState[permissionLabel],
          [prop]: !this.state.permissionsState[permissionLabel][prop],
        },
      },
    });
  };

  keyOperation = () => {
    const {userKey, permissions} = this.props;
    const is2Fa = this.props.SessionReducer.masterPersonality
      ? this.props.SessionReducer.masterPersonality.googleAuthAllowanceFlag
      : false;
    const {ipWhithelist, code2FA, password, keyLabel} = this.state;
    this.setState({ipWhithelistError: null, code2FAError: null, keyLabelError: null, passwordError: null});
    let hasError = null;

    const arr = userKey
      ? (userKey.permissions || []).map(elem => {
          return {...this.state.permissionsState[elem.baseApiKeyPermission.label]};
        })
      : permissions.map(elem => {
          return {...this.state.permissionsState[elem.label]};
        });
    /*if (!ipWhithelist) {
      this.setState({ipWhithelistError: {parsedError: "popup.enter_ip"}});
      hasError = true;
    }*/

    if (is2Fa) {
      if (!code2FA) {
        this.setState({code2FAError: {parsedError: 'popup.enter_2fa'}});
        hasError = true;
      }
    } else {
      if (!password) {
        this.setState({passwordError: {parsedError: 'popup.enter_pswd'}});
        hasError = true;
      }
    }
    if (!keyLabel) {
      this.setState({keyLabelError: {parsedError: 'popup.enter_key'}});
      hasError = true;
    }
    const passOrCode = is2Fa ? {tfCryptogram: code2FA} : {lockPickSymbolRow: password};
    const data = {
      electronicMailConfirmT: 'DEFAULT',
      stringTag: this.state.keyLabel,
      //prmsnSet: arr,
      //curSidTs: userKey ? userKey.curSidTs : "string",
      ...passOrCode,
    };
    if (!hasError) {
      userKey
        ? this.props.updateKey(data)
        : this.props.createKey(data).then(() => {
            this.props.keysList();
          });
      this.setState({ipWhithelist: '', code2FA: '', keyLabel: '', password: ''});
    }
  };

  render() {
    const is2Fa = this.props.SessionReducer.masterPersonality
      ? this.props.SessionReducer.masterPersonality.googleAuthAllowanceFlag
      : false;
    const {permissions, userKey, UpdateAccountApiKeyReducer, CreateAccountApiKeyReducer} = this.props;
    const {error} = this.state;
    const fetching = UpdateAccountApiKeyReducer.fetching || CreateAccountApiKeyReducer.fetching;
    return (
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>{permissions ? <I18n tKey='history.config_title' /> : <I18n tKey='history.edit_title' />}</h2>
          </div>
          <div className='modal-content_setting'>
            <div className='block-permissions'>
              {(permissions || (userKey && userKey.permissions) || []).map((permission, index) => {
                const permissionLabel = permission.baseApiKeyPermission
                  ? permission.baseApiKeyPermission.label
                  : permission.label;
                return (
                  <div key={index} className='block-permissions-item'>
                    <div className='block-permissions-item-name'>{permissionLabel}</div>
                    <div className='block-permissions-item-checkbox'>
                      <div className='ui-form-checkbox'>
                        <input
                          type='checkbox'
                          id={`checkbox${index}read`}
                          checked={this.state.permissionsState[permissionLabel].readAccess}
                          onChange={this.changeAccessPermission.bind(this, permissionLabel, 'readAccess')}
                        />
                        <label htmlFor={`checkbox${index}read`}>
                          <I18n tKey='common.read' />
                        </label>
                      </div>
                      <div className='ui-form-checkbox'>
                        <input
                          type='checkbox'
                          id={`checkbox${index}write`}
                          checked={this.state.permissionsState[permissionLabel].writeAccess}
                          onChange={this.changeAccessPermission.bind(this, permissionLabel, 'writeAccess')}
                        />
                        <label htmlFor={`checkbox${index}write`}>
                          <I18n tKey='common.write' />
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='modal-content_form'>
            <form className='ui-form'>
              <InputPopup
                placeholder='popup.key_label'
                onKeyDown={this.onKeyDown}
                value={this.state.keyLabel}
                error={this.state.keyLabelError}
                toCleanError={() => this.state.keyLabelError && this.setState({keyLabelError: null})}
                onChange={value => this.setState({keyLabel: value})}
              />
              {/*<InputPopup
                placeholder="popup.ip_label"
                onKeyDown={this.onKeyDown}
                value={this.state.ipWhithelist}
                error={this.state.ipWhithelistError}
                regEx={/\d+\.?$/}
                toCleanError={() => this.state.ipWhithelistError && this.setState({ipWhithelistError: null})}
                onChange={(value) => this.setState({ipWhithelist: value})}
              />*/}
              {is2Fa ? (
                <InputPopup
                  placeholder='popup.two_fa'
                  onKeyDown={this.onKeyDown}
                  toCleanError={() => this.state.code2FAError && this.setState({code2FAError: null})}
                  value={this.state.code2FA}
                  error={this.state.code2FAError}
                  onChange={value => this.setState({code2FA: value})}
                />
              ) : (
                <InputPopup
                  placeholder='common.password'
                  onKeyDown={this.onKeyDown}
                  toCleanError={() => this.state.passwordError && this.setState({passwordError: null})}
                  value={this.state.password}
                  error={this.state.passwordError}
                  className={'ui-form-control-secure'}
                  onChange={value => this.setState({password: value})}
                />
              )}

              <div className='ui-form-group-btn'>
                {(permissions || (userKey && userKey.permissions) || []) && (
                  <div className='ui-form-btn'>
                    {fetching && (
                      <a className='btn btn-primary btn-md'>
                        <span className='upper'>
                          <I18n tKey='common.loading' />
                        </span>
                      </a>
                    )}
                    {!fetching && (
                      <a className='btn btn-primary btn-md' onClick={this.keyOperation}>
                        <span className='upper'>
                          {permissions ? <I18n tKey='popup.create_key' /> : <I18n tKey='popup.save_changes' />}
                        </span>
                      </a>
                    )}
                  </div>
                )}
                <div className='ui-form-btn'>
                  <a data-dismiss='modal' className='btn-link'>
                    <I18n tKey='common.cancel' />
                  </a>
                </div>
              </div>

              {error && (
                <div className='popup-error-message common red'>
                  <I18n tKey={error} />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({UpdateAccountApiKeyReducer, CreateAccountApiKeyReducer, SessionReducer}) => ({
  UpdateAccountApiKeyReducer,
  CreateAccountApiKeyReducer,
  SessionReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        keysList: Actions.GetAccountApiKeys.action,
        createKey: Actions.CreateAccountApiKey.action,
        updateKey: Actions.UpdateAccountApiKey.action,
        resendConfirmation: Actions.ResendApiKeyConfirmationEmail.action,
      },
      dispatch
    ),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateApiKeyPopup));
