import {ORDER_MODE, total_amount_option} from '../cmp/modal/ModalOrderBook';
import {marker_option} from '../cmp/modal/ModalOrderSet';
import * as locale from 'react-date-range/dist/locale';
import storageHelper from 'storage-helper';
import BigNumber from 'bignumber.js';

//local setting features
const EXCHANGE_LOCAL_SETTING_KEY = 'EXCHANGE_LOCAL_SETTING_KEY';
export const settingNames = {activeMarket: 'activeMarket', orderBook: 'orderBook', openOrders: 'openOrders'};
export const createOrderBoxSettingObj = (boxName, title, name, propName, unitCode) => ({
  boxName,
  title,
  name,
  propName,
  unitCode,
});
export const createOpenOrderBoxSettingObj = (boxName, label, name) => ({boxName, label, name});
const defaultSettings = {
  [settingNames.activeMarket]: null,
  [settingNames.orderBook]: {
    orderMode: ORDER_MODE.COLUMN,
    radio: total_amount_option,
    boxes: [
      createOrderBoxSettingObj('price', 'common.price', 'price', null, 'right'),
      createOrderBoxSettingObj('amount', 'common.amount', 'amount', null, 'left'),
      createOrderBoxSettingObj('total', 'common.total', 'total', null, 'right'),
    ],
  },
  [settingNames.openOrders]: {
    radio: marker_option,
    boxes: [
      createOpenOrderBoxSettingObj('market', 'common.market', 'market'),
      createOpenOrderBoxSettingObj('type', 'common.type', 'type'),
      createOpenOrderBoxSettingObj('openTime', 'common.open_time', 'openTime'),
      createOpenOrderBoxSettingObj('amount', 'common.amount', 'amount'),
      createOpenOrderBoxSettingObj('price', 'common.price', 'price'),
      createOpenOrderBoxSettingObj('total', 'common.total', 'total'),
      createOpenOrderBoxSettingObj('status', 'common.status', 'status'),
    ],
  },
};
export const returnLocalSettings = settingName => {
  let settings = defaultSettings[settingName];
  if (settingName === settingNames.activeMarket) {
    const savedSettings = storageHelper.getItem(EXCHANGE_LOCAL_SETTING_KEY, true);
    if (savedSettings && savedSettings[settingName]) {
      settings = savedSettings[settingName];
    }
  }

  return settings;
};
export const changeLocalSettings = (settingName, newValue, propName) => {
  let savedSettings = returnLocalSettings(settingName);
  if (propName) {
    savedSettings[propName] = newValue;
  } else {
    savedSettings = newValue;
  }
  const oldSettings = storageHelper.getItem(EXCHANGE_LOCAL_SETTING_KEY, true) || defaultSettings;
  storageHelper.setItem(EXCHANGE_LOCAL_SETTING_KEY, JSON.stringify({...oldSettings, [settingName]: savedSettings}));
};
//local setting features

export const formatCalendarLngPrefix = lng => {
  const field = lng.replace('_', '');
  if (!locale[field]) {
    return locale.enUS;
  }
  return locale[field];
};

export const changeFormat = (x, round = 2, fmt) => {
  if (isNaN(Number(x))) {
    return x;
  }
  if (fmt) {
    return new BigNumber(x).toFormat(round, fmt);
  } else {
    return new BigNumber(x).toFormat(round);
  }
};

export const FIAT_PROVIDER = {
  GTI: 'GUPay',
  QUANTUM: 'Quantum',
};
export const getFiatWithdrawFee = (actionsFeesList, currencyCode, provider) => {
  /* providers = [GUPay, Quantum] */
  const actionFees = actionsFeesList.find(c => c.currencyCode.toLowerCase() === currencyCode.toLowerCase());
  if (!actionFees) {
    return null;
  }

  const matches = actionFees.withdrawFee.match(new RegExp(`([\\d+\\.]+%)\\s*?${provider}`, 'i'));
  if (!matches || !matches[1]) {
    return 0;
  }
  return matches[1];
};

export const isMobile = window.matchMedia('only screen and (max-width: 1024px)').matches;

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
