import React, {Fragment} from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import I18n from '../../cmp/helpers/I18n';
import * as Actions from '../../api/Actions';

class RedeemVoucher extends React.PureComponent {
  state = {
    voucherError: false,
    voucherCode: '',
  };

  async redeemVoucher() {
    const voucherCode = this.state.voucherCode.trim();
    if (!voucherCode) {
      this.setState({voucherError: true});
      return;
    }

    this.props.redeemVoucher({token: voucherCode});
  }

  setVoucher(e) {
    const value = e.target.value.replace(/[^\w]/g, '').trim();
    this.setState({voucherCode: value});
    if (this.state.voucherError && !!value) {
      this.setState({voucherError: false});
    }
  }

  render() {
    const {RedeemVoucherReducer} = this.props;
    const {fetching, error} = RedeemVoucherReducer;
    if (RedeemVoucherReducer.completed) {
      this.props.history.push('/balances/TransactionHistory');
    }

    return (
      <Fragment>
        <div className={'voucher-tab'}>
          <div className={'tab-pane-info-title'}>
            <I18n tKey='vouchers.redeem_voucher' />
          </div>
          <div className={'add-info'}>
            <div className='box-control '>
              <label className='label'>
                <I18n tKey='vouchers.paste_code' />
              </label>
              <input
                className={'form-control ' + (this.state.voucherError ? 'error' : '')}
                type='text'
                value={this.state.voucherCode}
                onChange={e => this.setVoucher(e)}
              />

              {!fetching && (
                <button type='button' className='btn btn-primary' onClick={() => this.redeemVoucher()}>
                  <I18n tKey='vouchers.redeem' />
                </button>
              )}
              {!error && this.state.voucherError && !this.state.voucherCode && (
                <div className='red'>
                  <small>
                    <I18n tKey='vouchers.no_code_error' />
                  </small>
                </div>
              )}
              {!!error && (
                <div className='red'>
                  <small>
                    {!!error.error && (error.error === 'NOT_FOUND' || error.error === 'VALIDATION_ERROR') && (
                      <I18n tKey='vouchers.incorrect_code' />
                    )}
                    {!!error.error && error.error !== 'NOT_FOUND' && error.error !== 'VALIDATION_ERROR' && error.error}
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({RedeemVoucherReducer}) => ({RedeemVoucherReducer});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        redeemVoucher: Actions.RedeemVoucher.action,
      },
      dispatch
    ),
  };
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(RedeemVoucher));

export default connectedWithRouter;
