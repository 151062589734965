import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as Actions from '../../../../api/Actions';
import {CreateTicket} from '../../../../api/Actions';
import {OPEN_POPUP} from '../../../../reducers/PopupReducer';
import {TICKET_CREATED_POPUP} from '../../../../cmp/popups/custom/TicketCreated';

const mapStateToProps = ({CreateTicketReducer}) => {
  return {
    CreateTicketReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        createTicket: Actions.CreateTicket.action,
      },
      dispatch
    ),
    openTicketCreateModal: closeCb => dispatch({type: OPEN_POPUP, name: TICKET_CREATED_POPUP, data: {closeCb}}),
    cleanCreatedTicket: () => dispatch({type: CreateTicket.events.onClear}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
