// @flow
export default class Resolution {
  determineTVTime = (time: string) => {
    let number = time.match(/\d+/g);
    let name = time.match(/[a-zA-Z]+/g);
    if (name) {
      switch (name[0]) {
        case 'D': {
          let number = number ? number[0] : 14;
          return this.days(number, true, name[0]);
        }
        case 'W': {
          let number = number ? number[0] : 1;
          return this.weeks(number, true, name[0]);
        }
        case 'M':
          return this.months(number[0], true, name[0]);
      }
    } else {
      if (number[0] <= 59) return this.minutes(number[0], false, 'm');
      return this.hours(number[0] / 60, false, 'h');
    }
  };

  determineTime = (time: string) => {
    let number = time.match(/\d+/g);
    let name = time.match(/[a-zA-Z]+/g);
    if (name && number) {
      switch (name[0]) {
        case 'm':
          return this.minutes(number[0], true);
        case 'h':
          return this.hours(number[0] * 60, true);
        case 'D':
          return this.days(number[0], true, name[0]);
        case 'W':
          return this.weeks(number[0], true, name[0]);
        case 'M':
          return this.months(number[0], true, name[0]);
      }
    }
  };

  minutes = (time: string, revert: boolean, name: string) => {
    if (revert) return time;
    return time + name;
  };

  hours = (time: string, revert: boolean, name: string) => {
    if (revert) return time;
    return time + name;
  };

  days = (time: string, revert: boolean, name: string) => {
    return time + name;
  };

  weeks = (time: string, revert: boolean, name: string) => {
    return time + name;
  };

  months = (time: string, revert: boolean, name: string) => {
    return time + name;
  };
}
