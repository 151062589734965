import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Footer from './Footer';
import {bindActionCreators} from 'redux';
import * as Actions from '../api/Actions';
import Loader from '../cmp/Loader';
import I18n from '../cmp/helpers/I18n';

class FeesPage extends React.PureComponent {
  componentDidMount() {
    const {getDefaultFees, DefaultFeesReducer} = this.props;
    if (!DefaultFeesReducer.completed) {
      getDefaultFees();
    }
  }

  renderFeeSetTable(feeSet) {
    const values = feeSet.values.sort((a, b) => parseFloat(a.volumeAmount) - parseFloat(b.volumeAmount));
    let prevValue = 0;

    return (
      <table className='fees-table'>
        <thead>
          <tr>
            <th>
              <I18n tKey='balances:days_trading' /> ({feeSet.volumeCurrencyCode})
            </th>
            <th>
              <I18n tKey='common:taker' />
            </th>
            <th>
              <I18n tKey='common:maker' />
            </th>
          </tr>
        </thead>
        <tbody>
          {values.map((v, i) => {
            const ret = (
              <tr key={v.id}>
                {i === values.length - 1 ? (
                  <td>
                    {parseInt(prevValue).toLocaleString()} ≤ <I18n tKey='common:vol' />
                  </td>
                ) : (
                  <td>
                    {parseInt(prevValue).toLocaleString()} ≤&nbsp;
                    <I18n tKey='common:vol' /> &lt; {parseInt(v.volumeAmount).toLocaleString()}
                  </td>
                )}
                <td>{v.takerFee}%</td>
                <td>{v.makerFee}%</td>
              </tr>
            );
            prevValue = v.volumeAmount;
            return ret;
          })}
        </tbody>
      </table>
    );
  }

  renderCurAmount(amount, cur) {
    if (parseFloat(amount) === 0) return 0;
    return (
      <span>
        {amount}&nbsp;{cur.currency.code}
      </span>
    );
  }

  renderDepositFees(currencies) {
    return (
      <table className='fees-table narrow'>
        <thead>
          <tr>
            <th>
              <I18n tKey='common:currency' />
            </th>
            <th></th>
            <th>
              <I18n tKey='balances:trx_fee' />
            </th>
            <th>
              <I18n tKey='common:min_amount' />
            </th>
          </tr>
        </thead>
        <tbody>
          {currencies.map(c => {
            if (c.currency.code === 'USD' || c.currency.code === 'EUR') {
              return null;
            }

            return (
              <tr key={c.currency.id}>
                <td>{c.currency.code}</td>
                <td style={{textAlign: 'left'}}>{c.currency.title && c.currency.title}</td>
                <td>
                  {c.actionFees.depositFee != c.currency.depositFee
                    ? c.actionFees.depositFee
                    : this.renderCurAmount(c.currency.depositFee, c)}
                </td>
                <td>
                  {!!c.currency.minimumDepositAmount ? this.renderCurAmount(c.currency.minimumDepositAmount, c) : 0}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderWithdrawFees(currencies, baseCurCode) {
    return (
      <table className='fees-table narrow'>
        <thead>
          <tr>
            <th>
              <I18n tKey='common:currency' />
            </th>
            <th></th>
            <th>
              <I18n tKey='balances:trx_fee' />
            </th>
            <th>
              <I18n tKey='common:min_amount' />
            </th>
          </tr>
        </thead>
        <tbody>
          {currencies.map(c => {
            if (c.currency.code === 'USD' || c.currency.code === 'EUR') {
              return null;
            }

            return (
              <tr key={c.currency.id}>
                <td>{c.currency.code}</td>
                <td style={{textAlign: 'left'}}>{c.currency.title && c.currency.title}</td>
                <td>{this.renderCurAmount(c.currency.withdrawUserFee, c)}</td>
                <td>
                  {c.withdrawAmountConverted ? (
                    this.renderCurAmount(c.minimumWithdrawAmount, c)
                  ) : (
                    <span>
                      {c.minimumWithdrawAmount}&nbsp;{baseCurCode}
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const {DefaultFeesReducer} = this.props;
    const isFetched = DefaultFeesReducer.completed;
    const baseCurCode =
      DefaultFeesReducer.data &&
      DefaultFeesReducer.data.basePaymentPlan &&
      DefaultFeesReducer.data.basePaymentPlan.currencyCode;

    return (
      <div className='page-legacy'>
        <div className='container'>
          <div className='page-legacy_content'>
            <div className='page-legacy_content-block'>
              {!isFetched && <Loader open={!isFetched} />}

              {isFetched && (
                <React.Fragment>
                  <div>
                    <h1>
                      <I18n tKey='common:link_limit' />
                    </h1>
                    <p>
                      <I18n tKey='fees:disclaimer' />
                    </p>
                    <br />
                  </div>

                  <h2>
                    <I18n tKey='common:trading' /> <I18n tKey='common:link_limit' />
                  </h2>
                  {this.renderFeeSetTable(DefaultFeesReducer.data.feeSet)}
                  <br />
                  <br />

                  <div>
                    <h2>
                      FIAT <I18n tKey='common:deposit' /> <I18n tKey='common:and' /> <I18n tKey='common:withdraw' />{' '}
                      <I18n tKey='common:link_limit' />
                    </h2>
                    <p>
                      <I18n tKey='common:GUP_cooperation' />{' '}
                      <a target='_blank' href='https://globalunitpay.ch/EN/'>
                        Global Unit Pay
                      </a>
                    </p>
                    <table className='fees-table'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            <I18n tKey='common.fee' />
                          </th>
                          <th>
                            <I18n tKey='common.limit' />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            EUR <I18n tKey='common:deposit' />
                          </td>
                          <td>0%</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>
                            EUR <I18n tKey='common:withdrawal' />
                          </td>
                          <td>2.5%</td>
                          <td>
                            <I18n tKey='common.min' /> 1 EUR
                          </td>
                        </tr>

                        <tr style={{height: 41}}>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>

                        <tr>
                          <td>
                            USD <I18n tKey='common:deposit' />
                          </td>
                          <td>0%</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>
                            USD <I18n tKey='common:withdrawal' />
                          </td>
                          <td>2.5%</td>
                          <td>
                            <I18n tKey='common.min' /> 1 USD
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p style={{marginTop: 30}}>
                      <I18n tKey='common:GUP_cooperation' />{' '}
                      {/*<a target='_blank' href='https://globalunitpay.ch/EN/'>*/}
                      <span style={{color: '#4a90e2'}}> SEPA</span>
                      {/*</a>*/}
                    </p>
                    <table className='fees-table'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            <I18n tKey='common.fee' />
                          </th>
                          <th>
                            <I18n tKey='common.limit' />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td width={335}>
                            EUR <I18n tKey='common:deposit' />
                          </td>
                          <td>0.5%</td>
                          <td>
                            <I18n tKey='common.min' /> 2 EUR
                          </td>
                        </tr>
                        <tr>
                          <td width={335}>
                            EUR <I18n tKey='common:withdrawal' />
                          </td>
                          <td>0.54%</td>
                          <td>
                            <I18n tKey='common.min' /> 7 EUR
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <br />
                  <div>
                    {/*<h2>*/}
                    {/*  <I18n tKey='common:transaction_fees_title' />*/}
                    {/*</h2>*/}
                    <h2>
                      <I18n tKey='common:deposit' /> <I18n tKey='common:link_limit' />
                    </h2>
                    {this.renderDepositFees(DefaultFeesReducer.data.currencies)}
                  </div>
                  <br />
                  <br />

                  <div>
                    <h2>
                      <I18n tKey='common:withdraw' /> <I18n tKey='common:link_limit' />
                    </h2>
                    {this.renderWithdrawFees(DefaultFeesReducer.data.currencies, baseCurCode)}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({LngReducer, SessionReducer, DefaultFeesReducer}) => ({
  LngReducer,
  SessionReducer,
  DefaultFeesReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        getDefaultFees: Actions.DefaultFees.action,
      },
      dispatch
    ),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeesPage));
