// @flow
import Currency from '../utils/currency';
import {CurrencyList, MarketList, OhlcvHistory, UserLastTrades} from '../api/Actions';
import {SUBSCRIBE_TO_MARKET, SUBSCRIBE_TO_MARKET_SET_MARKET} from './WsReducer';
import type {MarketTO} from '../api/types/models';
import * as Actions from '../api/Actions';
import {changeLocalSettings, returnLocalSettings, settingNames} from '../utils/utils';

const initialState: MarketTO = {
  identificatSymbol: null,
};

export const sortMarketList = markets => {
  const popOrder = ['CRU-USDU', 'UNTB-USDU'];
  const popMap = {};
  const result = [];
  for (const popTitle of popOrder) {
    for (let i = 0; i < markets.length; ++i) {
      if (markets[i].mBanner === popTitle) {
        result.push(markets[i]);
        popMap[popTitle] = true;
      }
    }
  }

  for (let i = 0; i < markets.length; ++i) {
    if (!popMap[markets[i].mBanner]) {
      result.push(markets[i]);
    }
  }
  return result;
};

export const SET_ACTIVE_MARKET = 'SET_ACTIVE_MARKET';

function ActiveMarketReducer(state = initialState, action) {
  switch (action.type) {
    case MarketList.events.onSuccess: {
      const list = action.data.respItemCollection;
      if (list && list.length > 0) {
        Currency.updateMarketList(action.data.respItemCollection);
        const savedLocalId = returnLocalSettings(settingNames.activeMarket);
        let currentMarket = list[0];
        if (savedLocalId !== null && savedLocalId !== undefined) {
          const foundMarket = list.find(market => market.identificatSymbol === savedLocalId); //check is there saved id in list
          foundMarket ? (currentMarket = foundMarket) : changeLocalSettings(settingNames.activeMarket, null);
        }
        action.asyncDispatch({type: SUBSCRIBE_TO_MARKET, id: currentMarket.identificatSymbol});
        return {...currentMarket};
      }

      return state;
    }

    case MarketList.events.onError:
      return initialState;

    case SUBSCRIBE_TO_MARKET: {
      if (!!action.id) {
        UserLastTrades.action(null, {ctcInstrIdentifier: action.id})(action.asyncDispatch);
        OhlcvHistory.action(null, {ctcInstrIdentifier: action.id, ohlcvResolutionReq: '1m'})(action.asyncDispatch);
      }
      return state;
    }

    case SET_ACTIVE_MARKET: {
      if (state.identificatSymbol !== action.market.identificatSymbol) {
        changeLocalSettings(settingNames.activeMarket, action.market.identificatSymbol);
        action.asyncDispatch({type: SUBSCRIBE_TO_MARKET, id: action.market.identificatSymbol});
        return {...action.market};
      } else {
        return state;
      }
    }

    case CurrencyList.events.onSuccess: {
      Currency.initCurrencies(action.data.respItemCollection);
      return state;
    }

    default:
      return state;
  }
}

export default ActiveMarketReducer;
