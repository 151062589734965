import React, {Component, Fragment} from 'react';

import CoinStat from './CoinStat';
import CurrencyLabel from '../CurrencyLabel';
import PriceSlider from './PriceSlider';
import PriceLabel from '../../PriceLabel';
import Chart from './Chart';
import PriceChange from './PriceChange';
import ChartSettings from './ChartSettings';
import numeral from 'numeral';

class Statistics extends Component {
  render() {
    const {coin, OHLCPeriod, chartType, onOHLCPeriodChange, onChartTypeChange, OHLC, fiat, coinDetails} = this.props;
    const {
      current_price = {},
      high_24h = {},
      low_24h = {},
      ath = {},
      total_volume = {},
      price_change_percentage_1h_in_currency = {},
      market_cap = {},
      price_change_percentage_24h = 0,
      price_change_percentage_7d = 0,
      max_supply = 0,
      circulating_supply = 0,
    } = coinDetails || {};

    return (
      <Fragment>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <CurrencyLabel currency={coin} />
          <div className='coin-stats__last-price'>
            <PriceLabel fiat={fiat.id} value={current_price[fiat.id]} />{' '}
            <PriceChange isNegative={price_change_percentage_24h < 0}>
              {String(price_change_percentage_24h).replace('-', '')}%
            </PriceChange>
          </div>
        </div>

        <ChartSettings
          OHLCPeriod={OHLCPeriod}
          chartType={chartType}
          onChartTypeChange={onChartTypeChange}
          onOHLCPeriodChange={onOHLCPeriodChange}
        />

        <div className='coin-graph'>
          <Chart OHLC={OHLC} chartType={chartType} />
        </div>

        <PriceSlider low={low_24h[fiat.id]} currentPrice={current_price[fiat.id]} high={high_24h[fiat.id]} />

        <div className='coin-stats'>
          <CoinStat label='ATH'>
            <PriceLabel fiat={fiat.id} value={ath[fiat.id]} />
          </CoinStat>
          <CoinStat label='Price Change(1h)'>
            <PriceChange isNegative={price_change_percentage_1h_in_currency[fiat.id] < 0}>
              <PriceLabel
                format='0,0.0000'
                fiat={fiat.id}
                value={String(price_change_percentage_1h_in_currency[fiat.id]).replace('-', '')}
              />
            </PriceChange>
          </CoinStat>
          <CoinStat label='Price Change(24h)'>
            <PriceChange isNegative={price_change_percentage_24h < 0}>
              {String(price_change_percentage_24h).replace('-', '')}%
            </PriceChange>
          </CoinStat>
          <CoinStat label='Price Change(7d)'>
            <PriceChange isNegative={price_change_percentage_7d < 0}>
              {String(price_change_percentage_7d).replace('-', '')}%
            </PriceChange>
          </CoinStat>
          <CoinStat label='Market Cap'>
            <PriceLabel format='0,0 a' fiat={fiat.id} value={market_cap[fiat.id]} />
          </CoinStat>
          <CoinStat label='Total volume'>
            <PriceLabel format='0,0 a' fiat={fiat.id} value={total_volume[fiat.id]} />
          </CoinStat>
          <CoinStat label='Circulating Supply'>
            {circulating_supply ? numeral(circulating_supply).format('0,0 a') : '-'}{' '}
          </CoinStat>
          <CoinStat label='Max Supply'> {max_supply ? numeral(max_supply).format('0,0 a') : '-'} </CoinStat>
        </div>
      </Fragment>
    );
  }
}

export default Statistics;
