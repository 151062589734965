// @flow

import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import moment from 'moment';

import {ReactComponent as Connect} from '../../../assets/images/maintenance/connect.svg';
import {SvgWrapper} from '../cmp/helpers/SvgWrapper';
import I18n from '../cmp/helpers/I18n';

class CommonMaintenancePage extends React.Component {
  displayName: 'CommonMaintenancePage';

  componentDidMount() {
    // fetcher.get("../../actuator/health").then((resp) => {
    //   if (resp.data.status === "UP") {
    //     this.props.history.push(URLMAP.TERMINAL);
    //   }
    // }, (e) => {
    //   console.log(e);
    // });
  }

  btnClick = () => {
    location.reload();
  };

  render() {
    return (
      <section className='maintenance'>
        <SvgWrapper SvgCmp={Connect} />
        <h1 className='title'>
          <I18n tKey='common.sorry' />
        </h1>
        <div className='text'>
          <I18n tKey='common.website_out' />
        </div>
        <div className='box-btn'>
          <a
            className='btn btn-lg btn-primary'
            // onClick={() => this.props.history.push(URLMAP.TERMINAL)}
            onClick={this.btnClick}
          >
            <span className='upper'>
              <I18n tKey='common.back_to_main' />
            </span>
          </a>
        </div>
        <div className='maintenance_footer'>
          &#169; {moment().format('YYYY')} <I18n tKey='common.footer' />
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({}) => ({});

const connectedWithRouter = withRouter(connect(mapStateToProps, {})(CommonMaintenancePage));

export default connectedWithRouter;
