import React, {Component, Fragment} from 'react';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';

import {ReactComponent as SearchIc} from '../../../../assets/images/icon/search-n.svg';
import {ReactComponent as TriangleIc} from '../../../../assets/images/icon/dropdown-small-n.svg';
import {ReactComponent as ChildMark} from '../../../../assets/images/icon/childmark-small-n.svg';
import {ReactComponent as NoimageIc} from '../../../../assets/images/limit/crypto/noimage.svg';

import {ReactComponent as AtlIc} from '../../../../assets/images/limit/crypto/atl.svg';
import {ReactComponent as AtmIc} from '../../../../assets/images/limit/crypto/atm.svg';
import {ReactComponent as BtcIc} from '../../../../assets/images/limit/crypto/btc.svg';
import {ReactComponent as EthIc} from '../../../../assets/images/limit/crypto/eth.svg';
import {ReactComponent as McapIc} from '../../../../assets/images/limit/crypto/mcap.svg';
import {ReactComponent as SpdIc} from '../../../../assets/images/limit/crypto/spd.svg';
import I18n from '../../cmp/helpers/I18n';
import {translate} from '../../utils/i18n';
import {CurrencyView} from '../../cmp/helpers/CurrencyView';
import {CurrencyIcon} from '../../cmp/helpers/CurrencyIcon';

const curIcons = {
  atl: AtlIc,
  atm: AtmIc,
  // btc: BtcIc, todo svg ic
  eth: EthIc,
  noimage: NoimageIc,
  mcap: McapIc,
  spd: SpdIc,
};

export class TradingFees extends Component {
  state = {
    currencyFilter: '',
    openedRows: [],
    pairFilter: '',
  };
  renderCurrencyIcon = name => {
    const ic = curIcons[name.toLowerCase()] || NoimageIc;
    return <SvgWrapper className='icon ic-size-18' SvgCmp={ic} />;
  };

  onFilterChange = ev => {
    this.setState({pairFilter: ev.target.value.toUpperCase()});
  };

  compareCurrencyTitle = title => {
    const {currencyFilter} = this.state;
    if (!currencyFilter) {
      return true;
    } else {
      const filter = currencyFilter.toLowerCase();
      let curTitle = title.toLowerCase();
      return curTitle.includes(filter);
    }
  };

  openTradingInfo = value => {
    const rows = [...this.state.openedRows];
    const foundIndex = this.state.openedRows.findIndex(name => name === value);
    if (foundIndex >= 0) {
      rows.splice(foundIndex, 1);
    } else {
      rows.push(value);
    }
    this.setState({openedRows: rows});
  };

  render() {
    const {fees, tradingParams, account, markets} = this.props;
    if (tradingParams.fetching) {
      return (
        <div className='text-center'>
          <I18n tKey='common.loading' />
        </div>
      );
    }
    const currencyObj = {};
    (markets || []).forEach(elem => {
      if (currencyObj[elem.leftMonetaryUnitCode]) {
        currencyObj[elem.leftMonetaryUnitCode].push(elem);
      } else {
        currencyObj[elem.leftMonetaryUnitCode] = [elem];
      }
    });

    return (
      <div className='block-account trading-fees'>
        <table className='table-limit'>
          <tbody>
            <tr>
              <th className='cell-cur'>
                <I18n tKey='common.pair' />
                <div className='table-limit-search'>
                  <div className='box-control'>
                    <input
                      onChange={this.onFilterChange}
                      value={this.state.pairFilter}
                      type='text'
                      placeholder=''
                      className='form-control '
                    />
                    <SvgWrapper themeWatch className='icon icon-search ic-size-02' SvgCmp={SearchIc} />
                  </div>
                </div>
              </th>
              <th>
                <I18n tKey='common.taker' />
              </th>
              <th>
                <I18n tKey='common.maker' />
              </th>
              <th>
                <I18n tKey='common.min_amount' />
              </th>
              <th>
                <I18n tKey='common.max_amount' />
              </th>
              {/*<th>Min price</th>*/}
              {/*<th>Max price</th>*/}
            </tr>
            {markets.length > 0 ? (
              <Fragment>
                {Object.keys(currencyObj)
                  .filter((k, v) => k.includes(this.state.pairFilter))
                  .map((key, i) => (
                    <Fragment key={i}>
                      <tr className='next-info-header' onClick={this.openTradingInfo.bind(this, key)}>
                        <td className='cell-cur'>
                          <SvgWrapper themeWatch className='ic-size-02 icon-cons' SvgCmp={TriangleIc} />
                          <CurrencyIcon curName={key} showCurName={false} />
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>

                      {currencyObj[key].map(
                        (elem, i) =>
                          this.state.openedRows.some(name => name === key) && (
                            <tr key={i} className='next-info'>
                              <td className='cell-cur'>
                                <SvgWrapper themeWatch className='ic-size-02 icon-cons' SvgCmp={ChildMark} />
                                {elem.mBanner.replace('-', '/')}
                              </td>
                              {/* <td>{(elem.orderFeeResp || {}).tFeeVal ? `${elem.orderFeeResp.tFeeVal}%` : <I18n tKey="common.empty_fee"/>}</td>
                    <td>{(elem.orderFeeResp || {}).mFeeVal ? `${elem.orderFeeResp.mFeeVal}%` : <I18n tKey="common.empty_fee"/>}</td> */}
                              <td>{`${account.paySetQty.tFeeVal}%`}</td>
                              <td>{`${account.paySetQty.mFeeVal}%`}</td>
                              <td>{<CurrencyView isFiat={true} amount={elem.leastQty || 0} />}</td>
                              <td>{<CurrencyView isFiat={true} amount={elem.topQty || 0} />}</td>
                            </tr>
                          )
                      )}
                    </Fragment>
                  ))}
              </Fragment>
            ) : (
              <tr className='tr'>
                <td colSpan={5} className='text-center'>
                  <I18n tKey='common:no_data' />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
