import React, {Component, Fragment} from 'react';
import {parseError} from '../../utils/i18n';
import {fetcher, getUserToken} from '../../api/Api';
import I18n from '../../cmp/helpers/I18n';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from '../../cmp/Loader';
import {CurrencyInput} from '../../../js/cmp/helpers/CurrencyInput';

export class AdvCashForm extends Component {
  state = {
    amount: '',
    ac_account_email: '',
    ac_sci_name: '',
    ac_currency: '',
    ac_order_id: '',
    ac_sign: '',
    isLoading: false,
  };
  form = React.createRef();

  onClick = async ev => {
    ev.preventDefault();
    this.setState({error: false});
    if (this.state.amount) {
      const {c, w} = this.props;
      const data = {amount: this.state.amount, currencyCode: c.mUCryptogram, walletId: w.identificatSymbol};
      try {
        this.setState({isLoading: true});
        const resp = await fetcher.request({
          url: '/quantum/pgc/deposit/quantum',
          method: 'POST',
          headers: {
            'x-token': getUserToken(),
          },
          data,
        });
        window.location = resp.data.url;
        //this.setState({...resp.data}, () => this.form.current.submit())
      } catch (error) {
        this.setState({error: true, isLoading: false});
        console.log('Payment Error!');
      }
    }
  };

  changeState = (name, value) => {
    this.setState({amount: value});
  };

  render() {
    const {amount, error, ac_account_email, ac_sci_name, ac_currency, ac_order_id, ac_sign, isLoading} = this.state;
    const {FeesSettingsReducer, isFeelessTransactions} = this.props;
    let fees = null;

    if (FeesSettingsReducer && FeesSettingsReducer.completed) {
      fees = FeesSettingsReducer.data.actionsFees.find(
        c => c.currencyCode.toLowerCase() == this.props.c.mUCryptogram.toLowerCase()
      );
    }

    return (
      <div style={{width: '100%'}}>
        <Loader open={isLoading && fees != null} />
        <form className='advcash-from' ref={this.form} method='post' action='https://wallet.advcash.com/sci/'>
          <input readOnly={true} type='hidden' name='ac_account_email' value={ac_account_email} />
          <input readOnly={true} type='hidden' name='ac_sci_name' value={ac_sci_name} />
          <div className='box-control'>
            <div className='gti-input-section'>
              <label className='label'>
                <I18n tKey='balances.amount_to_receive' />
              </label>
              <CurrencyInput
                onChange={this.changeState}
                value={amount}
                name='ac_amount'
                currencyType={this.props.c.mUCryptogram}
              />
            </div>
          </div>
          <input readOnly={true} type='hidden' name='ac_currency' value={ac_currency} />
          <input readOnly={true} type='hidden' name='ac_order_id' value={ac_order_id} />
          <input readOnly={true} type='hidden' name='ac_sign' value={ac_sign} />
          {/*Optional Fields*/}
          {/*todo ok page url*/}
          {/*<input readOnly={true} type="hidden" name="ac_success_url" value="https://exchange.uat.unitex.one/ok.html"/>*/}
          {/*<input readOnly={true} type="hidden" name="ac_success_url_method" value="GET"/>*/}
          {/*todo error page url*/}
          {/*<input readOnly={true} type="hidden" name="ac_fail_url" value="https://exchange.uat.unitex.one/err.html"/>*/}
          {/*<input readOnly={true} type="hidden" name="ac_fail_url_method" value="GET"/>*/}
          {/*<input readOnly={true} type="hidden" name="ac_status_url" value="https://exchange.uat.unitex.one/api/v1/pgc/pmnt/post-card-back/advcash"/>*/}
          {/*<input readOnly={true} type="hidden" name="ac_status_url_method" value="POST"/>*/}
          {/*<input type="text" name="ac_comments" value="Comment"/>*/}
          <button
            disabled={!amount || isLoading}
            className='btn btn-primary btn-md btn-full adv-button'
            onClick={this.onClick}
          >
            <I18n tKey='common.add_deposit' />
          </button>
        </form>
        {/* <p style={{paddingTop: "15px"}}>* <I18n tKey="common.quantum_warn"/></p> */}
        {!isFeelessTransactions && fees && fees.depositFee && (
          <p style={{paddingTop: '15px'}}>
            * <I18n tKey='balances.additional_fee' />: {fees.depositFee}
          </p>
        )}
        {error && (
          <p style={{paddingTop: '15px', color: 'red'}}>
            <I18n tKey='common.deposir_er' />
          </p>
        )}
      </div>
    );
  }
}
