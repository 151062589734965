import React, {Component, Fragment} from 'react';
import ExchangeInput from '../ExchangeInput';
import {OPERATION_TYPE} from '../constants';

class PairInputs extends Component {
  state = {
    isError: false,
  };

  handleBlur = () => {
    const {leftCurrency, rightCurrency, leftCurrencyAmount, rightCurrencyAmount} = this.props;
    if (!leftCurrencyAmount || !rightCurrencyAmount) {
      return;
    }
    if (leftCurrency && (leftCurrencyAmount < leftCurrency.min || leftCurrencyAmount > leftCurrency.max)) {
      return this.setState({isError: true});
    }
    if (rightCurrency && (rightCurrencyAmount < rightCurrency.min || rightCurrencyAmount > rightCurrency.max)) {
      return this.setState({isError: true});
    }
  };

  changeIsError = value => {
    this.setState({isError: value});
  };

  render() {
    const {
      onRightCodeChange,
      onLeftCodeChange,
      leftCurrencyAmount,
      rightCurrencyAmount,
      leftCurrency,
      rightCurrency,
      rightSelectOptions,
      leftSelectOptions,
      onLeftAmountChange,
      onRightAmountChange,
      operationType,
      isReadOnly,
    } = this.props;

    return (
      <Fragment>
        <ExchangeInput
          label={operationType === OPERATION_TYPE.BUY ? 'I want to Spend' : 'I want to Sell'}
          onSelectChange={onLeftCodeChange}
          selectOptions={leftSelectOptions}
          onChange={onLeftAmountChange}
          onBlur={this.handleBlur}
          changeIsError={this.changeIsError}
          value={leftCurrencyAmount}
          selectedOption={leftCurrency}
          isError={this.state.isError}
          isReadOnly={isReadOnly}
        />
        <ExchangeInput
          label='I will Receive'
          onSelectChange={onRightCodeChange}
          selectOptions={rightSelectOptions}
          onChange={onRightAmountChange}
          onBlur={this.handleBlur}
          changeIsError={this.changeIsError}
          value={rightCurrencyAmount}
          selectedOption={rightCurrency}
          isError={this.state.isError}
          isReadOnly={isReadOnly}
        />
      </Fragment>
    );
  }
}

export default PairInputs;
