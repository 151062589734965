import {Redirect} from 'react-router';
import React from 'react';

import CommonMaintenancePage from '../page/CommonMaintenancePage';
import FeesPage from '../page/FeesPage';
import ExchangePage from '../page/Exchange';
import NewExchangePage from '../page/Exchange';
import {FrozenBySanctions} from '../page/UserSanctionsForm';
import StaticPage from '../page/StaticPage';
import CheckToken from '../page/CheckToken';
import ConfirmWithdrawEmail from '../page/ConfirmWithdrawEmail';
import AccountFreezeConfirmation from '../page/profile/AccountFreezeConfirmation';
import BalancePage from '../page/BalancePage';
import ProfilePage from '../page/ProfilePage';
import DashboardPage from '../page/DashboardPage';
import DocumentsHistoryPage from '../page/DocumentsHistoryPage';
import SetNewPassword from '../page/SetNewPassword';
import Login from '../page/Login';
import SignUp from '../page/SignUp';
import AssetInfo from '../page/AssetInfo';

import ConfirmUserSettingsAction from '../cmp/popups/apiKey/ConfirmApiKey';
import NotFound from '../layout/NotFound';

import {URLMAP} from '../utils/const';

export const ROUTES = {
  visibleForAll: [
    {path: URLMAP.TERMINAL, exact: true, render: () => <div />},
    {path: URLMAP.MARKET, render: () => <div />},
    {path: URLMAP.MAINTENANCE, exact: true, component: CommonMaintenancePage},
    {path: URLMAP.EXCHANGE, exact: true, component: NewExchangePage},
    {path: URLMAP.NEW_EXCHANGE, exact: true, component: NewExchangePage},
    // {path: URLMAP.ASSET_INFO, exact: true, component: AssetInfo},
    {path: '*', component: NotFound},
  ],
  guest: [
    {path: [URLMAP.CONFIRM_EMAIL], exact: true, render: () => <div />},
    {path: URLMAP.LOGIN, exact: true, component: Login},
    {path: URLMAP.SIGNUP, exact: true, component: SignUp},
    {
      path: [
        //URLMAP.STATIC_PAGE,
        URLMAP.WITHDRAW_SUCCESS,
        URLMAP.CONFIRM_SETTING_ACTION,
        URLMAP.CONFIRM_WITHDRAW_EMAIL,
        URLMAP.FREEZE_ACCOUNT,
        `${URLMAP.BALANCES}/:tab?`,
        `${URLMAP.PROFILE}/:tab?`,
        URLMAP.WITHDRAW_ERROR,
        URLMAP.DASHBOARD,
        URLMAP.FREEZE_ACCOUNT,
      ],
      exact: true,
      component: () => <Redirect to={URLMAP.TERMINAL} />,
    },
    {path: URLMAP.SANCTIONS_BLOCK, exact: true, component: FrozenBySanctions},
    {path: URLMAP.STATIC_PAGE, exact: true, component: p => <StaticPage key={p.match.params.name} />}, // refactor pages view to remote api
    {path: URLMAP.SET_NEW_PASSWORD2, exact: true, component: SetNewPassword},
  ],
  user: [
    {
      path: [URLMAP.CONFIRM_EMAIL, URLMAP.SIGNUP, URLMAP.LOGIN, URLMAP.SANCTIONS_BLOCK],
      exact: true,
      component: () => <Redirect to={URLMAP.TERMINAL} />,
    },
    {path: URLMAP.STATIC_PAGE, exact: true, component: p => <StaticPage key={p.match.params.name} />}, // refactor pages view to remote api
    {path: URLMAP.WITHDRAW_SUCCESS, exact: true, component: () => <CheckToken asSuccess={true} />},
    {path: URLMAP.CONFIRM_SETTING_ACTION, exact: true, component: ConfirmUserSettingsAction},
    {path: URLMAP.CONFIRM_WITHDRAW_EMAIL, exact: true, component: ConfirmWithdrawEmail},
    {path: URLMAP.FREEZE_ACCOUNT, exact: true, component: AccountFreezeConfirmation},
    {path: URLMAP.SET_NEW_PASSWORD2, exact: true, component: SetNewPassword},
    {path: `${URLMAP.BALANCES}/:tab?`, exact: true, component: BalancePage},
    {path: `${URLMAP.PROFILE}/:tab?`, exact: true, component: ProfilePage},
    {path: URLMAP.WITHDRAW_ERROR, exact: true, component: CheckToken},
    {path: URLMAP.DASHBOARD, exact: true, component: DashboardPage},
    {path: URLMAP.DOCUMENTS_HISTORY, exact: true, component: DocumentsHistoryPage},
    // {path: URLMAP.MARKETS, exact: true, component: MarketsPage}, // remove in future
  ],
  frozenUser: [
    {
      path: [
        URLMAP.FREEZE_ACCOUNT,
        URLMAP.STATIC_PAGE,
        URLMAP.WITHDRAW_SUCCESS,
        URLMAP.CONFIRM_SETTING_ACTION,
        URLMAP.CONFIRM_WITHDRAW_EMAIL,
        URLMAP.SET_NEW_PASSWORD2,
        `${URLMAP.BALANCES}/*`,
        `${URLMAP.PROFILE}/*`,
        URLMAP.WITHDRAW_ERROR,
        URLMAP.DASHBOARD,
        URLMAP.SANCTIONS_BLOCK,
        URLMAP.LOGIN,
        URLMAP.SIGNUP,
      ],
      exact: true,
      component: AccountFreezeConfirmation,
    },
  ],
};

export const FROZEN_ROUTES = ROUTES.frozenUser.concat(ROUTES.visibleForAll);
export const USER_ROUTES = ROUTES.user.concat(ROUTES.visibleForAll);
export const GUEST_ROUTES = ROUTES.guest.concat(ROUTES.visibleForAll);
