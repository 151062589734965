import React from 'react';

import UserTradesHistory from './UserTradesHistory';
import OpenOrders from './OpenOrders';
import I18n from '../../../../cmp/helpers/I18n';
import classNames from 'classnames';
import {connect} from 'react-redux';
import StopOrders from './StopOrders';
import Pagination from 'rc-pagination';
import {bindActionCreators} from 'redux';
import {CloseOrder, OrderList} from '../../../../api/Actions';

const TABS = {
  history: 'HISTORY',
  orders: 'ORDERS',
  stopOrders: 'STOP_ORDERS',
};

class History extends React.PureComponent {
  state = {
    openedTab: TABS.orders,
  };

  setIsTradingHistoryOpen = value => {
    this.setState({openedTab: value});
  };

  render() {
    const {OrderStopListReducer, OrderListReducer} = this.props;
    // STOP_WAITING, STOP_DONE, STOP_CANCELED, STOP_ERRORED,
    const awaitingOrdersStopCount = (
      OrderStopListReducer.data ? OrderStopListReducer.data.publicElements || [] : []
    ).filter(({status}) => status === 'STOP_WAITING').length;

    return (
      <div className='terminal-history'>
        <div className='terminal-history__header'>
          <div
            className={classNames('terminal-history__header-item', {['active']: this.state.openedTab === TABS.orders})}
            onClick={() => this.setIsTradingHistoryOpen(TABS.orders)}
          >
            <I18n tKey='orders.open' />
            {OrderListReducer.totalElements > 0 && (
              <div className='count-wrapper'>
                <span className='count'>{OrderListReducer.totalElements}</span>
              </div>
            )}
          </div>

          <div
            className={classNames('terminal-history__header-item', {
              ['active']: this.state.openedTab === TABS.stopOrders,
            })}
            onClick={() => this.setIsTradingHistoryOpen(TABS.stopOrders)}
          >
            <I18n tKey='common.order_stop' />
            {awaitingOrdersStopCount > 0 && (
              <div className='count-wrapper'>
                <span className='count'>{awaitingOrdersStopCount}</span>
              </div>
            )}
          </div>

          <div
            className={classNames('terminal-history__header-item', {['active']: this.state.openedTab === TABS.history})}
            onClick={() => this.setIsTradingHistoryOpen(TABS.history)}
          >
            <I18n tKey='layout.trade_his' />
          </div>
        </div>

        {this.state.openedTab === TABS.orders && (
          <OpenOrders updatePagination={this.props.updatePagination} page={this.props.page} limit={this.props.limit} />
        )}
        {this.state.openedTab === TABS.stopOrders && <StopOrders />}
        {this.state.openedTab === TABS.history && <UserTradesHistory />}
      </div>
    );
  }
}

const mapStateToProps = ({OrderListReducer, OrderStopListReducer}) => ({
  OrderListReducer,
  OrderStopListReducer,
});

export default connect(mapStateToProps, undefined)(History);
