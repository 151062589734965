import React, {Component} from 'react';

class CoinStat extends Component {
  render() {
    const {label, children} = this.props;
    return (
      <div className='coin-stat'>
        <span className='coin-stat__label'>{label}</span>
        <div className={'coin-stat__value'}>{children}</div>
      </div>
    );
  }
}

export default CoinStat;
