import React, {Component} from 'react';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';

import {translate} from '../../../../utils/i18n';
import I18n from '../../../../cmp/helpers/I18n';
import connect from './connect';

class FiatForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: props.userName,
      email: props.email,
      trxId: '',
      paymentSystem: '',
      description: '',
      isLoading: false,
    };
  }

  componentWillReceiveProps(props) {
    if (props.userName !== this.props.userName || props.email !== this.props.email) {
      this.setState({userName: props.userName, email: props.email});
    }
  }

  getIsFormReady = () => {
    const {trxId, paymentSystem, description, userName, email} = this.state;

    let isReady = true;

    if (!description.length || !userName.length || !email.length || !trxId.length || !paymentSystem.length) {
      isReady = false;
    }

    return !isReady;
  };

  handleChange = e => {
    const {value, name} = e.target;

    this.setState({[name]: value});
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.props.cleanCreatedTicket();
    this.setState({isLoading: true});

    const {executeRecaptcha} = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.debug('NO CAPTCHA');
      return;
    }

    try {
      const token = await executeRecaptcha('attachment');

      const {status} = await this.props.createTicket({
        body: {
          serviceDeskId: this.props.serviceDeskId,
          requestTypeId: this.props.activeSubSection.value,
          requestFieldValues: {
            customfield_10060: this.state.userName,
            customfield_10062: this.state.email,
            customfield_10064: this.state.trxId,
            customfield_10065: this.state.paymentSystem,
            description: this.state.description,
            attachment: [],
          },
        },
        gToken: token,
      });

      if (status === 200) {
        this.props.openTicketCreateModal(this.onCloseModal);
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({isLoading: false});
  };

  onCloseModal = () => {
    this.setState({trxId: '', paymentSystem: '', description: ''});
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className='help__form'>
        <div className='form__block'>
          <input
            value={this.state.userName}
            onChange={e => this.handleChange(e)}
            name='userName'
            id='username'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.username')}
            readOnly={this.props.userLoggedIn}
            required
          />
          <label htmlFor='username'>
            <I18n tKey='helpcenter.username' />
          </label>
        </div>

        <div className='form__block'>
          <input
            value={this.state.email}
            onChange={e => this.handleChange(e)}
            name='email'
            id='email'
            type='email'
            className='form__control'
            placeholder={translate('helpcenter.email')}
            readOnly={this.props.userLoggedIn}
            required
          />
          <label htmlFor='email'>
            <I18n tKey='helpcenter.email' />
          </label>
        </div>

        <div className='form__block'>
          <input
            value={this.state.trxId}
            onChange={e => this.handleChange(e)}
            name='trxId'
            id='trxId'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.transaction_id')}
            required
          />
        </div>

        <div className='form__block'>
          <input
            value={this.state.paymentSystem}
            onChange={e => this.handleChange(e)}
            name='paymentSystem'
            id='paymentSystem'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.used_payment_system')}
            required
          />
        </div>

        <div className='form__block'>
          <label htmlFor='message' className='header__label'>
            <I18n tKey='helpcenter.description_short' />*
          </label>
          <textarea
            name='description'
            id='message'
            className='form__control'
            required
            value={this.state.description}
            onChange={e => this.handleChange(e)}
          />
        </div>

        <div className='form__block button__wrapper d-flex  '>
          <button className='btn btn-cancel '>
            <span>
              <I18n tKey='common.cancel' />
            </span>
          </button>

          <button type='submit' className='btn btn-purple ' disabled={this.getIsFormReady() || this.state.isLoading}>
            <span>
              <I18n tKey='common.send' />
            </span>
          </button>
        </div>
      </form>
    );
  }
}

export default withGoogleReCaptcha(connect(FiatForm));
