import React, {Component} from 'react';
import Select from 'react-select';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';

import {translate} from '../../../../utils/i18n';
import I18n from '../../../../cmp/helpers/I18n';
import {CRYPTO_ASSETS, CUSTOM_THEME, CUSTOM_SELECT_STYLES} from './constants';
import connect from './connect';

class CryptoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: props.userName,
      email: props.email,
      currency: CRYPTO_ASSETS[0],
      addressFrom: '',
      addressTo: '',
      blockId: '',
      trxId: '',
      description: '',
      isLoading: false,
    };
  }

  componentWillReceiveProps(props) {
    if (props.userName !== this.props.userName || props.email !== this.props.email) {
      this.setState({userName: props.userName, email: props.email});
    }
  }

  getIsFormReady = () => {
    const {description, userName, email, addressFrom, addressTo, blockId, trxId} = this.state;

    let isReady = true;

    if (this.props.activeSubSection.requestTypeId === '189' && !addressFrom.length) {
      isReady = false;

      return !isReady;
    }

    if (
      !description.length ||
      !userName.length ||
      !email.length ||
      !addressTo.length ||
      !blockId.length ||
      !trxId.length
    ) {
      isReady = false;
    }

    return !isReady;
  };

  handleChange = e => {
    const {value, name} = e.target;

    this.setState({[name]: value});
  };

  handleCurrencyChange = currency => {
    this.setState({currency});
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.props.cleanCreatedTicket();
    this.setState({isLoading: true});

    const {executeRecaptcha} = this.props.googleReCaptchaProps;
    const isDepositRequestId = this.props.activeSubSection.requestTypeId === '189';

    if (!executeRecaptcha) {
      console.debug('NO CAPTCHA');
      return;
    }

    try {
      const token = await executeRecaptcha('attachment');

      const {status} = await this.props.createTicket({
        body: {
          serviceDeskId: this.props.serviceDeskId,
          requestTypeId: this.props.activeSubSection.value,
          requestFieldValues: {
            attachment: [],
            customfield_10060: this.state.userName,
            customfield_10062: this.state.email,
            customfield_10064: this.state.trxId,
            customfield_10072: {id: this.state.currency.value},
            customfield_10069: this.state.addressTo,
            customfield_10071: this.state.blockId,
            description: this.state.description,
            ...(isDepositRequestId && {customfield_10068: this.state.addressFrom}),
          },
        },
        gToken: token,
      });

      if (status === 200) {
        this.props.openTicketCreateModal(this.onCloseModal);
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({isLoading: false});
  };

  onCloseModal = () => {
    this.setState({addressFrom: '', addressTo: '', blockId: '', description: '', trxId: ''});
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className='help__form'>
        <div className='form__block'>
          <input
            value={this.state.userName}
            onChange={e => this.handleChange(e)}
            name='userName'
            id='username'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.username')}
            readOnly={this.props.userLoggedIn}
            required
          />
          <label htmlFor='username'>
            <I18n tKey='helpcenter.username' />
          </label>
        </div>

        <div className='form__block'>
          <input
            value={this.state.email}
            onChange={e => this.handleChange(e)}
            name='email'
            id='email'
            type='email'
            className='form__control'
            placeholder={translate('helpcenter.email')}
            readOnly={this.props.userLoggedIn}
            required
          />
          <label htmlFor='email'>
            <I18n tKey='helpcenter.email' />
          </label>
        </div>

        <div className='form__block'>
          <label className={'header__label'} htmlFor='crypto'>
            <I18n tkey='helpcenter.crypto_label' />
          </label>
          <Select
            value={this.state.currency}
            styles={CUSTOM_SELECT_STYLES}
            isSearchable={false}
            theme={CUSTOM_THEME}
            name={'currency'}
            onChange={e => this.handleCurrencyChange(e)}
            options={CRYPTO_ASSETS}
            placeholder={translate('helpcenter.select_crypto')}
          />
          <label htmlFor='crypto'>
            <I18n tKey='helpcenter.select' />
          </label>
        </div>

        {this.props.activeSubSection.requestTypeId === '189' && (
          <div className='form__block'>
            <input
              value={this.state.addressFrom}
              onChange={e => this.handleChange(e)}
              name='addressFrom'
              id='addressFrom'
              type='text'
              className='form__control'
              placeholder={translate('helpcenter.wallet_address_send_from')}
              required
            />
          </div>
        )}

        <div className='form__block'>
          <input
            value={this.state.addressTo}
            onChange={e => this.handleChange(e)}
            name='addressTo'
            id='addressTo'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.wallet_adress_send_to')}
            required
          />
        </div>

        <div className='form__block'>
          <input
            value={this.state.blockId}
            onChange={e => this.handleChange(e)}
            name='blockId'
            id='blockId'
            type='text'
            className='form__control'
            placeholder={translate('helpcenter.block_id')}
            required
          />
        </div>

        <div className='form__block'>
          <input
            value={this.state.trxId}
            onChange={e => this.handleChange(e)}
            name='trxId'
            id='trxId'
            type='text'
            className='form__control'
            placeholder={translate('common.trx_id')}
            required
          />
        </div>

        <div className='form__block'>
          <label htmlFor='message' className='header__label'>
            <I18n tKey='helpcenter.description_short' />*
          </label>
          <textarea
            name='description'
            id='message'
            className='form__control'
            required
            value={this.state.description}
            onChange={e => this.handleChange(e)}
          />
        </div>

        <div className='form__block button__wrapper d-flex  '>
          <button className='btn btn-cancel '>
            <span>
              <I18n tKey='common.cancel' />
            </span>
          </button>

          <button type='submit' className='btn btn-purple ' disabled={this.getIsFormReady() || this.state.isLoading}>
            <span>
              <I18n tKey='common.send' />
            </span>
          </button>
        </div>
      </form>
    );
  }
}

export default withGoogleReCaptcha(connect(CryptoForm));
