import React, {Component} from 'react';
import {connect} from 'react-redux';

class ProblemsActivating2FaAnswer extends Component {
  renderRuVersion() {
    return (
      <React.Fragment>
        <div className='faq-complex-answer'>
          <div>
            <ol>
              <li>Убедитесь, что Вы используете корректный код для Вашего емейла привязанного к аккаунту UnitEx.</li>
              <li>
                В случае, если после установки 2ФА у Вас не срабатывает шестизначный код в аккаунте UnitEx, пожалуйста
                дождитесь следующего кода, который появится через 30 секунд. Если же и в этот раз код не сработает,
                пожалуйста проверьте следующую информацию.
              </li>
            </ol>
          </div>

          <div>
            <em>Важно</em>: Вы не сможете войти через Google Authenticator, если приложение Google Authenticator не
            синхронизировано.
            <br />
            Как убедиться, что время в приложении Google Authenticator синхронизировано корректно.
          </div>

          <div>
            Для <em>Android</em>:
            <ol>
              <li>Зайдите в главное меню приложения Google Authenticator.</li>
              <li>
                Перейдите в <em>Настройки</em>.
              </li>
              <li>
                Выберите <em>Коррекция времени для кодов</em>.
              </li>
              <li>
                Нажмите <em>Синхронизировать</em>.
              </li>
            </ol>
            В открывшемся окне приложение подтвердит, что время было синхронизировано, и вы сможете использовать коды
            для входа в свой аккаунт. Синхронизация влияет только на внутреннее время приложения Google Authenticator и
            не отражается на настройках даты и времени самого устройства.
          </div>

          <div>
            Для <em>iOS</em>:
            <ol>
              <li>
                Откройте <em>Настройки устройства</em> на главном экране вашего устройства iPhone.
              </li>
              <li>
                Выберите <em>Основные</em>.
              </li>
              <li>
                Зайдите в <em>Дата и время</em>.
              </li>
              <li>
                Отключите опцию <em>Автоматически</em> и включите ее вновь.
              </li>
              <li>Перезагрузите устройство.</li>
            </ol>
          </div>

          <div>
            Если данные шаги не помогли в решении Вашей проблемы, просим Вас обратиться в нашу Службу поддержки и
            предоставить скриншоты с подробным описанием Вашей проблемы.
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEnVersion() {
    return (
      <React.Fragment>
        <div className='faq-complex-answer'>
          <div>
            <ol>
              <li>
                Make sure that you are using correct code for your email address which is attached to UnitEx account.
              </li>
              <li>
                In case the 6 digit code, received after installing 2FA, does not work in your UnitEx account, please
                wait for the next code, which will become available in 30 seconds. If even then code will not work,
                please wait for another one. In case it will fail again, please create ticket in{' '}
                <a href={'/page/help-center/accounts'} target='_blank'>
                  Help Center
                </a>
                .
              </li>
            </ol>
          </div>

          <div>
            <em>Important</em>: You will not be able to get access using Google Authenticator if the application is not
            synchronized.
            <br />
            How to make sure that Google Authenticator is synchronized correctly.
          </div>

          <div>
            For <em>Android</em>:
            <ol>
              <li>Go to main menu of Google Authenticator application.</li>
              <li>
                Go to <em>Settings</em>.
              </li>
              <li>
                Choose <em>Correction of times for codes</em>.
              </li>
              <li>
                Press <em>Synchronize</em>.
              </li>
            </ol>
            In new window application will confirm that time was synchronized and you will be able to use the codes for
            accessing your account. Synchronization only affects Google Authenticator app and does not influence time
            and date settings of your device.
          </div>

          <div>
            For <em>IOS</em>:
            <ol>
              <li>
                Open device <em>Settings</em> on your iPhone.
              </li>
              <li>
                Choose <em>General</em>.
              </li>
              <li>
                Access <em>Date and Time</em>.
              </li>
              <li>
                Disable <em>Automatic</em> function and enable it again.
              </li>
              <li>Restart the device.</li>
            </ol>
          </div>

          <div>
            If these steps did not resolve your issues, we ask you to contact our Customer Support in{' '}
            <a href={'/page/help-center/accounts'} target='_blank'>
              Help Center
            </a>
            . Please provide us with screenshots and an explanation of your issue.
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {lng} = this.props.LngReducer;
    if (lng === 'en') return this.renderEnVersion();
    else if (lng === 'ru') return this.renderRuVersion();
    return this.renderEnVersion();
  }
}

const mapStateToProps = ({LngReducer}) => {
  return {LngReducer};
};

export default connect(mapStateToProps)(ProblemsActivating2FaAnswer);
