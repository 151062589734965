import React from 'react';
import {translate} from '../../utils/i18n';
export const CheckBox = ({checked, onChange, label}) => (
  <div className='box-control'>
    <label className='form-checkbox'>
      <input onChange={onChange} type='checkbox' checked={checked} />
      <span className='checkmark' />
      <span>{translate(label)}</span>
    </label>
  </div>
);
