import React, {Fragment} from 'react';
import I18n from '../helpers/I18n';

class SystemTab extends React.Component {
  displayName: 'SystemTab';

  render() {
    return (
      <Fragment>
        <div className='notification-center_title'>
          <I18n tKey='common.system' />
        </div>
      </Fragment>
    );
  }
}

export default SystemTab;
