import React from 'react';
import I18n from '../../../../cmp/helpers/I18n';
import classNames from 'classnames';

class CellHeader extends React.Component {
  render() {
    return (
      <div onClick={this.props.onClick} className={classNames('cell-header', this.props.className)}>
        {this.props.label ? <I18n tKey={this.props.label} /> : this.props.children}
      </div>
    );
  }
}

export default CellHeader;
