import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import ModalNotify from '../modal/ModalNotify';
import ModalAlert from '../modal/ModalAlert';
import NotificationTab from './NotificationTab';
import TradingNotificationTab from './TradingNotificationTab';
import PriceAlertTab from './PriceAlertTab';
import FinanceTab from './FinanceTab';
import SystemTab from './SystemTab';
import NewsTab from './NewsTab';
import {ReactComponent as Gear} from '../../../../assets/images/icon/clear-view.svg';
import {ReactComponent as BackIc} from '../../../../assets/images/icon/back-n.svg';
import {ReactComponent as NavIc} from '../../../../assets/images/notification/nav/all.svg';
import {SvgWrapper} from '../helpers/SvgWrapper';
import React from 'react';
import I18n from '../helpers/I18n';

class NotificationCenter extends React.Component {
  displayName: 'NotificationCenter';

  constructor(props) {
    super(props);
    this.state = {
      isOpenModalNotify: false,
      isOpenModalAlert: false,
    };
  }

  render() {
    const {isOpen, notificationContentRef} = this.props;
    return (
      <section className={isOpen ? 'notification-center open' : 'notification-center'}>
        <div className='notification-center_header'>
          <span style={{cursor: 'pointer'}} className='back' onClick={this.props.onClose}>
            <SvgWrapper themeWatch className='icon icon-back ic-size-06' SvgCmp={BackIc} />
            <I18n tKey='notification.title' />
          </span>
          <ModalNotify isShow={this.state.isOpenModalNotify} />
          <ModalAlert isShow={this.state.isOpenModalAlert} />
        </div>
        <Tabs className='notification-center_tabs'>
          <div className='full-height' ref={notificationContentRef}>
            <div className='tab-content'>
              <NotificationTab onClose={this.props.onClose} />
            </div>
          </div>
        </Tabs>
      </section>
    );
  }
}

export default NotificationCenter;
