import {Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from '../../api/Actions';
import ApiKey from '../../cmp/apiAccess/ApiKey';
import {CREATE_API_KEY} from '../../reducers/ApiAccessKeyPopupReducer';
import {withRouter} from 'react-router-dom';
import {CREATE_KEY, closeCreateApiKeyPopup} from '../../cmp/popups/apiKey/CreateApiKeyPopup';
import {closeDeleteApiKeyPopup} from '../../cmp/popups/apiKey/DeleteApiKey';
import {EnableAccountApiKey} from '../../api/Actions';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import {ReactComponent as PlusIc} from '../../../../assets/images/icon/plus.svg';
import React from 'react';
import I18n from '../../cmp/helpers/I18n';

class ApiAccess extends React.PureComponent {
  state = {
    apiKeys: [],
    enable: true,
    disable: true,
  };

  componentDidMount() {
    this.props.keysList();
    this.props.apiPermissions();
  }

  componentWillReceiveProps(n) {
    if (Array.isArray(n.GetAccountApiKeysReducer.data)) {
      //
      this.setState({apiKeys: [...n.GetAccountApiKeysReducer.data]});
    }
    if (
      n.CreateAccountApiKeyReducer.data.curSidTs &&
      n.CreateAccountApiKeyReducer.data.curSidTs !== this.props.CreateAccountApiKeyReducer.data.curSidTs &&
      !n.CreateAccountApiKeyReducer.error
    ) {
      this.setState({apiKeys: [...this.state.apiKeys, {...n.CreateAccountApiKeyReducer.data}]});
      closeCreateApiKeyPopup();
    }

    if (
      n.RevokeApiKeyReducer.data.curSidTs &&
      n.RevokeApiKeyReducer.data.curSidTs !== this.props.RevokeApiKeyReducer.data.curSidTs &&
      !n.RevokeApiKeyReducer.error
    ) {
      const newArr = this.state.apiKeys.map(elem => {
        return elem.curSidTs !== n.RevokeApiKeyReducer.data.curSidTs ? elem : n.RevokeApiKeyReducer.data;
      });
      this.setState({apiKeys: newArr});
      closeDeleteApiKeyPopup();
    }

    if (n.UpdateAccountApiKeyReducer.data.curSidTs && !n.UpdateAccountApiKeyReducer.error) {
      const newArr = this.state.apiKeys.map(elem => {
        return elem.curSidTs !== n.UpdateAccountApiKeyReducer.data.curSidTs ? elem : n.UpdateAccountApiKeyReducer.data;
      });
      this.setState({apiKeys: newArr});
      closeCreateApiKeyPopup();
    }

    if (n.ApiKeyStateReducer.data.curSidTs && !n.ApiKeyStateReducer.error) {
      const newArr = this.state.apiKeys.map(elem => {
        return elem.curSidTs !== n.ApiKeyStateReducer.data.curSidTs ? elem : n.ApiKeyStateReducer.data;
      });
      this.setState({apiKeys: newArr});
      closeDeleteApiKeyPopup();
    }
  }

  createApiKey = () => {
    const {BaseApiKeyPermissionListReducer, createKey, clearCreateKey, clearUpdateKey, cleargetKey} = this.props;
    cleargetKey();
    clearCreateKey();
    clearUpdateKey();
    createKey(BaseApiKeyPermissionListReducer.data);
  };

  render() {
    const {apiKeys} = this.state;
    const {BaseApiKeyPermissionListReducer} = this.props;
    return (
      <Fragment>
        <div className='tab-pane-header'>
          <div className='tab-pane-name'>
            <I18n tKey='common.api_access' />
          </div>
        </div>
        <div className='tab-pane-body'>
          <div className='block-account'>
            <div className='block-account_title'>
              <I18n tKey='profile.my_keys' />
            </div>
            <table className='table table-api'>
              <thead className='table-api-header'>
                <tr>
                  <th>
                    <I18n tKey='common.created' />
                  </th>
                  <th>
                    <I18n tKey='profile.key_label' />
                  </th>
                  <th>
                    <I18n tKey='common.api_key' />
                  </th>
                  <th>
                    <I18n tKey='common.status' />
                  </th>
                </tr>
              </thead>
              <tbody className='table-api-body'>
                {apiKeys.map(elem => elem.currentSt !== 'REVOKED' && <ApiKey key={elem.currentClef} userKey={elem} />)}
              </tbody>
            </table>

            <div className='block-account-btn'>
              {Array.isArray(BaseApiKeyPermissionListReducer.data) && (
                <a className='btn btn-primary' data-toggle='modal' data-target='#modal-key' onClick={this.createApiKey}>
                  <span className='upper'>
                    <SvgWrapper className='icon icon-plus ic-color-00 ic-size-14' SvgCmp={PlusIc} />
                    <I18n tKey='profile.create_key' />
                  </span>
                </a>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  GetAccountApiKeysReducer,
  BaseApiKeyPermissionListReducer,
  RevokeApiKeyReducer,
  CreateAccountApiKeyReducer,
  UpdateAccountApiKeyReducer,
  ApiKeyStateReducer,
}) => ({
  GetAccountApiKeysReducer,
  BaseApiKeyPermissionListReducer,
  RevokeApiKeyReducer,
  CreateAccountApiKeyReducer,
  UpdateAccountApiKeyReducer,
  ApiKeyStateReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        keysList: Actions.GetAccountApiKeys.action,
        apiPermissions: Actions.BaseApiKeyPermissionList.action,
      },
      dispatch
    ),
    createKey: permissions => dispatch({type: CREATE_API_KEY, name: CREATE_KEY, data: permissions}),
    clearCreateKey: () => dispatch({type: Actions.CreateAccountApiKey.events.onClear}),
    clearUpdateKey: () => dispatch({type: Actions.UpdateAccountApiKey.events.onClear}),
    cleargetKey: () => dispatch({type: Actions.GetAccountApiKeys.events.onClear}),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiAccess));
