import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {CLOSE_POPUP, OPEN_POPUP} from '../../reducers/PopupReducer';
import {FORGOT_PASSWORD_POPUP} from '../../cmp/popups/right/ForgotPasswordPopup';
import {LoginTwoFactorAuth, UserLogin, ResendConfirmationEmail} from '../../api/Actions';

const mapStateToProps = ({
  ApplicationSettingsReducer,
  ResendConfirmationEmailReducer,
  LoginTwoFactorAuthReducer,
  UserLoginReducer,
  SessionReducer,
  history,
  settingsList,
}) => ({
  ApplicationSettingsReducer,
  ResendConfirmationEmailReducer,
  LoginTwoFactorAuthReducer,
  UserLoginReducer,
  SessionReducer,
  ...history,
  ...settingsList,
});

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        login: UserLogin.action,
        login2FA: LoginTwoFactorAuth.action,
        resendConfirmationEmail: ResendConfirmationEmail.action,
      },
      dispatch
    ),
    loginClear: () => dispatch({type: UserLogin.events.onClear}),
    login2faClear: () => dispatch({type: LoginTwoFactorAuth.events.onClear}),
    cleanEmailResendErr: () => dispatch({type: ResendConfirmationEmail.events.onClear}),
    openForgotPasswordPopup: () => dispatch({type: OPEN_POPUP, name: FORGOT_PASSWORD_POPUP}),
    closePopup: () => dispatch({type: CLOSE_POPUP}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
