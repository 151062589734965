import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import copy from 'copy-to-clipboard';

import I18n from '../../cmp/helpers/I18n';
import {SvgWrapper} from '../../cmp/helpers/SvgWrapper';
import {ReactComponent as CopyIc} from '../../../../assets/images/icon/copy.svg';
import {ReactComponent as QrIc} from '../../../../assets/images/icon/qr.svg';
import {ReactComponent as RefreshIc} from '../../../../assets/images/icon/refresh.svg';
import {Link} from 'react-router-dom';
import {ReactComponent as WarningIc} from '../../../../assets/images/icon/warning-ic.svg';
import {OPEN_POPUP} from '../../reducers/PopupReducer';
import {DEPOSIT_QR_CODE_POPUP} from '../../cmp/popups/top/DepositQRCodePopup';
import * as Actions from '../../api/Actions';
import {getDepositChainInfo} from '../../api/Actions';
import {bindActionCreators} from 'redux';

export class MultiChainDepositData extends React.Component {
  state = {};

  copyToClipboard(addr, propName) {
    copy(addr);

    this.setState({[propName]: true});

    setTimeout(() => {
      this.setState({[propName]: false});
    }, 1000);
  }

  updateChainInfo = () => {
    this.props.clearAdditionalDepositChainData();

    this.props.getDepositChainInfo({
      purseIdentifier: this.props.walletId,
      currencyFireblocksId: this.props.chainId,
    });
  };

  render() {
    const selectedNetwork = (this.props.GetWithdrawChainsReducer.data.respItemCollection || []).find(
      ({id}) => id === this.props.chainId
    );
    const {targetPlaceName: depAddr, minDepositAmount} = this.props.GetDepositChainInfoReducer.data || {};
    const depError =
      this.props.GetDepositChainInfoReducer.error && this.props.GetDepositChainInfoReducer.error.error
        ? this.props.GetDepositChainInfoReducer.error.parsedError || this.props.GetDepositChainInfoReducer.error.error
        : undefined;

    return (
      <Fragment>
        <div className='add-info-header'>
          {this.props.GetDepositChainInfoReducer.fetching && <I18n tKey='common.loading' />}
          <div className='add-info-header-item'>
            {depAddr && (
              <Fragment>
                <div className='box-control'>
                  <label className='label'>
                    <I18n tKey='common.your' /> {this.props.currencyName} <I18n tKey='balances.deposit_address' />{' '}
                  </label>
                  <input value={depAddr || ''} className='form-control' readOnly={true} type='text' />
                </div>
              </Fragment>
            )}

            {depError && !depAddr && <div className='red'>{depError}</div>}
          </div>

          {depAddr && (
            <Fragment>
              <div className='add-info-header-item'>
                <a className='action-ic-block' onClick={this.copyToClipboard.bind(this, depAddr, 'copiedAddress')}>
                  <SvgWrapper themeWatch className='icon icon-copy ic-size-08' SvgCmp={CopyIc} />
                  <I18n tKey='common.copy' />

                  {this.state.copiedAddress && (
                    <div className='copied-wrapper'>
                      <I18n tKey='common:copied' />
                    </div>
                  )}
                </a>
              </div>
            </Fragment>
          )}

          <div className='add-info-header-item'>
            {depAddr && (
              <Fragment>
                <a
                  className='action-ic-block'
                  onClick={this.props.showQRDeposit.bind(this, this.props.currencyName, depAddr, false)}
                >
                  <SvgWrapper themeWatch className='icon ic-size-06 icon-qr ic-color-00' SvgCmp={QrIc} />
                  <I18n tKey='common.show_qr' />
                </a>
              </Fragment>
            )}
          </div>

          <div className='add-info-header-item'>
            {depAddr && (
              <a className='action-ic-block' onClick={this.updateChainInfo}>
                <SvgWrapper themeWatch className='icon icon-refresh ic-size-02' SvgCmp={RefreshIc} />
                <I18n tKey='common.refresh' />
              </a>
            )}

            {depAddr && depError && (
              <div className='red' style={{textAlign: 'right', width: '15em'}}>
                {depError}
              </div>
            )}
          </div>
        </div>

        <div className='add-info-body'>
          <div className='add-info-body-text'>
            <I18n tKey='balances.after_making' />
            &nbsp;
            <Link to='/balances/TransactionHistory'>
              <I18n tKey='balances.pages' />{' '}
            </Link>
            {minDepositAmount > 0 && (
              <div>
                <strong>
                  <I18n tKey='balances.min_deposit_amount' />: {minDepositAmount}
                </strong>
              </div>
            )}
            {this.props.currencyCode.toLocaleLowerCase() == 'btc' && (
              <div>
                <strong>
                  <I18n tKey='balances.transaction_delay_warn' />
                </strong>
              </div>
            )}
            {!!minDepositAmount && (
              <div className='deposit-warning'>
                <SvgWrapper className='ic-size-06' SvgCmp={WarningIc} />
                <I18n tKey='balances.transaction_amount_warn' />
              </div>
            )}
            {!!selectedNetwork && (
              <div className='deposit-warning'>
                <SvgWrapper className='ic-size-06' SvgCmp={WarningIc} />
                <I18n tKey='balances.address_for_blockchain_warn' />: {selectedNetwork.network}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({GetDepositChainInfoReducer, GetWithdrawChainsReducer}) => ({
  GetDepositChainInfoReducer,
  GetWithdrawChainsReducer,
});

const mapDispatchToProps = dispatch => {
  return {
    clearAdditionalDepositChainData: () => dispatch({type: Actions.getDepositChainInfo.events.onClear}),
    showQRDeposit: (currency, address, isMemo) =>
      dispatch({
        type: OPEN_POPUP,
        name: DEPOSIT_QR_CODE_POPUP,
        data: {currency, address, isMemo},
      }),
    ...bindActionCreators({getDepositChainInfo: getDepositChainInfo.action}, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MultiChainDepositData);
