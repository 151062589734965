import {TICKERS} from './WsReducer';
import * as Actions from '../api/Actions';

const initialState = {
  priceList: null,
  marketList: null,
  pricesByMarket: [],
};

const performMapping = (cryptoPrices, marketList) => {
  let mappedArr = [];
  if (cryptoPrices == null || marketList == null) {
    return mappedArr;
  }
  for (const price of cryptoPrices) {
    const marketTitle = `${price.leftMonetaryUnitCode}-${price.rightMonetaryUnitCode}`.toLowerCase();
    const market = marketList.find(x => marketTitle === x.mBanner.toLowerCase());
    if (!market) {
      continue;
    }
    mappedArr[market.identificatSymbol] = price.transformed;
  }
  return mappedArr;
};

function MarketPricesReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.CryptoPrices.events.onSuccess: {
      return {
        ...state,
        priceList: action.data.respItemCollection,
        pricesByMarket: performMapping(action.data.respItemCollection, state.marketList),
      };
    }

    case Actions.MarketList.events.onSuccess: {
      return {
        ...state,
        marketList: action.data.respItemCollection,
        pricesByMarket: performMapping(state.priceList, action.data.respItemCollection),
      };
    }

    case TICKERS: {
      const prices = [...state.pricesByMarket];
      for (const ticker of action.payload) {
        prices[ticker[0]] = ticker[1];
      }
      return {...state, pricesByMarket: prices};
    }

    default:
      return state;
  }
}

export default MarketPricesReducer;
