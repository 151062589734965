import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import {translate} from '../../utils/i18n';

class I18n extends Component {
  render() {
    const {
      tKey = '',
      onClick,
      className = '',
      LngReducer,
      attr = {},
      param,
      usePreLine = false,
      useHtmlParser = false,
    } = this.props;
    let translated = translate(tKey, LngReducer.dictionary);
    translated = translated.replace('%1', param || '');
    translated = translated.replace(/\\n/g, '\n');

    if (useHtmlParser) {
      return (
        <span
          {...attr}
          onClick={onClick}
          dangerouslySetInnerHTML={{__html: translated.trim()}}
          className={classNames(className, {['i18n-preLine']: usePreLine})}
        />
      );
    }

    return (
      <span {...attr} onClick={onClick} className={classNames(className, {['i18n-preLine']: usePreLine})}>
        {translated.trim()}
      </span>
    );
  }
}

const mapStateToProps = ({LngReducer}) => {
  return {LngReducer};
};

export default connect(mapStateToProps)(I18n);
