class datePickerLocale {
  constructor(
    months,
    weekDays,
    nextMonth,
    previousMonth,
    openMonthSelector,
    openYearSelector,
    closeMonthSelector,
    closeYearSelector,
    defaultPlaceholder,
    from,
    to
  ) {
    this.months = months;
    this.weekDays = weekDays;
    this.weekStartingIndex = 0;
    this.digitSeparator = ',';
    this.nextMonth = nextMonth;
    this.previousMonth = previousMonth;
    this.openMonthSelector = openMonthSelector;
    this.openYearSelector = openYearSelector;
    this.closeMonthSelector = closeMonthSelector;
    this.closeYearSelector = closeYearSelector;
    this.defaultPlaceholder = defaultPlaceholder;
    this.from = from;
    this.to = to;
  }
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  }

  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  }

  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  }

  transformDigit(digit) {
    return digit;
  }
}
export const ru = new datePickerLocale(
  ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
  [
    {
      name: 'Воскресенье',
      short: 'Вс',
      isWeekend: true,
    },
    {
      name: 'Понедельник',
      short: 'Пн',
    },
    {
      name: 'Вторник',
      short: 'Вт',
    },
    {
      name: 'Среда',
      short: 'Ср',
    },
    {
      name: 'Четверг',
      short: 'Чт',
    },
    {
      name: 'Пятница',
      short: 'Пт',
    },
    {
      name: 'Суббота',
      short: 'Сб',
      isWeekend: true,
    },
  ],
  'Следующий месяц',
  'Предыдущий месяц',
  'Открыть выбор месяца',
  'Открыть выбор года',
  'Закрыть выбор месяца',
  'Закрыть выбор года',
  'Выбрать...',
  'с',
  'по'
);
export const cs = new datePickerLocale(
  [
    'Leden',
    'Únor',
    'Březen',
    'Duben',
    'Květen',
    'Červen',
    'Červenec',
    'Srpen',
    'Září',
    'Říjen',
    'Listopad',
    'Prosinec',
  ],
  [
    {
      name: 'Neděle',
      short: 'Ne',
      isWeekend: true,
    },
    {
      name: 'Pondělí',
      short: 'Po',
    },
    {
      name: 'Úterý',
      short: 'Út',
    },
    {
      name: 'Středa',
      short: 'St',
    },
    {
      name: 'Čtvrtek',
      short: 'Čt',
    },
    {
      name: 'Pátek',
      short: 'Pá',
    },
    {
      name: 'Sobota',
      short: 'So',
      isWeekend: true,
    },
  ],
  'Příští měsíc',
  'Předchozí měsíc',
  'Otevřete výběr měsíce',
  'Otevřený výběr roku',
  'Zavřít výběr měsíce',
  'Zavřít výběr roku',
  'Vybrat...',
  'z',
  'podle'
);
export const kk = new datePickerLocale(
  [
    'Қаңтар',
    'Ақпан',
    'Наурыз',
    'Сәуір',
    'Мамыр',
    'Маусым',
    'Шілде',
    'Тамыз',
    'Қыркүйек',
    'Қазан',
    'Қараша',
    'Желтоқсан',
  ],
  [
    {
      name: 'Жексенбі',
      short: 'Же',
      isWeekend: true,
    },
    {
      name: 'Дүйсенбі',
      short: 'Дү',
    },
    {
      name: 'Сейсенбі',
      short: 'Сй',
    },
    {
      name: 'Сәрсенбі',
      short: 'Ср',
    },
    {
      name: 'Бейсенбі',
      short: 'Бе',
    },
    {
      name: 'Жұма',
      short: 'Жұ',
    },
    {
      name: 'Сенбі',
      short: 'Сн',
      isWeekend: true,
    },
  ],
  'Келесі ай',
  'Алдыңғы ай',
  'Ашық ай таңдау',
  'Жылдың ашық таңдауы',
  'Ай таңдауын жабыңыз',
  'Жылдың жақын таңдауы',
  'Таңдау...',
  'бастап',
  'арқылы'
);
export const de = new datePickerLocale(
  [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  [
    {
      name: 'Sonntag',
      short: 'So',
      isWeekend: true,
    },
    {
      name: 'Montag',
      short: 'Mo',
    },
    {
      name: 'Dienstag',
      short: 'Di',
    },
    {
      name: 'Mittwoch',
      short: 'Mi',
    },
    {
      name: 'Donnerstag',
      short: 'Do',
    },
    {
      name: 'Freitag',
      short: 'Fr',
    },
    {
      name: 'Samstag',
      short: 'Sa',
      isWeekend: true,
    },
  ],
  'Nächsten Monat',
  'Vorheriger Monat',
  'Offene Monatsauswahl',
  'Offene Auswahl des Jahres',
  'Monatliche Auswahl schließen',
  'Enge Auswahl des Jahres',
  'Wählen...',
  'von',
  'im'
);
export const pt = new datePickerLocale(
  [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  [
    {
      name: 'Domingo',
      short: 'Dom',
      isWeekend: true,
    },
    {
      name: 'Segunda-feira',
      short: 'Seg',
    },
    {
      name: 'Terça-feira',
      short: 'Ter',
    },
    {
      name: 'Quarta-feira',
      short: 'Qua',
    },
    {
      name: 'Quinta-feira',
      short: 'Qui',
    },
    {
      name: 'Sexta-feira',
      short: 'Sex',
    },
    {
      name: 'Sabado',
      short: 'Sab',
      isWeekend: true,
    },
  ],
  'Próximo mês',
  'Mês anterior',
  'Seleção de mês aberto',
  'Seleção aberta do ano',
  'Fechar seleção de mês',
  'Fechar seleção do ano',
  'Escolher...',
  'de',
  'por'
);
export const hi_IN = new datePickerLocale(
  ['जनवरी', 'फरवरी', 'मार्च', 'अप्रैल', 'मई', 'जून', 'जुलाई', 'अगस्त', 'सितंबर', 'अक्टूबर', 'नवंबर', 'दिसंबर'],
  [
    {
      name: 'रविवार',
      short: 'रविवार',
      isWeekend: true,
    },
    {
      name: 'सोमवार',
      short: 'सोमवार',
    },
    {
      name: 'मंगलवार',
      short: 'मंगलवार',
    },
    {
      name: 'बुधवार',
      short: 'बुधवार',
    },
    {
      name: 'गुरूवार',
      short: 'गुरूवार',
    },
    {
      name: 'शुक्रवार',
      short: 'शुक्रवार',
    },
    {
      name: 'शनिवार',
      short: 'शनिवार',
      isWeekend: true,
    },
  ],
  'अगले महीने',
  'पिछ्ला महिना',
  'महीने का चयन खोलें',
  'वर्ष का खुला चयन',
  'महीने का चयन बंद करें',
  'वर्ष का करीबी चयन',
  'चुनें...',
  'से',
  'द्वारा'
);

export const zh_CN = new datePickerLocale(
  ['一月', '二月', '遊行', '四月', '可能', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
  [
    {
      name: '星期日',
      short: '星期日',
      isWeekend: true,
    },
    {
      name: '星期一',
      short: '星期一',
    },
    {
      name: '星期二',
      short: '星期二',
    },
    {
      name: '星期三',
      short: '星期三',
    },
    {
      name: '星期四',
      short: '星期四',
    },
    {
      name: '星期五',
      short: '星期五',
    },
    {
      name: '星期六',
      short: '星期六',
      isWeekend: true,
    },
  ],
  '下個月',
  '前一個月',
  '開放月選擇',
  '年度公開選拔',
  '關閉月份選擇',
  '年度精選',
  '選擇...',
  '從',
  '通過'
);
export const it = new datePickerLocale(
  [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],
  [
    {
      name: 'Domenica',
      short: 'Do',
      isWeekend: true,
    },
    {
      name: 'Lunedi',
      short: 'Lu',
    },
    {
      name: 'Вторник',
      short: 'Вт',
    },
    {
      name: 'Mercoledì',
      short: 'Me',
    },
    {
      name: 'Giovedi',
      short: 'Gi',
    },
    {
      name: 'Venerdì',
      short: 'Ve',
    },
    {
      name: 'Sabato',
      short: 'Sa',
      isWeekend: true,
    },
  ],
  'Il prossimo mese',
  'Il mese scorso',
  'Apri la selezione del mese',
  'Selezione aperta dell anno',
  'Chiudi la selezione del mese',
  'Chiudi selezione dell anno',
  'Scegliere...',
  'da',
  'a'
);

export const ro = new datePickerLocale(
  [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie',
  ],
  [
    {
      name: 'Duminică',
      short: 'Du',
      isWeekend: true,
    },
    {
      name: 'Luni',
      short: 'Lu',
    },
    {
      name: 'Marți',
      short: 'Ma',
    },
    {
      name: 'Miercuri',
      short: 'Mi',
    },
    {
      name: 'Joi',
      short: 'Jo',
    },
    {
      name: 'Vineri',
      short: 'Vi',
    },
    {
      name: 'Sâmbătă',
      short: 'Sâ',
      isWeekend: true,
    },
  ],
  'Luna viitoare',
  'Luna trecută',
  'Selecție lună deschisă',
  'Selecție deschisă a anului',
  'Închideți selecția lunii',
  'Închideți selecția anului',
  'Alege...',
  'de la',
  'la'
);

export const es = new datePickerLocale(
  [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  [
    {
      name: 'Domingo',
      short: 'Do',
      isWeekend: true,
    },
    {
      name: 'Lunes',
      short: 'Lu',
    },
    {
      name: 'Martes',
      short: 'Ma',
    },
    {
      name: 'Miércoles',
      short: 'Mi',
    },
    {
      name: 'Jueves',
      short: 'Ju',
    },
    {
      name: 'Viernes',
      short: 'Vi',
    },
    {
      name: 'Sábado',
      short: 'Sá',
      isWeekend: true,
    },
  ],
  'Próximo mes',
  'Mes anterior',
  'Selección de mes abierto',
  'Selección abierta del año',
  'Cerrar selección de mes',
  'Selección cercana del año',
  'Escoger...',
  'de',
  'a'
);

export const et = new datePickerLocale(
  [
    'Jaanuar',
    'Veebruar',
    'Märts',
    'Aprill',
    'Mai',
    'Juuni',
    'Juuli',
    'August',
    'September',
    'Oktoober',
    'November',
    'Detsember',
  ],
  [
    {
      name: 'Pühapäev',
      short: 'Pü',
      isWeekend: true,
    },
    {
      name: 'Esmaspäev',
      short: 'Es',
    },
    {
      name: 'Teisipäev',
      short: 'Te',
    },
    {
      name: 'Kolmapäev',
      short: 'Ko',
    },
    {
      name: 'Neljapäev',
      short: 'Ne',
    },
    {
      name: 'Reede',
      short: 'Re',
    },
    {
      name: 'Laupäev',
      short: 'La',
      isWeekend: true,
    },
  ],
  'Järgmine kuu',
  'Eelmine kuu',
  'Avatud kuu valik',
  'Aasta avatud valik',
  'Sulge kuu valik',
  'Aasta tihe valik',
  'Valige...',
  'alates',
  'kuni'
);
