// @flow
import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {NavLink, withRouter} from 'react-router-dom';
import I18n from '../../cmp/helpers/I18n';

class TopMenu extends React.PureComponent {
  render() {
    const name = this.props.match.params.name;
    const {tabs} = this.props;
    return (
      <div className='page-legacy_content-nav'>
        <div id='menu-scroll-legacy'>
          <div className='scroll-spy' role='tablist' data-spy='affix' data-offset-top='160'>
            <ul className='nav'>
              {tabs.map((tab, i) => (
                <li key={i} className={`${name === 'introduction' ? 'active' : ''}`}>
                  <NavLink activeClassName='active' className='scroll-page' to={tab.url}>
                    <I18n tKey={tab.label} />
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({}, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopMenu));
