import React, {Component} from 'react';

export class SvgWrapper extends Component {
  render() {
    const {SvgCmp, onClick, className, style = {}, themeWatch} = this.props;
    return (
      <SvgCmp
        style={{...style}}
        onClick={onClick}
        className={`svg-icon ${className ? className : ''} ${themeWatch ? 'depends-on-theme' : ''}`}
      />
    );
  }
}
