// @flow
import * as React from 'react';
import classNames from 'classnames';

import {ReactComponent as EyeOpened} from '../../../../assets/images/icon/eye_opened.svg';
import {ReactComponent as EyeClosed} from '../../../../assets/images/icon/eye_closed.svg';
import I18n from '../helpers/I18n';

class Input extends React.PureComponent {
  state = {
    value: this.props.value || '',
    focus: false,
    type: this.props.type || 'text',
  };

  componentWillReceiveProps(n) {
    this.setState({value: n.value});
  }

  handleFocus = () => {
    !this.props.readOnly && this.setState({focus: true});
  };

  handleBlur = () => {
    !this.props.readOnly && this.setState({focus: false});
  };

  handleEyeClick = () => {
    this.setState({type: this.state.type === 'password' ? 'text' : 'password'});
  };

  handleChange = e => {
    const {onChange, validation, toCleanError, inputName, maxValLength} = this.props;
    const val = e.target.value;
    let isValid = true;
    if (this.props.regEx) {
      isValid = this.props.regEx.test(val) || !val.length;
    }
    if (maxValLength) {
      isValid = val.length <= maxValLength;
    }
    if (isValid) {
      this.setState({value: val});
      onChange && onChange(val);
    }
    validation && validation(inputName, val);
    toCleanError && toCleanError();
  };

  getValue = () => {
    return this.state.value;
  };

  preventDefaultBehavior = e => {
    e.preventDefault();
  };

  render() {
    const {error, showPasswordActive} = this.props;
    return (
      <div
        className={`ui-form-input ${this.state.value || this.state.focus ? 'focus' : ''} ${
          this.props.error ? 'error' : ''
        }`}
      >
        <input
          type={this.state.type}
          readOnly={this.props.readOnly}
          className={classNames('ui-form-control', this.props.className, {
            ['border-bottom-red']: error,
            ['with-eye']: showPasswordActive,
          })}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.props.onKeyDown || (e => {})}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyPress={this.props.onPressEnter}
          onPaste={this.props.isPasteEventPrevented && this.preventDefaultBehavior}
        />
        <div className='ui-form-control-focus' />
        <label className={`ui-form-control-placeholder`}>
          <I18n tKey={this.props.placeholder} />
        </label>
        {!error && (
          <label className='ui-form-control-help'>
            <I18n tKey={this.props.label} />
          </label>
        )}
        {error && (
          <label className='ui-form-control-help'>
            <I18n tKey={error.error || error.parsedError} />
          </label>
        )}
        {this.props.showPasswordActive && (
          <div onClick={this.handleEyeClick} className='type-switcher'>
            {this.state.type === 'password' ? <EyeClosed /> : <EyeOpened />}
          </div>
        )}
        {/*{error && <div className="popup-error-message">*/}
        {/*{error.parsedError || error.error}*/}
        {/*</div>}*/}
        {this.props.children && <div className='ui-form-control-rules left'>{this.props.children}</div>}
      </div>
    );
  }
}

export default Input;
