import React from 'react';

export const CUSTOM_SELECT_STYLES = {
  control: (base, state) => ({
    ...base,
    border: 'none',
    background: 'var(--bg_clr_11)',
  }),
  menu: base => ({
    ...base,
    border: 'none',
    zIndex: 100,
  }),
  menuList: base => ({
    ...base,
    background: 'var(--bg_clr_11)',
    border: 'none',
  }),
  singleValue: base => ({
    ...base,
    color: 'var(--color_text_01)',
  }),
  option: (base, {isSelected, isFocused}) => ({
    ...base,
    color: 'var(--color_text_02)',
    ...(isSelected && {backgroundColor: 'var(--brdr_clr_05)!important'}),
    ...(isFocused && {backgroundColor: 'var(--brdr_clr_05)'}),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'var(--brdr_clr_05)',
    },
  }),
};

export const CUSTOM_THEME = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    text: 'black',
    primary25: '#F4F5F7',
    primary: '#F4F5F7',
  },
});

export const COUNTRIES = [
  {
    value: '10121',
    label: 'Afghanistan',
  },
  {
    value: '10122',
    label: 'Albania',
  },
  {
    value: '10123',
    label: 'Algeria',
  },
  {
    value: '10124',
    label: 'Andorra',
  },
  {
    value: '10125',
    label: 'Angola',
  },
  {
    value: '10126',
    label: 'Antigua and Barbuda',
  },
  {
    value: '10127',
    label: 'Argentina',
  },
  {
    value: '10128',
    label: 'Armenia',
  },
  {
    value: '10129',
    label: 'Australia',
  },
  {
    value: '10130',
    label: 'Austria',
  },
  {
    value: '10131',
    label: 'Azerbaijan',
  },
  {
    value: '10132',
    label: 'The Bahamas',
  },
  {
    value: '10133',
    label: 'Bahrain',
  },
  {
    value: '10134',
    label: 'Bangladesh',
  },
  {
    value: '10135',
    label: 'Barbados',
  },
  {
    value: '10136',
    label: 'Belarus',
  },
  {
    value: '10137',
    label: 'Belgium',
  },
  {
    value: '10138',
    label: 'Belize',
  },
  {
    value: '10139',
    label: 'Benin',
  },
  {
    value: '10140',
    label: 'Bhutan',
  },
  {
    value: '10141',
    label: 'Bolivia',
  },
  {
    value: '10142',
    label: 'Bosnia and Herzegovina',
  },
  {
    value: '10143',
    label: 'Botswana',
  },
  {
    value: '10144',
    label: 'Brazil',
  },
  {
    value: '10145',
    label: 'Brunei',
  },
  {
    value: '10146',
    label: 'Bulgaria',
  },
  {
    value: '10147',
    label: 'Burkina Faso',
  },
  {
    value: '10148',
    label: 'Burundi',
  },
  {
    value: '10149',
    label: 'Cambodia',
  },
  {
    value: '10150',
    label: 'Cameroon',
  },
  {
    value: '10151',
    label: 'Canada',
  },
  {
    value: '10152',
    label: 'Cape Verde',
  },
  {
    value: '10153',
    label: 'Central African Republic',
  },
  {
    value: '10154',
    label: 'Chad',
  },
  {
    value: '10155',
    label: 'Chile',
  },
  {
    value: '10156',
    label: 'China',
  },
  {
    value: '10157',
    label: 'Colombia',
  },
  {
    value: '10158',
    label: 'Comoros',
  },
  {
    value: '10159',
    label: 'Congo, Republic of the',
  },
  {
    value: '10160',
    label: 'Congo, Democratic Republic of the',
  },
  {
    value: '10161',
    label: 'Costa Rica',
  },
  {
    value: '10162',
    label: "Cote d'Ivoire",
  },
  {
    value: '10163',
    label: 'Croatia',
  },
  {
    value: '10164',
    label: 'Cuba',
  },
  {
    value: '10165',
    label: 'Cyprus',
  },
  {
    value: '10166',
    label: 'Czech Republic',
  },
  {
    value: '10167',
    label: 'Denmark',
  },
  {
    value: '10168',
    label: 'Djibouti',
  },
  {
    value: '10169',
    label: 'Dominica',
  },
  {
    value: '10170',
    label: 'Dominican Republic',
  },
  {
    value: '10171',
    label: 'East Timor (Timor-Leste)',
  },
  {
    value: '10172',
    label: 'Ecuador',
  },
  {
    value: '10173',
    label: 'Egypt',
  },
  {
    value: '10174',
    label: 'El Salvador',
  },
  {
    value: '10175',
    label: 'Equatorial Guinea',
  },
  {
    value: '10176',
    label: 'Eritrea',
  },
  {
    value: '10177',
    label: 'Estonia',
  },
  {
    value: '10178',
    label: 'Ethiopia',
  },
  {
    value: '10179',
    label: 'Fiji',
  },
  {
    value: '10180',
    label: 'Finland',
  },
  {
    value: '10181',
    label: 'France',
  },
  {
    value: '10182',
    label: 'Gabon',
  },
  {
    value: '10183',
    label: 'The Gambia',
  },
  {
    value: '10184',
    label: 'Georgia',
  },
  {
    value: '10185',
    label: 'Germany',
  },
  {
    value: '10186',
    label: 'Ghana',
  },
  {
    value: '10187',
    label: 'Greece',
  },
  {
    value: '10188',
    label: 'Grenada',
  },
  {
    value: '10189',
    label: 'Guatemala',
  },
  {
    value: '10190',
    label: 'Guinea',
  },
  {
    value: '10191',
    label: 'Guinea-Bissau',
  },
  {
    value: '10192',
    label: 'Guyana',
  },
  {
    value: '10193',
    label: 'Haiti',
  },
  {
    value: '10194',
    label: 'Honduras',
  },
  {
    value: '10195',
    label: 'Hungary',
  },
  {
    value: '10196',
    label: 'Iceland',
  },
  {
    value: '10197',
    label: 'India',
  },
  {
    value: '10198',
    label: 'Indonesia',
  },
  {
    value: '10199',
    label: 'Iran',
  },
  {
    value: '10200',
    label: 'Iraq',
  },
  {
    value: '10201',
    label: 'Ireland',
  },
  {
    value: '10202',
    label: 'Israel',
  },
  {
    value: '10203',
    label: 'Italy',
  },
  {
    value: '10204',
    label: 'Jamaica',
  },
  {
    value: '10205',
    label: 'Japan',
  },
  {
    value: '10206',
    label: 'Jordan',
  },
  {
    value: '10207',
    label: 'Kazakhstan',
  },
  {
    value: '10208',
    label: 'Kenya',
  },
  {
    value: '10209',
    label: 'Kiribati',
  },
  {
    value: '10210',
    label: 'Korea, North',
  },
  {
    value: '10211',
    label: 'Korea, South',
  },
  {
    value: '10212',
    label: 'Kosovo',
  },
  {
    value: '10213',
    label: 'Kuwait',
  },
  {
    value: '10214',
    label: 'Kyrgyzstan',
  },
  {
    value: '10215',
    label: 'Laos',
  },
  {
    value: '10216',
    label: 'Latvia',
  },
  {
    value: '10217',
    label: 'Lebanon',
  },
  {
    value: '10218',
    label: 'Lesotho',
  },
  {
    value: '10219',
    label: 'Liberia',
  },
  {
    value: '10220',
    label: 'Libya',
  },
  {
    value: '10221',
    label: 'Liechtenstein',
  },
  {
    value: '10222',
    label: 'Lithuania',
  },
  {
    value: '10223',
    label: 'Luxembourg',
  },
  {
    value: '10224',
    label: 'Macedonia',
  },
  {
    value: '10225',
    label: 'Madagascar',
  },
  {
    value: '10226',
    label: 'Malawi',
  },
  {
    value: '10227',
    label: 'Malaysia',
  },
  {
    value: '10228',
    label: 'Maldives',
  },
  {
    value: '10229',
    label: 'Mali',
  },
  {
    value: '10230',
    label: 'Malta',
  },
  {
    value: '10231',
    label: 'Marshall Islands',
  },
  {
    value: '10232',
    label: 'Mauritania',
  },
  {
    value: '10233',
    label: 'Mauritius',
  },
  {
    value: '10234',
    label: 'Mexico',
  },
  {
    value: '10235',
    label: 'Micronesia, Federated States of',
  },
  {
    value: '10236',
    label: 'Moldova',
  },
  {
    value: '10237',
    label: 'Monaco',
  },
  {
    value: '10238',
    label: 'Mongolia',
  },
  {
    value: '10239',
    label: 'Montenegro',
  },
  {
    value: '10240',
    label: 'Morocco',
  },
  {
    value: '10241',
    label: 'Mozambique',
  },
  {
    value: '10242',
    label: 'Myanmar (Burma)',
  },
  {
    value: '10243',
    label: 'Namibia',
  },
  {
    value: '10244',
    label: 'Nauru',
  },
  {
    value: '10245',
    label: 'Nepal',
  },
  {
    value: '10246',
    label: 'Netherlands',
  },
  {
    value: '10247',
    label: 'New Zealand',
  },
  {
    value: '10248',
    label: 'Nicaragua',
  },
  {
    value: '10249',
    label: 'Niger',
  },
  {
    value: '10250',
    label: 'Nigeria',
  },
  {
    value: '10251',
    label: 'Norway',
  },
  {
    value: '10252',
    label: 'Oman',
  },
  {
    value: '10253',
    label: 'Pakistan',
  },
  {
    value: '10254',
    label: 'Palau',
  },
  {
    value: '10255',
    label: 'Panama',
  },
  {
    value: '10256',
    label: 'Papua New Guinea',
  },
  {
    value: '10257',
    label: 'Paraguay',
  },
  {
    value: '10258',
    label: 'Peru',
  },
  {
    value: '10259',
    label: 'Philippines',
  },
  {
    value: '10260',
    label: 'Poland',
  },
  {
    value: '10261',
    label: 'Portugal',
  },
  {
    value: '10262',
    label: 'Qatar',
  },
  {
    value: '10263',
    label: 'Romania',
  },
  {
    value: '10264',
    label: 'Russia',
  },
  {
    value: '10265',
    label: 'Rwanda',
  },
  {
    value: '10266',
    label: 'Saint Kitts and Nevis',
  },
  {
    value: '10267',
    label: 'Saint Lucia',
  },
  {
    value: '10268',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    value: '10269',
    label: 'Samoa',
  },
  {
    value: '10270',
    label: 'San Marino',
  },
  {
    value: '10271',
    label: 'Sao Tome and Principe',
  },
  {
    value: '10272',
    label: 'Saudi Arabia',
  },
  {
    value: '10273',
    label: 'Senegal',
  },
  {
    value: '10274',
    label: 'Serbia',
  },
  {
    value: '10275',
    label: 'Seychelles',
  },
  {
    value: '10276',
    label: 'Sierra Leone',
  },
  {
    value: '10277',
    label: 'Singapore',
  },
  {
    value: '10278',
    label: 'Slovakia',
  },
  {
    value: '10279',
    label: 'Slovenia',
  },
  {
    value: '10280',
    label: 'Solomon Islands',
  },
  {
    value: '10281',
    label: 'Somalia',
  },
  {
    value: '10282',
    label: 'South Africa',
  },
  {
    value: '10283',
    label: 'South Sudan',
  },
  {
    value: '10284',
    label: 'Spain',
  },
  {
    value: '10285',
    label: 'Sri Lanka',
  },
  {
    value: '10286',
    label: 'Sudan',
  },
  {
    value: '10287',
    label: 'Suriname',
  },
  {
    value: '10288',
    label: 'Swaziland',
  },
  {
    value: '10289',
    label: 'Sweden',
  },
  {
    value: '10290',
    label: 'Switzerland',
  },
  {
    value: '10291',
    label: 'Syria',
  },
  {
    value: '10292',
    label: 'Taiwan',
  },
  {
    value: '10293',
    label: 'Tajikistan',
  },
  {
    value: '10294',
    label: 'Tanzania',
  },
  {
    value: '10295',
    label: 'Thailand',
  },
  {
    value: '10296',
    label: 'Togo',
  },
  {
    value: '10297',
    label: 'Tonga',
  },
  {
    value: '10298',
    label: 'Trinidad and Tobago',
  },
  {
    value: '10299',
    label: 'Tunisia',
  },
  {
    value: '10300',
    label: 'Turkey',
  },
  {
    value: '10301',
    label: 'Turkmenistan',
  },
  {
    value: '10302',
    label: 'Tuvalu',
  },
  {
    value: '10303',
    label: 'Uganda',
  },
  {
    value: '10304',
    label: 'Ukraine',
  },
  {
    value: '10305',
    label: 'United Arab Emirates',
  },
  {
    value: '10306',
    label: 'United Kingdom',
  },
  {
    value: '10307',
    label: 'United States of America',
  },
  {
    value: '10308',
    label: 'Uruguay',
  },
  {
    value: '10309',
    label: 'Uzbekistan',
  },
  {
    value: '10310',
    label: 'Vanuatu',
  },
  {
    value: '10311',
    label: 'Vatican City (Holy See)',
  },
  {
    value: '10312',
    label: 'Venezuela',
  },
  {
    value: '10313',
    label: 'Vietnam',
  },
  {
    value: '10314',
    label: 'Yemen',
  },
  {
    value: '10315',
    label: 'Zambia',
  },
  {
    value: '10316',
    label: 'Zimbabwe',
  },
].map(elem => ({
  ...elem,
  label: <div className='label__wrapper'> {elem.label}</div>,
}));
