// @flow
import * as React from 'react';
import {Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {CLOSE_POPUP, OPEN_POPUP} from '../../../reducers/PopupReducer';
import {ENABLE_2FA_STEP3_POPUP} from './Enable2FAStep3Popup';
import * as Actions from '../../../api/Actions';
import {ReactComponent as GPlay} from '../../../../../assets/images/2fa/google-play-badge.svg';
import {ReactComponent as MSoft} from '../../../../../assets/images/2fa/Microsoft-badge.svg';
import {ReactComponent as AppStore} from '../../../../../assets/images/2fa/Download_on_the_App_Store_Badge.svg';
import {SvgWrapper} from '../../helpers/SvgWrapper';
import I18n from '../../helpers/I18n';

export const ENABLE_2FA_STEP2_POPUP = 'ENABLE_2FA_STEP2_POPUP';

class Enable2FAStep2Popup extends React.PureComponent<void, ComponentState> {
  state = {
    password: '',
    passwordError: '',
  };

  startEnable2FA = () => {
    const {password} = this.state;
    this.setState({passwordError: ''});
    let hasError = null;
    if (!password) {
      hasError = true;
      this.setState({passwordError: 'popup.enter_password'});
    }
    !hasError && this.props.start({lockPickSymbolRow: this.state.password, tfVec: 'google'});
  };

  componentWillReceiveProps(n) {
    if (n.AddTwoFactorAuthReducer && n.AddTwoFactorAuthReducer.data && n.AddTwoFactorAuthReducer.data.mysteryInf) {
      this.props.nextStep();
    }
  }

  onPressEnter = event => {
    if (event.key === 'Enter') {
      this.startEnable2FA();
    }
  };

  render() {
    const {AddTwoFactorAuthReducer} = this.props;
    return (
      <Fragment>
        <div className='modal-2FA-form text-center'>
          <h2>
            <I18n tKey='popup.long' />
          </h2>
          <div className='box-app'>
            <div className='box-app-text'>
              <I18n tKey='popup.begin_install' />
            </div>
            <div className='box-app-item'>
              <a target='_blank' href='https://apps.apple.com/ru/app/google-authenticator/id388497605'>
                {' '}
                <SvgWrapper SvgCmp={AppStore} />
              </a>
              <a
                target='_blank'
                href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru'
              >
                <SvgWrapper SvgCmp={GPlay} />
              </a>
              <a target='_blank' href='https://www.microsoft.com/en-us/p/authenticator/9nblggh08h54'>
                {' '}
                <SvgWrapper SvgCmp={MSoft} />
              </a>
            </div>
          </div>
          <div className='ui-form-input focus'>
            <input
              type='password'
              value={this.state.password}
              onChange={e => this.setState({password: e.target.value})}
              className='ui-form-control ui-form-control-secure'
              onKeyPress={this.onPressEnter}
            />
            <div className='ui-form-control-focus' />
            <label className='ui-form-control-placeholder'>
              <I18n tKey='common.enable_2fa_password' />
            </label>
          </div>

          <div className='modal-2FA-form-text'>
            <I18n tKey='popup.generate_code' />
          </div>
          {!AddTwoFactorAuthReducer.fetching && (
            <a onClick={this.startEnable2FA} className='btn btn-primary btn-lg'>
              <I18n tKey='common.start' />
            </a>
          )}
          {AddTwoFactorAuthReducer.error && (
            <div className='error-2fa'>
              {AddTwoFactorAuthReducer.error.parsedError || AddTwoFactorAuthReducer.error.error}
            </div>
          )}
          {AddTwoFactorAuthReducer.fetching && <I18n tKey='common.loading' />}
          <div className='modal-text-bottom'>
            <a href='/page/faq/all' target='_blank'>
              <I18n tKey='faq.about_2fa' />
            </a>
          </div>

          {this.state.passwordError && (
            <div className='error'>
              <I18n tKey={this.state.passwordError} />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({AddTwoFactorAuthReducer}) => ({AddTwoFactorAuthReducer});
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      {
        start: Actions.AddTwoFactorAuth.action,
      },
      dispatch
    ),
    nextStep: () => dispatch({type: OPEN_POPUP, name: ENABLE_2FA_STEP3_POPUP}),
    close: () => dispatch({type: CLOSE_POPUP}),
  };
};
const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Enable2FAStep2Popup));

export default connectedWithRouter;
