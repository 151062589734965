import BigNumber from 'bignumber.js';

import {SAVED_PRECISION} from '../reducers/OrderbookReducer';

const SUPPORTED_PRECISIONS = [0, 1, 2, 3, 4];

export class Orderbook {
  ctcInstrIdentifier = null;
  precision = Number(localStorage.getItem(SAVED_PRECISION));
  buy = {};
  sell = {};
  buyVolume = 0;
  buyMaxVolume = 0;
  sellMaxVolume = 0;
  sellVolume = 0;
  spread = 0;
  spreadPercent = 0;

  init(ctcInstrIdentifier, precision, buy, sell) {
    const parsedPrecision = this.getIntPrecision(precision);

    localStorage.setItem(SAVED_PRECISION, parsedPrecision);

    this.ctcInstrIdentifier = ctcInstrIdentifier;
    this.precision = parsedPrecision;
    this.buy = this.parseRawOrderBookOrders(buy);
    this.sell = this.parseRawOrderBookOrders(sell);
  }

  parseRawOrderBookOrders(rawOrders = []) {
    return rawOrders.reduce((accumulator, {price, volume}) => {
      accumulator[price] = {
        volume,
        changed: false,
      };

      return accumulator;
    }, {});
  }

  getIntPrecision(precision) {
    return parseInt(precision.toString().toLowerCase().replace('p', ''));
  }

  calcSpread(buy, sell) {
    this.spread = 0;
    this.spreadPercent = 0;

    if (buy && buy.length >= 1 && sell && sell.length >= 1) {
      this.spread = new BigNumber(sell[0].price).minus(buy[0].price).toNumber();
      this.spreadPercent = new BigNumber(this.spread).div(sell[0].price).multipliedBy(100).toNumber();
    }
  }

  recalculateVolume(key) {
    const list = this[key];
    let max = false;
    let total = 0;

    if (list && Object.keys(list).length >= 1) {
      for (let order of Object.values(list)) {
        if (max === false || order.volume > max) {
          max = order.volume;
        }

        total += order.volume;
      }
    }

    this[key + 'MaxVolume'] = max;
    this[key + 'Volume'] = total;
  }

  recalculateVolumes() {
    this.recalculateVolume('buy');
    this.recalculateVolume('sell');
  }

  toFlatOrderList(orderMap, maxVolume, isBuy, totalVolume) {
    return Object.keys(orderMap)
      .reduce((accumulator, priceKey) => {
        const {volume} = orderMap[priceKey];

        accumulator.push({
          ...orderMap[priceKey],
          price: priceKey,
          total: new BigNumber(priceKey).multipliedBy(volume).toNumber(),
          share: new BigNumber(volume).multipliedBy(100).div(totalVolume).toNumber(),
        });

        return accumulator;
      }, [])
      .sort((leftValue, rightValue) =>
        isBuy ? rightValue.price - leftValue.price : leftValue.price - rightValue.price
      );
  }

  isPrecisionAvailable(delta, curPrecision = null) {
    if (!curPrecision) {
      curPrecision = this.precision;
    }
    const newPrecision = new BigNumber(curPrecision).plus(delta).toNumber();

    return SUPPORTED_PRECISIONS.includes(newPrecision);
  }

  toState() {
    this.recalculateVolumes();
    const buy = this.toFlatOrderList(this.buy, this.buyMaxVolume, true, this.buyVolume);
    const sell = this.toFlatOrderList(this.sell, this.sellMaxVolume, false, this.sellVolume);
    this.calcSpread(buy, sell);

    return {
      ctcInstrIdentifier: this.ctcInstrIdentifier,
      precision: Number(this.precision),
      buy,
      sell,
      buyVolume: this.buyVolume.toString(),
      sellVolume: this.sellVolume.toString(),
      hasMorePrecision: this.isPrecisionAvailable(1),
      hasLessPrecision: this.isPrecisionAvailable(-1),
      spread: this.spread,
      spreadPercent: this.spreadPercent,
      highestBuy: buy && buy.length >= 1 ? buy[0].price : null,
      lowestSell: sell && sell.length >= 1 ? sell[0].price : null,
    };
  }

  getNewPrecisionState(delta) {
    const newPrecision = new BigNumber(this.precision).plus(delta).toNumber();

    if (!SUPPORTED_PRECISIONS.includes(newPrecision)) {
      return null;
    }

    localStorage.setItem(SAVED_PRECISION, newPrecision);

    return {
      precision: newPrecision,
      hasMorePrecision: this.isPrecisionAvailable(-1, newPrecision),
      hasLessPrecision: this.isPrecisionAvailable(1, newPrecision),
    };
  }
}
